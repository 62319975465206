import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-firewall-block',
  templateUrl: './firewall-block.page.html',
  styleUrls: ['./firewall-block.page.scss'],
})
export class FirewallBlockPage implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  async tryAgain() {
    if(!!await this.modalCtrl.getTop()) {
      return this.modalCtrl.dismiss()
    }
  }

}
