import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { LocationSectionComponent } from "./location-section.component";
import { PipesModule } from "src/app/pipes/pipes.module";

@NgModule({
  declarations: [LocationSectionComponent],
  imports: [CommonModule, IonicModule, PipesModule],
  exports: [LocationSectionComponent],
})
export class LocationSectionComponentModule {}
