import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CheckmarkButtonComponent} from "./checkmark-button.component";
import {IonicModule} from "@ionic/angular";
import { ChipModule } from "../chip/chip.module";

@NgModule({
  declarations: [
    CheckmarkButtonComponent
  ],
  imports: [
    CommonModule,
    ChipModule,
    IonicModule
  ],
  exports: [
    CheckmarkButtonComponent
  ]
})
export class CheckmarkButtonModule {}
