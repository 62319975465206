export const getInfoModalsInfo =
  (options: any) => {
    return {
      claim_drink: {
        title: "Free drink on us",
        image: './assets/img/invite-friend.png',
        description: "Meet up with your We3 group and we’ll pick up the tab on your first drink.",
        subTitle: "How it works:",
        items: [
          {
            title: "Meet your group IRL",
            image: "./assets/img/meet-irl.png",
            description: "Zoom doesn’t count! Hangout in the real world."
          },
          {
            title: "Get a drink of your choice",
            image: "./assets/img/any-drink.png",
            description: "Coffee, bubble tea, beer, cocktail, smoothie... they all work!"
          },
          {
            title: "Send us a group selfie",
            image: "./assets/img/group-photo.png",
            description: "All 3 group members must be in the picture."
          },
          {
            title: "Reimbursed within 24h",
            image: "./assets/img/reimburse.png",
            description: "We’ll send an Interac e-Transfer to your email or phone number.",
          },
          {
            divider: true,
            icon: "information-circle-outline",
            text: "Important terms & conditions"
          },
          {
            title: "Rights to use",
            image: "./assets/img/rights-to-picture.png",
            description: "The group grants us the rights to use the picture in our marketing materials."
          },
          {
            title: "Adults only",
            image: "./assets/img/adults-only.png",
            description: "To claim an alcoholic beverage, you must be of legal drinking age."
          },
          {
            title: "Up to $10 CAD",
            image: "./assets/img/monetary-limit.png",
            description: "Including taxes. You just cover the tip."
          },
          {
            title: "Canada only",
            image: "./assets/img/canada.png",
            description: "We’ll expand to warmer places soon."
          },
          {
            title: "Valid once",
            image: "./assets/img/valid-once.png",
            description: "We’d love to but can’t pick up your tab every time."
          },
          {
            title: "3-month expiry",
            image: "./assets/img/calendar.png",
            description: "The meet-up must occur within 90 days of joining."
          }
        ]
      },
      give_drinks_unlimited: {
        title: "How invites work",
        image: './assets/img/invite-friend.png',
        description: "Anyone who joins We3 with your invite link can have their first drink paid for by us when meeting their We3 group in person.",
        subTitle: "Here are the details:",
        items: [
          {
            title: "Any drink of their choice",
            image: "./assets/img/any-drink.png",
            description: "Coffee, bubble tea, beer, cocktail, smoothie... they all work!"
          },
          {
            title: "Anywhere in Canada",
            image: "./assets/img/canada.png",
            description: "We'll expand to warmer places soon."
          }
        ],
        notice: {
          icon: "information-circle-outline",
          text: "$10 CAD limit, including taxes. Valid only once and within 3 months of signup. Reimbursed via Interac e-transfer within 24h."
        }
      },
      give_drinks_2_friends: {
        title: "How invites work",
        image: './assets/img/invite-friend.png',
        description: "The first two people join We3 with your invite link can have their first drink paid for by us when meeting their We3 group in person",
        subTitle: "Here are the details:",
        items: [
          {
            title: "Any drink of their choice",
            image: "./assets/img/any-drink.png",
            description: "Coffee, bubble tea, beer, cocktail, smoothie... they all work!"
          },
          {
            title: "Anywhere in Canada",
            image: "./assets/img/canada.png",
            description: "We'll expand to warmer places soon."
          },
        ],
        notice: {
          icon: "information-circle-outline",
          text: "$10 CAD limit, including taxes. Valid only once and within 3 months of signup. Reimbursed via Interac e-transfer within 24h."
        }
      },
      privacy: {
        title: "Privacy on We3",
        image: './assets/img/shield.svg',
        description: "We are different. You are safe here.",
        subTitle: "",
        items: [
          {
            title: "No public profiles",
            image: "./assets/img/public.svg",
            description: "Your profile is hidden by default. Only your matches can see you."
          },
          {
            title: "Be invisible to friends",
            image: "./assets/img/address-book.svg",
            description: "You can avoid being seen by anyone you already know, in advance."
          },
          {
            title: "No tracking",
            image: "./assets/img/tracking.svg",
            description: "We don’t link accounts to ad services that track you across sites or apps."
          },
          {
            title: "No ads",
            image: "./assets/img/no-ads.svg",
            description: "We don’t sell your information or attention to anyone."
          },
          {
            title: "No endless feeds",
            image: "./assets/img/newsfeed.svg",
            description: "Instead of keeping you in the app, we try to get you out in the real world meeting new people."
          }
        ],
        notice: ''
      },
      joiningConditions: {
        title: "How to get invited",
        image: "./assets/img/invite-friend.png",
        description: "You will get invited when someone else starts a group with you in it.",
        subTitle: '<span class = "bold">How often you get invited depends on:</span>',
        items: [
          {
            title: "1. Your Profile",
            icon: {
              name: 'person-circle',
              cssClass: 'secondary-color'
            },
            description: "A friendly profile gets 4x more invites. So pick a great photo and write a positive blurb."
          },
          {
            title: "2. Your Filters",
            icon: {
              name: 'options',
              cssClass: 'primary-color'
            },
            description: "Limit your filters as much as you need, but going beyond that may result in fewer invites."
          },
          {
            title: "3. Number of Groups Started",
            icon: {
              name: 'pool',
              cssClass: 'success-color'
            },
            description: "The more people start groups in your area, the more invites you’ll get."
          }
        ],
        notice: {
          icon: 'help-circle-outline',
          text: '<span class = "bold">Your Status</span> must also remain “Open” to be invited to groups.'
        }
      },
      radar: {
        title: "Your Radar Chart",
        image: './assets/img/radar.svg',
        description: "A quick and simple way to fit your whole psychographic profile into a single chart.",
        subTitle: "",
        items: [
          {
            title: "How to use it",
            image: "./assets/img/tap.svg",
            description: "To compare yourself to others, open their profile and tap on their radar chart. Yours will appear so you can see how much you overlap."
          },
          {
            title: "How to interpret it",
            image: "./assets/img/interpretation.svg",
            description: "The radar chart contains everything from marital status to taste in music. So scoring higher on a factor doesn’t mean better, just different. Also note that similarity isn’t compatibility.*"
          },
          {
            title: "Limitations",
            image: "./assets/img/limitations.svg",
            description: "There are so many factors contained in each chart that it’s not currently possible to “drill down” to see the individual differences."
          }
        ],
        notice: {
          icon: "information-circle-outline",
          text: 'We3’s matching algorithm optimizes for friendship compatibility, which in many cases means complementarity over similarity.'
        }
      },
      batteryOptimization: {
        title: "Battery Optimization",
        image: './assets/img/battery.svg',
        description: "Your device is using an aggressive battery-saving technique that’s breaking the functionality of some apps, like We3.",
        subTitle: "",
        items: [
          {
            title: "Why “optimize”?",
            icon: {
              name: "help-circle-outline",
              cssClass: "secondary-color"
            },
            description: `When deciding what smartphone to buy, consumers value a long battery life, so ${ options?.oem } uses various software tricks to extend it as much as possible. These tricks work by restricting what some apps can do (like cutting off their Internet connection). But they often break basic things like getting notifications on time.`,
          },
          {
            title: "Why didn’t I know this?",
            image: "./assets/img/thinking-face.svg",
            description: `Not all apps require timely updates or notifications to work, and ${ options?.oem } exempts popular apps (like Whatsapp) from these restrictions.`
          }
        ],
        notice: {
          icon: "help-circle-outline",
          text: 'To learn more from an independent source, here’s an article from. ' +
            '<a class = "primary-color underlined" href = "https://www.androidpolice.com/2020/07/18/phone-makers-are-breaking-your-favorite-apps-with-reckless-changes-to-androids-power-optimization-features/">' +
            'Android Police' +
            '</a>'
        }
      }
    }[ options.type ];
  };
