import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { ActionButtonComponent } from 'src/app/components/action-button/action-button.component';
import { DoneButtonComponent } from 'src/app/components/done-button/done-button.component';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UserService } from 'src/app/services/data/user.service';
import { BuilderService } from 'src/app/services/helpers/builder.service';
import { TopicsService } from 'src/app/services/topics.service';
import { UserTribeStatus } from 'src/app/shared/enums/user-tribe-status.enum';
import { fromNullable } from 'src/app/shared/helpers/either-monad';
import { logc } from 'src/app/shared/helpers/log';

const BACKGROUND_VISIBLE_PARTS_LENGTH = 200;
const BACKGROUND_LINE_NUMBER = 3;

export interface TribeInteractable {
  id: string | number;
  title: string;
  image_url?: string;
  summary?: string;
  tags?: string;
  field?: string;
  subject?: string;
  background?: string;
  follow_ups?: any;
  selected?: boolean;
}

@Component({
  selector: 'app-smart-suggestion',
  template: `
    <ion-header class = "absolute w-full h-[100px]">
      <close-button class = "absolute right-[20px] top-[20px]" 
                    (click) = "close()"></close-button>
    </ion-header>
    <ion-content>
      <div class = "header-container">
        <img *ngIf = "state?.image_url" class = "header-image" [src] = "state?.image_url" alt = "Header image">
        <div class = "text-huge font-semibold title-color mt-[25px]" [innerHTML] = "state?.title"></div>
        <div class = "tags">
          <div class = "flex tag">
            <div class = "small-text label-color" [innerHTML] = "state?.subject"></div>
          </div>
        </div>
      </div>
      <div class = "summary">
        <!-- <div class = "title medium-text label-color heavy uppercase" [innerHTML] = "topic?.summary"></div> -->
        <div class = "text text-base body-color" [innerHTML] = "state?.summary"></div>
        <div *ngIf = "state?.field" class = "w-full flex justify-center gap-4 mt-[15px] mb-[30px]">
          <span *ngFor = "let hashTag of hashTags"
                class = "font-bold text-label text-medium" 
                [innerHTML] = "hashTag"></span>
        </div>
      </div>
      <!-- <separator-line></separator-line> -->
      <!-- <img class = "no-chat-image" src = "./assets/img/no-chat.png" alt = "No chat image"> -->
      <div *ngIf = "state?.background" 
           class = "
            transition-300 rounded-[5px] border-[1px] border-line 
            bg-surface px-[15px] pt-[10px] pb-[15px] mt-[30px]
      ">
        <div class = "heading my-[5px]">Background</div>
        <div class = "text-medium text-body {{ backgroundExpanded ? 'line-clamp-none' : 'line-clamp-3' }}">
          {{ state?.background }}
        </div>
        <span *ngIf = "state?.background?.length > 300"
              class = "text-primary text-small font-semibold" 
              (click) = "backgroundExpanded = !backgroundExpanded">
            Read {{ backgroundExpanded ? 'less' : 'more' }}
          </span>
      </div>
      <div *ngIf = "followUps?.length" class = "follow-up-questions-title medium-text label-color heavy uppercase mt-[30px]">
        Related Questions
      </div>
      <bullet-list [items] = "followUps"></bullet-list>
      <!-- <div class = "extras">
        <div class = "medium-text label-color heavy uppercase">
          Learn More
        </div> -->
        <!-- <div class = "extras-item">
          <ion-icon class = "primary-color" name = "document-text-outline"></ion-icon>
          <div class = "bold base-text body-color">Read an article</div>
          <ion-icon class = "label-color" name = "open-outline"></ion-icon>
        </div>
        <div class = "extras-item">
          <ion-icon class = "secondary-color" name = "headset"></ion-icon>
          <div class = "bold base-text body-color">Listen to podcast</div>
          <ion-icon class = "label-color" name = "open-outline"></ion-icon>
        </div>
        <div class = "extras-item">
          <ion-icon class = "error-color" name = "logo-youtube"></ion-icon>
          <div class = "bold base-text body-color">Watch a video</div>
          <ion-icon class = "label-color" name = "open-outline"></ion-icon>
        </div> -->
      <!-- </div> -->
    </ion-content>
  `,
  styleUrls: ['./smart-suggestion.page.scss'],
})
export class SmartSuggestionPage implements OnInit {
  public state: TribeInteractable = null;
  public tribe: any = {};
  private entityType: string = "";
  public hashTags: string[] = [];
  public followUps: string[] = [];
  public backgroundExpanded: boolean = false;

  public BACKGROUND_LINE_NUMBER = BACKGROUND_LINE_NUMBER;

  private onDestroyed: Subject<any> = new Subject();

  private callback = () => {};

  constructor(private navParams: NavParams, 
              private userService: UserService,
              private builderService: BuilderService,
              private modalCtrl: ModalController,
              private analyticsService: AnalyticsService,
              private topicsService: TopicsService) { 
    (window as any).topicModal = this;
  }

  ngOnInit() {
    this.state = this.navParams.get('state');
    this.entityType = this.navParams.get("entityType");
    this.callback = this.navParams.get("callback");
    this.tribe = this.navParams.get("tribe");
    // logc.indigo("Topic: ", this.topic);
    // logc.indigo("Tribe: ", this.tribe);

    this.analyticsService.trackEvent({ 
      key: "topic_modal_viewed", 
      value: 1,
      tribe_id: this.tribe?.id,
      topic: this.state?.title,
      fields: this.state?.field
    })

    this.followUps = 
      fromNullable(this.state.follow_ups)
        .map(followUpsString => followUpsString.trim())
        .map(followUps => followUps.split(/\r?\n|\r|\n/g))
        .map(followUps => followUps.filter(e => e.trim()))
        .fold(() => [], s => s);
    this.hashTags = 
      (this.state?.field || "")
        .split(',')
        .map(field => '#' + field.toLowerCase());

    this.addButton();
  }

  async ionViewDidEnter() {
    const modal = await this.modalCtrl.getTop();
    modal.onDidDismiss().then(() => this.onDestroyed.next());
  }

  async addButton() {
    const statesByTypes = {
      topic: [
        { 
          button: { text: `I find this topic interesting`, cssClass: "text-body" },
          icon: { name: "star-outline", cssClass: "text-label"}
        },
        { 
          button: { text: "Interesting", cssClass: "text-tertiary" },
          icon: { name: "star", cssClass: "text-tertiary"}
        }
      ],
      activity: [
        { 
          button: { text: `I'm open to this activity`, cssClass: "text-body" },
          icon: { name: "star-outline", cssClass: "text-label"}
        },
        { 
          button: { text: "I'm open to this activity", cssClass: "text-tertiary" },
          icon: { name: "star", cssClass: "text-tertiary"}
        }
      ]
    }
    const states = statesByTypes[this.entityType];

    logc.blue("smart suggestion callback: ", this.callback);

    await this.builderService.create(ActionButtonComponent, {
      inputs: { 
        onParentDestroyed: this.onDestroyed,
        callback: () => this.callback(),
        states: this.state.selected ? states.reverse() : states,
      },
      outputs: {}
   });
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
