import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HorizontalSliderCardComponent} from "./horizontal-slider-card.component";
import {IonicModule} from "@ionic/angular";
import { ImageRowComponent } from "../image-row/image-row.component";
import { ImageRowModule } from "../image-row/image-row.module";
import { TipComponent } from "../tip/tip.component";
import { TipComponentModule } from "../tip/tip.module";

@NgModule({
  entryComponents: [
    ImageRowComponent,
    TipComponent
  ],
  declarations: [
    HorizontalSliderCardComponent
  ],
  imports: [
    TipComponentModule,
    ImageRowModule,
    CommonModule,
    IonicModule
  ],
  exports: [HorizontalSliderCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HorizontalSliderCardModule {}
