import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ChatPage } from './chat.page';
import { GifsComponentModule } from '../../components/gifs/gifs.module';
import { EmojisKeyboardComponent } from '../../components/emojis-keyboard/emojis-keyboard';
import { PageStatusModule } from '../../components/page-status/page-status.module';
import { ChatStatusBarModule } from '../../components/chat-status-bar/chat-status-bar.module';

import { OptionsPopupComponentModule } from '../../components/options-popup/options-popup.module';
import { OptionsPopupComponent } from '../../components/options-popup/options-popup';

import { RouterModule } from '@angular/router'

import { ProfilePopupComponentModule } from '../../components/profile-popups/profile-popup.module';
import { ProfilePopupComponent } from '../../components/profile-popups/profile-popup';

import { RichContentModule } from '../../components/rich-content/rich-content.module';
import { TribeDetailsPage } from '../tribe-details/tribe-details.page';
import { TribeDetailsPageModule } from '../tribe-details/tribe-details.module';

import { TooltipPopup } from '../../components/tooltip-popup/tooltip-popup';
import { TooltipPopupModule } from '../../components/tooltip-popup/tooltip-popup.module';
import { StatusComponent} from '../../components/status/status.component';
import { StatusComponentModule } from '../../components/status/status.module';
import {LottieModule} from "ngx-lottie";
import {UseTheAppComponent} from "../../components/use-the-app/use-the-app.component";
import {UseTheAppModule} from "../../components/use-the-app/use-the-app.module";
import {PipesModule} from "../../pipes/pipes.module";
import {RemovalStatusesComponent} from "../../components/removal-statuses/removal-statuses.component";
import {RemovalStatusesModule} from "../../components/removal-statuses/removal-statuses.module";
import {DirectivesModule} from "../../directives/directives.module";
import {HammerModule} from "@angular/platform-browser";
import { StarsFeedbackComponent } from 'src/app/components/stars-feedback/stars-feedback.component';
import { StarsFeedbackComponentModule } from 'src/app/components/stars-feedback/stars-feedback.module';
import { TribeInfoComponent } from './components/tribe-info/tribe-info.component';
import { TribeInfoModule } from './components/tribe-info/tribe-info.module';
import { ChatNoticeModule } from './messages/chat-notice/chat-notice.module';
import { NotificationBadgeModule } from 'src/app/components/notification-badge/notification-badge.module';
import { ExtendExpirySectionComponentModule } from 'src/app/components/extend-expiry-section/extend-expiry-section.module';

@NgModule({
  declarations: [
    ChatPage, EmojisKeyboardComponent
  ],
  entryComponents: [
    OptionsPopupComponent,
    TribeDetailsPage,
    TooltipPopup,
    StatusComponent,
    RemovalStatusesComponent,
    ProfilePopupComponent,
    UseTheAppComponent,
    StarsFeedbackComponent,
    TribeInfoComponent
  ],
  imports: [
    ExtendExpirySectionComponentModule,
    NotificationBadgeModule,
    ChatNoticeModule,
    TribeInfoModule,
    StarsFeedbackComponentModule,
    UseTheAppModule,
    StatusComponentModule,
    RemovalStatusesModule,
    ProfilePopupComponentModule,
    PageStatusModule,
    ChatStatusBarModule,
    TribeDetailsPageModule,
    GifsComponentModule,
    IonicModule,
    CommonModule,
    RichContentModule,
    DirectivesModule,
    OptionsPopupComponentModule,
    TooltipPopupModule,
    RouterModule.forChild([{
      path: '',
      component: ChatPage,
    }]),
    LottieModule,
    PipesModule,
    HammerModule
  ],
  exports: [
    ChatPage
  ],
  schemas: [ ]
})
export class ChatPageModule {}
