import {EventEmitter, Component, Output, Input, Renderer2, ElementRef, ViewChild} from "@angular/core";
import {Subject} from "rxjs/Subject";
import { Platform } from '@ionic/angular';
import {KeyboardService} from "../../services/native/keyboard.service";
import {takeUntil} from "rxjs/internal/operators";
import {logc} from "../../shared/helpers/log";
import {UtilsService} from "../../services/utils.service";
import {move} from "@angular-devkit/schematics";

@Component({
  selector: "done-button",
  template: `
    <div #buttonContainer 
         class = "done-button-container flex items-center justify-center gap-[10px] {{ cssClass }}" 
         (click) = "emit()">
      <ion-icon *ngIf = "icon" [name] = 'icon?.name' [class] = "icon?.cssClass"></ion-icon>
      <span class = "done-button-label large-text bold" [innerHTML] = "text"></span>
    </div>
  `,
  styleUrls: ["done-button.component.scss"]
})
export class DoneButtonComponent {
  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClose') onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input("onParentDestroyed") onParentDestroyed: Subject<any>;
  @Input("text") text = '';
  @Input("cssClass") cssClass: string = 'text-primary';
  @Input("icon") icon: { name: string, cssClass: string } = null;

  @ViewChild("buttonContainer") buttonContainer;

  // Provided by builder service
  @Input("destroySelf") destroySelf = () => {};

  private unsubscribe: Subject<any> = new Subject<any>();
  private androidNotchHeight: number;

  constructor(private keyboardService: KeyboardService,
              private utils: UtilsService,
              private platform: Platform,
              private renderer: Renderer2) {
    if(window['AndroidNotch']) {
      window['AndroidNotch'].getInsetTop(success => this.androidNotchHeight = success);
    }
  }

  ngOnInit() {
    this.onParentDestroyed.subscribe(() => this.destroySelf());
    this.keyboardService
      .onKeyboardChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((change) => {
        if(this.platform.is("ios")) {
          this.moveButton(change);
        }
      })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  moveButton(change) {
    let bottom = 0;
    if(change.state == 'opened') {
      bottom += this.androidNotchHeight + change.data["keyboardHeight"];
    }
    this.renderer.setStyle(this.buttonContainer.nativeElement, "bottom", `${ bottom }px`)
  }

  emit() {
    this.onClick.emit(null);
  }
}
