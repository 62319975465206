import { Injectable } from '@angular/core';
import {RemoteConfigService} from "./remote-config.service";
import {PusherService} from "./pusher.service";
import {Config} from "./config/config";
import {logc} from "../shared/helpers/log";
import {takeUntil} from "rxjs/internal/operators";
import {Subject} from "rxjs/Subject";
import {AnalyticsService} from "./analytics/analytics.service";

const PUSHER_RECONNECTION_EXPERIMENT_TIMEOUT = 6000; // ms

enum PusherExperimentGroups {
  Control= "Control",
  Treatment = "Treatment"
}

@Injectable({
  providedIn: 'root'
})
export class ExperimentsService {

  private pusherExperimentTimeout: any = null;
  private pusherExperimentRunningSource: Subject<any> = new Subject();
  private experimentGroup: string = '';

  private serviceStartTime: number = null;

  private id: string = "::EXPERIMENT SERVICE::";

  public initialized: boolean = false;

  constructor(private remoteConfigService: RemoteConfigService,
              private pusherService: PusherService,
              private config: Config,
              private analyticsService: AnalyticsService) {

  }

  async init(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.serviceStartTime = Date.now();
      logc.green("Experiments service started at: ", new Date(this.serviceStartTime));
      logc.orange(`${ this.id } ** Experiments service init! **`);
      logc.info("this.config.get(\"pusherReconnectionExperimentDone\"): ", this.config.get("pusherReconnectionExperimentDone"));
      if(!this.config.get("pusherReconnectionExperimentDone")) {
        await this.startPusherExperiment();
      }

      this.initialized = true;
      resolve();
    })
  }

  async startPusherExperiment() {
    const pusherExperiment: any =
      await this.remoteConfigService.getObject("pusher_reconnection_reloading_experiment") || {};
    logc.info(`${ this.id } Remote config pusher_reconnection_reloading_experiment: `, pusherExperiment);
    if(pusherExperiment?.active && !this.config.get("pusherReconnectionExperiment")) {
      logc.orange(`${ this.id } Starting reconnection experiment`);
      this.pusherExperimentTimeout = setTimeout(() => {
        this.setupPusherExperimentGroup();
        this.config.set("pusherReconnectionExperiment", {
          group: "Treatment",
          seconds_to_connect: Date.now()
        });
        if(this.experimentGroup == PusherExperimentGroups.Treatment) {
          this.pusherExperimentRunningSource.next();
          this.pusherExperimentRunningSource.complete();
          (window as any).location.reload();
        }
        this.pusherExperimentTimeout = null;
      }, pusherExperiment?.timeout || PUSHER_RECONNECTION_EXPERIMENT_TIMEOUT)
    }

    this.pusherService
      .onStateChanged
      .pipe(takeUntil(this.pusherExperimentRunningSource))
      .subscribe((states) => {
        logc.error(`${ this.id } experiments.service.ts Pusher state changed: `, states.current);
        if(states.current == 'connected') {
          if(this.pusherExperimentTimeout) {
            logc.info(`${ this.id } Took ${ this.getTime({ seconds_to_connect: this.serviceStartTime })}s to connect pusher `);
            clearTimeout(this.pusherExperimentTimeout)
          } else {
            const pusherExperimentData = this.config.get("pusherReconnectionExperiment");
            if(pusherExperimentData) {
              const data = {
                ...pusherExperimentData,
                ... {
                  seconds_to_connect: this.getTime(pusherExperimentData)
                }
              }
              logc.info(`${ this.id } Experiment message: `, data);
              this.analyticsService.trackEvent({
                key: "pusher_reconnection_reloading_experiment",
                value: 1,
                ...data
              })
            }
          }
          this.config.set("pusherReconnectionExperimentDone", true);
        }
      })
  }

  private getTime(data) {
    return (Date.now() - data.seconds_to_connect) / 1000;
  }

  private setupPusherExperimentGroup() {
    this.experimentGroup = Math.random() > 0.5
      ? PusherExperimentGroups.Control
      : PusherExperimentGroups.Treatment;
    logc.info(`${ this.id } User put in: `, this.experimentGroup);
  }

}
