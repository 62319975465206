import {Component, NgZone, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';

@Component({
  selector: 'app-checkmark',
  templateUrl: './checkmark.component.html',
  styleUrls: ['./checkmark.component.scss']
})
export class CheckmarkComponent implements OnInit {

  public animationConfig: any = {
    path: './assets/gif/tribe-started-joined.json',
    renderer: 'canvas',
    autoplay: true,
    loop: false,
  };

  public animation;

  constructor(public modalCtrl: ModalController,
              private ngZone: NgZone) { }

  ngOnInit() {
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => this.animation.stop());
    }, 2000);
  }

  handleAnimation(anim) {
    this.animation = anim;
    this.ngZone.runOutsideAngular(() => this.animation.play());
  }

}
