import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "getTribemateName"
})

export class GetTribemateNamePipe implements PipeTransform {
  transform(userId: any, tribe): any {
    if(!tribe) return;
    if(userId == -5) return "";
    if(userId == tribe.tribe_user.id) return 'yourself'

    return tribe.users.find(u => u.id == userId)?.first_name || "";
  }
}
