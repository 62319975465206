export const DEFAULT_NOTIFICATION_SETTINGS = {
  'push-tribes-new_tribe_invites': true,
  'sms-tribes-new_tribe_invites': true,
  'email-tribes-new_tribe_invites': true,
  'tribes-tribe_updates': true,
  'tribes-pending_approvals': true,
  'tribes-tribe_reminders': true,
  'messages-chat_messages': true,
  'messages-message_reactions': false,
  'tips_and_reminders-profile_suggestions': true,
  'tips_and_reminders-level_reminders': true,
  'tips_and_reminders-general_tips': true,
  'news-announcements': true,
  'news-promotions': true,
  'other-account_matters': false
};
