<ion-menu side = "start" menuId = "first" contentId = "main" swipe-gesture = "true">
  <ion-content>
    <div class = "menu-container">
      <div class = "status-container">
        <div class = "avatar" (click) = "openPage('status_page')">
          <img [src] = "userImage" alt = "Avatar">
        </div>
        <div class = "text-content" (click) = "openPage('status_page')">
          <div class = "user-name truncated title-font-heavy">{{userName}}</div>
          <div class = "user-status">
            <div class = "point {{ matchingStatus }}"></div>
            <div class = "status-text">{{ matchingStatus | titlecase | matchingStatus }}</div>

            <div #hint *ngIf = "!sideMenuTipShown" class = "blue-tooltip">
              Your Status
              <div class = "arrow-up"></div>
            </div>

          </div>
        </div>
      </div>

      <div *ngIf = "showLevels()" class = "next-level-container" (click) = "openLevel()">
        <div class = "radar-section">
          <img src = "./assets/img/radial-progress.png" alt = "Radar">
        </div>
        <div class = "text-section">
          <div class = "next-level-title title-color title-font">
            {{nextLevel}}
          </div>
          <div class = "next-level-desc label-color">
            Boost your match quality.
          </div>
        </div>
        <div *ngIf = "hasReward()" class = "crate-section">
          <img src = "./assets/img/chest.svg" alt = "Crate">
        </div>
      </div>

      <div class="options-container">

        <div class = "account-section">
          <div class="section-title heading">ACCOUNT</div>
          <div class = "options-list">
            <div *ngFor = "let option of accountOptions" (click) = "option.action()" class = "option {{option.cssClass}}">
              <ion-icon class = "option-icon label-color" [name] = "option.icon"></ion-icon>
              <div class = "option-content">
                <div class = "option-title title-color title-font">{{option.title}}</div>
                <div class = "option-desc help-text label-color small-text" [innerHTML] = "option.description"></div>
              </div>
              <ion-icon *ngIf = "option.warning" class = "warning" name = "alert-circle-outline"></ion-icon>
            </div>
          </div>
        </div>

        <div class="settings-section">
          <div class="section-title heading">PREFERENCES</div>
          <div class="options-list">
            <div *ngFor = "let option of settingsOptions" (click) = "option.action()" class = "option">
              <ion-icon class = "option-icon label-color" [name] = "option.icon"></ion-icon>
              <div class = "option-content">
                <div class = "option-title title-color title-font">{{option.title}}</div>
                <div class = "option-desc help-text label-color small-text" [innerHTML] = "option.description"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="help-section">
          <div class="section-title heading">HELP</div>
          <div class="options-list">
            <div *ngFor = "let option of helpOptions" (click) = "option.action()" class = "option">
              <ion-icon class = "option-icon label-color" [name] = "option.icon"></ion-icon>
              <div class = "option-content">
                <div class = "option-title title-color title-font">{{option.title}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="logo-container">
          <img class = "logo" src = "./assets/img/we3-logo-menu.png" alt = "Logo">
        </div>

        <div class="leaving-container">
          <div class="options-list">
            <div class = "option" [class.disabled-option] = "sessionService.stateMachine.state != 'ready'"(click) = "openLogoutMenu()">
              <ion-icon class = "option-icon label-color" name = "exit-outline"></ion-icon>
              <div class = "option-content">
                <div class = "option-title">Log Out</div>
              </div>
            </div>
            <div class = "option delete-account" (click) = "deleteAccount()">
              <ion-icon class = "option-icon" name = "trash-outline"></ion-icon>
              <div class = "option-content">
                <div class = "option-title">Delete my account</div>
              </div>
            </div>
          </div>
        </div>

      </div>



    </div>
  </ion-content>
</ion-menu>
