<div #scroll class = "horizontal-images" >
  <div class = 'giphy'
       *ngFor = "let gif of gifs"
       [loadSrc] = "gif?.mini_url"
       [style.width] = "gif?.mini_width + 'px'"
       (click) = "select(gif.url)">
    <ion-spinner></ion-spinner>
  </div>
</div>

<img id = 'powered-by' src='./assets/img/powered-by-giphy.png'>

<div class = "search-container">
  <ion-icon class = "back-button" name = "arrow-back" slot = 'start' (click) = "back($event)"></ion-icon>
  <ion-input #searchbar
             [(ngModel)] = "searchValue"
             (ionInput) = "search($event)"
             placeholder = 'Search for a gif'>
  </ion-input>
  <ion-icon *ngIf = "searchValue"
            (click) = "cleanSearch()"
            class = 'clear-button'
            name = "close-circle-outline"></ion-icon>
</div>
