<ion-content fullscreen class = "state-input-page">
  <div class = "state-2-content">
      <ion-icon class = "arrow-back" name = "arrow-back" (click) = "navBack()"></ion-icon>
      <div class = "form-group">
          <div class = "geo-container">
            <input id = "geo-input"
                   inputAutoFocus
                   placeholder = "Search city..."
                   spellcheck = "off"
                   type = "text"
                   class = "state-2-input"
                   #searchBox>
              <div #geoSubst class = " state-2-input geo-sub" [hidden]="subHidden"></div>
          </div>
      </div>

  </div>
  <div id = "powered-by-google">Powered by Google</div>
</ion-content>
