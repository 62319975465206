import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TooltipPopup } from './tooltip-popup';

@NgModule({
  declarations: [
    TooltipPopup,
  ],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [
    TooltipPopup
  ]
})
export class TooltipPopupModule {}
