import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'textFormatTribeStatus'
})
export class TextFormatTribeStatusPipe implements PipeTransform {

  transform(status: string) {
    return { in_progress: 'In Progress' }[ status ] || status;
  }

}
