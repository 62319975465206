import {Component, Input} from "@angular/core";
import {UserNotificationService} from "../../services/user-notification.service";
import {takeUntil} from "rxjs/internal/operators";
import {Subject} from "rxjs/Subject";
import {Config} from "../../services/config/config";

@Component({
  selector: "topic-tribe-avatar",
  template: `
    <div class = "tribe-avatar">
      <ng-lottie *ngIf = "isSetupForNotifications || inProgress" 
                 class = "search-ongoing-animation"
                 [options] = "lottieSearchOngoing"
                 width = "78px"
                 height = "78px"
                 (animationCreated) = "handleAnimation($event)">
      </ng-lottie>
      <div class = "image">
        <div class = "initiator">
          <img class = "initiator-image"
               [src] = "tribe.picture || defaultPicture"
               (error) = "tribe.picture = ''"
               alt = "Topic">
        </div>
        <div class = "status-container">
          <div *ngIf = "isSetupForNotifications; else warning" 
               class = "status blue">
            <ion-icon name = "time-outline"></ion-icon>
          </div>
          <ng-template #warning>
            <div class = "pulse-box">
              <div class = "pulse"></div>
              <div class = "pulse"></div>
              <div class = "pulse"></div>
            </div>
            <div class = "status yellow">
              <ion-icon name = "warning"></ion-icon>
            </div>
          </ng-template>
        </div>
      </div>
      <div class = "info-section">
        <div class = "title tribe-status text-small text-body font-bold" [innerHTML] = "tribe.name">
        </div>
<!--        <div class = "topic-status"[innerHTML] = "tribe.status"></div>-->
      </div>
    </div>
  `,
  styleUrls: ["topic-tribe-avatar.component.scss"]
})
export class TopicTribeAvatarComponent {
  @Input() inProgress: boolean = false;
  @Input("tribe") tribe: any = {
    icon: {
      name: ''
    },
    name: '',
    picture: ''
  };

  public defaultPicture: string = '';

  public lottieSearchOngoing: any = {
    path: "./assets/gif/search_ongoing.json",
    renderer: 'canvas',
    autoplay: true,
    loop: false
  }

  private unsubscribe: Subject<any> = new Subject();
  public isSetupForNotifications: boolean = false;

  constructor(private userNotificationService: UserNotificationService,
              private config: Config) {}

  ngOnInit() {
    this.defaultPicture = this.config.getDefaultAvatar();
    // this.userNotificationService
    //   .onUserNotifiableStatusChanged
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe((status: boolean) => {
    //     this.isSetupForNotifications = status;
    //   })
    this.isSetupForNotifications = true;
  }

  handleAnimation(animation) {
    animation.play();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
