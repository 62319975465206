<form class = "school-form" [formGroup] = "schoolForm" (ngSubmit) = "submit()">
  <ion-grid>
    <ion-row>
      <ion-item class = "form-input" >
        <ion-label position="stacked">School</ion-label>
        <ion-input #schoolInput type="text" formControlName="school"
        placeholder = "Enter School (optional)" autofocus>
        </ion-input>
      </ion-item>
    </ion-row>
  </ion-grid>
  <div class="alert-button-group">
    <ion-button clear type = "button" (click) = "cancel()">Cancel</ion-button>
    <ion-button clear
                (click) = "submit()"
                [disabled] = "!schoolForm.valid"
                class = "update">Update</ion-button>
  </div>
</form>
