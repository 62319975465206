import { ThisReceiver } from "@angular/compiler";
import { Component, Input, SimpleChanges } from "@angular/core";
import { getWeekYearWithOptions } from "date-fns/fp";

import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AudiencesService } from "src/app/services/analytics/audiences.service";
import { ChatService } from "src/app/services/chat.service";
import { Config } from "src/app/services/config/config";
import { TribeService } from "src/app/services/data/tribe.service";
import { TribesService } from "src/app/services/data/tribes.service";
import { DispatcherService } from "src/app/services/dispatcher.service";
import { IconStatus, IconStatusService } from "src/app/services/icon-status.service";
import { FIRST_TIME_TOOLTIP_CLOSING_TIMEOUT } from "src/app/shared/constants/constants";
import { logc } from "src/app/shared/helpers/log";

@Component({
  selector: "tribe-info",
  template: `
    <div *ngIf = "tribe?.total_messages < MESSAGES_TO_LOAD" 
         class = "w-full text-center"
         [style.margin-top.px] = "hasExpiries ? 180 : 55">
      <div *ngIf = "!isDirectMessageGroup">
        <div class = "font-semibold text-xl text-body my-[12px]">
          Group Started
        </div>
        <div *ngIf = "tribe?.created_at" class = "text-base text-body font-subtitle italic">
          {{ tribe?.created_at | map:fromNow }}
        </div>
      </div>
      <div class = "flex justify-evenly my-[25px]">
        <chat-avatar *ngFor = "let user of usersData" 
                     (onClick) = "openProfile($event)"
                     [tribe] = "tribe" 
                     [user] = "user">
          <icon-plate *ngIf = "user.iconStatus"
                      [name] = "user.iconStatus.name"
                      [iconCssClass] = "user.iconStatus.cssClass"
                      size = "big"
                      cssClass = "bottom-0 right-0"></icon-plate>
        </chat-avatar>
      </div>
    </div>

    <chat-tip *ngIf = "showFirstTribeTooltip" 
              (onClose) = "closeTip($event)" 
              (onButtonClick) = "openInfoModal('joiningConditions')"></chat-tip>

    <div class = "m-[15px] text-center">
      <div class = "text-large text-body font-semibold my-[5px]">Tip:</div>
      <div class = "text-small text-label">
        Chat as if you were close friends already, no need to be extra polite.
      </div>
    </div> 
  `,
  styleUrls: ['tribe-info.component.scss']
})
export class TribeInfoComponent {
  @Input() tribe: any = {};

  public MESSAGES_TO_LOAD = 10;
  public usersData: any[] = [];
  public isDirectMessageGroup: boolean = false;
  public iconStatus: IconStatus = null;
  public showFirstTribeTooltip: boolean = false;
  private chatWasVisitedOnce: boolean = false;
  public showStartersTip: boolean = false;
  public hasExpiries: boolean = false;

  private unsubscribe: Subject<any> = new Subject();

  constructor(private iconStatusService: IconStatusService,
              private dispatcherService: DispatcherService,
              private chatService: ChatService,
              private tribeService: TribeService,
              private tribesService: TribesService,
              private audienceService: AudiencesService,
              private config: Config) {
                
  }

  ngOnInit() {
    this.tribesService
      .onTribeChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((tribe) => {
        this.hasExpiries = this.tribeService.getExpiries(tribe).length > 0;
      });

    this.chatWasVisitedOnce = this.config.getFlag('chatWasVisitedOnce', false);
    if(this.chatWasVisitedOnce && !this.config.get("howToGetInvitedTooltipTapped")) {
      setTimeout(() => this.showFirstTribeTooltip = true, 1000);
    }
    this.isDirectMessageGroup = this.tribe?.type == "DirectMessage";
    this.hasExpiries = this.tribeService.getExpiries(this.tribe).length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.usersData = this.tribe.users.map(user => ({
      ...user,
      iconStatus: this.iconStatusService.getIconInfo(user, this.tribe, this.config.get("matchPreferences"))
    }))
  }

  fromNow(date) {
    return moment(date).fromNow();
  }

  openInfoModal( modalName ) {
    setTimeout(() => this.closeTip({ manually: false }), FIRST_TIME_TOOLTIP_CLOSING_TIMEOUT);

    let options: any = {
      type: modalName
    };

    if(modalName === 'joiningConditions') {
      this.config.set("howToGetInvitedTooltipTapped", true);
    }

    if(modalName === 'joiningConditions' &&
      !this.config.getFlag('joinGroupsFreeShownInChat') &&
      !this.audienceService.inScheduledGroup())
    {
      options.closingCallback = () => this.showHeadsUpPopup();
      this.config.setFlag("joinGroupsFreeShownInChat", true);
    }

    this.dispatcherService.newInfoModalSource.next( options );
  }

  showHeadsUpPopup(): void {
    setTimeout(() => {
      this.chatService.showStartersTip();
      this.chatService.showHighlightsTip();
      this.dispatcherService.newPopupSource.next({ popupName: "richProfilePopup" });
    }, 1000);
  }

  closeTip({ manually }) {
    this.showFirstTribeTooltip = false;
    this.config.set("howToGetInvitedTooltipTapped", true);
    if(manually) {
      this.showHeadsUpPopup();
    }
  }

  openProfile(user): void {
    if(user.id == this.config.get("id")) return;

    this.dispatcherService.newModalSource.next({
      modalName: 'profile',
      options: {
        isEditable: false,
        userId: user.id,
        profileId: user.profile_id,
        user: user,
        tribeId: this.tribe.id,
        tribe: this.tribe,
        fromHighlights: true,
        notificationName: this.iconStatusService.getIconStatusName( user )
      },
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}