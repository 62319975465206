import { Injectable } from '@angular/core';
import { ApiService } from './data/api.service';
import { Config } from './config/config';
import { environment } from '../../environments/environment';
import { AppService } from "./app";
import { SentryService } from "./performance/sentry.service";
import { ConsoleService } from "./performance/console.service";
import {log, logc} from "../shared/helpers/log";
import { Observable } from "rxjs";
import { Subject } from "rxjs/Subject";
import { NgZone } from '@angular/core';
import {PusherErrors} from "../shared/enums/pusher-errors.enum";

interface PusherState {
  previous: string;
  current: string;
}

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: any = null;
  public onEvent: any;
  public channels = {};
  public state;

  private resumeTimeout: any = null;
  private unavailableTimeout: any = null;

  public sessionState: string = '';

  public onStateChanged: Observable<PusherState> = null;
  public stateChangedSource: Subject<PusherState> = new Subject();

  private serviceStartTime: number = Date.now();

  constructor(public api: ApiService,
              public errorTrackingService: SentryService,
              public ngZone: NgZone = null,
              public config: Config) {
    if(this.config.isDev || this.config.isStaging) {
      (<any> window).pusher = this;
    }

    this.onStateChanged = this.stateChangedSource.asObservable();
  }

  async init(): Promise<void> {
    return new Promise((resolve, reject) => {
      logc.green("Pusher service started at: ", new Date(this.serviceStartTime));
      this.pusher = new (<any>window).Pusher(environment.pusherApiKey,
        {
          encrypted: true,
          cluster: environment.pusherClusterName,
          authEndpoint: environment.domain + '/api/v1/users/channel/auth',
          auth: {
            headers: {
              'Authorization': this.config.get('apiKey')
            }
          }
        });

      (window as any).Pusher.log = function(message) {
        console.log( message );
      }

      this.trackPusherStateChanges();
      this.trackPusherErrors();
      log("-- Pusher initialized! --", { color: "#FFFFFF", background: "#2F0E50" });
      resolve();
    })
  }

  reset() {
    this.disconnect();
    this.pusher = null;
  }

  disconnect() {
    this.pusher.disconnect();
  }

  connect() {
    this.pusher.connect();
  }

  nth = 0
  async getInstance() {
    return new Promise((resolve, reject) => {
      if(this.api.isOffline()) {
        return reject();
      }

      if(this.pusher) {
        if(this.nth > 0) {
          console.log(`got a pusher instance after ${this.nth} tries.`);
        }
        this.nth = 0;
        resolve(this.pusher);
      } else {
        this.nth++;
        console.log(`no pusher instance after ${this.nth} tries.`);
        setTimeout( () => { 
          this.getInstance().then(resolve, reject);
        }, 200);
      }
    });
  }

  stateChangeTimeout: any;
  trackPusherStateChanges() {
    this.pusher.connection.bind('state_change', function(states) {
      this.stateChangedSource.next({
        previous: states.previous,
        current: states.current
      })

      this.ngZone.run(() => this.state = states.current);
      console.log(`### PUSHER STATE CHANGED ### ${states.previous} -> ${states.current}, current: ${this.state}`);
    }, this)
  }

  trackPusherErrors() {
    this.pusher.connection.bind('error', function(err) {
      console.log('--- PUSHER SERVICE ERROR DETECTED: ', err);

      const errorCode = err.error?.data?.code;
      if (errorCode) {
        console.log(`-- Pusher error code: ${ errorCode }`);
      }
    }, this)
  }

  private handlePusherError( err ) {
    const errCode = err.error?.data?.code;
    console.log(`-- Pusher error code: ${ errCode }`);

    switch(errCode) {
      case PusherErrors.ReconnectImmediately:
        try {
          this.connect();
        } catch (e) {
          console.log('-- Pusher reconnection error: ', e);
        }
        break;
    }
  }

}
