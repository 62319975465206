import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';

@Injectable({
  providedIn: 'root',
})
export class EmojiService {
  public recent: any[] = [];
  public data: any = null;
  public groups: any[] = [];
  public loaded = false;

  constructor(private http: HttpClient,
              private config: Config) {
  }

  async loadRecentlyUsed() {
    await this.config.load();
    let saved = this.config.getFlag('recent_emojis');
    let recent = []; 
    if(this.hasRecent()) {
      saved.forEach( r => {
        recent.push({ u: r }); 
      });
    }

    this.data['recent'] = recent;
  }

  hasRecent() {
    const recent = this.config.getFlag('recent_emojis', []);
    return recent && recent.length > 0
  }

  loadEmojis(group) {
    return new Promise((resolve, reject) => { 
      resolve(this.data[group.k]);
    });
  }

  load(): Promise<void> {
    if(this.data) {
      this.loadRecentlyUsed();
      return new Promise((resolve, reject) => resolve() );
    }

    return new Promise((resolve, reject) => { 
      this.http.get('./assets/data/emojis.json')
        .toPromise()
        .then( (data:any) => {
          this.groups = data.groups;
          this.data = data.emojis;
          this.loadRecentlyUsed();
          this.loaded = true;
          resolve();
        }, reject );
    });
  }
}

