import { Injectable } from '@angular/core';

declare const AWS: any;

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  private bucket: any;
  public awsAccessKeyId: string = 'AKIAJEVIF72XB46E7EKQ';
  public awsSecretAccessKey: string = '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';
  public awsRegion: string = 'us-east-1';
  public awsBucket: string = 'me3.profile-pictures'

  constructor() {
    AWS.config.update({
      accessKeyId: this.awsAccessKeyId,
      secretAccessKey: this.awsSecretAccessKey
    });
    AWS.config.region = this.awsRegion;
    this.bucket = new AWS.S3({
      params: {
        Bucket: this.awsBucket
      }
    });
  }

  base64ToByteArray(base64String) {
    let binaryString = atob(base64String);
    let byteArray = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++)  {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    //byteArray.type = this.mime(base64String);
    return byteArray;
  }

  uploadBase64ToS3(key, base64String) {
    let byteArray = this.base64ToByteArray(base64String);
    return this.uploadToS3(key, byteArray);
  }

  uploadToS3(key, byteArray): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        Key: key + '.jpg',
        Body: byteArray,
        ContentType: 'image/jpeg',
        ContentEncoding: 'base64',
        CacheControl: 'max-age=86400'
      };

      this.bucket.upload(params, (err, data) => {
        if(err) {
          reject(err);
        } else {
          resolve(data.Location);
        };
      });
    });
  }
}
