import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { isThisISOWeek, nextThursday } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { mergeMap, startWith, switchMap, map, withLatestFrom, takeUntil } from 'rxjs/operators';
import { Config } from 'src/app/services/config/config';
import { SearchService } from 'src/app/services/data/search.service';
import { FeedbackService } from 'src/app/services/popups/feedback.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DEFAULT_AVATAR_PATH } from 'src/app/shared/constants/constants';
import { logc } from 'src/app/shared/helpers/log';
import { getDayPrefix, getHoursMeridiem } from 'src/app/shared/helpers/time-helpers';
import { environment } from 'src/environments/environment';

const DEFAULT_GROUP_NUMBER = 1;
@Component({
  selector: 'app-fan-matching-search-details',
  template: `
    <ion-content class = "padding-20">
      <ion-icon class = "text-body text-2xl absolute left-[10px] top-[15px]" 
                name = "chevron-back-outline"
                (click) = "openPreviousModal()"></ion-icon>
      <div class = "flex w-full items-center flex-col gap-[15px] mt-[15px]">
        <img class = "h-[80px] w-[80px] rounded-[10px]"
             [src] = "influencerImage" 
             alt = "Image Link">
        <div class = "font-semibold text-large text-body">
          Matching fans in 
          <span class = "text-primary font-heavy" (click) = "showLocationTip()">
            {{ userArea }}
          </span>
      </div>
      </div>
      <div class = "heading mt-[12px] mb-[5px]">
        Next matching date
      </div>
      <div *ngIf = "date; else emptyState" class = "rounded-[10px] border-line border-[1px] p-[15px] 
                    duration-300 flex justify-start items-center gap-[15px]" 
           [class.border-primary] = "dateSelected"
           (click) = "selectDate()">
        <div class = "rounded-[50%] w-[18px] h-[18px] flex 
                      items-center justify-center p-[3px] border-line border-[1px]">
          <div class = "w-full h-full bg-primary rounded-[50%] duration-300 opacity-0" 
               [class.opacity-100] = "dateSelected"></div>
        </div>
        <div>
          <div class = "text-base text-body font-semibold"> {{ dayPrefix }} Thursday evening </div>
          <date-label [date] = "date"></date-label>
        </div>
      </div>
      <ng-template #emptyState>
        <div class = "w-full rounded-[10px] bg-bg px-[15px] py-[30px] 
                      border-line border-[1px] text-small text-center 
                      text-label mt-[10px] mb-[20px]">
          No dates are currently scheduled in your area
        </div>
      </ng-template>
      <notice *ngIf = "date">
        On this date, We3 will match fans in group chats of 3. It’s not a virtual or in-person meeting.
      </notice>

      <div *ngIf = "!inScheduledFansMatching" class = "groups-selector-container">
        <span *ngFor = "let group of [1,2,3]"
              [class.selected] = "(groupsNumberChanged | async) == group && group > fansMeetupsScheduled"
              [class.plus-feature] = "!isPlus && group != 1"
              [class.pointer-events-none] = "fansMeetupsScheduled >= group"
              [class.opacity-50] = "fansMeetupsScheduled >= group"
              (click) = "selectGroupsNumber(group)">
          <img *ngIf = "group != 1" src = "./assets/img/crown.png" alt = "Crown">
          {{ group }} group{{ group > 1 ? 's' : '' }}
        </span>
      </div>
      
      <button-with-success-state [activate] = "inScheduledFansMatching"
                                 [activatedText] = "'You are already signed up'" 
                                 [disabled] = "!dateSelected || !date" 
                                 [cssClass] = "(!isPlus && groupsNumber > 1) ? 'golden-button' : ''"
                                 (onClick) = "execute()">
        {{ buttonText | async }}
      </button-with-success-state>
    </ion-content>
  `,
  styleUrls: ['./fan-matching-search-details.component.scss'],
})
export class FanMatchingSearchDetailsComponent implements OnInit {
  @Input() onButtonClicked;
  public date: string = null;
  public groupsNumber: number = 1;
  public dateSelected: boolean = true;
  public userArea: string = null;
  public inScheduledFansMatching: boolean = false;
  private influencer: string = null;
  public meetupHours: string = null;
  public influencerImage: string = null;
  public dayPrefix: string = "This";
  public isPlus: boolean = false;
  public initialGroupsNumber: number = DEFAULT_GROUP_NUMBER;

  public buttonText: Observable<string>;
  public groupsNumberChanged: BehaviorSubject<number> = new BehaviorSubject(this.initialGroupsNumber);
  private meetupHoursChanged: Subject<number | string> = new Subject();

  public fansMeetupsScheduled: number = null;

  constructor(private config: Config, 
              private searchService: SearchService,
              private utils: UtilsService,
              private modalCtrl: ModalController,
              private feedbackService: FeedbackService) { 
    if(environment.name == 'development') {
      (window as any).fanSearchDetails = this;
    }
  }

  selectGroupsNumber(number) {
    this.groupsNumber = number;
    this.groupsNumberChanged.next(number);
  }

  ngOnInit() {
    this.fansMeetupsScheduled = this.searchService.getFansSearches().length;
    this.groupsNumberChanged.next(this.fansMeetupsScheduled + 1);
    this.groupsNumber = this.fansMeetupsScheduled + 1;
    this.isPlus = this.config.isPlus();
    const matchDate = this.config.get("partner")['next_match_date'];
    this.date = matchDate;
    this.meetupHours = getHoursMeridiem(matchDate);
    
    this.buttonText = combineLatest(
      this.groupsNumberChanged.pipe(startWith(DEFAULT_GROUP_NUMBER)),
      this.meetupHoursChanged.pipe(startWith(this.meetupHours)),
    ).pipe(
      map(([groups, hours]) => {
        if(groups == 1) {
          return `Sign up for Thursday @ ${ hours }`;
        } else {
          return `Sign up for ${ groups } groups`;
        }
      })
    )
    this.dayPrefix = getDayPrefix(matchDate);
    this.influencer = this.config.getPartnerName();
    this.influencerImage = this.config.getPartnerImage();
    this.userArea = this.config.getUserCity() || "your area";
    this.inScheduledFansMatching = this.searchService.inScheduledFanMatching();
  }

  showLocationTip() {
    this.utils.showLocationTip();
  }

  selectDate() {
    this.dateSelected = !this.dateSelected;
  }

  execute() {
    logc.crimson("this.groupsNumber: ", this.groupsNumber);
    this.onButtonClicked.next({ groupsNumber: this.groupsNumber });
  }

  openPreviousModal() {
    this.modalCtrl.dismiss();
    this.searchService.openFansSearchPicker();
  }
}
