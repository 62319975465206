<ion-content>

  <div class="content-container">

    <img src="./assets/img/firewall.png" alt="Firewall">
    
    <div class="page-title">
      Something’s up with your network
    </div>

    <div class="page-subtitle">
      Looks like a firewall is blocking We3. Please update your settings or try a different network.
    </div>

    <ion-button class = 'ionic-button turquoise-gradient-btn'
                large
                shape = "round"
                (click) = "tryAgain()">
      Try again
    </ion-button>

  </div>

</ion-content>
