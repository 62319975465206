import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileAboutMePopupComponent } from './profile-about-me-popup';

@NgModule({
  declarations: [
    ProfileAboutMePopupComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ProfileAboutMePopupComponent
  ]
})
export class ProfileAboutMePopupComponentModule {}
