import * as moment from "moment";
import {DAILY_TRIBES_REFRESH_HOUR} from "../constants/constants";
import {environment} from "../../../environments/environment";
import {addHours, differenceInDays, endOfDay} from "date-fns";
import * as dateFns from "date-fns";
import * as dateFnsTz from "date-fns-tz";
import millisecondsToHours from "date-fns/millisecondsToHours";
import parseISO from "date-fns/parseISO";

(window as any).moment = moment;
export const DEFAULT_SLIDER_TRANSITION_TIME = 500;
const ONE_DAY_MS = 86400000;
export const REFRESH_HOUR = 7; // 0-24
enum ExpiryTimeMs {
  Initiator = 3 * ONE_DAY_MS,
  Invitee = ONE_DAY_MS
}

export const getExpiryTime = (user) => user.is_initiator ? ExpiryTimeMs.Initiator : ExpiryTimeMs.Invitee;
export const getDailyTribeRefreshTime = () => {
  const dayStart = moment().startOf("day");
  const refreshTime = moment().startOf('day').add(DAILY_TRIBES_REFRESH_HOUR, 'hours');
  const currentTime = moment();
  return currentTime.isBetween(dayStart, refreshTime)
    ? refreshTime.valueOf()
    : nextDayAt(DAILY_TRIBES_REFRESH_HOUR).valueOf();
}

export const hoursLeft = (date: string) => millisecondsToHours(new Date(parseISO(date)).getTime() - Date.now());

/**
 *
 * @param date - added for testing purposes, will remove next version
 */
export const getDailyTribeRefreshTimeFallback = (date = new Date()) => {
  let newDate = date || new Date();

  let hourNow = newDate.getHours()
  if(hourNow > 0 && hourNow < DAILY_TRIBES_REFRESH_HOUR) {
    newDate.setHours(DAILY_TRIBES_REFRESH_HOUR);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate.getTime();
  } else {
    return nextDayAtFallback(DAILY_TRIBES_REFRESH_HOUR);
  }
}
/**
 *
 * @param hour - Next day hour; e.g. 15 means time next day at 15:00 (3pm)
 */
export const nextDayAtFallback =
  (hour = DAILY_TRIBES_REFRESH_HOUR) => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(hour);
    date.setMinutes(0);
    date.setSeconds(0);
    return date.getTime();
  }

/**
 *
 * @param hour - Next day hour; e.g. 15 means time next day at 15:00 (3pm)
 */
export const nextDayAt =
  (hour = DAILY_TRIBES_REFRESH_HOUR) =>
      moment()
        .endOf("day")
        .add(hour, "hours");

export const nextDayAtISO = (hour) => nextDayAt(hour).toISOString();

export const todayAt = (hour) => 
  dateFns.addHours(
    dateFns.startOfDay(new Date()), hour);

export const getWeekDay = (date) => {
  if(!date) return "Thursday";
  return weekdayNames[(new Date(date)).getDay() - 1];
}

export const getDayPrefix = (date: string) => {
  return dateFns.startOfWeek(dateFns.parseISO(date)) > new Date() ? "Next" : "This";
}

const weekdayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const monthNames = [ "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December" ];

const addDatePostfix = (day) => {
  let postfix = "th";
  if(day == 1) postfix = "st";
  if(day == 2) postfix = "nd";
  if(day == 3) postfix = "rd";
  return day + postfix;
}

export const getScheduledDate = (date) => {
  if(!date) return '';
  const newDate = new Date(date);
  const month = monthNames[newDate.getMonth()];
  const day = addDatePostfix(newDate.getDate());
  const year = newDate.getFullYear();
  return `${ month }, ${ day }, ${ year }`;
}

export const getFanMatchDate = (date: string, timezone: string) => {
  return dateFnsTz.utcToZonedTime(date, timezone);
}

export const getUTCDateNextDayAt =
  (hours: number) =>
    addHours(endOfDay(new Date()), hours).toISOString()

export const getHoursMeridiem = (date: string) => {
  const hours = new Date(
    Date
      .parse(date.replace(/[+-]\d\d:\d\d$/, '')))
      .getHours();

  return hours > 12 ? hours - 12 + " pm" : hours + " am";
}

// Randomizing dates
const addDays = (days, date = new Date()) => dateFns.addDays(date, days);
const addRandomDaysToNow = (range) => 
  addHours(
    addDays(
      Math.ceil(Math.random() * range)
    ), Math.ceil(Math.random() * 6)
  );
  
export const getRandomFutureDays = 
  (length, range, format = (date) => date) => 
    Array.from(
      { length }, 
      () => format(addRandomDaysToNow(range)
    )
  )

export const getRandomFutureDaysISO = (length, range) => getRandomFutureDays(length, range, dateFns.formatISO);
export const getShortDateFromISO = (date) => dateFns.formatISO(date).split('T')[0];
export const formatToDaysAndHours = (date) => 
  dateFns.format(
    typeof date == 'string' ? dateFns.parseISO(date) : date, 
    "yyyy-MM-dd:hh"
  )

export const isTomorrow = 
  (date: string) => 
    dateFns.getDate(dateFns.parseISO(date)) - dateFns.getDate(new Date()) == 1;

export const formatISOTo = (...args) => {
  const dateFnsLib = {
    year: "getYear",
    month: "getMonth",
    day: "getDate",
    hours: "getHours",
    minutes: "getMinutes",
    seconds: "getSeconds"
  }
}

const getTribeUsersExpirationTime = ({ users }) => users.map(({ expires_at }) => hoursLeft(expires_at));

if(environment.name == 'development') {
  (window as any).dateFns = dateFns;
  (window as any).dateFnsTz = dateFnsTz;
  (window as any).time = {
    nextDayAt,
    getDayPrefix,
    getHoursMeridiem,
    nextDayAtFallback,
    getDailyTribeRefreshTime,
    getDailyTribeRefreshTimeFallback,
    addDays,
    addRandomDaysToNow,
    getRandomFutureDays,
    getShortDateFromISO,
    formatToDaysAndHours,
    formatISOTo,
    getFanMatchDate,
    hoursLeft,
    getTribeUsersExpirationTime
  }
}

