import { Component, OnInit } from '@angular/core';
import {blankFn, performSequentially} from "../../shared/helpers/helpers";
import {ModalController, NavController, NavParams} from "@ionic/angular";
import {TribesService} from "../../services/data/tribes.service";
import {Subject} from "rxjs/Subject";
import {takeUntil} from "rxjs/internal/operators";
import {delay, filter, tap} from "rxjs/operators";
import {TribeStatus} from "../../shared/enums/tribe-status.enum";
import {logc} from "../../shared/helpers/log";
import {PopupService} from "../../services/popups/popup.service";

interface MatchingProcessState {
  animationName: string;
  animationSpeed?: number;
  title: string;
  delay?: number;
  subTitle: string;
}

@Component({
  selector: 'app-matching-process',
  template: `
    <ion-content>
      <div class = "content">
        <ng-lottie *ngIf = "state.animationName == 'searching'"
                   [options] = "lottieSearch"
                   class = "animation"
                   width = "500px"
                   height = "500px"
                   (animationCreated) = "animationCreated($event)"></ng-lottie>
        <ng-lottie *ngIf = "state.animationName == 'success'"
                   [options] = "lottieFirework"
                   class = "animation"
                   width = "500px"
                   height = "500px"
                   (animationCreated) = "animationCreated($event)"></ng-lottie>
        <ng-lottie *ngIf = "state.animationName == 'failed'"
                   [options] = "lottieFailed"
                   class = "animation"
                   width = "350px"
                   height = "350px"
                   (animationCreated) = "animationCreated($event)"></ng-lottie>
        <div class = "xl-text semibold title-color" [innerHTML] = "state.title"></div>
        <div *ngIf = "state?.subTitle" 
             class = "large-text body-color" 
             [innerHTML] = "state?.subTitle"></div>
      </div>
<!--      <button (click) = "changeAnimation('success')">Firework</button>-->
<!--      <button (click) = "changeAnimation('searching')">Search</button>-->
<!--      <button (click) = "changeAnimation('failed')">Failed</button>-->
    </ion-content>
  `,
  styleUrls: ['./matching-process.page.scss'],
})
export class MatchingProcessPage implements OnInit {
  public lottieFirework: any = {
    path: 'assets/gif/success.json',
    renderer: 'svg',
    autoplay: true,
    loop: false
  };

  public lottieSearch: any = {
    path: 'assets/gif/matching-2000.json',
    renderer: 'svg',
    autoplay: true,
    loop: true
  };

  public lottieFailed: any = {
    path: 'assets/gif/match-delayed.json',
    renderer: 'svg',
    autoplay: true,
    loop: false
  };

  private states: any = {
    searching: {
      animationName: "searching",
      animationSpeed: 2.4,
      title: "Matching",
      subTitle: "Sorting by best match"
    },
    success: {
      animationName: "success",
      animationSpeed: 1,
      title: "Success",
      subTitle: "Preparing group..."
    },
    failed: {
      animationName: "failed",
      animationSpeed: 1,
      title: "Incomplete",
      subTitle: ""
    }
  }

  public animationName = 'searching';
  public state: MatchingProcessState = this.states[this.animationName];

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
              private navCtrl: NavController,
              private tribesService: TribesService,
              private popupService: PopupService) { }

  ngOnInit() {
    this.tribesService
      .onSwapFound
      .pipe(
        takeUntil(this.unsubscribe),
        delay(3000),
        tap(() => this.changeAnimation('success')),
        delay(2500),
        tap(async (tribe) => {
          if(!(window as any).location.pathname.includes('highlights')) {
            await this.navCtrl.navigateForward(`tribes/${ tribe.id }/highlights`);
          }
          this.modalCtrl.dismiss({}, '', 'matching-process')
        })
      ).subscribe();

    this.tribesService
      .onTribeChanged
      .pipe(
        takeUntil(this.unsubscribe),
        delay(3000),
        filter((tribe: any) => tribe.status == TribeStatus.Incomplete),
        tap(() => this.changeAnimation("failed")),
        delay(2500),
        tap(async () => {
          await this.modalCtrl.dismiss({}, '', 'matching-process');
          await this.popupService.createPopup({ popupName: "swapFailedPopup" });
        })
      ).subscribe();
  }

  animationCreated(anim) {
    anim.play();
    anim.setSpeed(this.state.animationSpeed);
  }

  changeAnimation(name): void {
    this.animationName = name;
    this.state = this.states[this.animationName];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
