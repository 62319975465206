import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InputAutoFocusDirective} from './input-auto-focus.directive';
import {KeyboardAttachDirective} from "./keyboard-attach.directive";
import {LoadSrcDirective} from "./load-src.directive";
import {RemoveClassDirective} from "./remove-class.directive";
import {TextChangeDirective} from "./text-change.directive";
import {PwaStylesDirective} from "./pwa-styles.directive";
import {ExpandableDirective} from "./expandable.directive";
import {TooltipDirective} from "./tooltip.directive";

@NgModule({
  declarations: [
    InputAutoFocusDirective,
    KeyboardAttachDirective,
    LoadSrcDirective,
    PwaStylesDirective,
    RemoveClassDirective,
    TextChangeDirective,
    ExpandableDirective,
    TooltipDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    InputAutoFocusDirective,
    KeyboardAttachDirective,
    PwaStylesDirective,
    LoadSrcDirective,
    RemoveClassDirective,
    TextChangeDirective,
    ExpandableDirective,
    TooltipDirective
  ]
})
export class DirectivesModule {}
