import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CountryInformation {
  data:any;

  constructor(public http: HttpClient) {
  }

  getCountry(code) {
    return this.data.filter(u => u.code == code)[0];
  }

  getCountries() {
    return this.data;
  }

  load() {
    return new Promise((resolve, reject) => { 
      this.http.get('./assets/data/new_country_information.json')
        .toPromise()
        .then(
          (data) => { 
            this.data = data;
            resolve(this.data);
         }, reject);
    });
  }
}
