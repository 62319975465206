import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Config } from '../services/config/config';

@Injectable({
  providedIn: 'root',
})
export class FrontDoorResolver {
  constructor(public navCtrl: NavController,
              public config: Config,
             ) { }

             async resolve(route:ActivatedRouteSnapshot): Promise<void> {
               return new Promise(async (resolve, reject) => {
                 this.navCtrl.navigateRoot(['front_door'], { queryParams: route.queryParams });
    });
  }
}
