import { Injectable } from "@angular/core";
import {
  AlertController,
  ModalController,
  NavController,
} from "@ionic/angular";
import { first } from "rxjs/operators";

import { ApiService } from "./api.service";
import { AppService } from "../app";
import { RemoteConfigService } from "../remote-config.service";
import { CrashReportService } from "../performance/crash-report.service";
import { PerformanceReportService } from "../performance/performance-report.service";
import { UtilsService } from "../utils.service";
import { Config } from "../config/config";
import { DispatcherService } from "../dispatcher.service";
import { LocationService } from "../native/location.service";
import { StatementsService } from "./statements.service";
import { TribesService } from "./tribes.service";
import { TribeService } from "./tribe.service";
import { UserService } from "./user.service";
import { SearchService } from "./search.service";
import { AnalyticsService } from "../analytics/analytics.service";
import { PushNotification } from "../push-notification/push-notification";
import { Storage } from "@ionic/storage";
import { Subject } from "rxjs/Subject";
import { PusherService } from "../pusher.service";
import { AlertService } from "../popups/alert.service";
import { CustomAlertComponent } from "../../components/custom-alert/custom-alert.component";
import { CUSTOM_NOTIFICATION_ALERTS } from "../../shared/constants/custom-notification-alerts";
import { StateMachine } from "../../shared/lib/state-machine/state-machine";
import { SentryService } from "../performance/sentry.service";
import { ConsoleService } from "../performance/console.service";
import { SMELL_CHECK_TIMEOUT } from "../../shared/constants/constants";
import { Credentials, SMState } from "../../shared/interfaces";
import { DailyTribesService } from "../daily-tribes.service";
import { logc } from "../../shared/helpers/log";
//import { FacebookService } from "../facebook.service";
import { MatchesService } from "../matches.service";
import { ExperimentsService } from "../experiments.service";
import { environment } from "../../../environments/environment";
import { FeedbackService } from "../popups/feedback.service";
import { MeetupService } from "../meetup.service";
import { TopicAction, TopicsService } from "../topics.service";
import { TribeHighlightsService } from "../tribe-highlights.service";
import { MatchPreferencesLocation } from "src/app/shared/enums/match-preferences.enum";
import {
  PictureStatus,
  ProfilePictureService,
} from "../profile-picture.service";
import { ActivitiesService, ActivityAction } from "../activities.service";
import { ModalService } from "../popups/modal.service";
import {
  UpsellingPage,
  UpsellingPoppingReason,
} from "src/app/pages/upselling/upselling.page";
import { parseISO } from "date-fns";
import { BehaviorSubject } from "rxjs";

export enum DeviceInfoTransmittingStatus {
  Started = "started",
  Completed = "completed",
}

@Injectable({
  providedIn: "root",
})
export class SessionService {
  public notifications: any = [];
  public platform: any = null;
  public latitude: any = null;
  public version: any = null;
  public latestVersion: any = null;
  public longitude: any = null;
  public userId: any = null;
  public showedLocationWarning: boolean = false;
  public isMissingTribeAction: boolean = false;
  private tribeActionRequired: any = {};
  private signup: any = "email";
  public pausedForPlugin: boolean = false;
  public canForceSearch: boolean = true;
  public safeTop = 0;
  private authenticated: boolean = false;
  public trackedData: any[] = [];

  public connectingInstagram: boolean = false;
  public lastActiveAt = null;

  private locationSource = new Subject();
  private logoutSource = new Subject();
  private loginSource = new Subject();
  public beforeLogoutSource = new Subject();
  public deleteAccountSource = new Subject();
  public tribeEventSource = new Subject();
  public searchEventSource = new Subject();
  public pictureRejectedSource = new Subject();
  public onTimeoutSource = new Subject();
  public connectionChangedSource = new Subject();
  private readySource: any = new BehaviorSubject(null);
  public connectedSubscription: any;
  public disconnectedSubscription: any;
  private tribeActionTakenSubscription: any = null;
  private customAlerts = CUSTOM_NOTIFICATION_ALERTS;
  private EXPERIMENTS_TIMEOUT = 1000;

  public onLogout: any = this.logoutSource.asObservable();
  public onLogin: any = this.loginSource.asObservable();
  public onBeforeLogout: any = this.beforeLogoutSource.asObservable();
  public onDeleteAccount: any = this.deleteAccountSource.asObservable();
  public onTribeEvent: any = this.tribeEventSource.asObservable();
  public onSearchEvent: any = this.searchEventSource.asObservable();
  public onPictureRejected: any = this.pictureRejectedSource.asObservable();
  public onTimeout: any = this.onTimeoutSource.asObservable();
  public onConnectionChanged: any = this.connectionChangedSource.asObservable();
  public onReady: any = this.readySource.asObservable();

  public serverChannel: any;
  public hasPwaToken = false;
  public skippingLoginRedirect = false;
  private unsubscribe = new Subject<void>();
  private onFlagsChangedSubscription: any = null;
  public stateMachine: any = null;
  public deviceStateMachine: any = null;
  private justResumed: boolean = false;

  private errorsCheckTimeout: any = null;
  private resumeTimeout: any;
  public ssoInfo: any = null;

  constructor(
    public statementsService: StatementsService,
    public userService: UserService,
    public config: Config,
    public utils: UtilsService,
    public api: ApiService,
    public tribesService: TribesService,
    public tribeService: TribeService,
    public analyticsService: AnalyticsService,
    private alertService: AlertService,
    public profilePictureService: ProfilePictureService,
    private feedbackService: FeedbackService,
    public pushService: PushNotification,
    private locationService: LocationService,
    public storage: Storage,
    public alertCtrl: AlertController,
    private pusherService: PusherService,
    public navCtrl: NavController,
    public appService: AppService,
    public searchService: SearchService,
    public remoteConfig: RemoteConfigService,
    public crashReportService: CrashReportService,
    public dispatcherService: DispatcherService,
    public performanceReportService: PerformanceReportService,
    private dailyTribesService: DailyTribesService,
    private errorTrackingService: SentryService,
    private consoleService: ConsoleService,
    //private facebookService: FacebookService,
    private matchesService: MatchesService,
    private meetupService: MeetupService,
    private modalService: ModalService,
    private experimentsService: ExperimentsService,
    private activitiesService: ActivitiesService,
    private tribeHighlightsService: TribeHighlightsService,
    private topicsService: TopicsService,
    private modalCtrl: ModalController
  ) {
    this.appService.onResume.subscribe(() => {
      this.resumeTimeout = setTimeout(() => {
        if (this.api.isOnline() && this.authenticated) {
          if (
            this.pusherService.state !== "connected" ||
            this.stateMachine.state !== "ready"
          ) {
            const message =
              "Session or Pusher isn't ready in 10s after app resumed";
            const tags = {
              klass: "sessionService",
              func: "this.appService.onResume",
            };
            const extras = {
              pusherState: this.pusherService.state,
              sessionState: this.stateMachine.state,
              logs: this.consoleService?.getLogs(),
            };

            console.log(`Session smellcheck error: ${message}}`);
            console.log(`pusher: ${extras.pusherState}`);
            console.log(`session: ${extras.sessionState}`);
            this.errorTrackingService.sendMessage(message, tags, extras);
          }
        }
      }, SMELL_CHECK_TIMEOUT);
    });

    this.appService.onPause.subscribe(() => clearTimeout(this.resumeTimeout));

    this.deviceStateMachine = new StateMachine(
      {
        standby: {
          on: () => {},
          init: () => {
            this.pushService.onReady.subscribe((_) => {
              this.deviceStateMachine.env.pushesReady = true;
              this.deviceStateMachine.exec("pushesReady");
            });
            this.locationService.onReady.subscribe((_) => {
              this.deviceStateMachine.env.locationReady = true;
              this.deviceStateMachine.exec("locationReady");
            });

            this.pushService.onPushChanged.subscribe((data) => {
              console.log("device push changed", data);
              this.deviceStateMachine.exec("pushesChanged");
            });

            this.locationService.onLocationChanged.subscribe((data) => {
              console.log("device location changed", data);
              this.deviceStateMachine.exec("locationChanged");
            });
          },
          locationReady: "checking",
          locationChanged: "checking",
          pushesReady: "checking",
          pushesChanged: "checking",
        },
        checking: {
          on: () => {
            if (
              this.deviceStateMachine.env.locationReady &&
              this.deviceStateMachine.env.pushesReady
            ) {
              return "transmitting";
            } else {
              return "standby";
            }
          },
        },
        transmitting: {
          on: () => {
            return new Promise(async (resolve, reject) => {
              //If authenticated we really want to make sure that we send this device info
              //If not authenticated, we will join it with the createAccount or updateSession
              console.log(
                "transmitting - Is authenticated",
                this.authenticated
              );
              console.log("transmitting - SM state", this.stateMachine.state);
              if (
                ["authenticating", "creatingAccount"].includes(
                  this.stateMachine.state
                )
              ) {
                setTimeout((_) => {
                  resolve("checking");
                }, 3000);
                return;
              }

              if (this.authenticated) {
                try {
                  await this.sendDeviceInfo();
                } catch (error) {
                  console.log(error);
                  setTimeout((_) => {
                    resolve("checking");
                  }, 3000);
                  return;
                }
              }
              //If the session is not ready yet, it's fine, we will send it with the auth of the session.
              resolve("ready");
            });
          },
        },
        ready: {
          on: () => {},
          locationChanged: "transmitting",
          pushesChanged: "transmitting",
        },
      },
      "device",
      this.appService
    );

    this.stateMachine = new StateMachine(
      {
        standby: {
          on: () => {},
          init: "initializing",
          resume: "connecting",
          drop: ["disconnect"],
        },
        initializing: {
          on: () => {
            return new Promise(async (resolve, reject) => {
              await this.init();
              resolve("connecting");
            });
          },
          pause: "standby",
        },
        connecting: {
          on: () => {
            return new Promise(async (resolve, reject) => {
              const connected = await this.checkConnection();
              console.log("SESSION SERVICE API CONNECTED", connected);
              resolve(connected ? "connected" : "offline");
            });
          },
          pause: "standby",
        },
        connected: {
          on: () => {
            this.connectionChangedSource.next(true);
            const needsAuth = this.needsAuth();
            console.log(`connected. Needs auth: ${needsAuth}`);
            if (this.authenticated && !needsAuth) {
              return "authenticated";
            } else if (this.api.hasToken()) {
              return "authenticating";
            }
          },
          createAccount: "creatingAccount",
          login: "logingIn",
          pause: "standby",
          disconnect: "offline",
          drop: ["connect"],
        },
        creatingAccount: {
          on: async (context) => {
            return new Promise(async (resolve, reject) => {
              try {
                await this.onCreateAccount();
              } catch (e) {
                return resolve("connected");
              }
              resolve("authenticated");
            });
          },
        },
        authenticating: {
          on: (context) => {
            return new Promise(async (resolve, reject) => {
              this.updateSession().then(
                async (_) => {
                  resolve("authenticated");
                },
                (err) => {
                  if (err.status === 401) {
                    context.expired = true;
                    resolve("expired");
                  } else {
                    //Automatic api auth failed unexpectedly
                    //Try again in 3 seconds.
                    setTimeout((_) => {
                      resolve("connecting");
                    }, 3000);
                  }
                }
              );
            });
          },
        },
        authenticated: {
          on: () => {
            this.authenticated = true;
            if (!this.pushService.isSubscribed) {
              this.pushService.turnOnPushNotifications();
            }

            this.userService.load();

            return "ready";
          },
        },
        ready: {
          on: async () => {
            if (!this.activitiesService.initialized) {
              await this.activitiesService.init();
            }
            if (!this.topicsService.initialized) {
              await this.topicsService.init();
            }

            if (!this.experimentsService.initialized) {
              await this.experimentsService.init();
            }

            if (!this.pusherService.pusher) {
              await this.pusherService.init();
            }

            if (!this.tribeHighlightsService.initialized) {
              this.tribeHighlightsService.init();
            }

            this.matchesService.init();
            // this.userService.load();

            await this.openServerChannel();
            await this.dailyTribesService.checkDailyTribesStatus();
            this.readySource.next(null);

            if (this.justResumed) {
              this.notifications = await this.api.get(
                "users/notifications",
                {}
              );
              this.handleNotifications();
            }
            this.justResumed = false;
          },
          pause: async () => {
            await this.closeServerChannel();
            return "standby";
          },
          disconnect: async () => {
            await this.closeServerChannel();
            return "offline";
          },
          logout: "logingOut",
          deleteAccount: "deletingAccount",
          login: "stale",
        },
        stale: {
          on: (context) => {
            //User loged in while on an account, it's an anonymous account
            return new Promise(async (resolve, reject) => {
              await this.userService.clearSession();
              await this.closeSession();
              resolve("logingIn");
            });
          },
        },
        logingIn: {
          on: (context) => {
            return new Promise((resolve, reject) => {
              console.log(
                "**** SENT DEVICE INFO with onLogingIn",
                context.params
              );
              this.login(context.resource, context.params).then(
                (_) => {
                  resolve("authenticated");
                },
                (err) => {
                  resolve("connecting");
                }
              );
            });
          },
        },
        expired: {
          on: () => {
            return new Promise(async (resolve, reject) => {
              try {
                await this.kickOut();
              } catch (e) {
                console.error(e);
              } finally {
                resolve("connecting");
              }
            });
          },
        },
        logingOut: {
          on: async (context) => {
            try {
              await this.logingOut();
            } catch (e) {
              console.error(e);
            } finally {
              return "connecting";
            }
          },
        },
        deletingAccount: {
          on: async (context) => {
            await this.deletingAccount(context.reason, context.details);
            return "connecting";
          },
        },
        offline: {
          on: () => {
            this.connectionChangedSource.next(false);
          },
          connect: async () => {
            this.connectionChangedSource.next(true);
            this.justResumed = true;
            return "connecting";
          },
          pause: "standby",
          disconnect: "offline",
        },
      },
      "session",
      this.appService
    );
    this.deviceStateMachine.exec("init");
    this.stateMachine.exec("init");

    /*
    this.facebookService.onDataFetched.subscribe(
      (data) => (this.ssoInfo = data)
    );
    */

    this.deviceStateMachine.onStateChanged.subscribe((state: SMState) => {
      this.config.SMAggregator.device = state;
    });

    this.stateMachine.onStateChanged.subscribe((state: SMState) => {
      this.config.SMAggregator.session = state;
      this.pusherService.sessionState = state.name;
    });

    this.pushService.onNewPushEvent.subscribe((data) => {
      if (this.stateIs("ready")) {
        this.handleEvent(data.event, data.payload);
      } else {
        this.onReady.pipe(first()).subscribe((_) => {
          this.handleEvent(data.event, data.payload);
        });
      }
    });
  }

  createAccount() {
    return new Promise(async (resolve, reject) => {
      if (this.stateMachine.state == "connected") {
        // await this.utils.showLoading('');
        const state = await this.stateMachine.exec("createAccount");
        // await this.utils.doneLoading();
        resolve(state);
      }
    });
  }

  stateIs(state: string): boolean {
    return this.stateMachine.state === "ready";
  }

  onCreateAccount() {
    return new Promise(async (resolve, reject) => {
      let data = await this.updateSessionInfo();

      if (this.ssoInfo) {
        data = {
          ...data,
          ...{
            sso_token: this.ssoInfo.token,
            sso_id: this.ssoInfo.id,
            sso_provider: "facebook",
          },
        };
      }

      data["flags"] = this.config.get("flags");

      console.log("**** SENT DEVICE INFO with createAccount", data);
      this.api.getNewApiKey(data).then((_) => {
        this.setUserId(this.config.get("id")); //API service will set it
        resolve(true);
      }, reject);
    });
  }

  needsAuth() {
    let needsAuth = false;
    console.log("DEBUG lastActiveAt", this.lastActiveAt);
    if (this.lastActiveAt) {
      let a_minute = 30000;
      let now: any = new Date();
      let last_active_mins_ago = (now - this.lastActiveAt) / a_minute;
      console.log("DEBUG mins ago", last_active_mins_ago);
      needsAuth = last_active_mins_ago > 2;
    }
    return needsAuth;
  }

  init(): Promise<void> {
    (<any>window).profilePictureService = this.profilePictureService;
    (<any>window).pictureStatus = PictureStatus;

    return new Promise(async (resolve, err) => {
      if (["staging", "development"].includes(environment.name)) {
        (<any>window).session = this;
        (<any>window).refApi = ApiService;
      }
      this.appService.onPause.subscribe((_) => {
        console.log("--- session appService.onPause triggered ---");
        this.onPause();
        clearTimeout(this.errorsCheckTimeout);
      });

      this.appService.onResume.subscribe((_) => {
        console.log("--- session appService.onResume triggered ---");
        this.onResume();
        // this.smellCheck();
      });

      this.appService.onAppReady.subscribe((_) => {
        this.crashReportService.load();
        this.remoteConfig.load();
        this.performanceReportService.load();
      });

      this.dispatcherService.onSessionResume.subscribe(() => {
        console.log("--- session dispatcherService.onSessionResume ----");
        this.onResume();
      });

      this.dispatcherService.onConnectingInstagram.subscribe(() => {
        this.connectingInstagram = true;
      });

      this.analyticsService.onNewEvent.subscribe((ev) => {
        this.trackedData.unshift({
          timestamp: new Date(),
          source: "app",
          type: "ev",
          name: ev.name,
          params: JSON.stringify(ev.params),
        });
      });
      this.analyticsService.onPropertySet.subscribe((p) => {
        this.trackedData.unshift({
          timestamp: new Date(),
          source: "app",
          type: "prop",
          name: p.name,
          value: p.value,
        });
      });

      this.onFlagsChangedSubscription = this.config.onFlagsChanged.subscribe(
        (flags) => {
          if (this.api.hasToken()) {
            this.api.put("users", { flags: flags });
          }
        }
      );

      this.locationService.onLocationChanged.subscribe((data: any) => {
        this.onLocationSet(data);
      });

      this.disconnectedSubscription = this.api.onDisconnected.subscribe((_) => {
        this.onDidDisconnect();
      });

      this.connectedSubscription = this.api.onConnected.subscribe(
        (data: any) => {
          this.onConnected(data);
        }
      );

      this.tribeActionTakenSubscription =
        this.tribesService.onTribeActionTaken.subscribe((tribeId) => {
          this.tribeActionRequired[tribeId] = false;
          this.onTribeActionsChanged();
        });

      await this.config.load();

      await this.config.setFlags(
        {
          device_info: this.utils.getDeviceInfo(),
        },
        false
      );
      resolve();
    });
  }

  isOnline() {
    return this.stateMachine.state === "ready";
  }

  smellCheck() {
    this.errorsCheckTimeout = setTimeout(() => {
      if (this.api.isOnline()) {
        const tags = { klass: "session", func: "init()" };
        let message, extras;
        if (this.stateMachine.state !== "ready") {
          message = "Session isn't 'ready' in 30s";
          extras = {
            sessionState: this.stateMachine.state,
            logs: this.consoleService.getLogs(),
          };
          this.errorTrackingService.sendMessage(message, tags, extras);
          console.log(message, extras);
        }
        if (this.deviceStateMachine.state !== "ready") {
          message = "Session Device SM isn't 'ready' in 30s";
          extras = {
            sessionDeviceState: this.deviceStateMachine.state,
            logs: this.consoleService?.getLogs(),
          };
          this.errorTrackingService.sendMessage(message, tags, extras);
        }
      }
    }, SMELL_CHECK_TIMEOUT);
  }

  checkConnection() {
    return new Promise(async (resolve, err) => {
      if (this.api.isOnline()) {
        resolve(true);
      } else {
        setTimeout((_) => {
          if (this.api.isOnline()) {
            resolve(true);
          } else {
            setTimeout((_) => {
              resolve(this.api.isOnline());
            }, 1000);
          }
        }, 500);
      }
    });
  }

  isConnected() {
    return this.stateMachine.state != "offline";
  }

  doesOtherTribeRequireAction(tribeId) {
    let tribeIds = Object.keys(this.tribeActionRequired).filter((tid) => {
      return tid != tribeId;
    });
    return tribeIds.some((id) => {
      return this.doesTribeRequireAction(id);
    });
  }

  doesTribeRequireAction(tribeId) {
    return this.tribeActionRequired[tribeId] === true;
  }

  onTribeActionsChanged() {
    this.isMissingTribeAction = false;
    let tribeIds = Object.keys(this.tribeActionRequired);
    this.isMissingTribeAction = tribeIds.some((tribeId) => {
      return this.doesTribeRequireAction(tribeId);
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.tribeActionTakenSubscription) {
      this.tribeActionTakenSubscription.unsubscribe();
    }
    if (this.onFlagsChangedSubscription) {
      this.onFlagsChangedSubscription.unsubscribe();
    }
  }

  reset() {
    this.notifications = [];
    this.userId = null;
    this.showedLocationWarning = false;
    this.tribeActionRequired = {};
    this.isMissingTribeAction = false;
    this.authenticated = false;
    this.skippingLoginRedirect = false;
  }

  private closeSession(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.config.isPWA && !this.config.isDev) {
        this.pushService.webPushUnsubscribe();
      }

      this.closeServerChannel();
      await this.clearData();
      resolve();
    });
  }

  loginDataSource = new Subject();
  onLoginData = this.loginDataSource.asObservable();
  private login(resource, params) {
    return new Promise(async (resolve, reject) => {
      this.api.auth(resource, ApiService.formatParams(params)).then(
        (data: any) => {
          setTimeout(() => this.pushService.turnOnPushNotifications(), 2000);

          this.onDidLogin(resource, data).then((_) => {
            this.utils.doneLoading();
            let p = this.userService.getProfile();
            this.utils.showGenericMessage(
              "Signed in as " + p.firstName + (p.lastName || "")
            );
            this.config.setFlag("opening_is_done", true);
            this.loginDataSource.next(data);
            if (!this.skippingLoginRedirect) {
              this.navCtrl.navigateRoot("/");
            }
            this.loginSource.next(null);
            resolve(data);
          }, reject);

          setTimeout((_) => {
            if (
              !this.pushService.hasPermission &&
              !this.config.isExternalLinkSignIn
            ) {
              this.alertService.openPushAlert();
            }
          }, 1000);
        },
        async (err) => {
          this.utils.doneLoading();
          if (err.status == 401) {
            this.utils.showGenericError({
              msg: "Wrong email or password!",
              key: "api_auth",
            });
          } else {
            this.utils.errorContext =
              `session login, error: ` + JSON.stringify(err);
            this.utils.showGenericError({
              key: "api_auth",
            });
          }
          reject(err);
        }
      );
    });
  }
  /*
   else if (
      err.status == 404 &&
      resource == "sso_login" &&
      params.sso_provider == "facebook"
    ) {
      this.utils.showGenericMessage(
        "Almost done! Just a few quick steps left"
      );
      await this.config.setFlag("signUpSetup", "noNumberSetup");
      await this.onCreateAccount();
      await this.navCtrl.navigateForward("sign-up-flow", {
        state: { backPath: "sign_in_choice" },
      });
      await this.config.setFlag(
        "current_onboarding_page",
        "sign-up-flow"
      );
    }
*/

  handleExperiments() {
    if (this.config.getFlag("request_for_referral") == "scheduled") {
      setTimeout(() => {
        this.analyticsService.trackEvent({
          key: "entered_referral_experiment",
          value: 1,
        });
        this.navCtrl.navigateForward("referral-invitation");
      }, this.EXPERIMENTS_TIMEOUT);
    }
  }

  notificationSeen(id) {
    return this.api.delete("users/notifications/" + id, {});
  }

  async handleEvent(ev, data) {
    logc.session("*** Server Event ***", ev);
    logc.session("*** Data: ", data);
    switch (ev) {
      case "topic_votes_updated":
        this.topicsService.topicsVotesUpdateSource.next({
          payload: data,
          action: TopicAction.Vote,
        });
        break;
      case "activity_votes_updated":
        this.activitiesService.activitiesVotesUpdateSource.next({
          payload: data,
          action: ActivityAction.Vote,
        });
        break;
      case "message_reaction":
        this.tribeService.messageReactionSource.next(data);
        break;
      case "message_reaction_removed":
        this.tribeService.messageReactionRemovedSource.next(data);
        break;
      case "scheduled_matches_signup":
        this.searchService.startSearch();
        break;
      case "APIaction":
        this.userService.runAPIAction(
          data.customAction,
          data.successText,
          data.failureText,
          data.customPayload
        );
        break;
      case "feedback_request":
        data.analytics.type = "feedback";
        this.feedbackService.createFeedbackModal(data);
        break;
      case "search_event":
        this.searchService.searchChanged(data);
        break;
      case "chat_unlocked":
        this.tribesService.chatUnlockedSource.next(data);
        break;
      case "joins":
        this.tribesService.userJoinedSource.next(data);
        break;
      case "intros_suggested":
        this.tribesService.introsSuggestedSource.next(data?.suggested_intros);
        break;
      case "questions_suggested":
        this.tribesService.questionsSuggestedSource.next(
          data?.suggested_questions
        );
        break;
      case "tribe_formation_event":
        //Delay this because the UI reacts right away to user actions and we don't want the server events to interfere with UI changes.
        //500 ms gives the time to the UI to decie what to do
        setTimeout((_) => {
          this.tribesService.dispatchFormationEvent(data);
          // this.tribesService.tribeChanged(data.tribe);
        }, 500);
        break;
      case "new_incomplete_group":
        setTimeout(async () => {
          this.tribesService.dispatchFormationEvent(data);
          await this.navCtrl.navigateBack("tabs/tribes");
          await this.navCtrl.navigateForward(`tribes/${data?.tribe?.id}/chat`);
        }, 500);
        break;
      case "tribe_message":
        console.log(
          "--- session.service.ts tribe_message event tribesService.newMessage ---"
        );
        this.tribesService.newMessage(data);
        if (this.tribeService.isCurrentChat(data.tribe_id)) {
          console.log(
            "--- session.service.ts tribe_message event tribeService.isChatConnected() ---",
            data.tribe_id
          );
          this.tribeService.newMessage(data);
        }
        break;
      case "picture_verified":
        this.config.updateProfile({
          pictureStatus: "verified",
          picture: data && data.picture,
          banned: data.banned,
        });
        if (data.gender) {
          this.utils.showGenericMessage("Your account has been unblocked.");
        } else {
          this.utils.showGenericMessage(
            "Your profile pic has been verified and updated."
          );
        }
        break;
      case "picture_verification_failed":
        this.pictureRejectedSource.next(data);
        break;
      case "on_a_timeout":
        this.config.updateProfile({ available_for_tribes: false });
        this.onTimeoutSource.next();
        break;
      case "left_tribe":
        this.tribesService.tribeLeftSource.next(data);
        if (!this.config.getFlag("groupFailedBefore")) {
          this.config.setFlag("groupFailedBefore", true);
        }
        break;
      case "declined_tribe":
        this.tribesService.tribeDeclinedSource.next(data);
        break;
      case "replaceable":
        this.navCtrl.navigateForward(
          "tribes/" + data.tribe_id + "/highlights",
          { state: { replaceable: true } }
        );
        break;
      case "subscribed_to_premium":
        this.updateSubscriptionInfo(data);
        break;
      case "subscribed_to_premium_update":
        this.updateSubscriptionInfo(data);
        break;
      case "subscription_canceled":
        this.updateSubscriptionInfo(data);
        this.resetPreferencesToNotPlus();
        this.dispatcherService.plusSubscriptionChangedSource.next(
          this.config.isPlus()
        );
        break;
      case "search_stopped":
        this.searchService.hasStopped();
        break;
      case "profile_update":
        this.config.updateProfile(data);
        break;
      case "profile":
        this.config.updateProfile(data);
        break;
      case "meta_user_updated":
        this.statementsService.metaUserUpdated(data);
        break;
      case "flags_updated":
        this.config.setFlags(data.flags);
        this.config.save().then(() => this.handleExperiments());
        break;
      case "subscription_resumed":
        this.updateSubscriptionInfo(data);
        this.openCustomAlert(this.customAlerts.subscriptionResumed);
        break;
      case "subscription_hold":
        this.updateSubscriptionInfo(data);
        this.resetPreferencesToNotPlus();
        this.dispatcherService.plusSubscriptionChangedSource.next(
          this.config.isPlus()
        );
        this.openCustomAlert(this.customAlerts.subscriptionPaused);
        break;
      case "matches_recomputed":
        this.analyticsService.trackEvent({
          key: "matches_recomputed",
          value: 1,
          ...data,
        });
        this.dispatcherService.matchesRecomputedSource.next(data);
        break;
      case "logs_requested":
        this.userService.sendFeedback(`Logs ${data.topic}`, "development");
        break;
      case "notification_issues":
        if (!this.config.isExternalLinkSignIn) {
          this.dispatcherService.newPopupSource.next({
            popupName: this.utils.getNotificationIssuesPopup(data.situation),
          });
        }
        break;
      case "popup":
        this.dispatcherService.newPopupSource.next({
          popupName: "remote",
          options: data,
        });
        break;
      case "new_scheduled_search":
        this.tribesService.newScheduledSearchSource.next(data);
        break;
      case "new_scheduled_searches":
        this.tribesService.newScheduledSearchesSource.next(data);
        break;
      case "availabilities_updated":
        this.meetupService.availabilityUpdatedSource.next(data);
        break;
      case "new_replacement":
        if (await this.modalCtrl.getTop()) {
          this.modalCtrl.dismiss();
        }
        break;
      case "limited_time_offer":
        this.modalService.openUpselling({
          source: "session-service",
          reason: UpsellingPoppingReason.GetMatched,
        });
        break;
      default:
        break;
    }
  }

  async openCustomAlert(options: any) {
    const alert = await this.alertService.createAlertNotification(
      options,
      CustomAlertComponent
    );
    alert.open();
  }

  async handleNotifications() {
    logc.crimson("notifications: ", this.notifications);
    this.notifications.forEach((notification) =>
      this.handleEvent(notification.message_type, notification.data)
    );
    this.notifications = [];

    this.handleExperiments();
  }

  async updateSubscriptionInfo(data) {
    console.log("--- subscription is updated to: ", data);
    if (await this.modalCtrl.getTop()) {
      this.modalCtrl.dismiss();
    }
    await this.config.updateProfile({
      subscription: {
        name: data.name,
        status: data.status,
        renewsAt: data.renews_at,
        topUpAt: data.top_up_at,
      },
    });
    this.dispatcherService.plusSubscriptionChangedSource.next(
      this.config.isPlus()
    );
  }

  resetPreferencesToNotPlus() {
    //TODO change this and configure the default configs somewhere, we can't
    //just repeat this everywhere we need to reset them.
    let matchPreferences = this.config.get("matchPreferences");
    matchPreferences.gender = null;
    matchPreferences.location = MatchPreferencesLocation.NearMe;
    delete matchPreferences.coords;
    delete matchPreferences.remote_city_name;

    if (this.config.getProfile().gender != matchPreferences.gender) {
      matchPreferences.gender = null;
    }
    delete matchPreferences.coords;
    delete matchPreferences.remote_city_name;
    this.config.updateProfile({
      matchPreferences: matchPreferences,
    });
  }

  async smsLogin(code) {
    let params = Object.assign(
      {
        code: code,
      },
      await this.updateSessionInfo()
    );

    await this.stateMachine.exec("login", {
      resource: "sms_login",
      params: params,
    });
  }

  async tribeCodeLogin(token) {
    this.api.setSecureToken(token);

    let params = Object.assign({}, await this.updateSessionInfo());

    await this.stateMachine.exec("login", {
      resource: "tribe_code_login",
      params: params,
    });
  }

  async pwaLogin(pwa_token) {
    if (this.isAuthenticated()) {
      logc.info("Already logged in, logging out...");
      await this.logout();
    }
    let params = Object.assign(
      {
        pwa_token: pwa_token,
      },
      await this.updateSessionInfo()
    );

    logc.info("Is authenticated: ", this.isAuthenticated());
    await this.stateMachine.exec("login", {
      resource: "pwa_login",
      params: params,
    });

    setTimeout((_) => {
      this.analyticsService.trackEvent({ key: "pwa_install", value: 1 });
    }, 2000);
    this.hasPwaToken = false;
  }

  async emailLogin({ email, password }: Credentials) {
    let params = Object.assign(
      {
        email: email,
        password: password,
      },
      await this.updateSessionInfo()
    );

    await this.stateMachine.exec("login", {
      resource: "email_login",
      params: params,
    });
  }

  /*
  async facebookLogin(data) {
    let params = {
      sso_token: data.token,
      sso_id: data.id,
      sso_provider: "facebook",
      ...(await this.updateSessionInfo()),
    };

    return this.stateMachine.exec("login", {
      resource: "sso_login",
      params: params,
    });
  }
 */

  /* clears data and cache for the whole app */
  clearData(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.reset();
      this.config.reset().then(
        async (_) => {
          this.pusherService.reset();
          if (this.config.get("id") != -1) {
            const tags = { klass: "sessionService", func: "clearData()" };
            const extras = { user_id: this.config.get("id") };
            this.errorTrackingService.sendMessage(
              "Config was cleared but then was loaded again",
              tags,
              extras
            );
          }
          this.analyticsService.cleanup(this.config);
          Promise.all([
            this.statementsService.reset(),
            this.tribesService.reset(),
            this.tribeService.reset(),
            this.userService.reset(),
            this.topicsService.reset(),
            this.activitiesService.reset(),
          ]).then(
            (_) => {
              this.api.resetCache();
              resolve();
            },
            (err) => {
              console.error("Couldn't reset services", err);
              reject(err);
            }
          ); // can't reset providers
        },
        (err) => {
          console.error("Couldn't flush config", err);
          reject(err);
        }
      ); // can't flush config
    });
  }

  setUserId(userId) {
    this.userId = userId;
    this.analyticsService.setUserId(this.userId);
    this.crashReportService.setUserId(this.userId);
  }

  /* clears data and cache for the whole app */
  onDidLogin(resource, profileData) {
    this.analyticsService.trackEvent({ key: resource, value: 1 });

    let signup = "";
    if (["email", "users"].indexOf(resource) > -1) {
      signup = "email";
    }

    return new Promise(async (resolve, reject) => {
      this.storage.clear();
      this.config.reset().then((_) => {
        this.latestVersion = profileData.version;
        this.notifications = profileData.notifications;

        let toUpdate: any = {
          id: profileData.id,
          apiKey: profileData.authentication_token,
          email: profileData.email,
          gender: profileData.gender,
          firstName: profileData.first_name,
          lastName: profileData.last_name,
          picture: profileData.picture,
          closest_city: profileData.closest_city,
          birthday: profileData.birthday,
          currentLevel: profileData.level,
          signup: signup,
          firstPendingTribeVisit: profileData.first_pending_tribe_visit,
          hasManualLocation: profileData.has_manual_location,
          legalGuardian: profileData.legal_guardian,
          smsOptIn: profileData.sms_opt_in,
          referrer: profileData.referrer,
          notificationPreferences: profileData.notification_preferences,
          instagramTestUser: profileData.instagram_test_user,
          hasPassword: profileData.has_password,
          status: profileData.status,
          missedMessagesCounts: profileData.missed_messages_counts,
          availableForTribes: profileData.available_for_tribes,
          readyToSearch: profileData.ready_to_search,
          radius: profileData.radius,
          matchPreferences: profileData.match_preferences,
          availableWhileUninstalled: profileData.available_while_uninstalled,
          enforceRadius: profileData.enforce_radius,
          phoneNumber: profileData.phone_number,
          phoneNumberStatus: profileData.phone_number_status,
          startedCurrentLevel: profileData.started_level,
          referralCode: profileData.referral_code,
          banned: profileData.banned,
          partner: profileData.partner,
          pictureStatus: profileData.picture_status,
          instagramToken: profileData.instagram_token,
          bonusLevelDone: profileData.bonus_level_done,
          flags: profileData.flags,
          subscription: {
            name: profileData.subscription && profileData.subscription.name,
            status: profileData.subscription && profileData.subscription.status,
            renewsAt:
              profileData.subscription && profileData.subscription.renews_at,
            topUpAt:
              profileData.subscription && profileData.subscription.top_up_at,
          },
        };

        if (profileData.has_manual_location || !this.config.hasLocation()) {
          this.latitude = profileData.latitude;
          this.longitude = profileData.longitude;
          toUpdate.latitude = profileData.latitude;
          toUpdate.longitude = profileData.longitude;
        }

        if (profileData.pwa_token) {
          toUpdate.pwaToken = profileData.pwa_token;
        }

        this.config.partner = profileData.partner;

        this.config.updateProfile(toUpdate).then(async () => {
          await this.analyticsService.cleanup(this.config);
          this.config.isAppLatestVersion = this.isAppLatestVersion();
          this.setUserId(profileData.id);
          await this.analyticsService.tryInit(
            this.config.getProfile(),
            this.userId,
            this.pushService.hasPush()
          );

          this.handleNotifications();

          this.statementsService.reset();
          this.tribesService.reset();
          this.tribeService.reset();
          this.api.resetCache();
          resolve(profileData);
        }, reject);
      }, reject);
    });
  }

  beforeLogout() {
    this.pushService.turnOffPushNotifications();
    this.config.setFlag("current_onboarding_page", null);
    this.beforeLogoutSource.next(null);
  }

  deleteAccount(reason, details) {
    this.stateMachine.exec("deleteAccount", {
      reason: reason,
      details: details,
    });
  }

  deletingAccount(reason, details): Promise<void> {
    return new Promise((resolve, reject) => {
      this.api
        .delete("users", {
          reason: reason,
        })
        .then(
          async (_) => {
            this.beforeLogout();
            await this.clearData();
            this.analyticsService.trackEvent({
              key: "deleted_account",
              value: 1,
              ...details,
            });
            this.deleteAccountSource.next(null);
            this.utils.showGenericMessage(
              "You successfully deleted your account"
            );
            resolve();
          },
          (err) => {
            this.utils.showGenericError({
              msg: "Unable to delete your account, please try again later.",
            });
            reject();
          }
        );
    });
  }

  logingOut(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.beforeLogout();
      try {
        await this.api.post(
          "logout",
          ApiService.formatParams({ platform: this.config.platformName })
        );
      } catch (e) {
        console.log("Loging out error: ", e);
      }
      await this.closeSession();
      this.logoutSource.next({ kickedOut: false });
      resolve();
    });
  }

  kickOut(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.beforeLogout();
      await this.closeSession();
      this.logoutSource.next({ kickedOut: true });
      resolve();
    });
  }

  sendDeviceInfo(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const deviceInfo = this.deviceInfo();
      logc.pink("device info before sending: ", deviceInfo);
      try {
        this.dispatcherService.deviceInfoTransmittingSource.next({
          status: DeviceInfoTransmittingStatus.Started,
        });
        await this.api.put("device_info", ApiService.formatParams(deviceInfo));
        resolve();
      } catch (ex) {
        reject(ex);
      } finally {
        logc.info("** Device Info Sent **");
        this.dispatcherService.deviceInfoTransmittingSource.next({
          status: DeviceInfoTransmittingStatus.Completed,
        });
      }
    });
  }

  /* Update the app config from server,
   * it's done every time the app starts as soon as we have a connection. */
  updateSession(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const info = await this.updateSessionInfo();
      console.log("**** SENT DEVICE INFO with updateSession", info);
      this.api.get("me", info).then((resp: any) => {
        this.notifications = resp.notifications;
        this.tribeActionRequired = resp.action_required || {};
        this.onTribeActionsChanged();
        this.config.checkDailyStats();
        this.setUserId(resp.id);
        this.latestVersion = resp.version;
        let toUpdate: any = {
          id: resp.id,
          closest_city: resp.closest_city,
          picture: resp.picture,
          pictureStatus: resp.picture_status,
          firstPendingTribeVisit: resp.first_pending_tribe_visit,
          hasManualLocation: resp.has_manual_location,
          status: resp.status,
          missedMessagesCounts: resp.missed_messages_counts,
          availableForTribes: resp.available_for_tribes,
          radius: resp.radius,
          smsOptIn: resp.sms_opt_in,
          matchPreferences: resp.match_preferences,
          notificationPreferences: resp.notification_preferences,
          instagramTestUser: resp.instagram_test_user,
          availableWhileUninstalled: resp.available_while_uninstalled,
          enforceRadius: resp.enforce_radius,
          phoneNumber: resp.phone_number,
          phoneNumberStatus: resp.phone_number_status,
          startedCurrentLevel: resp.started_level,
          referralCode: resp.referral_code,
          banned: resp.banned,
          partner: resp.partner,
          instagramToken: resp.instagram_token,
          bonusLevelDone: resp.bonus_level_done,
          flags: resp.flags,
          subscription: {
            name: resp.subscription && resp.subscription.name,
            status: resp.subscription && resp.subscription.status,
            renewsAt: resp.subscription && resp.subscription.renews_at,
            topUpAt: resp.subscription && resp.subscription.top_up_at,
          },
        };

        if (resp.has_manual_location || !this.config.hasLocation()) {
          this.latitude = resp.latitude;
          this.longitude = resp.longitude;
          toUpdate.latitude = resp.latitude;
          toUpdate.longitude = resp.longitude;
        }

        this.config.updateProfile(toUpdate, true).then(
          (_) => {
            setTimeout((_) => {
              this.handleNotifications();
            }, 1500);
            this.config.isAppLatestVersion = this.isAppLatestVersion();
            /*
           * no need to sync user params from the backend
          this.analyticsService.tryInit(this.config.getProfile(), 
                                        this.userId, 
                                        this.pushService.hasPermission);
                                        */
          },
          (_) => {}
        );
        if (!this.config.get("cachedPicture")) {
          logc.info("Caching picture in session...");
          this.config.processCachePicture(this.config.get("picture"));
        }
        resolve();
      }, reject);
    });
  }

  isAppLatestVersion() {
    if (this.config.isPWA) return true;

    if (!this.config.version || !this.latestVersion) return true;

    let isLatestVersion = this.utils.isLatestVersion(
      this.latestVersion,
      this.config.version
    );

    return isLatestVersion;
  }

  async updateSessionInfo() {
    return this.deviceInfo();
  }

  setPosition(coords) {
    this.latitude = coords.latitude;
    this.longitude = coords.longitude;
  }

  deviceInfo() {
    let data: any = {
      platform: this.config.platformName,
      app_version: this.config.version,
    };
    // OneSignal's player_id / user_id - the same for both web and native pushes.
    if (this.pushService.providerId) {
      data.push_provider_id = this.pushService.providerId;
    }

    const deviceInfo = this.config.getFlag("device_info");
    if (deviceInfo) {
      data.flags = deviceInfo;
    }

    //iOS will return the device token even if the user is not subscribed to the pushes.
    //For web push it is 'registration_id', for native stuff it is 'device_token'.
    if (this.deviceStateMachine.env.pushesReady) {
      logc.red("this.deviceStateMachine.env.pushesReady");
      data.device_token = "";
      if(this.pushService.hasPermission && this.pushService.deviceToken) {
        data.device_token = this.pushService.deviceToken;
      }

    }
    if (this.deviceStateMachine.env.locationReady && this.latitude) {
      data.latitude = this.latitude;
      data.longitude = this.longitude;
    }
    logc.green("device info finalized: ", data);
    return data;
  }

  isLocationEnabled() {
    return this.longitude;
  }

  onLocationSet({ latitude, longitude, attempts, source }) {
    let latitudeWas = this.config.get("latitude");

    this.latitude = latitude;
    this.longitude = longitude;

    this.config.updateProfile({
      latitude: this.latitude,
      longitude: this.longitude,
    });

    let key = "";
    if (latitudeWas === null) {
      key = "location_set";
    } else {
      key = "location_changed";
    }
    this.analyticsService.trackEvent({ key, value: 1, source, attempts });
    this.analyticsService.trackProperty({
      key: "location",
      value: JSON.stringify({ latitude, longitude }),
    });
  }

  backendEvent = (ev) => this.handleEvent(ev.type, ev.data);
  openServerChannel(): Promise<void> {
    console.log("--- opening server channel ---");
    return new Promise(async (resolve, reject) => {
      try {
        // this.pusherService.connect();
        const pusher: any = await this.pusherService.getInstance();
        this.serverChannel = pusher.subscribe("user_" + this.config.get("id"));
        this.serverChannel.bind("backend_event", this.backendEvent);

        console.info("SERVER CHANNEL: ", this.serverChannel);
        window["serverChannel"] = this.serverChannel;

        let presence = pusher.subscribe(
          "presence-user_" + this.config.get("id")
        );
        presence.bind("pusher:subscription_succeeded", function (members) {
          resolve();
        });
        // this.serverChannel.bind('backend_event', this.backendEvent);
        console.log("--- backend events handler bound ---");
      } catch (err) {
        reject(err);
      }
    });
  }

  async closeServerChannel() {
    console.log("--- closing server channel ---");
    try {
      const pusher: any = await this.pusherService.getInstance();
      pusher.unsubscribe("user_" + this.config.get("id"));
      pusher.unsubscribe("presence-user_" + this.config.get("id"));
      this.serverChannel = null;
      this.connectionChangedSource.next(this.isConnected());

      // this.pusherService.disconnect();
    } catch (e) {
      console.log("Could not close the server channel.", e);
    }
  }

  async logout(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.stateMachine.exec("logout");
      } catch (e) {}

      const id = this.config.get("id");
      if (id != -1) {
        const tags = { klass: "opening", func: "logout()" };
        const extras = {
          user_id: id,
          logs: this.consoleService.getLogs() || [],
        };
        this.errorTrackingService.sendMessage(
          `Logout issue. User still has an ID in config, ${id}`,
          tags,
          extras
        );
      }
      resolve();
    });
  }

  onPause() {
    this.lastActiveAt = new Date();
    this.stateMachine.exec("pause");
  }

  onResume() {
    this.stateMachine.exec("resume");
  }

  onConnected(info) {
    this.stateMachine.exec("connect");
  }

  onDidDisconnect() {
    this.lastActiveAt = new Date();
    this.stateMachine.exec("disconnect");
  }

  isAuthenticated() {
    return this.authenticated;
  }
}
