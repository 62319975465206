<ion-content fullscreen class = "state-page state-neutral">
  <div class="closeBtn">
    <ion-icon name="arrow-back-outline" (click)="closeModal()"></ion-icon>
  </div>
  <div class="vertically-centered">
    <div class = "state-content">
      <ion-slides [options]=sliderOptions>
        <ion-slide>
          <div class="content swiper-zoom-container" [style.height.px]="containerHeight">
          <img src = "{{imageFullsize}}"
               (error) = "this.onImageError($event)">
          </div>
        </ion-slide>
      </ion-slides>
    </div>
  </div>
</ion-content>