import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { PremiumFeaturesTableComponent } from "./premium-features-table.component";

@NgModule({
  declarations: [PremiumFeaturesTableComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [PremiumFeaturesTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PremiumFeaturesTableComponentModule {}