import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {logc} from "../../shared/helpers/log";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DispatcherService} from "../../services/dispatcher.service";

@Component({
  selector: 'instructions',
  template: `
    <div [@openClose] = "isShown ? 'open' : 'closed'"
         class = "instructions-container {{ state?.cssClass }}">
      <div class = "instructions-header">
        <ion-icon *ngIf = "state?.header?.icon" 
                  class = "header-icon" 
                  [name] = "state?.header?.icon"></ion-icon>
        <span class = "instructions-title" [innerHTML] = "state?.header?.title"></span>
        <span *ngIf = "state?.header?.details" 
              class = "instructions-details" 
              [innerHTML] = "state?.header?.details"></span>
      </div>
      <div class = "instructions-buttons">
        <div *ngFor = "let button of state?.buttons; let index = index" class = "button-container">
          <match-me-popover *ngIf = "button?.onDisabled | async"
                            class = "highlights"
                            [class.fade-in-animation] = "(button?.onDisabled | async)"
                            [reason] = "button?.disablingReason"></match-me-popover>
          <div class = "button {{ button?.cssClass }}"
               [class.disabled] = "button?.onDisabled | async"
               (click) = "handler($event, button)">
            {{ button?.text }}
            <ion-icon *ngIf = "button?.icon" [name] = "button.icon"></ion-icon>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./instructions.component.scss'],
  animations: [
    trigger("openClose", [
      state("open", style({ bottom: "0" })),
      state("closed", style({ bottom: "-400px" })),
      transition("open => closed",[ animate("0.5s 0s ease-in")]),
      transition("closed => open",[ animate("0.5s 0s ease-out")]),
    ])
  ]
})
export class InstructionsComponent implements OnInit {
  @Input("destroySelf") destroySelf = () => {};
  @Input() state: any = {};
  @Input("close") close: any;

  public isShown: boolean = false;

  constructor(private dispatcherService: DispatcherService) {}

  ngOnInit() {
    // logc.info("State: ", this.state);
    this.state.buttons.map((button) => {
      if(!button.onDisabled) {
        button.onDisabled = new BehaviorSubject(false);
      }
      return button;
    })
    setTimeout(() => this.show(),0)
    this.close.subscribe(() => this.hide());
  }

  show() {
    this.isShown = true;
  }

  hide() {
    this.isShown = false;
    this.dispatcherService.matchMePopoverSource.next({
      action: "close", reason: ""
    });
    setTimeout(() => this.destroySelf(), 500);
  }

  processing: boolean = false;
  async handler(event, { text, callback }) {
    let processingTimeout = ['Pass', 'More options'].includes(text) ? 0 : 5000;
    if(!this.processing) {
      this.processing = true;
      callback(event);
      setTimeout(() => this.processing = false, processingTimeout);
    }
  }
}
