import { Component, Input, OnInit } from '@angular/core';
import {ModalController, ToastController} from '@ionic/angular';
import { InstagramService } from 'src/app/services/instagram.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import SwiperCore, { SwiperOptions, Pagination } from "swiper";
import { SwiperComponent } from 'swiper/angular';
import { DEFAULT_SLIDER_TRANSITION_TIME } from 'src/app/shared/helpers/time-helpers';

SwiperCore.use([Pagination]);
@Component({
  selector: 'app-instagram-modal',
  template: `
    <div (click) = "close()" class = "close-button">
      <ion-icon name = "close"></ion-icon>
    </div>
    <swiper *ngIf = "sliderDidInit"
            class = "instagram-swiper"
            [config] = "swiperOptions"
            (click) = "exitZoom()"
            (imagesReady) = "slidesLoaded()">
      <ng-template swiperSlide *ngFor = "let media of displayedMedia; let i = index">
        <img [class.hidden] = "onSlidesLoadedObs | async" 
              [src] = "media.media_url" 
              alt = "Media {{i}} of {{media.username}}'s' feed" 
              (click) = "noPropag($event)">
        <footer class="media-info" [class.hidden]="onSlidesLoadedObs | async">
          <div *ngIf="media.caption" class="media-caption" >{{media.caption}}</div>
          <div class="media-date">
            {{ media.timestamp | date: 'MMM dd, yyyy'}}
          </div>
        </footer>
      </ng-template>
    </swiper>
    <div *ngIf="!currentUserProfile && currentUserInstagramToken == 'none'" class="button-login-container">
      <ion-button expand="block" shape="round"
                  (click)="loginToInsta()">
        <ion-icon name="instagram-colored"></ion-icon>
        Connect My Instagram
      </ion-button>
    </div>
  `,
  styleUrls: ['./instagram-modal.component.scss']
})
export class InstagramModalComponent implements OnInit {
  @Input() displayedMedia :any;
  @Input() currentUserProfile: boolean;
  @Input() currentUserInstagramToken: any;
  @Input() initialSlide: number;
  onSlidesLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  onSlidesLoadedObs: Observable<boolean>;

  public swiperOptions: SwiperOptions = null;

  public sliderDidInit: boolean = false;

  constructor(public modalCtrl: ModalController,
              public instagramService: InstagramService) { }

  ngOnInit() {
    this.onSlidesLoadedObs = this.onSlidesLoadedSubject.asObservable();
  }

  ionViewDidEnter() {
    this.swiperOptions = {
      initialSlide: this.initialSlide,
      updateOnWindowResize: true,
      pagination: {
        type: "bullets",
        bulletClass: "pagination-bullet",
        bulletActiveClass: "pagination-bullet-active",
        clickable: true
      },
      speed: DEFAULT_SLIDER_TRANSITION_TIME,
    }
    this.sliderDidInit = true;
  }

  exitZoom(){
    this.modalCtrl.dismiss();
  }
  noPropag($event){
    $event.stopPropagation()
  }
  async loginToInsta(){
    this.instagramService.initInstagramLogin();
    this.modalCtrl.dismiss();
  }
  slidesLoaded(){
    this.onSlidesLoadedSubject.next(false);
  }
  close(){
    this.modalCtrl.dismiss();
  }
}
