import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "namesOf"
})
export class NamesOfPipe implements PipeTransform{
  transform(tribe: any, targetUsersIds: number[]): string {
    return tribe.users
      .filter(user => targetUsersIds.includes(user.id))
      .map(user => user.first_name)
      .reduce((label, name) => label += ` & ${ name }`, '');
  }
}
