import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "chat-notice",
  template: `
    <div *ngIf = "message?.user_id == -999 && message?.meta_data?.notice" 
          class = "flex flex-col items-center text-small text-label mb-[30px] mt-[15px]">
      <ion-icon *ngIf = "message?.meta_data?.icon" 
                [name] = "message?.meta_data?.icon" 
                class = "text-huge my-[6px] text-label"></ion-icon>
      <div>
        {{ message?.content }} 
        <span *ngIf = "message?.meta_data?.link_text"> 
          · 
          <span class = "text-primary font-semibold" (click) = "clicked()">
            {{ message?.meta_data?.link_text }}
          </span> 
        </span>
      </div>
    </div>
  `
})
export class ChatNoticeComponent {
  @Input() message: any = {};
  @Output() onClick: EventEmitter<null> = new EventEmitter();

  clicked(): void {
    this.onClick.emit(null);
  }
}