import { ApiService } from '../../services/data/api.service';
import { AppService } from '../../services/app';
import { Component, Directive, NgZone, OnInit } from '@angular/core';
import {interval, of, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import { StateMachine } from "../../shared/lib/state-machine/state-machine";
import { RouteTrackerService } from 'src/app/services/route-tracker.service';
import { logc } from 'src/app/shared/helpers/log';
import { fi } from 'date-fns/locale';
import { isDev } from 'src/app/shared/helpers/helpers';
import { PAGE_LOADING_ACCEPTABLE_TIME } from 'src/app/shared/constants/constants';
import { UtilsService } from 'src/app/services/utils.service';
import { SentryService } from 'src/app/services/performance/sentry.service';

@Directive()
export abstract class PageWithStatus {
  public isOnline: boolean = true;
  public isOffline: boolean = false;
  private offlineSource = new Subject();
  private anchorPage: string = null;

  public unsubscribe = new Subject<void>();
  public stateMachine:any = null;
  public debugId = 'page_sm';
  public onPage: boolean = true;

  private loadingStart: any = null;
  private loadingFinish: any = null;

  constructor(public api: ApiService,
              public appService: AppService,
              public routeTrackerService: RouteTrackerService,
              public errorTrackingService: SentryService,
              public utils: UtilsService,
              public ngZone: NgZone = null) {

    this.anchorPage = (window as any).location.pathname;
    this.routeTrackerService
      .onRouteChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ current, previous }) => {
        if(current == this.anchorPage) {
          this.onReturn();
        }
      })

    this.stateMachine = new StateMachine({
      standby: {
        on: () => {},
        init: 'initializing',
        resume: 'connecting',
        drop: ['disconnect']
      },
      initializing: {
        on: async () => {
          this.loadingStart = performance.now();
          await this.init();
          return 'connecting';
        },
        pause: 'standby'
      },
      connecting: {
        on: () => {
          return new Promise( async (resolve, reject) => {
            this.onConnecting();
            const connected = await this.checkConnection();
            resolve(connected ? 'connected' : 'offline');
          });
        },
        pause: 'standby'
      },
      connected: {
        on: async () => {
          try {
            let data = await this.loadData();
            this.onDidLoadData(data);
            this.isOnline = true;
            this.isOffline = false;
            this.onConnected();
            this.offlineSource.next(false);
          } catch (e) {
            //Error 0 for instance sometimes happens when reconnect
            //api says we have a good connection but it somehow still fails
            //we retry by going back to connecting, we don't want to go offline because api already knows we are offline
            return (e?.status && e.status < 400) ? 'connecting' : null;
          }
          this.loadingFinish = performance.now();
          if(this.loadingStart) {
            const loadingTime = this.loadingFinish - this.loadingStart
            logc.crimson(`** ${ this.debugId } SM took ${ (loadingTime / 1000).toFixed(2) }s to render`);
            const pageLoadedTooLong = loadingTime > PAGE_LOADING_ACCEPTABLE_TIME;
            logc.inf("pageLoadedTooLong: ", pageLoadedTooLong);
            this.loadingStart = null;
            if(pageLoadedTooLong) {
              const msg = `Page took too long to load. Limit is ${ PAGE_LOADING_ACCEPTABLE_TIME }`;
              const tags = { klass: "PageWithStatus", func: "connected:" };
              const extras = { data: this.utils.getPerformanceInfo({ loadingTime, debugId: this.debugId })};
              this.errorTrackingService.sendMessage(msg, tags, extras);
            }
          }
        },
        pause: () => {
          this.onPause();
          return 'standby'
        },
        disconnect: 'disconnected',
      },
      disconnected: {
        on: async () => {
          this.onDisconnected();
          return 'offline';
        }
      },
      offline: {
        on: async () => {
          this.isOnline = false;
          this.isOffline = true;
          this.offlineSource.next(true);
        },
        pause: 'standby',
        resume: 'connecting',
        drop: ['disconnect']
      },
    }, '', this.appService);
  }

  initSubscriptions() {
    this.api.onDisconnected.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.stateMachine?.exec('disconnect');
    });

    this.api.onConnected.pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      console.log("--- API ON CONNECTED ---", this.debugId);
      if(this.onPage) {
        console.log("--- API ON CONNECTED --- RESUMING ", this.debugId);
        this.stateMachine?.exec('resume');
      }
    });

    this.appService.onPause.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      console.log("--- APP_SERVICE ONPAUSE ---", this.debugId);
      if(this.api.isOnline()) {
        console.log("--- APP_SERVICE ONPAUSE --- PAUSING ", this.debugId);
        this.stateMachine?.exec('pause');
      }
    });

    this.appService.onResume.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      console.log("--- APP_SERVICE ONRESUME ---", this.debugId);
      if(this.api.isOnline()) {
        console.log("--- APP_SERVICE ONRESUME --- RESUMING ", this.debugId);
        this.stateMachine?.exec('resume');
      }
    });
  }

  ngOnInit() {
    this.initSubscriptions();
    this.stateMachine.setIdentifier(this.debugId);
    this.stateMachine.exec('init');
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  init(): Promise<void> {
    return new Promise((resolve, reject) => { resolve() });
  }

  loadData(): Promise<void> {
    return new Promise((resolve, reject) => { resolve() });
  }

  onDidLoadData(data): any {}
  onConnecting(): any {}
  onConnected(): any {}
  onDisconnected(): any {}
  onPause(): any {}
  stateIs( state: string ): boolean {
    return this.stateMachine.state === state;
  }

  checkConnection() {
    return new Promise(async (resolve, err) => {
      if(this.api.isOnline()) {
        resolve(true);
      } else {
        setTimeout( _ => {
          if(this.api.isOnline()) {
            resolve(true);
          } else {
            setTimeout( _ => {
              resolve(this.api.isOnline());
            }, 1000);
          }
        }, 500);
      }
    });
  }
  
  onReturn(): void {
    if(isDev()) {
      logc.crimson(`* Return to page ${ this.anchorPage } from ${ this.routeTrackerService.getPreviousPage() }`);
    }
  }
}
