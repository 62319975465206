import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NavController} from "@ionic/angular";
import {Config} from "../../services/config/config";
import {Subject} from "rxjs/Subject";
import {takeUntil} from "rxjs/operators";
import {logc} from "../../shared/helpers/log";
import {SearchService} from "../../services/data/search.service";

@Component({
  selector: 'tribes-guide',
  template: `
    <div class = "no-chat-logo-wrapper">
      <div class = "no-chat-logo-container" #noChatLogo>
        <img class = "no-chat-logo" [src] = "logo" alt = "Groups logo">
        <div class = "your-tribes-appear-msg">
          {{ matchingHelpingLabel }}
          <img *ngIf = "!scheduledToMatch"
               #helpArrow
               class = "helping-arrow"
               src = "./assets/img/arrow.png"
               alt = "Helping arrow">
        </div>
        <light-button [buttonBody] = "tutorialButtonBody" (onClick) = "openExplanationSlider()"></light-button>
      </div>
    </div>
  `,
  styleUrls: ['./tribes-guide.component.scss'],
})
export class TribesGuideComponent implements OnInit {
  @ViewChild("noChatLogo") noChatLogo;
  @ViewChild("helpArrow") helpArrow;

  public matchingHelpingLabel: string = "";
  public scheduledToMatch: boolean = false;
  public tutorialButtonBody = {
    icon: 'help-circle-outline',
    text: 'How it works',
    style: 'medium'
  };

  public logo: string = "./assets/img/psychographics.svg";

  private unsubscribe: Subject<any> = new Subject();

  constructor(private navCtrl: NavController,
              private config: Config,
              private searchService: SearchService,
              private renderer: Renderer2) {}


  ngOnInit() {
    this.scheduledToMatch = this.searchService.isScheduledToMatch();
    this.setLogo();
    this.setMatchingHelpingLabel();

    setTimeout(() => {
      const startButton = document.getElementById("startButton");
      if(this.noChatLogo && this.helpArrow) {
        this.createHelpingArrow(this.noChatLogo.nativeElement, startButton, this.helpArrow.nativeElement);
      }
    }, 0);

    this.config
      .onFlagsChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.scheduledToMatch = this.searchService.isScheduledToMatch();
        this.setMatchingHelpingLabel();
        this.setLogo();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openExplanationSlider(): void {
    this.navCtrl.navigateForward('explanation_slider');
  }

  setLogo() {
    this.logo = this.scheduledToMatch
      ? "./assets/img/group-complete.png"
      : "./assets/img/psychographics.svg";
  }

  setMatchingHelpingLabel() {
    this.matchingHelpingLabel =
      this.scheduledToMatch
        ? "You're all set to match"
        : "Let's get you matched!";
  }

  createHelpingArrow(startElement, endElement, arrow) {
    setTimeout(() => {
      const chatTopPosition = this.getTopPosition( startElement );
      const startButtonTopPosition = this.getTopPosition( endElement );
      const diff = startButtonTopPosition - chatTopPosition;
      this.renderer.setStyle(arrow, 'opacity', '1');
      this.renderer.setStyle(arrow, 'height', (diff - 135) + 'px')
    }, 300)
  }

  getTopPosition(element) {
    return element.getBoundingClientRect().top;
  }
}
