import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Config } from './config/config';
import { environment } from '../../environments/environment';
import {logc} from "../shared/helpers/log";


@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  public instance: any = null;
  loaded = false;
  loadPromise: any = null;

  constructor(private platform: Platform, 
              private firebaseX: FirebaseX) {
    (window as any).rc = this;
  }

  load(): Promise<void> {
    if(this.loadPromise && this.loaded) {
      return this.loadPromise;
    }
    
    this.loadPromise = new Promise(async (isReady, failed) => {
      if(this.platform.is('cordova')) {
        this.instance = this.firebaseX;
        await this.instance.fetch(10);
        await this.instance.activateFetched();
        this.loaded = true;
      } else {
        this.instance = (<any> window).firebase.remoteConfig();
        if(environment.name === 'development') {
          this.instance.settings = {
            minimumFetchIntervalMillis: 6000,
            fetchTimeoutMillis: 6000
          }
        }
        try {
          logc.info("- Trying to fetch and activate Firebase... -");
          await this.fetchAndActivate();
          logc.success(`** Firebase fetched and activated after ${ this.fetchAndActivateAttempts } attempts!`);
        } catch(e) {
          logc.error(`** Firebase fetched and activated failed`);
        }

        this.loaded = true;
      }
      isReady(null);
    });
    (<any> window).remoteConfig = this.instance;
    return this.loadPromise;
  }

  fetchAndActivateAttempts: number = 1;
  async fetchAndActivate() {
    try {
      await this.instance.fetchAndActivate();
      return;
    } catch(e) {
      if(this.fetchAndActivateAttempts < 5) {
        this.fetchAndActivateAttempts += 1;
        logc.info(`Trying to fetch and activate firebase again for ${ this.fetchAndActivateAttempts } time...`);
        await this.fetchAndActivate();
      } else {
        throw new Error("Failed to fetch and activate firebase");
      }
    }
  }

  getConfig() {
    return this.instance.getAll();
  }

  async getString(key) {
    if(this.platform.is('cordova')) {
      return this.instance.getValue(key);
    } else {
      return this.instance.getString(key);
    }
  }

  async getBoolean(key) {
    if(this.platform.is('cordova')) {
      return (await this.instance.getValue(key)) === 'true';
    } else {
      return this.instance.getBoolean(key);
    }
  }

  async getObject(key: string): Promise<any> {
    let obj = {};
    try {
      obj = JSON.parse((await this.getString(key)) || "");
    } catch(e) {}
    return obj;
  }
}
