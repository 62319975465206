import { Injectable } from '@angular/core';
import { SessionService } from './data/session.service';
import { UtilsService } from './utils.service';
import { Config } from './config/config';
import { VisionService } from './vision.service';
import { UserService } from './data/user.service';
import { AnalyticsService } from './analytics/analytics.service';
import { ActionSheetController, NavController, AlertController, Platform } from '@ionic/angular';
import { ApiService } from './data/api.service';

@Injectable({
  providedIn: 'root',
})
export class AccountCreatorService {

  public options:any = null;
  public curPage;
  public isCreatingAccount = false;
  //Used for multistep account creation where we only track the signup event at the end

  constructor(public actionSheetCtrl: ActionSheetController,
              public sessionService: SessionService,
              public utils: UtilsService,
              public config: Config,
              public vision: VisionService,
              public userService: UserService,
              public analyticsService: AnalyticsService,
              public api: ApiService,
              public platform: Platform,
              public alertCtrl: AlertController,
              public navCtrl: NavController) {
    this.config.load().then( _ => {
      this.curPage = this.config.getFlag('current_onboarding_page');
    });
  }

  async profilePopup(onOptionSelected: any) {
    setTimeout( _ => {
      this.analyticsService.trackEvent({key: 'signup_prompted', value: 1});
    });

    const hasSms = this.config.hasSms();

    let buttons = [{
        text: hasSms ? 'Manually Enter My Info' : 'Email',
        cssClass: 'writing-colored',
        icon: this.setManualRegIcon(status),
        handler: () => {
          onOptionSelected().then( _ => {
            this.emailSignup();
          });
        }
      }, {
        text: 'Skip for Now',
        icon: !this.platform.is('ios') ? 'arrow-redo-outline' : null,
        handler: () => {
          this.skip(onOptionSelected);
          return true;
        }
      }];

      if(hasSms)
        buttons = buttons.slice(0,-1);

      this.options = await this.actionSheetCtrl.create({
        header: hasSms ? 'Fetch my info from...' : 'Continue with...',
        buttons: buttons
      });

      await this.options.present();
  }

  setManualRegIcon(status) {
    let icon = null;
    if (!this.platform.is('ios') ) {
      icon = 'mail';
      if(status == 'verified') {
        icon = 'writing'
      }
    }
    
    return icon;
  }

  async skip(onOptionSelected) {
    await this.utils.showLoading('Loading...');
    if(this.api.isOnline) {
      this.config.setFlag('profile_skipped', true);
      onOptionSelected().then( _ => {
        this.analyticsService.trackEvent({ key: 'skip_signup', value: 1 });
        this.utils.doneLoading();
        this.onDone();
      }, err => {
        this.utils.doneLoading();
        this.utils.errorContext = 'account-creator skip, error: ' + JSON.stringify(err);
        this.utils.showGenericError({});
      });
    }
  }

  smsSignup(phoneNumber): Promise<void> {
    // await this.utils.showLoading('Loading...');
    return new Promise((resolve, reject) => {
      if(this.api.isOnline) {
        this.analyticsService.trackEvent({key: 'signup_sms_click', value: 1});
        this.utils.doneLoading();
      }
      resolve();
    })
  }

  async emailSignup() {
    await this.utils.showLoading('Loading...');
    if(this.api.isOnline) {
      this.analyticsService.trackEvent({ key: 'signup_email_click', value: 1 });
      const page = 'sign-up-flow';
      this.config.setFlag('current_onboarding_page', page);
      this.utils.doneLoading();
      this.navCtrl.navigateForward(page);
    }
  }

  onDone() {
    if(!this.curPage || this.config.get('currentLevel') > 0) {
      let backPath = this.config.getFlag('signUpStartPage') || '/tabs/tribes';
      return this.navCtrl.navigateForward(backPath).then(() =>  {
        this.utils.doneLoading();
        this.config.setFlag('signUpStartPage', undefined);
      });
    }

    if(this.curPage.match('number') && this.config.get('currentLevel') == 0) {
      const page = 'opening-quiz-time';
      this.config.setFlag('current_onboarding_page', page);
      this.navCtrl.navigateForward('/').then(() => this.utils.doneLoading());
    } else {
      this.actionSheetCtrl.dismiss();
    }
  }

  showEmailTaken() {
    this.alertCtrl.create({
      header: 'Email taken.',
      message: 'We already have a We3 profile under this Email address. ',
      buttons: [{
        text: 'Sign In',
        handler: () => {
          this.navCtrl.navigateForward('/sign_in_choice');
        }
      }, {
        text: 'Retry',
        handler: () => {}
      }]
    }).then(alert => {
      alert.present();
    })
  }
}
