import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { createAsyncToggle, createInfiniteLoop, createTwoSidesInifiniteLoop } from 'src/app/shared/generators';
import { logc } from 'src/app/shared/helpers/log';

interface MainButton {
  button: {
    text: string;
    cssClass?: string;
  },
  icon?: {
    name: string;
    cssClass?: string;
  }
}

@Component({
  selector: 'app-action-button',
  template: `
    <div class = "
      fixed bottom-0 w-full text-base bg-surface 
      flex justify-center items-center p-[15px] 
      shadow-top font-bold gap-4 {{ cssClass }}
    " 
         (click) = "emit($event)">
      <ion-icon *ngIf = "state?.icon?.name" 
                [name] = "state?.icon?.name"
                class = "text-xl" 
                [class] = "state?.icon?.cssClass"></ion-icon>
      <span [class] = "state?.button?.cssClass">
        {{ state?.button?.text }}
      </span>
    </div>
  `,
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements OnInit {
  @Input("cssClass") cssClass: string = "";
  @Input("states") states: MainButton[] = [];
  @Input("callback") callback: Function = () => {};
  @Input("onParentDestroyed") onParentDestroyed: Subject<any> = null;
  @Output("onClick") onClick: EventEmitter<any> = new EventEmitter();

  public state: MainButton = null;

  @Input("destroySelf") destroySelf = () => {};

  private getState: any = null;

  constructor() { }

  ngOnInit() {
    logc.info('callback: ', this.callback);
    this.onParentDestroyed.subscribe(() => this.destroySelf());
    this.getState = createTwoSidesInifiniteLoop(this.states);
    this.state = this.getState.current();
  }

  async emit(event) {
    console.log("state: ", this.state);
    this.state = this.getState.next();
    console.log("state next(): ", this.state);
    try {
      console.log("Vote attempt...");
      console.log("callback: ", this.callback)
      const data = await this.callback();
      console.log("data: ", data);
      console.log("Voted!");
    } catch(e) {
      console.log("Failed to vote, revert...");
      this.state = this.getState.prev();
    }
    console.log('passed');
    this.onClick.emit(event);
  }
}
