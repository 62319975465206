import { Injectable, NgZone } from "@angular/core";
import {
  ToastController,
  AlertController,
  ModalController,
  PopoverController,
  Platform,
  NavController,
} from "@ionic/angular";
import { takeUntil, take } from "rxjs/operators";
import { Subject } from "rxjs/Subject";
import { first } from "rxjs/operators";
import { Config } from "../config/config";

import { SearchService } from "../data/search.service";
import { AppService } from "../app";
import { AnalyticsService } from "../analytics/analytics.service";
import { FeedbackService } from "../popups/feedback.service";

import { environment } from "../../../environments/environment";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { StatementsService } from "../data/statements.service";
import { ApiService } from "../data/api.service";
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { DispatcherService } from "../dispatcher.service";
import { RouteTrackerService } from "../route-tracker.service";
import { SentryService } from "../performance/sentry.service";
import { PUSHES_RECHECK_DELAY } from "../../shared/constants/constants";
import { PusherService } from "../pusher.service";
import { isEmpty } from "../../shared/helpers/lodash";
import { createLogger, logc } from "../../shared/helpers/log";
import { MainErrorHandlerService } from "../performance/main-error-handler.service";
import {
  getDeviceInfo,
  getOneSignalServiceWorkerPath,
  isIosPWA,
  msToSec,
} from "../../shared/helpers/helpers";

const LOG_STYLES = { background: "black", color: "red" };
const log = createLogger(LOG_STYLES);
const PUSH_CHANGES_PROCESSING_TIMEOUT = 500;

interface PushStateChanges {
  pushToken: any;
  userId: any;
  wasSubscribed: any;
  isSubscribed: any;
}

@Injectable({
  providedIn: "root",
})
export class PushNotification {
  private pushChangedSource: Subject<any> = new Subject<any>();
  private newPushEventSource: Subject<any> = new Subject<any>();
  private goToTribeRequestedSource: Subject<any> = new Subject<any>();
  private readySource: any = new Subject();
  public onPushChanged: any;
  public onNewPushEvent: any;
  public onGoToTribeRequested: any;
  public onReady: any;

  public wasPrompted: boolean = false;
  public hasPermission: boolean = false;

  public isSubscribed: boolean = false;

  public providerId: any = null;
  public deviceToken: any = null;

  public initialized: boolean = false;
  public ready = false;
  public oneSignalSDK: any = {};
  public pushes: any = {};
  public oneSignal: any = null;
  public webPushStatus: any;
  private attemptsCount = 0;
  readonly isDev: boolean;
  private webPushesInitialized: boolean = false;
  private webPushesInitStartTime: any = 0;

  constructor(
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public platform: Platform,
    public config: Config,
    public ngZone: NgZone,
    public appService: AppService,
    public navCtrl: NavController,
    public pusher: PusherService,
    public dispatcherService: DispatcherService,
    public analyticsService: AnalyticsService,
    private statementsService: StatementsService,
    private feedbackService: FeedbackService,
    private api: ApiService,
    private settings: OpenNativeSettings,
    private errorTrackingService: SentryService,
    private routeTrackerService: RouteTrackerService,
    private firebase: FirebaseX,
    private mainErrorHandler: MainErrorHandlerService
  ) {
    this.onPushChanged = this.pushChangedSource.asObservable();
    this.onNewPushEvent = this.newPushEventSource.asObservable();

    this.onGoToTribeRequested = this.goToTribeRequestedSource.asObservable();
    this.onReady = this.readySource.asObservable();

    this.isDev = this.config.isDev;

    this.appService.onAppReady.subscribe(async (_) => {
      logc.orange("-- Initializing push notifications service --");
      try {
        await this.initializePushNotifications();
      } catch (err) {
        this.mainErrorHandler.handleError(err);
        logc.error("** Notifications init error **: ", err);
      } finally {
        await this.awaitInitialization();
      }
    });
    if (["development", "staging"].includes(environment.name)) {
      (<any>window).pushService = this;
    }
  }

  flushPushesTimeout = null;
  flushPushesConfirmations(n = 0) {
    clearTimeout(this.flushPushesTimeout);
    this.flushPushesTimeout = setTimeout((_) => {
      this.executeflushPushesConfirmations(n);
    }, 3000);
  }

  async executeflushPushesConfirmations(n = 0) {
    let processed = [];
    try {
      for (const [ref_id, push] of Object.entries(this.pushes)) {
        await this.api.post(
          "pushes/delivery_confirmation",
          {
            data: JSON.stringify({ ...push["tap"], ...push["payload"] }),
            platform: this.config.platformName,
            source: "inapp",
          },
          this.api.getHeadersFor(
            "TWx5d4340DeoF-iAiZWS_dpzabwjOqHBtPyCnn9d4tAwYXXSLj3hDgRoidSopbpq_b6Dn0A2UtblFYqs60bJFZR0sfVMqjw0hdT-gQ4CKFE"
          ),
          true
        );
        processed.push(ref_id);
        console.log(
          `PUSH CD: confirmed! push_class:${push["payload"]["a"]["ref_class"]}, ref_id:${ref_id}. n:${n}`
        );
      }
    } catch (err) {
      if (n < 10) {
        console.log(
          `PUSH CD: could not deliver inapp push status, n:${n}`,
          err
        );
        this.flushPushesConfirmations(n + 1);
      }
    } finally {
      processed.forEach((ref_id) => {
        delete this.pushes[ref_id];
      });
    }
  }

  async processPush(payload) {
    if (payload.a.ref_id === -1) {
      return;
    }

    if (!this.pushes[payload.a.ref_id]) {
      this.pushes[payload.a.ref_id] = {
        payload: payload,
      };
    } else {
      if (payload.tap) {
        //Override if it was tapped and already recorded
        this.pushes[payload.a.ref_id]["payload"] = payload;
      }
    }
    this.flushPushesConfirmations();
  }

  initializePushNotifications(nthInit = 0): Promise<void> {
    if (this.platform.is("cordova")) {
      this.firebase.onMessageReceived().subscribe((data) => {
        console.log("Firebase plugin new notification raw :", data);

        var rawPayload = data;
        try {
          var payload: any = {};
          if (rawPayload.custom.toString()[0] == "[") {
            //object
            payload = JSON.stringify(rawPayload.custom);
          } else {
            //already a string
            payload = rawPayload.custom;
          }
          payload = JSON.parse(payload);

          console.log("Push payload", payload);
          this.processPush(payload);
        } catch (err) {
          console.log("PUSH CD: could not parse inapp push 1", err);
        }
      });
    }

    console.log(`-- initializePushNotifications() -- nthInit: ${nthInit}`);
    return new Promise(async (resolve, reject) => {
      if (this.platform.is("cordova")) {
        //Android 13
        console.log("ANDROID 13");
        this.oneSignal = (<any>window).OneSignal.default;
        if (!this.oneSignal) {
          setTimeout(() => {
            return this.initializePushNotifications(nthInit + 1);
          }, 500);
        }
        logc.info("-- Initializing native pushes --");
        this.oneSignal.setAppId(environment.oneSignalAppId);
        this.oneSignal.setNotificationWillShowInForegroundHandler(
          async (notificationEvent) => {
            console.log("--- opened received: ", notificationEvent);
            const notification = notificationEvent.notification;
            const data = notification.additionalData;
          }
        );

        this.oneSignal.setNotificationOpenedHandler(
          async (notificationClickedEvent) => {
            await this.config.load();
            console.log(
              "--- push-notification.ts handleNotificationOpened() opened_notification: ",
              notificationClickedEvent
            );

            if (this.config.get("id") === -1) return;

            const notification = notificationClickedEvent.notification;
            const data = notification.additionalData;

            let path = data.destination_path;
            const isOnHighlights = /tribes\/\d*\/highlights/.test(
              location.pathname
            );
            const isOnChat = /tribes\/\d*\/chat/.test(location.pathname);
            if (!data) return;

            var rawPayload = notification.rawPayload;
            try {
              var payload: any = {};
              rawPayload = JSON.parse(notification.rawPayload);
              if (rawPayload.custom.toString()[0] == "[") {
                //object
                payload = JSON.stringify(rawPayload.custom);
              } else {
                //already a string
                payload = rawPayload.custom;
              }
              payload = JSON.parse(payload);
              payload["tap"] = 1;

              this.processPush(payload);
            } catch (err) {
              console.log("PUSH CD: could not parse inapp push 2", err);
            }

            if (data.standalone_custom_local_action) {
              this.newPushEventSource.next({
                event: data.standalone_custom_local_action,
                payload: data.custom_payload,
              });
            }
            if (data.buttons) {
              let btn;

              if (notificationClickedEvent.action?.actionId) {
                btn = data.buttons.find(
                  (b) => b.id === notificationClickedEvent.action.actionId
                );
              } else {
                btn = data.buttons[0];
              }

              if (btn && btn.path) {
                path = btn.path;
              }

              if (btn && btn.custom_local_action) {
                this.newPushEventSource.next({
                  event: btn.custom_local_action,
                  payload: data.custom_payload,
                });
              }

              if (btn && btn.custom_api_action) {
                this.newPushEventSource.next({
                  event: "APIaction",
                  payload: {
                    customAction: btn.custom_api_action,
                    successText: btn.success_text,
                    failureText: btn.failureText,
                    customPayload: btn.custom_payload,
                  },
                });
              }
            }

            console.log("--- PUSH DATA: ", data);

            if (data.type == "onboarding" && data.destination_view) {
              return this.handleDestinationView(data.destination_view, data);
            }

            const originalPath = path;

            if (data.popup_name) {
              path += `?popup_name=${data.popup_name}`;
            }

            if (originalPath === location.pathname) return;

            if (isOnChat || isOnHighlights) {
              this.dispatcherService.leavingChatSource.next(data);
              await this.navCtrl.navigateBack("tabs/tribes");
            }

            if (path) {
              await this.navCtrl.navigateForward(path);
            }
          }
        );

        this.oneSignal.addSubscriptionObserver(async (state) => {
          console.log("push notifications sub observer", state);
          const userId = state.to.userId;
          this.providerId = userId == "null" ? null : userId;

          const pushToken = state.to.pushToken;
          this.deviceToken = pushToken == "null" ? null : pushToken;

          const isSubscribed = state.to.subscribed;
          this.isSubscribed = isSubscribed == "null" ? null : isSubscribed;

          await this.config.load();
          const tokenWasEmpty = !this.config.get("deviceToken");
          await this.config.updateProfile({ deviceToken: this.deviceToken });

          console.log(
            `Push Observer config object after all: ${
              this.config.getProfile().id
            }}`
          );

          if (
            (!state.from.subscribed && state.to.subscribed) ||
            (tokenWasEmpty && state.to.subscribed)
          ) {
            this.hasPermission = state.to.subscribed;
            if (this.hasPush()) {
              this.analyticsService.trackEvent({
                key: "pushes_turned_on",
                value: 1,
              });
              setTimeout(() => this.recheckPushes(), PUSHES_RECHECK_DELAY);
            }
          }

          this.analyticsService.trackProperty({
            key: "has_push",
            value: this.hasPush(),
          });

          this.pushChangedSource.next(this.deviceToken);
          resolve(null);
        });
        this.initialized = true;
        logc.success("-- Native pushes initialized --");
        resolve(null);
      }

      if (this.config.isPWA) {
        try {
          logc.info(`-- Initializing web pushes --`);
          this.webPushesInitStartTime = performance.now();
          await this.initWebPushNotifications();
          if (this.webPushesInitialized) {
            this.deviceToken = await this.oneSignalSDK.getRegistrationId();
            this.providerId = await this.oneSignalSDK.getUserId();
          } else {
            this.deviceToken = null;
            this.providerId = null;
          }
          return resolve(null);
        } catch (err) {
          logc.error("-- Error while initializing Web Pushes--: ", err);
          if (nthInit == 5) {
            const msg = `-- Web pushes failed initializing after ${nthInit} attempts`;
            const tags = {
              klass: "PushNotification",
              func: "initializePushNotifications()",
            };
            const extras = { one_signal_sdk: this.oneSignalSDK, error: err };
            await this.errorTrackingService.sendMessage(msg, tags, extras);
            return reject();
          } else {
            logc.info("-- Retrying initialize Web Pushes...");
            setTimeout(async () => {
              await this.initializePushNotifications(nthInit + 1);
            }, 500);
          }
        }
      }
    });
  }

  async initWebPushNotifications(nthInit = 1, nthSwInit = 1): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.isDev && !this.config.isStaging) {
        logc.info("-- Dev env, resolving... --");
        return resolve();
      }

      if (this.config.isIosPWA()) {
        logc.info(
          "-- IOS Mobile Browser, doesn't support Push API, resolving..."
        );
        return resolve();
      }

      if (!("serviceWorker" in navigator)) {
        logc.error("Service Worker is not supported");
        this.analyticsService.trackEvent({
          key: "onesignal_service_worker_registration",
          value: 1,
          device_info: getDeviceInfo(),
          sdk_attempts_count: nthInit,
          sw_attempts_count: nthSwInit,
          attempted_init: 0,
          init_outcome: 0,
          elapsed_time_s: 0,
          details: "Service Worker API is not supported",
        });
        return reject({
          description: "not_supported",
          details: "Service Worker not supported",
        });
      }

      if (!("PushManager" in window)) {
        logc.error("-- Push API is not supported for current browser --");
        this.analyticsService.trackEvent({
          key: "web_push_api_not_supported",
          value: 1,
          device_info: getDeviceInfo(),
          details: "Push API is not supported",
        });
        return reject({
          description: "not_supported",
          details: "Push API not supported",
        });
      }

      logc.info(`-- Starting OneSignal SDK initialization for ${nthInit} time`);
      this.oneSignalSDK = window["OneSignal"] || [];
      logc.info("-- OneSignal SDK: ", this.oneSignalSDK);

      if (isEmpty(this.oneSignalSDK)) {
        logc.error("** OneSignalSDK not found **");
        if (nthInit == 15) {
          this.analyticsService.trackEvent({
            key: "onesignal_sdk_init_failed",
            value: 1,
            device_info: getDeviceInfo(),
            sdk_attempts_count: nthInit,
          });
          return reject();
        } else {
          return setTimeout(() => {
            this.initWebPushNotifications(nthInit + 1);
          }, 500);
        }
      } else {
        logc.success(`-- OneSignal SDK initialized after ${nthInit} tries`);
      }
      if ("serviceWorker" in navigator) {
        try {
          logc.info(
            `-- Starting OneSignal service worker initialization ${nthSwInit} time`
          );
          let serviceWorkerPath = "/OneSignalSDKWorker.js";
          if (this.config.isProd) {
            serviceWorkerPath = "/start" + serviceWorkerPath;
          }
          await navigator.serviceWorker.register(serviceWorkerPath);
          logc.success(
            `-- One Signal service worker is registered after ${nthSwInit} tries! --`
          );
          this.analyticsService.trackEvent({
            key: "onesignal_service_worker_registration",
            value: 1,
            device_info: getDeviceInfo(),
            sdk_attempts_count: nthInit,
            sw_attempts_count: nthSwInit,
            attempted_init: 1,
            init_outcome: 1,
            elapsed_time_s: msToSec(
              performance.now() - this.webPushesInitStartTime
            ),
          });
        } catch (err) {
          logc.error(
            `-- One signal failed registering service worker ${nthSwInit} times --`
          );
          logc.error("-- error: ", err);
          this.mainErrorHandler.handleError(err);
          if (nthSwInit == 15) {
            this.analyticsService.trackEvent({
              key: "onesignal_service_worker_registration",
              value: 1,
              device_info: getDeviceInfo(),
              sdk_attempts_count: nthInit,
              sw_attempts_count: nthSwInit,
              attempted_init: 1,
              init_outcome: 0,
              elapsed_time_s: msToSec(
                performance.now() - this.webPushesInitStartTime
              ),
            });
            return reject();
          } else {
            return this.initWebPushNotifications(nthInit, nthSwInit + 1);
          }
        }
      }
      // } else {
      //   try {
      //     logc.info("-- Updating service worker... --");
      //     const registration = await navigator.serviceWorker.getRegistration();
      //     await registration.update();
      //     logc.success("-- Service worker update complete --");
      //   } catch (e) {
      //     logc.error("** Service worker update failed **: ", e);
      //   }
      // }

      this.addWebPushesListener();

      logc.info("-- Initializing Web pushes --");
      try {
        this.oneSignalSDK.push(async () => {
          logc.info("-- OneSignalSDK push callback --");
          this.initOneSignalSDK();
          logc.info("-- OneSignalSDK init callback passed--");
          this.webPushStatus = await this.getWebPushPermissionStatus();
          this.setWebPushSubscriptionChangedListener();
          this.setWebPushNotificationPermissionChangedListener();
          logc.success("-- OneSignalSDK initialized");
        });

        this.deviceToken = await this.oneSignalSDK.getRegistrationId();
        this.providerId = await this.oneSignalSDK.getUserId();
        this.webPushesInitialized = true;
      } catch (e) {
        logc.error("-- Error while setting up OneSignal web push sdk --");
        return reject();
      }

      resolve();
    });
  }

  addWebPushesListener() {
    logc.orange("Adding web pushes listener");
    try {
      navigator.serviceWorker.addEventListener("message", (message) => {
        console.log("Received message in push notification service: ", message);
        if (message.data.isPush) {
          console.log("Data: ", message.data);
        }
      });
    } catch (e) {
      logc.error("** Adding web pushes listener went wrong: ", e);
    }
  }

  initOneSignalSDK() {
    try {
      logc.info("-- Initializing OneSignalSDK --");
      this.oneSignalSDK.init({
        appId: "dfa32a3a-8cde-4d6f-a166-9276360bc940",
        autoRegister: false,
        autoResubscribe: true,
        notificationClickHandlerMatch: "origin",
        notificationClickHandlerAction: "focus",
        welcomeNotification: {
          disable: true,
        },
        notifyButton: {
          enable: false,
        },
        promptOptions: {
          slidedown: {
            enabled: false,
            autoPrompt: false,
          },
        },
      });
    } catch (e) {
      logc.error("** initOneSignalSDK() error: ", e);
    }
  }

  setWebPushSubscriptionChangedListener() {
    this.oneSignalSDK.on("subscriptionChange", (isPermitted) => {
      logc.info("-- oneSignal WEB PUSH subscription is: ", isPermitted);
      if (isPermitted) {
        this.oneSignalSDK
          .getIdsAvailable()
          .then(({ userId, registrationId }) => {
            logc.info(
              "--- sending provider id to API... id:",
              userId,
              registrationId
            );
            this.updatePushInfo({
              deviceToken: registrationId,
              providerId: userId,
            });
          });
      }
    });
  }

  setWebPushNotificationPermissionChangedListener() {
    this.oneSignalSDK.on("notificationPermissionChange", (permissionChange) => {
      let currentPermission = permissionChange.to;
      logc.info("New WEB PUSH permission state is:", currentPermission);
      this.webPushStatus = currentPermission;
    });
  }

  async awaitInitialization() {
    logc.info("-- Await initialization checking permissions --");
    try {
      await this.checkPermissions();
    } catch (e) {
      logc.error("-- awaitInitialization() -> checkPermissions() failed: ", e);
    }
    logc.info("Moving forward");

    logc.info("-- Await initialization Execution --");
    //Try waiting for init until we have a provider ID, sometimes onesignal takes 20/30 seconds to init
    //we just want the pushes to be ready and loaded, not to have a device token
    if (this.platform.is("cordova") && !this.providerId && !this.deviceToken) {
      if (this.attemptsCount == 15) {
        const message = "error pushes not available after 15 tries, aborting.";
        logc.error(message);
        this.errorTrackingService.sendMessage(message);
        this.readySource.next(null);
        this.ready = true;
      }
      setTimeout((_) => {
        this.attemptsCount++;
        this.awaitInitialization();
      }, 2000);
    } else {
      this.attemptsCount = 0;
      console.log(
        `pushes ready with t:${this.deviceToken} - p:${this.providerId}.`
      );
      this.readySource.next(null);
      this.ready = true;
    }
  }

  async getWebPushPermissionStatus(): Promise<any> {
    if (!window.Notification) {
      this.wasPrompted = true;
      return Promise.resolve("not_supported");
    }

    if (this.isDev) {
      return Promise.resolve(window.Notification.permission);
    }

    return this.oneSignalSDK.getNotificationPermission();
  }

  public async requestWebPushPermission(): Promise<void> {
    if (this.isDev) {
      return new Promise((resolve, reject) => {
        window.Notification
          ? window.Notification.requestPermission()
          : reject();
        resolve();
      });
    }
    if (!this.webPushesInitialized) {
      return this.dispatcherService.openPopup("blockedNotificationsPopup");
    }

    logc.info("-- Requesting WEB pushes permissions --");
    try {
      await this.oneSignalSDK.showNativePrompt();
      this.wasPrompted = true;
      logc.success(
        "-- WEB Pushes prompt success, result: ",
        await this.getWebPushPermissionStatus()
      );
    } catch (e) {
      logc.error("-- Prompting WEB PUSH failed --");
      logc.error("Reason: ", e);
    }
  }

  checkPermissions(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.config.isPWA) {
        if (!window.Notification) {
          this.wasPrompted = true;
          this.hasPermission = false;
          return resolve();
        }

        if (this.isDev) {
          if (window.Notification) {
            this.webPushStatus = (<any>window).Notification.permission;
          }
        } else {
          if (this.webPushesInitialized) {
            try {
              this.webPushStatus = await this.getWebPushPermissionStatus();
              this.deviceToken = await this.oneSignalSDK.getRegistrationId();
              this.isSubscribed = await this.oneSignalSDK.getSubscription();
              this.providerId = await this.oneSignalSDK.getUserId();
            } catch (err) {
              logc.error(
                "** checkPermissions PWA getting webPushStatus error **"
              );
              const msg = "oneSignalSDK issue";
              const tags = {
                type: "one-signal",
                klass: "PushNotification",
                func: "checkPermissions()",
              };
              const extras = {
                one_signal_sdk: this.oneSignalSDK,
                web_push_status: this.webPushStatus,
                is_subscribe: this.isSubscribed,
                provider_id: this.providerId,
              };
              this.errorTrackingService.sendMessage(msg, tags, extras);
              return reject();
            }
          }
        }
        logc.info("checkPermissions() webPushStatus: ", this.webPushStatus);
        this.wasPrompted = ["denied", "granted", "not_supported"].includes(
          this.webPushStatus
        );
        this.hasPermission = this.webPushStatus === "granted";

        resolve();
      } else {
        try {
          this.oneSignal.getDeviceState((status: any) => {
            this.hasPermission = status.hasNotificationPermission === true;
            this.wasPrompted =
              status.pushDisabled === true || this.hasPermission;
            const { userId, pushToken, subscribed } = status;

            this.providerId = userId == "null" ? null : userId;
            this.deviceToken = pushToken == "null" ? null : pushToken;
            this.isSubscribed = subscribed == "null" ? null : subscribed;
          });
        } catch (e) {
          logc.error("getDeviceState error: ", e);
          reject();
        }
      }
      logc.indigo(`-${this.config.isPWA ? "WEB" : "NATIVE"} PUSHES-`);
      console.log("providerId: ", this.providerId);
      console.log("deviceToken: ", this.deviceToken);
      console.log("isSubscribed: ", this.isSubscribed);
      console.log("hasPermission", this.hasPermission);
      resolve();
    });
  }

  async webPushUnsubscribe() {
    console.log("--- unsubscribing from web pushes...");
    await this.oneSignalSDK.setSubscription(false);
  }

  async webPushSubscribe() {
    console.log("--- subscribing to web pushes...");
    await this.oneSignalSDK.setSubscription(true);
  }

  handleDestinationView(view, data) {
    this.ngZone.run(() => {
      switch (view) {
        case "bonus_level":
          this.config.unlockBonusLevel().then(() => {
            this.statementsService.openCurrentLevel();
          });
          break;
      }
    });
  }

  removeGroup(group_id) {
    if (this.platform.is("cordova")) {
      try {
        this.oneSignal.removeGroupedNotifications(group_id);
      } catch (e) {
        logc.error("Error removing groupNotification: ", e);
      }
    }
  }

  async recheckPushes() {
    if (this.config.get("id") <= 0) return;

    const pushesStatus: any = await this.api.get("pushes/checkup", {
      user_id: this.config.get("id"),
      device_token: this.deviceToken,
    });

    if (pushesStatus?.status == 0) {
      const tags = { klass: "PushNotification" };
      const extras = {
        user_id: this.config.get("id"),
        device_token: this.deviceToken,
        has_push: this.hasPush(),
      };
      this.errorTrackingService.sendMessage(
        "User turned on pushes but status is 0 in 15 sec",
        tags,
        extras
      );
    }
  }

  updatePushInfo({ deviceToken, providerId }) {
    console.log("--- updating web push info: ", deviceToken, providerId);
    this.deviceToken = deviceToken;
    this.providerId = providerId;
    this.pushChangedSource.next(this.deviceToken);
  }

  hasPush(): boolean {
    if (this.config.platformName === "dev") return false;
    console.log("hasPermission: ", this.hasPermission);
    console.log("hasToken: ", this.hasDeviceToken());

    return this.hasPermission && this.hasDeviceToken();
  }

  hasPushToken() {
    return this.hasDeviceToken();
  }

  hasDeviceToken(): boolean {
    if (this.config.platformName === "dev") return true;

    const t = this.deviceToken || "";
    return t && t.length > 6;
  }

  nthSubAttempt = 0;
  turnOnPushNotifications(): void {
    try {
      this.nthSubAttempt++;
      console.log(`Pushes turnOnPushNotifications nth: ${this.nthSubAttempt}`);
      if (this.platform.is("cordova")) {
        this.oneSignal.disablePush(false);
      } else {
        if (this.providerId && !this.isDev) {
          this.webPushSubscribe();
        }
      }
    } catch (err) {
      logc.error("notifications sub error", err);
      if (this.nthSubAttempt < 15) {
        setTimeout((_) => {
          this.turnOnPushNotifications();
        }, 2000);
      } else {
        const message =
          "error couldn't sub to OneSignal after 15 tries, aborting.";
        console.log(message);
        this.errorTrackingService.sendMessage(message);
      }
    }
  }

  turnOffPushNotifications() {
    this.nthSubAttempt = 0;
    if (this.platform.is("cordova")) {
      this.oneSignal.disablePush(true);
    }
  }

  async showTurnedOffPrompt() {
    if (this.config.isPWA) {
      if (isIosPWA()) {
        const buttons = [
          {
            text: "Got it",
            handler: () => this.dispatcherService.openPopup("downloadAppPopup"),
          },
        ];
        const alert = await this.alertCtrl.create({
          header: "Notifications are needed to match in groups",
          message:
            "We need to notify you about new groups and incoming messages, but iPhones don’t allow notifications in the mobile browser. Get the full app to allow notifications.",
          buttons,
          cssClass: "z-index-35000",
        });
        await alert.present();
      } else {
        this.dispatcherService.downloadAppCaseSource.next(true);
      }
      return;
    }

    let title = "To allow notifications, go to your settings";
    let message = "Make sure the Notifications switch is set to ON.";

    const confirm = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: "Open Settings",
          handler: () => this.settings.open("application_details"),
        },
      ],
    });
    await confirm.present();
  }

  requestPushPermissionAndInit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.hasPermission) return resolve();

      if (this.wasPrompted) {
        await this.showTurnedOffPrompt();
        return reject({
          hasPermission: this.hasPermission,
          wasPrompted: this.wasPrompted,
        });
      }

      if (this.config.isPWA) {
        await this.requestWebPushPermission();
        return resolve();
      }

      this.appService.skipNextPause(5000);

      this.oneSignal.promptForPushNotificationsWithUserResponse(
        (didUserAccept: any) => {
          console.log("--- Native prompt didUserAccept: ", didUserAccept);
          this.appService.resetPauseSkipping();
          this.wasPrompted = true;
          this.hasPermission = didUserAccept;

          if (!didUserAccept) {
            return reject({
              hasPermission: this.hasPermission,
              wasPrompted: this.wasPrompted,
            });
          } else {
            log("-- Resolving requestPushPermissionAndInit() --");
            resolve();
          }
        }
      );
    });
  }

  isReadyForMatching(): boolean {
    if (this.config.isPWA) {
      if (!("PushManager" in window)) {
        return false;
      }
      return this.wasPrompted;
    } else {
      return this.hasPush();
    }
  }

  webPushesSupported() {
    return this.webPushStatus !== "not_supported";
  }
}
