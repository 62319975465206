<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click) = "close()"></ion-back-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class='state-page state-neutral after-onboarding-steps'>
  <div class="vertically-centered">
    <div class = "state-content">
      <div class="content fade-in-icon">
        <img id = "main-img" src="./assets/img/maintenance.png"/>
      </div>
    </div>

    <div class = "state-header">
      <div class = "state-title">Server Down</div>
      <div class = "state-sub-title">Somebody seems to be messing with our servers. Try again in 2 minutes? It’s not you. It’s Us.</div>
    </div>

    <div class = "state-footer">
      <div class = "button-wrapper">
        <button class='btn-large-action turquoise-gradient-btn turquoise-btn-shadow' large (click)="retry()">
          Retry now
        </button>
        <button class='btn-large-action blank-button' large (click)="flappy()">
          Play game for 30 seconds
        </button>
      </div>
    </div>
  </div>
</ion-content>
