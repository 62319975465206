import { Injectable } from '@angular/core';
import {Platform, AlertController, ModalController, NavController} from '@ionic/angular';
import { Config } from '../config/config';
import { UtilsService } from '../utils.service';
import { Subject } from 'rxjs/Subject';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { AppService } from '../app';
import { RouteTrackerService } from "../route-tracker.service";
import { log, logc } from "../../shared/helpers/log";
import { SentryService } from "../performance/sentry.service";
import { LOCATION_ATTEMPT_RETRY_TIMEOUT } from "../../shared/constants/constants";
import { GeolocationError } from "../../shared/enums/geolocation-error.enum";
import {AnalyticsService} from "../analytics/analytics.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ApiService} from "../data/api.service";

const LOCATION_ATTEMPTS_LIMIT = 15;
const LOCATE_REATTEMPT_TIMEOUT = 10000;

interface GoogleGeolocationResponse {
  accuracy: number;
  location: {
    lat: number,
    lng: number
  }
}

interface ClosestCityInfo {
  name: string;
  iso2: string;
}

interface Coords {
  latitude: number | string;
  longitude: number | string;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public locationChangedSource = new Subject();
  private readySource: any = new Subject();
  public onLocationChanged: any;
  public onReady: any;

  private googleGeolocationUrl = `https://www.googleapis.com/geolocation/v1/geolocate?key=${ environment.googleMapsApiKey }`;

  constructor(private config: Config, 
              private utils: UtilsService,
              private geolocation: Geolocation,
              private diagnostic: Diagnostic,
              private alertCtrl: AlertController,
              private platform: Platform,
              private modalCtrl: ModalController,
              private appService: AppService,
              private settings: OpenNativeSettings,
              private http: HttpClient,
              private api: ApiService,
              private navCtrl: NavController,
              private errorTrackingService: SentryService,
              private analyticsService: AnalyticsService) {
    this.onLocationChanged = this.locationChangedSource.asObservable();
    this.onReady = this.readySource.asObservable();

    if(this.config.isDev || this.config.isStaging) {
      (window as any).locationService = this;
    }

    this.appService.onAppReady.subscribe(() => {
      this.attemptToLocate().then(() => {
          logc.success(`-- Attempt to locate succeed --`);
        }).catch((err) => {
          logc.error('** Attempt to locate **: ', err);
        }).finally(() => {
          logc.indigo('-- Attempt to locate finalizing... --');
          this.readySource.next(null);
        });
    });
  }

  async getLocationServiceStatus() {
    return this.diagnostic.getLocationAuthorizationStatus();
  }

  /*TODO this should be merged with utils.ts#requestPermission 
  * we also want to include skipNextPause I think in the utils.ts#requestPermission 
  * Android 11 limitations: https://github.com/dpa99c/cordova-diagnostic-plugin/issues/422 */
  locationPermission(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if(!this.platform.is('cordova'))
        return resolve();

      if(!await this.hasLocationEnabled())
        return reject('locationDisabled');

      this.appService.skipNextPause(5000);
      this.diagnostic.getLocationAuthorizationStatus().then((status) => {
        console.log('*** getLocationAuthorizationStatus: ', status);
        switch(status) {
          case this.diagnostic.permissionStatus.NOT_REQUESTED:
          case this.diagnostic.permissionStatus.DENIED_ONCE:
            this.diagnostic.requestLocationAuthorization().then( (status) => {
              if([this.diagnostic.permissionStatus.GRANTED, 
                this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE].includes(status)) {
                resolve();
              } else {
                reject();
              }
            });
            break;
          case this.diagnostic.permissionStatus.DENIED_ALWAYS:
            if(this.platform.is("android")) {
              /*new android 11, DENIED_ALWAYS can be there when popup is dismissed and when allowed this time and session ends, but it can still be requested again*/
              this.diagnostic.requestLocationAuthorization().then( (status) => {
                  if([this.diagnostic.permissionStatus.GRANTED, 
                    this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE].includes(status)) {
                    resolve();
                  } else {
                    reject();
                  }
              });
            } else {
              reject();
            }
            break;
          case this.diagnostic.permissionStatus.GRANTED:
          case 'authorized_when_in_use': /*new android 11*/
          case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
            resolve();
            break;
        }
        this.appService.resetPauseSkipping();
      }, _ => {
        this.appService.resetPauseSkipping();
      });
    });
  }

  hasLocationPermission() {
    return new Promise((resolve, reject) => {
      if(this.platform.is('cordova')) {
        this.diagnostic.isLocationAvailable().then((data: any) => {
          resolve(data);
        }, (err: any) => {
          resolve(false);
        });
      } else {
        resolve(this.config.hasLocation());
      }
    });
  }

  hasLocationEnabled(){
    this.diagnostic.isLocationEnabled().then(status => console.log('IS GEOLOCATION ENABLED? ', status));
    return this.diagnostic.isLocationEnabled();
  }
  locationAttempts: number = 1;
  locate(options: any = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      this.analyticsService.trackEvent({ key: "location_requested", value: 1, source: "device" });
      if(this.platform.is('cordova')) {
        this.geolocation.getCurrentPosition({
          maximumAge: 600000,
          timeout: 60000,
          enableHighAccuracy: true // If this option it's desactivated, low accuracy will be dependant of the Phone GPS only
        }).then((data: any) => {
          console.log('--- location.service.ts locate() geolocation.getCurrentPosition() data: ', data);
          logc.purple(`Successfully located after ${ this.locationAttempts } attempts`);
          // this.locationAttempts = 0;
          this.locationChangedSource.next({
            latitude: data.coords.latitude, 
            longitude: data.coords.longitude,
            source: "device",
            attempts: this.locationAttempts
          });
          resolve();
        }, async (error) => {
          logc.error('location error: ', error);

          if(error.code == GeolocationError.TIMEOUT
            && this.locationAttempts < LOCATION_ATTEMPTS_LIMIT
            && !this.config.get("latitude")
          ) {
            setTimeout(() => {
              this.locate();
              this.locationAttempts += 1;
            }, LOCATE_REATTEMPT_TIMEOUT);
          }

          if(this.locationAttempts == LOCATION_ATTEMPTS_LIMIT) {
            this.analyticsService.trackEvent({
              key: "location_request_failed",
              value: 1,
              attempts: this.locationAttempts,
              source: "device"
            })

            const message = `Location failed after ${ LOCATION_ATTEMPTS_LIMIT } attempts`;
            const tags = { klass: "LocationService", func: "locate()" };
            await this.errorTrackingService.sendMessage(message, tags);

            this.locationAttempts = 0;
          }
          return this.fallbackLocation(error).then(resolve, reject);
        });
      } else {
        let browserLocation = (<any>navigator).geolocation;
        browserLocation.getCurrentPosition((data: any) => {
          this.locationChangedSource.next({
            latitude: data.coords.latitude,
            longitude: data.coords.longitude,
            attempts: this.locationAttempts,
            source: "pwa"
          });
          resolve();
        }, error => {
          console.log('location error (pwa): ', error);
          if(error.code == 1) {
            this.analyticsService.trackEvent({
              key: "rejected_location_permissions",
              value: 1
            })
          }
          return this.fallbackLocation(error).then(resolve, reject);
        });
      }
    });
  }

  fallbackLocation(error): Promise<void> {
    return new Promise((resolve, reject) => {
      if(this.config.get('latitude')){ 
        console.log('location fallback used');
        this.locationChangedSource.next({
          latitude: this.config.get('latitude'),
          longitude: this.config.get('longitude')
        });
        resolve();
      } else {
        console.log('location fatal');
        reject(error);
      }
    });
  }

  locateAttemptsCount = 0;
  locateAttemptTotal: number = 0;
  attemptToLocate() {
    return new Promise(async (resolve, reject) => {
      const hasLocationPermission = await this.hasLocationPermission();
      if(!hasLocationPermission) {
        return this.fallbackLocation('No permission').then(resolve, reject);
      }

      console.log('locate attempt:', this.locateAttemptsCount);
      this.locate().then(resolve, error => {
        //When it fails on normal boot, try again 3 times
        //1 PERMISSION DENIED
        console.log('locate error code:', error.code);
        if(error.code === 1) reject(error);

        if(this.locateAttemptsCount == 4) {
          const tags = { klass: "LocationService", func: "attemptToLocate()" };
          const extras = { error: error, attempt: this.locateAttemptTotal };
          this.errorTrackingService.sendMessage("tried to locate 4 times and failed", tags, extras)
          this.locateAttemptsCount = 0;
          setTimeout(() => this.attemptToLocate(), LOCATION_ATTEMPT_RETRY_TIMEOUT);
          return reject(error);
        }

        setTimeout(_ => {
          this.attemptToLocate().then( _ => {}, _ => {});
          this.locateAttemptsCount = this.locateAttemptsCount + 1;
          this.locateAttemptTotal += 1;
        }, 3000);
        reject(error);
      });
    });
  }

  async locateWithPermissions() {
    return new Promise((resolve, reject) => {
      this.locationPermission().then( _ => {
        this.locate().then( _ => {
          resolve(true);
        }, error => {
          setTimeout(() => this.utils.doneLoading(), 500);
          if(error.code === 1 || error.code === 2) { //Permission denied
            reject('permissionDenied');
          } else {
            const tags = { klass: "LocationService", func: "locateWithPermission()" };
            const extras = { error: error };
            this.errorTrackingService.sendMessage("Location failed", tags, extras);
            reject('locationFailed');
          }
          console.log('locationPermission: ', error);
          reject(error);
        });
      }, error => {
        setTimeout(() => this.utils.doneLoading(), 500);
        logc.error('** Geolocation: Permission denied **');
        this.analyticsService.trackEvent({
          key: "rejected_location_permissions",
          value: 1
        })
        reject('permissionDenied');
      });
    });
  }

  private getGeolocationData(): Promise<GoogleGeolocationResponse> {
    return this.http.post<GoogleGeolocationResponse>(this.googleGeolocationUrl, {}).toPromise();
  }

  private getClosestCity(coords: Coords): Promise<ClosestCityInfo> {
    return this.api.put('position', coords);
  }

  async approximateLocation(): Promise<void> {
    try {
      this.utils.showLoading();
      this.analyticsService.trackEvent({ key: "location_requested", value: 1, source: "ip_approximation" });
      const geolocationData = await this.getGeolocationData();
      const { lat: latitude, lng: longitude } = geolocationData.location;
      this.locationChangedSource.next({ latitude, longitude });
      logc.success("** Fetched approx. coords: ", { latitude, longitude });

      const closestCity = await this.getClosestCity({ latitude, longitude });
      logc.success("** Fetched closest city info: ", closestCity);

      await this.config.updateProfile({ closestCity });
      this.showChangingLocationToast(closestCity.name);
    } catch(err) {
      this.utils.showGenericError({});
      this.analyticsService.trackEvent({ key: "location_request_failed", value: 1, source: "ip_approximation" });
      logc.error("** Getting approximated location error: ", err);
    } finally {
      await this.utils.doneLoading();
    }
  }

  showChangingLocationToast(city: string) {
    this.utils.showGenericMessage(
      `Location set to ${ city }`,
      5000,
      "Update",
      () => this.navCtrl.navigateForward("status_page")
    )
  }
}
