import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FeedbackCheckboxListQuestion, FeedbackRadioListAnswer} from "../../../shared/interfaces/feedback-modal";
import {ModalController} from "@ionic/angular";
import {values} from "../../../shared/helpers/helpers";
import {BuilderService} from "../../../services/helpers/builder.service";
import {DoneButtonComponent} from "../../done-button/done-button.component";
import {Subject} from "rxjs/Subject";
import {fromEvent} from "rxjs";
import {takeUntil} from "rxjs/internal/operators";

@Component({
  selector: 'checkbox-list',
  template: `
    <ion-list>
      <ion-item *ngFor = "let answer of currentQuestion?.answers; let index = index" 
                lines = "none"
                (click) = "toggle($event, answer, index)">
        <ion-avatar *ngIf = "!answers[index]?.checked; else checkmark" slot = "start">
          <img *ngIf = "answer.image" [src] = "answer.image" alt="">
          <span *ngIf = "answer.emoji" [innerHTML] = "answer.emoji"></span>
          <ion-icon *ngIf = "answer.icon" [name] = "answer.icon"></ion-icon>
        </ion-avatar>
        <ng-template #checkmark>
          <ion-avatar slot = "start">
            <ion-icon class = "success-color" name = "checkmark-circle"></ion-icon>
          </ion-avatar>
        </ng-template>
        <ion-label *ngIf = "!showOtherOption || !answer.detailed; else detailsInputContainer"
                   [class.selected] = "answers[index]?.checked"
                   [innerHTML] = "answer?.text"></ion-label>
        <ng-template #detailsInputContainer>
          <ion-textarea #detailsInput
                        placeholder = "Start typing"
                        [(ngModel)] = "details"
                        (ionChange) = "onTextChange(index)"
                        (ionBlur) = "onTextareaBlur()"></ion-textarea>
        </ng-template>
      </ion-item>
    </ion-list>
  `,
  styleUrls: ['./checkbox-list.component.scss', './../radio-list/radio-list.component.scss'],
})
export class CheckboxListComponent implements OnInit {
  @Input("currentQuestion") currentQuestion: any;
  @ViewChild("detailsInput") detailsInput;

  @Output("onInputOpened") onInputOpened: EventEmitter<any> = new EventEmitter();
  @Output("onInputClosed") onInputClosed: EventEmitter<any> = new EventEmitter();

  public answers: any = {};
  public showOtherOption: boolean = false;
  public details;

  public onParentDestroyed = new Subject();
  private submitting: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private modalCtrl: ModalController,
              private builderService: BuilderService) {
    fromEvent(document, "mousedown")
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((event) => {
        this.submitting =
          (event?.target as any).classList?.contains("done-button-container") ||
          (event?.target as any).classList?.contains("done-button-label")
      })
  }

  ngOnInit() {
    this.addButton();
  }

  onTextareaBlur() {
    this.showOtherOption = !!this.details;
    setTimeout(() => this.onInputClosed.emit(true), 300);
  }

  onTextChange(index) {
    this.answers[index].checked = !!this.details;
  }

  toggle(event, answer, index) {
    if(answer.detailed) {
      this.showOtherOption = true;
      this.onInputOpened.emit(true);
      setTimeout(() => {
        this.detailsInput.setFocus();
      }, 500);
    }

    if(!this.answers[index]) {
      if(answer.detailed) {
        this.answers[index] = { checked: false, text: answer.text }
      } else {
        this.answers[index] = { checked: true, text: answer.text }
      }

    } else {
      if(!answer.detailed)
        this.answers[index].checked = !this.answers[index].checked;
    }

    console.log("answers: ", this.answers);
  }

  addButton() {
    this.builderService.create(DoneButtonComponent, {
      inputs: { onParentDestroyed: this.onParentDestroyed, text: "Continue" },
      outputs: {
        onClick: () => {
          this.onParentDestroyed.next(null);
          this.next();
        }
      }
    })
  }

  next() {
    this.close({
      action: "next",
      current: this.currentQuestion,
      analytics: {
        topic: this.currentQuestion.title,
        answer: this.getFormattedAnswers(),
        details: this.details
      }
    });
  }

  getFormattedAnswers() {
      return values(this.answers)
        .filter((answer: any) => answer?.checked)
        .map((answer: any) => answer?.text);
  }

  close(data) {
    this.modalCtrl.dismiss(data);
  }

  ngOnDestroy(){
    this.onParentDestroyed.next(null);
  }
}
