import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {ChatItemComponent} from "./chat-item.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TribeAvatarModule} from "../tribe-avatar/tribe-avatar.module";
import {PipesModule} from "../../pipes/pipes.module";

@NgModule({
  declarations: [ ChatItemComponent ],
  imports: [
    CommonModule,
    IonicModule,
    TribeAvatarModule,
    PipesModule
  ],
  exports: [ ChatItemComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ChatItemModule {}
