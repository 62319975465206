import {
  Component,
  ElementRef,
  Input,
  NgZone,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
} from "@ionic/angular";
import { Config } from "../../services/config/config";
import { environment } from "../../../environments/environment";
import { UtilsService } from "../../services/utils.service";
import { ApiService } from "../../services/data/api.service";
import { AppService } from "../../services/app";
import { PageWithStatus } from "../../components/page-status/page-with-status";
import { UserService } from "../../services/data/user.service";
import { SessionService } from "../../services/data/session.service";
import {
  PictureStatus,
  ProfilePictureService,
} from "../../services/profile-picture.service";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { StatementsService } from "../../services/data/statements.service";
import { TribeService } from "../../services/data/tribe.service";

import { Subject } from "rxjs/Subject";
import { takeUntil, tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

import { ProfileInfoPopupComponent } from "../profile-info-popup/profile-info-popup";
import { ProfileWorkPopupComponent } from "./profile-work-popup/profile-work-popup";
import { ProfileSchoolPopupComponent } from "./profile-school-popup/profile-school-popup";
import { SettingsPopoverComponent } from "./settings-popover/settings-popover.component";
import { ModalImageComponent } from "../modal-image/modal-image.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountCreatorService } from "src/app/services/account-creator.service";
import { DeclineMenuComponent } from "../decline-menu/decline-menu.component";
import { InstagramComponent } from "../instagram/instagram.component";
import { LocationService } from "../../services/native/location.service";
import { PersonalityType } from "../../pages/profile/personality-type/personality-type.page";
import { PushNotification } from "../../services/push-notification/push-notification";
import { CrashReportService } from "../../services/performance/crash-report.service";
import { MatchesService } from "../../services/matches.service";
import { GenderPipe } from "../../pipes/gender.pipe";
import { CachedPicturesService } from "../../services/data/cached-pictures.service";
import { TribesService } from "../../services/data/tribes.service";
import { TestService } from "../../services/test.service";
import { DispatcherService } from "../../services/dispatcher.service";
import { PopupService } from "../../services/popups/popup.service";
import { getProfileBadge } from "../../shared/helpers/profile-notifications-helper";
import { ColorModeService } from "../../services/native/color-mode.service";
import { SentryService } from "../../services/performance/sentry.service";
import { PROFILE_PROGRESS_MAXIMUM } from "../../shared/constants/constants";
import { DailyTribesService } from "../../services/daily-tribes.service";
import { Progress } from "../../shared/enums/progress.enum";
import { logc } from "../../shared/helpers/log";
import {
  TooltipAppearance,
  TooltipOptions,
  TooltipPosition,
} from "../../directives/tooltip.directive";
import { AudiencesService } from "../../services/analytics/audiences.service";
import { merge } from "rxjs";
import { RouteTrackerService } from "../../services/route-tracker.service";
import { HeartfeltMessagesService } from "../../services/heartfelt-messages.service";
import { blankFn, keys } from "../../shared/helpers/helpers";
import { MatchPreferencesLocation } from "src/app/shared/enums/match-preferences.enum";
import { ModalService } from "src/app/services/popups/modal.service";
import { UserTribeStatus } from "src/app/shared/enums/user-tribe-status.enum";
import { TribeStatus } from "src/app/shared/enums/tribe-status.enum";
import { UpsellingPoppingReason } from "src/app/pages/upselling/upselling.page";

@Component({
  selector: "profile-popup",
  templateUrl: "profile-popup.html",
  styleUrls: ["profile-popup.scss"],
  host: { class: "transparent-header" },
})
export class ProfilePopupComponent extends PageWithStatus {
  @Input() isEditable: any = false;
  @ViewChild("instructions") instructions;
  @ViewChild("aboutMeForm") aboutMeForm;
  @ViewChild(InstagramComponent) instagramComponent;
  @ViewChild("about") about;

  public replaceable: boolean = false;
  public userExpiresSoon: boolean = false;
  public workButtonBody = {
    icon: "briefcase-outline",
    text: "Add Work",
    style: "medium",
  };
  public schoolButtonBody = {
    icon: "school-outline",
    text: "Add School",
    style: "medium",
  };

  public profileStatus = {
    text: "Your account is incomplete.",
    cssClass: this.colorModeService.isDark() ? "yellow-dark" : "yellow",
    timeLeft: "Complete account",
    icon: "warning",
    action: () => this.completeProfile(),
  };

  public testsData = {
    getNotifiedExtras: {
      nearMe: {
        state: { preferences: { location: MatchPreferencesLocation.NearMe } },
      },
      anywhere: {
        state: { preferences: { location: MatchPreferencesLocation.Global } },
      },
      pickPlace: {
        state: {
          preferences: {
            location: MatchPreferencesLocation.PickPlace,
            remote_city_name: "Sidney",
          },
        },
      },
      empty: { state: {} },
    },
  };
  public failedStatus: any = null;
  public failedStatusNotification: any = null;
  public isPlus: boolean = false;
  public isIos: boolean = false;
  public profile: any = {};
  public aboutMe: string = "";
  public status = "";
  public onOpenEdit: any = null;
  public editProfilePicture: boolean = false;
  public unsubscribe = new Subject<void>();
  public fromHighlights: boolean = false;
  public tribeId = null;

  public userId = null;
  public user: any = {};
  private profileId: any = null;

  public notificationName: string = null;
  public remoteCityName: string = "";
  public displayProfile: any = {};
  public unlockPersonality = null;
  public showGenderTip: boolean = false;
  public currentUserInstagramToken: any;
  public loading: Promise<{}>;
  public fromMatches: boolean = false;
  public fromFailedSearch: boolean = false;
  public section;
  public tribe: any = {};
  public onTouchend: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isReadyToPool: boolean = false;
  public isStandby: boolean = false;

  public checkmarkAnimConfig: any = {
    path: "./assets/gif/user-added-approved.json",
    renderer: "canvas",
    autoplay: false,
    loop: false,
  };

  public animation;
  public checkMarked: boolean = false;
  public phoneStatus;
  public isProduction = environment.name == "production";

  public personalityType = {
    code: "",
    name: "",
    summary: "",
    story: "",
    factorAvg: 0,
    topThreeFactors: [
      { image: "", label: "", strength: 0 },
      { image: "", label: "", strength: 0 },
      { image: "", label: "", strength: 0 },
    ],
    hasData: false,
  };
  public profileNotification: any;
  public profileNotifications = {};

  public stepsList = [];

  public compatibility: any;
  public showCompleteMoreLevels: boolean;
  public completeMoreLevels: boolean;

  public hasLocationPermission;

  public progressMenuState: string = "closed";
  public profileFilled: boolean = true;
  public progress = {
    value: 0,
    color: "red",
  };
  public tribeMateDataCached: any;
  public canDirectMessage = false;
  public profileStrengthTooltipOptions: TooltipOptions = {};
  public profilePictureIsReady: boolean = false;
  public inScheduledGroup: boolean = false;

  public scheduledSearchesNumber: number = 0;
  public popupNames: string[] = [];

  constructor(
    public platform: Platform,
    public navCtrl: NavController,
    public userService: UserService,
    public sessionService: SessionService,
    public analyticsService: AnalyticsService,
    public config: Config,
    public testService: TestService,
    public utils: UtilsService,
    public api: ApiService,
    public appService: AppService,
    public ngZone: NgZone,
    public routeTrackerService: RouteTrackerService,
    public profilePictureService: ProfilePictureService,
    public popoverCtrl: PopoverController,
    public tribeService: TribeService,
    private colorModeService: ColorModeService,
    public cachedPicturesService: CachedPicturesService,
    public statementsService: StatementsService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public router: Router,
    private heartfeltMessageService: HeartfeltMessagesService,
    public route: ActivatedRoute,
    public tribesService: TribesService,
    private popupService: PopupService,
    public accountCreatorService: AccountCreatorService,
    public renderer: Renderer2,
    public loadingCtrl: LoadingController,
    public locationService: LocationService,
    private pushNotification: PushNotification,
    private matchesService: MatchesService,
    public crashReportService: CrashReportService,
    public genderPipe: GenderPipe,
    public errorTrackingServer: SentryService,
    private dispatcherService: DispatcherService,
    private audienceService: AudiencesService,
    private dailyTribesService: DailyTribesService,
    private modalService: ModalService,
    private el: ElementRef
  ) {
    super(api, appService, routeTrackerService, errorTrackingServer, utils);
    this.debugId = "profilePopup";

    this.popupNames = keys(this.popupService.getPopups());

    this.route.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation()) {
        let state = this.router.getCurrentNavigation().extras.state;
        if (
          state &&
          state.openProgress &&
          this.progressMenuState === "closed"
        ) {
          setTimeout(() => {
            this.progressMenuState = "opened";
          }, 1000);
        }
        if (state && state.openPopup === "updateInfo") {
          setTimeout(() => this.updatePersonalInfo(), 1000);
        }
      }
    });
  }

  async init() {
    (<any>window).profilePopup = this;
    await this.config.load();

    const failedStatusCallbacks = {
      you_rejected: () =>
        this.popupService.createPopup({ popupName: "resetMatchesPopup" }),
    };

    if (this.failedStatus) {
      this.failedStatusNotification = getProfileBadge({
        notificationName: this.failedStatus,
        handler: () => (failedStatusCallbacks[this.failedStatus] || blankFn)(),
      });
    }

    this.tribesService.onTribeChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((tribe) => {
        this.tribe = tribe;
        this.user = this.tribe.users.find(({ id }) => this.user.id);
      });

    this.routeTrackerService.onRouteChanged
      .pipe(
        takeUntil(this.unsubscribe),
        tap(({ previous, current }) => {
          if (
            current.includes("tabs/tribes") &&
            previous.includes("tabs/profile")
          ) {
            if (!this.aboutMe && this.progressMenuState == "opened") {
              this.showDontSkipHeartFeltMessage();
            }
          }
        })
      )
      .subscribe();

    this.scheduledSearchesNumber = this.tribesService.scheduledSearchNumber();
    this.inScheduledGroup = this.audienceService.inScheduledGroup();

    this.profileStrengthTooltipOptions = {
      initShow: this.config.getFlag("profileProgress") < 100,
      showOnce: true,
      delay: 1500,
      position: TooltipPosition.Center,
      appearance: TooltipAppearance.Blue,
    };
    this.getAge = this.getAge.bind(this);

    this.profile = this.config.getProfile();
    this.isReadyToPool = this.checkPoolReadiness();

    this.config.setFlag("signUpStartPage", "tabs/profile");
    this.unlockPersonality = this.config.finishedLevel(2);

    this.fromHighlights = false;
    this.phoneStatus = this.config.getProfile().phoneNumberStatus;
    this.isPlus = this.config.isPlus();
    this.dispatcherService.onPlusSubscriptionChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.isPlus = this.config.isPlus();
      });
    if (!this.isEditable && !this.fromMatches && !this.fromFailedSearch) {
      this.canDirectMessage = this.tribeService.canDirectMessage(this.user);
    }
    this.isIos = this.platform.is("ios");

    this.hasLocationPermission =
      await this.locationService.hasLocationPermission();

    merge(this.config.onProfileChanged, this.config.onFlagsChanged)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.pictureVerified =
          this.config.getProfile().pictureStatus === "verified";
        this.pictureRejected = this.config.getFlag(
          "scheduled_matches_picture_rejection_reason"
        );
      });

    this.tribesService.onUserRejected
      .pipe(
        takeUntil(this.unsubscribe),
        tap(({ userId }) => {
          if (this.displayProfile.id == userId) {
            this.modalCtrl.dismiss();
          }
        })
      )
      .subscribe();

    this.config.onProfileChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((profile) => {
        this.ngZone.run(() => {
          this.loadData().then((data) => {
            this.onDidLoadData(data);
          });
        });
      });

    this.config.onPicturedCached
      .pipe(
        takeUntil(this.unsubscribe),
        tap(() => {
          if (this.isEditable) {
            this.displayProfile.picture = this.config.get("cachedPicture");
          }
        })
      )
      .subscribe();

    this.userService.personalityTypeChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((personalityType: any) => {
        this.personalityType = personalityType;
      });

    this.onOpenEdit = () => {
      this.updatePersonalInfo();
    };

    this.userExpiresSoon =
      this.user.expires_at &&
      this.user.status != UserTribeStatus.Accepted &&
      this.tribe.status != TribeStatus.Expired;

    setTimeout(() => {
      if (this.instructions)
        this.renderer.removeClass(
          this.instructions.nativeElement,
          "hidden-instructions"
        );
    }, 750);
  }

  expire(hours: number): void {
    this.api
      .post(`tribes/${this.tribe.id}/users/${this.user.id}/set_expiry`, {
        user_id: this.user.id,
        tribe_id: this.tribe.id,
        hours,
      })
      .then(console.log);
  }

  pictureVerified: boolean = false;
  pictureRejected: boolean = false;
  handleAnimation(anim) {
    this.animation = anim;
    this.ngZone.runOutsideAngular(() => this.animation.play());
  }

  showDontSkipHeartFeltMessage(delay = 500) {
    this.config.callOnceLocally("dontSkipHeartfeltMessageShown", () => {
      setTimeout(() => {
        this.heartfeltMessageService.showPopup("dontSkip", {
          userName: this.displayProfile.firstName,
        });
      }, delay);
    });
  }

  async addScheduledUser() {
    if (!this.userService.canJoin()) return;

    try {
      await this.api.post("search/add", { user_id: this.displayProfile.id });
      this.checkMarked = true;
      this.tribesService.userSelectedForScheduledGroupSource.next(
        this.displayProfile
      );
      this.tribesService.finishScheduledSigningUp({
        source: "matches",
        callback: () => this.modalCtrl.dismiss(),
      });
    } catch (e) {
      await this.utils.showGenericError({
        msg: "Something went wrong, try again later",
      });
      await this.modalCtrl.dismiss();
      logc.error("Adding scheduled user error: ", e);
    }
  }

  onDidLoadData(data) {
    if (data) {
      this.personalityType.hasData = true;
      this.personalityType.code = data.code;
      this.personalityType.name = data.name;
      this.personalityType.summary = data.summary;
      this.personalityType.story = data.story;
      this.personalityType.factorAvg = data.neutral_factor_category_count;

      if (data.personality_score)
        this.personalityType.topThreeFactors = this.userService.topThreeFactors;
    }

    let profile = this.config.getProfile();
    this.showCompleteMoreLevels = !this.config.finishedLevel(6);
    const bonusLevelUnlocked = this.config.getFlag("bonusLevelUnlocked");
    this.completeMoreLevels =
      this.config.getCurrentLevel() < (bonusLevelUnlocked ? 6 : 5);
    this.currentUserInstagramToken = profile.instagramToken;

    if (this.isEditable) {
      this.prepareProfileForDisplay(
        this.userService.getProfile(),
        this.userService.details
      );
    } else {
      this.prepareTribemateProfileForDisplay();
    }

    logc.crimson("This user: ", this.user);

    this.editProfilePicture =
      profile.picture === null || profile.pictureStatus === "verified";
    this.pictureVerified = profile.pictureStatus === "verified";
    this.pictureRejected = this.config.getFlag(
      "scheduled_matches_picture_rejection_reason"
    );

    this.calculateProgress();
  }

  getTestBadgeInfo(type) {
    return getProfileBadge({
      notificationName: type,
      firstName: "Donald",
      city: "Kiyv",
    });
  }

  loadData(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.isEditable) {
        try {
          await this.userService.load();
          if (this.unlockPersonality && !this.personalityType.hasData) {
            //avoids useless calls to the server
            const data = await this.userService.fetchPersonalityProfile();
            resolve(data);
          } else {
            resolve(null);
          }
        } catch (err) {
          const tags = { klass: "ProfilePopup", func: "loadData()" };
          const extras = { smState: this.stateMachine.state, err: err };
          this.errorTrackingServer.sendMessage(
            "Profile failed to load",
            tags,
            extras
          );
          this.utils.showGenericMessage(
            "Oops! We failed to load your profile",
            60000,
            "Try again",
            () => this.stateMachine.exec("resume")
          );
          reject(err);
        }
      } else {
        resolve(null);
      }
    });
  }

  showToast() {
    this.utils.showGenericMessage("Test", 300000);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  activateProgressMenu(event: any) {
    if (event.srcElement.classList.contains("tooltip-directive-container"))
      return;
    this.progressMenuState =
      this.progressMenuState === "closed" ? "opened" : "closed";
    if (this.progressMenuState == "closed" && !this.aboutMe) {
      this.showDontSkipHeartFeltMessage();
    }
  }

  focusAboutMe() {
    this.aboutMeForm.el.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      this.aboutMeForm.setFocus();
    }, 500);
  }

  connectInstagram() {
    this.instagramComponent.loginToInsta();
  }

  ionViewWillEnter() {
    if (!this.isEditable) {
      this.tribeMateDataCached = this.cachedPicturesService.getUserTribe(
        this.tribeId,
        this.userId
      );
    }
  }

  ionViewWillLeave() {
    if (!this.isEditable) {
      this.stateMachine = null;
    }
  }

  openRadarPopup() {
    const options = {
      firstName:
        this.displayProfile.id === this.config.get("id")
          ? "YOU"
          : this.displayProfile.firstName,
    };

    return this.openPopup({
      popupName: "radarChartTipPopup",
      options: options,
    });
  }

  async calculateProgress() {
    this.progress.value = 0;

    this.stepsList = [
      {
        done: true,
        text: "Enter your basic info",
        icon: "🙌",
        value: Progress.BasicInfo,
        action: () => {},
      },
      {
        done: !!this.displayProfile.smile,
        text: "Show a big smile",
        icon: "📸",
        value: Progress.Smile,
        action: () => this.changeProfilePic(),
      },
      {
        done:
          !!this.displayProfile.work_organization ||
          !!this.displayProfile.work_title,
        text: "Add your work info",
        icon: "💼",
        value: Progress.Work,
        action: () => this.addWork(),
      },
      {
        done: !!this.displayProfile.school,
        text: "Add your school",
        icon: "📚",
        value: Progress.School,
        action: () => this.addSchool(),
      },
      {
        done: !!this.displayProfile.about,
        text: 'Write an "About me"',
        icon: "✍️",
        value: Progress.AboutMe,
        action: () => this.focusAboutMe(),
      },
      {
        done: this.displayProfile.instagramToken !== "none",
        text: "Connect your Instagram",
        icon: "🖼️",
        value: Progress.Instagram,
        action: () => this.connectInstagram(),
      },
    ];

    this.stepsList.forEach(
      (el) => (this.progress.value += el.done ? el.value : 0)
    );
    await this.config.setFlag("profileProgress", this.progress.value);
    this.profileFilled = this.progress.value === PROFILE_PROGRESS_MAXIMUM;
    console.log("-- Profile progress: ", this.progress.value);

    let p = this.progress.value;
    switch (true) {
      case p < 50:
        this.progress.color = "red-color";
        break;
      case p >= 50 && p <= 89:
        this.progress.color = "yellow-color";
        break;
      case p > 89:
        this.progress.color = "green-color";
        break;
    }

    if (
      this.profileFilled &&
      !this.config.getFlag("profileFilledOnce") &&
      !!this.config.getFlag("tribesStartedToday") &&
      !this.audienceService.inScheduledGroup()
    ) {
      await this.config.setFlag("profileFilledOnce", true);
      this.analyticsService.trackEvent({
        key: "daily_limit_reset",
        value: 1,
      });
      await this.dailyTribesService.refreshDailyTribes();
      await this.popupService.createPopup({
        popupName: "profileStrengthCongratulationsPopup",
      });
    }
  }

  openUpsellingPage(): void {
    this.modalService.openUpselling({
      source: "profile-popup",
      reason: UpsellingPoppingReason.PickGroupMember,
    });
  }

  async showProfileOptions(event) {
    return this.tribeService.showProfileOptions({
      sender: this.fromMatches ? this.displayProfile : this.user,
      event: event,
      source: `profile-popup`,
      tribe: this.tribe,
    });
  }

  async initDirectMessage() {
    await this.tribeService.initDirectMessage(this.tribeId, this.user);
  }

  checkPoolReadiness(): boolean {
    let showProgress = this.config.getFlag("showProfileProgress");
    let fitRequirements =
      !!this.profile.firstName &&
      !!this.profile.birthday &&
      !!this.profile.gender &&
      this.config.get("latitude") &&
      this.config.finishedLevel(3) &&
      this.pushNotification.hasPush() &&
      this.profile.pictureStatus === "verified";

    if (fitRequirements && !showProgress) {
      this.config.setFlag("showProfileProgress", true);
    }

    return fitRequirements || showProgress;
  }

  prepareProfileForDisplay(profile, details) {
    this.displayProfile = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      gender: profile.gender,
      birthday: profile.birthday,
      picture: this.config.getPicture(profile.picture),
      distance: null,
      work_title: details.work_title,
      work_organization: details.work_organization,
      school: details.school,
      about: details.about,
      smile: details.smile,
      id: profile.id,
      instagramToken: profile.instagramToken,
    };

    // console.log("--- DISPLAYPROFILE: ", this.displayProfile);

    this.aboutMe = this.displayProfile.about;

    if (!this.displayProfile.instagramToken) {
      this.displayProfile.instagramToken = "none";
    }
  }

  async addName() {
    await this.navCtrl.navigateForward("sign-up-flow", {
      state: {
        setup: ["name"],
        backPath: "tabs/profile",
      },
    });
  }

  profileIsIncomplete() {
    const profile = this.config.getProfile();
    return !profile.firstName || !profile.hasPassword || !profile.email;
    // return true;
  }

  async completeProfile() {
    try {
      await this.navCtrl.navigateForward("sign-up-flow", {
        state: {
          setup: await this.utils.getMissingProfileValues(),
          backPath: "tabs/profile",
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  prepareTribemateProfileForDisplay() {
    if (!this.isOnline) {
      return;
    }

    let endpoint;
    if (this.fromMatches || this.fromFailedSearch) {
      endpoint = `users/${this.profileId}`;
    } else {
      endpoint = `tribes/${this.tribeId}/users/${this.profileId}`;
    }

    this.api
      .get(endpoint, {})
      .then((data) => {
        if (data) {
          logc.info("data['details']: ", data["details"]);
          let details = data["details"];
          this.displayProfile = {
            id: data["id"],
            firstName: data["first_name"],
            gender: data["gender"],
            lastName: data["last_name"],
            birthday: data["birthday"],
            picture: data["picture"],
            location: details["location"],
            work_title: details["work_title"],
            work_organization: details["work_organization"],
            school: details["school"],
            level: data["level"],
            started_level: data["started_level"],
            about: details["about"],
            instagramToken:
              environment.name === "development"
                ? {
                    access_token: "eee",
                    user_id: "aaa",
                  }
                : details["instagram_token"],
            status: data["status"],
          };
          console.log("INSTAGRAM DATA: ", this.displayProfile.instagramToken);
          this.aboutMe = this.displayProfile.about;
          if (this.config.isDev) {
            console.log(this.displayProfile);
          }

          if (data["personality_type"]) {
            let personality_type: any = data["personality_type"];
            this.personalityType.code = personality_type.code;
            this.personalityType.name = personality_type.name;
            this.personalityType.summary = personality_type.summary;
            this.personalityType.story = personality_type.story;
            this.personalityType.factorAvg =
              personality_type.neutral_factor_category_count;

            if (personality_type.personality_score) {
              this.personalityType.topThreeFactors =
                this.userService.extractTopThreeFactors(
                  personality_type.personality_score
                );
            }
            this.unlockPersonality = true;
            this.personalityType.hasData = true;
          }

          this.compatibility = data["compatibility"];
          // if(this.config.isDev) {
          //   this.compatibility = TEST_HIGHLIGHTS;
          // }
          // logc.crimson("this.compatibility: ", this.compatibility);

          if (this.userService.notReadyForHighlights(this.displayProfile)) {
            this.utils.showUnderLevel3Alert({
              first_name: this.displayProfile.firstName,
            });
          }
          this.profileNotification = getProfileBadge({
            notificationName: this.notificationName,
            firstName: this.displayProfile.firstName,
            city: this.remoteCityName,
            cssClass: this.utils.getExpirationColors(
              this.tribe?.users?.find((u) => u.id == this.displayProfile.id)
            ),
          });
        }
      })
      .catch((err) => {
        console.log("something is wrong: ", err);
        this.modalCtrl.dismiss();
      });
  }

  getAge(birthday) {
    return this.utils.getUserAge(birthday);
  }

  async openPopup(data) {
    this.dispatcherService.newPopupSource.next(data);
  }

  addToMatchesSelected() {
    this.checkMarked = true;
    setTimeout(() => {
      this.matchesService.addedMatch.next({
        id: this.userId,
        section: this.section,
        action: "add",
      });
      this.close();
    }, 1000);
  }

  openMenu(event) {
    this.popoverCtrl
      .create({
        component: DeclineMenuComponent,
        event: event,
        backdropDismiss: true,
        cssClass: "decline-menu-popover",
      })
      .then((popover) => {
        popover.present();
        popover.onDidDismiss().then((dismissResponse) => {
          if (dismissResponse.data && dismissResponse.data.action) {
            this.matchesService.cardProfileAction.next({
              id: this.userId,
              section: this.section,
              action: dismissResponse.data.action,
            });
            this.close();
          }
        });
      });
  }

  changeProfilePic() {
    if (!this.isOnline) {
      this.utils.showGenericError({
        msg: "Please connect to the Internet first, so we can save your profile.",
        key: "profile_pic_change",
      });
      this.modalCtrl.dismiss();
      return;
    }

    this.profilePictureService.showUploadMediaPicker().then(
      (data: any) => {
        const url = data.url;
        if (url) {
          this.userService
            .updateProfile({
              picture: url,
              pictureStatus: data.hasSmile
                ? PictureStatus.Verified
                : PictureStatus.OnHold,
              pictureGender: data.gender,
              smile: data.hasSmile,
            })
            .then(
              (resp) => {
                if (data.hasSmile) {
                  this.analyticsService.trackEvent({
                    key: "big_smile_set",
                    value: 1,
                  });
                }
              },
              (err) => {
                console.log(err);
                this.utils.errorContext =
                  "profile-popup, error: " + JSON.stringify(err);
                this.utils.showGenericError({
                  key: "save_profile_pic",
                });
                this.modalCtrl.dismiss();
                this.crashReportService.logException(err);
              }
            );
        }
      },
      async (err) => {
        if (err.data) {
          this.profilePictureService.currentData = err.data;
          this.navCtrl.navigateForward("/picture/cannot_detect_face");
        } else {
          this.modalCtrl.dismiss();
        }
      }
    );
  }

  //PRE POPUP HANDLERS
  async updatePersonalInfo() {
    this.popoverCtrl.dismiss();
    const popover = await this.popoverCtrl.create({
      component: ProfileInfoPopupComponent,
      translucent: true,
      backdropDismiss: true,
      cssClass: "popup top edit-popup",
    });
    await popover.present();
  }

  //POPUP HANDLERS
  async addWork() {
    if (!this.isEditable) return;
    if (document.getElementsByClassName("work-form")[0]) return;
    if (await this.popoverCtrl.getTop()) {
      this.popoverCtrl.dismiss();
    }
    const popover = await this.popoverCtrl.create({
      component: ProfileWorkPopupComponent,
      componentProps: {
        work: {
          title: this.displayProfile.work_title,
          organization: this.displayProfile.work_organization,
        },
      },
      backdropDismiss: true,
      cssClass: ["popup", "edit-popup"],
    });
    await popover.present();
  }

  async addSchool() {
    if (!this.isEditable) return;
    if (document.getElementsByClassName("school-form")[0]) return;
    if (await this.popoverCtrl.getTop()) {
      this.popoverCtrl.dismiss();
    }

    const popover = await this.popoverCtrl.create({
      component: ProfileSchoolPopupComponent,
      componentProps: {
        school: this.displayProfile.school,
      },
      backdropDismiss: true,
      cssClass: ["popup", "edit-popup"],
    });
    await popover.present();
  }

  previousAboutMe: string = "";
  setPreviousAboutMe() {
    this.previousAboutMe = this.aboutMe;
  }

  async addAboutMe() {
    if (!this.isEditable) return;
    if (this.aboutMe == this.previousAboutMe) return;
    this.userService.updateProfile({ about: this.aboutMe }).then(() => {
      this.analyticsService.trackEvent({ key: "about_me_set", value: 1 });
    });
  }

  showPictureIssueAlert(reason = "") {
    if (this.profile.picture) {
      this.utils.showAlert(
        "Profile Issue Detected",
        "There seems to be a small issue with your profile photo. Please fix it.",
        "Reminder: Profiles are never public.",
        [
          {
            text: "Fix Issue",
            handler: () =>
              this.navCtrl.navigateForward(
                "/picture/cannot_detect_face/" + this.getCannotDetectFacePath()
              ),
          },
        ]
      );
    } else {
      this.changeProfilePic();
    }
  }

  getCannotDetectFacePath(): string {
    if (this.config.getFlag("scheduled_matches_picture_rejection_reason")) {
      return this.config.getFlag("scheduled_matches_picture_rejection_reason");
    }
    if (this.config.getProfile().pictureStatus == PictureStatus.OnHold) {
      return "serious";
    }
    return "";
  }

  //TESTS START
  joinTribeTest() {
    this.api
      .post("tribes/" + this.tribeId + "/users/" + this.userId + "/join", {})
      .then(
        (_) => {
          this.utils.showGenericMessage("user joined the tribe");
        },
        (err) => {
          this.utils.showGenericError({
            msg: "FAILED to join tribe",
          });
        }
      );
  }

  confirmTribeTest() {
    this.api
      .post("tribes/" + this.tribeId + "/users/" + this.userId + "/approve", {})
      .then(
        (_) => {
          this.utils.showGenericMessage("user confirmed the tribe");
        },
        (err) => {
          this.utils.showGenericError({
            msg: "FAILED to confirm tribe",
          });
        }
      );
  }

  rejectTest() {
    this.api
      .post("tribes/" + this.tribeId + "/users/" + this.userId + "/reject", {
        reason: "passed_not_feeling_it",
      })
      .then(
        (_) => {
          this.utils.showGenericMessage("user rejected user in standby");
        },
        (err) => {
          this.utils.showGenericError({
            msg: "FAILED to reject user",
          });
        }
      );
  }

  declineTribeTest() {
    this.api
      .post("tribes/" + this.tribeId + "/users/" + this.userId + "/decline", {
        reason: "passed_not_feeling_it",
      })
      .then(
        (_) => {
          this.utils.showGenericMessage("user declined the tribe");
        },
        (err) => {
          this.utils.showGenericError({
            msg: "FAILED to decline tribe",
          });
        }
      );
  }

  expiredTribeTest() {
    this.api
      .post("tribes/" + this.tribeId + "/users/" + this.userId + "/expire", {})
      .then(
        (_) => {
          this.utils.showGenericMessage("user expired the tribe");
        },
        (err) => {
          this.utils.showGenericError({
            msg: "FAILED to expire tribe",
          });
        }
      );
  }

  async openPersonalityTypeModal() {
    if (this.unlockPersonality && this.personalityType.hasData) {
      const modal = await this.modalCtrl.create({
        component: PersonalityType,
        componentProps: {
          personalityType: this.personalityType,
          currentUserPersonality:
            this.displayProfile.id == this.config.get("id"),
          usersName: this.displayProfile.firstName,
          tribe: this.tribe,
          user: this.displayProfile,
          source: "profile",
          fromMatches: this.fromMatches,
        },
        backdropDismiss: true,
        swipeToClose: true,
        cssClass: "modal-card z-index-30000",
      });
      return modal.present();
    }
  }

  hideRadarHover() {
    this.onTouchend.next(true);
  }

  async showInfoPopup(type, popupPosition) {
    if (this.isEditable) {
      let popupData = { ...this.getInfoPopupData(type), type };
      this.openInfoPopup(popupData, popupPosition);
    } else {
      if (type !== "gender") return;
      if ((!this.isPlus && this.fromMatches) || this.fromFailedSearch) return;
      this.showGenderTooltip();
    }
  }

  showGenderTooltip() {
    this.showGenderTip = !this.showGenderTip;
    setTimeout(() => {
      this.showGenderTip = !this.showGenderTip;
    }, 3000);
  }

  getInfoPopupData(type: string) {
    let types = {
      name: {
        title: `Howdy, ${this.displayProfile.firstName}! 👋`,
        text: `Want to change your first name?`,
      },
      age: {
        title: `You're ${this.getAge(
          this.displayProfile.birthday
        )} years old 🎂`,
        text: `Want to change your age?`,
      },
      gender: {
        title: `You're ${this.displayProfile.gender != "non-binary" ? "a" : ""} 
          ${this.getGenderInfo().gender} 
          ${this.getGenderInfo().emoji}
        `,
        text: `Want to change your gender identity?`,
      },
    };

    return types[type] || {};
  }

  getGenderInfo() {
    let defaultEmoji = "🙋";
    let emojis = { male: "🙋‍♂️", female: "🙋‍♀️" };
    return {
      gender: this.genderPipe.transform(this.displayProfile.gender),
      emoji: emojis[this.displayProfile.gender] || defaultEmoji,
    };
  }

  async openInfoPopup(popupData: any, popupPosition: any) {
    try {
      const popover = await this.popoverCtrl.create({
        component: SettingsPopoverComponent,
        event: popupPosition,
        mode: "ios",
        cssClass: "popover-info",
        componentProps: {
          data: popupData,
        },
      });
      await popover.present();
    } catch (err) {
      console.log(err);
    }
  }

  async showModalImage() {
    if (
      (this.fromMatches && !this.isPlus) ||
      (!this.config.get("picture") && this.isEditable) ||
      this.fromFailedSearch
    )
      return;

    const modal = await this.modalCtrl.create({
      component: ModalImageComponent,
      componentProps: {
        image: this.displayProfile.picture,
        imageFullsize: this.isEditable
          ? this.config.toFullsizePicture(this.config.get("picture"))
          : this.config.toFullsizePicture(this.displayProfile.picture),
      },
      cssClass: "z-index-30000",
    });
    return modal.present();
  }

  onImageError(event) {
    if (this.isEditable) {
      this.selectPicture(
        "bg-img-editable",
        this.config.get("cachedPicture"),
        event
      );
    } else if (this.tribeMateDataCached) {
      this.selectPicture(
        "bg-img",
        this.tribeMateDataCached.cachedPicture,
        event
      );
    }
    event.onerror = null;
  }

  selectPicture(elementId, picture, event) {
    setTimeout((_) => {
      this.displayProfile.picture = picture || this.config.getDefaultAvatar();
    }, 1000);
    //timeout because the error often happens at load time when the elements aren't all rendered yet.
  }

  async close() {
    if (await this.modalCtrl.getTop()) {
      this.modalCtrl.dismiss({
        tribe: this.tribe,
        user: this.displayProfile,
      });
    }
  }

  camelToNormal(name) {
    return name.replace(/([A-Z])/g, " $1");
  }
}
