import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-popover',
  template: `
  
    <div class = "info-popover">
      <div class = "icon-section">
        <ion-icon [name] = "info?.icon?.name" class = "{{ info?.icon?.cssClass }}"></ion-icon>
      </div>
      <div class = "content-section">
        <div class = "info-popover-title bold" [innerHTML] = "info?.title"></div>
        <div class = "info-popover-description body-color" [innerHTML] = "info?.description"></div>
      </div>
    </div>
  
  `,
  styleUrls: ['./info-popover.component.scss'],
})
export class InfoPopoverComponent implements OnInit {

  public info = {
    icon: {
      name: '',
      cssClass: ""
    },
    title: '',
    description: ''
  };

  constructor() { }

  ngOnInit() {}

}
