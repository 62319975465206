import {Component, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { Config } from '../../services/config/config';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import {fromEvent} from 'rxjs';
import {SentryService} from "../../services/performance/sentry.service";
import {ConsoleService} from "../../services/performance/console.service";
import { logc } from 'src/app/shared/helpers/log';

export interface FeedbackQuestion {
  title: string;
  body?: string;
  topic?: string;
}

@Component({
  selector: 'stars-feedback',
  template: `
      <div *ngIf = "options?.showScrim" 
           class = "stars-scrim" [class.shown] = "showQuestion" (click) = "close()"></div>
      <div keyboard-attach = "" 
           class = "feedback-main-container {{ cssClass }} rounded-[5px] p-[15px]" 
           [class.shown] = "showQuestion" 
           [class.opened] = "reactionSelected">
          <ion-icon *ngIf = "!ratingHidden && options?.showCloseButton" class = "close-button" name = "close" (click) = "close()"></ion-icon>
          <div class="stars-layer">
              <div class="title-section" [class.opacity-0] = "titleHidden">
                <div class = "question-title">{{question.title}}</div>
                <div class = "question-body">{{question.body}}</div>
              </div>
              <div class="rating-section">
                  <div #ratingSection class = "rating-stars">
                    <ng-container *ngFor = "let reaction of rating; index as index">
                        <div class="icon-container" (click) = "selectReaction(index)">
                            <ion-icon class = "reaction-icon"
                                      [class.selected] = "selectedReaction.id === index"
                                      name = "{{reaction.icon}}-{{selectedReaction.id === index ? 'on' : 'off'}}"></ion-icon>
                        </div>
                    </ng-container>
                  </div>
                  <div #sendAnimation *ngIf = "ratingHidden" class="send-animation">
                    <ng-lottie [options]="sendAnimationConfig"
                                           width = "100px"
                                           height = "100px"
                                           (animationCreated)="handleAnimation($event)">
                    </ng-lottie >
                  </div>
                  <div class="reaction-text" [class.fly-in] = "selectedReaction.text">{{selectedReaction.text}}</div>
              </div>
          </div>
          <div class="text-layer">
              <input [(ngModel)] = "response.text" 
                     #sendInput 
                     type="text" 
                     class = "stars-feedback-input" 
                     placeholder = "Anything you'd like to add...">

              <ion-button class = "btn-medium-action primary-popup-button tight ionic-button turquoise-gradient-btn" 
                          shape = "round" 
                          (click) = "sendFeedback()">
                  Submit
              </ion-button>
          </div>
      </div>
  `,
  styleUrls: ['./stars-feedback.component.scss'],
})
export class StarsFeedbackComponent implements OnInit {
  @Input('question') question: FeedbackQuestion;
  @Input() cssClass: string = "";
  @Input('options') options = { 
    speed: 1,
    showScrim: true,
    showCloseButton: true
  };
  @Input() tribeId: number = null;
  @Output('onClose') onClose = new EventEmitter();
  @ViewChild('ratingSection') ratingSection;
  @ViewChild('sendAnimation') sendAnimation;
  @ViewChild('sendInput') sendInput;

  public selectedReaction = { id: null, text: '' };
  public reactionSelected: boolean = false;
  public ratingHidden: boolean = false;
  public showQuestion: boolean = false;
  public titleHidden: boolean = false;
  public response = {
    text: ''
  };

  public rating = [
    { icon: 'terrible',  text: 'Terrible' },
    { icon: 'poor',      text: 'Poor'},
    { icon: 'fair',      text: 'Fair'},
    { icon: 'good',      text: 'Good'},
    { icon: 'excellent', text: 'Excellent'}
  ];

  public sendAnimationConfig: any = {
    path: 'assets/gif/feedback-sent.json',
    renderer: 'canvas',
    autoplay: false,
    loop: false
  };
  public animation;

  constructor(private renderer: Renderer2,
              private analyticsService: AnalyticsService,
              private config: Config,
              private ngZone: NgZone,
              private errorTrackingService: SentryService,
              private consoleService: ConsoleService) {
    setTimeout(() => {
      this.showQuestion = true;
    }, 200);

  }

  ngOnInit() {
    this.analyticsService.trackEvent({
      key: 'requested_feedback',
      value: 1
    });
  }

  selectReaction(index) {
    this.reactionSelected = true;
    this.selectedReaction.id = index;
    this.selectedReaction.text = this.rating[index].text;
    this.sendInput.nativeElement.focus();
  }

  sendFeedback() {
    this.sendInput.nativeElement.style.opacity = 0;
    const quality = this.selectedReaction.id + 1;
    const response = this.response.text;
    this.analyticsService.trackEvent({
      key: 'submitted_feedback',
      value: 1,
      user_id: this.config.get('id'),
      email: this.config.get('email'),
      topic: this.question.topic,
      tribe_id: this.tribeId,
      quality,
      response
    });

    if(quality <= 2) {
      const tags = { klass: "StarsFeedbackComponent", func: "sendFeedback()" };
      const extras = {
        user_id: this.config.get('id'),
        email: this.config.get('email'),
        response: response,
        quality: quality,
        topic: this.question.topic,
        logs: this.consoleService.getLogs()
      }
      const message = "Stars feedback is 3 or lower";
      this.errorTrackingService.sendMessage(message, tags, extras);
    }

    this.reactionSelected = false;
    this.selectedReaction.id = null;
    this.selectedReaction.text = '';
    this.ratingHidden = true;
    this.runClosingAnimation();

    setTimeout(() => {
      this.close();
    }, 2200 / this.options.speed);
  }

  handleAnimation(animation: any) {
    this.ngZone.runOutsideAngular(() => this.animation = animation);
    this.animation.setSpeed(this.options.speed);
  }

  runClosingAnimation() {
    setTimeout(() => {
      this.renderer.addClass(this.ratingSection.nativeElement, 'hidden');
    }, 200 / this.options.speed);
    setTimeout(() => {
      this.titleHidden = true;
    }, 300 / this.options.speed);
    setTimeout(() => {
      this.renderer.addClass(this.sendAnimation.nativeElement, 'shown');
      this.animation.play();
    }, 500 / this.options.speed);
  }

  close() {
    this.config.setFlag(`${this.question.topic}_asked`, true);
    this.showQuestion = false;
    setTimeout(() => {
      // this.ngZone.runOutsideAngular(() => this.animation.stop());
      this.onClose.emit(null);
    }, 500);
  }

}
