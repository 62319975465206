import {Component, Input, OnInit} from '@angular/core';
import {getHoursMeridiem, getScheduledDate} from "../../shared/helpers/time-helpers";

@Component({
  selector: 'date-label',
  template: `
    <div class = "date-container">
      <div class = "element date">
        <ion-icon name = "calendar"></ion-icon>
        {{ date }}
      </div>
      <div class = "element time">
        <ion-icon name = "time-outline"></ion-icon>
        {{ meetupHours }}
      </div>
    </div>
  `,
  styleUrls: ["./date-label.component.scss"],
})
export class DateLabelComponent implements OnInit {
  public date;
  public meetupHours: string = "";
  @Input("date") set setDate(date: any) {
    this.date = getScheduledDate(date);
    this.meetupHours = getHoursMeridiem(date);
  }
  constructor() { }

  ngOnInit() {}

}
