import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Platform, NavController } from '@ionic/angular';
import {BACKGROUND_TIMEOUT} from "../shared/constants/constants";
import * as faker from "faker";
import {log} from "../shared/helpers/log";
import {Config} from "./config/config";
import {SMState} from "../shared/interfaces";
import {SentryService} from "./performance/sentry.service";
import {ConsoleService} from "./performance/console.service";
import {logc} from "../shared/helpers/log";
import {RemoteConfigService} from './remote-config.service';
import {ExperimentsService} from "./experiments.service";

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private appReadySource = new Subject();
  private pauseSource = new Subject();
  private resumeSource = new Subject();
  public onAppReady: any;
  public onPause: any;
  public onResume: any;
  public skippingPauses = false;
  public isInBackground = false;

  constructor(public _platform: Platform,
              private errorTrackingService: SentryService,
              private consoleService: ConsoleService,
              public navCtrl: NavController,
              public remoteConfig: RemoteConfigService,
              public config: Config) {
    this.onAppReady = this.appReadySource.asObservable();
    this.onPause = this.pauseSource.asObservable();
    this.onResume = this.resumeSource.asObservable();
    this.init();
    if(this.config.isDev || this.config.isStaging) {
      (<any>window).appService = this;
    }
  }

  skipNextPause(t = 5000) {
    this.skippingPauses = true;
    setTimeout( _ => {
      this.resetPauseSkipping();
      if(this.isInBackground) {
        this.onDidPause();
      }
    }, t);
  }

  resetPauseSkipping() {
    this.skippingPauses = false;
  }

  timeout;
  init() {
    if(this.config.isPWA) {
      const onPWAVisibilityChanged =
        (visibilityState) => ({
          visible: () => this.onDidResume(),
          hidden: () => this.onDidPause()
        })[ visibilityState ]();
      document.addEventListener("visibilitychange", () => onPWAVisibilityChanged(document.visibilityState))
    }

    this._platform.pause.subscribe( _ => {
      this.timeout = setTimeout(() => {
        this.onDidPause()
        this.timeout = null;
      }, BACKGROUND_TIMEOUT);
    });

    this._platform.resume.subscribe( _ => {
      if(this.timeout) {
        clearTimeout(this.timeout);
      } else {
        this.onDidResume()
      }
    });
  }

  pause() { this.pauseSource.next(null) }
  resume() { this.resumeSource.next(null) }

  onDidPause() {
    logc.black("--- putting the app in the background... ---");
    this.isInBackground = true;
    if(!this.skippingPauses) {
      this.pauseSource.next(null);
    }
  }

  onDidResume() {
    logc.black("--- putting the app in the foreground... ---");

    const smInfos: SMState[] = Object.values(this.config.SMAggregator)
    if(smInfos.some(stateInfo => stateInfo.queue.length)) {
      const message = `*** Some SM has queue on the app resume **`;
      logc.orange(message, this.config.SMAggregator);
      const tags = { klass: "appService", func: "onDidResume" };
      const extras = { stateMachines: this.config.SMAggregator, logs: this.consoleService.getLogs() };
      this.errorTrackingService.sendMessage(message, tags, extras);
    }

    this.isInBackground = false;
    if(!this.skippingPauses) {
      logc.pink("--- app.ts resumeSource.next ---");
      this.resumeSource.next(null);
    }
  }

  appReady() {
    this.appReadySource.next(null);
    this.nativeInit();
  }

  async nativeInit() {
    /*
    if(!this._platform.is('android')) {
      return;
    }

    await this.remoteConfig.load();
    let shortcuts = (<any>window).plugins.Shortcuts;
    const uninstallShortCutVariant = await this.remoteConfig.getString('uninstall_shortcut_text') || 'Uninstall, stay available';

    const shortcut = {
      id: 'sms_opt_in',
      shortLabel: uninstallShortCutVariant,
      longLabel: uninstallShortCutVariant,
      //iconBitmap: '<Bitmap for the shortcut icon, base64 encoded>',
      //iconFromResource: "ic_action_next_item", //filename w/o extension of an icon that resides on res/drawable-* (hdpi,mdpi..)
      intent: {
        action: 'android.intent.action.RUN',
        data: 'myapp://sms-opt-in/uninstall',
        extras: {
          'android.intent.extra.SUBJECT': 'sms-opt-in', // Built-in Android extra (string)
        }
      }
    }
    shortcuts.setDynamic([shortcut], function() {
      logc.info('shortcuts initialized');
    }, function(error) {
      logc.error("shortcuts couldn't be initialized");
    });

    shortcuts.getIntent(function(intent) {
      logc.info(`app start with intent ${JSON.stringify(intent)}`);
      if(intent.SUBJECT == 'sms-opt-in') {
        this.navCtrl.navigateForward('sms-opt-in/uninstall')
      }
    });
    */
  }
}
