import { Injectable } from '@angular/core';
import {AppService} from "./app";
import {logc} from "../shared/helpers/log";
import {fromNullable} from "../shared/helpers/either-monad";
import {TribesService} from "./data/tribes.service";
import {environment} from "../../environments/environment";
import {Config} from "./config/config";
import {Subject} from "rxjs/Subject";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

enum Sounds {
  Message = "message.m4a"
}

interface AudioSettings {
  on: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private audioSettingsChangedSource: Subject<AudioSettings> = new Subject<AudioSettings>();
  private onAudioSettingsChanged: Observable<AudioSettings>;

  private assetsPath = "./assets/audio/";
  private settings: AudioSettings;
  private defaultSettings: AudioSettings = {
    on: true
  }
  private playlist = {
    message: new Audio(this.getFilePath(Sounds.Message))
  }

  constructor(private appService: AppService,
              private tribesService: TribesService,
              private config: Config) {
    this.appService.onAppReady.subscribe(() => this.init());
    this.tribesService.onUserNewMessage.subscribe(() => this.play("message"));

    this.onAudioSettingsChanged = this.audioSettingsChangedSource.asObservable();

    if(environment.name == 'development') {
      (window as any).audioService = this;
    }
  }

  private async init() {
    this.settings = await this.config.get("audioSettings") || this.defaultSettings;

    this.onAudioSettingsChanged
      .pipe(
        tap((settings) => {
          this.settings = settings;
          this.config.set("audioSettings", this.settings);
        })
      ).subscribe();

    logc.info("-- ♩♫♪ Audio service init ♩♫♪ --");
  }

  private play(soundName: string): any {
    // if(!this.settings.on) return;
    fromNullable(this.playlist[soundName])
      .map(this.playSound)
      .fold(() => logc.error(`${ soundName } sound is not found in the playlist`), () => {});
  }

  private playSound(sound: HTMLAudioElement) {
    logc.pink("PARTY HARD");
    return sound.play();
  }

  turnOn() {
    this.settings.on = true;
    this.changeSettings();
  }

  turnOff() {
    this.settings.on = false;
    this.changeSettings();
  }

  private changeSettings() {
    this.audioSettingsChangedSource.next(this.settings);
  }

  private getFilePath(fileName: string): string {
    return this.assetsPath + fileName;
  }

}
