import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TitledCardComponent } from "./titled-card.component";

@NgModule({
  declarations: [TitledCardComponent],
  imports: [CommonModule],
  exports: [TitledCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TitledCardModule {}