import { Injectable } from '@angular/core';
import {Config} from "./config/config";
import { getDailyTribeRefreshTime, getDailyTribeRefreshTimeFallback } from "../shared/helpers/time-helpers";
import {log} from "../shared/helpers/log";
import * as moment from "moment";
import {AppService} from "./app";
import {DailyTribesLimit} from "../shared/enums/daily-tribes-limit.enum";
import {SentryService} from "./performance/sentry.service";
import {ConsoleService} from "./performance/console.service";
import {DAILY_TRIBES_REFRESH_HOUR} from "../shared/constants/constants";
import { ApiService } from './data/api.service';

const logStyles = { background: "#E26A2C", color: "white" };

@Injectable({
  providedIn: 'root'
})
export class DailyTribesService {

  public refreshTimeout = null;

  constructor(private config: Config,
              private api: ApiService,
              private errorTrackingService: SentryService,
              private consoleService: ConsoleService) {
    log("** Daily tribes service init **", logStyles);
    if(this.config.isDev) {
      (window as any).dailyTribesService = this;
    }
  }

  canStartGroup(): boolean {
    return !this.reachedDailyTribesLimit();
  }

  reachedDailyTribesLimit(): boolean {
    return this.config.getFlag("tribesStartedToday") >=
      (this.config.isPlus()
          ? DailyTribesLimit.Premium
          : DailyTribesLimit.Basic
      )
  }

  async setDailyTribesRefreshTime(ms: number) {
    await this.config.setFlag("dailyTribesRefreshAt", ms);
  }

  checkDailyTribesStatus() {
    let refreshAt = this.config.getFlag("dailyTribesRefreshAt", 0);

    refreshAt = parseInt(refreshAt);

    const tribesStartedToday = this.config.getFlag("tribesStartedToday", 0);
    if(!refreshAt && tribesStartedToday > 0) {
      const message = "** No refreshAt and started 1+ tribe today";
      console.log(`** ${ message } **`);
      console.log("refreshAt type is: ", typeof refreshAt);
      console.log("tribesStartedToday: ", tribesStartedToday);
      const tags = { klass: "DailyTribesService", func: "checkDailyTribesStatus()" };
      const extras = {
        logs: this.consoleService.getLogs(),
        dailyTribesRefreshAt: refreshAt,
        tribesStartedToday: tribesStartedToday
      };
      this.errorTrackingService.sendMessage(message, tags, extras);
    }

    if(!refreshAt) return false;

    if(Date.now() > refreshAt) {
      log("-- Refreshing daily tribes... --", logStyles);
      return this.refreshDailyTribes();
    }
    const duration = moment.duration(this.getTimeLeft());
    log(
      `Will refresh daily tribes in: ${ duration.hours() }h ${ duration.minutes() }m ${ duration.seconds() }s`,
      logStyles
    );
    this.setRefreshTimeout();
  }

  private handleBadRefreshTime(dailyTribesRefreshAt) {
    try {
      const message = "** dailyTribeRefreshTime is falsy ** ";
      const tags = { klass: "DailyTribesService", func: "addDailyTribe()" };
      const extras = {
        logs: this.consoleService.getLogs(),
        dailyTribesRefreshAt: dailyTribesRefreshAt,
        tribesStartedToday: this.config.getFlag("tribesStartedToday"),
        moment: moment() || {}
      };

      console.log(message, dailyTribesRefreshAt);

      this.errorTrackingService.sendMessage(message, tags, extras);
    } catch (e) {}
  }

  addDailyTribe(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      let tribesStartedToday: number = this.config.getFlag("tribesStartedToday", 0);
      tribesStartedToday += 1;

      let dailyTribesRefreshAt = getDailyTribeRefreshTime();
      if(dailyTribesRefreshAt == undefined || !dailyTribesRefreshAt || isNaN(dailyTribesRefreshAt)) {
        this.handleBadRefreshTime(dailyTribesRefreshAt);
        dailyTribesRefreshAt = getDailyTribeRefreshTimeFallback();
        console.log("** refresh time fallback fired **");
        console.log("new time is : ", new Date(dailyTribesRefreshAt));
      }

      await this.config.setFlags({
        tribesStartedToday,
        dailyTribesRefreshAt
      });
      if(this.reachedDailyTribesLimit()) {
        this.api.post("daily_limit_reached", {});
      }
      log(`Tribes started today number: ${ tribesStartedToday }`, logStyles);
      log(`Will refresh at: ${ new Date(dailyTribesRefreshAt) }`, logStyles);
      resolve();
    })
  }

  async refreshDailyTribes() {
    if(this.refreshTimeout) this.clearRefreshTimeout();

    await this.config.setFlags({
      tribesStartedToday: null,
      dailyTribesRefreshAt: null
    });

    console.log("-- refreshDailyTribes() --");
    console.log("tribesStartedToday: ", this.config.getFlag('tribesStartedToday'));
    console.log("dailyTribesRefreshAt: ", this.config.getFlag('dailyTribesRefreshAt'));
    console.log("--------------------------");
  }

  async setRefreshTimeout() {
    if(this.refreshTimeout) this.clearRefreshTimeout();

    this.refreshTimeout = setTimeout(async () => {
      await this.refreshDailyTribes()
    }, this.getTimeLeft());
  }

  clearRefreshTimeout() {
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = null;
  }

  getTimeLeft() {
    return this.config.getFlag("dailyTribesRefreshAt") - Date.now();
  }

}
