import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {TribeComponent} from "./tribe.component";
import {CommonModule} from "@angular/common";
import {TribeAvatarModule} from "../tribe-avatar/tribe-avatar.module";
import {PipesModule} from "../../pipes/pipes.module";
import {IonicModule} from "@ionic/angular";

@NgModule({
  declarations: [ TribeComponent ],
  imports: [
    CommonModule,
    TribeAvatarModule,
    IonicModule,
    PipesModule
  ],
  exports: [ TribeComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class TribeModule {}
