export function Executed() {
  return function(
    target,
    propertyKey,
    descriptor
  ) {
    console.log(`%c --- class: ${ target.constructor.name }, method: ${ propertyKey } was called ---`,
      "background: purple; color: white;");
  }
}
