import {MatchingStrategy} from "./matching-strategy";
import {Injectable} from "@angular/core";
import {ModalController} from "@ionic/angular";
import { MatchingTypePickerComponent } from "src/app/components/matching-type-picker/matching-type-picker.component";
import { Subject } from "rxjs";
import { Config } from "../../config/config";
import { SearchService } from "../../data/search.service";

@Injectable({
  providedIn: "any"
})
export class FansMatchingStrategy extends MatchingStrategy {
  constructor(private modalCtrl: ModalController,
              private config: Config,
              private searchService: SearchService) {
    super();
  }

  async execute(): Promise<any> {
    this.searchService.openFansSearchPicker();
  }
}
