import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import { ImageRowComponent } from "./image-row.component";

@NgModule({
  declarations: [ImageRowComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [ImageRowComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImageRowModule {}
