<!--<div *ngIf = "(isCurrentUserProfile || displayProfile.instagramToken != 'none') && verySpecialCondition()"-->
<div *ngIf = "(isCurrentUserProfile || displayProfile.instagramToken != 'none')"
     class = "instagram-container"
     [class.dark-bg] = "isDarkMode"
     [class.placeholder] = "!displayedMedia">

  <ion-button *ngIf="isCurrentUserProfile && 
                     ((!displayedMedia && displayProfile.instagramToken == 'none') ||
                       needsReconnection)"
              expand = "block"
              shape = "round"
              (click)="loginToInsta()">
    <ion-icon name = "instagram-colored"></ion-icon>
    Connect My Instagram
  </ion-button>

  <div *ngIf = "onMediaRefLoadedSubject | async" class = "instagram-medias">
    <div class = "insta-annotation top-left">
      <ion-icon name = "instagram-plain"></ion-icon>
      <div *ngIf = "fromMatches && !isPlus; else regularInstaInfo">
        INSTAGRAM
      </div>
      <ng-template #regularInstaInfo>
        {{isCurrentUserProfile ? "YOUR" : displayProfile.firstName + "'s"}} INSTAGRAM
      </ng-template>
    </div>
    <div *ngFor = "let media of displayedMedia; let i = index"
         [class.blur] = "fromMatches && !isPlus"
         class = "media-container" >
      <div class="loading-message-container" *ngIf = "!loadedImgs[i] && i < 6">
        <loading></loading>
      </div>
      <img *ngIf = "i < 6"
           [class.loaded] = "loadedImgs[i]"
           src = "{{ media.media_url }}"
           (click) = "zoomPic(i)"
           alt = "Media {{i}} of {{media.username}}'s' feed"
           (load) = "onImgLoaded(i)">
    </div>

    <div *ngIf = "isCurrentUserProfile"
         class = "insta-annotation top-right"
         (click)="showDisconnectAlert()">
        <ion-icon name="remove-circle"></ion-icon>
    </div>

  </div>
</div>
<div class = "description"
     *ngIf = "verySpecialCondition() && !displayedMedia && isCurrentUserProfile && displayProfile.instagramToken == 'none'">
  Show your latest Instagram posts on your profile. Your username won’t be shown.
</div>
