import { Component } from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';

@Component({
  selector: 'generic-popup',
  template: `
    <div class = "container">
      <ion-icon (click) = "close()" class="close-icon back-arrow-position right" name="close"></ion-icon>
      <div *ngIf = "popup?.image; else icon" class="image-container">
        <img class = "main-icon img" [src] = "popup?.image">
      </div>
      <ng-template #icon>
        <ion-icon class = "header-icon {{ popup?.iconStyles}}" [name] = "popup?.icon"></ion-icon>
      </ng-template>
      <div class = "header">
        <div class = "sub-header {{ popup?.gradient }}" 
             [class.disabled] = "">
          {{ popup?.header }}
        </div>
      </div>
      <div class = "content">
        <div *ngIf = "popup?.bodyImage" class = "body-image" >
          <img [src] = "popup?.bodyImage" alt = "Body">
        </div>
        <div class = "title" [innerHTML] = "popup?.title"></div>
        <div class = "description" [innerHTML] = "popup?.description"></div>
        <div *ngIf = "popup?.notice" class = "notice" [innerHTML] = "popup?.notice"></div>
        <ion-button *ngFor = "let button of popup?.buttons" 
                    class = '{{ button?.cssClass }} ionic-button btn-large-action text-title'
                    [class.primary-button] = "button?.primary"
                    [class.primary-popup-button] = "button?.primary"
                    [class.secondary-popup-button] = "button?.secondary"
                    [class.text-white] = "button?.primary"
                    [class.turquoise-btn-shadow] = "button?.primary"
                    [attr.fill] = "button?.secondary ? 'clear' : null"
                    expand = "block"
                    (click) = "button?.action()"
                    shape = "round">
          {{ button?.text }}
        </ion-button>

      </div>
    </div>

  `,
  styleUrls: ['generic-popup.scss'],
})
export class GenericPopup {
  public popup: any = {};

  constructor(private navParams: NavParams,
              private popoverCtrl: PopoverController) {}

  ngOnInit() {
    this.popup = this.navParams.get('popup');
    (<any>window).test_el = this;
    (<any>window).test_params = this.popup;
  }

  async close() {
    await this.popoverCtrl.dismiss({ role: 'cancel' });
  }
}
