import { Component, OnInit, Input } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss'],
  host: {'class': 'transparent-header tertiary-page'}
})
export class ModalImageComponent implements OnInit {

  @Input() image : any;
  @Input() imageFullsize : any;

  sliderOptions = {
    zoom:{
      maxRatio: 3,
      centeredSlides: true,
    }
  }
  public containerHeight;

  constructor(
      private platform: Platform,
      private modalCtrl: ModalController
    ) { }

  ngOnInit() {
    this.containerHeight = Math.min(this.platform.height());
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }

  onImageError(event){
    event.target.src = this.image;
  }

}
