import { Component } from '@angular/core';
import {
  NavController, AlertController,
  PopoverController, NavParams, ModalController, Platform
} from '@ionic/angular';
import { UtilsService } from '../../../services/utils.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { UserService } from '../../../services/data/user.service';
import { ApiService } from '../../../services/data/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../../services/data/session.service';
import {Config} from "../../../services/config/config";
import {ColorModeService} from "../../../services/native/color-mode.service";
import { FeedbackService } from 'src/app/services/popups/feedback.service';

@Component({
  selector: 'personality-type-page',
  templateUrl: 'personality-type.page.html',
  styleUrls: ['personality-type.page.scss'],
})
export class PersonalityType {
  public code: string = "";
  public title: string = "";
  public description: string = "";
  public story: string = "";
  public factor = 1;
  public topThreeFactors;
  public status: string = 'public';
  public newStatus: string = 'public';
  public isCurrentUserPersonality: boolean;
  public usersName: string;
  public backPage: any = "/tabs/profile";
  public isIos: boolean = false;
  public fromMatches: boolean;
  public isPlus: boolean;
  private tribe;
  private user;

  public isDarkMode: boolean = false;

  constructor(public navCtrl: NavController,
              public alertCtrl: AlertController,
              public utils: UtilsService,
              public api: ApiService,
              private feedbackService: FeedbackService,
              private popoverCtrl: PopoverController,
              public analyticsService: AnalyticsService,
              public userService: UserService,
              public router: Router,
              public sessionService: SessionService,
              public colorModeService: ColorModeService,
              public route: ActivatedRoute,
              public navParams: NavParams,
              private modalCtrl: ModalController,
              private platform: Platform,
              private config: Config
            ) {
    this.init();
    const data = this.navParams.data;
    this.code = data.personalityType.code;
    this.title = data.personalityType.name;
    this.description = data.personalityType.summary;
    this.story = data.personalityType.story;
    this.factor = data.personalityType.factorAvg;
    this.topThreeFactors = data.personalityType.topThreeFactors;
    this.isCurrentUserPersonality = data.currentUserPersonality;
    this.usersName = data.usersName;
    this.tribe = data?.tribe;
    this.user = data.user;
    this.sendAnalytics(data);

    this.fromMatches = data.fromMatches;
    this.isPlus = this.config.isPlus();

    if(this.isCurrentUserPersonality){
      this.userService.getPersonalityStatus().then((data:any) =>{
        this.status = data.personality_type_status;
      })
    }
  }

  async init() {
    this.isDarkMode = this.colorModeService.isDark();
    this.isIos = this.platform.is('ios');
  }

  sendAnalytics(data): void {
    let analyticsData: any = {};
    if(data?.tribe) {
      const { is_initiator, id } = data.tribe;
      analyticsData = { is_initiator, id };
    }
    if(data?.user) {
      analyticsData.target_user_id = data.user.id;
    }

    this.analyticsService.trackEvent({
      key: "viewed_personality_type",
      value: 1,
      source: data?.source,
      ...analyticsData
    })
  }

  async showStoryChangesWarning() {
    this.utils.showAlert(
      "Your Story may change as we get to know you better.",
      "If you're unhappy with it, you can make it private. Also, your personality is just one of five categories that informs our matching algorithm.",
      "",
      [
        { text: "Submit Feedback", handler: () => this.sendFeedback() },
        { text: "Close", role: 'cancel' }
      ]
    )
  }

  async warning(){
    this.utils.showAlert(
      'Reduced confidence in our assessment',
      'You fall right in the middle of one or more important personality factors. This makes our analysis slightly less reliable. If you think our assessment fails to reflect your personality, please rate it and provide feedback below.',
      "",
      [{ text: 'Ok', role: 'cancel' }]
    );
  }

  suggestRateUs() {
    this.utils.showAlert(
      'Great! Would you mind rating us?',
      'Rating us on the app store increases our visibility, which grows the pool of users and the quality of your future Groups.',
      '',
      [
        {
          text: 'Not Now',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Sure',
          handler: () => {
            this.analyticsService.trackEvent({
              key: 'rated_app', 
              value: 1, 
              source: 'personality_type'
            });
            this.utils.goToAppStore();
          }
        }
      ]
    )
  }

  async thumbsUp(){
    this.analyticsService.trackEvent({key: 'personality_thumbs_up', value: 1});
    this.suggestRateUs();
  }

  thumbsDown() {
    this.analyticsService.trackEvent({key: 'personality_thumbs_down', value: 1});
    this.showStoryChangesWarning();
  }

  sharePersonality() {
    let shareURL = 'https://www.we3app.com/types/' + this.code.toLowerCase();
    this.utils.shareApp('', '', shareURL);
    this.analyticsService.trackEvent({ key: 'shared_personality_type', value: 1 });
  }

  async sendFeedback() {
    this.feedbackService.showFeedbackForm({ context: 'Personality Type' });
  }

  async changePersoTypeStatus(){
    let handlerStatus = (data) =>{
      this.newStatus = data.name;
    }
    let alert = await this.alertCtrl.create({
      header: 'Visibility',
      message: '',
      inputs: [
        {
          name: 'private',
          type: 'radio',
          label: 'Private (only me)',
          checked: this.status == 'private',
          handler: handlerStatus
        },
        {
          name: 'public',
          type: 'radio',
          label: 'Visible to your matches',
          checked: this.status == 'public',
          handler: handlerStatus
        },
      ],
      cssClass: 'z-index-35000',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.newStatus = this.status;
          }
        },
        {
          text: 'Confirm',
        }
      ]
    }).then(alert =>{
      alert.present();
      alert.onDidDismiss().then(_=>{
        if(this.newStatus != this.status){
          this.userService.changePersonalityStatus(this.newStatus)
          .then(newStatus=>{
          this.status = this.newStatus
        }).
          catch(err => console.log(err));
        }
      })
    });
  }

  back() {
    this.modalCtrl.dismiss();
  }

}
