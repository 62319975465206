import {Component, Injectable} from '@angular/core';

@Component({
  selector: 'chat-map',
  template: `<a target="_blank" href="{{url}}"><img src="{{src}}"/><div class="description"><span class="headline">Shared location</span>
  <br>
  <span class="title">{{title}}</span></div></a>`
})
@Injectable()
export class ChatMap {
  public src: string ="";
  public url: string ="";
  public title: string = "";

  constructor(){ }

  public updateData(data: any){
    this.src = data.src;
    this.url = data.url;
    this.title = data.title;
  }
}

