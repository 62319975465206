import { Component, Injectable, Output, EventEmitter, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EmojiService } from '../../services/data/emoji.service';
import { Config } from '../../services/config/config';

@Component({
  selector: 'emojis-keyboard',
  templateUrl: 'emojis-keyboard.html',
  styleUrls: ['emojis-keyboard.scss']
})
@Injectable()
export class EmojisKeyboardComponent {
  @Output() onSelect = new EventEmitter();

  emojis: any[] = [];
  currentGroup: any = null;
  recentlyUsed: any[] = [];
  groups: any[] = [];

  constructor(public el: ElementRef, 
              public config: Config, 
              public emojiService: EmojiService,
              public platform: Platform) {
  }

  ngOnInit() {
    this.emojiService.load();
  }

  select(emoji) {
    this.recentlyUsed.push(emoji.u);
    this.onSelect.emit(emoji);
  }

  saveRecentEmojis(){
    if(this.recentlyUsed.length > 0) {
      this.config.updateNewEmojisUsed(this.recentlyUsed);
      this.recentlyUsed = [];
      this.emojiService.loadRecentlyUsed();
    }
  }

  init() {
    const groupIndex = this.emojiService.hasRecent() ? 0 : 1;
    this.currentGroup = this.emojiService.groups[groupIndex];

    this.groups = this.emojiService.groups;
    return this.loadGroup(this.currentGroup).then( _ => {
      setTimeout(_=> {
        this.resetScroll();
      }, 200);
    });
  }

  resetScroll(){
    this.el.nativeElement.scrollTop = 0;
  }

  loadGroup(group) {
    this.currentGroup =  group;
    return this.emojiService
      .loadEmojis(group)
      .then((items: any[]) => {
        this.emojis = items;
        console.log("emojis: ", this.emojis);
        this.resetScroll();
      });
  }
}
