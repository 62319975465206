import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'notice',
  template: `
    <div class = "flex text-left items-start mt-[10px] mb-[15px] gap-[5px]">
      <ion-icon class = "text-label text-large shrink-0" name = "information-circle-outline"></ion-icon>
      <div class = "text-small text-label">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./notice.component.scss'],
})
export class NoticeComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
