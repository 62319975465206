import { IonicModule } from '@ionic/angular';
import { NgModule } from "@angular/core";
import { InstagramComponent } from "./instagram.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { InstagramModalComponent } from 'src/app/components/instagram-modal/instagram-modal.component';
import { InstagramModalModule } from 'src/app/components/instagram-modal/instagram-modal.module';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { Loading } from 'src/app/components/loading/loading';

@NgModule({
  declarations: [
    InstagramComponent,
  ],
  entryComponents: [
    InstagramModalComponent,
    Loading,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    InstagramModalModule,
    LoadingModule
  ],
  exports: [
    InstagramComponent
  ]
})

export class InstagramModule {}
