import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchingStatus'
})
export class MatchingStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value === 'Available' ? 'Open' : value;
  }

}
