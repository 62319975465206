import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DeclineUserComponent } from './decline-user';
import { FormsModule  } from '@angular/forms';

@NgModule({
  declarations: [
    DeclineUserComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
  ],
  exports: [
    DeclineUserComponent
  ]
})
export class DeclineUserComponentModule {}
