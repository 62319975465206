import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {LightButtonComponent} from './light-button.component';

@NgModule({
  declarations: [
    LightButtonComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    LightButtonComponent
  ]
})

export class  LightButtonModule {}