/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import {fromNullable} from "../../shared/helpers/either-monad";
import {getDistance} from "geolib";
import {Config} from "../config/config";

@Injectable({
  providedIn: 'root'
})
export class GeoServiceService {

  private geoCoder;
  private latLng;
  private locationData;
  private locations = {};
  private currentLocation: string;

  constructor(private maps: MapsAPILoader,
              private config: Config) {
    this.defineLocations();
  }

  defineLocations() {
    this.locations = {
      city:    () => this.getCity(),
      region:  () => this.getRegion(),
      country: () => this.getCountry()
    }
  }

  getSearchLocation(coords, locations = ['city'], matching = true): Promise<any> {
    return new Promise((resolve, reject) => {
      if(!coords) {
        resolve('');
      }
      this.maps.load().then(() => {
        this.geoCoder = new google.maps.Geocoder();
        this.latLng = new google.maps.LatLng(coords.latitude, coords.longitude);
        this.geoCoder.geocode({ location: this.latLng }, (res, status) => {
          if(status === google.maps.GeocoderStatus.ZERO_RESULTS) {
            resolve('Reload the page');
          }
          let addressParts: any[] = [];
          let finalAddress: string = '';
          if(status === google.maps.GeocoderStatus.OK) {
            this.locationData = res;
            locations.forEach(loc => {
              addressParts.push(this.locations[loc]())
            });
            finalAddress = addressParts.filter(e => e.length).join(', ');
            this.currentLocation = matching ? finalAddress : '';
          }
          resolve(finalAddress)
        });
      });
    });
  }

  getCity() {
    let cityInfo = this.locationData.find(part => part.types.includes('locality'));
    let city = cityInfo ? cityInfo.address_components[0].short_name : '';
    return city;
  }

  getRegion() {
    let regionInfo = this.locationData.find(part => part.types.includes('administrative_area_level_1'));
    regionInfo = regionInfo.address_components.find(comp => comp.types.includes('administrative_area_level_1'));
    let region = regionInfo ? regionInfo.short_name : '';
    return region;
  }

  getCountry() {
    let countryInfo = this.locationData.find(part => part.types.includes('country'));
    let country = countryInfo ? countryInfo.address_components[0].long_name : '';
    return country;
  }

  setCurrentLocation(address) {
    this.currentLocation = address;
  }

  getCurrentLocation() {
    return this.currentLocation || '';
  }

  clearCurrentLocation() {
    this.currentLocation = '';
  }

  getDistanceTo(coords: { latitude: number, longitude: number }): number {
    const { latitude, longitude } = this.config.getProfile()
    const currentCoords = { latitude, longitude }
    return fromNullable(getDistance(currentCoords, coords))
      .map(d => Math.ceil((d / 1000)))
      .fold(e => 0, v => v);
  }

}

