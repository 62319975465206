import { Injectable } from '@angular/core';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Platform } from '@ionic/angular';
import { addHours, addMinutes, nextThursday, startOfDay } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { logc } from '../shared/helpers/log';
import { getFanMatchDate } from '../shared/helpers/time-helpers';
import { AnalyticsService } from './analytics/analytics.service';
import { Config } from './config/config';
import { MenuService } from './menu.service';
import { UtilsService } from './utils.service';

export interface CalendarEvent {
  title?: string;
  notes?: string;
  startDate?: Date;
  endDate?: Date;
  location?: string;
}

enum CalendarMedium {
  Google,
  Outlook,
  Office365,
  Yahoo
}

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private calendarEvent: CalendarEvent = null;

  public onCalendarEventAdded: Observable<any>;
  private calendarEventAddedSource: Subject<any> = new Subject();
  constructor(private diagnostic: Diagnostic,
              private platform: Platform,
              private config: Config,
              private menuService: MenuService,
              private analyticsService: AnalyticsService,
              private calendar: Calendar) {
    (window as any).calendarService = this;
    this.onCalendarEventAdded = this.calendarEventAddedSource.asObservable();
  }

  async addEvent({ calendarEvent, event = null }): Promise<any> {
    if(!this.platform.is('cordova')) {
      await this.addEventWeb(event);
    } else {
      return this.addEventCordova(calendarEvent, event);
    }
  }

  private async addEventWeb(event) {
    const items = this.getWebOptions();
    await this.menuService.createOptionsMenu(event, items, `menu-popover min-height-${ items.length }`)
  }

  private getWebOptions(): any[] {
    return [
      {
        image: "./assets/img/calendars/google.svg",
        text: "Google",
        handler: () => this.selectWebMedium(this.calendarEvent, CalendarMedium.Google)
      },
      {
        image: "./assets/img/calendars/outlook.svg",
        text: "Outlook",
        handler: () => this.selectWebMedium(this.calendarEvent, CalendarMedium.Outlook)
      },
      {
        image: "./assets/img/calendars/yahoo.svg",
        text: "Yahoo",
        handler: () => this.selectWebMedium(this.calendarEvent, CalendarMedium.Yahoo)
      }
    ]
  }

  addDefaultFanMatcingDate(event = null): void {
    const startDate = new Date(this.config.get("partner")["next_match_date"]);
    this.calendarEvent = {
      title: `New Group Match: Fans of ${ this.config.getPartnerName() } (on We3)`,
      notes: "",
      startDate,
      endDate: addMinutes(startDate, 30),
      location: "https://we3.app.link/"
    }
    this.addEvent({ calendarEvent: this.calendarEvent, event });
  }

  async isEventAdded(): Promise<boolean> {
    if(!this.calendarEvent) return;
    const { title, location, notes, startDate, endDate } = this.calendarEvent;
    return !!(await this.calendar.findEvent(title, location, notes, startDate, endDate))?.length;
  }

  private async addEventCordova(calendarEvent: CalendarEvent, event) {
    const authStatus = await this.diagnostic.getCalendarAuthorizationStatus();
    logc.crimson("calendar status: ", authStatus);
    switch(authStatus) {
      case this.diagnostic.permissionStatus.NOT_REQUESTED:
        await this.diagnostic.requestCalendarAuthorization();
        this.addEventCordova(calendarEvent, event);
        break;
      case this.diagnostic.permissionStatus.DENIED_ALWAYS:
        this.addEventWeb(event);
        break;
      case this.diagnostic.permissionStatus.DENIED_ONCE:
        this.addEventWeb(event);
        break;
      case this.diagnostic.permissionStatus.GRANTED:
        this.createEvent(calendarEvent);
        break;
      case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
        this.createEvent(calendarEvent);
        break;
    };
  }

  private createEvent(calendarEvent: CalendarEvent) {
    const { title, location, notes, startDate, endDate } = calendarEvent;
    this.calendar
      .createEvent(title, location, notes, startDate, endDate)
      .then(async () => {
        if(await this.isEventAdded()) {
          this.calendarEventAddedSource.next(null)
        }
      });
  }

  private selectWebMedium(calendarEvent: CalendarEvent, medium) {
    switch(medium) {
      case CalendarMedium.Google:
        return this.createGoogleCalendarEvent(calendarEvent);
      case CalendarMedium.Outlook:
        return CalendarService.createOutlookCalendarEvent(calendarEvent);
      case CalendarMedium.Office365:
        return CalendarService.createOffice365CalendarEvent(calendarEvent);
      case CalendarMedium.Yahoo:
        return CalendarService.createYahooCalendarEvent(calendarEvent);
      default:
        return this.createGoogleCalendarEvent(calendarEvent);
    }
  }

  private createGoogleCalendarEvent(event) {
    const { title, location, notes, startDate, endDate } = event;
    const urlBase = "https://www.google.com/calendar/render?";
    const action = "action=TEMPLATE";
    const text = `&text=${ encodeURI(title) }`;
    const details = `&details=${ encodeURI(notes) }`; 
    const _location = `&location=${ encodeURI(location) }`;
    const ctz = `&ctz=${ encodeURI(this.config.getFlag("timezone") || "") }`;
    const dates = 
      `&dates=${ startDate.toISOString() }/${ endDate.toISOString() }`
        .replaceAll(/[-:]/g, '')
        .replaceAll(".000", "");
    const creatingEventUrl = urlBase + action + text + details + _location + dates + ctz;
    (window as any).location.href = creatingEventUrl;
  }

  private static generateOutlookLink(event): string {
    const { title, location, startDate, endDate } = event;
    const base = "https://outlook.live.com/calendar/0/deeplink/compose?allday=false";
    const start = "&startdt=" + encodeURIComponent(startDate.toISOString());
    const end = "&enddt=" + encodeURIComponent(endDate.toISOString());
    const loc = "&location=" + encodeURI(location);
    const stuff = "&path=%2Fcalendar%2Faction%2Fcompose";
    const rru = "&rru=addevent";
    const subject = "&subject=" + encodeURI(title)
    return base + start + end + loc + stuff + rru + subject; 
  }

  private static createOutlookCalendarEvent(event): void {
    (window as any).location.href = CalendarService.generateOutlookLink(event);
  }
  private static createOffice365CalendarEvent(event): void {
    (window as any).location.href = CalendarService.generateOutlookLink(event).replace("live", "office");
  }
  private static createYahooCalendarEvent(event): void {
    const { title, location, startDate, endDate } = event;
    const base = "https://calendar.yahoo.com/?dur=";
    const end = "&et=" + endDate.toISOString().replaceAll(/[-:]/g, '').replaceAll(".000", "");
    const loc = "&in_loc=" + encodeURI(location);
    const start = "&st=" + startDate.toISOString().replaceAll(/[-:]/g, '').replaceAll(".000", "");
    const subject = "&title=" + encodeURI(title);
    (window as any).location.href = base + end + loc + start + subject + "&v=60";
  }
}
