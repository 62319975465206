import {Component, Input, OnInit} from '@angular/core';
import {NavController} from "@ionic/angular";

@Component({
  selector: 'chat-item',
  template: `
    <ion-item no-padding class = "tribe-item" (click) = 'goToTribe(tribe)'>
      <tribe-avatar [tribe] = "tribe"></tribe-avatar>
      <ion-label text-wrap>
        <div class = "tribe-title-section">
          <div class = "tribe-title" primary-text>
            {{ tribe.status === 'closed' ? 'Just You' : tribe.name || 'Just You' }}
          </div>
          <div *ngIf = "tribe.status === 'expired'" class = "label expired-label">EXPIRED</div>
        </div>
        <div class = "base-text body-color truncated" [innerHTML]="tribe?.last_message?.content | lastMsg:tribe"></div>
      </ion-label>
      <ion-badge *ngIf = "tribe.unread_messages_count > 0"
                 mode = "ios"
                 slot = "end"
                 color = "secondary">
        {{ tribe.unread_messages_count }}
      </ion-badge>
    </ion-item>
  `,
  styleUrls: ['./chat-item.component.scss'],
})
export class ChatItemComponent implements OnInit {
  @Input("tribe") tribe: any;

  constructor(private navCtrl: NavController) { }

  ngOnInit() {}

  goToTribe(tribe) {
    tribe.unread_messages_count = 0;
    this.navCtrl.navigateForward(`tribes/${tribe.id}/chat`);
    // this.navCtrl.navigateForward(`room/${tribe.id}`);
  }

}
