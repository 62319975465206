import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'lastMsg'
})
export class TribeLastMessagePipe implements PipeTransform {

  transform(message: string, tribe: any) {
    if(!message) return;
    if(message.includes("https://s3.amazonaws.com/me3")) {
      return  "<i class='ss-icon'>camera</i>" + " Photo";
    }
    if(message.includes("https://media")) {
      return "<i class='material-icons' >gif</i>";
    }

    return message.split('<br>')[0];
  }

}
