import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(value: string, plural = false) {
    if(!value) return null;
    let gender = value.toLowerCase();
    let data = {
      male: plural ? 'men' : 'man',
      female: plural ? 'women' : 'woman'
    };
    return (!data[gender]) ? 'non-binary' : data[gender];
  }
}
