import {UAParser} from "ua-parser-js";
import {fromNullable} from "./either-monad";
import {OverlayEventDetail} from "@ionic/core/dist/types/utils/overlays-interface";
import {environment} from "../../../environments/environment";
import * as faker from "faker";
import { getRandomFutureDays, hoursLeft } from "./time-helpers";
import { MeetupStatus } from "../enums/meetup-statuss.enum";
import { shuffle } from "lodash";
import { logc } from "./log";
const deviceParser = new UAParser();

interface SequenceElement {
  wait: number;
  run: Function;
}

type IntervalSequence = Array<SequenceElement>

export const clearBase64 = (obj) => {
  const marker = "data:image/jpeg;base64";
  for(let key in obj) {
    if(typeof obj[key] == 'string') {
      if(obj[key].includes(marker)) {
        obj[key] = "base64 replacement";
      }
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach(clearBase64)
    } else if(typeof obj[key] == 'object') {
      clearBase64(obj[key]);
    }

  }
  return obj;
}

export const createIterator = (array) => {
  let index = 0;
  let forward = true;
  return {
    next: () => {
      console.log("next: ", index);
      if(!forward) index++;
      forward = true;
      if(index == array.length) {
        return { first: index == 0, value: array[array.length - 1], last: index == array.length  };
      } else {
        return { first: index == 0, value: array[index++], last: index == array.length  }
      }
    },
    prev: () => {
      console.log("prev: ", index);
      if(forward) index--;
      forward = false;
      if(index == 0) {
        return { first: true, value: array[0], last: index == array.length  }
      } else {
        if(index == array.length) index -= 1;
        return { first: index - 1 == 0, value: array[--index], last: index == array.length };
      }
    }
  }
}

export const performSequentially = (sequence: IntervalSequence): void => {
  for(let x = sequence.length - 1; x >= 0; x--) {
    for(let y = x - 1; y >= 0; y--) {
      sequence[x].wait += sequence[y].wait;
    }
  }

  sequence.forEach(e => setTimeout(e.run, e.wait));
}

export const getRandomly = (variants: Array<any>): any => {
  if(!Array.isArray(variants) || !variants.length || !variants) return '';

  const elementId = Math.floor(Math.random() * variants.length);

  return variants[elementId];
}

export const msToSec = (ms) => Math.floor(ms / 1000);

export const getDeviceInfo = () => deviceParser.getDevice();
export const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test((<any>navigator).userAgent);
export const prepareExtras = (state) => ({ state });


export const getExpiryIconColor = ({ expires_at }) => {
  const _hoursLeft = hoursLeft(expires_at || new Date());
  switch(true) {
    case _hoursLeft < 2:
      return "bg-error-light-1 text-error-dark-3";
    case _hoursLeft >=2 && _hoursLeft < 12:
      return "bg-tertiary-light-1 text-tertiary-dark-3";
    default: 
      return "text-secondary-dark-3 bg-secondary-light-1";
  }
}
  
export const getExpiryColors = ({ expires_at, on_extended_invite }) => {
  const _hoursLeft = hoursLeft(expires_at || new Date());
  switch(true) {
    case on_extended_invite:
      return "bg-success-light-1 text-success-darl-3";
    case _hoursLeft >= 2 && _hoursLeft < 12:
      return "bg-tertiary-light-1 text-tertiary-dark-3";
    case _hoursLeft < 2:
      return "bg-error-light-1 text-error-dark-3";
    default:
      return "bg-secondary-light-1 bg-text-dark-3";
  }
}

export const isIosPWA = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform);

export const getTribeIDFromUrl = (url) =>
  fromNullable(url.match(/tribes\/(.*)\/chat/))
    .map((matchElems) => matchElems[1])
    .map(parseInt)
    .fold(
      (notFound) => '',
      (tribeId) => tribeId
    );

export const modalCancelled = (data: OverlayEventDetail) => ["gesture", "backdrop"].includes(data.role)

export const getNumberVerificationPageStateFromPhoneStatus = (phoneStatus: string) => {
  if(phoneStatus == 'pending') return 'unverified';
  if(!phoneStatus || phoneStatus == 'undelievered') return 'no_number';

  return phoneStatus;
}

export const getOneSignalServiceWorkerPath = () => {
  const env = environment.name;
  if(env == 'production') {
    return '/OneSignalSDKWorkerProd.js'
  } else {
    return '/OneSignalSDKWorker.js'
  }
}

export const keys = (obj) => {
  if(typeof obj != 'object') {
    return [];
  } else {
    return Object.keys(obj);
  }
}

export const values = (obj) => {
  if(typeof obj != 'object') {
    return [];
  } else {
    return Object.values(obj);
  }
}

export const getRandom = multiplier => Math.ceil(Math.random() * multiplier);

export const getProgressColor = (p) => {
  switch(true) {
    case (p < 50):
      return "error-color";
    case (p >= 50 && p <= 89):
      return "tertiary-color";
    case (p > 89):
      return "success-color"
  }
}

export const HashTherapist = (obj) => ({
  ...obj,
  ...{
    undefined: () => console.log("Requested key is undefined"),
    null: () => console.log("Requested key is null")
  }
});

export const blankFn = (data) => {};

export const getDefaultPicture =
  (gender) =>
    `./assets/img/default-${ gender === 'male' ? '' : 'female-' }avatar.png`;

export const DUMMY_TRIBE = {
  users: Array(4).fill({})
}

export const getUsers = (number) => {
  if(number > 10) {
    logc.error("Number should be <= 10");
    return [];
  }
  let users = [];
  for(let i = 1; i <= number; i++) {
    users.push({
      picture: `./assets/img/portraits/${ i }.jpeg`,
      first_name: faker.name.firstName(),
      birthday: null,
      details: {
        location: { distance: 100 },
        work_organization: "Work Org",
        work_title: "Work title",
        school: "School"
      }
    })
  }
  return shuffle(users);
}

export const getRandomImage =
  (width = 200, height = 200) =>
    `https://picsum.photos/id/${ Math.floor(Math.random() * 1000) }/${ width }/${ height }`

export const getShortUser = () => ({
  id: Math.floor(Math.random() * 9999),
  picture: getRandomImage(),
  first_name: faker.name.firstName()
})

export const getMeetupEvents = (eventsNumber, usersNumber = 3) => {
  const users = Array.from({ length: usersNumber }, getShortUser);
  return getRandomFutureDays(5, 4)
    .map((date) => ({ 
      user_id: getRandomly(users).id, 
      status: getRandomMeetupStatus(),
      date 
    }));
}

export const logObject = (object: Object, level = 1): void => {
  if(typeof object != 'object') throw new Error("This is not object");
  for(let key in object) {
    if(typeof object[key] != 'object') {
      console.log(`${ '-'.repeat(level) } ${ key }: ${ object[ key ] }`);
    } else {
      console.log(`${ '-'.repeat(level) } ${ key }: `);
      logObject(object[key], level + 1);
    }
  }
}

export const createFunctionWithBlockingTimeout = function(cb: Function, timeout) {
  let blocked = false;
  return function() {
    setTimeout(() => blocked = false, timeout)
    if(!blocked) {
      cb();
      blocked = true;
    }
  }
}

export const getUserName = (userId, tribe) => [ 
  tribe.tribe_user, 
  ...tribe.users
].find(user => user.id == userId)?.first_name;

export const getRandomMeetupStatus = () => 'blank'
  // getRandomly([
  //   MeetupStatus.Blank, 
  //   MeetupStatus.Maybe,
  //   MeetupStatus.Available,
  //   MeetupStatus.Busy
  // ])

export const isDev = () => environment.name == 'development';

if(environment.name == 'development') {
  (window as any).faker = {
    getShortUser,
    getMeetupEvents,
    getRandomMeetupStatus,
    getUsers
  }
}
