import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Config } from 'src/app/services/config/config';
import { SearchService } from 'src/app/services/data/search.service';
import { TribesService } from 'src/app/services/data/tribes.service';
import { MenuItem } from 'src/app/services/menu.service';
import { ModalService } from 'src/app/services/popups/modal.service';
import { MenuPopoverComponent } from '../menu-popover/menu-popover.component';

@Component({
  selector: 'app-fan-failed-search',
  template: `
    <ion-content class = "padding-20">
      <div class = "relative flex flex-col justify-center items-center top-[100px] move-to-top">
        <ng-lottie class = "-my-[125px]"
                   [options] = "calendarAnimationConfig"
                   width = "500px"
                   height = "500px"
                   (animationCreated) = "handleAnimation($event)">
        </ng-lottie>
        <div class = "text-center font-semibold text-title text-xl my-[12px]">
          Your group is being rescheduled
        </div>
      </div>
      <div class = "w-full text-center opacity-0 show">
        <div class = "text-base text-body mt-[10px] mb-[25px]">
          There are {{ searchData?.total_matches }} fans of {{ influencer }} in {{ userArea }}
          {{ 
            searchData?.total_matches > 0 
              ? ", but they’re not available this week." 
              : " who signed up to match this week." 
          }}
        </div>
        <potential-matches-short *ngIf = "searchData?.total_matches > 0"
                                 matchingType = "fans"
                                 title = "Fans Unavailable"
                                 [searchData] = "searchData"
                                 [matchesNumber] = "searchData?.total_matches"></potential-matches-short>
        <ion-button class = "turquoise-gradient-btn btn-large-action ionic-button turquoise-btn-shadow"
                    shape = "round" 
                    (click) = "tryAgainNextWeek()">
          Try again next week
        </ion-button>
        <ion-button  class = "empty-button flex items-center gap-[1px]" 
                     style = "border: none;" 
                     (click) = "openMenu($event)">
          More options
          <ion-icon name = "chevron-down-outline"></ion-icon>
        </ion-button>
      </div>
    </ion-content>
  `,
  styleUrls: ['./fan-failed-search.component.scss'],
})
export class FanFailedSearchComponent implements OnInit {
  @Input() searchData: any = {};
  public calendarAnimationConfig: any = {
    path: './assets/gif/calendar-short.json',
    renderer: 'canvas',
    autoplay: true,
    loop: false
  }

  public influencer: string = null;
  public userArea: string = null;

  constructor(private config: Config,
              private popoverCtrl: PopoverController,
              private modalCtrl: ModalController,
              private analyticsService: AnalyticsService,
              private tribesService: TribesService,
              private searchService: SearchService) { }

  ngOnInit() {
    this.influencer = this.config.getPartnerName();
    this.userArea = this.config.getUserCity();
    this.analyticsService.trackEvent({
      key: "failed_fan_match_viewed",
      value: 1
    })
  }

  handleAnimation(animation) {
    animation.play();
  }

  async tryAgainNextWeek() {
    this.analyticsService.trackEvent({
      key: "failed_fan_match_try_again_next_week",
      value: 1
    })
    await this.modalCtrl.dismiss();
    this.searchService.openFanSearchDetails();
  }

  openMenu(event) {
    const items: MenuItem[] = [
      {
        icon: { name: "stars", cssClass: "text-tertiary" },
        text: "Match now (Best Overall)",
        handler: async () => {
          this.analyticsService.trackEvent({
            key: "failed_fan_match_try_best_overall",
            value: 1
          });
          await this.modalCtrl.dismiss();
          this.searchService.openFansSearchPicker();
        }
      },
      // {
      //   icon: { name: "people", cssClass: "text-label" },
      //   text: "Get other fans to sign up",
      //   handler: () => {

      //   }
      // },
      {
        icon: { name: "ban", cssClass: "text-error" },
        text: "Abort",
        handler: async () => {
          await this.modalCtrl.dismiss();
          this.tribesService.cancelSearch(this.searchData.id)
        }
      }
    ]
    this.createMenuPopover(event, items, `menu-popover min-height-${ items.length }`);
  }

  private async createMenuPopover(
    event,
    items,
    cssClass = "menu-popover",
    currentUser: any = {}
  ) {
    try {
      const popover = await this.popoverCtrl.create({
        component: MenuPopoverComponent,
        componentProps: { items, isAwaiting: currentUser.status == 'awaiting' },
        backdropDismiss: true,
        id: "menu-popover",
        event,
        cssClass
      });
      await popover.present();
      return popover;
    } catch ( err ) {
      console.log( err );
    }
  }
}
