<ion-content class = "tribes">
    <div *ngIf = "isDev"> 
      <button class = "m-4 p-15 bg-secondary text-[#fff] text-base"
            (click) = "iosPwaExperience()">
      Ios fan pwa
    </button> 
    <button class = "m-4 p-15 bg-secondary text-[#fff] text-base" 
          (click) = "showIosUserStatus()">
      Ios user status
    </button>
  </div>
  <page-status></page-status>
  <notification-badge *ngIf = "showProfileStrengthBoost"
                      [content] = "boostStrengthNotificationContent"
                      [showCloseButton] = "showProfileStrengthBoost"
                      [cssClass] = "'shadow-md m-[10px] ' + (isLatestVersion ? '' : 'update')"
                      (onClose) = "config.setFlag('boostProfileBadgeTapped', true)"></notification-badge>

  <div class = "section-title heading" [class.update-message-margin] = "!isLatestVersion">
    {{ wording?.tribesPage?.queue?.title }}
    <ion-icon *ngIf = "isDev" (click) = "cleanQueue()" name = "trash-outline"></ion-icon>
  </div>
  <div class = "tribe-slider">
    <div class = "slider-active">
      <matches-plate *ngIf = "readyForMatches || isPlus"></matches-plate>
      <div *ngFor = "let tribe of queue"
           class = "tribe-container"
           (click) = "openQueueTribe(tribe)"
           [ngSwitch] = "tribe.type">
        <topic-tribe-avatar *ngSwitchCase = "'fans'" [inProgress] = "true" [tribe] = "tribe"></topic-tribe-avatar>
        <searching-tribe-avatar *ngSwitchCase = "'searching'" [tribe] = "tribe"></searching-tribe-avatar>
        <tribe *ngSwitchDefault [tribe] = "tribe"></tribe>
      </div>
    </div>
  </div>

  <div *ngIf = "chats.length > 0; else logo">
    <div class = "section-title heading">
      CHATS
      <ion-icon *ngIf = "isDev" (click) = "cleanChats()" name = "trash-outline"></ion-icon>
    </div>
    <ion-list class = 'chats' lines = "none">
      <chat-item *ngFor = "let tribe of chats" [tribe] = "tribe"></chat-item>
    </ion-list>
  </div>
  <ng-template #logo>
    <tribes-guide></tribes-guide>
  </ng-template>
</ion-content>

<div class = "button-container" (click) = "showMatchMePopover()">
  <ion-button class = "turquoise-gradient-btn btn-large-action ionic-button turquoise-btn-shadow"
              #startButton
              id = "startButton"
              [disabled] = "!isReadyToMatch() || this.processing || this.processingMatchingInfo || !this.stateIs('connected')"
              [class.disabled-button] = "!isReadyToMatch() || this.processing || this.processingMatchingInfo || !this.stateIs('connected')"
              [class.golden-button] = "matchPreferences?.remote_city_name && !isPlus"
              [class.black-shadow] = "config.isDarkMode()"
              shape = "round"
              expand = "block"
              (click) = "startSearch()">
    <div class = "connecting-label"
         *ngIf = "sessionService.stateMachine.state !== 'ready' ||
                  pusherService.state !== 'connected' ||
                  processingMatchingInfo;
                  else matchMeButton">
      <ion-spinner></ion-spinner>
      Preparing algorithm...
    </div>
    <ng-template #matchMeButton>
      <div class = "title-font">
        {{ matchMeButtonText }}
      </div>
    </ng-template>
  </ion-button>
</div>
<match-me-popover style = "display: none"></match-me-popover>
