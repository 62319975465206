import {Component, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavController, NavParams, PopoverController, ToastController } from '@ionic/angular';
import { ApiService } from '../../services/data/api.service';
import { TribeService } from '../../services/data/tribe.service';
import { TribesService } from '../../services/data/tribes.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { UtilsService } from '../../services/utils.service';
import { D3AnimationsService } from '../../services/helpers/d3-animations.service';
import { CountdownTimerService } from '../../services/helpers/countdown-timer.service';
import { ActivatedRoute } from '@angular/router';
import { Config } from '../../services/config/config';
import { CustomActionSheetComponent } from '../../components/custom-action-sheet/custom-action-sheet.component';
import {FeedbackService} from "../../services/popups/feedback.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DispatcherService} from "../../services/dispatcher.service";
import {PopupService} from "../../services/popups/popup.service";
import {logc} from "../../shared/helpers/log";
import {ModalService} from "../../services/popups/modal.service";
import {ReportService} from "../../services/report.service";
import { hoursLeft } from 'src/app/shared/helpers/time-helpers';

@Component({
  selector: 'page-tribe-details',
  templateUrl: 'tribe-details.page.html',
  styleUrls: ['tribe-details.page.scss'],
})
export class TribeDetailsPage {
  @ViewChild(CustomActionSheetComponent) customSheet;
  public tribeMates: any[] = [];
  public tribe: any;
  public name: string;
  public status: string;

  public swapStatus: boolean;
  public currentUser: any = {};
  public matesForActionSheet;
  public isInitiator: boolean;
  public chatUnlocked: boolean;
  public alert;
  public toast;
  public actionState: string = '';

  public showFeedbackModal: boolean = false;
  public reportUserIds: any;

  public modalOptions;

  public leaveTimeout;
  public unsubscribe = new Subject();
  public isDirectMessage = false;
  public users: any[] = [];

  constructor(public navCtrl: NavController,
              public api: ApiService,
              private popupService: PopupService,
              private dispatcherService: DispatcherService,
              public navParams: NavParams,
              public route: ActivatedRoute,
              public tribeService: TribeService,
              public tribesService: TribesService,
              public analyticsService: AnalyticsService,
              public toastCtrl: ToastController,
              public alertCtrl: AlertController,
              public utils: UtilsService,
              public config: Config,
              public popoverCtrl: PopoverController,
              private d3: D3AnimationsService,
              private reportService: ReportService,
              public modalCtrl: ModalController,
              private feedbackService: FeedbackService,
              private timerService: CountdownTimerService,
              private modalService: ModalService) {

    this.tribe = this.navParams.get('tribe');
    this.users = [ ...this.tribe.users, this.tribe.tribe_user ];
    this.onData();

    this.tribesService
      .onTribeChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(tribe => {
        this.tribe = tribe;
        this.onData();
      });

    this.tribesService
      .onTribeLeft
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        clearTimeout(this.leaveTimeout);
        this.navigateBack();
      })

  }

  setUserStatus(mate) {
    let timeLeft = this.utils.getExpiredDate(mate.expires_at);
    let statuses = {
      "standby": `On Standby - <span class='standby-status'>New Member</span>`,
      "invited": `Invited - <span class='${ this.utils.getExpirationTextColor(mate) }'>Expires in ${timeLeft}</span>`,
      "accepted": "Active",
      "expired": "Expired",
      "needs_review": `Active - <span class="pending-confirmation">Pending Confirmation</span>`,
    };

    return statuses[mate.status];
  }

  getExpirationColor( hoursLeft ) {
    if (hoursLeft >= 0 && hoursLeft < 1) return "error-color";
    if (hoursLeft >= 1 && hoursLeft < 6) return "tertiary-color";

    return "secondary-color";
  }

  async navigateBack() {
    this.utils.doneLoading();
    this.modalCtrl.dismiss();
    this.navCtrl.navigateBack("tabs/tribes");
  }

  onData() {
    this.tribeMates = [ ...this.tribe.users ];
    this.tribeMates.uniqPush(this.tribe.tribe_user);
    this.matesForActionSheet = this.tribeMates
      .filter(mate => mate.id != this.tribe.tribe_user.id)
      .map(mate => {
        return {
          picture: mate.picture,
          message: `Report ${mate.first_name}`,
          subTitle: 'Don\'t worry, this is anonymous.',
          id: mate.id,
        }
      });

    this.name = this.tribeMates.map(mate => mate.first_name).join(' & ');
    this.status = this.tribe.status;
    this.isInitiator = this.tribe.is_initiator;
    this.swapStatus = this.tribe.swap_automatically;
    this.currentUser = this.tribe.tribe_user;
    this.chatUnlocked = this.tribe.chat_unlocked;
    if(this.isDirectMessageGroup()) {
      this.isDirectMessage = true;
    }

    setTimeout(() => {
      let elements = this.collectUsersForProgressBars(this.tribeMates);
      this.d3.startProgressBars(elements);
    }, 0);

  }

  collectUsersForProgressBars(tribemates) {
    let elements = [];
    let mates = tribemates.filter( mate => mate.status == 'invited' );
    mates.forEach( mate => {
      let progress = (new Date(mate.expires_at).getTime() - new Date().getTime()) / 86400000;
      let hoursLeft = this.timerService.getGenericTime(mate.expires_at).hours;
      let element = {
        'id': mate.id,
        'color': this.utils.getProgressMeterColor(hoursLeft),
        'amount': `-${progress.toFixed(2)}`
      };
      elements.push(element);
    });
    return elements;
  }

  isDirectMessageGroup() {
    return this.tribe?.type && this.tribe?.type == 'DirectMessage';
  }

  hasThreeUsers() {
    return this.tribeMates.length === 3;
  }

  async showProfileOptions( user, event ) {
    await this.tribeService.showProfileOptions({
      sender: user,
      event: event,
      source: 'details',
      tribe: this.tribe
    });
  }

  startLeavingFlow() {
    this.tribeService.startLeavingFlow(this.tribe);
  }

  startReportingFlow() {
    this.reportService.reportTribe(this.tribe);
  }

  close() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
