import { Injectable } from '@angular/core';
import {ColorModeService} from "../native/color-mode.service";
import {CountdownTimerService} from "./countdown-timer.service";
import {UtilsService} from "../utils.service";
import {logc} from "../../shared/helpers/log";
import { getExpiryTime } from "../../shared/helpers/time-helpers";
var ProgressBar = require('progressbar.js');

@Injectable({
  providedIn: 'root',
})
export class D3AnimationsService {

  constructor(private colorModeService: ColorModeService,
              private countdownTimerService: CountdownTimerService,
              private utils: UtilsService) {
  }

  startProgressBars(elements, title?) {
    if (elements.length) {
      elements.forEach(element => {
        let container = document.getElementById(`progress-meter-${title ? title : ''}${element.id}`);
        if (container && !container.firstChild) {
          let bar = new ProgressBar.Circle(`#progress-meter-${title ? title : ''}${element.id}`, {
            strokeWidth: 4.5,
            easing: 'easeInOut',
            duration: 1800,
            color: element.color,
            trailColor: this.colorModeService.isDark() ? "#242A39" : "#FFFFFF",
            trailWidth: 3.5,
            svgStyle: null,
          });
          let amount = element.amount;
          if(amount <= -1) 
            amount = -0.9999;
          bar.animate(amount);  // Number from 1.0 to 0.0
        }
      })
    }
  }

  createProgressBar({ tribeId, user }) {
    setTimeout(async () => {
      if(document.getElementById(`progress-meter-${ tribeId }-${ user.id }`)?.children?.length) return;
      
      const hoursLeft = this.countdownTimerService.getFullHours(user.expires_at);
      let progress = (new Date(user.expires_at).getTime() - new Date().getTime()) / getExpiryTime(user)
      const bar = new ProgressBar.Circle(`#progress-meter-${ tribeId }-${ user.id }`, {
        strokeWidth: 8,
        easing: 'easeInOut',
        duration: 1800,
        color: this.utils.getProgressMeterColor(hoursLeft),
        trailColor: this.colorModeService.isDark() ? "#242A39" : "#FFFFFF",
        trailWidth: 3.5,
        svgStyle: null,
      });

      progress = progress <= -1 ? -0.9999 : -1 * progress;
      bar.animate(progress);
    }, 0)
  }

  clearOldProgressBar(tribeId, user) {
    return Promise.resolve(
      document.getElementById(`progress-meter-${ tribeId }-${ user.id }`).textContent = ''
    );
  }
}
