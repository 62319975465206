import { Injectable } from '@angular/core';
import {MenuPopoverComponent} from "../components/menu-popover/menu-popover.component";
import {PopoverController} from "@ionic/angular";

export interface MenuItem {
  text: string;
  handler: Function;
  image?: string;
  keepOpened?: boolean;
  rightIcon?: MenuItemRightIcon;
  icon?: MenuItemIcon;
}

interface MenuItemRightIcon {
  name: string
}

interface MenuItemIcon {
  name: string;
  cssClass: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private popoverCtrl: PopoverController) { }

  async createMenuPopover({ event, componentProps, cssClass = "menu-popover" }) {
    try {
      const popover = await this.popoverCtrl.create({
        component: MenuPopoverComponent,
        componentProps,
        event,
        cssClass,
        backdropDismiss: true,
      });
      await popover.present();
    } catch ( err ) {
      console.log( err );
    }
  }

  async createOptionsMenu(
    event,
    items,
    cssClass = "menu-popover",
    currentUser: any = {}
  ) {
    try {
      const popover = await this.popoverCtrl.create({
        component: MenuPopoverComponent,
        componentProps: { items, isAwaiting: currentUser.status == 'awaiting' },
        backdropDismiss: true,
        id: "menu-popover",
        event,
        cssClass
      });
      await popover.present();
      return popover;
    } catch ( err ) {
      console.log( err );
    }
  }
}
