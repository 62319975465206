<ion-header class = "informative-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-icon name = "close" class = "back-button" (click)="back()"></ion-icon>
    </ion-buttons>
    <ion-title>Personality Type</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="sharePersonality()">
        <ion-icon slot="end" name="share"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class = "informative-page">
  <page-status></page-status>
  <div *ngIf = "isCurrentUserPersonality"
       class = " personality-type-status {{ isDarkMode ? 'blue-dark' : 'blue'}}">
    <div class="inner-status" *ngIf="status=='public'">
      <ion-icon name="eye"></ion-icon>
      <span class="status-message">
        Visible only to your matches.
      </span>
    </div>
    <div class="inner-status" *ngIf="status=='private'">
      <ion-icon name="eye-off"></ion-icon>
      <span class="status-message">
        Your Personality Type is private.
      </span>
    </div>
    <div class="change-personality-type-status" (click)="changePersoTypeStatus()">
      Change
    </div>
  </div>


  <div class = "top-content">
    <ion-icon *ngIf="factor == 0.5" name="warning"
              class="warning-icon" (click)="warning()"></ion-icon>
    <div class = "images-holder">
      <div class = "left-holder">
        <img src = "{{topThreeFactors[1].image}}">
        <div>{{topThreeFactors[1].label}}</div>
      </div>
      <div class = "center-holder">
        <img src = "{{topThreeFactors[0].image}}">
        <div>{{topThreeFactors[0].label}}</div>
      </div>
      <div class = "right-holder">
        <img src = "{{topThreeFactors[2].image}}">
        <div>{{topThreeFactors[2].label}}</div>
      </div>
    </div>

    <div class = "header-title">{{title}}</div>
    <div class = "profile-section white-section" id="description">
      <div class="content">{{description}}</div>
    </div>
  </div>

  <div class = "profile-section blue-section padded-section">
    <div class = "heading" *ngIf="isCurrentUserPersonality">Your Story</div>
    <div class = "heading" *ngIf="!isCurrentUserPersonality">{{fromMatches && !isPlus ? '' : usersName + '\'s'}} Story</div>
    <div class = "label personality-type">{{title}}</div>
    <div class = "content story-text">{{story}}</div>

    <ion-button class = 'ionic-button turquoise-gradient-btn translate-centered'
                shape = "round"
                (click)="sharePersonality()">
      Share Personality Type
    </ion-button>

  </div>
  <div class = "informative-footer" *ngIf="isCurrentUserPersonality">
    <div>
      <div class = "label">Is your story accurate?</div>
      <ion-icon id = "thumb-up" name="thumbs-up" (click) = "thumbsUp()"></ion-icon>
      <ion-icon id = "thumb-down" name="thumbs-down" (click) = "thumbsDown()"></ion-icon>
    </div>

  </div>

</ion-content>
