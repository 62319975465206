const Skip = value => ({
  map: fn => Skip(value),
  fold: (error, success) => error()
})

const Map = value => ({
  map: fn => Map(fn(value)),
  fold: (error, success) => success(value)
})

export const fromNullable = x => x == null ? Skip(x) : Map(x);

(window as any).fromNullable = fromNullable;
