import { Component, OnInit } from '@angular/core';
import {PushNotification} from "../../services/push-notification/push-notification";
import {OpenNativeSettings} from "@ionic-native/open-native-settings/ngx";
import {LocationService} from "../../services/native/location.service";
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { DispatcherService } from "../../services/dispatcher.service";
import {fromNullable} from "../../shared/helpers/either-monad";

@Component({
  selector: 'app-location-permission',
  templateUrl: './location-permission.page.html',
  styleUrls: ['./location-permission.page.scss'],
})
export class LocationPermissionPage implements OnInit {

  public state;
  public isIos: boolean;
  public isPwa: boolean;

  //Buttons bodies
  public allowedStepOneBody: any = {};
  public allowedStepTwoBody: any = {};
  public deniedStepOneBody: any  = {};

  constructor(private push: PushNotification,
              private settings: OpenNativeSettings,
              private locationService: LocationService,
              private utils: UtilsService,
              private popoverCtrl: PopoverController,
              private modalCtrl: ModalController,
              private platform: Platform,
              private dispatcherService: DispatcherService) {
    this.isIos = this.platform.is('ios');
    this.isPwa = !this.platform.is('cordova');
  }

  setButtons() {
    this.allowedStepOneBody = {
      text: 'Enable Location',
      icon: 'locate-outline',
      style: 'big'
    };
    this.allowedStepTwoBody = {
      text: 'Open We3 Permissions',
      icon: 'open-outline',
      style: 'big'
    };
    this.deniedStepOneBody = {
      text: `Open We3 ${this.isIos ? 'Settings' : 'Permissions'}`,
      icon: 'open-outline',
      style: 'big'
    };
  }

  async ngOnInit() {
    if(this.isPwa) return;
    
    this.setButtons();

    let status = await this.locationService.getLocationServiceStatus();
    console.log('--- location-permission.page.ts ngOnInit() status: ', status);
    this.state = 'denied';

    /*TODO this section should be refactored, it shouldn't know about the different location statuses from the plugin, this is a leak of responsibility.*/
    switch(status.toLowerCase()) {
      case "granted":
      case 'authorized_when_in_use':
      case 'granted_when_in_use':
        console.log('status is GRANTED');
        break;
      case "denied_once" :
        this.state = this.platform.is('ios') ? 'denied' : 'allowed';
        console.log('status is DENIED ONCE');
        break;
      case "denied_always":
        console.log('status is DENIED_ALWAYS');
        this.state = this.platform.is('ios') ? 'denied' : 'allowed';
        break;
    }
  }

  async close() {
    return this.modalCtrl.dismiss();
  }

  async checkLocationPermissions() {
    return this.locationService.hasLocationPermission();
  }

  async setLocationPreferences() {
    this.utils.showLoading('Locating...');
    await this.locationService.locateWithPermissions()
      .then(() => this.modalCtrl.dismiss())
      .catch((error) => {
        let errorHandler = {
          locationDisabled : () => this.openGenericPopup({
            popupName: 'locationDisabledPopup',
            options: {}
          }),
          permissionDenied : () => {
            this.state = 'denied';
          },
          locationFailed : () => this.openGenericPopup({
            popupName: 'locationRequestFailed',
            options: {}
          })
        };
        if(!Object.keys(errorHandler).includes(error)) {
          this.openGenericPopup({
            popupName: 'locationRequestFailed',
            options: {}
          })
        }
      });
    this.utils.doneLoading();
  }

  async openGenericPopup( data ) {
    this.dispatcherService.newPopupSource.next( data )
  }

  async openSettings() {
    return this.settings.open('application_details');
  }

  // changeState(): void {
  //   this.state = this.state === 'allowed' ? 'denied' : 'allowed';
  // }

  statusIs(status: string): boolean{
    return this.state === status;
  }


}
