import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ChatStatusBarComponent } from "./chat-status-bar";

@NgModule({
  declarations: [
    ChatStatusBarComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    ChatStatusBarComponent
  ]
})

export class ChatStatusBarModule {}
