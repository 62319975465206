import { Injectable } from '@angular/core';
import { ApiService } from './data/api.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Config } from './config/config';
import { DispatcherService } from './dispatcher.service';

interface IInstaInfo {
  userId: string;
  redirectUri: string;
}

@Injectable({
  providedIn: 'root'
})

export class InstagramService {
  onAccessTokenAvailable = new Subject();
  constructor(private api: ApiService,
              private dispatcherService: DispatcherService,
              public http: HttpClient,
              private config: Config) { }

  async initInstagramLogin(){
    let data: any = await this.api.get("instagram_login", ApiService.formatParams({ platform: this.config.platformName }));

    let instaLoginPath = `https://api.instagram.com/oauth/authorize?client_id=${data.clientId}&redirect_uri=${data.redirectUri}&scope=user_profile,user_media&response_type=code`;
    location.href = instaLoginPath;
  }

  async getAccessToken(code) {
    const data = await this.api.post("instagram_login", ApiService.formatParams({ code: code, platform: this.config.platformName }));
    console.log('--- instagram.service.ts getAccessToken(code) data: ', data);
    console.log('--- code: ', code);
    this.dispatcherService.connectingInstagramSource.next(true);
    this.onAccessTokenAvailable.next(data);
    return data;
  }

  async getRecentMedia(userId, access_token) {
    let photosLimit: number = 18;
    let photoFields: string[] = ["id", "caption", "media_type", "media_url", "username", "timestamp"];
    let url = `https://graph.instagram.com/${userId}/media?limit=${photosLimit}&fields=${photoFields.join(',')}&access_token=${access_token}`;
    const media: any =
      await this.http
        .get(url)
        .toPromise();
    //TODO Refactor to observable async
    return media
      .data
      .filter(m => m.media_type === "IMAGE");
  }

  disconnectInstagram(){
    return this.api.delete("instagram_login",{})
  }
}
