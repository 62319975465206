import {Component, Input } from "@angular/core";
import {Config} from "../../services/config/config";
import {Observable} from "rxjs";
import {UserNotificationService} from "../../services/user-notification.service";

@Component({
  selector: "searching-tribe-avatar",
  template: `
    <div class = "tribe-avatar searching">
      <div class = "image">
        <div class = "initiator">
          <ng-lottie class = "search-ongoing-animation"
                     [options] = "lottieSearchOngoing"
                     width = "74px"
                     height = "74px"
                     (animationCreated) = "handleAnimation($event)">
          </ng-lottie>
          <img class = "initiator-image" [src] = "userPicture" alt = "Initiator">
        </div>
        <div class = "u2">
          <img [src] = "defaultPicture" alt = "U2">
        </div>
        <div class = "u3">
          <img [src] = "defaultPicture" alt = "U3">
        </div>
      </div>
      <div class = "info-section">
        <div class = "status-bullet status-{{ (userNotifiable$ | async) ? 'open' : 'available' }}"></div>
        <div class = "title capitalize" [innerHTML] = "tribe.label"></div>
      </div>
    </div>
  `,
  styleUrls: [
    "./../tribe-avatar/tribe-avatar.component.scss",
    "./../tribe/tribe.component.scss",
    "searching-tribe-avatar.component.scss"
  ]
})
export class SearchingTribeAvatarComponent {
  @Input("tribe") tribe: any;

  public defaultPicture: string = '';
  public userPicture: string = "";
  public userNotifiable$: Observable<any>;

  public lottieSearchOngoing: any = {
    path: "./assets/gif/search_ongoing.json",
    renderer: 'canvas',
    autoplay: true,
    loop: true
  }

  constructor(private config: Config,
              private userNotificationService: UserNotificationService) {}

  ngOnInit() {
    this.defaultPicture = this.config.getDefaultAvatar();
    this.userNotifiable$ = this.userNotificationService.getNotifiableObservable();
    this.userPicture = this.config.get('picture') || this.defaultPicture;
  }

  handleAnimation(animation) {
    animation.setSpeed(0.5);
    animation.play();
  }
}
