import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector
} from "@angular/core";
import {PopoverController} from "@ionic/angular";

interface BuilderCoords {
  top: string;
  left: string;
}

@Injectable({
  providedIn: "root"
})
export class BuilderService {

  constructor(private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private popoverCtrl: PopoverController,
              private injector: Injector) {

  }

  async createAt({ component, options = {}, coords, anchor = document.body}) {
    const componentRef = await this.create(component, options, anchor);
    const nativeEl = componentRef.location.nativeElement;

    if(!coords.top) { coords.top = '10px' } 
    if(!coords.left) { coords.left = '10px' } 

    nativeEl.style.position = "absolute";
    nativeEl.style['z-index'] = 10000;
    nativeEl.style.top = coords.top;
    nativeEl.style.left = coords.left;
    return componentRef;
  }

  attachTo({ tagName }) {

  }

  create(component, options, anchor = document.body): Promise<ComponentRef<any>> {
    return new Promise((resolve, reject) => {
      const componentRef: ComponentRef<any> = this.resolver.resolveComponentFactory(component).create(this.injector);

      const outputs = options?.outputs || {};
      const inputs = options?.inputs || {};
      Object
        .entries(outputs)
        .forEach((output: any) => {
          componentRef
            .instance[output[0]]
            .subscribe((value) => output[1](value))
        });
      Object
        .entries(inputs)
        .forEach((input: any) => componentRef.instance[input[0]] = input[1]);

      componentRef.instance.destroySelf = () => this.clear(componentRef);

      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      anchor.appendChild(domElem);
      resolve(componentRef);
    })
  }

  clear(componentRef): void {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
