import { Component } from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import { UserService } from "../../services/data/user.service";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { UtilsService } from "../../services/utils.service";
import {Config} from '../../services/config/config';
import {DispatcherService} from "../../services/dispatcher.service";

@Component({
  selector: 'review-request-page',
  templateUrl: 'review-request.page.html',
  styleUrls: ['review-request.page.scss']
})
export class ReviewRequestPage {

  public lottieStars: Object;
  public userName;
  public anim;

  constructor(public navCtrl: NavController,
              public userService: UserService,
              public analyticsService: AnalyticsService,
              public utils: UtilsService,
              public config: Config,
              private dispatcherService: DispatcherService,
              private modalCtrl: ModalController
              ) {
    this.setAnimations();
  }

  ngOnInit() {
    this.config.setFlag('personal_appeal_viewed', true);
    this.analyticsService.trackEvent({ key: 'personal_appeal_viewed', value: 1 });
    this.userName = this.config.getProfile().firstName;
  }

  setAnimations() {
    this.lottieStars = {
      path: 'assets/gif/rate-5-stars.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
  }

  handleAnimation(anim) {
    this.anim = anim;
  }

  ngOnDestroy() {
  }

  rateApp() {
    this.config.setFlag('rated_app', true);
    this.analyticsService.trackEvent({ 
      key: 'rated_app', 
      value: 1, 
      source: 'personal_appeal'
    });
    this.close();
    this.utils.goToAppStore();
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
