/// <reference types="@types/googlemaps" />
import { Component, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { MapsAPILoader } from "@agm/core";
import { Config } from '../../services/config/config';
import { SessionService } from '../../services/data/session.service';
import { UserService } from '../../services/data/user.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoServiceService } from '../../services/helpers/geo-service.service';
import {DispatcherService} from "../../services/dispatcher.service";
import {NEAR_ME_PROXIMITY} from "../../shared/constants/constants";
import { MatchPreferencesLocation } from 'src/app/shared/enums/match-preferences.enum';

@Component({
  selector: 'page-manual-location',
  templateUrl: 'manual-location.page.html',
  styleUrls: ['manual-location.page.scss']
})
export class ManualLocationPage {

  public subHidden: boolean = true;
  public matchingLatitude: number;
  public matchingLongitude: number;
  public addressString: string = '';
  public toast;
  public isPlus;
  private pageState;
  private formattedAddress;

  public city: string = '';
  public placeData: google.maps.places.PlaceResult;

  @ViewChild("geoSubst", { static: true })  public geoSub: ElementRef;
  @ViewChild("searchBox", { static: true }) public searchElementRef: ElementRef;

  constructor(
    private navCtrl: NavController,
    private mapsAPIloader: MapsAPILoader,
    private ngZone: NgZone,
    private config: Config,
    private session: SessionService,
    private elementRef: ElementRef,
    private userService: UserService,
    public analyticsService: AnalyticsService,
    private toastCtrl: ToastController,
    private router: Router,
    private route: ActivatedRoute,
    private dispatcherService: DispatcherService,
    private geoService: GeoServiceService) {

    this.route.queryParams.subscribe( params => {
      this.pageState = this.router.getCurrentNavigation().extras.state || {};
    });

  }

  ngOnInit() {
    console.log(this.mapsAPIloader);
    this.isPlus = this.config.isPlus();
    this.geoSub.nativeElement.addEventListener('click',(event) => {
      this.geoSub.nativeElement.innerHTML = '';
      this.subHidden = true;
      this.addressString = '';
    });

    this.mapsAPIloader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, { types: ["(cities)"] });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.placeData = autocomplete.getPlace();
          this.searchElementRef.nativeElement.value = '';
          this.searchElementRef.nativeElement.placeholder = '';

          this.putAddressIntoInput(this.addressString);

          if (this.placeData.geometry === undefined || this.placeData.geometry === null) {
            return;
          } else {
            this.matchingLatitude  = this.placeData.geometry.location.lat();
            this.matchingLongitude = this.placeData.geometry.location.lng();
          }

          this.createAddressString(this.placeData);
        });
      });
    });

  }

  createAddressString(placeData) {
    let comps = placeData.address_components;
    let cityInfo = comps.find(c => c.types.includes('locality'));
    let city = cityInfo ? cityInfo.long_name : '';
    let regionInfo = comps.find(c => c.types.includes('administrative_area_level_1'));
    let region = regionInfo ? regionInfo.short_name : '';
    let countryInfo = comps.find(c => c.types.includes('country'));
    let country = countryInfo ? countryInfo.long_name : '';
    let address_data = {
      city: city,
      region: region,
      country: country
    };
    this.city = address_data.city;

    this.formattedAddress = Object.values(address_data).filter(v => v.length).join(', ');
    this.geoService.setCurrentLocation(this.formattedAddress);

    this.addressString = address_data.city + ", <span class='geo-sub-container'>" + address_data.country + "</span>";
    this.setLocation();
  }

  putAddressIntoInput(concat) {
    this.subHidden = false;
  }

  async refreshMatchPreferences() {
    let matchPreferences = this.pageState.matchPreferences;
    matchPreferences.coords = {
      latitude: this.config.getProfile().latitude,
      longitude: this.config.getProfile().longitude
    };
    matchPreferences.location = MatchPreferencesLocation.NearMe;
    matchPreferences.remote_city_name = null;
    await this.userService.updateProfile({ matchPreferences: matchPreferences });
  }

  async setLocation() {
    let matchPreferences = this.pageState.matchPreferences;

    // TODO Coords saved for <25km cities even though it should not
    matchPreferences.coords = {
      latitude: this.matchingLatitude,
      longitude: this.matchingLongitude
    };
    matchPreferences.location = MatchPreferencesLocation.PickPlace;
    matchPreferences.remote_city_name = this.city;
    await this.userService.updateProfile({ matchPreferences });

    this.navCtrl.navigateBack('tabs/tribes').then(() => {
      const placeCoords = {
        latitude: this.placeData.geometry.location.lat(),
        longitude: this.placeData.geometry.location.lng()
      }
      if(this.geoService.getDistanceTo(placeCoords) < NEAR_ME_PROXIMITY) {
        this.refreshMatchPreferences();
        this.dispatcherService.newPopupSource.next({
          popupName: "matchingNearMePopup",
          options: { city: this.placeData.name }
        })
      }
    });
  }

  hideDropdown() {
    let dropdown = document.getElementsByClassName('pac-container')[0];
    if(dropdown) {
      dropdown.remove();
    }
  }

  async navBack() {
    await this.hideDropdown();
    return this.navCtrl.navigateBack('preferences');
  }

}
