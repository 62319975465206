/// <reference types="@types/googlemaps" />
import {
  AlertController,
  IonRouterOutlet,
  MenuController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
} from "@ionic/angular";
import { Component, NgZone, Renderer2, ViewChild } from "@angular/core";
import { Config } from "../../services/config/config";
import { ApiService } from "../../services/data/api.service";
import { AppService } from "../../services/app";
import { TribesService } from "../../services/data/tribes.service";
import { PageWithStatus } from "../../components/page-status/page-with-status";
import { CountdownTimerService } from "../../services/helpers/countdown-timer.service";
import { takeUntil } from "rxjs/operators";
import { UtilsService } from "../../services/utils.service";
import { CustomActionSheetComponent } from "../../components/custom-action-sheet/custom-action-sheet.component";
import { NavigationExtras, Router } from "@angular/router";
import { UserService } from "../../services/data/user.service";
import {
  ScheduledStates,
  SearchService,
} from "../../services/data/search.service";
import { TribeService } from "src/app/services/data/tribe.service";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { DispatcherService } from "../../services/dispatcher.service";
import { SessionService } from "../../services/data/session.service";
import { RouteTrackerService } from "../../services/route-tracker.service";
import { PopupService } from "../../services/popups/popup.service";
import { CachedPicturesService } from "../../services/data/cached-pictures.service";
import { Device } from "@ionic-native/device/ngx";
import { NotificationBadgeComponent } from "../../components/notification-badge/notification-badge.component";
import {
  FeedbackService,
  testFeedbackData,
} from "../../services/popups/feedback.service";
import { MatchMePopoverComponent } from "../../components/match-me-popover/match-me-popover.component";
import { merge, Observable, Subscription } from "rxjs";
import { AlertService } from "../../services/popups/alert.service";
import { initiatorFirst } from "../../shared/helpers/sorting-methods";
import { Executed } from "../../shared/decorators/executed";
import { PusherService } from "../../services/pusher.service";
import { NotificationBadgeContent } from "../../shared/types";
import { PROFILE_PROGRESS_MAXIMUM } from "../../shared/constants/constants";
import { DevicesService } from "../../services/helpers/devices.service";
import { DailyTribesService } from "../../services/daily-tribes.service";
import { ResetLevelsCampaign } from "../../shared/enums/reset-levels-campaign.enum";
import { logc } from "../../shared/helpers/log";
import { SMState } from "../../shared/interfaces";
import { Tribes } from "../../shared/enums/tribes.enum";
import { environment } from "../../../environments/environment";
import { UserNotificationService } from "../../services/user-notification.service";
import { SentryService } from "../../services/performance/sentry.service";
import { AudiencesService } from "../../services/analytics/audiences.service";
import * as _ from "lodash";
import * as moment from "moment";
import { ManualLocationPage } from "../manual-location/manual-location.page";
import { WordingService } from "../../services/wording.service";
import { BuilderService } from "../../services/helpers/builder.service";
import { InstructionsComponent } from "../../components/instructions/instructions.component";
import { MatchingProcessPage } from "../matching-process/matching-process.page";
import { isEmpty } from "../../shared/helpers/lodash";
import { getRandomImage } from "../../shared/helpers/helpers";
import { matchesMock } from "src/app/shared/mocks/matches";
import { MatchesModalPage } from "../matches-modal/matches-modal.page";
import { OPPOSITE_DESCRIPTORS } from "src/app/shared/mocks/highlights";
import { CheckmarkButtonComponent } from "src/app/components/checkmark-button/checkmark-button.component";
import { TestPage } from "../test/test.page";
import { Calendar } from "@awesome-cordova-plugins/calendar/ngx";
import { FanFailedSearchComponent } from "src/app/components/fan-failed-search/fan-failed-search.component";
import { MatchPreferencesLocation } from "src/app/shared/enums/match-preferences.enum";
import { ModalService } from "src/app/services/popups/modal.service";
import { UpsellingPoppingReason } from "../upselling/upselling.page";
import { PushNotification } from "src/app/services/push-notification/push-notification";
import { timeStamp } from "console";

@Component({
  selector: "tribes-page",
  templateUrl: "tribes.page.html",
  styleUrls: ["tribes.page.scss"],
})
export class TribesPage extends PageWithStatus {
  @ViewChild("startButton") startButton;
  @ViewChild(MatchMePopoverComponent) matchMePopover;

  public followUpItems = [
    {
      text: "If you were a participant in Milgram’s experiment, would you have delivered the shocks?",
    },
    {
      text: "Do you think Milgram’s participants who delivered shocks would have answered in the same way as you?",
    },
    {
      text: "What distinguishes people who obey orders that go against their conscience from those who don’t? ",
    },
    {
      text: "As a society, where are we too deferential to authority? Where are we too mistrusting? ",
    },
    {
      text: "If this suggests that “good people” can harm others under certain circumstances, should we change how we think about justice from a moral standpoint? How about from a legal perspective?",
    },
  ];

  public horizontalSlides = [
    {
      header: {
        startImage: "./assets/img/star.png",
        title: "Phantom pregnancy",
        icon: "thumbs-up",
      },
      text: "How much could your thoughts alter your body?",
      votes: [{ user_id: 4242, picture: getRandomImage() }],
    },
    {
      header: {
        startImage: "./assets/img/star.png",
        title: "Phantom pregnancy",
        icon: "thumbs-up",
      },
      text: "How much could your thoughts alter your body?",
      votes: [
        { user_id: 4242, picture: getRandomImage() },
        { user_id: 4242, picture: getRandomImage() },
      ],
    },
    {
      header: {
        startImage: "./assets/img/star.png",
        title: "Phantom pregnancy",
        icon: "thumbs-up",
      },
      text: "How much could your thoughts alter your body?",
    },
  ];

  public testLottie: any = {
    path: "./assets/gif/scheduled-success.json",
    autoplay: true,
    loop: false,
  };
  public tribes: Array<any> = [];
  public chats: Array<any> = [];
  public queue: Array<any> = [];
  public defaultAvatar: string = "";
  private canRunMissingSteps: boolean = true;

  private hardwareBackButtonSubscription: Subscription;

  public matchesImage: string = "./assets/img/pixel-img.png";
  public readyForMatches: boolean = false;
  public boostStrengthNotificationContent: NotificationBadgeContent = {
    image: "./assets/img/fast-strength.png",
    body: {
      title: `Start another group today <span class = "green-highlight heavy small-text">FREE</span>`,
      desc: `Get your Profile Strength to 100% to match again today.
             <span class = "primary-color underlined bold">
                Increase my Profile Strength
             </span>`,
    },
    callback: () =>
      this.navigate("tabs/profile", { state: { openProgress: true } }),
  };

  public showProfileStrengthBoost: boolean = false;
  public userStatus;
  public dailyTribesLimitReached: boolean = false;
  public lottieSearchOngoing: Object;
  public lottieAnimation: any;
  public isDev: boolean = environment.name === "development";
  public searches = [];
  public whitelistPageShown: boolean;
  public matchMePopoverShown: boolean = false;
  public profile: any = {};
  private initiatorFirst = initiatorFirst;
  public decliningTribe: boolean = false;

  public tutorialButtonBody = {
    icon: "help-circle-outline",
    text: "How it works",
    style: "medium",
  };

  public inProgressTribe: any = {};
  public matchPreferences: any = {};
  public guideInProgress: boolean;
  public isPlus: boolean = false;
  public isLatestVersion: boolean = true;
  public processing: boolean = false;
  public processingMatchingInfo: boolean = false;

  public cachedUsers = [];
  public pendingTribeAlert: any;
  public matchesNumber: number = 10;
  public userNotifiable$: Observable<any>;
  public audience: string = "";
  public matchMeButtonText: string = "";
  public inScheduledGroup: boolean = false;

  public wording: any = {};

  public potentialAvatars: any[] = [
    { picture: getRandomImage() || this.defaultAvatar },
    { picture: getRandomImage() || this.defaultAvatar },
    { picture: getRandomImage() || this.defaultAvatar },
  ];

  constructor(
    public navCtrl: NavController,
    public config: Config,
    public api: ApiService,
    public appService: AppService,
    public tribesService: TribesService,
    public tribeService: TribeService,
    public searchService: SearchService,
    public alertCtrl: AlertController,
    private alertService: AlertService,
    public analyticsService: AnalyticsService,
    public timerService: CountdownTimerService,
    public modalCtrl: ModalController,
    public utils: UtilsService,
    public renderer: Renderer2,
    public routeTrackerService: RouteTrackerService,
    public router: Router,
    public ngZone: NgZone,
    private pushNotification: PushNotification,
    public cachedPicturesService: CachedPicturesService,
    public userService: UserService,
    private iro: IonRouterOutlet,
    public platform: Platform,
    public popupService: PopupService,
    public dispatcherService: DispatcherService,
    public popoverCtrl: PopoverController,
    public sessionService: SessionService,
    public device: Device,
    private feedbackService: FeedbackService,
    public errorTrackingService: SentryService,
    private builderService: BuilderService,
    private menu: MenuController,
    public pusherService: PusherService,
    public devicesService: DevicesService,
    public dailyTribesService: DailyTribesService,
    public userNotificationService: UserNotificationService,
    private audienceService: AudiencesService,
    private wordingService: WordingService,
    private calendar: Calendar,
    private modalService: ModalService
  ) {
    super(api, appService, routeTrackerService, errorTrackingService, utils);
    this.debugId = "tribes";
    (window as any).tribesPage = this;
    (window as any).calendar = this;
  }

  async openTestingMatchesModal(matches) {
    const modal = await this.modalCtrl.create({
      component: MatchesModalPage,
      componentProps: {
        matches,
        matchesLength: 400,
        oppositeDescriptors: OPPOSITE_DESCRIPTORS,
      },
      cssClass: "bottom-sheet-modal full-height-modal",
    });
    modal.present();
  }

  async init(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.config.load();
      if (this.config.isPWA) {
        this.config.callOnceLocally("tribesPageVisitedBefore", () => {
          this.popupService.createPopup({ popupName: "downloadAppPopup" });
        });
      }

      if (
        !this.config.isPlus() &&
        !this.config.getFlag("premium_subscription_proposed") &&
        this.config.getCurrentLevel() >= 3 &&
        this.config.finishedLevel(this.config.getCurrentLevel()) &&
        this.routeTrackerService.getPreviousPage().match("levels")
      ) {
        this.modalService.openUpselling({
          source: "go_to_tribes_from_levels"
        });
        this.config.setFlag("premium_subscription_proposed", true);
      }

      // setTimeout(() => this.openTestingMatchesModal(matchesMock), 1000);

      this.defaultAvatar = this.config.getDefaultAvatar();
      this.isPlus = this.config.isPlus();
      this.isLatestVersion = this.sessionService.isAppLatestVersion();
      this.profile = this.config.getProfile();
      this.audience = this.audienceService.getAudience();
      this.inScheduledGroup = this.audienceService.inScheduledGroup();

      this.matchPreferences = this.config.get("matchPreferences");
      this.userNotifiable$ =
        this.userNotificationService.getNotifiableObservable();
      this.readyForMatches = this.userService.readyForMatches();
      this.stateMachine.onStateChanged.subscribe(
        (state: SMState) => (this.config.SMAggregator.tribes = state)
      );

      this.menu.enable(true, "first");
      this.iro.swipeGesture = true;

      this.setDevData();
      this.setProfileStrengthBoostStatus();
      this.setMatchMeButtonText();
      this.setAnimations();
      this.checkGuide();

      this.wordingService.onWordingReady
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((wording) => {
          this.wording = wording;
        });

      this.tribesService.onScheduledSearchAdded
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((search) => {
          this.appendSearchingTribes();
        });

      merge(
        this.dispatcherService.matchesRecomputedSource,
        this.config.onProfileChanged
      )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.readyForMatches = this.userService.readyForMatches();
        });

      merge(
        this.tribesService.onScheduledSearchFormed,
        this.tribesService.onScheduledSearchRemoved
      )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.showMatchMePopover();
          this.setMatchMeButtonText();
        });

      merge(
        this.config.onProfileChanged,
        this.config.onFlagsChanged,
        this.tribesService.onBlacklistEmptied,
        this.tribesService.onTribeAdded,
        this.tribesService.onTribeRemoved
      )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.showMatchMePopover();
        });

      merge(
        this.dispatcherService.onProfileUpdating,
        this.dispatcherService.onDeviceInfoTransmitting
      )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data: any) => {
          this.ngZone.run(() => {
            switch (data.status) {
              case "started":
                this.processingMatchingInfo = true;
                break;
              case "completed":
                this.processingMatchingInfo = false;
                break;
            }
          });
        });

      this.searchService.onSearchBlocked
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((processing: boolean) => {
          this.processing = processing;
        });

      this.dispatcherService.onChatMessagesCounterUpdate
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((event: { id: number; key: any }) => {
          let chat = this.chats.find((chat) => chat.id === event.id);
          if (!!chat) {
            chat.unread_messages_count = 0;
          }
        });

      this.tribesService.onNewMessage
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((tribe) => {
          this.ngZone.run(() => {
            console.log("--- onNewMessage: ", tribe?.last_message);
            if (
              !!this.queue.find((t) => t.id === tribe.id) &&
              tribe.last_message.user_id > 0
            ) {
              this.chats.push(this.formatTribe(tribe));
              this.removeTribeFrom(this.queue, tribe.id);
            }

            let index = this.chats?.findIndex((c) => c.id === tribe.id);
            if (this.chats[index]) {
              this.chats[index].last_message = tribe.last_message;
              this.chats[index].last_event_at = tribe.last_event_at;
              this.chats[index] = this.formatTribe(this.chats[index]);
              if (
                tribe.last_message.user_id !== this.config.get("id") &&
                this.isOnPage()
              ) {
                this.incrementMessageCounter(this.chats[index]);
              }
              this.sortChatsByRecentMessage();
            }
          });
        });

      this.sessionService.onReady
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(async (_) => {
          this.isLatestVersion = this.sessionService.isAppLatestVersion();
          this.handleMissingSteps();
          if (this.config.isPWA) {
            await this.pushNotification.requestWebPushPermission();
          }
        });

      this.dispatcherService.onPlusSubscriptionChanged
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((status) => {
          this.setProfileStrengthBoostStatus();
          this.isPlus = status;
        });

      this.config.onProfileChanged
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((profile) => {
          this.setProfileStrengthBoostStatus();
          if (this.profile.picture !== profile.picture) {
            this.updateUserInLists(profile);
          }
          this.matchPreferences = profile.matchPreferences;
          this.setMatchMeButtonText();
        });

      this.config.onFlagsChanged
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.setProfileStrengthBoostStatus();
          this.dailyTribesLimitReached =
            this.dailyTribesService.reachedDailyTribesLimit();
        });

      this.searchService.onAddMatchingDummy
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dummy) => this.addTribeToQueue(dummy));

      this.searchService.onRemoveMatchingDummy
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.removeTribeFromQueue(Tribes.MatchingDummyId);
        });

      this.searchService.onRemoveFansDummy
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.removeFansDummy();
        });

      this.tribesService.onScheduledSearchRemoved
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((id) => {
          logc.info("Search to remove from queue: ", id);
          this.queue = this.queue.filter((tribe) => tribe?.scheduledId != id);
        });

      this.tribesService.onScheduledSearchFormed
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((search) => {
          const scheduledSearch = this.searchService.getFansDummy({
            scheduledId: search.id,
          });
          this.queue.unshift(scheduledSearch);
        });

      this.searchService.onSearchingDummy
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          if (data.action === "add") this.addSearchingDummy(data);
          if (data.action === "remove") this.removeSearchingDummy(data);
        });

      this.tribesService.onFoundOne
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((event) => {
          if (!event) return;
          this.ngZone.run(() => {
            this.appendSearchingTribes();
          });
        });

      this.tribesService.onTribeAdded
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((tribe) => {
          this.ngZone.run(() => {
            this.tribesService.refresh(null);

            if (tribe?.type && tribe?.type == "DirectMessage") {
              this.addTribeToChats(tribe);
            } else {
              this.addTribeToQueue(tribe);
            }

            this.sortQueue();
          });
        });

      this.tribesService.onTribeFailed
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((tribe) => {
          this.ngZone.run(async () => {
            await this.removeTribe(tribe.id);
          });
        });

      this.tribesService.onTribeRemoved
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(async (tribeId) => {
          await this.removeTribe(tribeId);
        });

      this.tribesService.onTribeChanged
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((tribe) => {
          this.ngZone.run(() => {
            if (tribe.tribe_user?.status === "removed") {
              this.removeTribe(tribe.id);
              this.tribesService.save();
            }

            if (tribe.type === "DirectMessage") {
              this.addTribeToChats(tribe);
            }

            if (
              tribe.status === "inviting" &&
              !this.queue.map((t) => t.id).includes(tribe.id)
            ) {
              this.addTribeToQueue(tribe);
            }
            let i = this.getTribeIndex(this.chats, tribe);
            if (i > -1) {
              this.updateTribeAt(this.chats, tribe, i);
              this.sortChatsByRecentMessage();
            }

            i = this.getTribeIndex(this.queue, tribe);
            if (i > -1) {
              this.updateTribeAt(this.queue, tribe, i);
              this.sortQueue();
            }
          });
        });

      await this.onData(await this.tribesService.loadFromCache());
      resolve();
    });
  }

  ionViewWillEnter() {
    this.hardwareBackButtonSubscription = this.platform.backButton.subscribe(
      () => {
        this.utils.onDoubleTapBackExit();
      }
    );
  }

  ionViewWillLeave() {
    this.hardwareBackButtonSubscription.unsubscribe();
  }

  setDevData() {
    if (!this.isDev || !this.config.isStaging) return;

    (<any>window).tribesPage = this;
    this.tribesService.onTribeLeft
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async ({ tribe_id }) => {
        await this.utils.showGenericMessage(`Tribe ${tribe_id} removed!`, 1500);
      });
  }

  setProfileStrengthBoostStatus(): void {
    this.showProfileStrengthBoost =
      !this.config.isPlus() &&
      this.config.getFlag("dailyLimitPopoverTapped") &&
      this.config.getFlag("profileProgress") != PROFILE_PROGRESS_MAXIMUM &&
      !this.config.getFlag("profileFilledOnce") &&
      !this.config.getFlag("boostProfileBadgeTapped") &&
      !this.dailyTribesService.canStartGroup() &&
      !this.audienceService.inScheduledGroup();
  }

  loadData(): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("LOADED DATA!!!");
      this.tribesService.load().then(
        //retrieves tribes
        (tribes) => {
          resolve(tribes);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async openMatchingProcessModal() {
    const modal = await this.modalCtrl.create({
      component: MatchingProcessPage,
      componentProps: {
        callback: () => console.log("Boom shakalakalaka"),
      },
    });
    modal.present();
  }

  openInstructions(stateName) {
    this.builderService.create(InstructionsComponent, {
      inputs: { stateName },
    });
  }

  async onDidLoadData(tribes) {
    await this.cachedPicturesService.load();
    this.cachedUsers = this.cachedPicturesService.getTribes();
    await this.onData(tribes);
    this.showMatchMePopover();
  }

  updateUserInLists(profile: any): void {
    const updateCurrentUserAvatar = (tribe) => {
      if (tribe.tribe_user) {
        tribe.tribe_user.picture = profile.picture;
      }
    };
    this.queue.forEach(updateCurrentUserAvatar);
    this.chats.forEach(updateCurrentUserAvatar);
  }

  openModal() {
    this.modalCtrl
      .create({
        component: FanFailedSearchComponent,
        componentProps: {
          fansNumber: "42",
        },
      })
      .then((modal) => modal.present());
  }

  isOnPage(): boolean {
    return !!this.router.url.match("tabs/tribes");
  }

  setTestValues() {
    this.config.setFlag("notify_me_clicked", false);
    this.config.setFlag("genderPreferenceChangeAsked", false);
  }

  addSearchingDummy({ preferences }) {
    if (
      this.queue.find((tribe) => tribe?.location == preferences.location) ||
      this.queue.some((tribe) => tribe.id == -1)
    )
      return;

    let searchingDummy = this.searchService.getSearchingDummy({
      preferences: preferences,
      currentUserAvatar: this.config.get("picture"),
    });

    this.queue.unshift(searchingDummy);
  }

  removeSearchingDummy({ preferences }) {
    const dummyIndex = this.queue.findIndex(
      (tribe) => tribe?.location === preferences.location
    );
    if (dummyIndex > -1) {
      this.queue.splice(dummyIndex, 1);
    }
  }

  removeTribe(tribeId): Promise<void> {
    return new Promise((resolve, reject) => {
      this.removeTribeFrom(this.chats, tribeId);
      this.removeTribeFrom(this.queue, tribeId);
      if (!this.queue.some((t) => t?.id > 0)) {
        this.queue = this.queue.concat([{}, {}, {}, {}]);
      }
      resolve();
    });
  }

  getTribeIndex(list, tribe) {
    return list && list?.findIndex((t) => t.id == tribe.id);
  }

  addTribeToChats(tribe) {
    // console.log('--- tribes.page.ts -> addTribeToChats(tribe)... ', tribe.id, tribe.status);
    let chat = this.chats.find((t) => t.id === tribe.id);

    if (chat) {
      if (
        chat.last_event_at < tribe.last_event_at ||
        chat.unread_messages_count < tribe.unread_messages_count
      ) {
        let replaceIndex = this.chats?.findIndex(
          (chat) => chat.id === tribe.id
        );
        this.chats.splice(replaceIndex, 1, this.formatTribe(tribe));
        this.sortChatsByRecentMessage();
      }
    } else {
      this.chats.push(this.formatTribe(tribe));
    }
  }

  log(event) {
    logc.info("Log: ", event);
  }

  addTribeToQueue(tribe) {
    // console.log('--- tribes.page.ts -> addTribeToQueue(tribe)... ', tribe.id, tribe.status);
    const tribeIndex = this.queue.findIndex((t) => t.id === tribe.id);
    if (tribeIndex > -1) {
      return this.updateTribeAt(this.queue, tribe, tribeIndex);
    }

    if (!this.queue.some((t) => t.id > 0)) {
      this.queue = this.queue.filter((t) => t.id);
    }
    this.queue.push(this.formatTribe(tribe));
  }

  addTribeTo(list, tribe) {
    if (this.tribesService.getListForTribe(tribe) === "chats") {
      tribe = this.formatTribe(tribe);
    }
    list.push(tribe);
  }

  updateTribeAt(list, tribe, index) {
    // console.log("--- tribes.page.ts updateTribeAt() tribe: ", tribe.id );
    list[index] = { ...list[index], ...this.formatTribe(tribe) };
    // list[index] = this.formatTribe( tribe );
  }

  removeTribeFromQueue(tribeId) {
    let i = this.queue.findIndex((t) => t?.id == tribeId);
    if (i > -1) {
      this.queue.splice(i, 1);
    }

    if (!this.queue || this.queue.length == 0) {
      this.refreshQueue();
    }
  }

  removeFansDummy() {
    const index = this.queue.findIndex((t) => t?.type == "fans");
    if (index > -1) this.queue.splice(index, 1);
    if (!this.queue || this.queue.length == 0) {
      this.refreshQueue();
    }
  }

  removeTribeFrom(list, tribeId) {
    let i = list && list?.findIndex((t) => t?.id == tribeId);
    if (i > -1) {
      list.splice(i, 1);
    }
  }

  iosPwaExperience() {
    location.href = this.utils.getBranchLink();
  }

  showIosUserStatus() {
    this.utils.userReadyButHasNoPushes();
  }

  showMatchMePopover() {
    const reason = this.searchService.getBlockReason();
    this.searchService.outputReadyToMatchDetails();

    logc.orange("block reason: ", reason);

    if (!reason) {
      return this.matchMePopover.close();
    }

    this.dispatcherService.matchMePopoverSource.next({
      name: reason,
      action: reason ? "open" : "close",
    });
  }

  isPickAPlace() {
    return (
      this.matchPreferences.location === MatchPreferencesLocation.PickPlace
    );
  }

  setAnimations() {
    this.lottieSearchOngoing = {
      path: "./assets/gif/search_ongoing.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    };
  }

  handleAnimation(animation: any) {
    this.lottieAnimation = animation;
    this.lottieAnimation.setSpeed(0.5);
  }

  notEmpty(tribe) {
    return !_.isEmpty(tribe);
  }

  sortTribesIntoLists(tribes) {
    for (let tribe of tribes) {
      let list = this.tribesService.getListForTribe(tribe);
      // console.log(`-- tribes.page.ts sortTribeIntoLists(tribe) ${ tribe.id }: ${ list }`);
      if (list === "queue") {
        this.addTribeToQueue(tribe);
      }
      if (list === "chats") {
        if (this.queue.find((t) => t.id === tribe.id)) {
          this.removeTribeFrom(this.queue, tribe.id);
        }
        this.addTribeToChats(tribe);
      }
    }
  }

  refreshQueue() {
    this.queue = [{}, {}, {}, {}];
  }

  @Executed()
  onData(tribes): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.sortTribesIntoLists(tribes);

      this.inProgressTribe = tribes.find(
        (tribe) => tribe.status == "in_progress"
      );

      if (!this.queue.length) {
        this.queue = [...this.queue, ...[{}, {}, {}, {}]];
      }

      this.sortChatsByRecentMessage();
      this.sortQueue();

      this.appendSearchingTribes();

      resolve();
    });
  }

  removeSearchingTribes() {
    this.queue = this.queue.filter((tribe) => tribe.id != -1);
  }

  async openTestModal() {
    const modal = await this.modalCtrl.create({
      component: TestPage,
      cssClass: "auto-height",
    });

    modal.present();
  }

  appendSearchingTribes() {
    this.removeSearchingTribes();
    this.searches = this.tribesService.ongoingSearches;
    // logc.success("Searches: ", this.searches.length);
    this.searches.forEach((search) => {
      // logc.info("adding search to queue: ", search);
      let searchingDummy: any;
      if (search.status == "fans") {
        if (!this.queue.some((tribe) => tribe?.scheduledId == search.id)) {
          searchingDummy = this.searchService.getFansDummy({
            scheduledId: search.id,
          });
        }
      } else {
        if (
          !this.queue.some(
            (tribe) => tribe?.location == search?.preferences?.location
          )
        ) {
          searchingDummy = this.searchService.getSearchingDummy({
            preferences: search.preferences,
            currentUserAvatar: this.config.get("picture"),
          });
        }
      }

      // logc.orange("Searhing tribe: ", searchingDummy);
      if (searchingDummy) {
        this.queue.unshift(searchingDummy);
      }
    });
  }

  lastMessageHandler(message) {
    if (!message) return;

    if (message.includes("https://s3.amazonaws.com/me3")) {
      return "<i class='ss-icon'>camera</i>" + " Photo";
    } else if (message.includes("https://media")) {
      return "<i class='material-icons' >gif</i>";
    } else {
      return message.split("<br>")[0];
    }
  }

  incrementMessageCounter(chat) {
    chat.unread_messages_count += 1;
  }

  formatTribe(rawTribe) {
    let tribe = _.cloneDeep(rawTribe);
    // console.log('--- tribes.page.ts formatTribe( rawTribe ) tribe: ', tribe);
    tribe.users.uniqPush(tribe.tribe_user);

    if (!tribe.last_message) {
      tribe.last_message = { content: "", created_at: new Date() };
      tribe.last_message.content =
        "No messages yet. Be the first break the ice.";
    } else {
      tribe.last_message.content = this.lastMessageHandler(
        tribe.last_message.content
      );
    }

    tribe.users.forEach((user) => {
      user.picture = this.getImageFor(user.id, tribe?.id, user.picture);
    });
    tribe.users.sort(this.initiatorFirst);
    return tribe;
  }

  sortQueue() {
    this.queue.sort((t1, t2) => t2.id - t1.id);
  }

  sortChats() {
    this.chats.sort(
      (t1, t2) => Date.parse(t2.last_event_at) - Date.parse(t1.last_event_at)
    );
  }

  sortChatsByRecentMessage() {
    this.chats.sort(
      (t1, t2) =>
        Date.parse(t2.last_message.created_at) -
        Date.parse(t1.last_message.created_at)
    );
  }

  goToTribe(tribe: any) {
    tribe.unread_messages_count = 0;
    try {
      this.navCtrl.navigateForward(`tribes/${tribe.id}/chat`);
      // this.navCtrl.navigateForward(`room/${tribe.id}`);
    } catch (err) {
      console.log(err);
    }
  }

  async openQueueTribe(tribe) {
    if (tribe.type == "fans") {
      this.navigate("dummy-chat", { state: { tribe } });
      return;
    }
    if (tribe.type == "matching") {
      await this.utils.showGenericMessage(
        "We're still trying to match you. Hang tight!"
      );
      return;
    }

    if (tribe.type == "searching") {
      let extras = {
        state: {
          preferences: {
            location: tribe.location,
            remote_city_name: tribe.label,
          },
        },
      };
      this.navCtrl.navigateForward("get-notified", extras);
    } else {
      try {
        this.tribesService.openQueueTribe(tribe.id);
      } catch (err) {
        console.log(err);
      }
    }

    if (this.pendingTribeAlert) {
      this.pendingTribeAlert.close();
    }
  }

  openFeedbackModal() {
    this.feedbackService.createFeedbackModal(testFeedbackData);
  }

  checkGuide(): void {
    this.guideInProgress = this.config.get("guideInProgress");
    if (this.guideInProgress === undefined) {
      this.guideInProgress = true;
    }
  }

  async startSearch(): Promise<any> {
    await this.searchService.startSearch();
  }

  isReadyToMatch(): boolean {
    return this.searchService.isReadyToMatch();
  }

  openMatchesFlow() {
    if (this.isPlus && this.dailyTribesService.reachedDailyTribesLimit()) {
      return this.dispatcherService.matchMePopoverSource.next({
        name: "daily_tribes_limit_reached",
        action: "open",
      });
    }
    this.inProgressTribe = this.queue.find(
      (tribe) => tribe.status == "in_progress"
    );
    let navigationExtras: NavigationExtras = {
      state: { inProgressTribe: this.inProgressTribe },
    };
    this.navCtrl.navigateForward("/matches", navigationExtras);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getTribeStatus(status) {
    return status == "in_progress" ? "In progress" : status;
  }

  getImageFor(userId: number, tribeId: number, defaultPicture: string) {
    if (userId == this.config.get("id")) {
      return this.config.getPicture();
    }
    return (
      this.getUserCachedPicture(tribeId, userId) ||
      defaultPicture ||
      this.defaultAvatar
    );
  }

  onImageError(event, tribe, pos) {
    event.target.src = this.defaultAvatar;
    event.onerror = null;
  }

  getUserCachedPicture(tribeId: number, userId: number): string {
    return this.cachedUsers
      .find((t) => t?.id == tribeId)
      ?.users.find((u) => u?.id == userId)?.cachedPicture;
  }

  navigate(page, options = {}) {
    this.navCtrl.navigateForward(page, options);
  }

  async handleMissingSteps() {
    if (!this.canRunMissingSteps) return;

    this.canRunMissingSteps = false;

    if (!!this.config.getFlag("signUpSetup")) {
      this.config.setFlag("signUpSetup", null);
    }

    if (this.showResetLevelsCampaignStatus()) {
      await this.handleResetLevelsCampaign();
    } else if (this.profile.status == "unconfirmed") {
      await this.handleConfirmedEmail(this.config.data.lastEmailConfirmTime);
    }
  }

  showResetLevelsCampaignStatus() {
    const healingCampaignStatus = this.config.getFlag(
      "highlights_fix_campaign_status"
    );
    const showResetLevelsRequestPage = [
      ResetLevelsCampaign.Scheduled,
      ResetLevelsCampaign.Seen,
    ].includes(healingCampaignStatus);

    if (showResetLevelsRequestPage) {
      const currentDate = Date.now();
      const lastAttemptAt = this.config.getFlag(
        "lastResetLevelCampaignReminderAt"
      );
      const daysDiffDates = moment(currentDate).diff(
        moment(lastAttemptAt),
        "days"
      );
      return !lastAttemptAt || daysDiffDates >= 1;
    }
    return false;
  }

  async handleResetLevelsCampaign() {
    await this.config.setFlag("lastResetLevelCampaignReminderAt", Date.now());
    await this.navCtrl.navigateForward("reset-levels-request");
  }

  async handleConfirmedEmail(lastAttemptAt: any) {
    const currentDate = Date.now();
    const daysDiffDates = moment(currentDate).diff(
      moment(lastAttemptAt),
      "days"
    );
    if (!lastAttemptAt || daysDiffDates >= 1) {
      this.config.update("lastEmailConfirmTime", currentDate);
      await this.config.save();

      if (daysDiffDates >= 1) {
        await this.navCtrl.navigateForward(["sign-up-flow"], {
          state: {
            setup: ["email"],
            backPath: "tabs/tribes",
            emailStatus: "confirmation",
          },
        });
      }
    }
  }

  // dev only
  cleanQueue() {
    this.queue
      .filter((tribe) => tribe.id > 0)
      .forEach(async (tribe) => {
        await this.tribeService.delete({ tribeId: tribe.id });
      });

    this.refreshQueue();
  }

  // dev only
  cleanChats() {
    this.chats.forEach((tribe) =>
      this.tribeService.delete({ tribeId: tribe.id })
    );
  }

  setMatchMeButtonText(): void {
    if (this.audienceService.inScheduledGroup()) {
      this.matchMeButtonText = "Match me in a group";
    } else if (
      this.matchPreferences.location == MatchPreferencesLocation.Global
    ) {
      this.matchMeButtonText = "Match me around the world";
    } else {
      this.matchMeButtonText = `Match me ${
        this.matchPreferences?.remote_city_name
          ? "in " + this.matchPreferences.remote_city_name
          : "in a group"
      }`;
    }
  }

  addCalendar() {}
}
