import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Saveable } from "../../shared/structure/saveable";
import { Storage } from "@ionic/storage";
import { Subject } from "rxjs/Subject";
import {
  DEFAULT_AVATAR_PATH,
  DEFAULT_DARK_AVATAR_PATH,
  PASSED_USERS_RESET_DAYS,
  REJECTED_USERS_RESET_DAYS,
} from "../../shared/constants/constants";
import { AGE_DIVIDER } from "../../shared/constants/constants";
import { AnalyticsService } from "../analytics/analytics.service";
import { PictureService } from "../picture.service";
import { environment } from "../../../environments/environment";
import { ColorModeService } from "../native/color-mode.service";
import { SMAggregator } from "../../shared/interfaces";
import { logc } from "../../shared/helpers/log";
//declare var window: any;

import * as _ from "lodash";
import { Observable } from "rxjs";
import { DEFAULT_NOTIFICATION_SETTINGS } from "../../shared/constants/notifications";
import { isDev, isIosPWA, isMobile } from "../../shared/helpers/helpers";
import {
  addDays,
  addHours,
  addSeconds,
  differenceInDays,
  differenceInSeconds,
  endOfDay,
  isAfter,
  parseISO,
} from "date-fns";
import {
  DeclineEducationalPopup,
  RejectEducationalPopup,
} from "../tribe-highlights.service";
import {
  getUTCDateNextDayAt,
  REFRESH_HOUR,
} from "../../shared/helpers/time-helpers";
import { VisionService } from "../vision.service";
import { MatchPreferencesLocation } from "src/app/shared/enums/match-preferences.enum";

@Injectable({
  providedIn: "root",
})
export class Config extends Saveable {
  defaultConfig: any = {
    id: -1,
    availableForTribes: true,
    currentLevel: 0,
    apiKey: null,
    pwaToken: null,
    email: null,
    closest_city: null,
    gender: null,
    firstName: null,
    lastName: null,
    picture: null,
    status: "unconfirmed",
    birthday: null,
    latitude: null,
    longitude: null,
    defaultUnitSystem: "km",
    skipNotLaunchedWarning: false,
    startedCurrentLevel: false,
    offensiveMessageIds: {},
    shared: [false, false, false, false],
    radius: 25,
    partner: null,
    enforceRadius: false,
    matchPreferences: {},
    instagramTestUser: null,
    phoneNumber: null,
    phoneNumberStatus: null, //null, pending, failed, expired, verified
    availableWhileUninstalled: false,
    pictureStatus: "unverified", //unverified, verified, invalid
    pictureGender: null,
    referralCode: null,
    referredBy: null,
    partnerCode: null,
    instagramToken: null,
    geoSkipped: false,
    hasManualLocation: false,
    firstPendingTribeVisit: false,
    legalGuardian: false,
    notificationPreferences: {},
    banned: false,
    smsOptIn: false,
    referrer: {},
    hasPassword: false,
    seenHighlightsTooltip: false,
    flags: {},
    bonusLevelDone: false,
    subscription: null,
    passedUsers: {
      declined: { daily: 0, streak: 0, total: 0, reasons: {} },
      rejected: { daily: 0, streak: 0, total: 0, reasons: {} },
    },
    educationalPopups: {},
  };

  public key = "config";
  public platformName = "";

  public signUpStartPage: string = "tabs/profile";
  public isExternalLinkSignIn: boolean = false;
  public isDev: boolean = false;
  public isStaging: boolean = false;
  public isProd: boolean = false;
  private profile;
  private availabilityChangedSource = new Subject();
  private profileChangedSource = new Subject();
  public flagsChangedSource = new Subject();
  public genderMissrepresentedSource = new Subject();
  public matchPreferencesChangeSource: Subject<any> = new Subject<any>();
  private localDataChangeSource: Subject<any> = new Subject();
  private pictureCachedSource: Subject<any> = new Subject<any>();
  public version = "7.00.03";
  public showedLocationWarning = false;
  public bonusLevelUnlocked = false;
  public safeTop = "0px";

  public partner: any = null;

  public isPWA = false;
  public isIOS: boolean = false;
  public onAvailabilityChanged;
  public onProfileChanged;
  public onFlagsChanged;
  public onGenderMissrepresented;
  public onMatchPreferencesChanged: Observable<any>;
  public onLocalDataChange: Observable<any>;
  public onPicturedCached: Observable<any>;

  public defaultUnitSystem;
  public isAppLatestVersion: boolean = false;

  public SMAggregator: SMAggregator = {
    device: { name: "", queue: [] },
    session: { name: "", queue: [] },
    api: { name: "", queue: [] },
    tribes: { name: "", queue: [] },
  };

  constructor(
    public platform: Platform,
    private colorModeService: ColorModeService,
    public analyticsService: AnalyticsService,
    public pictureService: PictureService,
    public storage: Storage
  ) {
    super(storage);

    this.isDev = ["development", "staging"].includes(environment.name);
    this.isDev = environment.name == "development";
    this.isIOS = this.platform.is("ios");
    this.isStaging = environment.name == "staging";
    this.isProd = environment.name == "production";
    if (this.isDev || this.isStaging) {
      (<any>window).appConfig = this;
      (<any>window).storage = this.storage;
    }

    this.platformName = "web";
    this.isPWA = !this.platform.is("cordova");
    if (this.platform.is("android")) {
      this.platformName = "android";
    }
    if (this.platform.is("ios")) {
      this.platformName = "ios";
    }

    //In dev we don't want the server to think we are in the PWA
    if (this.isPWA) {
      this.platformName = "pwa";
    }

    if (this.isPWA && environment.name === "development") {
      this.platformName = "dev"; //dev is a pwa that behaves like the for the business logic flows.
    }

    this.setPassedUsersInfo({ refresh: false });

    this.onAvailabilityChanged = this.availabilityChangedSource.asObservable();
    this.onProfileChanged = this.profileChangedSource.asObservable();
    this.onFlagsChanged = this.flagsChangedSource.asObservable();
    this.onGenderMissrepresented =
      this.genderMissrepresentedSource.asObservable();
    this.onMatchPreferencesChanged =
      this.matchPreferencesChangeSource.asObservable();
    this.onLocalDataChange = this.localDataChangeSource.asObservable();
    this.onPicturedCached = this.pictureCachedSource.asObservable();
    this.analyticsService.setConfig(this);
  }

  refreshDailyDeclinedUsers(): void {
    let passedUsers = this.get("passedUsers");
    if (!passedUsers?.declined?.daily) return;
    logc.info("* Resetting daily declines *");

    passedUsers.declined.daily = 0;
    this.set("passedUsers", passedUsers);
  }

  refreshDailyRejectedUsers(): void {
    let passedUsers = this.get("passedUsers");
    if (!passedUsers?.rejected?.daily) return;
    logc.info("* Resetting daily rejects *");

    passedUsers.rejected.daily = 0;
    this.set("passedUsers", passedUsers);
  }

  checkDailyStats(): void {
    logc.crimson("-- Checking daily stats -- ");

    const refreshTime = this.get("refreshTime");
    const currentMoment = new Date();
    if (refreshTime) {
      logc.orange("Refresh time is:", refreshTime);
      if (isAfter(currentMoment, parseISO(refreshTime))) {
        this.set("refreshTime", getUTCDateNextDayAt(REFRESH_HOUR));
        logc.info("Next refresh at: ", this.get("refreshTime"));
        this.refreshDailyDeclinedUsers();
        this.refreshDailyRejectedUsers();
        this.updateHash("educationalPopups", {
          [DeclineEducationalPopup.Third]: false,
          [RejectEducationalPopup.Third]: false,
        });
      }
    } else {
      this.set("refreshTime", getUTCDateNextDayAt(REFRESH_HOUR));
    }
  }

  isPlus() {
    let sub = this.get("subscription");
    if (sub) {
      let diff = Math.abs(
        new Date(sub.renewsAt).getTime() - new Date().getTime()
      );
      return sub.status == "active" && diff > 0;
    } else {
      return false;
    }
  }

  getSubscriptionName() {
    return this.get("subscription").name;
  }

  getFlag(key, defaultValue = null) {
    return this.get("flags")[key] || defaultValue;
  }

  callOnceLocally(flag: string, callback: Function): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!this.get(flag)) {
        this.set(flag, true);
        await callback();
      }
      resolve();
    });
  }

  async callOnce(flag: string, callback: Function): Promise<void> {
    if (!this.getFlag(flag)) {
      callback();
      await this.setFlag(flag, true);
    }
  }

  getPartnerName(): string {
    return this.get("partner")?.name;
  }

  getUserArea(): string {
    return this.get("closest_city")?.name;
  }

  getUserCity(): string {
    return this.getFlag("city_name");
  }

  getPartnerImage(): string {
    return (
      this.get("partner")?.image_url ||
      "./assets/img/influencer-empty-state.png"
    );
  }

  async updateMatchPreferences(options) {
    let matchPreferences = this.get("matchPreferences");
    matchPreferences = {
      ...matchPreferences,
      ...options,
    };
    await this.updateProfile({
      matchPreferences,
    });
  }

  setFlag(key, value) {
    let flags = {};
    flags[key] = value;
    return this.setFlags(flags);
  }

  isDarkMode() {
    return this.colorModeService.isDark();
  }

  setFlags(flags, updateServer = true): Promise<void> {
    return new Promise((resolve, reject) => {
      let changed = false;
      for (var key in flags) {
        let value = flags[key];
        if (this.get("flags")[key] !== value) {
          changed = true;
          this.get("flags")[key] = value;
        }
      }

      if (!changed) return resolve();

      this.save().then((_) => {
        if (updateServer) {
          this.flagsChangedSource.next(this.get("flags"));
        }
        resolve();
      }, reject);
    });
  }

  isGlobalSearching() {
    return (
      this.get("matchPreferences").location == MatchPreferencesLocation.Global
    );
  }

  trackMatchPreferencesChanges(newMatchPreferences, oldMatchPreferences) {
    if (newMatchPreferences.gender != oldMatchPreferences.gender) {
      logc.info("-- MP gender changed! --");
      this.analyticsService.trackEvent({
        key: "gender_preference_changed",
        value: 1,
        old: oldMatchPreferences.gender,
        new: newMatchPreferences.gender,
      });
      this.analyticsService.trackProperty({
        key: "gender_preference",
        value: newMatchPreferences.gender,
      });
    }
    if (newMatchPreferences.location != oldMatchPreferences.location) {
      logc.info("-- MP location changed! --");
      this.analyticsService.trackEvent({
        key: "location_preference_changed",
        value: 1,
        old: oldMatchPreferences.location,
        new: newMatchPreferences.location,
      });
      this.analyticsService.trackProperty({
        key: "location_preference",
        value: newMatchPreferences.location,
      });
    }
    if (
      oldMatchPreferences.age_range &&
      !_.isEqual(oldMatchPreferences.age_range, newMatchPreferences.age_range)
    ) {
      logc.info("-- MP age_range changed! --");
      this.analyticsService.trackEvent({
        key: "age_range_changed",
        value: 1,
        old: oldMatchPreferences.age_range,
        new: newMatchPreferences.age_range,
      });
      this.analyticsService.trackProperty({
        key: "age_range",
        value: newMatchPreferences.age_range,
      });
    }
    if (
      oldMatchPreferences.tribe_quality &&
      !_.isEqual(
        oldMatchPreferences.tribe_quality,
        newMatchPreferences.tribe_quality
      )
    ) {
      logc.info("-- MP tribe_quality changed! --");
      this.analyticsService.trackEvent({
        key: "compatibility_range_changed",
        value: 1,
        old: oldMatchPreferences.tribe_quality,
        new: newMatchPreferences.tribe_quality,
      });
      this.analyticsService.trackProperty({
        key: "compatibility_range",
        value: newMatchPreferences.tribe_quality,
      });
    }
  }

  trackRadiusChanges(oldRadius, newRadius) {
    this.analyticsService.trackEvent({
      key: "maximum_distance_changed",
      value: 1,
      old: oldRadius,
      new: newRadius,
    });
    this.analyticsService.trackProperty({
      key: "maximum_distance",
      value: newRadius,
    });
  }

  trackPictureChanges(picture) {
    let key: any = null;
    if (!this.get("picture")) {
      key = "pic_set";
    } else {
      key = "pic_changed";
    }
    this.analyticsService.trackEvent({ key, value: 1 });
    this.analyticsService.trackProperty({ key: "picture", value: 1 });
  }

  updateProfile(params, skipAnalytics = false): Promise<void> {
    logc.black("--- config.ts updateProfile() updating profile!: ", params);
    let keys: any = Object.keys(params);
    return new Promise(async (resolve, reject) => {
      let availableForTribes =
        keys.includes("availableForTribes") &&
        params["availableForTribes"] !== this.get("availableForTribes");
      let status =
        keys.includes("status") && params["status"] !== this.get("status");
      let closest_city: any =
        keys.includes("closest_city") &&
        params["closest_city"] !== this.get("closest_city");
      let pictureStatus =
        keys.includes("pictureStatus") &&
        params["pictureStatus"] !== this.get("pictureStatus");
      const matchPreferencesChanged =
        keys.includes("matchPreferences") &&
        !_.isEqual(params["matchPreferences"], this.get("matchPreferences"));
      const radiusChanged =
        keys.includes("radius") && params["radius"] != this.get("radius");

      if (!skipAnalytics) {
        if (matchPreferencesChanged && this.isLoggedIn()) {
          const oldMatchPreferences = { ...this.get("matchPreferences") };
          const newMatchPreferences = { ...params["matchPreferences"] };
          this.trackMatchPreferencesChanges(
            newMatchPreferences,
            oldMatchPreferences
          );
        }

        if (
          keys.includes("picture") &&
          params["picture"] != this.get("picture")
        ) {
          this.trackPictureChanges(params["picture"]);
        }

        if (radiusChanged) {
          this.trackRadiusChanges(this.get("radius"), params["radius"]);
        }
      }

      // if(params['partner'] && !this.get("partner")) {
      //   this.set("partner", params['partner']);
      // }

      //Don't update the picture if it's the same or if it's not saved on the server
      //Won't be saved if the picture is invalid unless we request a review
      if (
        params["picture"] === null ||
        params["picture"] === this.get("picture")
      ) {
        delete params["picture"];
      } else if (
        (params["picture"] && params["picture"] != this.get("picture")) ||
        !this.get("cachedPicture")
      ) {
        logc.info("Caching picture in config...");
        this.processCachePicture(params["picture"]);
      }

      let changedKeys: any = Object.keys(params);
      let localKeys: any = Object.keys(this.defaultConfig);
      changedKeys.forEach((key) => {
        //Don't update keys that don't belong to Config
        //e.g. user provider updates all the keys it has
        if (localKeys.includes(key)) {
          this.update(key, params[key]);
        }
      });
      this.save().then((_) => {
        if (!skipAnalytics) {
          if (status) {
            this.trackEmailStatusChanged();
          }
          if (pictureStatus) {
            this.trackPictureStatusChanged();
          }
        }

        if (availableForTribes) {
          this.availableForTribesChanged();
        }
        if (closest_city?.country) {
          this.assignDefaultUnitSystem();
        }
        if (matchPreferencesChanged || radiusChanged) {
          this.matchPreferencesChangeSource.next(params["matchPreferences"]);
        }
        this.profileChangedSource.next(this.getProfile());
        resolve();
      }, reject);
    });
  }

  async processCachePicture(image: any, verifiedPicture = true) {
    logc.crimson("Setting cache image...");
    if (!image) return;
    // let path = !verifiedPicture ? 'temporaryPicture' : 'cachedPicture';
    let path = "cachedPicture";
    if (this.isUrl(image)) {
      await this.pictureService
        .toDataUrl(image)
        .then((base64) => this.setCachePicture(base64, path));
    } else {
      this.setCachePicture(image, path);
    }
  }

  async setCachePicture(image, path) {
    const picture = await this.pictureService.sanitizeImage(image);
    this.update(path, picture);
    await this.save();
    this.pictureCachedSource.next(null);
    return;
  }

  getPicture(picture = this.get("picture")) {
    return this.get("cachedPicture") || picture || this.getDefaultAvatar();
  }

  toFullsizePicture(pictureUrl) {
    return pictureUrl.replace(".jpg", "-fullsize.jpg");
  }

  isUrl(s) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }

  isLoggedIn() {
    return this.get("firstName") || this.get("email");
  }

  assignDefaultUnitSystem() {
    this.data.matchPreferences.defaultUnitSystem =
      this.get("closest_city")?.country == "United States of America" ||
      this.get("closest_city")?.country == "United States Virgin Islands"
        ? "mi"
        : "km";
    this.save();
  }

  isAnonymous() {
    return this.get("email") == null && this.get("firstName") == null;
  }

  getDefaultRootTab() {
    return "/tabs/tribes";
  }

  getDefaultAgeRange() {
    let userAge = this.getUserAge();
    let ageDiff = Math.round(userAge / AGE_DIVIDER);
    return {
      lower: userAge - ageDiff,
      upper: userAge + ageDiff,
    };
  }

  getProfile() {
    return {
      id: this.get("id"),
      email: this.get("email"),
      gender: this.get("gender"),
      pictureGender: this.get("pictureGender"),
      firstName: this.get("firstName"),
      lastName: this.get("lastName"),
      picture: this.get("cachedPicture") || this.get("picture"),
      birthday: this.get("birthday"),
      status: this.get("status"),
      latitude: this.get("latitude"),
      longitude: this.get("longitude"),
      closest_country_name: this.get("closest_country_name"),
      closest_city: this.get("closest_city"),
      currentLevel: this.get("currentLevel"),
      availableForTribes: this.get("availableForTribes"),
      skipNotLaunchedWarning: this.get("skipNotLaunchedWarning"),
      startedCurrentLevel: this.get("startedCurrentLevel"),
      radius: this.get("radius"),
      enforceRadius: this.get("enforceRadius"),
      shared: this.get("shared"),
      offensiveMessageIds: this.get("offensiveMessageIds"),
      phoneNumber: this.get("phoneNumber"),
      phoneNumberStatus: this.get("phoneNumberStatus"),
      availableWhileUninstalled: this.get("availableWhileUninstalled"),
      pictureStatus: this.get("pictureStatus"),
      referralCode: this.get("referralCode"),
      notificationPreferences: this.get("notificationPreferences"),
      instagramTestUser: this.get("instagramTestUser"),
      referredBy: this.get("referredBy"),
      partnerCode: this.get("partnerCode"),
      partner: this.get("partner"),
      instagramToken: this.get("instagramToken"),
      hasSms: this.get("phoneNumberStatus") === "verified",
      geoSkipped: this.get("geoSkipped"),
      hasManualLocation: this.get("hasManualLocation"),
      firstPendingTribeVisit: this.get("firstPendingTribeVisit"), //Get rid of this soon
      legalGuardian: this.get("legalGuardian"),
      referrer: this.get("referrer"),
      banned: this.get("banned"),
      hasPassword: this.get("hasPassword"),
      subscription: this.get("subscription"),
      matchPreferences: this.get("matchPreferences"),
      pwaToken: this.get("pwaToken"),
      smsOptIn: this.get("smsOptIn"),
    };
  }

  hasSms() {
    return this.get("phoneNumberStatus") === "verified";
  }

  trackPictureStatusChanged() {
    this.analyticsService.trackProperty({
      key: "picture_status",
      value: this.get("pictureStatus"),
    });
  }

  trackEmailStatusChanged() {
    this.analyticsService.trackProperty({
      key: "email_status",
      value: this.get("status"),
    });
  }

  updateNewEmojisUsed(emojis) {
    let recent = this.getFlag("recent_emojis", []);
    let old = [];
    if (recent.length > 0) old = recent;

    emojis.forEach((e) => {
      let i = old.indexOf(e);
      if (i > -1) old.splice(i, 1);
      old.unshift(e);
    });

    while (old.length > 50) old.pop();

    this.setFlag("recent_emojis", old);
  }

  availableForTribesChanged() {
    this.availabilityChangedSource.next(this.get("availableForTribes"));
  }

  hasLocation() {
    return this.get("latitude") !== null;
  }

  shouldRedirectToDownloadApp() {
    return this.isPWA && this.platformName != "dev" && isMobile();
  }

  isAvailable() {
    return this.get("availableForTribes") === true;
  }

  getApiKey() {
    return this.get("apiKey");
  }

  finishedLevel(level) {
    let currentLevel = this.get("currentLevel");
    let startedCurrentLevel = this.get("startedCurrentLevel");

    let endOfLevel = !startedCurrentLevel && currentLevel == level;
    return endOfLevel || currentLevel > level;
  }

  getCurrentLevel() {
    return this.data["currentLevel"];
  }

  isBonusLevel() {
    // return !this.get('startedCurrentLevel') && !this.get('bonusLevelDone') && this.finishedLevel(5);
    return this.getCurrentLevel() === 6;
  }

  async unlockBonusLevel() {
    await this.setFlag("bonusLevelUnlocked", true);
  }

  isOnboardingLevel() {
    return this.getCurrentLevel() == 1;
  }

  isUnconfirmed() {
    return (
      this.data["email"] == null ||
      this.data["status"] == null ||
      this.data["status"] == "unconfirmed"
    );
  }

  set(key, value) {
    super.set(key, value);
    this.localDataChangeSource.next(this.data);
  }

  updateHash(flag: string, value: Object = {}): void {
    if (!this.get(flag) || typeof this.get(flag) != "object") return;
    this.set(flag, { ...this.get(flag), ...value });
  }

  setPassedUsersInfo({ refresh }) {
    if (!this.get("passedUsers") || refresh) {
      this.set("passedUsers", {
        declined: {
          daily: 0,
          streak: 0,
          total: 0,
          reachedLimitTimes: 0,
          reasons: {},
        },
        rejected: {
          daily: 0,
          streak: 0,
          total: 0,
          reachedLimitTimes: 0,
          reasons: {},
        },
      });
    }
  }

  reset() {
    return new Promise((resolve, reject) => {
      //Before resetting, keep those values in

      //persisted across accounts
      let latitude = this.data["latitude"];
      let longitude = this.data["longitude"];

      let notFeelingPassedCounter = this.data["notFeelingPassedCounter"];
      let notFeelingPassedTime = this.data["notFeelingPassedTime"];

      //persisted until logout or deletion
      let geoSkipped = this.data["geoSkipped"];
      let pwaToken = this.data["pwaToken"];

      this.resetCache();
      this.init().then((_) => {
        this.data["latitude"] = latitude;
        this.data["longitude"] = longitude;
        this.data["geoSkipped"] = geoSkipped;
        this.data["pwaToken"] = pwaToken;
        this.data["notFeelingPassedCounter"] = notFeelingPassedCounter;
        this.data["notFeelingPassedTime"] = notFeelingPassedTime;
        // this.setPassedUsersInfo({ refresh: true });
        this.save().then(resolve, reject);
      }, reject);
    });
  }

  load() {
    return super.load();
  }

  cleanupAccountConfigs() {
    this.setFlag("opening_is_done", null);
    this.update("geoSkipped", this.defaultConfig["geoSkipped"]);
    this.update("pwaToken", this.defaultConfig["pwaToken"]);
    this.save();
  }

  getUserAge() {
    let ms = new Date().valueOf() - Date.parse(this.get("birthday")).valueOf();
    let years = 1000 * 60 * 60 * 24 * 365;
    return Math.trunc(ms / years);
  }

  isMinor() {
    let age = this.getUserAge();
    return +age >= 12 && +age <= 15;
  }

  resetCache() {
    super.resetCache();
    this.loadPromise = null;
    this.profile = null;
  }

  onboardingDone() {
    return this.getFlag("opening_is_done");
  }

  getNotificationSettings() {
    return {
      ...DEFAULT_NOTIFICATION_SETTINGS,
      ...this.get("notificationPreferences"),
    };
  }

  isDesktop(): boolean {
    return this.isPWA && !isMobile();
  }

  isCurrentUser(userId: number): boolean {
    return this.get("id") == userId;
  }

  isIosPWA() {
    return !this.platform.is("cordova") && isIosPWA();
  }

  getDefaultAvatar(): string {
    return {
      dark: DEFAULT_DARK_AVATAR_PATH,
      light: DEFAULT_AVATAR_PATH,
    }[this.colorModeService.getMode()];
  }

  isFan(): boolean {
    return this.get("partner")?.partner_type == "influencer";
  }
}
