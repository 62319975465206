export const getProfileBadge = ({
    notificationName = '',
    firstName = '',
    city = '',
    cssClass = '',
    handler = () => {}
  }) => {
    return {
      you_rejected: {
        icon: { name: 'thumb-down', cssClass: 'error-light-1-bg stroke-error-dark-3' },
        body: {
          title: 'Unavailable: You rejected them',
          desc: `You previously indicated that you weren't feeling them`,
          learnMore: false
        },
        buttons: [
          {
            text: "Reset",
            cssClass: "font-semibold text-primary",
            handler: () => handler()
          }
        ],
        callback: () => {}
      },
      previously_grouped: {
        icon: { name: 'ban', cssClass: 'error-light-1-bg error-dark-3-color' },
        body: {
          title: 'Unavailable: Previously grouped',
          desc: `All three group members must be new to each other, but this user has already been grouped with your other matches.`,
          learnMore: false
        },
        callback: () => {}
      },
      taking_a_break: {
        icon: { name: 'time-outline', cssClass: 'error-light-1-bg error-dark-3-color' },
        body: {
          title: 'Unavailable: Taking a break',
          desc: `They recently updated their status to Invisible to pause invitations to new groups. If they become available again, you will match.`,
          learnMore: false
        },
        callback: () => {}
      },
      unreachable: {
        icon: { name: 'notifications-off', cssClass: 'error-light-1-bg error-dark-3-color' },
        body: {
          title: 'Unavailable: Notification Issues',
          desc: `We’re having trouble reaching them through the app and we can’t text them because they haven’t verified their number.`,
          learnMore: false
        },
        callback: () => {}
      },
      daily_limit: {
        icon: { name: 'too-busy', cssClass: 'error-light-1-bg error-dark-3-color' },
        body: {
          title: 'Unavailable: Reached daily limit',
          desc: `They were already matched in a group today.`,
          learnMore: false
        },
        callback: () => {}
      },
      outsideRadius: {
        icon: { name: 'no-location', cssClass: 'error-gradient-bg white-color' },
        body: {
          title: 'Outside your distance filter',
          desc: `To stop matching outside your max distance, tap Pass and select Too Far.`,
          learnMore: false
        },
        callback: () => {}
      },
      arriving: {
        icon: { name: 'airplane-outline', cssClass: 'primary-gradient-bg white-color rotated--45' },
        body: {
          title: `Coming to ${ city } soon`,
          desc: `${ firstName } isn't in ${ city } just yet, but will be here in the near future.`,
          learnMore: false
        },
        callback: () => {}
      },
      joining: {
        icon: { name: 'timer-outline', cssClass: cssClass },
        body: {
          title: `Waiting for them to join`,
          desc: `If ${ firstName }'s time runs out, we'll suggest another user you're compatible with.`,
          learnMore: false
        },
        callback: () => {}
      },
      approving: {
        icon: { name: 'timer-outline', cssClass: cssClass },
        body: {
          title: `Waiting for them to approve`,
          desc: `${ firstName } needs to approve the new member before we can invite them to your Group`,
          learnMore: false
        },
        callback: () => {}
      }
    }[ notificationName ];
};
