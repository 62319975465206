import {Component, OnInit, Input, ViewChild, ChangeDetectorRef} from '@angular/core';
import { D3Service, Point } from 'src/app/services/helpers/d3.service';
import { Config } from 'src/app/services/config/config';
import { StatementsService } from 'src/app/services/data/statements.service';
import { SessionService } from 'src/app/services/data/session.service';
import * as d3 from 'd3';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import {RouteTrackerService} from "../../services/route-tracker.service";
import {ColorModeService} from "../../services/native/color-mode.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnInit {

  @Input() displayProfile :any;
  @Input() isCurrentUserProfile = false;
  @Input() onTouchend;
  @Input() path;
  @ViewChild('radarContainer', { static: true }) chartContainer:any;
  dispalyedData: any;
  currentUserId:any;
  routeToGraph;

  externalPoints:any;
  numSubFactor:any;
  graphSize:number;

  @Input() userId: number;
  smooth: boolean = true;
  curvature: number = 5;

  public isDarkMode: boolean = false;

  showTip: boolean = true;

  public unsubscribe  = new Subject();

  constructor(
    public config:Config,
    public platform: Platform,
    public d3Service: D3Service,
    public router: Router,
    public colorModeService: ColorModeService,
    private routeTrackerService: RouteTrackerService,
    public statementsService: StatementsService,
    public sessionService: SessionService,
    private cdr: ChangeDetectorRef
  ) {
    this.colorModeService
      .onModeChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(mode => {
        console.log('--- updating radar chart... ---');
        this.isDarkMode = mode == 'dark';
        this.updateRadarChar();
      })
  }

  ngOnInit() {
    this.isDarkMode = this.colorModeService.isDark();
    this.chartContainer = d3.select(this.chartContainer.nativeElement);
    this.currentUserId = this.sessionService.userId;
    this.userId = this.displayProfile.id;
    (<any> window).radar = this;

    if(this.isCurrentUserProfile){
      this.statementsService.onMetaUserUpdated.subscribe({next: _ => this.updateRadarChar()});
      this.routeToGraph = window.location.origin;

      if(this.config.platformName === 'pwa' && window.location.hostname.includes('we3app')){
        this.routeToGraph += '/start';
      }

      this.routeToGraph += this.path;
    }
    else{
      this.routeToGraph = window.location.href;//this.router.url;
    }
    this.updateRadarChar();
    this.onTouchend
      .asObservable()
      .subscribe({ next: _=> {
        // this.hideHover()
      } })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // hideTip(){
  //   this.showTip = false;
  //   let elementsToShow;
  //   if(this.isCurrentUserProfile){
  //     elementsToShow = d3.select(".legend-container").selectAll("path,text");
  //   } else {
  //     elementsToShow = d3.select(".second-user");
  //   }
  //   elementsToShow
  //     .transition()
  //     .ease(d3.easeQuadInOut)
  //     .duration(250)
  //     .attr("opacity", "1")
  // }

  // hideHover(){
  //   let elementsToShow;
  //   if(this.isCurrentUserProfile){
  //     elementsToShow = d3.select(".legend-container").selectAll("path,text");
  //   } else {
  //     elementsToShow = d3.select(".second-user");
  //   }
  //   // elementsToShow.transition()
  //   //   .ease(d3.easeQuadInOut)
  //   //   .duration(250)
  //   //   .attr("opacity", "0")
  // }

  /*
  toogleSmooth(){
    this.chartContainer.select("svg").remove();
    this.smooth = !this.smooth
    this.buildRadarChart(this.smooth);
  }
  changeCurvature($event){
    this.curvature = $event
    this.chartContainer.select("svg").remove();
    this.buildRadarChart(this.smooth);
  }
  */
  updateRadarChar(){
    this.chartContainer.select("svg").remove();
    var svg = this.chartContainer.append("svg").attr("class", this.isCurrentUserProfile ? "current-user" : "other-user");
    this.statementsService.getRadarData(this.userId)
      .then(data => {
        this.dispalyedData = data;
        this.sortRadarData(this.dispalyedData);
        this.buildRadarChart(svg, this.smooth);
      })
      .catch( err => console.log(err))
  }

  sortRadarData(data){
    data.sort((factorA,factorB) => {
      return -factorB.factor.localeCompare(factorA.factor)
    })
    data.forEach(factorData => {
      factorData.values.sort(
        (categ1,categ2) => +categ1.factor_categ_id - +categ2.factor_categ_id
      )
    })
  }

  buildRadarChart(svg,smooth){
    var numGrid = 4 ;
    this.numSubFactor = this.dispalyedData.map( el => {
      return {"factor": el["factor"],"subCategNum" : el["values"].length}
    });

    if (window.innerHeight < window.innerWidth) {
      this.graphSize = this.platform.height()*0.45;
    }
    else if(this.platform.height() < 580){
      this.graphSize = this.platform.width()*0.7;
    } else {
      this.graphSize = this.platform.width()*0.8;//75;
    }

    var graphPadding = 20;
    var axColor = this.isDarkMode ? "#313746" : "#EFF2F6";

    svg
      .attr("height", this.graphSize + graphPadding)
      .attr("width", this.graphSize + graphPadding);


    var graphG =
      svg
        .append("g")
        .attr("class","first-user")
        .attr("transform","translate("+svg.attr("width")/2+","+svg.attr("height")/2+")");

    this.externalPoints = this.drawAxes(graphG,numGrid,this.dispalyedData,axColor);

    var defs = graphG.append("defs");
    var user1Profile = this.drawUserProfile(graphG, this.dispalyedData ,smooth)
    if(this.isCurrentUserProfile){
      this.d3Service.addShadow(defs,user1Profile,2,2,5,"profile1Filter",this.routeToGraph)
      this.d3Service.fillGradient(defs,user1Profile, new Point("25%","25%"),new Point("75%","75%"),"#3a9af5","#8067f5", 0.55,"gradient1",this.routeToGraph)
    } else {
      this.buildCurrentUserRadarChart(smooth);
      this.d3Service.addShadow(defs,user1Profile,2,2,5,"profile3Filter",this.routeToGraph)
      this.d3Service.fillGradient(defs,user1Profile, new Point("25%","25%"),new Point("75%","75%"),"#e69138","#ebd254", 0.5,"gradient3",this.routeToGraph)
    }

    var legendG =
      svg
        .append("g")
        .attr("class", "legend-container")
        .attr("transform", "translate(" + svg.attr("width") / 2 + "," + svg.attr("height") / 2 + ")");

    this.drawLgend(
      legendG,
      this.dispalyedData,
      this.computeExtremePoints(this.dispalyedData,this.graphSize/2)
    );

    const self = this;
    d3
      .select(`svg.${ this.isCurrentUserProfile ? "current-user" : "other-user" }`)
      .select('.legend-container')
      .on("click", function() {
        console.log('legend is drawn!');
        self.toggleLegend();
      }, self);

    // let drag = this.holdBehaviour();
    // legendG.call(drag);
  }

  drawAxes(graphContainer, numGrid, radarChartData, axColor){
    var axesContainer = graphContainer.append("g").attr("class","axes-container");
    var roundAxesContainer = axesContainer.append("g").attr("class","round-axes-container");
    for (var j = numGrid-1; j >= 0; j--) {
      let distFromCenter = this.graphSize/2/numGrid*(j+1);
      let points = this.computeExtremePoints(radarChartData,distFromCenter)

      let path = roundAxesContainer.append("path")
      .attr("d",this.d3Service.pathDFromPoints(points))
      .attr("stroke",axColor)
      path.attr("fill","none")
      .attr("stroke-width","2")
      if(j == numGrid-1){ //Outer ring
        path.attr("fill", this.isDarkMode ? "#242A39" : "#FFFFFF").attr("fill-opacity","1");

        this.d3Service.addDarkShadow(
          roundAxesContainer.append("defs"),
          path,
          0,
          0,
          10,
          "backShadow" + "bis" + this.userId,
          0.04,
          this.routeToGraph);
        this.drawRadialLines(
          axesContainer
            .append("g")
            .attr("class", "lines-container"),
          points,
          axColor);
        var externalPoints = points;
      }
    }
    return externalPoints
  };

  computeExtremePoints(radarChartData,distFromCenter){
    let points = []
    for (var i = 0; i < radarChartData.length; i++) {
      var point = this.d3Service.computeCirclePoint(i*360/radarChartData.length, distFromCenter);
      points[i] = point;
    }
    return points
  }

  drawUserProfile(graphG, data, smooth, printVisualAids=false){
    var profilePoints = [];
    var tangents = [];
    data.forEach((factorData,index) => {
      var p1 = this.externalPoints[index];
      var p2 = this.externalPoints[(index+1)%this.externalPoints.length];
      var origin = new Point(0,0);
      var subFactorNumber = this.numSubFactor[index]['subCategNum']
      factorData.values.forEach((object,index) => {
        if(index>=0 && index<subFactorNumber){
          var externalPoint = Point.interpolate(p1,p2,(index+1)/(subFactorNumber+1))
          profilePoints.push(Point.interpolate(origin,externalPoint,Math.max(Math.min(object.val,1)*(1-0.25)+0.25)))
          if(smooth){
            let tangent = Point.substract(p2,p1);
            tangent.normalise();
            tangents.push(tangent)
          }
        }
      })
    })
    if(printVisualAids){
      profilePoints.forEach(point =>{
        graphG.append("circle")
        .attr("cx",point.x)
        .attr("cy",point.y)
        .attr("r",2)
        .attr("fill","black")
        .attr("stroke","grey")
        .attr("stroke-width","1")
      })
    }
    return graphG.append("path")
    .attr("d",this.d3Service.pathDFromPoints(profilePoints, smooth, graphG, tangents, this.curvature, printVisualAids))
  }
  private legendShown: boolean = false;
  drawLgend(legendG, radarChartData, externalPoints){
    /**
     * See https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Gradients for more info
     */
    var centerPoints = []
    for (var index = 0; index < externalPoints.length; index++) {
      var sectorG = legendG.append("g");
      var factorData = radarChartData[index];
      var p1 = externalPoints[index];
      var p2 = externalPoints[(index+1)%externalPoints.length];
      const innerMarginProportion = 50;
      var p3 = new Point((p2.x+p1.x)/innerMarginProportion,(p2.y+p1.y)/innerMarginProportion); //Would be 0,0 if centered
      centerPoints.push(p1);
      centerPoints.push(p3);
      centerPoints.push(p2);
      var sector = sectorG
        .append("path")
        .attr("d",this.d3Service.pathDFromPoints([p1,p2,p3]))
        .attr("opacity","0");

      //Gradient
      //Gradient coordinates are in local space with the path to be filled
      //The gradient should start in the center and end in the middle of the outer border to have it radial
      //Just need to get the local origin global coordinates and change coordinate space

      //In global coordinate
      var pMid = Point.interpolate(p1,p2,.5); //center of outer border
      var p4 = Point.interpolate(p3,pMid,2); //point beyond the outer border same distance than pdMid p3
      var sectorPoints = [p1,p2,p3,p4];
      var norms = sectorPoints.map(point => +point.x + +point.y );
      var localOrigin = sectorPoints[norms.indexOf(Math.min(...norms))];//Local origin is the most upper left

      var sectorSize = Point.substract(p4,p1).norm();
      var startingPoint = Point.substract(localOrigin,p3,sectorSize,sectorSize);
      var endingPoint = Point.substract(localOrigin,pMid,sectorSize,sectorSize);

      var sectorDefs = sectorG.append("defs");
      var sectorColors = this.statementsService.getFactorGradientColor(factorData.factor)
      this.d3Service.fillGradient(sectorDefs,sector, startingPoint ,endingPoint,sectorColors[0],sectorColors[1], 0.6,"gradientLegend"+index+"Bis"+this.isCurrentUserProfile,this.routeToGraph);
      this.d3Service.addShadow(sectorDefs,sector,1,1,5,"legendShadow"+index+"Bis"+this.isCurrentUserProfile,this.routeToGraph);

      legendG
        .append("text")
        .html(factorData.factor)
        .attr("x",(p1.x+p2.x+p3.x)/3)
        .attr("y",(p1.y+p2.y+p3.y)/3)
        .attr("text-anchor","middle")
        .attr("opacity","0")
        .attr("fill","white")
        .call(this.d3Service.wrap, 65)
        .attr("class","legend-text")
        .attr("font-size", "13px");
    }
    //Inner white star
    legendG
      .append("path")
      .attr("d",this.d3Service.pathDFromPoints(centerPoints))
      .attr("opacity","0")
      .attr("fill", this.isDarkMode ? "#242A39" : "#FFFFFF")
      .attr("stroke-width","3")
      .attr("stroke", this.isDarkMode ? "#313746" : "#EFF2F6");


    setTimeout( _ => {
      const els = Array.from(document.getElementsByClassName('legend-text') as HTMLCollectionOf<HTMLElement>);
      els.forEach( el => { el.style.fontSize = '12px' });
    }, 600);
  }

  private toggleState: number = 0;
  toggleLegend() {
    if(this.isCurrentUserProfile) {
      const newOpacity = this.legendShown ? 0 : 1;
      this.legendShown = !this.legendShown;
      this.setLegendContainerOpacity( newOpacity );
    } else {
      if(this.toggleState === 0) {
        this.setOverlayingUserOpacity( 1 );
      }
      if(this.toggleState === 1) {
        this.setOverlayingUserOpacity( 0 );
        this.setLegendContainerOpacity( 1 );
      }
      if(this.toggleState === 2) {
        this.setOverlayingUserOpacity( 0 );
        this.setLegendContainerOpacity( 0 );
      }
      if(this.toggleState < 2) {
        this.toggleState = this.toggleState + 1;
      } else {
        this.toggleState = 0;
      }
      this.showTip = this.toggleState == 0;
      // console.log(this.toggleState);
    }
  }

  setOverlayingUserOpacity( opacity ) {
    console.log('...setOverlayingUserOpacity...', opacity);
    d3
      .select( '.second-user')
      .transition()
      .ease(d3.easeQuadInOut)
      .attr("opacity", opacity);
  }

  setLegendContainerOpacity( opacity ) {
    this.showTip = !opacity;
    d3
      .select(`svg.${ this.isCurrentUserProfile ? "current-user" : "other-user" }`)
      .select( '.legend-container')
      .selectAll("path,text")
      .transition()
      .ease(d3.easeQuadInOut)
      .attr("opacity", opacity);
  }

  showLegend() {
    this.toggleLegend();
  }


  drawRadialLines(linesContainer, points, axColor){
    //Big Cat lines
    points.forEach(point => {
      linesContainer.append("line")
      .attr("x1",0)
      .attr("y1",0)
      .attr("x2",point.x)
      .attr("y2",point.y)
      .attr("stroke",axColor)
      .attr("stroke-width","2")
    });
    for (var index = 0; index < points.length; index++) {
      var p1 = points[index];
      var p2 = points[(index+1)%points.length];
      var subCatNumber = this.numSubFactor[index]["subCategNum"];
      for (var i = 1; i <= subCatNumber; i++) {
        var interpolatedPoint = Point.interpolate(p1,p2,i/( subCatNumber+1));
        linesContainer
          .append("line")
          .attr("x1",0)
          .attr("y1",0)
          .attr("x2",interpolatedPoint.x)
          .attr("y2",interpolatedPoint.y)
          .attr("stroke",axColor)
          .attr("stroke-width","1")
      }
    }
  }

  buildCurrentUserRadarChart(smooth){
    this.statementsService.getRadarData(this.currentUserId).then(data => {
      var currentUserData  = data as any;
      this.sortRadarData(currentUserData);
      var svg = this.chartContainer.select("svg");
      var graphGBis = svg
        .insert("g","g.legend-container")
        .attr("class","second-user")
        .attr("opacity","0")
        .attr("transform","translate("+svg.attr("width")/2+","+svg.attr("height")/2+")");

      var defsBis = graphGBis.append("defs");

      this.externalPoints = this.computeExtremePoints(currentUserData,this.graphSize/2);
      this.numSubFactor = currentUserData.map( el => {
        return {"factor": el["factor"],"subCategNum" : el["values"].length}
      });
      var user2Profile = this.drawUserProfile(graphGBis, currentUserData, smooth);
      let nameBubbleContainer = graphGBis
        .append("g")
        .attr("transform", "translate(0,0)");

      nameBubbleContainer
        .append("rect")
        .attr("x", "-32.5px")
        .attr("y", "-27.5px")
        .attr("width", "65px")
        .attr("height", "45px")
        .attr("rx", "25px")
        .attr("ry", "25px")
        .attr("stroke", "#E5EBFB")
        .attr("stroke-width", "2px")
        .attr("fill", "#B9CBF9")
        .attr("fill-opacity", "0.3");

      nameBubbleContainer
        .append('text')
        .attr("y", "3px")
        .attr("font-size", "20px")
        .attr("font-family", "Lato")
        .attr("font-weight", "bold")
        .attr("text-anchor","middle")
        .attr("fill", "#001957")
        .text('You');

      this.d3Service.addShadow(defsBis,user2Profile,2,2,5,"profile2Filter",this.routeToGraph);
      this.d3Service.fillGradient(defsBis,user2Profile, new Point("25%","25%"),new Point("75%","75%"),"#3a9af5","#8067f5", 0.5,"gradient2",this.routeToGraph);
    }, err => {
      console.log( err );
    })
  }
}
