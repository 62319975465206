import { Component, Injectable, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { GiphyService } from '../../services/data/giphy.service';

@Component({
  selector: 'gifs',
  templateUrl: 'gifs.html',
  styleUrls: ['gifs.scss'],
})
@Injectable()
export class GifsComponent {
  @Output() onSelect = new EventEmitter();
  @Output() onBack = new EventEmitter();

  @ViewChild('searchbar', { static: true }) searchbar: any;
  @ViewChild('scroll', { static: true }) scroll: ElementRef; 

  public searchValue: string;

  gifs: any[] = [];
  loading = false;
  previousScrollPosition = 0;

  constructor(public giphyService: GiphyService) {}

  select(url){
    this.onSelect.emit(url);
  }

  back(ev){
    this.onBack.emit(ev);
  }

  reset(){
    this.searchbar.value = '';
    this.giphyService.reset();
  }

  search(ev: any){
    this.giphyService.reset();
    this.scroll.nativeElement.scrollLeft = 0;
    this.update();
  }

  cleanSearch() {
    this.searchValue = '';
  }

  onScroll = (e) => {
    let page = e.target.clientWidth;
    let scrolled = e.target.scrollLeft;
    let totalScrollable = e.target.scrollWidth;
    let direction = this.previousScrollPosition > scrolled ? 'left' : 'right';
    this.previousScrollPosition = scrolled;

    if((scrolled + page) > totalScrollable - page/3) {
      if(!this.loading && direction == 'right') {
        this.loading = true;
        this.doInfinite().then(_=> {
          setTimeout( _ => {
            this.loading = false;
          }, 200);
        });
      }
    }
  }

  init(){
    this.loading = false;
    this.previousScrollPosition = 0;
    console.info('scroll: ', this.scroll);
    this.scroll.nativeElement.addEventListener("scroll", this.onScroll);
    return this.update();
  }

  update(){
    return this.giphyService.load(this.searchbar.value).then((items: any[]) => {
      this.gifs = items;
      if(this.giphyService.canLoadMore()){
        this.gifs.push({mini_url: null, url: null, mini_width: '100'});
        this.gifs.push({mini_url: null, url: null, mini_width: '100'});
      }
    }).then(_=> {
      this.searchbar.setFocus();
    });
  }

  doInfinite(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.giphyService.load(this.searchbar.value).then((items: any[]) => {
        
        let loadingIndicators = [];
        if(this.gifs.length > 0) {
          while(this.gifs[this.gifs.length-1].mini_url == null) {
            loadingIndicators.push(this.gifs.pop());
          }
        }
        this.gifs = this.gifs.concat(items);

        while(loadingIndicators.length > 0) {
          this.gifs.push(loadingIndicators.pop());
        }
        resolve();
      });
    });
  }
}
