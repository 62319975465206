import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {FeedbackQuestion, StarsFeedbackComponent} from '../../components/stars-feedback/stars-feedback.component';
import {FeedbackModalComponent} from "../../components/feedback-modal/feedback-modal.component";
import {SendFeedbackComponent} from "../../components/send-feedback/send-feedback";
import {AlertController, ModalController, PopoverController, ToastController} from "@ionic/angular";
import {RadioListComponent} from "../../components/feedback-forms/radio-list/radio-list.component";
import {Subject} from "rxjs/Subject";
import {AnalyticsService} from "../analytics/analytics.service";
import {FeedbackModalEvent} from "../../shared/interfaces/feedback-modal";
import {CheckboxListComponent} from "../../components/feedback-forms/checkbox-list/checkbox-list.component";
import {FeedbackFormComponent} from "../../components/feedback-form/feedback-form.component";
import {DispatcherService} from "../dispatcher.service";
import {HashTherapist, isDev} from "../../shared/helpers/helpers";
import {logc} from "../../shared/helpers/log";

enum FeedbackModalStructure {
  Nested = "nested",
  Linear = "linear"
}

export const convoStartersList: any = {
  analytics: {
    type: "starters",
    tribe_id: 1234,
    tribes_user_id: 6969
  },
  body: [
    {
      initial: true,
      title: "How you are doing?",
      subtitle: "Bitch",
      type: "radio",
      answers: [
        { text: "Ok" },
        { text: "Bad" },
        { text: "Other" }
      ]
    }
  ]
}

export const linearCheckboxFeedbackModalList: any = {
    analytics: {
      type: "feedback",
      tribe_id: 1234,
      tribes_user_id: 6969
    },
    body: [
      {
        initial: true,
        title: "How you are doing?",
        subtitle: "Bitch",
        type: "checkbox",
        answers: [
          { emoji: "🤠", text: "Ok" },
          { image: "https://www.fillmurray.com/500/500", text: "Bad" },
          { icon: "happy-outline", text: "Other", detailed: true }
        ]
      },
      {
        title: "How much is the fish?",
        subtitle: "",
        type: "radio",
        answers: [
          { icon: "fish", text: "Very much",},
          { icon: "fish", text: "Mackerel" },
          { icon: "fish", text: "Po-tah-toe" }
        ]
      },
      {
        title: "Never gonna: ",
        subtitle: "",
        type: "checkbox",
        answers: [
          { icon: "arrow-up", text: "Give you up" },
          { icon: "arrow-down", text: "Let you down" },
          { emoji: "🏃", text: "Run around and desert you" },
          { emoji: "😭", text: "Make you cry" },
          { icon: "hand-left-outline", text: "Say goodbye" },
          { image: "https://www.film.ru/sites/default/files/persones/_imported/rD3vuaTMv185vBWUnL2DtpSLM9A.jpg", text: "Tell a lie and hurt you" }
        ]
      }
    ]
  }

export const singleQuetionTestFeedbackData: any = {
  analytics: {
    type: "feedback",
    tribe_id: 1234,
    tribes_user_id: 6969
  },
  body: [{
    title: "How much is the fish?",
    initial: true,
    subtitle: "",
    type: "radio",
    answers: [
      { icon: "fish", text: "Very much",},
      { icon: "fish", text: "Mackerel" },
      { icon: "fish", text: "Po-tah-toe" }
    ]
  }]
}

export const linearTestFeedbackData: any = {
  analytics: {
    type: "feedback",
    tribe_id: 1234,
    tribes_user_id: 6969
  },
  body: [
    {
      initial: true,
      title: "How you are doing?",
      subtitle: "",
      type: "radio",
      answers: [
        { emoji: "🤠", text: "Ok" },
        { image: "https://www.fillmurray.com/500/500", text: "Bad" },
        { icon: "happy-outline", text: "Other", detailed: true }
      ]
    },
    {
      title: "How much is the fish?",
      subtitle: "",
      type: "radio",
      answers: [
        { icon: "fish", text: "Very much",},
        { icon: "fish", text: "Mackerel" },
        { icon: "fish", text: "Po-tah-toe" }
      ]
    },
    {
      title: "Never gonna: ",
      subtitle: "",
      type: "radio",
      answers: [
        { icon: "arrow-up", text: "Give you up" },
        { icon: "arrow-down", text: "Let you down" },
        { emoji: "🏃", text: "Run around and desert you" },
        { emoji: "😭", text: "Make you cry" },
        { icon: "hand-left-outline", text: "Say goodbye" },
        { image: "https://www.film.ru/sites/default/files/persones/_imported/rD3vuaTMv185vBWUnL2DtpSLM9A.jpg", text: "Tell a lie and hurt you" }
      ]
    }
  ]
}

export const testFeedbackData: FeedbackModalEvent = {
  analytics: {
    type: "feedback",
    tribe_id: 1234,
    tribes_user_id: 6969
  },
  body: [
    {
      initial: true,
      title: "How you are doing?",
      subtitle: "",
      type: "radio",
      answers: [
        { icon: "happy-outline",
          text: "Other",
          detailed: true,
          next: {
            title: "How much is the fish?",
            subtitle: "",
            type: "radio",
            answers: [
              { icon: "fish",
                text: "Very much",
                next: {
                  title: "Never gonna: ",
                  subtitle: "",
                  type: "radio",
                  answers: [
                    { icon: "arrow-up", text: "Give you up" },
                    { icon: "arrow-down", text: "Let you down" },
                    { emoji: "🏃", text: "Run around and desert you" },
                    { emoji: "😭", text: "Make you cry" },
                    { icon: "hand-left-outline", text: "Say goodbye" },
                    { image: "https://www.film.ru/sites/default/files/persones/_imported/rD3vuaTMv185vBWUnL2DtpSLM9A.jpg", text: "Tell a lie and hurt you" },
                  ],
                  next: {
                    title: "Never gonna: ",
                    subtitle: "",
                    type: "radio",
                    answers: [
                      { icon: "arrow-up", text: "Give you up" },
                      { icon: "arrow-down", text: "Let you down" },
                      { emoji: "🏃", text: "Run around and desert you" },
                    ]
                  }
                }
              },
              { icon: "fish", text: "Mackerel" },
              { icon: "fish", text: "Po-tah-toe" },
            ]
          },
        },
        { emoji: "🤠", text: "Ok" },
        { image: "https://www.fillmurray.com/500/500", text: "Bad" },
      ]
    }
  ]
}


@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private componentRef;
  private options = { speed: 1 };
  private modalShown: boolean = false;

  constructor(private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private modalCtrl: ModalController,
              private popoverCtrl: PopoverController,
              private injector: Injector,
              private alertCtrl: AlertController,
              private toastCtrl: ToastController,
              private analyticsService: AnalyticsService,
              private dispatcherService: DispatcherService) {
    if(isDev()) {
      (window as any).feedbackService = this;    
    }
  }

  async createFeedbackModal(feedbackEvent: FeedbackModalEvent, cbs: any = {}, options = {}) {
    const { analytics, body } = feedbackEvent;
    const { onUserAction, show } = this.createFeedbackEngine(options);

    const questions = this.createNextQuestionGenerator(body);

    show(body[0]);

    let analyticsLib: any = {
      feedback: {
        initKey: "requested_feedback",
        selectedKey: "submitted_feedback"
      },
      starters: {
        initKey: "question_suggestions_viewed",
        selectedKey: "question_suggestion_used"
      },
      intros: {
        initKey: "intro_suggestions_viewed",
        selectedKey: "intro_suggestion_used"
      }
    };

    const customAnalytics = analyticsLib[analytics.type];

    this.analyticsService.trackEvent({
      key: customAnalytics.initKey,
      value: 1
    })

    onUserAction.subscribe(async ({ data: currentAnswer, role: dismissSource }) => {
      const userActions = {
        next: () => {
          this.analyticsService.trackEvent({
            key: customAnalytics.selectedKey,
            value: 1,
            ...analytics,
            ...currentAnswer.analytics
          })

          if(cbs.next) {
            cbs.next(currentAnswer);
          }

          const nextQuestion = questions.next(currentAnswer);
          if(!nextQuestion) {
            userActions.done();
          } else {
            show(nextQuestion);
          }
        },
        prev: () => show(questions.prev()),
        close: async () => {
          console.log(`- Feedback modal closed by ${ dismissSource }-`);
          if(cbs.close) {
            return cbs.close();
          }

          // const popover = await this.popupService.createPopup({
          //   popupName: "feedbackModalClosedPopup"
          // })
          // popover.onDidDismiss().then(({ data: dismiss }) =>
          //   HashTherapist({
          //     continue: () => show(questions.current()),
          //     skip: () => this.analyticsService.trackEvent({ key: "aborted_feedback", value: 1 })
          //   })[dismiss?.action]()
          // );
        },
        done: async () => {
          if(cbs.close) return;
          (await this.toastCtrl.create({ message: '👍 Feedback submitted', duration: 3000 })).present()
        }
      };

      const dismissActions = {
        gesture: () => userActions.close(),
        backdrop: () => userActions.close()
      }

      if(dismissSource) {
        dismissActions[dismissSource]();
      } else {
        userActions[currentAnswer.action]();
      }
    })
  }

  private createNextQuestionGenerator = (questions) => {
    let currentIndex = 0,
        history = [],
        structure = FeedbackService.getFeedbackQuestionsStructure(questions);

    return {
      nested: {
        next: (answer) => history.push(answer.current) && answer.next,
        current: () => history.last().next || questions[0],
        prev: () => history.pop(),
      },
      linear: {
        next: () => questions[ ++currentIndex ],
        current: () => questions[ currentIndex ],
        prev: () => questions[ --currentIndex ]
      }
    }[ structure ]
  };

  static getFeedbackQuestionsStructure(questions) {
    switch(true) {
      case questions.length == 1:
        return FeedbackModalStructure.Nested;
      default:
        return FeedbackModalStructure.Linear;
    }
  }

  async openInputAlert({ header }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const alert = await this.alertCtrl.create({
          header,
          inputs: [{ name: 'details', placeholder: 'Please tell us why...' }],
          buttons: [
            { text: 'Cancel', role: 'cancel' },
            { text: "Send", handler: (data) => resolve(data) }
          ]
        })
        await alert.present();
        const { data, role } = await alert.onDidDismiss();
        if(['backdrop', 'cancel'].includes(role)) {
          resolve({ details: "" });
        }
      } catch (e) {
        reject();
      }
    });
  }

  private getFeedbackModalInitialBreakpoint(question): number {
    const ITEM_HEIGHT = 56;
    const HEADER_HEIGHT = 76;
    const ITEM_MARGIN = 10;
    const SAFE_ZONE_HEIGHT = 10;
    const NEXT_BUTTON_MARGIN = 0;
    const itemsLength = question.answers.length;
    const totalHeight =  itemsLength * (ITEM_HEIGHT + ITEM_MARGIN) + HEADER_HEIGHT + SAFE_ZONE_HEIGHT + NEXT_BUTTON_MARGIN;
    return totalHeight / (window as any).innerHeight;
  }

  private createFeedbackEngine(options = {}) {
    const onUserAction = new Subject();
    return {
      onUserAction,
      show: (currentQuestion) => 
        this.showFeedbackModal(currentQuestion, onUserAction, options)
    }
  }

  async showFeedbackForm({ 
    context, 
    errorContext = ""
  }): Promise<any> {
    try {
      const popover = await this.popoverCtrl.create({
        component: SendFeedbackComponent,
        componentProps: { context, errorContext },
        cssClass: `popup feedback-popover z-index-35000`,
        backdropDismiss: true
      });
      await popover.present();
      return popover;
    } catch(err) {
      throw new Error(`* Showing feedback error, context: ${ context }.`);
    }
  }

  private async showFeedbackModal(currentQuestion, changesListener, options) {
    console.log("currentquestion: ", currentQuestion);
    try {
      const initialBreakpoint = options?.initialBreakpoint || this.getFeedbackModalInitialBreakpoint(currentQuestion);
      const topBreakpoint = options?.topBreakpoint || 1
      const modal = await this.modalCtrl.create({
        component: FeedbackFormComponent,
        componentProps: { currentQuestion },
        breakpoints: [ 0, initialBreakpoint, topBreakpoint ],
        cssClass: "bottom-sheet-modal",
        initialBreakpoint
      });
      await modal.present();
      modal.onDidDismiss().then(({ data, role }) => changesListener.next({ data, role }));
    } catch(e) {}
  }

  createQuestion(question: FeedbackQuestion, options = this.options): Promise<void> {
    return new Promise((resolve, reject) => {
      if(!question) return reject();
      this.componentRef = this.resolver.resolveComponentFactory(StarsFeedbackComponent).create(this.injector);

      // @Input('question')
      this.componentRef.instance.question = question;
      this.componentRef.instance.options  = options;
      this.componentRef.instance.cssClass = "absolute bottom-[16px]"
      // @Output('onClose')
      this.componentRef.instance.onClose.subscribe(() => this.clear());
      //Linking to angular
      this.appRef.attachView(this.componentRef.hostView);
      //Adding to dom
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
      resolve();
    })
  }

  createModal(stateName: string = 'leave'): Promise<any> {
    return new Promise((resolve, reject) => {
      if(this.modalShown) {
        reject('Modal is already shown');
        return;
      }

      this.componentRef = this.resolver.resolveComponentFactory(FeedbackModalComponent).create(this.injector);
      this.componentRef.instance.stateName = stateName;
      this.componentRef.instance.onClose.subscribe(() => this.hideModal());
      //Linking to angular
      this.appRef.attachView(this.componentRef.hostView);
      //Adding to dom
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);

      this.showModal();

      resolve(this.componentRef.instance);
    })
  }

  showInputAlert({ header, placeholder, buttonText }): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const alert = await this.alertCtrl.create({
          header,
          inputs: [{ name: 'input', placeholder }],
          buttons: [
            { text: 'Cancel', role: 'cancel'},
            { text: buttonText, handler: (data: any) => resolve(data.input) }
          ],
          cssClass: "z-index-35000"
        })
        await alert.present();
      } catch(e) {}
    })
  }

  showModal(): void {
    this.modalShown = true;
  }

  hideModal(): void {
    this.modalShown = false;
    this.clear();
  }

  clear(): void {
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
  }

}
