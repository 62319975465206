import { Injectable } from "@angular/core";
import { Config } from "./config/config";
import { CountdownTimerService } from "./helpers/countdown-timer.service";
import { DispatcherService } from "./dispatcher.service";
import { NavController } from "@ionic/angular";
import { UserNotificationService } from "./user-notification.service";
import { PushNotification } from "./push-notification/push-notification";
import { UtilsService } from "./utils.service";
import { ColorModeService } from "./native/color-mode.service";
import { logc } from "../shared/helpers/log";
import { TribesService } from "./data/tribes.service";
import { parseISO } from "date-fns";

@Injectable({
  providedIn: "root",
})
export class StatusServiceService {
  public statusTribeStates = [
    "forming",
    "approving",
    "inviting",
    "confirming",
    "incomplete",
    "closed",
    "expired",
    "proposed",
    "formed_replaceable",
  ];
  public statusUserStates = ["accepted", "needs_review"];
  public replaceableUsers = [];
  public states = {};

  public instructionsTribeStates = [
    "awaiting",
    "pending",
    "proposed",
    "inviting",
    "forming",
    "approving",
    "in_progress",
    "confirming",
    "incomplete",
  ];
  public instructionsUserStates = ["invited", "needs_review"];

  constructor(
    private config: Config,
    private userNotificationService: UserNotificationService,
    private navCtrl: NavController,
    private utils: UtilsService,
    private colorModeService: ColorModeService,
    private pushNotification: PushNotification,
    private timerService: CountdownTimerService,
    private tribesService: TribesService,
    private dispatcherService: DispatcherService
  ) {}

  needsStatus(tribeState, tribeUserStatus) {
    return (
      this.statusTribeStates.includes(tribeState) &&
      this.statusUserStates.includes(tribeUserStatus)
    );
  }

  needsInstructions(tribeState, tribeUserStatus) {
    return (
      this.instructionsTribeStates.includes(tribeState) &&
      this.instructionsUserStates.includes(tribeUserStatus)
    );
  }

  notificationStatusSet() {
    if (
      this.config.getFlag("codePageHiddenButtonPushed") ||
      this.config.getFlag("numberVerificationSkippedFromNotifBackupPopup")
    ) {
      return this.pushNotification.hasPermission;
    } else {
      return (
        this.userNotificationService.numberIsVerified() &&
        this.pushNotification.hasPermission
      );
    }
  }

  async notifyMe() {
    await this.userNotificationService.setNotification({
      state: { fromAwaiting: true },
    });
  }

  getInstructions(tribeUser, tribeState, tribeUsers) {
    if (!tribeUsers.length) return;

    let mates = [...tribeUsers, tribeUser];
    let tribeUserStatus = tribeUser.status;

    if (!this.needsInstructions(tribeState, tribeUserStatus)) return {};

    let defaultInstructions = {
      cssClass: "default",
      icon: "alert-circle-outline",
    };

    let notExpiredTribemates = mates.filter((m) => m.expires_at);

    let timeLeft = "";
    if (notExpiredTribemates.length) {
      timeLeft = this.getTimeLeft(notExpiredTribemates);
    }

    let standByMateName = this.getStandByMate(tribeUsers);

    let states = {
      pending: {
        invited: {
          text: "Do you want to start this group?",
          value: "-10",
        },
      },
      proposed: {
        needs_review: {
          text: "Confirm this group or Pass for more options.",
        },
      },
      inviting: {
        invited: {
          text: `Join this free group before it expires.`,
          timeLeft: timeLeft,
        },
      },
      forming: {
        invited: {
          text: `Join this free group before it expires.`,
          timeLeft: timeLeft,
        },
      },
      confirming: {
        needs_review: {
          text: `Do you want to approve ${standByMateName}?`,
        },
      },
      approving: {
        invited: {
          text: `Join this free group before it expires.`,
          timeLeft: timeLeft,
        },
        needs_review: {
          text: `Do you want to approve ${standByMateName}?`,
        },
      },
      in_progress: {
        invited: {
          text: "Select a user to be a part of this group.",
        },
      },
      incomplete: {
        invited: {
          text: `Join this free group before it expires.`,
          timeLeft: timeLeft,
        },
      },
      awaiting: {
        invited: {
          text: "This group isn’t ready to be started yet",
        },
      },
    };

    if (!states[tribeState][tribeUserStatus]) return {};

    let instructions = Object.assign(
      defaultInstructions,
      states[tribeState][tribeUserStatus]
    );
    return instructions;
  }

  prepareAdditionalStates(info) {
    console.log("prepareAdditionalStates info: ", info);
    const isDarkMode = this.colorModeService.isDark();
    this.states = {
      ...this.states,
      formed_replaceable: {
        accepted: {
          text: info.text,
          timeLeft: "See options",
          icon: "person-remove",
          cssClass: "bg-tertiary-light-1 text-tertiary-dark-3",
          action: () => this.showReplaceablePopup(info),
        },
        needs_review: {
          text: info.text,
          timeLeft: "See options",
          icon: "person-remove",
          cssClass: "bg-tertiary-light-1 text-tertiary-dark-3",
          action: () => this.showReplaceablePopup(info),
        },
      },
    };
  }

  getAwaitingStatus() {
    const isDarkMode = this.colorModeService.isDark();
    let text = "",
      icon = "",
      cssClass = "",
      timeLeft = "",
      action = () => {};

    if (this.notificationStatusSet()) {
      text = "We’ll notify you when it's ready";
      icon = "notifications";
      cssClass = isDarkMode ? "green-dark" : "green";
      timeLeft = "Manage";
      action = () =>
        this.navCtrl.navigateForward("notifications/new-tribe-invites");
    } else {
      text = "Get notified when the group is ready";
      icon = "alert-circle-outline";
      cssClass = isDarkMode ? "yellow-dark" : "yellow";
      timeLeft = "Notify Me";
      action = () => this.notifyMe();
    }

    return { text, icon, cssClass, timeLeft, action };
  }

  getStatus(tribe) {
    return new Promise((resolve, reject) => {
      let isDarkMode = this.colorModeService.isDark();
      let tribeId = tribe.id,
        tribeState = tribe.status,
        tribeUser = tribe.tribe_user,
        tribeUsers = tribe.users;

      let isAwaiting = tribe.status === "awaiting";

      if (isAwaiting) {
        return resolve(this.getAwaitingStatus());
      }

      // if (!tribeUsers.length && tribeState != 'closed') return;
      let mates = [...tribeUsers, tribeUser];
      let tribeUserStatus = tribeUser.status;
      let text = "";

      this.replaceableUsers = mates
        .filter((mate) => mate.replaceable)
        .filter((mate) => mate.id != tribeUser.id);

      // if(this.replaceableUsers.length && tribe.status !== 'expired') {
      //   console.log("Preparing additional state tribeId: ", tribe.id);
      //   tribeState = 'formed_replaceable';
      //   text = this.replaceableUsers
      //     .map(u => u.first_name)
      //     .join(' & ') + `${ this.replaceableUsers.length > 1 ? ' are' : ' is' } unreachable`;
      //   this.prepareAdditionalStates({
      //     text: text,
      //     users: this.replaceableUsers,
      //     tribeId: tribe.id
      //   });
      // }

      if (!this.needsStatus(tribeState, tribeUserStatus)) {
        return resolve({});
      }

      let standByMateName = this.getStandByMate(tribeUsers);

      let defaultStatus = {
        cssClass: this.utils.getExpirationColors(
          tribeUsers.find((u) => u?.expires_at)
        ),
      };

      let notExpiredTribemates = mates.filter(
        (m) => m.id != tribeUser.id && m.expires_at
      );
      let timeLeft = this.getTimeLeft(notExpiredTribemates);
      let [needsReviewName, invitedName] = this.getNames(mates);

      let states = {
        ...this.states,
        inviting: {
          accepted: {
            text: `Invited others to join. `,
            timeLeft: timeLeft,
            icon: "timer-outline",
          },
        },
        forming: {
          accepted: {
            text: `Invited ${invitedName} to join. `,
            timeLeft: timeLeft,
            icon: "timer-outline",
          },
        },
        confirming: {
          accepted: {
            text: `Waiting for ${needsReviewName} to approve ${standByMateName}.`,
            icon: "timer-outline",
          },
          needs_review: {
            text: `You have a new member to approve`,
            icon: "waving-hand",
            cssClass: "secondary-gradient",
            iconRight: {
              name: "chevron-forward",
              cssClass: "",
            },
          },
        },
        approving: {
          accepted: {
            text: `Waiting for ${needsReviewName} to confirm the invite`,
            icon: "alert-circle-outline",
          },
          needs_review: {
            text: `You have a new member to approve`,
            cssClass: "secondary-gradient",
            icon: "waving-hand",
            iconRight: {
              name: "chevron-forward",
              cssClass: "",
            },
          },
        },
        proposed: {
          needs_review: {
            text: "Two new members need your approval.",
            icon: "waving-hand",
            cssClass: "secondary-gradient",
            timeLeft: "View",
          },
        },
        incomplete: {
          accepted: {
            text: "This group is incomplete",
            timeLeft: "Find a new match",
            cssClass: this.colorModeService.isDark() ? "blue-dark" : "blue",
            icon: "alert-circle-outline",
            action: () =>
              this.navCtrl.navigateForward(
                `tribes/${tribeId}/highlights?from_chat=true`
              ),
          },
        },
        closed: {
          accepted: {
            text: "This group is closed.",
            timeLeft: "Leave group",
            cssClass: "bg-error-light-1 text-error-dark-3",
            icon: "alert-circle-outline",
            action: () => this.leaveTribe(tribeId),
          },
          invited: {
            text: "This group is closed.",
            timeLeft: "Leave group",
            cssClass: "bg-error-light-1 text-error-dark-3",
            icon: "alert-circle-outline",
            action: () => this.leaveTribe(tribeId),
          },
        },
        expired: {
          accepted: {
            text: "This group expired.",
            timeLeft: "Leave group",
            cssClass: "bg-error-light-1 text-error-dark-3",
            icon: "timer-outline",
            action: () => this.leaveAndTryNew(tribeId),
          },
        },
      };

      logc.orange("status: ", states[tribeState][tribeUserStatus]);
      logc.orange("tribeState: ", tribeState);
      logc.orange("tribeUserStatus: ", tribeUserStatus);
      let status = Object.assign(
        defaultStatus,
        states[tribeState][tribeUserStatus]
      );
      console.log("--- StatusServiceService status: ", status);
      resolve(status);
    });
  }

  async getRemovalStatuses({ id, users, data, tribe_user }): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const isDarkMode = this.colorModeService.isDark();
      let activeUsers = users.map((user) => user.id);
      let usersLib = {},
        statuses = [],
        removalRequests = Object.entries(data.removal_requests)
          .map((set) => set.map((e) => +e))
          .filter((idPair) => idPair.every((id) => activeUsers.includes(id)));

      users.forEach((user) => (usersLib[user.id] = user.first_name));

      for (let [requesterId, removeeId] of removalRequests) {
        statuses.push({
          authorId: requesterId,
          removeeId: removeeId,
          text: `${usersLib[requesterId]} wants to remove ${usersLib[removeeId]}`,
          timeLeft: "View",
          cssClass: "bg-tertiary-light-1 text-tertiary-dark-3",
          icon: "person-remove",
          action: () =>
            this.showUserRemovalPopup({
              otherUser: tribe_user,
              requester: users.find((user) => user.id === +requesterId),
              removee: users.find((user) => user.id === +removeeId),
              tribeId: id,
            }),
        });
      }
      console.log("--- removal statuses: ", statuses);
      resolve(statuses);
    });
  }

  leaveTribe(tribeId) {
    this.dispatcherService.leavingTribeSource.next(tribeId);
  }

  async swap(id) {
    await this.tribesService.startSearchingSwapFlow({ id });
  }

  leaveAndTryNew(id) {
    this.dispatcherService.expiredTribeLeftSource.next(id);
  }

  showReplaceablePopup({ users, tribeId }) {
    const popupName =
      users.length > 1 ? "multipleReplaceablePopup" : "singleReplaceablePopup";
    let data: any = {
      popupName,
      options: { tribeId },
    };

    if (users.length === 1) {
      data.options.replaceableUser = users[0];
    }
    this.dispatcherService.newPopupSource.next(data);
  }

  showUserRemovalPopup({ tribeId, requester, removee, otherUser }) {
    // console.log('--- status.service.ts -> showUserRemovalPopup() info:', tribeId, requester, removee, otherUser );
    this.dispatcherService.newPopupSource.next({
      popupName: "removeUserPopup",
      options: {
        requester: requester,
        removee: removee,
        otherUser: otherUser,
        tribeId: tribeId,
      },
    });
  }

  getTimeLeft(others) {
    let shorterDate = null;

    others = others.filter((mate) => mate.status == "invited");
    if (!others.length) return;

    shorterDate = others
      .map((user) => user.expires_at)
      .filter((date) => new Date(parseISO(date)).getTime() > 0)
      .sort((a, b) => a > b)[0];

    logc.crimson("shorterDate: ", shorterDate);

    // if (others.length == 2) {
    //   let date = others[0].expires_at;
    //   shorterDate = new Date(date);
    //   others.forEach(mate => {
    //     const currentDate = new Date(mate.expires_at);
    //     if (currentDate < shorterDate)
    //       shorterDate = currentDate;
    //   });
    // } else {
    //   shorterDate = new Date(others[0].expires_at);
    // }
    let genericTime = this.timerService.getGenericTime(shorterDate);
    logc.crimson("genericTime: ", genericTime);
    let timeLeft = `${genericTime.days * 24 + genericTime.hours}h ${
      genericTime.minutes
    }m`;
    return timeLeft;
  }

  getNames(mates) {
    let invitedName,
      needsReviewName,
      replaceableMateName = "";
    let needsReviewMate = mates.find((mate) => mate.status == "needs_review");
    let invitedMate = mates.find((mate) => mate.status == "invited");
    let replaceableMate = mates.find((mate) => mate.replaceable == true);

    if (needsReviewMate) needsReviewName = needsReviewMate.first_name;
    if (invitedMate) invitedName = invitedMate.first_name;
    if (replaceableMate) replaceableMateName = replaceableMate.first_name;

    return [needsReviewName, invitedName, replaceableMateName];
  }

  getStandByMate(tribeUsers) {
    let standByMateName = "";
    let standByMate = tribeUsers.find((m) => m.status == "standby");

    if (standByMate) {
      standByMateName = standByMate.first_name;
    }
    return standByMateName;
  }
}
