import { Component } from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {Config} from '../../services/config/config';
import {UserService} from '../../services/data/user.service';

@Component({
  selector: 'page-gender-change',
  templateUrl: 'gender-change.page.html',
  styleUrls: ['gender-change.page.scss']
})
export class GenderChangePage {

  public gender: string = 'male';
  public icon;

  constructor(public popoverCtrl: PopoverController,
              public config: Config,
              public userService: UserService) {
    this.gender = this.config.get('gender');
    this.icon = this.gender == 'male' ? './assets/img/guys.png' : './assets/img/ladies.png';
  }

  async adjustPreferences() {
    let matchPreferences = this.config.get('matchPreferences');
    matchPreferences.gender = this.gender;
    await this.userService.updateProfile({ matchPreferences: matchPreferences })
    this.closePopup();
  }

  closePopup() {
    this.config.setFlag('genderPreferenceChangeAsked', true);
    this.popoverCtrl.dismiss();
  }

}
