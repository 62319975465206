import {Component, OnInit} from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';
import {MatchesService} from "../../services/matches.service";

@Component({
  selector: 'app-matches-options',
  templateUrl: './matches-options.component.html',
  styleUrls: ['./matches-options.component.scss']
})
export class MatchesOptionsComponent implements OnInit {

  public userId;
  public section;

  constructor(public popoverCtrl: PopoverController,
              public navParams: NavParams,
              private matchesService: MatchesService) {
    this.userId = this.navParams.get('userId');
    this.section = this.navParams.get('section');
  }

  ngOnInit() {
  }

  sendAction(action) {
    this.matchesService.cardOptionsSelected.next({
      action: action,
      id: this.userId,
      section: this.section
    });
    this.popoverCtrl.dismiss();
  }

  notInterested() {
    this.sendAction('not_interested');
  }

  block() {
    this.sendAction('block');
  }

  report() {
    this.sendAction('report');
  }

}
