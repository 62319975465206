import { NgModule } from '@angular/core';
import { IntegerPipe } from './integer.pipe';
import { StatusPipe } from './status.pipe';
import { UsernamePipe } from "./username.pipe";
import {DistancePipe} from './distance.pipe';
import {FirstnamePipe} from './firstname.pipe';
import {GenderPipe} from "./gender.pipe";
import {MatchingStatusPipe} from "./matching-status.pipe";
import {UserImagePipe} from "./userImage.pipe";
import {TribeQualityPipe} from "./tribe-quality.pipe";
import {ReplyContentPipe} from "./reply-content.pipe";
import {MapPipe} from "./map.pipe";
import {TribeLastMessagePipe} from "./tribe-last-message.pipe";
import {OtherTribeUserPipe} from "./otherTribeUser.pipe";
import {CallbackPipe} from "./callback.pipe";
import {NameFilterPipe} from "./name-filter.pipe";
import {HighlightPipe} from "./highlight.pipe";
import {TextFormatTribeStatusPipe} from "./text-format-tribe-status.pipe";
import {ExpirationColorsPipe} from "./expiration-colors.pipe";
import {ClearImageUrlPipe} from "./clear-image-url.pipe";
import {ImageForPipe} from "./image-for.pipe";
import {GetTribemateNamePipe} from "./get-tribemate-name.pipe";
import {SafePipe} from "./safe.pipe";
import {CleanFromPipe} from "./clean-from.pipe";
import {IsLastMessageOfGroupPipe} from "./chat/is-last-message-of-group.pipe";
import {IsFirstMessageOfGroupPipe} from "./chat/is-first-message-of-group.pipe";
import {NamesOfPipe} from "./chat/names-of.pipe";
import { ContainsMedia } from './contains-media.pipe';

@NgModule({
	declarations: [
	  IntegerPipe, UsernamePipe, StatusPipe, DistancePipe,
    FirstnamePipe, GenderPipe, MatchingStatusPipe,
    UserImagePipe, TribeQualityPipe, ReplyContentPipe,
    MapPipe, TribeLastMessagePipe, OtherTribeUserPipe,
    CallbackPipe, NameFilterPipe, HighlightPipe,
    TextFormatTribeStatusPipe, ExpirationColorsPipe,
    ClearImageUrlPipe, ImageForPipe, GetTribemateNamePipe,
    SafePipe, CleanFromPipe, IsLastMessageOfGroupPipe,
    IsFirstMessageOfGroupPipe, NamesOfPipe, ContainsMedia
  ],
	imports: [],
	exports: [
	  IntegerPipe, UsernamePipe, StatusPipe, DistancePipe,
    FirstnamePipe, GenderPipe, MatchingStatusPipe,
    UserImagePipe, TribeQualityPipe, ReplyContentPipe,
    MapPipe, TribeLastMessagePipe, OtherTribeUserPipe,
    CallbackPipe, NameFilterPipe, TextFormatTribeStatusPipe,
    ExpirationColorsPipe, HighlightPipe, ClearImageUrlPipe,
    ImageForPipe, GetTribemateNamePipe, SafePipe, CleanFromPipe,
    IsLastMessageOfGroupPipe, IsFirstMessageOfGroupPipe,
    NamesOfPipe, ContainsMedia
  ]
})
export class PipesModule {}
