import { Component, HostBinding, Injectable, Renderer2 } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from "rxjs/Subject";
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

@Component({
  selector: 'chat-image',
  template: `<img (click) = "click()" 
                  [style.height.px] = "height"
                  src="{{src}}"/>`,
  styleUrls: ['chat-image.scss'],

})
@Injectable()
export class ChatImage {
  private doneLoadingSource = new Subject();
  public height: Number = 1;
  public opacity: Number = 0;
  public img = new Image();
  public src: string = "";
  public url: string = "";
  public onDoneLoading: any;

  constructor(public modalCtrl: ModalController){
    this.onDoneLoading = this.doneLoadingSource.asObservable();
  }

  onDoneLoadingImage= () => {
    setTimeout( _ => {
      this.height = this.img.naturalHeight > this.img.naturalWidth ? 300 : 150;
      this.doneLoadingSource.next(this.height);
      this.opacity = 1;
    } );
  };

  public updateData(data: any) {
    this.src = data.src;
    this.url = data.url;
    this.img.src = data.url;
    this.img.addEventListener("load", this.onDoneLoadingImage, false);
  }

  getHeight() {
    return this.height;
  }

  ngOnDestroy() {
    this.img.removeEventListener("load", this.onDoneLoadingImage, false);
  }

  async click() {
    this.url = this.url.replace('%40', '@');
    let modal = await this.modalCtrl.create({
      component: ImageViewerComponent,
      componentProps: {
        url: this.url
      },
      showBackdrop: false,
    });
    modal.present();
  }
}

