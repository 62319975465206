import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'isLastMessageOfGroup'
})
export class IsLastMessageOfGroupPipe implements PipeTransform {

  transform(message: any, messages: any[]): boolean {
    if(!message || !messages) return false;
    const messageIndex = messages.findIndex(msg => msg.id == message?.id);
    return ((message?.user_id != messages[messageIndex + 1]?.user_id) && message?.user_id > 0) || message?.user_id <= -5;
  }
}
