import {Component, Input, OnChanges} from "@angular/core";
import {TribesService} from "../../services/data/tribes.service";
import {isEmpty} from "../../shared/helpers/lodash";
import {DUMMY_TRIBE} from "../../shared/helpers/helpers";
import {D3AnimationsService} from "../../services/helpers/d3-animations.service";
import {Config} from "../../services/config/config";
import {logc} from "../../shared/helpers/log";

@Component({
  selector: "tribe-avatar",
  template: `
    <div class = "tribe-avatar">
      <div class = "image">
        <div *ngIf = "tribe.users && tribe.users[0]" class = "initiator">
          <div id = "progress-meter-{{tribe.id}}-{{tribe.users[0]?.id}}"></div>
          <img *ngIf = "tribe.users[0].picture"
               class = "initiator-image"
               [src] = "tribe.users[0]?.picture || defaultPicture"
               (error) = "tribe.users[0].picture = defaultPicture"
               alt = "Initiator">
        </div>
        <div *ngIf = "tribe.users && tribe.users[1]" class = "u2">
          <ng-lottie *ngIf = "tribe?.type == 'fans' && tribe.users[1]?.picture == defaultPicture"
                     class = "search-ongoing-animation"
                     [options] = "lottieSearchOngoing"
                     [width] = "circleDiameter + 'px'"
                     [height] = "circleDiameter + 'px'"
                     (animationCreated) = "handleAnimation($event)">
          </ng-lottie>
          <div id="progress-meter-{{tribe.id}}-{{tribe.users[1]?.id}}"></div>
          <img *ngIf = "tribe.users[1].picture"
               [src] = "tribe.users[1]?.picture || defaultPicture"
               [class.expired] = "tribe.users[1]?.status === 'expired'"
               (error) = "tribe.users[1].picture = defaultPicture"
               alt = "U2">
        </div>
        <div *ngIf = "tribe.users && tribe.users[2]" class = "u3">
          <ng-lottie *ngIf = "tribe?.type == 'fans'"
                     class = "search-ongoing-animation"
                     [options] = "lottieSearchOngoing"
                     [width] = "circleDiameter + 'px'"
                     [height] = "circleDiameter + 'px'"
                     (animationCreated) = "handleAnimation($event)">
          </ng-lottie>
          <div id="progress-meter-{{tribe.id}}-{{tribe.users[2]?.id}}"></div>
          <img *ngIf = "tribe.users[2].picture" 
               [src] = "tribe.users[2]?.picture || defaultPicture"
               [class.expired] = "tribe.users[2]?.status === 'expired'"
               (error) = "tribe.users[2].picture = defaultPicture"
               alt = "U3">
        </div>
      </div>
    </div>
  `,
  styleUrls: ["tribe-avatar.component.scss"]
})
export class TribeAvatarComponent implements OnChanges {
  public defaultPicture: string = '';
  @Input("tribe") tribe: any = {
    users: [
      { picture: this.defaultPicture }
    ]
  };

  public lottieSearchOngoing: any = {
    path: "./assets/gif/search_ongoing.json",
    renderer: 'canvas',
    autoplay: true,
    loop: true
  }
  public circleDiameter: number = 45;

  constructor(private tribesService: TribesService,
              private config: Config,
              private animationService: D3AnimationsService) {}

  ngOnInit() {
    // logc.info("tribe avatar: ", this.tribe);
    this.defaultPicture = this.config.getDefaultAvatar();
    if(isEmpty(this.tribe)) {
      this.tribe = DUMMY_TRIBE;
      return;
    }
    this.formatTribe();
  }

  handleAnimation(anim) {
    anim.setSpeed(0.5);
    anim.play();
  }

  formatTribe() {
    this.tribe?.users?.map((user) => user.picture = user.picture || this.defaultPicture);
    if(this.tribesService.isChat(this.tribe)) {
      if(this.tribe.users.length == 0) {
        this.tribe.users.push(this.tribe.tribe_user);
      }
      if(this.tribe.users.length == 2) {
        this.tribe.users = this.tribe.users.filter(user => user.id != this.tribe.tribe_user.id);
        // this.tribe.users[0] = this.tribe.users[1];
        // this.tribe.users.pop();
      }
    }
  }

  ngOnChanges(changes) {
    this.drawProgressBars();
    this.formatTribe();
  }

  drawProgressBars() {
    if(isEmpty(this.tribe) || this.tribesService.isChat(this.tribe)) return;

    this.tribe?.users
      .filter(user => user.status == 'invited')
      .forEach((user) => {
        this.animationService.createProgressBar({
          tribeId: this.tribe.id,
          user: user
        });
      })
  }
}
