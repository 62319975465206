import { Component } from '@angular/core';
import {NavController, NavParams, PopoverController} from '@ionic/angular';
import { UtilsService } from '../../../services/utils.service';
import { ApiService } from '../../../services/data/api.service';
import {PictureStatus, ProfilePictureService} from '../../../services/profile-picture.service';
import { UserService } from '../../../services/data/user.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { Config } from '../../../services/config/config';
import { CrashReportService } from '../../../services/performance/crash-report.service';
import { VisionService } from '../../../services/vision.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PopupService} from "../../../services/popups/popup.service";
import {logc} from "../../../shared/helpers/log";
import { FeedbackService } from 'src/app/services/popups/feedback.service';

export enum PictureRejectionReasons {
  Serious = "serious",
  Seductive = "seductive",
  Zoom = "zoom",
  SunGlasses = "sun_glasses",
  Unclear = "unclear",
  BadFilters = "bad_filters",
  FaceCovered = "face_covered"
}

@Component({
  selector: 'cannot-detect-face-page',
  templateUrl: 'cannot_detect_face.page.html',
  styleUrls: ['cannot_detect_face.page.scss'],
  host: {'class': 'transparent-header'},
})
export class CannotDetectFacePage {
  public profile = null;
  public image = "";
  public isRawData = false;
  public routeState;
  public backPage;

  public states = {
    face_covered: {
      title: "We can't see your face",
      desc: "Your group members need to be able to see who they’re chatting with.",
      primaryButtonText: "Select a Different Photo",
      secondaryButtonText: "Is this necessary?",
      secondaryAction: () => this.popupService.createPopup({ popupName: 'trustUsPopup' })
    },
    serious: {
      title: "Why so serious?",
      desc: "Try picking one where you’re smiling or laughing. It’s best to show you don’t take yourself too seriously. 😜",
      primaryButtonText: "Select a different photo",
      secondaryButtonText: "Skip",
      secondaryAction: async () => {
        const popover = await this.popupService.createPopup({ popupName: 'trustUsPopup' })
        popover.onDidDismiss().then(({ data, role }) => {
          if(role == 'backdrop') return;
          if(data?.ignored) {
            this.navCtrl.navigateBack(this.backPage || "tabs/tribes");
          }
        })
      }
    },
    seductive: {
      title: "You look a bit <i>too good</i> here",
      desc: "Please pick another one that doesn’t look like it belongs on a dating app. Try one where you’re smiling or laughing. ",
      primaryButtonText: "Select a Different Photo",
      secondaryButtonText: "Is this necessary?",
      secondaryAction: () => this.popupService.createPopup({ popupName: 'trustUsPopup' })
    },
    zoom: {
      title: "Let’s zoom out a bit, ok?",
      desc: "After selecting the photo, pinch the screen to zoom out.",
      primaryButtonText: "Select a Different Photo",
      secondaryButtonText: "Is this necessary?",
      secondaryAction: () => this.popupService.createPopup({ popupName: 'trustUsPopup' })
    },
    sun_glasses: {
      title: "You look just a bit <i>too good</i> here 😎",
      desc: "Please pick another one without sunglasses. Your group members need to be able to see who they’re chatting with. ",
      primaryButtonText: "Select a Different Photo",
      secondaryButtonText: "Is this necessary?",
      secondaryAction: () => this.popupService.createPopup({ popupName: 'trustUsPopup' })
    },
    unclear: {
      title: "It’s hard to see you clearly, don’t you think?",
      desc: "Please pick another one. Your group members need to clearly see who they’re chatting with. ",
      primaryButtonText: "Select a Different Photo",
      secondaryButtonText: "Is this necessary?",
      secondaryAction: () => this.popupService.createPopup({ popupName: 'trustUsPopup' })
    },
    bad_filters: {
      title: "Keep the filters for later",
      desc: "Please pick another one without filters. Your group members need to clearly see who they’re chatting with. ",
      primaryButtonText: "Select a Different Photo",
      secondaryButtonText: "Is this necessary?",
      secondaryAction: () => this.popupService.createPopup({ popupName: 'trustUsPopup' })
    },
    noFace: {
      title: "We can’t see your face",
      desc: "Your group members need to be able to see who they’re chatting with.",
      primaryButtonText: "Select a Different Photo"
    },
    group: {
      title: "We can’t tell which one is you.",
      desc: "Instead of making your groups figure it out, please pick one where it’s just you.",
      primaryButtonText: "Select a Different Photo"
    },
    risky: {
      title: "We’re not quite sure about this one.",
      desc: "The best ones are clear, friendly, and tame pictures of you smiling or laughing.",
      primaryButtonText: "Select a Different Photo"
    },
    stolen: {
      title: "Is that really you?",
      desc: "Your group members need to be able to see the REAL you. Remember, We3 profiles aren’t public.",
      primaryButtonText: "Select a Different Photo"
    },
    badResponse: {
      title: "We ran into an issue",
      desc: "To keep We3 safe, we inspect photos for nudity, etc. But we’re struggling to process photos at this moment. Please try again in 3-5 min.",
      primaryButtonText: "Try Again",
      secondaryButtonText: "Contact Support",
      secondaryAction: () => this.contactSupport()
    }
  };

  public state: any = this.states['badResponse'];
  public isDev: boolean = false;

  constructor(public navCtrl: NavController, 
              public utils: UtilsService,
              public api: ApiService,
              private feedbackService: FeedbackService,
              public analyticsService: AnalyticsService,
              public profilePictureService: ProfilePictureService,
              public userService: UserService,
              public crashReportService: CrashReportService, 
              public vision: VisionService,
              public config: Config,
              private popoverCtrl: PopoverController,
              public router: Router,
              public route: ActivatedRoute,
              private popupService: PopupService) {

    this.route.queryParams.subscribe((event) => {
      this.routeState = this.router.getCurrentNavigation().extras.state;
      if(this.routeState) {
        this.backPage = this.routeState.backPage;
        if(this.routeState.pageState) {
          this.setState(this.routeState.pageState);
        }
        logc.blue("Route page state: ", this.routeState);
      }
    });

    this.isDev = this.config.isDev;
  }

  ionViewWillEnter() {
    const data = this.profilePictureService.currentData,
          urlState = this.route.snapshot.paramMap.get('state');

    this.profilePictureService.currentData = null;
    this.onData(data);

    if(this.config.get("pictureRejectionReason")) {
      this.setState(this.config.get("pictureRejectionReason"));
    }

    if(this.vision.denialReason) {
      this.setState(this.vision.denialReason);
    }

    if(urlState) {
      logc.info("** Cannot detect face state **: ", urlState);
      this.setState(urlState);
    }
  }

  ionViewWillLeave() {
    this.vision.rejectedPicture = null;
    this.vision.denialReason = null;
  }

  ngOnInit() {
    let data = this.profilePictureService.currentData;
    this.profilePictureService.currentData = null;

    this.onData(data);
  }

  async contactSupport() {
    this.feedbackService.showFeedbackForm({
      context: "Google Vision Error",
      errorContext: 'cant detect face error'
    })
  }

  setState( state ) {
    this.state = this.states[ state ];
  }

  onData(data) {
    let urlReg = /(http:|https:)+[^\s]+[\w]/;
    let temporaryPicture = this.config.get('cachedPicture');
    this.isRawData = data && data.match(urlReg) === null;

    if(this.vision.rejectedPicture) {
      this.image = `data:image/jpeg;base64,${this.vision.rejectedPicture}`;
      return;
    }

    if(this.isRawData)
      data = `data:image/jpeg;base64,${data}`;

    if(temporaryPicture){
      data = temporaryPicture;
      this.isRawData = true;
    }

    if(!data && !temporaryPicture)
      data = this.userService.getProfile().picture;

    if(!data || data.includes('default-avatar')) {
      data = this.config.getDefaultAvatar();
    }

    this.image = data;
  }

  selectDifferentPhoto(){
    if(!this.api.isOnline){
      this.analyticsService.trackEvent({
        key: 'pic_error',
        reason: 'offline',
        page: location.pathname,
        value: 1
      });
      this.utils.showGenericError({
        msg: "Please connect to the Internet first, so we can save your profile.",
        key: "cannot_detect_face_offline"
      });
      this.navCtrl.pop();
      return;
    } 

    this.profilePictureService
      .showUploadMediaPicker({ originPath: '/picture/cannot_detect_face' })
      .then(async (data:any) => {
        if(data.hasSmile || !this.config.getFlag("opening_is_done")) {
          await this.navCtrl.navigateBack(this.backPage || "tabs/profile");
        } else {
          this.state = this.states['serious'];
        }
        
        setTimeout(() => {
          this.utils.showGenericMessage("👍 face detected.");
        }, 500);

        const url = data.url;
        if(url) {
          this.userService.updateProfile({
            picture: url,
            pictureStatus: data.hasSmile ? PictureStatus.Verified : PictureStatus.OnHold,
            pictureGender: data.gender,
            smile: data.hasSmile,
          }).then(profile => {
              //showUploadMedia now validates picture
              if(data.hasSmile) {
                this.analyticsService.trackEvent({ key: 'big_smile_set', value: 1 });
              }
            },
            err => {
              this.analyticsService.trackEvent({
                key: 'pic_error',
                reason: 'we3_api_upload',
                value: 1,
                details: JSON.stringify(err)
              });
              this.utils.errorContext = JSON.stringify(err);
              this.utils.showGenericError({
                key: "cannot_detect_face_saving_profile"
              });
            });
          }
        }, err => {
          const getError = e => typeof e != 'object' ? e : { ...e }
          let trackedErr = getError( err );

          delete trackedErr.data;
          this.analyticsService.trackEvent({
            key: 'pic_error',
            reason: 'catchall',
            page: location.pathname,
            value: 1,
            code: trackedErr?.code,
            message: trackedErr?.message,
            details: JSON.stringify(trackedErr)
          });

          console.log('-- photo err: ', trackedErr);
          this.state = this.states[err.reason] || this.state;

          if(err.data && err.code && err.code === 3) {
            this.image = `data:image/jpeg;base64,${err.data}`;
            this.utils.showGenericError({
              msg: "We couldn't detect your face, try choosing another photo",
            });
            this.isRawData = true;
          } else {
            this.utils.errorContext = 'Face detection, error: ' + JSON.stringify(err);
            this.utils.showGenericError({});
            this.crashReportService.logException(err);
          }
        });
  }

  dismiss(){
    this.navCtrl.pop();
  }

  navBack(){
    this.navCtrl.pop();
  }

}
