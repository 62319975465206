import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {PushNotification} from "../push-notification/push-notification";
import {AlertController} from "@ionic/angular";
import {Config} from "../config/config";
import {UtilsService} from "../utils.service";
import { isIosPWA } from 'src/app/shared/helpers/helpers';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private isPWA: boolean = false;

  private componentRef: any;
  private alertNotificationShown: any;

  private pushOptions = {
    header: 'Get notified of new messages.',
    cssClass: 'push-alert',
    buttons: []
  };
  private pushButtons = {
    pwa: [
      { text: 'Dismiss', role: 'cancel' },
      { text: 'Use the app', handler: () => this.utils.openBranchLink() }
    ],
    app: [
      { text: 'Cancel', role: 'cancel' },
      { text: 'Turn On Notifications', handler: () => this.pushService.requestPushPermissionAndInit() }
    ]
  };

  private webPushButtons = [
    { text: "Dismiss", role: 'cancel'},
    { text: "Get the app", handler: () => this.pushService.requestPushPermissionAndInit()}
  ];

  constructor(private pushService: PushNotification,
              private alertCtrl: AlertController,
              private config: Config,
              private utils: UtilsService,
              private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
    this.isPWA = this.config.isPWA;
    this.setOptions();
  }

  setOptions(): void {
    this.setPushAlertButtons();
  }

  async setPushAlertButtons(): Promise<void> {
    if(this.pushService.webPushesSupported() || !this.isPWA) {
      this.pushOptions.buttons = this.pushButtons.app;
    } else {
      this.pushOptions.buttons = this.pushButtons.pwa;
    }
  }

  async openPushAlert(): Promise<any> {
    if(isIosPWA()) return;
    this.setOptions();
    return this.createAlert(this.pushOptions);
  }

  async openFeedbackAlert({ header }) {
    await this.createAlert({
      header,
      inputs: [
        { name: 'reason', placeholder: 'Please tell us why...' },
      ],
    })
  }

  async openWebPushAlert() {
    if(!this.utils.isMobile()) return;

    const webPushPermission = await this.pushService.getWebPushPermissionStatus();
    const alerts = {
      default: () => this.utils.showNotificationsNeededAlert(),
      granted: async () => {
        await this.createAlert({
          header: 'Get the full app',
          message: "You don't have access to all of We3's features from the browser. You'll remain logged in after downloading We3.",
          buttons: [
            { text: "Dismiss", role: 'cancel'},
            { text: "Get the app", handler: () => this.utils.openBranchLink() }
          ]
        })
      },
      denied: async () => await this.createAlert({
        header: "Get notified of new messages",
        buttons: this.webPushButtons
      })
    };
    alerts[webPushPermission]();
  }

  async createAlert(options) {
    if(!!await this.alertCtrl.getTop()) return;
    try {
      const alert = await this.alertCtrl.create({ ...options, ...{ cssClass: 'z-index-50000' }});
      await alert.present();
    } catch ( err ) {
      console.log( err );
    }
  }

  prepareInputs(inputs: any): void {
    for(const [key, value] of Object.entries(inputs)) {
      this.componentRef.instance[key] = value;
    }
  }

  createAlertNotification(inputs: any, alertClass): Promise<any> {
    return new Promise((resolve, reject) => {
      if(this.alertNotificationShown) {
        reject(new Error('Stop it boi modal is already shown'));
        return;
      }

      this.componentRef = this.resolver.resolveComponentFactory(alertClass).create(this.injector);
      this.prepareInputs(inputs);

      this.componentRef.instance.onClose.subscribe(() => this.closeAlertNotification());
      //Linking to angular
      this.appRef.attachView(this.componentRef.hostView);
      //Adding to dom
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);

      this.showAlertNotification();

      resolve(this.componentRef.instance);

    });
  }

  showAlertNotification(): void {
    this.alertNotificationShown = true;
  }

  closeAlertNotification(): void {
    this.alertNotificationShown = false;
    this.clearAlertNotification();
  }

  clearAlertNotification(): void {
    //Unlinking from angular
    this.appRef.detachView(this.componentRef.hostView);
    //clearing from memory
    this.componentRef.destroy();
  }

}
