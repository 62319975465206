import { Injectable } from '@angular/core';
import {Storage} from "@ionic/storage";
import {DispatcherService} from "../dispatcher.service";
import {Subject} from "rxjs";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {Platform} from "@ionic/angular";
import {ThemeDetection} from "@ionic-native/theme-detection/ngx";
import {environment} from "../../../environments/environment";
import {logc} from "../../shared/helpers/log";

/* Service responsible for defining the theme dark mode or light mode*/
@Injectable({
  providedIn: 'root'
})
export class ColorModeService {
  private modeChangedSource = new Subject();
  public onModeChanged: any;

  private modes: string[] = ['light', 'dark'];
  private modeName: string = 'light';

  constructor(private storage: Storage,
              private statusBar: StatusBar,
              private platform: Platform,
              private themeDetection: ThemeDetection) {
    this.onModeChanged = this.modeChangedSource.asObservable();
    this.init();
    if(environment.name == 'development') {
      (window as any).statusBar = this;
    }
  }

  private async init() {
    logc.orange("** Color mode service init **");
    const cachedColorMode = await this.storage.get('colorMode');

    if(cachedColorMode) {
      await this.setMode(cachedColorMode);
    } else {
      let osColorMode = null;
      if(this.platform.is("cordova")) {
        osColorMode = (await this.themeDetection.isDarkModeEnabled()).value ? "dark" : "light";
      } else {
        osColorMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";
      }
      await this.setMode(osColorMode);
    }
  }

  getMode() {
    return this.modeName;
  }

  isDark() {
    return this.modeName == 'dark';
  }

  async setMode(mode: string) {
    this.modeName = mode;
    await this.storage.set("colorMode", this.modeName);

    this.modes
      .filter(m => m != this.modeName)
      .forEach(mode => document.body.classList.remove(mode));

    document.body.classList.toggle(this.modeName, true);

    this.modeChangedSource.next(this.modeName);

    switch (this.modeName) {
      case 'dark':
        this.statusBar.styleLightContent();
        this.statusBar.backgroundColorByHexString('#141B2A');
        break;
      case 'light':
        // this.statusBar.styleDefault();
        // this.statusBar.backgroundColorByHexString('#FFFFFF');
        this.statusBar.styleLightContent();
        this.statusBar.backgroundColorByHexString('#141B2A');
        break;
      default:
        break;
    }
  }
}
