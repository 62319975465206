export const environment = {
  production: true,
  anonymousToken: 'd41d8cd98f00b204e9800998ecf8427e',
  facebookAppId: '1587566771560285',
  googleClientId: '11770871423-28rlltbfj0cfu5mdom0le9b3h1tegdge.apps.googleusercontent.com',
  domain: 'https://me3api.herokuapp.com',
  pusherApiKey: '235c60f950c0f25a5a78',
  pusherClusterName: "mt1",
  googleMapsApiKey: 'AIzaSyCMzROzmZBG2bmC4TubZmPTCeWka0vrPyc',
  name: 'production',
  fireBaseProjectId: '11770871423',
  oneSignalAppId: 'dfa32a3a-8cde-4d6f-a166-9276360bc940',
  serviceWorker: true,
  debugLogsLimit: null
};
