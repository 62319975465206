import { Injectable, NgZone } from '@angular/core';
import {NavController, LoadingController, ModalController, ToastController} from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from './api.service';
import { Config } from '../config/config';
import { UtilsService } from '../utils.service';
import { Saveable } from '../../shared/structure/saveable';
import { Storage } from '@ionic/storage';
import { AnalyticsService } from '../analytics/analytics.service';
import { PictureService } from '../picture.service';
import { Subject } from "rxjs/Subject";
import {ScheduledStates, SearchService} from './search.service';
import { CachedPicturesService } from './cached-pictures.service';
import {Observable} from "rxjs";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DispatcherService} from "../dispatcher.service";
import {TribesAlertComponent} from "../../components/tribes-alert/tribes-alert.component";
import {RouteTrackerService} from "../route-tracker.service";
import {AlertService} from "../popups/alert.service";
import {logc} from "../../shared/helpers/log";
import {DAILY_PASSED_USERS_LIMIT, DAILY_REJECTED_USERS_LIMIT, WeeklyScheduledSearchLimit} from "../../shared/constants/constants";
import {FeedbackService} from "../popups/feedback.service";
import {ReportService} from "../report.service";
import {TribeStatus} from "../../shared/enums/tribe-status.enum";
import {MatchingProcessPage} from "../../pages/matching-process/matching-process.page";
import {EducationalPopup} from "../tribe-highlights.service";
import { SearchType } from './search.service';

const MESSENGERS = "whatsapp, watsapp, whats app, instagram, insta, telegram, tg, snapchat, snap,  google chat"
const MESSENGERS_REGEXP = new RegExp(
  MESSENGERS
    .split(',')
    .map(messenger => messenger.trim().toLowerCase())
    .join("|")
);

const NUMBER_REGEXP = /\d{3}[-\s]\d{2}[-\s]\d{2}|\d{7}/;

@Injectable({
  providedIn: 'root',
})
export class TribesService extends Saveable {
  defaultConfig: any = [];
  key: string = 'tribes';
  private newMessageSource = new Subject();
  public onNewMessage: any;

  private DECLINING_PROCESS_UNBLOCK_TIMEOUT = 5000;
  public tribeLoadedSource: Subject<any> = new Subject();
  public tribeChangedSource = new Subject();
  public tribeRemovedSource = new Subject();
  public tribeAddedSource = new Subject();
  public tribeActionTakenSource = new Subject();
  public chatUnlockedSource = new Subject();
  public tribeFormedSource = new Subject();
  public tribeFailedSource = new Subject();
  public tribeIncompleteSource = new Subject();
  public progressBarQueueSource = new Subject();
  public foundOneSource = new Subject();
  public refreshSource = new Subject();
  public tribesMoveSource = new Subject();
  public tribeLeftSource = new Subject();
  public tribeDeclinedSource = new Subject();
  public tribeUpdateSource = new Subject();
  public tribeJoinedSource = new Subject();
  public emptyBlacklistSource = new Subject();
  private tribeStartedSource = new Subject();
  public startedTribePageLeftSource = new Subject();
  private tribesReadySource = new BehaviorSubject([]);
  public otherUserNewMessageSource: Subject<any> = new Subject();
  public userNewMessageSource: Subject<any> = new Subject();
  public userSelectedForScheduledGroupSource: Subject<any> = new Subject();
  public newScheduledSearchSource: Subject<any> = new Subject();
  public newScheduledSearchesSource: Subject<any> = new Subject();
  public scheduledSearchRemovedSource: Subject<any> = new Subject();
  public scheduledSearchAddedSource: Subject<any> = new Subject();
  public scheduledSearchFormedSource: Subject<any> = new Subject();
  public userJoinedSource: Subject<any> = new Subject();
  public onUserJoined: Observable<any>;
  public onScheduledSearchFormed: Observable<any>;
  public onScheduledSearchAdded: Observable<any>;
  public onScheduledSearchRemoved: Observable<any>;
  public onNewScheduledSearch: Observable<any>;
  public onNewScheduledSearches: Observable<any>;
  public onTribeJoined: Observable<any>;
  public onUserNewMessage: Observable<any>;
  public onOtherUserNewMessage: Observable<any>;
  public onTribesReady: Observable<any>;
  public onStartedTribePageLeft: Observable<any>;
  public onTribeStarted: Observable<any>;
  public onUserSelectedForScheduledGroup: Observable<any>;
  private userRejectedSource: Subject<any> = new Subject();
  private swapFoundSource: Subject<any> = new Subject();
  public rejectUserSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public introsSuggestedSource: BehaviorSubject<any> = new BehaviorSubject([]);
  public questionsSuggestedSource: BehaviorSubject<any> = new BehaviorSubject([]);
  public onTribeLoaded: any;
  public onIntrosSuggested: any;
  public onQuestionsSuggested: any;
  public onTribeChanged: any;
  public onTribeActionTaken: any;
  public onTribeAdded: any;
  public onTribeRemoved: any;
  public onChatUnlocked: any;
  public onTribeFormed: any;
  public onTribeFailed: any;
  public onTribeIncomplete: any;
  public onProgressBarQueueChanged: any;
  public onRefresh: any;
  public onFoundOne: any;
  public onTribesMove: any;
  public onTribeLeft: any;
  public onTribeDeclined: any;
  public onTribeUpdate: any;
  public dataLoaded: boolean = false;
  public onBlacklistEmptied: any;
  public onUserRejected: Observable<any>;
  public onSwapFound: Observable<any>;
  // public inRejectingUserProcess: Observable<any>;

  public memberStates = ['needs_review', 'accepted'];

  public inRejectingUserProcess: boolean = false;

  public ongoingSearches = [];

  constructor(public navCtrl: NavController,
              public api: ApiService,
              public config: Config,
              public storage: Storage,
              public utils: UtilsService,
              public cachedPictureService: CachedPicturesService,
              public analyticsService: AnalyticsService,
              public _ngZone: NgZone,
              private toastCtrl: ToastController,
              public domSanitizer: DomSanitizer,
              public pictureService: PictureService,
              public loadingCtrl: LoadingController,
              public searchService: SearchService,
              private modalCtrl: ModalController,
              private dispatcherService: DispatcherService,
              private routeTrackerService: RouteTrackerService,
              private alertService: AlertService) {
    super(storage);

    if(this.config.isDev || this.config.isStaging) {
      (window as any).tribesService = this;
    }

    this.trackModal();
    this.onTribeJoined = this.tribeJoinedSource.asObservable();
    this.onNewMessage = this.newMessageSource.asObservable();
    this.onTribeChanged = this.tribeChangedSource.asObservable();
    this.onTribeIncomplete = this.tribeIncompleteSource.asObservable();
    this.onTribeActionTaken = this.tribeActionTakenSource.asObservable();
    this.onTribeRemoved = this.tribeRemovedSource.asObservable();
    this.onTribeFailed = this.tribeFailedSource.asObservable();
    this.onTribeAdded = this.tribeAddedSource.asObservable();
    this.onChatUnlocked = this.chatUnlockedSource.asObservable();
    this.onTribeFormed = this.tribeFormedSource.asObservable();
    this.onFoundOne = this.foundOneSource.asObservable();
    this.onProgressBarQueueChanged = this.progressBarQueueSource.asObservable();
    this.onRefresh = this.refreshSource.asObservable();
    this.onTribesMove = this.tribesMoveSource.asObservable();
    this.onTribeLeft = this.tribeLeftSource.asObservable();
    this.onTribeDeclined = this.tribeDeclinedSource.asObservable();
    this.onTribeUpdate = this.tribeUpdateSource.asObservable();
    this.onBlacklistEmptied = this.emptyBlacklistSource.asObservable();
    this.onTribeStarted = this.tribeStartedSource.asObservable();
    this.onStartedTribePageLeft = this.startedTribePageLeftSource.asObservable();
    this.onTribesReady = this.tribesReadySource.asObservable();
    this.onOtherUserNewMessage = this.otherUserNewMessageSource.asObservable();
    this.onUserNewMessage = this.userNewMessageSource.asObservable();
    this.onUserSelectedForScheduledGroup = this.userSelectedForScheduledGroupSource.asObservable();
    this.onNewScheduledSearch = this.newScheduledSearchSource.asObservable();
    this.onScheduledSearchRemoved = this.scheduledSearchRemovedSource.asObservable();
    this.onScheduledSearchAdded = this.scheduledSearchAddedSource.asObservable();
    this.onScheduledSearchFormed = this.scheduledSearchFormedSource.asObservable();
    this.onNewScheduledSearches = this.newScheduledSearchesSource.asObservable();
    this.onUserRejected = this.userRejectedSource.asObservable();
    this.onSwapFound = this.swapFoundSource.asObservable();
    this.onQuestionsSuggested = this.questionsSuggestedSource.asObservable();
    this.onIntrosSuggested = this.introsSuggestedSource.asObservable();
    this.onTribeLoaded = this.tribeLoadedSource.asObservable();
    this.onUserJoined = this.userJoinedSource.asObservable();

    this.searchService
      .onFoundOne
      .subscribe((event) => {
        if(event)
          this.foundOneSource.next();
      });

    this.onScheduledSearchRemoved.subscribe((id) => {
      const searchIndex = this.ongoingSearches.findIndex(search => search.id == id);
      if(searchIndex > -1) {
        this.ongoingSearches.splice(searchIndex, 1);
        this.refreshSearchServiceOngoingSearches();
      }
    })

    this.onUserSelectedForScheduledGroup.subscribe((user) => {
      let pendingSearch = this.ongoingSearches.find(search => search.preset_user && search.preset_user == 'pending');
      if(pendingSearch) {
        pendingSearch.preset_user = user;
        this.scheduledSearchFormedSource.next(pendingSearch);
      } else {
        let ongoingScheduledSearchDummy = {
          id: -5,
          preset_user: user,
          status: 'fans'
        }
        this.ongoingSearches.unshift(ongoingScheduledSearchDummy);
      }
      this.refreshSearchServiceOngoingSearches();
    })

    this.onNewScheduledSearch.subscribe(({ search_id }) => {
      let pendingSearch = this.ongoingSearches.find(search => search.id == -5);
      if(pendingSearch) {
        pendingSearch.id = search_id;
        this.scheduledSearchFormedSource.next(pendingSearch)
      } else {
        let ongoingScheduledSearchDummy = {
          id: search_id,
          preset_user: 'pending',
          status: 'fans'
        }
        this.ongoingSearches.unshift(ongoingScheduledSearchDummy);
      }
      this.refreshSearchServiceOngoingSearches();
    })

    this.onNewScheduledSearches.subscribe(({ search_ids }) => {
      const newSearchesIds =
        search_ids
          .filter(id =>
            !this.ongoingSearches.map(search => search.id).includes(id)
          );

      logc.info("newSearchesIds: ", newSearchesIds);
      newSearchesIds.forEach((id) => {
        let ongoingScheduledSearchDummy = { id, status: 'fans' }
        this.ongoingSearches.unshift(ongoingScheduledSearchDummy);
        this.scheduledSearchFormedSource.next(ongoingScheduledSearchDummy);
      })
      this.refreshSearchServiceOngoingSearches();
    })

    this.searchService
      .onSearchSucceeded
      .subscribe((searchData:any) => {
        if(searchData)
          this.dispatchFormationEvent( searchData );
      });

    this.onTribeDeclined.subscribe(data => {
      this.searchService.inDecliningTribeProcess = !data?.tribe_id;
      setTimeout(() => {
        if(this.searchService.inDecliningTribeProcess) {
          logc.info(
            `- app didn't receive declined event from server in ${ this.DECLINING_PROCESS_UNBLOCK_TIMEOUT / 1000 }s, unblocking inDecliningTribeProcess...`
          );
          this.searchService.inDecliningTribeProcess = false;
        }
      }, this.DECLINING_PROCESS_UNBLOCK_TIMEOUT)
    })

    this.searchService
      .onOngoingSearchesAdded
      .subscribe(newSearchObject => {
        if(this.ongoingSearches.some(search => search.preferences.location == newSearchObject.preferences.location)) {
          const index =
            this
              .ongoingSearches
              .findIndex(search => search.preferences.location == newSearchObject.preferences.location);

          this.ongoingSearches.splice(index, 1, newSearchObject);
        } else {
          this.ongoingSearches.push(newSearchObject);
        }
        console.log('--- tribes.service.ts --> ONGOINGSEARCHES: ', this.ongoingSearches);
      })
  }

  trackModal() {
  }

  reset() {
    super.reset();
    this.ongoingSearches = [];
  }

  hasChats(): boolean {
    return this.data?.some(this.isChat);
  }

  hasScheduledSearches(): boolean {
    return this.ongoingSearches.some(search => search.status == ScheduledStates.Scheduled);
  }

  scheduledSearchesNumberIs(num: number): boolean {
    return this.ongoingSearches.filter(search => search.status == ScheduledStates.Scheduled).length == num;
  }

  scheduledSearchNumber(): number {
    return this.ongoingSearches.filter(search => search.status == SearchType.Fans).length;
  }

  scheduledSearchLeft(): number {
    return this.getScheduleSearchesLimit() - this.ongoingSearches.filter(search => search.status == ScheduledStates.Scheduled).length;
  }

  private getScheduleSearchesLimit() {
    return this.config.isPlus()
      ? WeeklyScheduledSearchLimit.Plus
      : WeeklyScheduledSearchLimit.Default;
  }

  refreshSearchServiceOngoingSearches() {
    this.searchService.ongoingSearches = this.ongoingSearches;
  }

  async finishScheduledSigningUp({ source, callback = () => {} }) {
    const cb = () => {
      callback();
      this.dispatcherService.newPopupSource.next({
        popupName: "anotherScheduledGroupPopup"
      })
    }

    this.analyticsService.trackEvent({
      key: "fan_matching_signed_up",
      value: 1,
      platform: this.utils.getPlatform(),
      page: source
    })

    if(this.scheduledSearchLeft()) {
      await this.utils.showCheckmarkModal(1500, cb);
    } else {
      if(await this.modalCtrl.getTop()) {
        this.modalCtrl.dismiss();
      }
      this.navCtrl.navigateForward("offboarding");
    }
  }

  setTribes(tribes) {
    this.cachedPictureService.prepareTribeCache(tribes);
    this.searchService.tribes = tribes;
    this.tribesReadySource.next(tribes);
    this.data = tribes;
    setTimeout(() => this.showModalTribe(this.data), 2000);
    this.save();
  }

  async showModalTribe(tribes = []){
    let tribe = tribes
      .find(tribe =>
        (tribe.tribe_user.status === 'needs_review'
          || (tribe.tribe_user.status === 'invited' && !tribe.highlights_seen_at))
        && tribe.status != "in_progress");

    if(!tribe || tribe.id == this.routeTrackerService.getCurrentPage().match(/(\d+)/g)) return;

    const stateName = tribe.tribe_user.status === 'invited' ? 'newTribe' : 'newTribeMembers';
    let pendingTribeAlert;
    try {
      pendingTribeAlert = await this.alertService.createAlertNotification(
        { stateName, tribe },
        TribesAlertComponent
      );
      pendingTribeAlert.onClick.subscribe(id => {
        this.navCtrl.navigateForward(`/tribes/${id}/highlights`);
        pendingTribeAlert.close();
      });

    } catch ( err ) {
      console.log( err )
    }
  }

  count() {
    return this.data.length;
  }

  actionTaken(tribeId) {
    this.tribeActionTakenSource.next(tribeId);
  }

  tribeRemoved(tribeId) {
    if(!this.data)
      return;

    const i = this.data.findIndex(t => t.id == tribeId);
    if(i !== -1) {
      this.data.splice(i, 1);
    }
    this.save();
    this.tribeRemovedSource.next(tribeId);
    this.actionTaken(tribeId);
  }

  async openPendingTribe() {
    let tribe = this.getTribes().find(tribe => tribe.status === 'pending');
    return this.navCtrl.navigateForward(`tribes/${tribe.id}/highlights`);
  }

  getPendingTribes() {
    return this.getTribes().filter((tribe) => {
      return tribe.status == 'pending' || tribe.status == 'proposed';
    });
  }

  finishStreak(state: "declined" | "rejected"): void {
    let passedUsers = this.config.get("passedUsers");
    if(passedUsers[state]?.streak) {
      passedUsers[state].streak = 0;
      this.config.set("passedUsers", passedUsers);
    }
  }

  join(tribeId) {
    //when API confirms
    return new Promise((resolve, reject) =>{
      this.api.post('tribes/join', { id: tribeId })
        .then((res: any) => {
          if(!res.is_initiator) {
            this.dispatcherService.joinedTribeSource.next(true);
          }
          this.finishStreak("declined");
          this.tribeJoinedSource.next(res);
          this.resetNotFeelingPassData();
          this.actionTaken(tribeId);
          resolve(res);
        }, err =>{
          reject(err);
        });
      });
  }

  startNewGroup(tribeId, messageId, params): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `tribes/${ tribeId }/messages/${ messageId }/start_new_group`;
        await this.api.post(url, params)
        resolve();
      } catch(e) {
        logc.error("** Staring new group error: ", e);
        this.utils.showGenericError({});
        reject();
      }
    })
  }

  dismissNewGroupProposal(tribeId, messageId): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `tribes/${ tribeId }/messages/${ messageId }/dismiss_new_group_proposal`;
        await this.api.post(url, {})
        resolve();
      } catch(e) {
        logc.error("** Dismissing new grou pproposal error: ", e);
        this.utils.showGenericError({});
        reject();
      }
    })
  }

  directMessage({ id }) {
    //when API confirms
    return new Promise((resolve, reject) =>{
        this.api.post('tribes/directmessage', {user_id: id}).then( async (res : any) =>{
          this.dispatchFormationEvent({ tribe: res });
          this.actionTaken(res.id);
          resolve(res);
        }, err =>{
          reject(err);
        });
    });
  }

  approve(tribeId) {
    return new Promise((resolve, reject) =>{
      this.api.post('tribes/approve', {id: tribeId}).then( res =>{
        this.finishStreak("rejected");
        this.actionTaken(tribeId);
        resolve(res);
      }, err =>{
        console.log(err);
        reject(err);
      });
    });
  }

  emptyBlacklist(source = "") {
    return new Promise(async (resolve, reject) => {
      try {
        await this.api.post('tribes/clear_blacklist_entries', {});
        this.analyticsService.trackEvent({ key: "reset_matches", value: 1, source });
        this.resetNotFeelingPassData();
        this.config.refreshDailyDeclinedUsers();
        this.config.refreshDailyRejectedUsers();
        this.emptyBlacklistSource.next(true);
        this.utils.showGenericMessage('Your can now match again');
        resolve('');
      } catch (err) {
        this.utils.errorContext = 'tribes, emptyBlacklist: ' + JSON.stringify(err);
        await this.utils.showGenericError({
          key: "emptying_black_list"
        });
        reject(err);
      }
    });
  }

  async startSearchingSwapFlow({ id }) {
    if(this.searchService.reachedDailyRejectedUsersLimit()) {
      const currentUrl = (window as any).location.pathname;
      if(!currentUrl.includes("highlights")) {
        const details = currentUrl.includes('chat') ? "?from_chat=true" : "";
        this.navCtrl.navigateForward(`tribes/${ id }/highlights${ details }`);
      }
      return;
    }

    this.dispatcherService.closeInstructionsSource.next(null);
    try {
      await this.findSwap(id);
      const modal = await this.modalCtrl.create({
        component: MatchingProcessPage,
        id: "matching-process"
      });
      await modal.present();
    } catch(e) {
      logc.error("** Searching swap error: ", e);
    }
  }

  async findSwap(tribeId: number) {
    await this.utils.showLoading('Matching');
    return new Promise((resolve, reject) => {
      this.api.post('tribes/' + tribeId + '/swap', {}).then(data => {
        resolve(data);
        this.utils.doneLoading();
      }).catch(err => reject(err));
    })
  }

  resetNotFeelingPassData() {
    this.config.set('notFeelingPassedTime', null);
    this.config.set('notFeelingPassedCounter', 0);
  }

  blacklistUsersOutsideRadius(tribeId) {
    return new Promise((resolve, reject)=>{
      return this.api.post('tribes/blacklist_users_outside_radius', {id: tribeId}).then(res =>{
        resolve(res);
      }, err =>{
        console.log(err);
        this.utils.errorContext = `tribes, blacklistUsersOutsideRadius, id: ${tribeId}, error: ` + JSON.stringify(err);
        this.utils.showGenericError({
          key: "emptying_black_list_outside_radius"
        });
        reject(err);
      })
    });
  }

  reportUsers(tribeId: string | number, reportedUsers: number[]): Promise<any> {
    return new Promise((resolve, reject)=>{
      return this.api.post('tribes/report_users', {
        id: tribeId,
        reported_users: reportedUsers
      }).then(
        res =>
          resolve(res),
        err =>
            reject())
    });
  }

  blacklistUsers(tribeId, blacklistedUsers): Promise<void> {
    return new Promise((resolve, reject)=>{
      return this.api.post('tribes/blacklist_users', {id: tribeId, blacklisted_users: blacklistedUsers}).then(res => {
        resolve();
      }, rej =>{
        reject();
      })
    })
  }

  newMessage(data) {
    const message = data?.message?.content || "";
    const [ messenger ] = message.match(MESSENGERS_REGEXP) || [];
    const [ phoneNumber ] = message.match(NUMBER_REGEXP) || [];
    if(messenger) {
      logc.crimson(`Messenger mentioned! It's: ${ messenger }`);
      this.analyticsService.trackEvent({ key: "user_mentioned_other_messenger", value: 1, messenger })
    }
    if(phoneNumber) {
      logc.crimson("Phone number mentioned!");
      this.analyticsService.trackEvent({ key: "user_mentioned_phone_number", value: 1, phoneNumber });
    }

    const tribe = this.getTribe(data.tribe_id);
    if(this.data) {
      this.data.map(t => {
        if (t.id === data.tribe_id && !t.has_user_messages && t?.last_message?.user_id > 0) {
          t.has_user_messages = true;
        }
        return t;
      });
    }

    if(tribe) {
      tribe.last_message = data.message;
      tribe.last_event_at = data.message.created_at;
      // I don't see how we use it, we use this source when we renew tribe, but i see no place with message adding/checking. Leaving it here till the figuring out.
      this.newMessageSource.next(tribe);
      if(!this.config.isCurrentUser(tribe.tribe_user.id)) {
        this.otherUserNewMessageSource.next(data);
      }
      if(data.message.user_id > 0) {
        this.userNewMessageSource.next(data);
      }
    }

    this.save();

  }

  getTribe(tribeId) {
    return this.getTribes().find( t => t.id == tribeId);
  }

  getUserTribe(tribeId,userId) {
    return this.getTribes().find( t => t.id == tribeId).users.find( u => u.id == userId);
  }

  getDirectMessageTribe({ id }) {
    return this.getTribes().find(t => t.type == 'DirectMessage' && t.users.find(user => user.id === id));
  }

  addToProgressBarQueue(tribe) {
    this.progressBarQueueSource.next(tribe);
  }

  refresh(value) {
    this.refreshSource.next(value);
  }

  dispatchFormationEvent({ tribe }) {
    if(tribe.status === 'failed') {
      return this.tribeFailedSource.next(tribe);
    }

    if(this.data?.find(t => t.id === tribe.id)) {
      this.tribeChanged( tribe );
    } else {
      if(this.data) this.data.uniqPush( tribe );
      this.tribeAddedSource.next(tribe);
    }

  }

  async tribeChanged(tribe) {
    console.log('--- tribes.service.ts tribeChanged(tribe) tribe: ', tribe);
    let oldTribe = null;

    this.inRejectingUserProcess = false;

    if(this.data) {
      const i = this.data.findIndex(t => t.id === tribe.id);
      if(i > -1) {
        oldTribe = this.data[i];
        this.data[i] = tribe;
        await this.save();
      }
    }

    if(oldTribe.status == 'pending' && tribe.status == 'inviting') {
      this.tribeStartedSource.next(tribe);

      if(!this.config.getFlag("isFirstTribeCreated")) {
        await this.config.setFlag("isFirstTribeCreated", true);
      }
    }

    if([ TribeStatus.Approving, TribeStatus.Confirming ].includes(tribe.status)) {
      this.swapFoundSource.next(tribe);
    }

    this.tribeChangedSource.next(tribe);
    if(tribe.status === 'formed') {
      this.analyticsService.trackEvent({key: 'tribe_formed', value: 1});
      this.tribeFormedSource.next(tribe);
    }

    if(oldTribe) {
      const justWentIncomplete = oldTribe.status != tribe.status && tribe.status == 'incomplete';
      if(justWentIncomplete)
        this.tribeIncompleteSource.next(tribe);
    }

    switch(tribe.tribe_user?.status) {
      case 'invited':
        this.analyticsService.trackEvent({key: 'tribe_invitation', value: 1});
        break;
      case 'expired':
        this.tribeRemovedSource.next(tribe);
        break;
    }
  }

  getTribes() {
    return this.data || [];
  }

  removeTribe(tribe): Promise<void> {
    return new Promise((resolve, reject) => {
      let data = this.utils.deepClone(this.data);
      let tribeId = this.data.findIndex(t => t.id === tribe.id);
      this.data.splice(tribeId, 1);
      resolve();
    })
  }

  loadFromCache() {
    return new Promise((resolve, reject) => {
      super.load().then( _ => {
        this.dataLoaded = true;
        let tribes = this.getTribes();
        this.cachedPictureService.prepareTribeCache( tribes );
        resolve( tribes );
      });
    });
  }

  accept() {
    return this.api.post('tribes/join', {});
  }

  refreshPassedUsersInfo() {
    this.config.set("passedUsers", this.config.defaultConfig.passedUsers);
  }

  riseEducationalPopupFlag(flag: string): void {
    this.config.updateHash('educationalPopups', { [flag]: true })
  }

  reject(tribeId, user_id, reason) {
    return new Promise((resolve, reject)=>{
      this.api
        .post('tribes/reject', {id: tribeId, selected_user_id: user_id, reason: reason})
        .then(async (res) => {
          this.inRejectingUserProcess = true;
          this.actionTaken(tribeId);
          if(reason == "passed_not_feeling_it") {
            this.updateRejectCounter(reason);
            // if(this.passingStreakReached(2, 'rejected') && !this.config.get("secondPassEducationalPopupShown")) {
            //   this.finishStreak("rejected");
            //   this.dispatcherService.openPopup({ popupName: EducationalPopup.Second })
            //   this.analyticsService.trackEvent({ key: "passing_warning", value: 1, content: "request-explanation" });
            //   this.config.set("secondPassEducationalPopupShown", true);
            // }
            if(this.searchService.reachedDailyRejectedUsersLimit()) {
              let passedUsers = this.config.get("passedUsers");
              passedUsers.rejected.reachedLimitTimes += 1;
              this.config.set("passedUsers", passedUsers);
              this.analyticsService.trackEvent({
                key: "swapping_paused",
                value: 1,
                nth: passedUsers.rejected.reachedLimitTimes
              })
            }
            // this.showEducationalPopup('reject');
        }
        this.userRejectedSource.next({ tribeId, userId: user_id, reason });
        resolve(res);
      }, err=>{
        console.log(err);
        this.utils.errorContext = `reject, id: ${tribeId}, user_id: ${user_id}, reason: ${reason}, error: ` + JSON.stringify(err);
        this.utils.showGenericError({
          key: "reject"
        });
        reject(err);
      })
    });
  }

  updateDeclineCounter(passedCount: number, reason = ""): void {
    let passedUsers = this.config.get("passedUsers") || {};
    if(!passedUsers.declined) {
      passedUsers.declined = {
        daily: 0,
        streak: 0,
        total: 0,
        reachedLimitTimes: 0,
        reasons: {}
      };
    }
    if(!passedUsers.declined.reachedLimitTimes) {
      passedUsers.declined.reachedLimitTimes = 0;
    }
    passedUsers.declined.total += passedCount;
    passedUsers.declined.daily += passedCount;
    passedUsers.declined.streak += passedCount;
    passedUsers.declined.reasons[reason] = (passedUsers.declined.reasons[reason] + passedCount) || passedCount;
    this.config.set("passedUsers", passedUsers);
    console.log("Declined:");
    console.log("- today: ", passedUsers.declined.daily);
    console.log("- total: ", passedUsers.declined.total);
    console.log("- streak: ", passedUsers.declined.streak);
  }

  updateRejectCounter(reason) {
    let passedUsers = this.config.get("passedUsers") || {};
    if(!passedUsers.rejected) {
      passedUsers.rejected = {
        daily: 0,
        streak: 0,
        total: 0,
        reachedLimitTimes: 0,
        reasons: {}
      };
    }
    if(!passedUsers.rejected.reachedLimitTimes) {
      passedUsers.rejected.reachedLimitTimes = 0;
    }
    passedUsers.rejected.total += 1;
    passedUsers.rejected.daily += 1;
    passedUsers.rejected.streak += 1;
    passedUsers.rejected.reasons[reason] = (passedUsers.rejected.reasons[reason] + 1) || 1;
    this.config.set("passedUsers", passedUsers);
    console.log("Rejected:");
    console.log("- today: ", passedUsers.rejected.daily);
    console.log("- total: ", passedUsers.rejected.total);
    console.log("- streak: ", passedUsers.rejected.streak);
  }

  showEducationalPopup(state: "decline" | "reject"): void {
    let passedUsers = 0;
    let popupName = '';
    let educationalPopups = {};

    if(state == 'decline') {
      passedUsers = this.config.get("passedUsers").declined.reasons.passed_not_feeling_it;
      educationalPopups = this.config.get("declineEducationalPopups") || {};
    }
    if(state == 'reject') {
      passedUsers = this.config.get("passedUsers").rejected.reasons.passed_not_feeling_it;
      educationalPopups = this.config.get("rejectEducationalPopups") || {};
    }

    logc.crimson("Passed users: ", passedUsers);

    const readyForFirstEducationalPopup = () => {
      if(state == 'decline') return passedUsers <= 2;
      if(state == 'reject') return passedUsers == 1;
      return false;
    }

    const readyForSecondEducationalPopup = () => {
      if(state == 'decline') return passedUsers.inRange(3, 4);
      if(state == 'reject') return passedUsers == 2;
      return false;
    }

    const readyForThirdEducationalPopup = () => {
      if(state == 'decline') return passedUsers.inRange(5,6);
      if(state == 'reject') return passedUsers == 3;
      return false;
    }

    switch(true) {
      case readyForSecondEducationalPopup():
        popupName = "secondPassingEducationalPopup";
        break;
      case readyForThirdEducationalPopup():
        popupName = "thirdPassingEducationalPopup";
        break;
    }

    const analyticsEvents = {
      decline: {

      },
      reject: {}
    }

    if(popupName) {
      if(!educationalPopups[popupName]) {
        this.dispatcherService.newPopupSource.next({ popupName })
        educationalPopups[popupName] = true;
        if(state == 'decline') this.config.set("declineEducationalPopups", educationalPopups);
        if(state == 'reject') this.config.set("rejectEducationalPopups", educationalPopups);
      }
    }
  }

  decline(tribeId, usersIds, declineReason, reportReason?, details?) {
    return new Promise((resolve, reject)=> {
      // const isInitiator = this.data.find(tribe => tribe.id == tribe.id)?.is_initiator;
      let params = {
        id: tribeId,
        selected_user_ids: usersIds,
        reason: declineReason,
      };

      if(reportReason) {
        params = { ...params, ...{ report_reason: this.utils.underscoreCase(reportReason) } }
      }

      if(details) {
        params = { ...params, ...{ report_details: details } }
      }

      this.api.post('tribes/decline', params).then(res =>{
        this.tribeRemoved(tribeId);
        if(declineReason == "passed_not_feeling_it") {
          this.updateDeclineCounter(usersIds.length, declineReason);
          // if(this.passingStreakReached(3, 'declined') && !this.config.get("secondPassEducationalPopupShown")) {
          //   this.finishStreak("declined");
          //   this.dispatcherService.openPopup({ popupName: EducationalPopup.Second });
          //   this.analyticsService.trackEvent({ key: "passing_warning", value: 1, content: "request-explanation" });
          //   this.config.set("secondPassEducationalPopupShown", true);
          // }
        }
        if(this.searchService.reachedDailyPassedUsersLimit()) {
          let passedUsers = this.config.get("passedUsers");
          passedUsers.declined.reachedLimitTimes += 1;
          this.config.set("passedUsers", passedUsers);
          this.analyticsService.trackEvent({ 
            key: "matching_paused", 
            value: 1, 
            nth: passedUsers.declined.reachedLimitTimes 
          });
        }
        resolve(res);
      }, err=>{
        console.log(err);
        this.utils.errorContext = `decline id: ${tribeId}, error: ` + JSON.stringify(err);
        this.utils.showGenericError({
          key: "decline",
        });
        reject(err);
      })
    });
  }

  passingStreakReached(limit: number, state: "declined" | "rejected"): boolean {
    const passedUsers = this.config.get("passedUsers");
    return passedUsers[state]?.streak >= limit;
  }

  receiveFakeMessage() {
    return this.api.post('tribes/receive_fake_message', {});
  }

  tryMatch() {
    return this.api.post('tribes/try_match', {});
  }

  getTribeInvitationAsSecond() {
    return this.api.post('tribes/get_tribe_invitation_as_second', {});
  }

  getTribeInvitationAsThird() {
    return this.api.post('tribes/get_tribe_invitation_as_third', {});
  }

  createFakeTribe() {
    return this.api.post('tribes/fake_tribe_found', {});
  }

  private fetch(resolve, reject) {
    this.api.get('tribes', {}).then(
      (data: any) => {
        let tribes = data.tribes;
        this.setTribes(tribes);
        console.log('TRIBES endpoint data', data);
        this.ongoingSearches = data.ongoing_searches;
        this.searchService.ongoingSearches = this.ongoingSearches;
        resolve(tribes);
      },
      err => {
        console.log('error', err);
        //An error occured here, but it's from the server
        //Maybe we should let the user know
        resolve(this.getTribes());
      }
    );
  }

  openQueueTribe(id, extras = {}) {
    let tribe = this.getTribe( id );
    let currentUser: any = {};
    if(tribe) {
      currentUser = tribe.tribe_user;
    }

    if(this.memberStates.includes(currentUser.status)) {
      this.navCtrl.navigateForward(`/tribes/${ id }/chat`);
    } else if(currentUser.status === 'invited'){
      console.log('--- tribes.service.ts EXTRAS: ', extras);
      this.navCtrl.navigateForward(`/tribes/${ id }/highlights`, extras);
    }
  }

  getListForTribe(tribe) {
    let currentUser = tribe.tribe_user;

    if(currentUser?.status === 'invited') {
      return 'queue';
    }

    if(this.memberStates.includes(currentUser.status)) {
      if(this.isChat(tribe)) {
        return 'chats';
      } else {
        return 'queue';
      }
    }
  }

  isChat(tribe): boolean {
    return tribe.has_user_messages || tribe.last_message?.id > 0 || tribe.type == 'DirectMessage';
  }

  load() {
    return new Promise((resolve, reject) => {
      this._ngZone.run( () => {
        super.load().then( _ => {
          this.dataLoaded = true;
          this.fetch(resolve, reject);
        });
      });
    });
  }

  abortGroup( searchLocation ) {
    const searchId = this.ongoingSearches.find(search => search.preferences.location === searchLocation).id;
    this.api.delete(`search/${ searchId }`, {}).then((status) => {
      logc.orange("Aborting group status: ", status);
    })
  }

  cancelSearch( searchId ) {
    this.api
      .delete(`search/${ searchId }`, {})
      .then((status) => {
        logc.orange("Cancelled group status: ", status);
        this.scheduledSearchRemovedSource.next(searchId);
      })
  }

  addUserToManualTribe(userId, tribeId?) {
    return this.api.post('tribes/add', {user_id: userId, id: tribeId});
  }

  cancelTribe({ id }) {
    return new Promise((resolve, rejected) => {
      this.api
        .post('tribes/cancel', { id })
        .then(response => {
          this.tribeRemoved(id);
          resolve(response)
        });
    });
  }

  async extendExpiry({ tribeId, users }): Promise<void> {
    let path: string = 'extend_invites';
    let params: any = { tribe_id: tribeId };
    if(users.length == 1) {
      params = { ...params, user_id: users[0].id };
      path = "extend_invite";
    }
    const status = await this.api.post('tribes/' + path, params);
    if(status == 200) {
      this.utils.showGenericMessage("Success! They now have 24 more hours to join.");
    }
  }
}
