import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLink } from './chat-link';

@NgModule({
  declarations: [
    ChatLink
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ChatLink
  ]
})
export class ChatLinkModule {}
