import {NgModule} from "@angular/core";
import {UseTheAppComponent} from "./use-the-app.component";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    UseTheAppComponent
  ],
  imports: [
    IonicModule,
    CommonModule
  ],
  exports: [
    UseTheAppComponent
  ]
})
export class UseTheAppModule {}