import { NgModule } from '@angular/core';
import { GifsComponent } from './gifs';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { DirectivesModule } from "../../directives/directives.module";

@NgModule({
  declarations: [
    GifsComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DirectivesModule
  ],
  exports: [
    GifsComponent
  ],
  schemas: [ ]
})
export class GifsComponentModule {}
