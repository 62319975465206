import { Injectable } from '@angular/core';
import {Keyboard} from "@ionic-native/keyboard/ngx";
import {Observable, Subject} from "rxjs";
import { environment } from 'src/environments/environment';
import {logc} from "../../shared/helpers/log";
export interface KeyboardState {
  state: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  private keyboardChangeSource: Subject<KeyboardState> = new Subject<KeyboardState>();
  private keyboardOpenedSource: Subject<any> = new Subject<any>();
  private keyboardClosedSource: Subject<any> = new Subject<any>();
  public onKeyboardChange: any;

  public onKeyboardOpened: Observable<any>;
  public onKeyboardClosed: Observable<any>;

  constructor() {
    if(environment.name == 'development') {
      (window as any).keyboardService = this;
    }
    this.onKeyboardChange = this.keyboardChangeSource.asObservable();
    this.onKeyboardOpened = this.keyboardOpenedSource.asObservable();
    this.onKeyboardClosed = this.keyboardClosedSource.asObservable();
  }

  simOpening() {
    this.keyboardChangeSource.next({ state: 'opened', data: { keyboardHeight: 200 } });
    this.keyboardOpenedSource.next({ data: { keyboardHeight: 200 }});
  }

  simClosing() {
    this.keyboardChangeSource.next({ state: 'closed', data: {} });
    this.keyboardClosedSource.next({ data: {} });
  }

  init() {
    logc.info("** Keyboard service initialized **");
    window.addEventListener('keyboardWillShow', this.keyboardWillShowListener);
    window.addEventListener('keyboardWillHide', this.keyboardWillHideListener);
  }

  keyboardWillShowListener = data => {
    this.keyboardChangeSource.next({ state: 'opened', data: data });
    this.keyboardOpenedSource.next({ data });
  }
  keyboardWillHideListener = data => {
    this.keyboardChangeSource.next({ state: 'closed', data: data });
    this.keyboardClosedSource.next({ data });
  }

}
