import { NgModule } from '@angular/core';
import { TribeDetailsPage } from './tribe-details.page';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import {DeclineUserComponent} from '../../components/decline-user/decline-user';

import {DeclineUserComponentModule} from '../../components/decline-user/decline-user.module';
import {CustomActionSheetComponent} from '../../components/custom-action-sheet/custom-action-sheet.component';
import {CustomActionSheetModule} from '../../components/custom-action-sheet/custom-action-sheet.module';
import {FeedbackModalComponent} from "../../components/feedback-modal/feedback-modal.component";
import {FeedbackModalModule} from "../../components/feedback-modal/feedback-modal.module";
import {TribeAvatarModule} from "../../components/tribe-avatar/tribe-avatar.module";
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    TribeDetailsPage,
  ],
  entryComponents: [
    DeclineUserComponent,
    CustomActionSheetComponent,
    FeedbackModalComponent
  ],
  imports: [
    FeedbackModalModule,
    DeclineUserComponentModule,
    CustomActionSheetModule,
    IonicModule,
    CommonModule,
    PipesModule,
    FormsModule,
    TribeAvatarModule
  ],
})
export class TribeDetailsPageModule {}
