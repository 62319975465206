import { Injectable } from "@angular/core";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import {AlertController, Platform} from "@ionic/angular";
import {AlertService} from "../popups/alert.service";
import {OpenNativeSettings} from "@ionic-native/open-native-settings/ngx";
import {AppService} from "../app";
import {Storage} from "@ionic/storage";
import {ApiService} from "../data/api.service";
import {CONTACTS_CHUNK_LENGTH} from "../../shared/constants/constants";

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  private isCordova: boolean = this.platform.is('cordova');

  constructor(private diagnostic: Diagnostic,
              private platform: Platform,
              private alertController: AlertController,
              private ons: OpenNativeSettings,
              private appService: AppService,
              private storage: Storage,
              private api: ApiService) {

    this.appService
      .onAppReady
      .subscribe(async () => {
        if(await this.hasLocalContacts()) {
          console.log('sending contacts...');
          let contacts = await this.storage.get('contacts');
          if(contacts?.length) {
            this.sendContacts( contacts );
          }
        }
      });

  }

  async init() {
    console.log('*** CONTACTS SERVICE IS WORKING! ***');
    console.log('contacts status: ', await this.getContactsPermissionStatus());
    // this.requestContactsPermission();
  }

  async hasLocalContacts() {
    return (await this.storage.get('contacts'))?.length;
  }

  async sendContacts(contacts) {
    if(!contacts.length) return;

    let contactsPackage = contacts.splice(0, CONTACTS_CHUNK_LENGTH);
    this.api.post('users/block_contacts', {
      contacts: contactsPackage,
    }).then( async _ => {
      await this.storage.set('contacts', contacts);
      setTimeout( _ => {
        this.sendContacts(contacts);
      }, 1500);
    });
  }

  async requestContactsPermission() {
    if(!this.isCordova) return;

    try {
      const response = await this.diagnostic.requestContactsAuthorization();
      console.log('--- contact.service.ts requestContactsPermission() response: ', response);
      console.log('is contacts allowed: ', await this.isContactsPermissionAllowed());
    } catch ( err ) {
      console.log( err );
    }
  }

  async isContactsPermissionAllowed() {
    if(!this.isCordova) return;

    return this.diagnostic.isContactsAuthorized();
  }

  async getContactsPermissionStatus() {
    if(!this.isCordova) return;

    return this.diagnostic.getContactsAuthorizationStatus();
  }

  async showInstructions() {
    try {
      const alert = await this.alertController.create({
        header: "To allow access to your contacts, open your settings",
        message: "Make sure the Contacts switch is set to ON.",
        buttons: [
          { text: "Open Settings", handler: () => this.ons.open('application_details') }
        ]
      });
      await alert.present();
    } catch (err) {
      console.log(err);
    }
  }

}
