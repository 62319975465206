import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {HorizontalSliderComponent} from "./horizontal-slider.component";
import {CommonModule} from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { HorizontalSliderCardComponent } from "../horizontal-slider-card/horizontal-slider-card.component";
import { HorizontalSliderCardModule } from "../horizontal-slider-card/horizontal-slider-card.module";

@NgModule({
  entryComponents: [HorizontalSliderCardComponent],
  declarations: [HorizontalSliderComponent],
  imports: [CommonModule, IonicModule, HorizontalSliderCardModule],
  exports: [HorizontalSliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HorizontalSliderModule {}
