import {Pipe, PipeTransform} from "@angular/core";
import {Config} from "../services/config/config";
import {CachedPicturesService} from "../services/data/cached-pictures.service";

@Pipe({
  name: "imageFor"
})
export class ImageForPipe implements PipeTransform {

  private botPicture: string = './assets/img/chat-bot.png';
  private emptyPicture = '';

  constructor(private config: Config,
              private cachedPictureService: CachedPicturesService) {
    this.emptyPicture =
      `./assets/img/default-${['male', 'non-binary'].includes(this.config.get('gender')) ? '' : 'female-'}avatar.png`
  }

  transform(userId: any, tribe: any): any {
    if(userId == this.config.get('id')) return this.config.getPicture();
    if(userId == -5) return this.botPicture;
    
    let defaultImage = [...tribe.users, tribe.tribe_user].find(u => u.id == userId)?.picture || this.emptyPicture;

    return this.cachedPictureService.getTribes()
      ?.find(t => t?.id == tribe.id)
      ?.users
      .find(u => u?.id == userId)
      ?.cachedPicture || defaultImage
  }
}
