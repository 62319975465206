import { Injectable } from '@angular/core';
import {merge, Subject} from "rxjs";
import { logc } from "../shared/helpers/log";
import {UserService} from "./data/user.service";
import {TribesService} from "./data/tribes.service";
import {UtilsService} from "./utils.service";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {NavController} from "@ionic/angular";
import {FeedbackService} from "./popups/feedback.service";
import {AnalyticsService} from "./analytics/analytics.service";
import {isEmpty} from "../shared/helpers/lodash";
import {Config} from "./config/config";
import {DispatcherService} from "./dispatcher.service";
import {MatchesPageStates} from "../shared/constants/matches";
import { MatchPreferencesLocation } from '../shared/enums/match-preferences.enum';
import { getRandom, isDev } from '../shared/helpers/helpers';

export enum MatchStatus {
  Regular = 2,
  Recommended = 1
}
interface MatchesFetchOptions {
  force?: boolean;
  backgroundMode?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MatchesService {
  private initialized: boolean = false;

  public cardOptionsSelected = new Subject();
  public cardProfileAction = new Subject();
  public addedMatch = new Subject();
  public matchesFetched = new BehaviorSubject([]);
  public oppositeDescriptorsFetched = new BehaviorSubject([]);
  public stateChanged = new BehaviorSubject(null);
  public userSelected = new BehaviorSubject(null);
  public userRemoved = new BehaviorSubject(null);
  public fetchingMatches = new BehaviorSubject(false);

  private matchesState = MatchesPageStates.Standard;
  private oppositeDescriptors: any[] = [];
  private matches: any[] = [];
  private standardMatches: any[] = [];
  private hybridMatches: any[] = [];

  private selectedUser: any = null;
  private inProgressTribe: any = {};
  private feedbackModal: any = null;
  private matchingLocation: string = MatchPreferencesLocation.NearMe;

  public isDirty: boolean = false;

  //
  // The set of observers for variables. Key is a variable, value is a callback
  // firing every time variable is set.
  // ex: this.ctx.matchesState = something
  // will fire the value (which is an anonymous function)
  //
  // private ctx = observeVariables({
  //   matchesState: () => {
  //     this.ctx.matchesLength = this.getMatchesLength();
  //     this.stateChanged.next(this.ctx.matchesState)
  //   },
  //   selectedUser: () => this.userSelected.next(this.ctx.selectedUser),
  //   matchesLength: () => this.matchesLengthChanged.next(this.ctx.matchesLength)
  // })

  constructor(private userService: UserService,
              private feedbackService: FeedbackService,
              private analyticsService: AnalyticsService,
              private utils: UtilsService,
              private config: Config,
              private dispatcherService: DispatcherService,
              private tribesService: TribesService,
              private navCtrl: NavController) {
    (window as any).matchesService = this;
  }

  private setSelectedUser(user: any): void {
    this.selectedUser = user;
    this.userSelected.next(this.selectedUser);
  }

  private setState(state): void {
    this.matchesState = state;
    this.stateChanged.next(this.matchesState)
  }

  private setMatches(matches) {
    this.matches = matches;
    this.matches = this.matches
      .sort((a, b) => a.pool_status - b.pool_status)
      .sort((a, b) => (b.score - b?.details?.location?.distance) - (a.score - a?.details?.location?.distance));
    this.matchesFetched.next(this.matches);
  }

  private setOppositeDescriptors(oppositeDescriptors) {
    this.oppositeDescriptorsFetched.next(oppositeDescriptors);
  }

  async init(): Promise<any> {
    if(this.initialized) return;
    this.initialized = true;

    merge(
      this.tribesService.onTribesReady,
      this.tribesService.onTribeRemoved
    ).subscribe((tribes) => {
        this.inProgressTribe = this.tribesService.getTribes().find(tribe => tribe.status == 'in_progress') || {};
        if(!isEmpty(this.inProgressTribe)) {
          this.setSelectedUser(this.inProgressTribe.users[0]);
          this.setState(MatchesPageStates.Hybrid);
        } else {
          this.setSelectedUser(null);
          this.setState(MatchesPageStates.Standard);
        }
        this.showInfo();
      })

    this.tribesService
      .onUserSelectedForScheduledGroup
      .subscribe((user) => {
        this.removeFromList(this.matches, user);
      });

    this.matchingLocation = this.config.get("matchPreferences").location;

    this.cardProfileAction.subscribe(data => this.closeProfileDispatcher(data));
    this.cardOptionsSelected.subscribe(data => this.dismissDispatcher(data));
    this.addedMatch.subscribe(userData => this.addUser(userData));

    this.dispatcherService
      .matchesRecomputedSource
      .subscribe(() => {
        this.isDirty = true;
      });

    this.config
      .onMatchPreferencesChanged
      .subscribe((preferences) => {
        if(!this.config.onboardingDone()) return;

        this.matchingLocation = preferences?.location || MatchPreferencesLocation.NearMe;
        this.isDirty = true;
      })
  }

  async fetchUsers(options: MatchesFetchOptions = {
    force: false,
    backgroundMode: false
  }): Promise<any> {
    const logPrefix = "-- matches.service.ts fetchUsers() ";
    this.matches = [];
    this.fetchingMatches.next(true);

    logc.red(logPrefix + "Fetching users...");

    try {
      switch(this.matchesState) {
        case MatchesPageStates.Standard:
          if(!this.standardMatches.length || this.isDirty) {
            if(!options.backgroundMode) {
              this.utils.showLoading();
            }
            logc.red(logPrefix + "standard from server");
            const { matches, oppositeDescriptors } = await this.userService.getStandardMatches();
            logc.crimson("oppositeDescriptors: ", oppositeDescriptors);
            this.oppositeDescriptors = oppositeDescriptors;
            this.standardMatches = matches;
            this.isDirty = false;
          } else {
            logc.red(logPrefix + "standard from cache");
          }
          this.setMatches(this.standardMatches);
          this.setOppositeDescriptors(this.oppositeDescriptors);
          break;
        case MatchesPageStates.Hybrid:
          if(!this.hybridMatches.length || this.isDirty) {
            if(!options.backgroundMode) this.utils.showLoading();
            logc.red(logPrefix + "hybrid from server");
            this.hybridMatches = await this.userService.getHybridMatches(this.selectedUser.id);
            this.isDirty = false;
          } else {
            logc.red(logPrefix + "hybrid from cache");
          }
          this.setMatches(this.hybridMatches);
          break;
        default:
          break;
      }
      this.fetchingMatches.next(false);
    } catch ( e ) {
      logc.error("Matches service error: ", e);
      this.fetchingMatches.next(false);
    } finally {
      if(!options.backgroundMode) this.utils.doneLoading();
    }
  }

  private async addUser(user: any): Promise<any> {
    if(this.matchesState == MatchesPageStates.Hybrid) {
      this.tribesService
        .addUserToManualTribe(user.id, this.inProgressTribe.id)
        .then(() => {
          this.removeFromList(this.standardMatches, this.selectedUser);
          this.setState(MatchesPageStates.Standard);
          this.setSelectedUser(null);

          this.hybridMatches = [];
          this.navCtrl.navigateForward(`/tribes/${this.inProgressTribe.id}/highlights`)
        });
    } else if(this.matchesState == MatchesPageStates.Standard) {
      this.setState(MatchesPageStates.Hybrid);
      this.tribesService
        .addUserToManualTribe(user.id)
        .then(async (response:any) => {
          this.addUserToSelected(user);
          this.setMatches([]);

          await this.fetchUsers();

          this.removeFromList(this.matches, user);
          this.inProgressTribe.id = response['id'];
        });
    }
  }

  private addUserToSelected(data): void {
    this.setSelectedUser(this.matches.find(user => user.id == data.id));
  }

  async removeSelectedUser(): Promise<any> {
    this.setSelectedUser(null);
    this.hybridMatches = [];
    this.setState(MatchesPageStates.Standard);
    this.cancelTribe();

    await this.fetchUsers();
  }

  private removeFromList(list, { id }): void {
    list.splice(list.findIndex(user => user.id == id), 1);
  }

  private closeProfileDispatcher(userData) {
    const perform = {
      add: () => this.addUser(userData),
      dontFeel: () => this.notInterested(userData),
      block: () => this.block(userData),
      report: () => this.report(userData)
    };

    perform[ userData.action ]();
  }

  private async block(data) {
    await this.userService.block(data.id);
    await this.userService.removeUser(data);
    await this.utils.showGenericMessage("User Blocked.");
  }

  private async notInterested(data) {
    // await this.showMinimumTribeFilterPopup();
    await this.userService.notInterested(data.id);
    await this.userService.removeUser(data);
    await this.utils.showGenericMessage("User Removed.");
  }

  private async showMinimumTribeFilterPopup(){
    this.config.callOnce('minimumTribeFilterPopupShown',() => {
      this.dispatcherService.newPopupSource.next({ popupName: 'minimumTribeFilterPopup' })
    });
  }

  private async report(data) {
    try {
      this.feedbackModal = await this.feedbackService.createModal('report');
      this.feedbackModal.onClick.subscribe(reason => this.sendReport(data, reason));
    } catch ( err ) {
      console.log( err );
    }
  }

  private async sendReport(data: any, reason: string): Promise<any> {
    this.analyticsService.trackEvent({
      key: 'reported_user',
      value: 1,
      reason: this.utils.underscoreCase(reason),
      reported_users_ids: [data.id],
    });
    this.feedbackModal.close();
    await this.userService.blockAndReport(data.id);
    await this.userService.removeUser(data);
    return this.utils.showGenericMessage("User Reported.")
  }

  private dismissDispatcher(data) {
    if(!data) return;

    this.removeFromList(this.matches, data);

    const actions = {
      not_interested: () => this.notInterested(data),
      block: () => this.block(data),
      report: () => this.report(data)
    };

    actions[data.action]();
  }

  cancelTribe() {
    this.tribesService.cancelTribe(this.inProgressTribe);
  }

  private showInfo() {
    logc.info("Matches service info: ", {
      inProgressTribe: this.inProgressTribe,
      matchesState: this.matchesState,
      matchesLength: this.matches.length,
      isDirty: this.isDirty,
    })
  }
}
