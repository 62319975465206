import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { RouteTrackerService } from '../route-tracker.service';
var stringify = require('json-stringify-safe');
var StackTrace = require('stacktrace-js');

@Injectable({
  providedIn: 'root'
})
export class CrashReportService {
  public instance: any = null;
  loaded = false;
  loadPromise: any = null;

  constructor(private platform: Platform, 
              private firebaseX: FirebaseX,
              private routeTrackerService: RouteTrackerService,
              ) {
  }

  async logException(error, message = "") {
    let str = "";
    if(message.length > 0) {
      str = message + " "
    }

    const page = this.routeTrackerService.getCurrentPage();
    str = `${page} ${str}`;

    if(error.message) {
      str += error.message;
    }

    if((typeof error) === 'object' && (typeof error.stacktrace) !== 'undefined') {
      StackTrace.fromError(error).then((stackframes:any) => {
        str = str + '\n' + stackframes
          .splice(0, 20)
          .map((sf:any) => {
            return sf.toString();
          }).join('\n');

        this.instance && this.instance.logError(str);
      });
    } else {
      this.instance && this.instance.logError(str);
    }
  }

  formReport({ className, functionName, reason, }) {
    return `${ className } - ${ functionName } threw exception after ${ reason } action.`;
  }

  setUserId(id) {
    this.instance && this.instance.setCrashlyticsUserId(id);
  }

  load() {
    if(this.loadPromise)
      return this.loadPromise;

    this.loadPromise = new Promise<void>(async (isReady, failed) => {
      this.loaded = true;
      if(this.platform.is('cordova')) {
        this.instance = this.firebaseX;
      } else {
        this.instance = null;
      }
      (<any> window).crashReport = this.instance;
      (<any> window).StackTrace = StackTrace;
      isReady();
    });
  }
}
