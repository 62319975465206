import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty, startsWith, values } from 'lodash';
import { BehaviorSubject, combineLatest, from, merge, Observable, of, Subject, zip, interval } from 'rxjs';
import { filter, map, scan, startWith, tap, timeoutWith } from 'rxjs/operators';
import { SmartSuggestionPage, TribeInteractable } from '../pages/smart-suggestion/smart-suggestion.page';
import { logc } from '../shared/helpers/log';
import { Config } from './config/config';
import { ApiService } from './data/api.service';
import { TribesService } from './data/tribes.service';
import { UserService } from './data/user.service';
import { ModalService } from './popups/modal.service';

import * as _ from "lodash";
import { TribeHighlightsService } from './tribe-highlights.service';
import { UserTribeStatus } from '../shared/enums/user-tribe-status.enum';
import { throws } from 'assert';
import { AnalyticsService } from './analytics/analytics.service';
import { environment } from 'src/environments/environment';
import { getUserName, isDev } from '../shared/helpers/helpers';
import { TribeHighlightsPage } from '../pages/tribe-highlights/tribe-highlights.page';
import { isThisHour } from 'date-fns';

const UPDATE_DELAY = 2000;
const SENDING_SAVED_VOTE_INTERVAL = 1000;

interface Votes {
  [key: number | string]: Array<string | number>
}

export enum TopicAction {
  AddCustomTopic = 1,
  Vote = 2
}

export interface Topic {
  id?: number | string;
  topic?: string;
  background?: string;
  field?: string;
  follow_ups?: string;
  image_url?: string;
  subject?: string;
  user_id?: number;
  summary?: string;
  selected?: boolean;
  votes?: any[];
}

export interface Activity {
  id?: number | string;
  type?: string;
  background?: string;
  field?: string;
  follow_ups?: string;
  image_url?: string;
  subject?: string;
  summary?: string;
  selected?: boolean;
  votes?: number[];
}

export interface TopicGroups {
  [key: string]: Topic[]
}

@Injectable({
  providedIn: 'root'
})
export class TopicsService {
  private currentUser: any = {}; 
  private votesByUsersIds: Votes = {};
  private votesByTopicsIds: any = {};
  private topics: Topic[] = [];
  private groups: TopicGroups;
  private groups$: BehaviorSubject<TopicGroups> = new BehaviorSubject({});
  private updateTimeout: any = null;
  private currentTribe: any = null;
  private voteSource: Subject<any> = new Subject();
  
  private topTopics$: Observable<any>;
  private topActivities$: Observable<any>;

  public onCustomTopicAdded: Subject<any> = new Subject();

  private votesBuffer: { [key: string]: any[] } = {};

  public topicsVotesUpdateSource: Subject<any> = new Subject();
  public onTopicsVotesUpdates: Observable<any>;

  public initialized: boolean = false;

  private topicsWithVotes$: Observable<any>;

  constructor(private http: HttpClient,
              private userService: UserService,
              private config: Config,
              private analyticsService: AnalyticsService,
              private apiService: ApiService,
              private modalService: ModalService,
              private tribesService: TribesService,
              private tribeHighlightsService: TribeHighlightsService) {
    if(isDev()) {
      (window as any).topicsService = this;
    }
    this.onTopicsVotesUpdates = this.topicsVotesUpdateSource.asObservable();
  }

  reset() {
    this.currentTribe = null;
    this.currentUser = null;
    this.groups = null;
    this.topTopics$ = null;
    this.votesByUsersIds = null;
    this.votesByTopicsIds = null;
    this.initialized = false;
  }

  async init() {
    if(this.initialized) return;
    await this.config.load();

    this.votesBuffer = this.config.get("votesBuffer") || {};

    this.initialized = true;
    this.currentUser = this.config.getProfile();

    this.tribesService
      .onUserJoined
      .subscribe(({ tribe_id: tribeId }) => {
        let votes = this.votesBuffer[tribeId];
        if(!votes) return;
        zip(
          interval(SENDING_SAVED_VOTE_INTERVAL),
          from(votes)
        ).pipe(tap(([tick, topicId]) => this.sendVote(tribeId, topicId)))
         .subscribe();

        delete this.votesBuffer[tribeId];
        this.config.set("votesBuffer", this.votesBuffer);
      });

      this.tribesService.onTribeChanged.subscribe(tribe => {
        if(tribe.id == this.currentTribe?.id) {
          this.currentTribe = tribe;
        }
      })

    merge(
      this.tribesService.onTribeLoaded,
      this.tribesService.onTribeChanged,
      this.tribeHighlightsService.onDataLoad
    ).subscribe(async (tribe: any) => {
      if(isEmpty(tribe)) return;

      logc.yellow("TRIBE: ", tribe);
      if(this.topics.length == 0) {
        this.topics = await this.getTopics();
      }
      if(this.currentTribe?.id == tribe.id) {
        logc.indigo("Topics service tribe is already loaded");
        return;
      }
      this.currentTribe = tribe;
      this.prepareData();
    });

    this.onTopicsVotesUpdates.subscribe(({ payload }) => {
      const { tribe_id: tribeId, data } = payload;
      if(this.currentTribe?.id != tribeId) return;

      this.updateVotes(data.topic_votes);
      if(data?.suggested_topics) {
        const newTopics = 
          this.suggestedTopicsToNormalTopics(data.suggested_topics)
            .filter(topic => !this.topics.map(t => t.id).includes(topic.id));
        logc.crimson("New custom topics: ", newTopics);
        newTopics.forEach(topic => {
          this.topics.uniqPush(topic);
          if(!this.votesByTopicsIds[topic.id]) {
            this.votesByTopicsIds[topic.id] = [topic.user_id];
          } else {
            this.votesByTopicsIds[topic.id].push(topic.user_id);
          }
          if(!this.votesByUsersIds[topic.user_id]) {
            this.votesByUsersIds[topic.user_id] = [topic.id];
          } else {
            this.votesByUsersIds[topic.user_id].push(topic.id);
          }
          this.onCustomTopicAdded.next({ 
            action: TopicAction.AddCustomTopic,
            payload: topic
          })
        })
      }
    });

    logc.indigo("** Topics service initialized **");
  }

  prepareData(): void {
    this.prepareVotes();
    const { customTopics, customTopicsFields } = this.prepareCustomTopics();
    this.addVotesToTopics([ ...this.topics, ...customTopics ]);
    this.groups = this.groupTopics({ 
      ...this.currentTribe, 
      topic_fields: [ 
        ...this.currentTribe.topic_fields, 
        ...customTopicsFields 
      ]
    }, this.topics, this.votesByTopicsIds);
    this.groups$.next(this.groups);
    this.topTopics$ = this.groupTopTopics();
  }

  suggestedTopicsToNormalTopics(suggestedTopics): Topic[] {
    logc.crimson("here: ", suggestedTopics);
    const mapTopic = (data) => ({
      id: data[0],
      topic: data[1].topic,
      summary: data[1].summary,
      subject: `✏️ Added by ${ getUserName(data[1]?.user_id, this.currentTribe) }`,
      field: `Added by ${ getUserName(data[1]?.user_id, this.currentTribe) }`,
      user_id: data[1]['user_id'],
      selected: this.config.get("id") == data[1]?.user_id,
      votes: [[ 
        ...this.currentTribe.users, 
        this.currentTribe.tribe_user 
      ].find(user => user.id == data[1]?.user_id)]
    })
    return Object.entries(suggestedTopics).map(mapTopic);
  }

  getTopTopics(): Observable<Topic[]> {
    return this.topTopics$;
  }

  prepareVotes(): void {
    this.votesByUsersIds = 
      this.currentTribe?.data?.topic_votes || 
      { [this.currentUser.id]: this.votesBuffer[this.currentTribe.id] || [] } ||
      {};
    this.votesByTopicsIds = this.getVotesByTopicsIds(this.votesByUsersIds);
  }

  addVotesToTopics(topics) {
    this.topics = topics.map(topic => ({ 
      ...topic, 
      ...{
        selected: this.votesByTopicsIds[topic.id]?.includes(this.currentTribe.tribe_user.id),
        votes: (this.votesByTopicsIds[topic.id] || [])
          .map(userId => 
            [this.currentTribe.tribe_user, ...this.currentTribe.users]
              .find(user => user.id == userId))
        }
    }))
  }

  prepareCustomTopics(): { customTopics: Topic[], customTopicsFields: string[]} {
    const customTopics: Topic[] = 
      Object.entries(this.currentTribe?.data?.suggested_topics || [])
        .map(([id, data]) => ({ 
          id, 
          subject: `✏️ Added by ${ getUserName(data['user_id'], this.currentTribe) }`,
          field: `Added by ${ getUserName(data['user_id'], this.currentTribe) }`,
          ...(data as any) 
        }))
    const customTopicsFields = 
      customTopics
        .map(e => e.user_id)
        .map(id => `Added by ${ getUserName(id, this.currentTribe) }`);
    return {
      customTopics,
      customTopicsFields
    }
  }

  updateVotes(newVotes): void {
    const votesByTopicsIds = this.getVotesByTopicsIds(newVotes);
    logc.crimson("votesByTopicsIds: ", votesByTopicsIds);
    this.topics
      .forEach((topic) => {
        topic.votes = [ 
          this.currentTribe.tribe_user, 
          ...this.currentTribe.users
        ].filter(({ id }) => votesByTopicsIds[topic.id]?.includes(id)) || [];
      })
  }

  async openTopicModal(topic: Topic): Promise<any> {
    const state = this.topicToTribeInteractable(topic);
    delete state.topic;
    logc.crimson("State: ", state);
    logc.info("Custom topic: ", topic);
    const initialBreakpoint = topic?.subject.match("Added by") ? 0.5 : 1;
    return this.modalService.create({
      component: SmartSuggestionPage,
      componentProps: { 
        state,
        entityType: "topic",
        callback: () => 
          this.vote({ 
            topic, 
            user: this.userService.getCurrentUser(),
            updateApi: this.currentTribe?.tribe_user?.status != UserTribeStatus.Invited
          }),
        tribe: this.currentTribe 
      },
      breakpoints: [0, initialBreakpoint],
      initialBreakpoint
    })
  }

  topicToTribeInteractable(data: Topic): any {
    return {
      title: data?.topic,
      ...data
    }
  }

  async getTopics(): Promise<Topic[]> {
    return Promise.resolve(await this.http.get<Topic[]>("./assets/data/group_topics.json").toPromise());
  }

  groupTopTopics(): Observable<Topic[]> {
    return combineLatest(
      merge(
        this.voteSource.pipe(startWith(null)), 
        this.onTopicsVotesUpdates.pipe(startWith(null)),
        this.onCustomTopicAdded.pipe(startWith(null))
      ),
      from(this.topics)
    ).pipe(
      filter(([event, topic]) => topic.votes.length > 0 || event?.action),
      scan((acc, [event, topic]) => {
        console.log("event: ", event);
        if(!event && !acc.map(e => e.id).includes(topic.id)) {
          acc = [ ...acc, topic];
        }

        if(event?.action == TopicAction.Vote) {
          acc = acc.filter(e => e.votes.length > 0);
        }

        if(event?.action == TopicAction.AddCustomTopic) {
          acc = [ ...acc, event.payload ];
        }

        return acc;
      }, [])
    )
  }

  createCustomTopic(payload) {
    const { topic, summary } = payload;
    this.apiService.post(`tribes/${ this.currentTribe.id }/topics`, {
      tribe_id: this.currentTribe.id, 
      topic, 
      summary: summary || ""
    })
    // this.onCustomTopicAdded.next({ action: TopicAction.AddCustomTopic, payload });
  }

  groupTopics(tribe, topics, votesByTopicsIds): TopicGroups {
    return Object.fromEntries(
      tribe.topic_fields
        .map((fields) => 
          fields
            .toLowerCase()
            .split('&')
            .map(field => field.trim()))
        .map((fields) => 
          [ 
            fields.map(field => field.capitalize()).join(" & "), 
            [ ...topics.filter((topic) => 
              topic.field
                .split(',')
                .map((field) => field.toLowerCase())
                .some(field => fields.includes(field))
              )
            ].sort(() => Math.random() - 0.5)
          ]
        )
        .filter(([category, topics]) => topics.length)
    )
  }

  getVotesByTopicsIds(votes: Object) {
    const votedTopicsIds = [ ...new Set(Object.values(votes).reduce((acc, e) => [...acc, ...e], []))];
    return Object.fromEntries(votedTopicsIds.map((topicId) => [
      topicId, 
      Object.entries(votes)
        .reduce((acc, [userId, topicsIds]) => [
          ...acc, 
          (topicsIds as any[]).includes(topicId) ? userId : null], [])
        ])
        .map(([topicId, userIds]) => 
          [ 
            topicId, 
            (userIds as any[])
              .filter(Boolean)
              .map(value => +value) 
          ])
    )
  }

  getTopicGroups(): BehaviorSubject<TopicGroups> {
    return this.groups$;
  }

  getNoCustomTopicGroups(): BehaviorSubject<TopicGroups> {
    return this.groups$;
  }

  async createTopic({ tribeId, topic, summary }) {
    try {
      await this.apiService.post(`tribes/${ tribeId }/topics`, { topic, summary, tribe_id: tribeId });
      const Topic: Topic = {
        topic,
        summary,
        votes: [ this.config.getProfile() ]
      }
    } catch(e) {
      logc.error("** Error creating custom topics: ", e);
    }
  }

  private sendVote(tribeId, topicId) {
    return this.apiService.post(`tribes/${ tribeId }/topic_votes/${ topicId }`, {});
  }

  private deleteVote(tribeId, topicId) {
    return this.apiService.delete(`tribes/${ tribeId }/topic_votes/${ topicId }`, {});
  }

  vote({ topic, user, updateApi = true }): Promise<any> {
    clearTimeout(this.updateTimeout);

    const userInvited = this.currentTribe.tribe_user.status == UserTribeStatus.Invited;
    
    let { id: topicId } = topic;
    let { id: userId } = user;

    topicId = String(topicId);
    userId = String(userId);

    this.votesByUsersIds[userId] = this.votesByUsersIds[userId] || [];
    let userVotes = this.votesByUsersIds[userId];
    let promise = new Promise((resolve, reject) => resolve(null));
    if(!userVotes.includes(topicId)) {
      userVotes.push(topicId);
      this.topics
        .filter((topic: any) => topic.id == topicId)
        .forEach((topic: any) => {
          topic.votes.push(user);
          topic.selected = true;
        });

      if(updateApi) {
        if(this.currentUser.id == user.id) {
          promise = this.sendVote(this.currentTribe.id, topicId)
        }
      } else {
        if(!this.votesBuffer[this.currentTribe.id]) {
          this.votesBuffer[this.currentTribe.id] = [];
        }
        this.votesBuffer[this.currentTribe.id].uniqPush(topicId);
      }
      this.sendAnalytics('topic_saved', topic);
    } else {
      logc.crimson("Voting against");
      userVotes.remove(topicId);
      this.topics
        .filter((topic: any) => topic.id == topicId)
        .forEach((topic: any) => {
          topic.votes.removeBy('id', user);
          topic.selected = false;
        });
      if(updateApi) {
        if(this.currentUser.id == user.id) {
          promise = this.deleteVote(this.currentTribe.id, topicId);
        }  
      } else {
        this.votesBuffer[this.currentTribe.id] = 
          this.votesBuffer[this.currentTribe.id].filter(id => id != topicId);
        if(this.votesBuffer[this.currentTribe.id].length == 0) {
          delete this.votesBuffer[this.currentTribe.id];
        }
      }
      this.sendAnalytics("topic_unsaved", topic);
    }
    if(userInvited) {
      this.config.set("votesBuffer", this.votesBuffer);
    }
    this.voteSource.next({ payload: topic, action: TopicAction.Vote });
    return promise;
  }

  sendAnalytics(key, { topic, field: fields}) {
    this.analyticsService.trackEvent({
      key,
      value: 1,
      tribe_id: this.currentTribe.id,
      topic,
      fields
    })
  }
}
