import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEmpty } from "lodash";
import {
  BehaviorSubject,
  combineLatest,
  from,
  interval,
  merge,
  Observable,
  of,
  Subject,
  zip,
} from "rxjs";
import { filter, map, scan, startWith, tap } from "rxjs/operators";
import {
  SmartSuggestionPage,
  TribeInteractable,
} from "../pages/smart-suggestion/smart-suggestion.page";
import { UserTribeStatus } from "../shared/enums/user-tribe-status.enum";
import { getUserName, isDev } from "../shared/helpers/helpers";
import { logc } from "../shared/helpers/log";
import { AnalyticsService } from "./analytics/analytics.service";
import { Config } from "./config/config";
import { ApiService } from "./data/api.service";
import { TribesService } from "./data/tribes.service";
import { ModalService } from "./popups/modal.service";
import { Activity } from "./topics.service";
import { TribeHighlightsService } from "./tribe-highlights.service";

export enum ActivityAction {
  Vote = 1,
  AddCustomActivity = 2,
}

const SENDING_SAVED_VOTE_INTERVAL = 1000;

@Injectable({
  providedIn: "root",
})
export class ActivitiesService {
  public testActivities: any[] = [];
  public updateTimeout;
  public votesBuffer: any = {};
  private currentTribe: any = {};
  private currentUser: any = null;
  public initialized: boolean = false;
  public activities: any[] = [];
  public votesByActivityId: any = {};
  public votesByUserId: any = {};
  private groupedActivities: any = {};

  public onCustomActivityAdded: Subject<any> = new Subject();
  private activities$: BehaviorSubject<any> = new BehaviorSubject([]);
  public activitiesVotesUpdateSource: Subject<any> = new Subject();
  public onActivitiesVotesUpdates: Observable<any>;
  public voteSource: Subject<any> = new Subject();

  constructor(
    private apiService: ApiService,
    private config: Config,
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private tribesService: TribesService,
    private tribeHighlightsService: TribeHighlightsService
  ) {
    this.onActivitiesVotesUpdates =
      this.activitiesVotesUpdateSource.asObservable();
    if (isDev()) {
      (window as any).activitiesService = this;
    }
  }

  reset(): Promise<void> {
    return new Promise((resolve) => {
      this.votesBuffer = [];
      this.currentTribe = {};
      this.currentUser = null;
      this.initialized = false;
      this.activities = [];
      this.votesByActivityId = {};
      this.votesByUserId = {};
      this.groupedActivities = {};
      resolve();
    });
  }

  setTestData() {
    this.testActivities = [
      {
        image_url: "",
        id: 1,
        type: "Football",
        activity: "Sport",
        summary: "",
        cost: null,
        duration: null,
      },
      {
        image_url: "",
        id: 2,
        type: "Basketball",
        activity: "Sport",
        summary: "",
        cost: null,
        duration: null,
      },
      {
        image_url: "",
        id: 3,
        type: "Hiking",
        activity: "Outdoors",
        summary: "",
        cost: null,
        duration: null,
      },
    ];
  }

  // groupActivities(activities): Object {
  //   return Object.fromEntries([
  //     ...new Set(
  //       activities.map(activity => activity.activity_type)
  //     )
  //   ].filter(Boolean)
  //    .map(activityType => ([
  //      activityType,
  //      activities.filter(activity => activity.activity_type == activityType)
  //    ])))
  // }

  async getLocalActivities(): Promise<any[]> {
    return this.http
      .get<any>("./assets/data/group_activities.json")
      .toPromise();
  }

  async init() {
    const localActivities: any[] = await this.getLocalActivities();
    logc.crimson("local activities: ", localActivities);
    if (this.initialized) return;
    this.initialized = true;

    this.currentUser = this.config.getProfile();

    this.votesBuffer = this.config.get("activityVotesBuffer") || {};

    this.tribesService.onUserJoined.subscribe(({ tribe_id: tribeId }) => {
      let votes = (this.votesBuffer || {})[tribeId];
      if (!votes) return;
      zip(interval(SENDING_SAVED_VOTE_INTERVAL), from(votes))
        .pipe(tap(([tick, topicId]) => this.sendVote(tribeId, topicId)))
        .subscribe();

      delete this.votesBuffer[tribeId];
      this.config.set("activityVotesBuffer", this.votesBuffer);
    });

    merge(
      this.tribesService.onTribeLoaded,
      this.tribesService.onTribeChanged,
      this.tribeHighlightsService.onDataLoad
    ).subscribe(async (tribe: any) => {
      if (isEmpty(tribe)) return;
      this.currentTribe = tribe;
      this.votesByUserId = this.currentTribe?.data?.activity_votes || {};
      this.votesByActivityId = this.getVotesByActivityIds(this.votesByUserId);
      this.activities = [
        ...localActivities,
        ...this.formatSuggestedActivities(
          this.currentTribe?.data?.suggested_activities
        ),
      ];
      this.addVotes(this.activities, this.currentTribe?.data?.activity_votes);
      this.groupedActivities = this.groupActivities(
        this.currentTribe,
        this.activities
      );

      this.activities$.next(this.groupedActivities);
    });

    this.onActivitiesVotesUpdates.subscribe(({ payload }) => {
      const { tribe_id: tribeId, data } = payload;
      if (this.currentTribe?.id != tribeId) return;

      this.updateVotes(data.activity_votes);
      if (data?.suggested_activities) {
        const newActivities = this.formatSuggestedActivities(
          data.suggested_activities
        ).filter(
          (activity) => !this.activities.map((t) => t.id).includes(activity.id)
        );
        logc.crimson("New custom activities: ", newActivities);
        newActivities.forEach((activity) => {
          this.activities.uniqPush(activity);
          if (!this.votesByActivityId[activity.id]) {
            this.votesByActivityId[activity.id] = [activity.user_id];
          } else {
            this.votesByActivityId[activity.id].push(activity.user_id);
          }
          if (!this.votesByUserId[activity.user_id]) {
            this.votesByUserId[activity.user_id] = [activity.id];
          } else {
            this.votesByUserId[activity.user_id].push(activity.id);
          }
          this.onCustomActivityAdded.next({
            action: ActivityAction.AddCustomActivity,
            payload: activity,
          });
        });
      }
    });
  }

  groupActivities(tribe, activities): any {
    //console.log('fucked!!!', 
    //activities.filter(activity => activity.activity_type === undefined));
    // below we filter out the activity_type undefined, but we should include 
    // them if we re-introduce the custom activities suggestions

    return Object.fromEntries(
      [...new Set(activities
            .filter(activity => activity?.activity_type)
            .map((activity) => activity?.activity_type))].map(
        (activityType: string) => [
          activityType,
          activities.filter(
            (activity) =>
              activity?.activity_type && activity?.activity_type == activityType
          ),
        ]
      )
    );
  }

  updateVotes(newVotes) {
    const votesByTopicsIds = this.getVotesByActivityIds(newVotes);
    logc.crimson("votesByActivitiesIds: ", votesByTopicsIds);
    this.activities.forEach((topic) => {
      topic.votes =
        [this.currentTribe.tribe_user, ...this.currentTribe.users].filter(
          ({ id }) => votesByTopicsIds[topic.id]?.includes(id)
        ) || [];
    });
  }

  getVotesByActivityIds(votes: Object) {
    const votedTopicsIds = [
      ...new Set(Object.values(votes).reduce((acc, e) => [...acc, ...e], [])),
    ];
    return Object.fromEntries(
      votedTopicsIds
        .map((topicId) => [
          topicId,
          Object.entries(votes).reduce(
            (acc, [userId, topicsIds]) => [
              ...acc,
              (topicsIds as any[]).includes(topicId) ? userId : null,
            ],
            []
          ),
        ])
        .map(([topicId, userIds]) => [
          topicId,
          (userIds as any[]).filter(Boolean).map((value) => +value),
        ])
    );
  }

  addVotes(activities, votes = []): void {
    this.activities = activities.map((activity) => ({
      ...activity,
      selected: this.votesByActivityId[activity.id]?.includes(
        this.currentTribe.tribe_user.id
      ),
      votes: (this.votesByActivityId[activity.id] || []).map((id) =>
        [this.currentTribe.tribe_user, ...this.currentTribe.users].find(
          (user) => user.id == id
        )
      ),
    }));
  }

  formatSuggestedActivities(activities) {
    const mapActivity = (data) => ({
      id: data[0],
      activity: data[1].activity,
      summary: data[1].summary,
      subject: `✏️ Added by ${getUserName(
        data[1]?.user_id,
        this.currentTribe
      )}`,
      field: `Added by ${getUserName(data[1]?.user_id, this.currentTribe)}`,
      user_id: data[1]["user_id"],
      selected: this.config.get("id") == data[1]?.user_id,
      votes: [
        [...this.currentTribe.users, this.currentTribe.tribe_user].find(
          (user) => user.id == data[1]?.user_id
        ),
      ],
    });
    return Object.entries(activities || {}).map(mapActivity);
  }

  getTopActivities() {
    return combineLatest(
      merge(
        this.onCustomActivityAdded.pipe(startWith(null)),
        this.voteSource.pipe(startWith(null)),
        this.activitiesVotesUpdateSource.pipe(startWith(null))
      ),
      from(this.activities)
    ).pipe(
      filter(([event, el]) => el?.votes?.length > 0 || event?.action),
      scan((acc, [event, el]) => {
        if (!event && !acc.map((e) => e.id).includes(el.id)) {
          acc = [...acc, el];
        }

        if (event?.action == ActivityAction.Vote) {
          acc = acc.filter((e) => e.votes.length > 0);
        }

        if (event?.action == ActivityAction.AddCustomActivity) {
          acc = [...acc, event.payload];
        }
        return acc;
      }, [])
    );
  }

  getActivities() {
    return this.activities$;
  }

  createCustomActivity(activity, summary = "") {
    this.apiService.post(`tribes/${this.currentTribe.id}/activities`, {
      tribe_id: this.currentTribe.id,
      activity,
      summary,
    });
    // this.onCustomActivityAdded.next({ action: TopicAction.AddCustomTopic, payload });
  }

  activityToTribeInteractable(activity): TribeInteractable {
    logc.indigo("opening activity: ", activity);
    const title = activity?.activity;
    return {
      title,
      subject: activity?.activity,
      ...activity,
    };
  }

  openActivityModal(activity: Activity) {
    const state = this.activityToTribeInteractable(activity);
    // logc.crimson("openActivityModal state: ", state);
    const initialBreakpoint = activity?.subject.match("Added by") ? 0.5 : 1;
    return this.modalService.create({
      component: SmartSuggestionPage,
      componentProps: {
        state,
        entityType: "activity",
        callback: () => {
          this.vote({
            activity,
            user: this.config.getProfile(),
            updateApi:
              this.currentTribe?.tribe_user?.status != UserTribeStatus.Invited,
          });
        },
        tribe: this.currentTribe,
      },
      breakpoints: [0, initialBreakpoint],
      initialBreakpoint,
    });
  }

  private sendVote(tribeId, topicId) {
    return this.apiService.post(
      `tribes/${tribeId}/activity_votes/${topicId}`,
      {}
    );
  }

  private deleteVote(tribeId, topicId) {
    return this.apiService.delete(
      `tribes/${tribeId}/activity_votes/${topicId}`,
      {}
    );
  }

  vote({ activity, user, updateApi = true }): Promise<any> {
    clearTimeout(this.updateTimeout);

    const userInvited =
      this.currentTribe.tribe_user.status == UserTribeStatus.Invited;

    let { id: activityId } = activity;
    const { id: userId } = user;

    activityId = "" + activityId;

    this.votesByUserId[userId] = this.votesByUserId[userId] || [];
    let userVotes = this.votesByUserId[userId];
    let promise = new Promise((resolve, reject) => resolve(null));
    if (!userVotes.includes(activityId)) {
      userVotes.push(activityId);
      this.activities
        .filter((activity: any) => activity.id == activityId)
        .forEach((activity: any) => {
          activity.votes.push(user);
          activity.selected = true;
        });

      if (updateApi) {
        if (this.currentUser.id == user.id) {
          promise = this.sendVote(this.currentTribe.id, activityId);
        }
      } else {
        if (!this.votesBuffer[this.currentTribe.id]) {
          this.votesBuffer[this.currentTribe.id] = [];
        }
        this.votesBuffer[this.currentTribe.id].uniqPush(activityId);
      }
      this.sendAnalytics("activity_saved", activity);
    } else {
      userVotes.remove(activityId);
      this.activities
        .filter((activity: any) => activity.id == activityId)
        .forEach((activity: any) => {
          activity.votes.removeBy("id", user);
          activity.selected = false;
        });
      if (updateApi) {
        if (this.currentUser.id == user.id) {
          promise = this.deleteVote(this.currentTribe.id, activityId);
        }
      } else {
        this.votesBuffer[this.currentTribe.id] = this.votesBuffer[
          this.currentTribe.id
        ].filter((id) => id != activityId);
        if (this.votesBuffer[this.currentTribe.id].length == 0) {
          delete this.votesBuffer[this.currentTribe.id];
        }
      }
      this.sendAnalytics("activity_unsaved", activity);
    }
    if (userInvited) {
      this.config.set("activityVotesBuffer", this.votesBuffer);
    }
    this.voteSource.next({ payload: activity, action: ActivityAction.Vote });
    return promise;
  }

  sendAnalytics(key, { topic, field: fields }) {
    this.analyticsService.trackEvent({
      key,
      value: 1,
      tribe_id: this.currentTribe.id,
      topic,
      fields,
    });
  }
}
