import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';

import { SessionService } from '../services/data/session.service';
import { InstagramService } from '../services/instagram.service';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { Config } from '../services/config/config';

@Injectable({
  providedIn: 'root',
})
export class InstagramAuthResolver {
  constructor(public navCtrl: NavController,
              public sessionService: SessionService,
              public instagramService: InstagramService,
              public analyticsService: AnalyticsService,
              public config: Config,
             ) { }

  async resolve(route:ActivatedRouteSnapshot): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const code = route.queryParams['code'];
      console.log("--- instagram-auth.resolver.ts resolve() queryParams['code']: ", code);
      await this.config.load();
      this.analyticsService.trackEvent({ 
        key: 'instagram_redirect', 
        value: 1,  
        step: 'instagram_auth_resolver',
      });
      this.instagramService.getAccessToken(code).then(
          async res => {
            await this.config.updateProfile({ instagramToken: res });
            this.analyticsService.trackEvent({ key: 'instagram_set', value: 1 });
            this.navCtrl.navigateRoot('tabs/profile');
            resolve();
          }
        ).catch( err => { 
          console.log(err);
          this.navCtrl.navigateRoot('tabs/profile');
          this.analyticsService.trackEvent({ 
            key: 'connect_instagram_error', 
            value: 1, 
            ctx: JSON.stringify(err), 
            step: 'instagram_auth_resolver',
          });
          reject();
        });
    });
  }
}
