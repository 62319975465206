import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import { NavController } from '@ionic/angular';

import { ApiService } from '../services/data/api.service';
import { SessionService } from '../services/data/session.service';
import {first} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class TribeSmsLinkResolver implements Resolve<any> {
  constructor(public navCtrl: NavController,
              public api: ApiService,
              public sessionService: SessionService,
             ) {
  }

  async resolve(route:ActivatedRouteSnapshot) {
    const token = route.paramMap.get('token');
    await this.sessionService.tribeCodeLogin(token);
  }
}
