import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomAlertComponent } from "./custom-alert.component";
import {IonicModule} from "@ionic/angular";

@NgModule({
  declarations: [
    CustomAlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  exports: [
    CustomAlertComponent
  ]
})
export class CustomAlertModule {}
