import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import { MeetupCalendarComponent } from './meetup-calendar.component';
import { DatePickerWheelComponent } from '../date-picker-wheel/date-picker-wheel.component';
import { DatePickerWheelModule } from '../date-picker-wheel/date-picker-wheel.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MeetupStatusSwitcherComponent } from '../meetup-status-switcher/meetup-status-switcher.component';
import { MeetupStatusSwitcherModule } from '../meetup-status-switcher/meetup-calendar.module';

@NgModule({
  entryComponents: [
    DatePickerWheelComponent,
    MeetupStatusSwitcherComponent
  ],
  declarations: [MeetupCalendarComponent],
  imports: [
    PipesModule,
    DatePickerWheelModule,
    MeetupStatusSwitcherModule,
    CommonModule,
    IonicModule
  ],
  exports: [MeetupCalendarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class MeetupCalendarModule {}