import {AfterContentChecked, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: 'input[inputAutoFocus]'
})
export class InputAutoFocusDirective implements AfterContentChecked {
  constructor(private element: ElementRef) {}

  ngAfterContentChecked() {
    this.element.nativeElement.focus();
  }

}