<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        [routerLink]="['', 'tabs', 'tribes']"
        routerDirection="back"
        defaultHref="/tabs/tribes"
        [text]="''"
      >
      </ion-back-button>
    </ion-buttons>
    <ion-title style="padding-left: 35px; padding-right: 35%"
      >{{ title }}</ion-title
    >
    <ion-buttons slot="end">
      <!-- <ion-button *ngIf = "proposeUsingApp" class = "use-the-app-button">
        <use-the-app></use-the-app>
      </ion-button> -->
      <ion-button>
        <div
          class="offline-tag"
          [class.visible]="isOffline"
          [class.no-pointer-events]="!isOffline"
          (click)="openOfflinePopup()"
        >
          OFFLINE
        </div>
      </ion-button>
      <ion-button (click)="toHighlights()">
        <ion-icon class="chat-icon" name="tribes"></ion-icon>
      </ion-button>
      <ion-button (click)="openMeetupPage()">
        <ion-icon class="chat-icon" name="calendar"></ion-icon>
      </ion-button>
      <ion-button class="relative" (click)="showDetails($event)">
        <div
          *ngIf="hasReplaceableUsers"
          class="absolute w-[8px] h-[8px] rounded-full bg-error top-0 right-0"
        ></div>
        <ion-icon class="chat-icon" name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="spinner-container top" [class.shown]="loadingMore">
    <ion-spinner></ion-spinner>
  </div>
</ion-header>

<status [page]="'chat'" [status]="tribeStatus"></status>

<removal-statuses [statuses]="removalStatuses"></removal-statuses>

<ion-content
  #chat
  [attr.noScroll]="shouldScroll"
  scrollY="true"
  class="chat"
  scrollEvents="true"
  (ionScroll)="onScroll($event)"
>
  <extend-expiry-section
    *ngIf="expiries.length"
    [tribe]="tribe"
    source="chat"
    [users]="expiries"
  ></extend-expiry-section>
  <!-- <stars-feedback *ngIf = "showFeedbackForm"
                  [question] = "{ title: 'Howdy?', body: 'bitch' }" 
                  [options] = "{ speed: 1, showScrim: false }" 
                  (onClose) = "showFeedbackForm = false"
                  cssClass = "relative top-[50px]"></stars-feedback> -->

  <!-- Unread messages button -->
  <!-- <div *ngIf="isScrolledUp" (click)="scrollToBottom(1000)">
    <div class="button-arrow-container" *ngIf="newMessageCounter === 0">
      <ion-icon
        class="bottom-arrow"
        size="small"
        name="arrow-down-outline"
      ></ion-icon>
    </div>
    <div *ngIf="newMessageCounter > 0">
      <div class="new-message-btn">
        <div class="new-message-container">
          <ion-icon
            class="bottom-arrow"
            size="small"
            name="arrow-down-outline"
          ></ion-icon>
          <span class="new-message-text">
            {{ newMessageCounter }} new message{{ newMessageCounter == 1 ? '' :
            's' }}
          </span>
        </div>
      </div>
    </div>
  </div> -->

  <!-- when there is no chat -->
  <tribe-info *ngIf="tribe" [tribe]="tribe"></tribe-info>

  <!-- when there is no chat end -->
  <ul *ngIf="shownMessages.length > 0" class="messages">
    <li
      *ngFor="let message of shownMessages ; let messageIndex = index"
      class="message-container"
    >
      <!-- Direct Messages Start -->
      <div *ngIf="message.user_id == -4" class="system">
        You started a direct conversation
      </div>
      <div *ngIf="message.user_id == -3" class="system">
        {{tribe.name}} started a direct conversation
      </div>
      <div *ngIf="message.user_id == -3" class="system">
        <button class="action-btn leave" (click)="leaveTribe()">Leave</button> |
        <button class="action-btn report" (click)="reportUser( tribe )">
          Report
        </button>
      </div>

      <!--  -->
      <!-- SYSTEM MESSAGES -->
      <!-- Time Stamps -->

      <div *ngIf="message.user_id == -1" class="system">
        {{message.createdAt | date:'shortTime'}}
      </div>
      <chat-notice
        [message]="message"
        (onClick)="openMeetupPage()"
      ></chat-notice>
      <div *ngIf="message.user_id == -2" class="system">
        {{ message.createdAt | date:'shortTime' }} - {{ message.createdAt |
        date: 'shortDate' }}
      </div>
      <div
        *ngIf="message.user_id == -999 && !message?.meta_data?.notice"
        class="system"
      >
        {{ message.content }}
      </div>

      <!-- Unread messages banner -->
      <div *ngIf="message.user_id === -42" class="unread-messages-banner">
        {{ message.unreadMessageCount }} Unread Messages
      </div>

      <!--  -->

      <!-- USER MESSAGE STYLE -->
      <ion-item-sliding
        #currentUserSlidingItem
        *ngIf="message.user_id == currentUser?.id"
        class="sliding-item"
        (ionDrag)="onMessageDrag($event, message, currentUserSlidingItem)"
      >
        <ion-item lines="none" class="ion-no-padding">
          <div
            class="message user-message"
            [class.last-of-group]="message | isLastMessageOfGroup:shownMessages"
            [class.first-of-group]="message | isFirstMessageOfGroup:shownMessages"
          >
            <div class="content">
              <div class="bubble-holder">
                <div *ngIf="message.reply_to" class="reply-mention">
                  <ion-icon name="arrow-undo"></ion-icon>
                  You replied to {{ message.reply_to?.user_id |
                  getTribemateName:tribe }}
                </div>
                <div *ngIf="message.reply_to" class="reply">
                  <div
                    class="reply-bubble"
                    [class.reply-media]="message.reply_to?.content | map:replyContainsMedia"
                  >
                    <div class="reply-message">
                      <img
                        *ngIf="message.reply_to?.content | map:replyContainsMedia; else replyText"
                        [src]="message.reply_to?.content"
                        alt="Reply media"
                      />
                      <ng-template #replyText>
                        <div
                          [innerHTML]="message.status === 'deleted' ? 'Message deleted' : message.reply_to?.content"
                        ></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="message.status === 'deleted'; else userRichMessage"
                  class="user-content bubble fade-in message-{{message.id}} deleted"
                >
                  Message deleted
                </div>
                <ng-template #userRichMessage>
                  <rich-content
                    class="user-content bubble fade-in message-{{message.id}}"
                    (onDoneLoading)="onLoadedMedia()"
                    [data]="message.content"
                    (load)="scrollToBottomSmooth()"
                    (longPress)="showMessageOptions(message, message.user_id, messageIndex, $event)"
                    (mouseup)="showMessageOptionsForMedia(message, message.user_id, messageIndex, $event)"
                  >
                    {{ message.content | cleanFrom:messageCleaningRegexes }}
                  </rich-content>
                  <div *ngIf="message.reactions" class="reactions">
                    <span
                      *ngFor="let reaction of message.reactions"
                      class="reaction"
                      [class.selected]="reaction.usersIds.includes(currentUser.id)"
                      (click)="addReaction(reaction.emoji).toMessage(message.id).byUser(currentUser.id)"
                    >
                      {{ reaction.emoji }} {{ reaction.usersIds.length > 1 ?
                      reaction.usersIds.length : '' }}
                    </span>
                  </div>
                </ng-template>
              </div>
            </div>
            <div
              *ngIf="message.seen_by && message.seen_by.length > 0"
              class="read-receipt-container read-receipt-me"
            >
              <span *ngFor="let mate of message.seen_by">
                <img
                  class="read-receipt-icon"
                  src="{{ mate.id | map:getImageFor }}"
                />
              </span>
            </div>
          </div>
        </ion-item>
        <ion-item-options side="end" class="no-border">
          <ion-item-option>
            <ion-icon *ngIf="replyDragging" name="arrow-undo"></ion-icon>
          </ion-item-option>
        </ion-item-options>
        <ion-item-options side="start" class="no-border">
          <ion-item-option>
            <ion-icon *ngIf="replyDragging" name="arrow-undo"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>

      <!-- TRIBE MEMBER MESSAGE STYLE -->
      <ion-item-sliding
        #otherUserSlidingItem
        *ngIf="(message.user_id != currentUser.id && message.user_id > 0)"
        (ionDrag)="onMessageDrag($event, message, otherUserSlidingItem)"
      >
        <ion-item lines="none" class="ion-no-padding">
          <div
            class="message tribe-message"
            [class.last-of-group]="message | isLastMessageOfGroup:shownMessages"
            [class.first-of-group]="message | isFirstMessageOfGroup:shownMessages"
          >
            <div
              *ngIf="(message | isFirstMessageOfGroup:shownMessages) && !message.reply_to"
              class="display-name"
            >
              {{ message.user_id | getTribemateName:tribe }}
            </div>
            <div
              *ngIf="message | map:isTargetedSystemMessage(currentUser)"
              class="targeted-message-container"
            >
              <ion-icon class="targeted-message-icon" name="eye"></ion-icon>
              <span class="targeted-message-text">
                Only visible to you {{ tribe |
                namesOf:message.meta_data.target_user_ids }}
              </span>
            </div>

            <div class="content">
              <div class="sender">
                <div
                  *ngIf="message | isLastMessageOfGroup:shownMessages"
                  class="user-picture"
                  (click)="showProfileOptions(message, $event)"
                >
                  <img src="{{ message.user_id | imageFor: tribe  }}" />
                </div>
              </div>

              <div class="bubble-holder">
                <div *ngIf="message.reply_to" class="reply-mention">
                  <ion-icon name="arrow-undo"></ion-icon>
                  {{ message | map:formReplyTitle }}
                </div>
                <div *ngIf="message.reply_to" class="reply">
                  <div
                    class="reply-bubble"
                    [class.reply-media]="message.reply_to?.content | map:replyContainsMedia"
                  >
                    <div class="reply-message">
                      <img
                        *ngIf="message.reply_to?.content | map:replyContainsMedia; else otherUserReplyText"
                        [src]="message.reply_to?.content"
                        alt="Reply media"
                      />
                      <ng-template #otherUserReplyText>
                        <div
                          [innerHTML]="message.status === 'deleted' 
                          ? 'Message deleted' 
                          : message.reply_to?.content"
                        ></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="message | map:isTargetedSystemMessage(currentUser); else normalMessage"
                  class="bubble message-{{message.id}} bot-message-container bot-message"
                >
                  <div class="bot-message-content">{{ message.content }}</div>
                  <ng-container
                    *ngIf="message?.meta_data?.action_taken; else actionButtons"
                  >
                    <div
                      class="bot-message-button base-text label-color sub-title-font"
                    >
                      {{ message.meta_data.action_taken }}
                    </div>
                  </ng-container>
                  <ng-template #actionButtons>
                    <div
                      *ngFor="let action of message?.meta_data?.custom_actions"
                      class="bot-message-button base-text bold secondary-dark-2-color"
                      (click)="performCustomMessageAction(action, message)"
                    >
                      {{ action.text }}
                    </div>
                  </ng-template>
                </div>
                <ng-template #normalMessage>
                  <div
                    *ngIf="message.status === 'deleted'; else richMessage"
                    class="user-content bubble fade-in message-{{message.id}} deleted"
                  >
                    Message deleted
                  </div>
                  <ng-template #richMessage>
                    <rich-content
                      *ngIf="message.id"
                      class="bubble message-{{message?.id}}"
                      [class.bot-message]="message?.user_id !== this.currentUser?.id && message?.user_id == -5"
                      [message]="message"
                      [data]="message?.content"
                      (onDoneLoading)="onLoadedMedia()"
                      (click)="showMessageOptionsForMedia( message, message?.user_id, messageIndex, $event)"
                      (longPress)="showMessageOptions(message, message?.user_id, messageIndex, $event)"
                    >
                      {{ message?.content | cleanFrom:messageCleaningRegexes }}
                    </rich-content>
                    <div *ngIf="message.reactions" class="reactions">
                      <span
                        *ngFor="let reaction of message.reactions"
                        class="reaction"
                        [class.selected]="reaction.usersIds.includes(currentUser.id)"
                        (click)="addReaction(reaction.emoji).toMessage(message.id).byUser(currentUser.id)"
                      >
                        {{ reaction.emoji }} {{ reaction.usersIds.length > 1 ?
                        reaction.usersIds.length : '' }}
                      </span>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </div>

            <div
              *ngIf="message.seen_by && message.seen_by.length > 0"
              class="read-receipt-container read-receipt-member"
            >
              <span *ngFor="let mate of message.seen_by">
                <img
                  class="read-receipt-icon"
                  src="{{ mate.id | map:getImageFor }}"
                />
              </span>
            </div>
          </div>
        </ion-item>
        <ion-item-options side="end" class="no-border">
          <ion-item-option>
            <ion-icon name="arrow-undo"></ion-icon>
          </ion-item-option>
        </ion-item-options>
        <ion-item-options side="start" class="no-border">
          <ion-item-option>
            <ion-icon name="arrow-undo"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </li>
  </ul>
</ion-content>
<ion-footer
  #footer
  class="keyboard-padding iphone-x-margin"
  [style.margin-bottom.px]="isKeyboardOpen && isIos ? keyboardHeight : 0"
>
  <div class="spinner-container" [class.shown]="showConnectingSpinner">
    <ion-spinner></ion-spinner>
  </div>

  <div class="reply-container" [class.shown]="currentUserReplying">
    <div class="content-section">
      <div class="title">
        Replying to {{ currentMessage.reply_to?.user_id | getTribemateName:tribe
        }}
      </div>
      <div class="replied-message truncated">
        {{ currentMessage.reply_to?.content | replyContent }}
      </div>
    </div>
    <ion-icon (click)="cancelReply()" name="close-circle-outline"></ion-icon>
  </div>
  <div class="typing-users-section" [class.in-progress]="someoneTyping">
    <ng-lottie
      [options]="typingAnimation"
      width="50px"
      (animationCreated)="handleAnimation($event)"
    >
    </ng-lottie>
    <div class="typing-users">
      <div>
        {{ typingLabel }} {{ typingUsersArray.length > 1 ? "are" : "is" }}
        typing
      </div>
    </div>
  </div>

  <div
    #textbar
    id="textboxDiv"
    class="input-style"
    [hidden]="!textVisible"
    [style.margin-bottom.px]="keyboardHeight"
  >
    <div
      *ngIf="(tribe?.suggested_intros?.length || tribe?.suggested_questions?.length) && showStartersTip"
      class="blue-tooltip starters-tooltip"
      (click)="hideStartersTooltip()"
    >
      Don't know what to ask?
      <div class="arrow-down"></div>
    </div>
    <ion-icon
      *ngIf="(tribe?.suggested_intros?.length || tribe?.suggested_questions?.length)"
      class="starters-helper fill-tertiary stroke-tertiary"
      name="stars"
      (click)="showStarters(tribe)"
    ></ion-icon>
    <div
      #text
      contenteditable="true"
      role="textbox"
      hidefocus="true"
      class="message-input"
      rows="1"
      placeholder="Type a Message"
      (click)="textbarClick()"
      (keydown)="onKeyDown($event)"
      (onTextChange)="onTextChange($event)"
    ></div>
    <ion-icon
      [class.show]="currentMessage.content != '' && !isOffline && session.stateMachine.state === 'ready'"
      class="send-placement"
      name="send"
      (click)="sendMessage()"
    ></ion-icon>
    <ion-row class="media-bar" (click)="textbarClick()">
      <ion-icon
        [hidden]="isOffline"
        (click)="uploadPicture('camera')"
        name="camera-outline"
      ></ion-icon>
      <ion-icon
        [hidden]="isOffline"
        (click)="uploadPicture('gallery')"
        name="image-outline"
      ></ion-icon>
      <ion-icon name="happy-outline" (click)="toggleEmojis($event)"></ion-icon>
      <ion-icon name="gif" [hidden]="isOffline" (click)="openGifs()"></ion-icon>
    </ion-row>
  </div>

  <emojis-keyboard
    #emojis
    [hidden]="!emojiVisible"
    (onSelect)="inputEmoji($event)"
  ></emojis-keyboard>

  <gifs
    #gifs
    [hidden]="!gifsVisible"
    (onSelect)="inputGifs($event)"
    (onBack)="closeGifs($event)"
    [style.margin-bottom.px]="keyboardHeight"
  ></gifs>
</ion-footer>

<div
  #hint
  *ngIf="showHighlightsTip"
  (click)="toHighlights()"
  class="blue-tooltip"
>
  Here are the highlights
  <div class="arrow-up"></div>
</div>
