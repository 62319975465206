import { Component, OnInit } from '@angular/core';
import { getInfoModalsInfo } from "../../shared/helpers/info-modals-info";
import { ModalController, NavParams } from "@ionic/angular";
import {UtilsService} from "../../services/utils.service";
import {AnalyticsService} from "../../services/analytics/analytics.service";
import {camelToSnakeCase} from "../../shared/helpers/text.helpers";
import {logc} from "../../shared/helpers/log";

@Component({
  selector: 'app-info',
  template: `
      <ion-header></ion-header>
      <ion-content>
        <div class = "close-icon">
          <ion-icon name = "close" (click) = "close()"></ion-icon>
        </div>
        <div class = "info-modal">
          <div class = "title-container">
            <ion-icon *ngIf = "info?.icon" 
                      class = "{{ info.icon.cssClass }}" 
                      name = "{{ info.icon.name }}"></ion-icon>
            <img *ngIf = "info?.image" 
                 class = "title-image"
                 [src] = "info?.image" 
                 alt = "Modal image">
            <div class = "info-modal-title title-color bold" [innerHTML] = "info.title" ></div>
          </div>
          <div class = "info-modal-description title-color" [innerHTML] = "info.description"></div>
          <div class = "info-modal-subtitle title-color" [innerHTML] = "info?.subTitle"></div>
          <div *ngFor = "let item of info.items" class = "item">
            <div *ngIf = "item?.divider; else regularItem">
              <div class = "divider-line"></div>
              <div class = "divider-container">
                <ion-icon [name] = "item?.icon"></ion-icon>
                <span class = "title-color underlined base-text" [innerHTML] = "item?.text"></span>
              </div>
            </div>
            <ng-template #regularItem>
              <img *ngIf = "item?.image" class = "info-svg item-image" [src] = "item.image" alt = "Image Alt">
              <ion-icon *ngIf = "item?.icon" 
                        [name] = "item?.icon?.name" 
                        class = "item-icon {{ item?.icon?.cssClass }}"></ion-icon>
              <div class = "item-title bold title-color" [innerHTML] = "item.title"></div>
              <div class = "item-description description-color" [innerHTML] = "item.description"></div>
            </ng-template>
          </div>
          <div class = "info-modal-notice-container body-color">
            <ion-icon [name] = "info?.notice?.icon"></ion-icon>
            <div class = "info-modal-notice" [innerHTML] = "info?.notice?.text"></div>
          </div>
        </div>
      </ion-content>
  `,
  styleUrls: ['./info.page.scss'],
})
export class InfoPage {

  public info = {
    title: '',
    subTitle: '',
    image: '',
    icon: {
      name: '',
      cssClass: ''
    },
    description: '',
    items: [],
    notice: {
      icon: '',
      text: ''
    }
  };

  private type: string = '';

  constructor(private navParams: NavParams,
              private utils: UtilsService,
              private analyticsService: AnalyticsService,
              private modalCtrl: ModalController) {
  }

  ionViewWillEnter() {
    this.type = this.navParams.get('type');
    this.info = getInfoModalsInfo({ type: this.type, oem: this.utils.getDeviceVendor() });
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
