import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BillingPlan } from "../upselling.page";

@Component({
  selector: "billing-plan",
  template: `
    <div class = "flex items-center gap-[20px] border-t-[1px] border-t-line py-[10px]"
         (click) = "clicked()"
         [ngClass] = "{ 'border-b-[1px] border-b-line': last }">
      <div class = "box-border shrink-0 rounded-full w-[18px] h-[18px] border-label border-[1px]"
           [ngClass] = "{ 'border-success border-[4px]': plan.selected }"></div>
      <div class = "w-full">
        <div class = "flex gap-[10px] items-center">
          <div class = "text-base text-title font-semibold">{{ plan.name }}</div>
          <div *ngIf = "plan?.discount && plan?.selected" 
               class = "font-heavy text-tiny text-success">
            {{ plan.discount }}
          </div>
          <div class = "text-label text-small ml-auto">{{ plan.priceDetails }}</div>
        </div>
        <div class = "text-small text-body py-[5px]">{{ plan.description }}</div>
      </div>
    </div>
  `
})
export class BillingPlanComponent {
  @Input() plan: BillingPlan;
  @Input() last: boolean = false;
  @Output() planSelected: EventEmitter<BillingPlan> = new EventEmitter();

  clicked(): void {
    this.planSelected.emit(this.plan);
  }
}