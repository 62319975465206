import {Platform, NavController, PopoverController, ModalController} from '@ionic/angular';
import { Component } from '@angular/core';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import {SessionService} from "../../services/data/session.service";
import {DispatcherService} from "../../services/dispatcher.service";

@Component({
  selector: 'under-maintenance-page',
  styleUrls: ['under_maintenance.page.scss'],
  templateUrl: 'under_maintenance.page.html',
  host: {'class': 'transparent-header tertiary-page'},
})
export class UnderMaintenancePage {
  constructor(private platform: Platform,
              private navCtrl: NavController,
              private dispatcherService: DispatcherService,
              private modalCtrl: ModalController,
              private analyticsService: AnalyticsService,
              ) {
  }

  flappy() {
    this.modalCtrl.dismiss();
    this.analyticsService.trackEvent({ key: 'started_flappy', value: 1 });
    this.navCtrl.navigateRoot('/flappy');
  }

  async retry() {
    await this.modalCtrl.dismiss();
    this.navCtrl.navigateRoot('/').then( _ => {
      this.dispatcherService.sessionResumeSource.next(true);
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
