import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {NavController} from '@ionic/angular';
import {takeUntil} from "rxjs/operators";
import {DispatcherService} from "../../services/dispatcher.service";
import {Subject} from "rxjs";

@Component({
  selector: 'status',
  template: `
    <div class="status-container status-holder status-{{page}}" *ngIf = "!isEmpty()">
      <div class = "flex items-center p-[10px] gap-[15px] {{ status?.cssClass }}" (click) = "status?.action()">
        <ion-icon *ngIf = "status?.icon" class = "text-xl" name="{{status?.icon}}"></ion-icon>
        <span class = "text-small font-subtitle italic"
              [class.truncate] = "status.expires_at != null">
              {{ status?.text }}
        </span>
        <span *ngIf = "status?.timeLeft" 
              class = "text-small w-max whitespace-nowrap font-semibold ml-auto">{{ status?.timeLeft }}</span>
        <ion-icon *ngIf = "status?.iconRight?.name" 
                  class = "text-large ml-auto" 
                  [name] = "status?.iconRight?.name"></ion-icon>
      </div>
    </div>
  `,
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  @Input('status') status: any;
  @Input('page') page;

  private unsubscribe = new Subject();

  constructor(private navCtrl: NavController,
              private renderer: Renderer2,
              private dispatcherService: DispatcherService) {

  }

  ngOnInit() {
    this.dispatcherService
      .onStatusBarChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(action => (
        {
          show: () => this.showStatus(),
          hide: () => this.hideStatus()
        }[action]())
      );

    if(this.status && !this.status.action) {
      this.status.action = () => {};
    }
    setTimeout(() => {
      this.showStatus();
    }, 750)
  }

  showStatus() {
    let status = document.getElementsByClassName(`status-${this.page}`)[0];
    if(status) {
      setTimeout(() => this.renderer.addClass(status, 'show-status'), 2000);
    }
  }

  hideStatus(): Promise<void> {
    return new Promise((resolve, reject) => {
      let status = document.getElementsByClassName(`status-${this.page}`)[0];
      if(status) {
        this.renderer.removeClass(status, 'show-status');
      }
      resolve();
    });
  }

  isEmpty() {
    return this.status ? Object.entries(this.status).length === 0 : true;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
