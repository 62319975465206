import { Pipe, PipeTransform } from '@angular/core';
import {fromNullable} from "../shared/helpers/either-monad";
@Pipe({
  name: 'firstname',
})
export class FirstnamePipe implements PipeTransform {
  transform(name: string): string {
    const prefixes = ['Mr.', 'Mrs.'];
    return fromNullable(name)
      .map(name => name.trim())
      .map(name => name.split(' '))
      .map(nameParts =>
        prefixes.includes(nameParts[0])
          ? nameParts.slice(1)
          : nameParts
      )
      .map(nameParts => nameParts[0])
      .fold(
        () => "Name",
        name => name
      )
  }
}
