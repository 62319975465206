import { Component,  Input } from '@angular/core';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'custom-action-sheet',
  template: `
    <div class = "send-options-action-sheet">
      <div *ngFor = "let option of options" class = "option" (click) = "emit([ option ])">
        <ion-icon *ngIf = "option.icon" class = "main-icon" [name] = "option.icon"></ion-icon>
        <div *ngIf = "option.picture" class = "user-photo">
          <img [src] = "option.picture" alt = "Image">
        </div>
        <div class = "text-container">
          <div class = "option-title">{{option.message}}</div>
          <div *ngIf = "option.subTitle" class = "option-subtitle" [innerHTML] = "option.subTitle"></div>
        </div>
      </div>
      <div *ngIf = "state == 'dontFeel'" class = "option skip-option" (click) = "emit(options)">
        <div class = "both-users-container">
          <div *ngIf = "options[0]?.picture" class = "user-photo">
            <img [src] = "options[0].picture" alt="">
          </div>
          <div *ngIf = "options[1]?.picture" class = "user-photo">
            <img [src] = "options[1].picture" alt="">
          </div>
        </div>
        <div class = "text-container">
          <div class = "option-title" [innerHTML] = "extraOption?.text"></div>
          <div class = "option-subtitle" [innerHTML] = "extraOption?.subTitle"></div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./custom-action-sheet.component.scss']
})
export class CustomActionSheetComponent {
  @Input('options') options: any;
  @Input("state") state;

  public extraOption: any = {
    text: "Not feeling either of them",
    subTitle: "Don't worry, this is anonymous."
  }

  constructor(private modalCtrl: ModalController) {}

  emit(data) {
    return this.modalCtrl.dismiss(data);
  }
}
