import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FEEDBACK_STATES} from "./states";

@Component({
  selector: 'feedback-modal',
  template: `    
    <div class = "scrim" (click) = "close()" [class.shown] = "modalShown"></div>
    <div class = "feedback-modal" [class.shown] = "modalShown">
      <div class = "title">{{state?.title}}</div>
      <div class = "description">{{state?.description}}</div>
      <div class = "options-section">
        <div *ngFor = "let option of state.options" class = "option" (click) = "sendReason(option.text)">
          <ion-icon class = "icon" name = "{{option.icon}}"></ion-icon>
          <div class = "option-text">{{option.text}}</div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent implements OnInit {

  @Input('stateName') stateName;
  @Output('onClose') onClose = new EventEmitter();
  @Output('onClick') onClick = new EventEmitter();

  public state: any = {};

  private states = FEEDBACK_STATES;

  public modalShown: boolean = false;

  constructor() { }

  ngOnInit() {
    this.setState();
    setTimeout(() => this.showModal(), 300);
  }

  sendReason(reason: string) {
    this.onClick.emit(reason);
  }

  setState() {
    this.state = this.states[this.stateName];
  }

  showModal() {
    this.modalShown = true;
  }

  hideModal() {
    this.modalShown = false;
  }

  close(reason?) {
    this.hideModal();
    setTimeout(() => this.onClose.emit(reason || null), 300);
  }

}
