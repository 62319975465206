import { Injectable } from "@angular/core";
import { MatchPreferencesLocation } from "../shared/enums/match-preferences.enum";
import { TribeStatus } from "../shared/enums/tribe-status.enum";
import { UserTribeStatus } from "../shared/enums/user-tribe-status.enum";
import { getExpiryIconColor } from "../shared/helpers/helpers";
import { logc } from "../shared/helpers/log";

export enum MainColor {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Success = "success",
  Error = "error",
}
export interface IconStatus {
  name: string;
  color?: MainColor;
  cssClass?: string;
}
@Injectable({
  providedIn: "root",
})
export class IconStatusService {
  public currentUserStatus: string;
  public searchLocation: string;
  public tribeStatus: string;

  public radius;

  constructor() {}

  getIconInfo(user: any, tribe: any, options: any): IconStatus {
    switch (true) {
      case this.isOtherUserArrivingSoon(user, tribe):
        return {
          name: "airplane-outline",
          cssClass: "text-secondary-dark-3 bg-secondary-light-1",
        };
      case this.isOtherUserAccepted(user, tribe):
        return {
          name: "checkmark-outline",
          cssClass: "text-success-dark-3 bg-success-light-1",
        };
      case this.isOtherUserJoining(user):
        return { name: "timer-outline", cssClass: getExpiryIconColor(user) };
      case this.isCurrentUserApproving(user, tribe):
        return { name: "timer-outline", cssClass: getExpiryIconColor(user) };

      case this.isOtherUserOutsideRadius(user, tribe, options):
        return {
          name: "no-location",
          cssClass: "text-secondary-dark-3 bg-secondary-light-1",
        };
      default:
        return null;
    }
  }

  isOtherUserAccepted(user, tribe): boolean {
    return (
      user?.status == UserTribeStatus.Accepted &&
      tribe?.status != TribeStatus.Formed
    );
  }

  isOtherUserJoining(user): boolean {
    return user?.status == UserTribeStatus.Invited;
  }

  isCurrentUserApproving(user, { tribe_user }): boolean {
    return (
      tribe_user?.status === UserTribeStatus.Accepted &&
      user?.status === UserTribeStatus.NeedsReview
    );
  }

  isOtherUserArrivingSoon(user, { tribe_user }): boolean {
    logc.info("isOtherUserArrivingSoon user: ", user);
    logc.info("isOtherUserArrivingSoon tribe_user: ", tribe_user);
    // return (
    //   [UserTribeStatus.Invited, UserTribeStatus.NeedsReview].includes(
    //     tribe_user?.status
    //   ) && user?.remote_city_name
    // );
    return user?.remote_city_name;
  }

  isOtherUserOutsideRadius(user, { tribe_user }, options): boolean {
    return (
      [UserTribeStatus.Invited, UserTribeStatus.NeedsReview].includes(
        tribe_user?.status
      ) && this.isOutsideMaxDistance(user, options?.location)
    );
  }

  setData({ userStatus, searchLocation, tribeStatus }) {
    this.currentUserStatus = userStatus;
    this.searchLocation = searchLocation;
    this.tribeStatus = tribeStatus;
  }

  getIconStatusName(tribeMate): any {
    // console.log(tribeMate);
    if (!tribeMate) return;
    if (this.userAccepted(tribeMate)) {
      return "accepted";
    }
    if (this.userJoining(tribeMate)) {
      return "";
    }
    if (this.userApproving(tribeMate)) {
      return "approving";
    }
    if (this.userArrivingSoon(tribeMate)) {
      return "arriving";
    }
    if (this.userOutsideRadius(tribeMate)) {
      return "outsideRadius";
    }
  }

  userAccepted(tribeMate) {
    return (
      this.currentUserStatus === "accepted" && tribeMate.status == "accepted"
    );
  }

  userJoining(tribeMate) {
    return (
      this.currentUserStatus === "accepted" && tribeMate.status === "invited"
    );
  }

  userApproving(tribeMate) {
    return (
      this.currentUserStatus === "accepted" &&
      tribeMate.status === "needs_review"
    );
  }

  userArrivingSoon(tribeMate) {
    return (
      ["invited", "needs_review"].includes(this.currentUserStatus) &&
      tribeMate.remote_city_name
    );
  }

  userOutsideRadius(tribeMate) {
    return (
      ["invited", "needs_review"].includes(this.currentUserStatus) &&
      this.isOutsideMaxDistance(tribeMate, this.searchLocation)
    );
  }

  isOutsideMaxDistance(u, searchLocation) {
    if (u.remote_city_name && u.remote_city_name.length > 0) return false;

    if (searchLocation === MatchPreferencesLocation.NearMe) return false;

    if (searchLocation === MatchPreferencesLocation.NearMe)
      return (u && u.proximity) > this.radius;
  }
}
