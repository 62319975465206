import {Component, HostListener, ViewChild} from '@angular/core';
import {NavParams, ModalController, Platform, PopoverController} from '@ionic/angular';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { SessionService } from '../../../services/data/session.service';
import { UserService } from '../../../services/data/user.service';
import {AnalyticsService} from '../../../services/analytics/analytics.service';

@Component({
  selector: 'profile-work-popup',
  templateUrl: 'profile-work-popup.html',
  styleUrls: ['profile-work-popup.scss']
})
export class ProfileWorkPopupComponent {

  @ViewChild('workTitleInput') workTitleInput;
  @ViewChild('workOrganizationInput') workOrganizationInput;

  workForm: FormGroup;
  title: FormControl;
  organization: FormControl;

  constructor(public modalCtrl: ModalController,
              public formBuilder: FormBuilder,
              public platform: Platform,
              public usersService: UserService,
              public navParams: NavParams,
              public session: SessionService,
              public popoverCtrl: PopoverController,
              public analyticsService: AnalyticsService) {

    this.workForm = this.formBuilder.group({
        title: [this.navParams.get('work').title,],
        organization: [this.navParams.get('work').organization,]
    });
    this.title = <FormControl>this.workForm.get('title');
    this.organization = <FormControl>this.workForm.get('organization');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(!this.title.value) {
        this.workTitleInput.setFocus();
      } else if(!this.organization.value) {
        this.workOrganizationInput.setFocus();
      }

      if(!!this.title.value && !!this.organization.value) {
        this.workTitleInput.setFocus();
      }
    }, 0);
  }

  submit(){
    let workTitle = this.workForm.get('title').value;
    let organization = this.workForm.get('organization').value;
    this.usersService.details.work_title = workTitle;
    this.usersService.details.work_organization = organization;
    this.usersService.updateProfile({
      work_title: workTitle,
      work_organization: organization
    }).then( _ => {
      this.analyticsService.trackEvent({key: "work_set", value: 1 });
      this.popoverCtrl.dismiss();
    });
  }

  Cancel(){
    this.popoverCtrl.dismiss();
  }
}
