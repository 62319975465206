<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon class = "modal-close-button" name = "close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="criteria-holder">
    <ion-item class = "tribe" lines = "none">
      <tribe-avatar [tribe] = "{ id: tribe.id, users: users }"></tribe-avatar>
      <div class = "status-text-container">
        <div class = "tribe-name">
          {{ tribe.name }}
        </div>
        <div class = "tribe-status capitalize">{{ isDirectMessage ? 'Direct Chat' : tribe.status}}</div>
      </div>
    </ion-item>
  </div>

  <div class = "criteria-holder tribe-members">
    <div class = "heading-label heading uppercase">Members</div>
    <ion-item *ngFor="let mate of users" lines="none">
      <!--<div id="progress-meter-{{mate.status}}"></div>-->
      <div *ngIf = "mate.status == 'standby'" class="pulse-box">
        <div class="pulse-border-box"></div>
        <div class="pulse-css"></div>
      </div>
      <div class="member-avatar" item-left>
        <div *ngIf = "mate.status == 'invited'" id="progress-meter-{{mate.id}}"></div>
        <img src="{{mate.picture}}" class="member">
      </div>
      <div class = "status-text-container">
        <div class = "tribe-name">{{mate.first_name}}</div>
        <div class = "tribe-status capitalize" [innerHTML]="setUserStatus(mate)"></div>
      </div>
      <div *ngIf = "currentUser.id != mate.id" 
           class = "menu-container relative mr-[5px]"
           (click) = "showProfileOptions(mate, $event)">
        <div *ngIf = "mate?.replaceable"
             class = "absolute w-[8px] h-[8px] rounded-full bg-error -top-[5px] -right-[5px]"></div>
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </div>
    </ion-item>
  </div>

  <div class="criteria-holder negative-actions">
    <div class = "criteria-item single-item semibold" (click) = "startLeavingFlow()">
      Leave {{ this.isDirectMessage ? 'chat': 'group' }}
    </div>
    <div *ngIf = "tribeMates.length > 1"
         class = "criteria-item single-item semibold"
         (click) = "startReportingFlow()">
      Report user
    </div>
  </div>

</ion-content>
