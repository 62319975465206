import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'bullet-list',
  template: `
    <div class = "steps">
      <div *ngFor = "let text of items; index as index; last as last" class="step">
        <div class = "milestone-mark active">{{ index + 1 }}</div>
        <div class = "step-description" [innerHTML] = "text"></div>
        <div *ngIf = "!last" class = "path-line"></div> 
      </div>
    </div>
  `,
  styleUrls: ['./bullet-list.component.scss'],
})
export class BulletListComponent implements OnInit {
  @Input("items") items = [];

  constructor() { }

  ngOnInit() {}

}
