import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'separator-line',
  template: `
    <div class = "flex justify-center">
      <div class = "w-[50%] h-50 border-t-[1px] border-line"></div>
    </div>
  `,
  styleUrls: ['./separator-line.component.scss'],
})
export class SeparatorLineComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
