import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ProfilePopupComponent } from './profile-popup';

import { OptionsPopupComponentModule } from '../options-popup/options-popup.module'
import { OptionsPopupComponent } from '../options-popup/options-popup'

import { ProfileInfoPopupComponentModule } from '../profile-info-popup/profile-info-popup.module'
import { ProfileInfoPopupComponent } from '../profile-info-popup/profile-info-popup'

import { ProfileWorkPopupComponentModule } from './profile-work-popup/profile-work-popup.module'
import { ProfileWorkPopupComponent } from './profile-work-popup/profile-work-popup'

import { ProfileSchoolPopupComponentModule } from './profile-school-popup/profile-school-popup.module'
import { ProfileSchoolPopupComponent } from './profile-school-popup/profile-school-popup'

import { ProfileAboutMePopupComponentModule } from './profile-about-me-popup/profile-about-me-popup.module'
import { ProfileAboutMePopupComponent } from './profile-about-me-popup/profile-about-me-popup'

import { SettingsPopoverComponentModule } from './settings-popover/settings-popover.module';

import { LocationSectionComponentModule } from '../location-section/location-section.module';
import { ModalImageComponentModule } from '../modal-image/modal-image.module';

import { FormsModule } from '@angular/forms';

import { RadarChartModule } from '../../components/radar-chart/radar-chart.module'
import { InstagramModule } from '../../components/instagram/instagram.module'
import { HighlightsModule } from '../../components/highlights/highlights.module';

import { LoadingModule } from 'src/app/components/loading/loading.module';
import { Loading } from 'src/app/components/loading/loading';
import { DeclineMenuComponent } from '../decline-menu/decline-menu.component';
import { DeclineMenuModule } from '../decline-menu/decline-menu.module';
import { LightButtonModule } from '../light-button/light-button.module';
import { NotificationBadgeComponent } from '../notification-badge/notification-badge.component';
import { NotificationBadgeModule } from '../notification-badge/notification-badge.module';
import { LottieModule } from "ngx-lottie";
import {PipesModule} from "../../pipes/pipes.module";
import {StatusComponentModule} from "../status/status.module";
import {StatusComponent} from "../status/status.component";
import {DirectivesModule} from "../../directives/directives.module";
import { HighlightsListComponent } from '../highlights-list/highlights-list.component';
import { HighlightsListModule } from '../highlights-list/highlights-list.module';
import { ExtendExpirySectionComponentModule } from '../extend-expiry-section/extend-expiry-section.module';

@NgModule({
  declarations: [
    ProfilePopupComponent,
  ],
  entryComponents: [
    OptionsPopupComponent,
    ProfileWorkPopupComponent,
    ProfileInfoPopupComponent,
    ProfileSchoolPopupComponent,
    ProfileAboutMePopupComponent,
    StatusComponent,
    Loading,
    DeclineMenuComponent,
    NotificationBadgeComponent,
    HighlightsListComponent
  ],
  imports: [
    ExtendExpirySectionComponentModule,
    HighlightsListModule,
    DirectivesModule,
    PipesModule,
    NotificationBadgeModule,
    StatusComponentModule,
    LightButtonModule,
    CommonModule,
    IonicModule,
    OptionsPopupComponentModule,
    DeclineMenuModule,
    ProfileWorkPopupComponentModule,
    ProfileInfoPopupComponentModule,
    ProfileSchoolPopupComponentModule,
    ProfileAboutMePopupComponentModule,
    RadarChartModule,
    InstagramModule,
    HighlightsModule,
    FormsModule,
    LoadingModule,
    LottieModule,
    SettingsPopoverComponentModule,
    LocationSectionComponentModule,
    ModalImageComponentModule,
    StatusComponentModule
  ],
  exports: [
    ProfilePopupComponent
  ]
})
export class ProfilePopupComponentModule {}
