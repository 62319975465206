import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { LightButtonComponent } from "../light-button/light-button.component";
import { LightButtonModule } from "../light-button/light-button.module";
import { HighlightsListComponent } from "./highlights-list.component";

@NgModule({
  entryComponents: [LightButtonComponent],
  declarations: [HighlightsListComponent],
  imports: [
    CommonModule,
    LightButtonModule
  ],
  exports: [HighlightsListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HighlightsListModule {}