import { Component, Injectable } from '@angular/core';

@Component({
  selector: 'loading',
  template: `<ion-spinner></ion-spinner>`
})
@Injectable()
export class Loading {
  constructor(){ }
}

