import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { PipesModule } from "src/app/pipes/pipes.module";
import { CloseButtonModule } from "../close-button/close-button.module";
import { ExtendExpirySectionComponent } from "./extend-expiry-section.component";

@NgModule({
  declarations: [ExtendExpirySectionComponent],
  imports: [
    IonicModule,
    CommonModule,
    CloseButtonModule,
    PipesModule
  ],
  exports: [ExtendExpirySectionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ExtendExpirySectionComponentModule {}