import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IconStatus, MainColor } from "src/app/services/icon-status.service";

@Component({
  selector: "chat-avatar",
  template: `
    <div class = "relative">
      <div (click) = "onImageClick()" 
           class = "w-[120px] h-[120px] rounded-full border-[4px] border-surface overflow-hidden">
        <img [src] = "user?.picture" [alt] = "user?.picture">
      </div>
      <ng-content></ng-content>
    </div>
  `
})
export class ChatAvatarComponent {
  @Input() tribe: any = {};
  @Input() user: any = {};
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  public icon: IconStatus = {
    name: "",
    color: MainColor.Secondary
  }

  onImageClick(): void {
    this.onClick.emit(this.user);
  }
}