<ion-header>
  <div *ngIf="isIos && !isEditable" class="pull-bar"></div>
</ion-header>

<status
  *ngIf="profileIsIncomplete() && isEditable"
  [page]="'profile-popup'"
  [status]="profileStatus"
></status>

<ion-content>
  <div *ngIf="!isEditable" class="icon-container icon-bg close-btn-pos">
    <ion-icon
      *ngIf="isIos; else androidBackButton"
      (click)="close()"
      class="close-button"
      name="close"
    ></ion-icon>
    <ng-template #androidBackButton>
      <ion-icon
        (click)="close()"
        class="close-button"
        name="arrow-back"
      ></ion-icon>
    </ng-template>
  </div>
  <div
    *ngIf="(!isEditable && !fromMatches || fromMatches && isPlus) && !fromFailedSearch && tribe?.tribe_user?.status != 'invited'"
    class="icon-container icon-bg ellipsis-pos"
  >
    <div
      *ngIf="user?.replaceable"
      class="absolute w-[10px] h-[10px] rounded-full bg-error top-[5px] right-[5px]"
    ></div>
    <ion-icon
      (click)="showProfileOptions($event)"
      class="ellipsis"
      name="ellipsis-vertical"
    ></ion-icon>
  </div>
  <div class="profile-content" (touchend)="hideRadarHover()">
    <div class="background-container">
      <div
        *ngIf="isEditable"
        id="bg-img-editable"
        class="background-img"
        [style.background-image]="'url(' + displayProfile.picture + ')'"
      ></div>
      <div
        *ngIf="!isEditable"
        id="bg-img"
        class="background-img popup-height"
        [style.background-image]="'url(' + displayProfile.picture + ')'"
      ></div>
      <div class="profile-triangle"></div>
    </div>

    <div class="img-holder profile" [class.loading-data]="!displayProfile.id">
      <div *ngIf="isEditable && displayProfile.id">
        <div
          *ngIf="pictureVerified && !pictureRejected; else profilePictureWarning"
        >
          <ion-icon
            class="edit-position"
            name="camera"
            (click)="changeProfilePic()"
          ></ion-icon>
        </div>
        <ng-template #profilePictureWarning>
          <ion-icon
            *ngIf="!profile.picture"
            class="edit-position anonymous"
            name="add"
            (click)="showPictureIssueAlert()"
          ></ion-icon>
          <ion-icon
            *ngIf="pictureRejected || !pictureVerified"
            class="edit-position warning"
            name="warning"
            (click)="showPictureIssueAlert()"
          ></ion-icon>
        </ng-template>
      </div>
      <div
        *ngIf="profile.picture && (pictureRejected || !pictureVerified) && isEditable && displayProfile.id"
        class="guide-popup-profile secondary-gradient fade-in-animation delay-1sec"
      >
        <div class="top-arrow center"></div>
        <div class="content-section base-text">
          <div class="guide-text">There is a problem with your picture</div>
        </div>
      </div>

      <div class="image-container">
        <img
          [class.heavy-blur]="fromMatches && !isPlus || fromFailedSearch"
          [src]="displayProfile.picture"
          class="default-image"
          (click)="showModalImage()"
          (error)="onImageError($event)"
        />
      </div>
    </div>
    <div
      class="profile-infos"
      [class.skeleton-text]="!isPlus && fromMatches || fromFailedSearch"
    >
      <div
        class="name-title"
        *ngIf="!displayProfile.isAnonymous && displayProfile.id"
      >
        <div class="gender-container">
          <div *ngIf="showGenderTip" class="blue-tooltip">
            {{displayProfile.firstName}} is {{(displayProfile.gender == 'male')
            ? 'a man' : (displayProfile.gender == 'female') ? 'a woman' :
            'non-binary' }}
            <div class="arrow-down"></div>
          </div>
          <ion-icon
            class="custom-icon-profile"
            *ngIf='displayProfile.gender != "non-binary"'
            (click)="showInfoPopup('gender', $event)"
            name="{{displayProfile.gender}}-outline"
          ></ion-icon>
          <ion-icon
            class="custom-icon-profile"
            *ngIf='displayProfile.gender == "non-binary"'
            (click)="showInfoPopup('gender', $event)"
            name="male-female-outline"
          ></ion-icon>
        </div>
        <span>
          <span
            *ngIf="!displayProfile.firstName; else name"
            (click)="addName()"
            class="add-name underlined title-font-heavy placeholder-color"
          >
            Your name
          </span>
          {{ displayProfile.birthday && !displayProfile.firstName ? ', ' : '' }}
          <ng-template #name>
            <span
              class="title-font-heavy title-color xl-text"
              (click)="showInfoPopup('name', $event)"
            >
              {{ displayProfile.firstName }}{{ displayProfile.birthday ? ', ' :
              '' }}
            </span>
          </ng-template>
        </span>
        <span
          class="title-font-heavy title-color xl-text-text"
          (click)="showInfoPopup('age', $event)"
        >
          {{ displayProfile.birthday | map: getAge }}
        </span>
      </div>
      <div class="name-title" *ngIf="displayProfile.isAnonymous">
        Build My Profile
      </div>

      <div
        *ngIf="!!displayProfile.work_title || isEditable"
        class="sub-titles"
        [class.info-placeholder]="!displayProfile.work_title && !displayProfile.work_organization"
      >
        <div class="profile-sub-info-container" (click)="addWork()">
          <span
            *ngIf="!displayProfile.work_title && !displayProfile.work_organization; else workInfo"
          >
            <light-button [buttonBody]="workButtonBody"></light-button>
          </span>
          <ng-template #workInfo>
            <span class="work-info">
              <ion-icon class="work-icon" name="briefcase"></ion-icon>
              {{displayProfile.work_title}}{{displayProfile.work_organization &&
              displayProfile.work_title ? ', ' : ''}}
              {{displayProfile.work_organization}}
            </span>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="!!displayProfile.school || isEditable"
        class="sub-titles"
        [class.info-placeholder]="!displayProfile.school || displayProfile.school==''"
      >
        <div class="profile-sub-info-container" (click)="addSchool()">
          <span
            *ngIf="displayProfile.school == null || displayProfile.school==''"
          >
            <light-button [buttonBody]="schoolButtonBody"></light-button>
          </span>
          <span *ngIf="displayProfile.school">
            <ion-icon class="school-icon" name="school"></ion-icon>
            {{displayProfile.school}}
          </span>
        </div>
      </div>
    </div>

    <extend-expiry-section
      *ngIf="user?.status == 'invited'"
      [users]="[user]"
      source="profilePopup"
      [showCloseButton]="false"
      [tribe]="tribe"
    ></extend-expiry-section>

    <notification-badge
      *ngIf="!isEditable && profileNotification && user?.status != 'invited'"
      [cssClass]="'shadow-md m-[10px]'"
      [content]="profileNotification"
    ></notification-badge>

    <notification-badge
      *ngIf="failedStatus && user?.status != 'invited'"
      [cssClass]="'shadow-md m-[10px]'"
      [content]="failedStatusNotification"
    ></notification-badge>

    <!-- Progress Bar -->
    <div
      *ngIf="isEditable"
      class="profile-progress {{progressMenuState}}"
      [class.hidden]="profileFilled"
    >
      <div class="progress-labels" (click)="activateProgressMenu($event)">
        <div class="progress-left-section">
          <span class="profile-strength">Profile Strength</span>
          <span
            *ngIf="!profileFilled"
            [tooltip]="'Get it to 100%'"
            [tooltipOptions]="profileStrengthTooltipOptions"
            class="progress-tooltip progress-percents {{ progress.color }}"
          >
            {{progress.value}}%
          </span>
        </div>
        <div class="progress-right-section">
          <div class="progress-arrow {{progressMenuState}}"></div>
        </div>
      </div>
      <div class="progress-container">
        <div
          class="progress {{progress.color}}"
          [style.width.%]="progress.value"
        ></div>
      </div>
      <div class="steps-list">
        <div
          *ngFor="let step of stepsList"
          class="step"
          [class.done]="step.done"
          (click)="!step.done ? step.action() : undefined"
        >
          <div class="step-left">
            <ion-icon
              *ngIf="step.done; else outlined"
              name="checkmark-circle"
            ></ion-icon>
            <ng-template #outlined>
              <ion-icon
                name="checkmark-circle-outline"
                class="step-checkmark-outlined"
              ></ion-icon>
            </ng-template>
            <span class="step-text">{{step.text}}</span>
          </div>
          <div class="step-right">{{step.icon}}</div>
        </div>
      </div>
    </div>
    <!--  -->

    <div
      *ngIf="displayProfile.about || isEditable"
      class="profile-section blue-section"
      [class.unrool-top]="!isEditable && displayProfile.id"
      id="about-section"
    >
      <div *ngIf="isEditable && !displayProfile.id" class="loading-container">
        <loading></loading>
      </div>
      <div
        *ngIf="!isEditable || displayProfile.id"
        class="profile-section-content"
      >
        <ion-textarea
          *ngIf="displayProfile.about && !isEditable"
          [class.blur]="fromMatches && !isPlus || fromFailedSearch"
          readonly
          class="about-me-textarea"
          type="text"
          #about
          [(ngModel)]="aboutMe"
          [autoGrow]="true"
          ><div class="no-select-area"></div
        ></ion-textarea>
        <ion-textarea
          *ngIf="isEditable"
          class="about-me-textarea"
          type="text"
          #aboutMeForm
          maxlength="512"
          placeholder="Write something about yourself.🤔✍️⚽"
          [(ngModel)]="aboutMe"
          (ionFocus)="setPreviousAboutMe()"
          (ionBlur)="addAboutMe()"
          [autoGrow]="true"
        ></ion-textarea>
      </div>
    </div>
    <!--personality section-->
    <div
      *ngIf="isEditable || personalityType.hasData"
      class="profile-section white-section"
      [class.unrool-top]="!isEditable && displayProfile.id"
      id="personality-section"
      (click)="openPersonalityTypeModal()"
    >
      <div *ngIf="isEditable && !displayProfile.id" class="loading-container">
        <loading></loading>
      </div>
      <div
        class="profile-section-content"
        *ngIf="!isEditable || displayProfile.id"
      >
        <div class="personality-text">
          <div class="profile-section-header heading">
            <ion-icon name="paw"></ion-icon>
            Personality Type
          </div>
          <div class="personality-text-content">
            <div *ngIf="unlockPersonality && personalityType.hasData">
              <div
                class="personality-type-title title-font title-color large-text"
              >
                {{personalityType.name}}
              </div>
              <div class="small-description">
                {{personalityType.summary.slice(0,50)}}...
                <span class="read-more">Read More</span>
              </div>
            </div>
            <div
              *ngIf="!(unlockPersonality && personalityType.hasData) && isEditable"
            >
              <div class="small-description complete-levels-message">
                Complete level 2 to get your personality type.
              </div>
            </div>
          </div>
        </div>
        <div class="personality-img-container">
          <img
            *ngIf="(unlockPersonality && personalityType.hasData)"
            class="personality-img"
            src="{{personalityType.topThreeFactors[0].image}}"
          />
          <img
            *ngIf="!(unlockPersonality && personalityType.hasData) && isEditable"
            class="personality-img"
            src="./assets/img/animals/personalities.png"
          />
        </div>
      </div>
    </div>
    <!--radar-chart section-->
    <div
      class="profile-section blue-section"
      id="radar-chart-section"
      [class.loading]="!displayProfile.id"
    >
      <div *ngIf="displayProfile.id" class="profile-section-header heading">
        <!--        <img class = "profile-section-image" src = "./assets/img/radar.svg" alt = "Radar">-->
        <ion-icon name="radar" class="bigger-icon"></ion-icon>
        Radar Chart

        <div
          class="whats-next primary-color underlined bold"
          (click)="openRadarPopup()"
        >
          What's this?
        </div>
      </div>
      <div *ngIf="!displayProfile.id" class="loading-container">
        <loading></loading>
      </div>
      <radar-chart
        *ngIf="displayProfile.id"
        [isCurrentUserProfile]="isEditable"
        [displayProfile]="displayProfile"
        [path]="'/tabs/profile'"
        [onTouchend]="onTouchend"
      ></radar-chart>
    </div>

    <!--Individual compatibility-->
    <!-- <div *ngIf="!isEditable" class="profile-section blue-section">
      <highlights [data]='compatibility'
                  [hasInstagram] = "displayProfile.instagramToken != 'none'"
                  [completeMoreLevels]='completeMoreLevels'
                  [showCompleteMoreLevels]='showCompleteMoreLevels' ></highlights>
    </div> -->

    <highlights-list
      *ngIf="!isEditable"
      [data]="compatibility"
      [format]="'list'"
      [type]="'traits'"
    >
    </highlights-list>

    <highlights-list
      *ngIf="!isEditable"
      [data]="compatibility"
      [bgCssClass]="'-top-[35px]'"
      [format]="'tags'"
      [type]="'interests'"
    >
    </highlights-list>

    <highlights-list
      *ngIf="!isEditable"
      [data]="compatibility"
      [bgCssClass]="'-top-[35px]'"
      [format]="'tags'"
      [type]="'goals'"
    >
    </highlights-list>

    <instagram
      *ngIf="displayProfile.instagramToken"
      [displayProfile]="displayProfile"
      [fromMatches]="fromMatches"
      [isCurrentUserProfile]="isEditable"
      [currentUserInstagramToken]="currentUserInstagramToken"
      [class.from-matches-push]="fromMatches && isPlus"
    ></instagram>

    <!--Location Banner section-->
    <div
      class="profile-section white-section"
      id="location-banner-section"
      [class.loading]="!displayProfile.id"
      *ngIf="!isEditable && !fromMatches"
    >
      <div
        *ngIf="displayProfile.id"
        class="profile-section-header location-section-header heading"
      >
        <ion-icon
          name="location-sharp"
          class="location-section-icon bigger-icon"
        ></ion-icon>
        <p class="heading">Location</p>
      </div>
      <div *ngIf="!displayProfile.id" class="loading-container">
        <loading></loading>
      </div>
      <location-section
        *ngIf="displayProfile.location"
        [dataLocation]="displayProfile.location"
      ></location-section>
    </div>

    <!--Direct Messages Banner section-->
    <div
      class="profile-section blue-section"
      id="directmessage-banner-section"
      [class.loading]="!displayProfile.id"
    >
      <div *ngIf="!displayProfile.id" class="loading-container">
        <loading></loading>
      </div>
      <ion-grid *ngIf="!isEditable" class="location-container">
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-button
              *ngIf="canDirectMessage"
              class="direct-btn turquoise-gradient-btn btn-large-action ionic-button continue-button btn-bottom-margin turquoise-btn-shadow"
              [class.golden-button]="!isPlus"
              (click)="initDirectMessage()"
              shape="round"
              expand="block"
              >Direct Message</ion-button
            >
            <ion-button
              class="btn-large-action btn-bottom-margin report-btn"
              *ngIf="isPlus || !fromMatches || !fromFailedSearch"
              fill="clear"
              expand="block"
              (click)="tribeService.openReportModal( user, tribe )"
              >Report {{ user.firstName }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <!--TESTS START-->
    <div *ngIf="!isProduction">
      Current status: {{displayProfile.status}}
      <br />
      <ion-button (click)="joinTribeTest()">Join Tribe</ion-button>
      <ion-button (click)="confirmTribeTest()">Confirm Standby User</ion-button>
      <ion-button (click)="declineTribeTest()">Declined Tribe</ion-button>
      <ion-button (click)="expiredTribeTest()">Expire Invititation</ion-button>
      <ion-button (click)="rejectTest()">Reject Standby User</ion-button>
      <ion-button (click)="expire(2)">Expiry 2h</ion-button>
      <ion-button (click)="expire(6)">Expiry 6h</ion-button>
    </div>
    <!--TESTS END-->
  </div>

  <ion-button
    *ngIf="!failedStatus && !isPlus && !isEditable && fromMatches"
    class="ionic-button golden-button unlock-button-position"
    shape="round"
    (click)="openUpsellingPage()"
  >
    Add to my group
  </ion-button>

  <div *ngIf="fromMatches && isPlus">
    <div #instructions class="instructions-container hidden-instructions">
      <div class="instructions-header">
        <ion-icon class="instructions-add" name="person-add"></ion-icon>
        <div class="instructions-label">
          {{ "Add " + displayProfile.firstName + " to your group?" }}
        </div>
      </div>
      <div
        *ngIf="!inScheduledGroup; else scheduledGroupButtons"
        class="instructions-slots"
      >
        <div (click)="openMenu($event)" class="slot pass-button">
          Pass
          <ion-icon
            class="pass-dropdown"
            mode="md"
            name="caret-down-outline"
          ></ion-icon>
        </div>
        <div
          (click)="addToMatchesSelected()"
          class="slot add-button"
          [class.checkmarked]="checkMarked"
        >
          <ng-lottie
            [options]="checkmarkAnimConfig"
            *ngIf="checkMarked"
            class="checkmark-sign-animation"
            (animationCreated)="handleAnimation($event)"
          ></ng-lottie>
          <div class="button-text" *ngIf="!checkMarked">Add</div>
        </div>
      </div>
      <ng-template #scheduledGroupButtons>
        <div
          *ngIf="scheduledSearchesNumber < 3; else scheduledLimitReachedMessage"
          class="instructions-slots scheduled"
        >
          <ng-lottie
            [options]="checkmarkAnimConfig"
            *ngIf="checkMarked"
            class="checkmark-sign-animation"
            (animationCreated)="handleAnimation($event)"
          ></ng-lottie>
          <div
            *ngIf="!checkMarked"
            class="slot add-button scheduled"
            (click)="addScheduledUser()"
          >
            Add to Upcoming Group
          </div>
        </div>
        <ng-template #scheduledLimitReachedMessage>
          <div class="weekly-limit-text large-text black centered">
            Weekly group limit reached
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>

  <ion-card *ngIf="!isProduction && isEditable">
    <div (click)="showToast()">Show Toast</div>

    <h1>Lato</h1>
    <div class="lato black">London is the capital of Great Britain</div>
    <div class="lato semibold">London is the capital of Great Britain</div>
    <div class="lato regular">London is the capital of Great Britain</div>
    <div class="lato italic">London is the capital of Great Britain</div>

    <h1>Bubble Statuses</h1>
    <div class="statuses-list">
      <ion-icon
        class="tribe-status waiting-approving"
        name="timer-outline"
      ></ion-icon>
      <ion-icon
        class="tribe-status waiting-joining"
        name="timer-outline"
      ></ion-icon>
      <ion-icon
        class="tribe-status waiting-expiring"
        name="timer-outline"
      ></ion-icon>
      <ion-icon
        class="tribe-status accepted"
        name="checkmark-outline"
      ></ion-icon>
      <ion-icon
        class="tribe-status outside-radius"
        name="no-location"
      ></ion-icon>
      <ion-icon class="tribe-status arriving" name="airplane"></ion-icon>
    </div>

    <h1>Purple Alerts</h1>

    <div (click)="testService.openCustomAlert('subscriptionResumed')">
      Show Subscription Resumed
    </div>
    <div (click)="testService.openCustomAlert('subscriptionPaused')">
      Show Subscription Paused
    </div>

    <h1>Pages</h1>
    <div (click)="testService.openPage('/')">Root</div>
    <div
      (click)="testService.openPage('get-notified', testsData.getNotifiedExtras.nearMe)"
    >
      Get Notified page (Near me)
    </div>
    <div
      (click)="testService.openPage('get-notified', testsData.getNotifiedExtras.anywhere)"
    >
      Get Notified page (Global)
    </div>
    <div
      (click)="testService.openPage('get-notified', testsData.getNotifiedExtras.pickPlace)"
    >
      Get Notified page (Sydney)
    </div>
    <div
      (click)="testService.openPage('get-notified', testsData.getNotifiedExtras.empty)"
    >
      Get Notified page (empty state)
    </div>
    <div (click)="testService.openPage('claim-referral-reward')">
      Claim Referral Reward
    </div>
    <div (click)="testService.openPage('pending-status/declined')">
      Pending Status Decline
    </div>
    <div (click)="testService.openPage('manual_location')">Pick A Place</div>
    <div (click)="testService.openPage('/age_permission')">age permission</div>
    <div (click)="testService.openPage('/pending-status/failed')">
      Go To Failed page
    </div>
    <div (click)="testService.openPage('matches-depleted')">
      Depleted Matches
    </div>
    <div (click)="testService.openPage('insufficient-info')">
      Insufficient Info
    </div>
    <div (click)="testService.openPage('/pending-status/initiator-failed')">
      Go To Inititator Failed
    </div>
    <div (click)="testService.openPage('/pending-status/tribe-expired')">
      Go To Expired page
    </div>
    <div (click)="testService.openPage('levels/complete')">Complete page</div>
    <div (click)="testService.openPage('opening-code-page')">Code page</div>
    <div (click)="testService.openPage('sms-opt-in')">Add Number</div>
    <div (click)="testService.openPage('sms-opt-in/CO42LOL')">
      Add number (SMS)
    </div>
    <div (click)="testService.openPage('firewall-block')">Firewall Block</div>
    <div (click)="testService.openPage('whitelist')">Unblock We3</div>
    <div (click)="testService.openPage('troubleshooting-notifications')">
      Unblock We3 / Troubleshooting
    </div>
    <div (click)="testService.openPage('sms-verified/success')">
      Sms Verified (Success)
    </div>

    <h1>Menus</h1>
    <div (click)="testService.extraPopup()">Extra popup</div>

    <h1>Popups</h1>
    <div (click)="testService.genderChangePopup()">
      Open Gender Change Popup
    </div>
    <div
      *ngFor="let name of popupNames"
      class="capitalize"
      (click)="testService.openGenericPopup(name)"
    >
      {{ name | map:camelToNormal}}
    </div>

    <h1>Modals</h1>
    <div (click)="testService.showBottomSheet()">Show Ionic 6 Bottom Sheet</div>
    <div
      (click)="testService.openHeartfeltModal({ state: 'groupStarted', userName: 'Puto' })"
    >
      Heartfelt Started Modal
    </div>
    <!-- <div (click) = "testService.openHeartfeltModal({ state: 'groupFailed' })">Heartfelt Failed Modal</div> -->
    <div (click)="testService.openModal('location')">
      Open Location Permission Modal
    </div>
    <div (click)="testService.openModal('reviewRequest')">Review Request</div>
    <div
      (click)="testService.openModal('searching', { searchingState: 'searching' })"
    >
      Tribe search modal
    </div>
    <div
      (click)="testService.openModal('infoModalPrivacy', { type: 'privacy' })"
    >
      Info Modal (Privacy)
    </div>
    <div
      (click)="testService.openModal('infoModalPrivacy', { type: 'joiningConditions' })"
    >
      Info Modal (Join Groups)
    </div>
    <div (click)="testService.openModal('infoModalPrivacy', { type: 'radar' })">
      Info Modal (Radar)
    </div>
    <div
      (click)="testService.openModal('infoModalPrivacy', { type: 'batteryOptimization' })"
    >
      Info Modal (Battery Optimization)
    </div>
    <div (click)="testService.openInfoModal('give_drinks_unlimited')">
      Give drinks unlimited
    </div>
    <div (click)="testService.openInfoModal('give_drinks_2_friends')">
      Give drinks 2 friends
    </div>
    <div (click)="testService.openInfoModal('claim_drink')">Claim drink</div>
    <div (click)="testService.showCheckmarkPage()">Show Checkmark Modal</div>

    <hr />
    <h1>Other forms</h1>
    <div (click)="testService.openFeedbackForm()">Open Feedback Form</div>
    <div (click)="testService.openPickAPlace()">Open Pick A Place Page</div>
    <div (click)="testService.openSignUpFlow('gender')">
      Open Sign Up Flow (GENDER)
    </div>
    <div></div>
    <div (click)="testService.openSignUpFlow('photo')">
      Open Sign Up Flow (Photo)
    </div>
    <div></div>
    <div (click)="testService.openSignUpFlow('locationPreference')">
      Open Sign Up Flow (Location)
    </div>
    <div></div>
    <div (click)="testService.openWaiting()">Waiting</div>
    <div></div>
  </ion-card>

  <ion-card *ngIf="!isProduction && isEditable">
    <h1>Events</h1>
    <ul class="events">
      <li
        class="event"
        [ngClass]="{'app' : event.source == 'app',
                                          'backend' : event.source == 'backend',
                                          'property': event.type == 'prop'}"
        *ngFor="let event of sessionService.trackedData"
      >
        <b>{{event.timestamp.getHours()}}:{{event.timestamp.getMinutes()}}</b>
        {{event.name}} {{event.params}}
      </li>
    </ul>
  </ion-card>

  <hr />
  <div *ngIf="!isProduction && isEditable">
    <notification-badge
      [content]="getTestBadgeInfo('arriving')"
    ></notification-badge>
    <notification-badge
      [content]="getTestBadgeInfo('outsideRadius')"
    ></notification-badge>
    <notification-badge
      [content]="getTestBadgeInfo('approving')"
    ></notification-badge>
    <notification-badge
      [content]="getTestBadgeInfo('joining')"
    ></notification-badge>
  </div>
</ion-content>
