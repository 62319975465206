import {Component, Input, NgZone, OnInit, Renderer2} from '@angular/core';
import {DispatcherService} from "../../services/dispatcher.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'removal-statuses',
  template: `    
    <div *ngFor = "let status of statuses; let i = index" 
         [class.hidden] = "hidden"
         class = "status-container status-container-{{ i }} {{ status?.cssClass }}"
         (click) = "status.action()">
      <ion-icon class = "status-icon" [name] = "status?.icon"></ion-icon>
      <div class = "status-text" [innerHTML] = "status?.text"></div>
      <div class = "status-action" [innerHTML] = "status?.timeLeft"></div>
    </div>
  `,
  styleUrls: ['./removal-statuses.component.scss'],
})
export class RemovalStatusesComponent implements OnInit {

  @Input() statuses;

  public hidden: boolean = true;
  private unsubscribe = new Subject();

  constructor(private dispatcherService: DispatcherService,
              private ngZone: NgZone) { }

  ngOnInit() {
    this.dispatcherService
      .onStatusBarChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(action => setTimeout(() => ({
          hide: () => this.hidden = true,
          show: () => this.hidden = false
        }[action](),
        750
      )));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
