import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { NavParams, PopoverController, Platform } from '@ionic/angular';
import { UserService } from '../../services/data/user.service';
import {UtilsService} from "../../services/utils.service";
import {MainErrorHandlerService} from "../../services/performance/main-error-handler.service";
import { KeyboardService } from 'src/app/services/native/keyboard.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { timeStamp } from 'console';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/data/api.service';

@Component({
  selector: 'send-feedback',
  template: `
    <div>
      <div class = "profile-popover-title">
        Contact Us
      </div>
      <ion-item lines = "none" class = "feedback-input-container">
        <ion-textarea
                class = "feedback-input"
                placeholder = "Tell us what you think"
                [(ngModel)] = "currentMessage"
                autofocus
                autosize
                [maxRows]="6"
                type = "text"
                autocapitalize
                rows = '{{rows}}'
                maxlength = "512"
        ></ion-textarea>
      </ion-item>
      
      <div class="profile-popover-button-group">
        <ion-button class = 'profile-cancel-button'
                    fill = "clear"
                    (click) = "cancel()">Cancel</ion-button>
        <ion-button class='profile-ok-button'
                    fill = "clear"
                    [disabled] = "!currentMessage || !apiIsOnline"
                    (click) = "sendFeedback()">
          Send
        </ion-button>
      </div>
    </div>
  `,
  styleUrls: ['send-feedback.scss']
})
export class SendFeedbackComponent {
  private readonly context: any = null;
  private readonly errorContext: any = null;
  public extended: boolean = false;
  public rows: number = 1;
  public currentMessage: string = '';
  private MAX_ROWS: number = 10;
  private MIN_ROWS: number = 1;

  private unsubscribe: Subject<any> = new Subject();
  public keyboardOpened: boolean = false;

  public apiIsOnline: boolean = false;

  constructor(private popoverCtrl: PopoverController,
              private userService: UserService,
              private navParams: NavParams,
              private utils: UtilsService,
              private renderer: Renderer2,
              private apiService: ApiService,
              private elementRef: ElementRef,
              private keyboardService: KeyboardService,
              private platform: Platform,
              private mainErrorHandler: MainErrorHandlerService) {
    this.context = this.navParams.get('context') || null;
    this.errorContext = this.navParams.get('errorContext');
    if(environment.name == 'development') {
      (window as any).feedbackForm = this;
    }
  }

  ngOnInit() {
    this.apiIsOnline = this.apiService.isOnline();
    this.keyboardService
      .onKeyboardOpened
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if(this.platform.is("ios")) {
          this.renderer.setStyle(this.elementRef.nativeElement, "bottom", '-100px');
        }
      });

    this.keyboardService
      .onKeyboardClosed
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.renderer.setStyle(this.elementRef.nativeElement, "bottom", '0');
      });
  }

  async sendFeedback(): Promise<any> {
    try {
      await this.userService.sendFeedback(this.currentMessage, this.context);
      await this.utils.showGenericMessage("Message sent! We'll respond soon by email");
    } catch ( err ) {
      this.utils.errorContext = this.errorContext;
      await this.utils.showGenericError({
        key: "sending_feedback"
      });

      this.mainErrorHandler.handleError( err );
    } finally {
      await this.popoverCtrl.dismiss();
    }
  }

  extend(): void {
    this.extended = !this.extended;
    this.rows = this.extended ? this.MAX_ROWS : this.MIN_ROWS;
  }

  async cancel(): Promise<any> {
    return this.popoverCtrl.dismiss();
  }

}
