import { IonicModule } from '@ionic/angular';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageViewerComponent } from "./image-viewer.component";

@NgModule({
  declarations: [
    ImageViewerComponent,
  ],
  entryComponents: [
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    ImageViewerComponent
  ]
})

export class ImageViewerModule {}
