import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichContent } from './rich-content';
import { Loading } from "../loading/loading";
import { ChatImage } from "../chat-image/chat-image";
import { ChatVideo } from "../chat-video/chat-video";
import { ChatLink } from "../chat-link/chat-link";
import { ChatMap } from "../chat-map/chat-map";
import { LoadingModule } from "../loading/loading.module";
import { ChatImageModule } from "../chat-image/chat-image.module";
import { ChatVideoModule } from "../chat-video/chat-video.module";
import { ChatLinkModule } from "../chat-link/chat-link.module";
import { ChatMapModule } from "../chat-map/chat-map.module";
import {HammerModule} from "@angular/platform-browser";


@NgModule({
  declarations: [
    RichContent
  ],
  entryComponents: [
    Loading, ChatImage, ChatVideo, ChatLink, ChatMap
  ],
  imports: [
    HammerModule,
    CommonModule,
    LoadingModule,
    ChatImageModule,
    ChatVideoModule,
    ChatLinkModule,
    ChatMapModule
  ],
  exports: [
    RichContent
  ],
  schemas: [ ]
})
export class RichContentModule {}
