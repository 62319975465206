import {Component, Input, Output} from "@angular/core";
import { EventEmitter } from "@angular/core";
import {CheckmarkButton} from "../checkmark-buttons-list/checkmark-buttons-list.component";

@Component({
  selector: "checkmark-button",
  template: `
    <div class = "checkmark-button my-[10px] {{ cssClass }}" 
         [class.checked] = "button?.text == selected?.text" 
         (click) = "emit()">
      <ion-checkbox mode = "ios" 
                    class = "shrink-0"
                    [checked] = "button?.text == selected?.text">
      </ion-checkbox>
      <span class = "text-base text-body font-semibold line-clamp-2" [innerHTML] = "button?.text"></span>
      <ion-icon *ngIf = "button?.titleIcon" 
                [name] = "button?.titleIcon?.name"
                class = "text-[22px] ml-[3px]"
                [class] = "button?.titleIcon?.cssClass"></ion-icon>
      <!-- <ion-chip *ngIf = "button?.chip" class = "bg-input-background ml-auto">
        <ion-icon class = "text-label" [name] = "button?.chip?.icon"></ion-icon>
        <span class = "text-label font-heavy text-small">{{ button?.chip?.text }}</span>
      </ion-chip> -->
      <chip class = "ml-auto"
            [iconName] = "button?.chip?.icon"
            [text] = "button?.chip?.text"
            [activatedIconCssClass] = "button?.chip?.activatedIconCssClass"
            [activated] = "button?.text == selected?.text"
            [activatedCssClass] = "button?.chip?.activatedCssClass"></chip>
    </div>
  `,
  styleUrls: ["checkmark-button.component.scss"]
})
export class CheckmarkButtonComponent {
  @Input('button') button: CheckmarkButton;
  @Input("selected") selected: CheckmarkButton;
  @Input("cssClass") cssClass: string = '';
  @Output("onSelected") onSelected = new EventEmitter<CheckmarkButton>();

  constructor() {}

  emit() {
    this.onSelected.emit(this.button);
  }
}
