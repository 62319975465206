export class Message {
  public id: number;
  public content: string = '';
  public user_id: number;
  public created_at: Date;
  public reply_to?: Message;
  public reply_to_id?: number;
  public seen_by: any[] = new Array();

  constructor() {
  }

}
