export {}

declare global {
  interface Number {
    isBetween(lowerThreshold: any, higherThreshold: any): boolean;
    inRange(start: number, end: number): boolean;
  }
}

if(!Number.prototype.inRange) {
  Number.prototype.inRange = function(start, end) {
    return this >= start && this <= end;
  }
}

if(!Number.prototype.isBetween) {
  Number.prototype.isBetween = function(lt, ht): boolean {
    if(isNaN(parseInt(lt)) || isNaN(parseInt(ht))) return false;

    if(this == undefined) return false;

    if(lt == ht && this == lt) return true;

    if(lt > ht) {
      [lt, ht] = [ht, lt];
    }

    return this > lt && this < ht;
  }
}
