import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMessageComponent } from "./chat-message.component";
import {IonicModule} from "@ionic/angular";
import {RichContent} from "../../rich-content/rich-content";
import {RichContentModule} from "../../rich-content/rich-content.module";
import {DirectivesModule} from "../../../directives/directives.module";
import {PipesModule} from "../../../pipes/pipes.module";

@NgModule({
  entryComponents: [
    RichContent
  ],
  declarations: [
    ChatMessageComponent
  ],
  imports: [
    RichContentModule,
    DirectivesModule,
    PipesModule,
    CommonModule,
    IonicModule
  ],
  exports: [
    ChatMessageComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ChatMessageModule {}
