import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ProfilePopupComponent } from "../../components/profile-popups/profile-popup";
import { DispatcherService } from "../dispatcher.service";
import { InfoPage } from "../../pages/info/info.page";
import { ReviewRequestPage } from "../../pages/review-request/review-request.page";
import { SearchingPage } from "../../pages/searching/searching.page";
import { HeartfeltComponent } from "../../components/heartfelt/heartfelt.component";
import { FailedGroupVideoModalComponent } from "../../components/failed-group-video-modal/failed-group-video-modal.component";
import { Config } from "../config/config";
import { HttpClient } from "@angular/common/http";
import { logc } from "../../shared/helpers/log";
import { FeedbackService } from "./feedback.service";

import * as _ from "lodash";
import { CustomActionSheetComponent } from "../../components/custom-action-sheet/custom-action-sheet.component";
import { UpsellingPage } from "src/app/pages/upselling/upselling.page";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private viewedProfiles = {};
  private firstImpressions: any = {};

  constructor(
    private modalCtrl: ModalController,
    private http: HttpClient,
    private config: Config,
    private feedbackService: FeedbackService,
    private dispatcherService: DispatcherService
  ) {
    (window as any).modalService = this;
    this.http
      .get("./assets/data/first_impressions.json")
      .toPromise()
      .then((data: any) => {
        this.firstImpressions = data.custom_payload;
        this.firstImpressions.analytics.type = "feedback";
      });
  }

  init() {
    this.dispatcherService.onNewModal.subscribe((data) => {
      console.log(data);
      ({
        profile: () => this.openProfile(data.options),
        upselling: () =>
          this.openUpselling({
            source: data.source,
            reason: data.reason,
          }),
        reviewRequest: () => this.openReviewRequest(),
        searchingPage: () => this.openSearching(data.options),
      })[data.modalName]();
    });
    this.dispatcherService.onNewInfoModal.subscribe((data) => {
      this.openInfoModal(data);
    });
  }

  openVideoModal() {
    this.modalCtrl
      .create({
        component: FailedGroupVideoModalComponent,
        cssClass: "modal-card",
      })
      .then((modal) => modal.present());
  }

  async openHeartfeltModal(props) {
    const modal = await this.create({
      component: HeartfeltComponent,
      componentProps: props,
      initialBreakpoint: 1,
      breakpoints: [1, 0],
    });

    modal.onDidDismiss().then(() => {
      if (props && typeof props == "function") {
        props?.callback();
      }
    });
  }

  async create(modalData) {
    try {
      const modal = await this.modalCtrl.create({
        ...modalData,
        ...{ cssClass: `modal-card ${modalData.cssClass || ""}` },
      });
      modal.present();
      return modal;
    } catch (err) {
      console.log("Modal service error: ", err);
    }
  }
  users;
  async openProfile(options) {
    logc.pink("openProfile options: ", options);
    try {
      const modal = await this.modalCtrl.create({
        component: ProfilePopupComponent,
        componentProps: options,
        backdropDismiss: true,
        swipeToClose: true,
        cssClass: "profile-modal modal-card",
      });
      await modal.present();
      return modal;
    } catch (err) {
      console.log(err);
    }
  }

  private formatImpressions(users) {
    const [user1, user2] = [users[0], users[1]];
    return JSON.parse(
      JSON.stringify(_.cloneDeep(this.firstImpressions))
        .replaceAll("[tribe_user_1.picture]", user1.picture)
        .replaceAll("[tribe_user_1.first_name]", user1.first_name)
        .replaceAll("[tribe_user_2.picture]", user2.picture)
        .replaceAll("[tribe_user_2.first_name]", user2.first_name)
    );
  }

  async openUpselling({ source, reason = null }) {
    const modal = await this.modalCtrl.create({
      component: UpsellingPage,
      componentProps: { source, reason },
      swipeToClose: true,
      cssClass: "upselling-popup z-index-30000",
      backdropDismiss: true,
    });
    return modal.present();
  }

  async openSearching(options) {
    const modal = await this.modalCtrl.create({
      component: SearchingPage,
      componentProps: options,
      cssClass: "upselling-popup",
      backdropDismiss: true,
    });
    return modal.present();
  }

  async openInfoModal({ type, closingCallback = () => {} }) {
    try {
      const modal = await this.modalCtrl.create({
        component: InfoPage,
        componentProps: { type },
        cssClass: "info-modal",
      });

      await modal.present();
      modal.onDidDismiss().then((data) => closingCallback());
    } catch (err) {
      console.log(err);
    }
  }

  async openReviewRequest() {
    try {
      const modal = await this.modalCtrl.create({
        component: ReviewRequestPage,
      });
      await modal.present();
    } catch (err) {
      console.log(err);
    }
  }

  async openUsersModal({ componentProps, callback, initialBreakpoint = 0.3 }) {
    const breakpoints = [0, initialBreakpoint, 1];
    const modal = await this.modalCtrl.create({
      component: CustomActionSheetComponent,
      componentProps,
      initialBreakpoint,
      breakpoints,
      cssClass: "border-round-modal z-index-30000",
    });
    await modal.present();
    modal.onDidDismiss().then(async ({ data }) => {
      if (data) await callback(data);
    });
    return modal;
  }

  async closeAll() {
    if (await this.modalCtrl.getTop()) {
      this.modalCtrl.dismiss();
    }
    return;
  }
}
