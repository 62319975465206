import { Injectable } from "@angular/core";
import { Config } from "./config/config";
import { PushNotification } from "./push-notification/push-notification";
import { AnalyticsService } from "./analytics/analytics.service";
import {
  NavController,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import { DispatcherService } from "./dispatcher.service";
import { MainErrorHandlerService } from "./performance/main-error-handler.service";
import { Subject } from "rxjs/Subject";
import { merge, Observable, pipe } from "rxjs";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { logc } from "../shared/helpers/log";
import { AudiencesService } from "./analytics/audiences.service";
import { IpService } from "./ip.service";

@Injectable({
  providedIn: "root",
})
export class UserNotificationService {
  private smsRestricted;
  private userNotifiableStatusSource = new BehaviorSubject(false);
  public onUserNotifiableStatusChanged: Observable<any>;
  private currentCountryCode: string | null = null;
  private availableCountries: string[] = ["US", "CA"];
  constructor(
    private config: Config,
    private ipService: IpService,
    private pushNotification: PushNotification,
    private analyticsService: AnalyticsService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private dispatcherService: DispatcherService,
    private mainErrorHandler: MainErrorHandlerService,
    private audiencesService: AudiencesService
  ) {
    this.onUserNotifiableStatusChanged =
      this.userNotifiableStatusSource.asObservable();
    this.ipService.onIpInfoFetched.subscribe(({ countrycode }) => {
      logc.red("user notification serivice countrycode", countrycode);
      this.currentCountryCode = countrycode;
    });
    this.init();
  }

  init() {
    this.smsRestricted = this.config.getFlag("smsRestricted");

    merge(this.config.onProfileChanged, this.config.onFlagsChanged).subscribe(
      (profile) => {
        this.setUserNotifiableStatus();
      }
    );
  }

  getNotifiableObservable() {
    return this.userNotifiableStatusSource;
  }

  async notifyMe(options = {}) {
    if (this.availableCountries.includes(this.currentCountryCode)) {
      await this.navCtrl.navigateForward(
        "number-verification/notify_me",
        options
      );
    } else {
      await this.pushNotification.requestPushPermissionAndInit();
      await this.navCtrl.navigateForward("tabs/tribes");
    }
    this.sendAnalyticsEvent();
  }

  setUserNotifiableStatus() {
    const userWillBeNotified =
      (this.numberIsVerified() || this.pushNotification.hasPush()) &&
      this.config.getFlag("notify_me_intent");

    this.userNotifiableStatusSource.next(userWillBeNotified);
  }

  isReady() {
    return (
      (this.numberIsVerified() || this.smsRestricted) &&
      this.pushNotification.hasPermission
    );
  }

  numberIsVerified() {
    return this.config.getProfile().phoneNumberStatus === "verified";
  }

  async setNotification(extras = {}) {
    this.sendAnalyticsEvent();
    if (!this.numberIsVerified() && !this.smsRestricted) {
      await this.navCtrl.navigateForward(
        "number-verification/notify_me",
        extras
      );
    } else {
      if (!this.pushNotification.hasPermission) {
        this.askForPushes();
      } else {
        this.leaveFlow();
      }
    }
  }

  async leaveFlow() {
    await this.navCtrl.navigateBack("tabs/tribes");
    this.showNotifyToast();

    if (
      !this.config.getFlag("start_global_tribe_viewed") &&
      !this.audiencesService.inScheduledGroup()
    ) {
      this.showNewTribePopup();
      await this.config.setFlag("start_global_tribe_viewed", true);
    }
  }

  async showNotifyToast() {
    const toast = await this.toastCtrl.create({
      message: "We'll notify you when your Group is ready",
      duration: 3000,
      position: "bottom",
    });
    return toast.present();
  }

  async showNewTribePopup() {
    this.dispatcherService.openPopup("updateRadiusToGlobal");
  }

  async askForPushes() {
    try {
      await this.pushNotification.requestPushPermissionAndInit();
    } catch (err) {
      console.log(err);
      this.mainErrorHandler.handleError(err);
    } finally {
      this.leaveFlow();
    }
  }

  sendAnalyticsEvent() {
    this.analyticsService.trackEvent({
      key: "notify_me_when_tribe_is_ready",
      value: 1,
      has_push: this.pushNotification.hasPermission ? 1 : 0,
      has_sms: this.numberIsVerified() ? 1 : 0,
    });
  }
}
