<ion-grid>
  <ion-row>
    <ion-text class="ion-title-custom">
      {{data.title}}
    </ion-text>
  </ion-row>

  <ion-row>
    <ion-text class="ion-text-custom">
      {{data.text}}
    </ion-text>
  </ion-row>

</ion-grid>
<div class="alert-button-group">
  <div (click) = "changeAlert()" class = "alert-button">Change</div>
</div>