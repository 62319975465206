import { Component, OnInit } from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-decline-menu',
  templateUrl: './decline-menu.component.html',
  styleUrls: ['./decline-menu.component.scss']
})
export class DeclineMenuComponent implements OnInit {

  public fromHighights: boolean;

  constructor(private popoverCtrl: PopoverController,
              private navParams: NavParams) {
    this.fromHighights = this.navParams.get('highlights');
  }

  ngOnInit() {
  }

  dontFeel() {
    this.popoverCtrl.dismiss({action: 'dontFeel'});
  }

  tooFar() {
    this.popoverCtrl.dismiss({action: 'tooFar'});
  }

  report() {
    this.popoverCtrl.dismiss({action: 'report'});
  }

  block() {
    this.popoverCtrl.dismiss({action: 'block'});
  }

}
