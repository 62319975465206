import { Component, ViewChild, ElementRef } from "@angular/core";
import { UserService } from "../../services/data/user.service";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "failed-group-video-modal",
  template: `
    <ion-header>
      <div class="close-button-container" (click)="closeModal()">
        <ion-icon
          class="close-icon-absolute right no-plate"
          name="close-outline"
        ></ion-icon>
      </div>
    </ion-header>
    <ion-content class="ion-content-extended-padding">
      <div class="huge-text bold title-color">
        Looks like that group didn't work out, eh? 😬
      </div>
      <div class="large-text body-color paragraph">
        Check out how our founder uses We3 to get responsive groups.
      </div>

      <div class="video-container my-4">
        <video #videoPlayer
           preload="auto"
           [src]="videoUrl" 
           (click)="playVideo()" 
           (canplay)="onVideoBufferingDone()" 
           (loadstart)="onVideoBuffering()"
           poster="./assets/img/failed_group_thumbnail.png">
        </video>
        <ion-spinner *ngIf="isLoading"></ion-spinner>
      </div>
    </ion-content>
  `,
  styleUrls: ["failed-group-video-modal.component.scss"],
})
export class FailedGroupVideoModalComponent {
  public windowWidth = null;
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;


  constructor(private modalCtrl: ModalController, 
              private userService: UserService) {
    this.windowWidth = (window as Window).innerWidth - 50 + "px";

    if(this.userService.isUserFrom('United States of America') ||
      this.userService.isUserFrom('Canada')) {

      this.videoUrl = 'https://s3.us-west-2.amazonaws.com/me3.assets/failed_group/message_from_julian.mp4'; 
    } else {
      this.videoUrl = 'https://s3.us-west-2.amazonaws.com/me3.assets/failed_group/message_from_julian_outside_north_america.mp4';
    }
  }

  videoUrl: string = null;
  isLoading: boolean = true;
  
  onVideoBuffering() {
    console.log('buffering...');
    this.isLoading = true;
  }

  onVideoBufferingDone() {
    console.log('buffering done...');
    this.isLoading = false;
  }

  // Function to play video
  playVideo(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
