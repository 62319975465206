import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {TribeAvatarComponent} from "./tribe-avatar.component";
import {CommonModule} from "@angular/common";
import {DirectivesModule} from "../../directives/directives.module";
import {PipesModule} from "../../pipes/pipes.module";
import {LottieModule} from "ngx-lottie";

@NgModule({
  declarations: [TribeAvatarComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    PipesModule,
    LottieModule
  ],
  exports: [TribeAvatarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TribeAvatarModule {}
