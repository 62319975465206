import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import {SentryService} from "./sentry.service";

@Injectable({
  providedIn: 'root'
})
export class PerformanceReportService {
  public instance: any = null;

  constructor(private platform: Platform,
              private firebaseX: FirebaseX,
              private errorTrackingService: SentryService
             ) {
  }

  load() {
    if(this.platform.is('cordova')) {
      this.instance = this.firebaseX;
    } else {
      this.instance = (<any> window).firebase.performance();
    }

    (<any> window).performanceReport = this.instance;
    console.log("--- PerformanceReportService loaded ---");
  }

  traces: any = {};
  startTrace(traceName) {
    // console.log("*** START TRACE NAME: ", traceName);
    if(this.platform.is('cordova')) {
      this.instance?.startTrace(traceName);
    } else {
      let trace = this.instance?.trace(traceName);
      this.traces[traceName] = trace;
      trace?.start();
    }
  }

  stopTrace(traceName) {
    // console.log("*** STOP TRACE NAME: ", traceName);
    if(this.platform.is('cordova')) {
      this.instance?.stopTrace(traceName);
    } else {
      this.traces[traceName]?.stop();
      delete this.traces[traceName];
    }
  }

  record( traceName ) {
    if(this.platform.is('cordova')) {
      // this.instance.stopTrace(traceName);
    } else {
      console.info('record trace: ', this.traces);
      this.instance.trace('hola');
      // delete this.traces[traceName];
    }
  }

  incrementCounter(traceName, counterName) {
    if(this.platform.is('cordova')) {
      this.instance.incrementCounter(traceName, counterName);
    } else {
      let t = this.instance.trace(traceName);
      t.incrementCounter(counterName);
    }
  }
}
