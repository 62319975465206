import { Component, Input } from '@angular/core';

interface DestroySelf {
  destroySelf(): void;
}

@Component({
  selector: 'tooltip-warning',
  template: `
    <div (click) = "callback()" class = "custom-tooltip secondary-gradient fade-in-animation delay-1sec">
      <div class = "bottom-arrow center"></div>
      <div class = "text whitespace-nowrap">You can select who</div>
    </div>
  `,
  styleUrls: ['tooltip-warning.scss']
})
export class TooltipWarningComponent implements DestroySelf {
  @Input('callback') callback: Function = () => {};

  constructor() { }

  destroySelf() {}
}
