import { Injectable } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Subject } from 'rxjs/Subject';
import { Network } from '@ionic-native/network/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { environment } from '../../../environments/environment';
import { AppService } from '../app';
import { AnalyticsService } from '../analytics/analytics.service';
import { StateMachine } from "../../shared/lib/state-machine/state-machine";
import { FirewallBlockPage } from "../../pages/firewall-block/firewall-block.page";
import { UnderMaintenancePage } from "../../pages/under_maintenance/under_maintenance.page";
import { SMELL_CHECK_TIMEOUT } from "../../shared/constants/constants";

declare const ENV;
import 'rxjs/add/operator/map';
import {SentryService} from "../performance/sentry.service";
import {ConsoleService} from "../performance/console.service";
import {SMState} from "../../shared/interfaces";
import {logc} from "../../shared/helpers/log";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private connectedSource = new Subject();
  private disconnectedSource = new Subject();
  public onConnected: any = this.connectedSource.asObservable();
  public onDisconnected: any = this.disconnectedSource.asObservable();

  private authenticatedHeaders: any = null;
  private secureToken: any = '';
  private apiPreflightCorsCache = 3600;
  public url = environment.domain + '/api/v1';
  public skippingPauses = false;

  private smellCheckTimeout: any = null;

  private statusCodesInfo = new Map<number, any>([
    [ 0, {
      exception: ApiService.httpResponseApiError,
      errorPage: FirewallBlockPage,
      eventName: 'server_unreachable'
    }],
    [ 500, {
      exception: ApiService.unableToConnectToTheApiError,
      errorPage: UnderMaintenancePage,
      eventName: 'server_down'
    }],
  ]);

  private connectSubscription: any = null;
  private disconnectSubscription: any = null;
  static unauthorized: any = { message: 'Unauthorized access' };
  static unableToConnectToTheApiError: any = { message: 'API is unreachable' };
  static httpResponseApiError: any = { message: 'Http response error' };
  public stateMachine:any = null;

  constructor(private http: HttpClient, 
              private config: Config,
              private appService: AppService,
              private navCtrl: NavController,
              public network: Network,
              private modalCtrl: ModalController,
              private geolocation: Geolocation,
              private errorTrackingService: SentryService,
              private consoleService: ConsoleService,
              private analyticsService: AnalyticsService){

    this.stateMachine = new StateMachine({
        standby: {
          on: () => {},
          init: 'initializing',
          resume: 'connecting'
        },
        initializing: {
          on: () => {
            this.init();
            return 'connecting';
          },
          pause: 'standby'
        },
        connecting: {
          on: () => {
            return new Promise(async (resolve, reject) => {
              const connected = await this.connect();
              resolve(connected ? 'connected' : 'offline');
            });
          },
          pause: 'standby'
        },
        connected: {
          on: () => {
            return this.connected();
          },
          apiError: (context) => {
            this.onServerDown(context.uri, context.status, context.params);
            return 'apiDown';
          },
          pause: 'standby',
          disconnect: 'offline',
          drop: ["connect"]
        },
        apiDown: {
          on: () => {
            return new Promise((resolve, reject) => {
              setTimeout(_ => {
                resolve('apiDownCheck');
              }, 3000);
            })
          },
          pause: 'standby'
        },
        apiDownCheck: {
          on: () => {
            return new Promise(async (resolve, reject) => {
              const down = await this.isServerDown();
              if (down) {
                this.onOffline();
                resolve('apiDown');
              } else {
                resolve('connecting');
              }
            });
          }
        },
        offline: {
          on: () => {
            this.onOffline();
          },
          pause: 'standby',
          connect: 'connecting',
          drop: ['disconnect']
        }
      }, 'api', this.appService);
    this.stateMachine.exec('init');

    // if(this.config.isDev) {
      (<any> window).api = this;
    // }

  }

  init() {
    this.stateMachine
      .onStateChanged
      .subscribe((state: SMState) => {
        this.config.SMAggregator.api = state;
      })

    this.appService.onPause.subscribe( _ => {
      clearTimeout(this.smellCheckTimeout);
    });

    this.appService.onResume.subscribe( _ => {
      this.onResume();
      this.smellCheck();
    });

    (<any> window).addEventListener('online',  () => {
      console.log("-- api ONLINE listener triggered");
      this.stateMachine.exec('connect');
    });
    (<any> window).addEventListener('offline', () => {
      console.log("-- api OFFLINE listener triggered");
      this.stateMachine.exec('disconnect');
    });
  }

  smellCheck() {
    this.smellCheckTimeout = setTimeout(() => {
      if(this.isOnline()) {
        if (this.stateMachine.state !== 'connected') {
          const message = "Api Device SM isn't 'ready' in 30s";
          const tags = {klass: "api", func: "smellCheck()"}
          const extras = {apiState: this.stateMachine.state, logs: this.consoleService.getLogs()};
          this.errorTrackingService.sendMessage(message, tags, extras);
        }
      }
    }, SMELL_CHECK_TIMEOUT);
  }

  connect(): Promise<boolean> {
    return new Promise( (resolve, reject) => {
      setTimeout(  _ => {
        if(this.isNavigatorOnline()) {
          resolve(true);
        } else {
          setTimeout( _ => {
            if(this.isNavigatorOnline()) {
              resolve(true);
            } else {
              this.ping('https://www.google.com/').then( (isConnected: boolean)  => {
                  resolve(isConnected);
                }).catch( e => { 
                  resolve(false);
                });
            }
          }, 1200);
        }
      }, 500);
    });
  }

  get(resource, params, providedHeaders = null) {
    return new Promise((resolve, reject) => {

      let headers;

      if(!!this.getApiKey()) {
        headers = this.getAuthenticatedHeaders();
      } else {
        headers = this.getAnonymousHeaders();
      }

      let reqOpts = {
        params: params,
        headers: (providedHeaders || headers)
      };

      let uri = this.url + '/' + resource + '.json';
      this.http.get(uri, reqOpts)
        .toPromise()
        .then(resolve, err => reject(this.errorHandler(err, uri, params)));
    });
  }

  _get(resource, params, providedHeaders = null): Observable<any> {
      let headers;

      if(!!this.getApiKey()) {
        headers = this.getAuthenticatedHeaders();
      } else {
        headers = this.getAnonymousHeaders();
      }

      const reqOpts = {
        params: params,
        headers: (providedHeaders || headers)
      };

      const uri = this.url + '/' + resource + '.json';
      return this.http.get(uri, reqOpts);
  }

  post(resource, params, providedHeaders=null, throwsErrors=false){
    return new Promise((resolve, reject) => {

      let postHeaders;
      if(!!this.getApiKey()) {
        postHeaders = this.getAuthenticatedHeaders();
      } else {
        postHeaders = this.getAnonymousHeaders();
      }

      const reqOpts = {
        headers: (providedHeaders || postHeaders)
      };

      let uri = this.url + '/' + resource + '.json';
      this.http.post(uri,
        JSON.stringify(params),
        reqOpts)
        .toPromise()
        .then(
          (data:any) => resolve(data),
          err => { 
            if(throwsErrors) {
              reject(err);
            } else {
              reject(this.errorHandler(err, uri, params));
            }
          }
        );
    });
  }

  put(resource, params): Promise<any> {
    return new Promise( (resolve, reject) => {
      const reqOpts = {
        headers: this.getAuthenticatedHeaders()
      };

      let uri = this.url + '/' + resource + '.json';
      this.http.put(uri, params, reqOpts)
        .toPromise()
        .then(
          (data: any) => resolve(data),
          err => reject(this.errorHandler(err, uri, params)));
    });
  }

  delete(resource, params){
    return new Promise((resolve, reject) => {
      const reqOpts = {
        params: params,
        headers: this.getAuthenticatedHeaders()
      };


      let uri = this.url + '/' + resource + '.json';
      this.http.delete(uri, reqOpts)
        .toPromise()
        .then(resolve, err => reject(this.errorHandler(err, uri, params)));
    });
  }

  private isNavigatorOnline() {
    return (<any> navigator).onLine;
  }

  isReconnection = false;
  async connected() {
    if(!!await this.modalCtrl.getTop())  {
      this.closeErrorPage();
    }
    this.connectedSource.next({ isReconnection: this.isReconnection });
    this.isReconnection = true;
  }

  onOffline() {
    console.log('--- api state machine onOffline() ---');
    this.disconnectedSource.next(null);
  }

  setSecureToken(token) {
    this.secureToken = token;
    this.resetCache();
  }

  ping(url, timeout = 6000) {
    return new Promise((reslove, reject) => {
      const urlRule = new RegExp('(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]');
      if (!urlRule.test(url)) reject('invalid url');
      try {
        fetch(url, { mode: 'no-cors', method: 'HEAD'})
          .then(() => reslove(true))
          .catch(() => reslove(false));
        setTimeout(() => {
          reslove(false);
        }, timeout);
      } catch (e) {
        reject(e);
      }
    });
  }
  
  onPause() {
    this.stateMachine.exec('pause');
  }

  onResume() {
    this.stateMachine.exec('resume');
  }

  isOffline(): boolean {
    return !this.isOnline();
  }

  isReady(): boolean {
    return this.stateMachine.state === 'connected';
  }

  isOn() {
    return this.stateMachine.state !== 'offline';
  }

  isOnline(): boolean {
    return this.stateMachine.state === 'connected';
  }

  ngOnDestroy() {
    this.connectSubscription.unsubscribe();
    this.disconnectSubscription.unsubscribe();
  }
 
  resetCache(){
    this.authenticatedHeaders = null;
  }

  getApiKey() {
    return this.secureToken || this.config.getApiKey();
  }

  hasToken() {
    return !!this.getApiKey() && !this.secureToken;
  }

  getAuthenticatedHeaders(){
    if(this.authenticatedHeaders){
      return this.authenticatedHeaders;
    } else {
      const headers = { 
        'Content-Type': 'application/json',
        'Authorization': this.getApiKey(),
        'Access-Control-Max-Age': this.apiPreflightCorsCache.toString(),
      };
      this.authenticatedHeaders = headers;
      return this.authenticatedHeaders;
    }
  }

  getAnonymousHeaders(){
    const headers = { 
      'Content-Type': 'application/json',
      'Authorization': environment.anonymousToken,
      'Access-Control-Max-Age': this.apiPreflightCorsCache.toString(),
    };
    return headers;
  }

  getHeadersFor(token){
    const headers = { 
      'Content-Type': 'application/json',
      'Authorization': token,
      'Access-Control-Max-Age': this.apiPreflightCorsCache.toString(),
    };
    return headers;
  }

  recordAnonymousVisit(){
    return new Promise((resolve, reject) => {

      const reqOpts = {
        headers: this.getAnonymousHeaders()
      }

      const params = JSON.stringify({});

      this.http.post(this.url + '/anonymous_visit.json', params, reqOpts)
        .toPromise()
        .then(
          (data) => { resolve(data); },
          (error) => { reject(ApiService.unableToConnectToTheApiError) });
    });
  }

  getNewApiKey(deviceInfo): Promise<void> {
    return new Promise((resolve, reject) => {

      const reqOpts = {
        headers: this.getAnonymousHeaders()
      };

      const p = this.config.getProfile();
      deviceInfo = Object.assign({
        referred_by: p.referredBy,
        partner_code: p.partnerCode
      }, deviceInfo);

      const params = JSON.stringify(deviceInfo);

      this.http.post(this.url + '/login.json', params, reqOpts)
        .toPromise()
        .then(
          (data: any) => {
            this.resetCache();
            this.config.updateProfile({
              apiKey: data.authentication_token, 
              pwaToken: data.pwa_token,
              partner: data.partner,
              referralCode: data.referral_code,
              referrer: data.referrer,
              id: data.id
            }).then(() => resolve());
          },
          error => { reject(ApiService.unableToConnectToTheApiError) });
    });
  }

  static toSnake(str){
    return str.replace(
      /([A-Z])/g, 
      ($1) =>{ return "_"+$1.toLowerCase() }
    );
  }

  static formatParams(params){
    let cleanParams: any = {};
    for (var p in params) {
      if (params.hasOwnProperty(p)) {//&& params[p]
        cleanParams[ApiService.toSnake(p)] = params[p];
      }
    }
    return cleanParams;
  }

  auth(resource, params) {
    let headers = null;

    if(!!this.getApiKey()) {
      headers = this.getAuthenticatedHeaders();
    } else { 
      headers = this.getAnonymousHeaders();
    }

    return new Promise((resolve, reject) => {
      this.post(resource, params, headers).then( data => {
        this.secureToken = null;
        this.resetCache();
        resolve(data);
      }, reject);
    });
  }

  async onServerDown(originalUri: string, originalStatus: number, originalOpts: any) {
    if((this.config.isOnboardingLevel() && this.config.get('startedCurrentLevel'))) return;

    originalOpts = JSON.stringify(originalOpts);

    this.sendAnalytics(originalUri, originalStatus, originalOpts);
    await this.openErrorPage(originalStatus);
  }

  sendAnalytics(originalUri, originalStatus, originalOpts) {
    this.analyticsService.trackEvent({
      key: this.getAnalyticsKey(originalStatus),
      value: 1,
      uri: originalUri,
      status: originalStatus,
      options: originalOpts
    });
  }

  getAnalyticsKey(statusCode: number) {
    return this.statusCodesInfo.get(statusCode).eventName;
  }

  async openErrorPage(status: number) {
    try {
      if(!!await this.modalCtrl.getTop()) return;

      const modal = await this.modalCtrl.create({ component: UnderMaintenancePage, id: 'maint' });
      await modal.present();

    } catch ( err ) {
      console.log( err );
    }
  }

  async closeErrorPage() {
    try {
      // const modal = await this.modalCtrl.getTop();
      // console.log("MODAL: ", modal);
      if(!!await this.modalCtrl.getTop()) return;

      await this.modalCtrl.dismiss(null, null, 'maintenanceModal');
    } catch ( err ) {
      console.log( err );
    }
  }

  isServerDown() {
    return new Promise((resolve, reject) => {
      this.http.get(this.url + '/status.json', {
        headers: this.getAnonymousHeaders()
      }).toPromise()
        .then( (resp:any) => {
          resolve(false);
        }, err => {
          resolve(true);
        });
    });
  }

  getException(statusCode: number) {
    return this.statusCodesInfo.get(statusCode).exception;
  }

  errorHandler(err, uri, params) {
    if(err.status == 401) {
      return { ...ApiService.unauthorized, ...err };
    } else if ((err.status >= 400 && err.status < 500) || err.status === 0) {
      return err;
    } else if (err.status >= 500) {
      this.stateMachine.exec('apiError', {
        uri: uri,
        status: err.status,
        params: params
      });
      return this.getException(err.status);
    }
  }

  jsonp(url): Observable<any> {
    return this.http.jsonp(url, 'callback');
  }
}

