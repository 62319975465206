import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {MatchesService, MatchStatus} from "../../services/matches.service";
import {IonContent, ModalController, NavParams} from "@ionic/angular";
import { getRandomImage, getUsers } from 'src/app/shared/helpers/helpers';
import { HighlightStylesService } from 'src/app/services/data/highlight-styles.service';
import { logc } from 'src/app/shared/helpers/log';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatchingType, SearchService } from 'src/app/services/data/search.service';
import { takeUntil } from 'rxjs/operators';
import { Config } from 'src/app/services/config/config';
import { ModalService } from 'src/app/services/popups/modal.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UserService } from 'src/app/services/data/user.service';

const INCOMPATIBLE_MATCHES_NUMBER = 10;

@Component({
  selector: 'app-matches-modal',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click) = "dismiss()">
            <ion-icon class = "modal-close-button" name = "close"></ion-icon>
          </ion-button>
        </ion-buttons>
    
        <ion-title class = "p-0 text-center">
          {{ matchesLength }} Unavailable {{ matchesType }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
  
    <ion-content #ionContent>
      <matches-cards [matches] = "restrictedMatches"
                     [matchingType] = "matchingType"
                     [source] = "source" ></matches-cards>
      <div *ngIf = "matchesLength > CARDS_TO_SHOW && matchingType == 'fans'" 
           class = "base-text label-color heavy more-label">
        + {{ matchesLength - CARDS_TO_SHOW }} more
      </div>
      <div *ngIf = "matchingType == 'fans' && (regularMatches | async)?.length" class = "text-center m-[15px]">
        <div class = "mb-[15px]">
          <separator-line></separator-line>
        </div>
        <chip [iconName] = "'flash'"
              [text] = "'Instant'"
              [activatedIconCssClass] = "'text-tertiary-dark-3'"
              [activated] = "true"
              [activatedCssClass] = "'bg-tertiary-light-1 text-tertiary-dark-3'"></chip>
        <div class = "text-title text-huge font-semibold my-[5px]">{{ availableMatchesLength }} Available Matches</div>
        <div class = "text-base text-body notice mb-[20px] mt-[5px]">Skip the wait and match now!</div>
        <ion-button class = "turquoise-gradient-btn btn-large-action ionic-button turquoise-btn-shadow font-heavy"
                    shape = "round" 
                    (click) = "matchWithBestOverall()">
          Match with the Best Overall
        </ion-button>
        <notice>
          This considers ALL users in and around {{ userArea }}, not just fans of {{ influencer }}.
        </notice>
      </div>

      <div #availableMatchesSection 
           *ngIf = "matchingType == 'fans' && (regularMatches | async)?.length && readyForMatches" 
           class = "heading mx-[15px] flex item-center gap-[10px]">
        Best overall
        <ion-icon class = "text-tertiary text-large" name = "stars"></ion-icon>
      </div>
      <matches-cards *ngIf = "(regularMatches | async)?.length && readyForMatches"
                     [source] = "source"
                     [matches] = "(regularMatches | async)?.slice(0, CARDS_TO_SHOW)"></matches-cards>

      <div #incompatibleSection class = "relative">
        <div>
          <div *ngIf = "availableMatchesLength > CARDS_TO_SHOW" class = "base-text label-color heavy more-label">
            + {{ availableMatchesLength - CARDS_TO_SHOW }} more
          </div>
          <separator-line></separator-line>
          <div class = "text-large text-center text-body font-semibold py-4">
            ...and many available, but incompatible users
          </div>
        </div>
        <matches-cards [cssClass] = "'grayscale opacity-25'" 
                       [matches] = "incompatibleMatches" 
                       [interactive] = "false"
                       [source] = "source" ></matches-cards>
        <div class = "absolute shadow-md w-[80%] top-[50%] 
                      -translate-x-[50%] -translate-y-[50%] left-[50%] rounded-[5px]
                      p-[20px]">
          <div class = "absolute inset-0 z-10 rounded-[5px] bg-surface opacity-90"></div>
          <div class = "relative text-base z-20">
            <div class = "font-semibold text-large text-center text-title">We can’t match you with them</div>
            <div class = "py-3 text-body">To avoid wasting anyone’s time, we don’t match people who are unlikely to “click”.</div>
            <div class = "text-body">In your case, it might include people with the following traits:</div>
            <div class = "m-2">
              <div *ngFor = "let trait of oppositeDescriptors?.slice(0,5)"
                 class = "flex gap-[15px] items-center text-base my-[10px]">
                <i class = "ss-icon relative top-[3px]" [style.color] = "trait.color">{{ trait.icon }}</i>
                <div class = "font-semibold text-body" [innerHTML] = "trait.text"></div>
              </div>  
              <div class = "ml-[35px]">...etc</div>
            </div>
          </div>
        </div>
        <div class = "absolute h-[200px] w-full bg-gradient-to-t from-surface to-transparent bottom-0"></div>
      </div>
    </ion-content>
  `,
  styleUrls: ['./matches-modal.page.scss'],
})
export class MatchesModalPage implements OnInit {
  @ViewChild("incompatibleSection") incompatibleSection: ElementRef;
  @ViewChild('ionContent') ionContent: any;
  @ViewChild("availableMatchesSection") availableMatchesSection: ElementRef;
  @Input() matchingType: string = 'default';
  public CARDS_TO_SHOW = 10;
  public matchesLength: number = 0;
  public matches: any = null;
  public oppositeDescriptors: any[] = [];
  public incompatibleMatches: any[] = [];
  public source: string = "failedSearch";
  public availableMatchesLength: number = null;
  public userArea: string = null;
  public influencer: string = null;
  public anitTraits: any[] = [
    { picture: getRandomImage(), text: "Disdain of religion" },
    { picture: getRandomImage(), text: "Vegan" },
    { picture: getRandomImage(), text: "LGBT+ Activist" },
    { picture: getRandomImage(), text: "Disagreeable" },
    { picture: getRandomImage(), text: "Self-centered" }
  ];

  public restrictedMatches: any[] = [];
  public recommendedMatches: any[] = [];

  public regularMatches: BehaviorSubject<any>;
  public matchesType: string = "matches";

  public unsubscribe: Subject<any> = new Subject();
  private incompatibleMatchesSectionViewed: boolean = false;
  private availableMatchesSectionViewed: boolean = false;

  public readyForMatches: boolean = false;

  constructor(private navParams: NavParams,
              private config: Config,
              private searchService: SearchService,
              private analyticsService: AnalyticsService,
              private matchesService: MatchesService,
              private userService: UserService,
              private modalCtrl: ModalController,
              private highlightStylesService: HighlightStylesService) { 
    (window as any).matchesModal = this;
  }

  async ngOnInit() {
    this.availableMatchesLength = this.navParams.get("matchesLength");
    this.userArea = this.config.getUserCity();
    this.influencer = this.config.getPartnerName();
    this.readyForMatches = this.userService.readyForMatches();

    if(this.matchingType == MatchingType.Fans) {
      this.analyticsService.trackEvent({ key: "unavailable_fans_viewed", value: 1 });
      this.matchesType = "fans";
      this.regularMatches = this.matchesService.matchesFetched;
      this.matchesService
        .matchesFetched
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((matches) => {
          this.availableMatchesLength = matches.length;
          if(this.availableMatchesLength > 0) {
            setTimeout(() => this.observerAvailableMatchesSection(), 200);
          }
        });
      this.matchesService.init();
      this.matchesService.fetchUsers({ force: true, backgroundMode: true });

    }
    
    this.matches = this.navParams.get('matches');
    this.restrictedMatches = this.matches.filter(match => match.pool_status === MatchStatus.Regular);
    this.recommendedMatches = this.matches.filter(match => match.pool_status === MatchStatus.Recommended);
    this.oppositeDescriptors = this.navParams.get("oppositeDescriptors");
    // logc.info("this.oppositeDescriptors: ", this.oppositeDescriptors);
    this.highlightStylesService.mapOppositeDescriptors(this.oppositeDescriptors);
    this.incompatibleMatches = getUsers(INCOMPATIBLE_MATCHES_NUMBER);
  }

  ngAfterViewInit() {
    const options = { root: this.ionContent.el };
    const onIncompatibleSectionViewed = function(entries, observer) {
      const { isIntersecting } = entries[0];
      if(isIntersecting) {
        if(this.incompatibleMatchesSectionViewed) return;
        this.incompatibleMatchesSectionViewed = true;
        this.analyticsService.trackEvent({
          key: "incompatible_users_viewed",
          value: 1
        })
      }
    };

    const observer = new IntersectionObserver(onIncompatibleSectionViewed.bind(this), options);
    const target = this.incompatibleSection.nativeElement;
    observer.observe(target);
  }

  observerAvailableMatchesSection() {
    const options = { root: this.ionContent.el };
    const onAvailableMatchesSectionViewed = function(entries, observer) {
      const { isIntersecting } = entries[0];
      if(isIntersecting) {
        if(this.availableMatchesSectionViewed) return;
        this.availableMatchesSectionViewed = true;
        this.analyticsService.trackEvent({
          key: "available_matches_viewed",
          value: 1
        })
      }
    };

    const observer = new IntersectionObserver(onAvailableMatchesSectionViewed.bind(this), options);
    console.info("this.availableMatchesSection: ", this.availableMatchesSection);
    const target = this.availableMatchesSection.nativeElement;
    observer.observe(target);
  }

  async matchWithBestOverall() {
    await this.modalCtrl.dismiss();
    await this.modalCtrl.dismiss();
    this.analyticsService.trackEvent({
      key: "failed_fan_match_try_best_overall",
      value: 1
    });
    this.searchService.openFansSearchPicker();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
