import { Injectable } from '@angular/core';
import {logc} from "../shared/helpers/log";
import {AudiencesService} from "./analytics/audiences.service";
import {Config} from "./config/config";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs";

enum Wording {
  Default = "default",
  Scheduled = "scheduled"
}

@Injectable({
  providedIn: 'root'
})
export class WordingService {
  private lib: any = {
    scheduled: {
      matchesPage: { header: { title: "Individual matches" } },
      tribesPage: { queue: { title: "UPCOMING" } }
    },
    default: {
      matchesPage: { header: { title: "Matches" } },
      tribesPage: { queue: { title: "QUEUE" } }
    }
  }

  private wording: any = this.lib[ Wording.Default ];

  private wordingReadySource: BehaviorSubject<any> = new BehaviorSubject(this.wording);
  public onWordingReady: Observable<any>;

  constructor(private audiencesService: AudiencesService,
              private config: Config) {
    this.onWordingReady = this.wordingReadySource.asObservable();
    this.init();
  }

  async init() {
    await this.config.load();
    const audience = this.audiencesService.getAudience();
    this.wording = this.lib[audience];

    this.wordingReadySource.next(this.wording);
  }
}
