import { Component, Input, OnInit } from "@angular/core";
import { PremiumFeature } from "../upselling.page";

@Component({
  selector: "premium-features-table",
  template: `
    <div class = "overflow-hidden" 
         (click) = "expanded = true"
         [style.height] = "!expanded ? '245px' : 'max-content'">
      <table class = "table-auto w-full">
        <thead>
          <tr>
            <th class = "text-left">Features</th>
            <th class = "text-center">Free</th>
            <th class = "text-center" [style.color] = "'var(--tertiary)'">Plus</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor = "let feature of featuresList" 
              class = "text-body"
              [class.highlight] = "feature.highlightReason === upsellingOpeningReason">
            <td class = "text-left text-base pl-[5px]">{{ feature.name }}</td>
            <td class = "text-center text-label font-semibold">
              {{ feature.details.freePlan.value }}
            </td>
            <td class = "text-center font-semibold text-title pr-[5px]">
              <span *ngIf = "feature.details.premiumPlan.value">
                {{ feature.details.premiumPlan.value }}
              </span>
              <span *ngIf = "feature.details.premiumPlan.unlimited">
                <ion-icon name = "checkmark-circle" class = "text-success text-xl"></ion-icon>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf = "!expanded"
         class = "w-full text-center p-[5px]"
         (click) = "expanded = true">
      <ion-icon name = "chevron-down-outline" class = "text-xl text-label"></ion-icon>
    </div>
  `,
  styles: [
    ` tr {
        height: 40px;
        padding: 5px 0;
        font-size: var(--medium-text);
      }
      th {
        font-family: var(--title-font-heavy);
        color: var(--label);
        font-size: var(--medium-text);
        text-transform: uppercase;
      }
      .highlight {
        animation: 1.5s ease-in 1s normal forwards highlighting;
      }
      @keyframes highlighting {
        0% { background: var(--surface) }
        20% { background: var(--tertiary-light-3); color: black; }
        35% { background: var(--tertiary-light-3); color: black; }
        100% { background: var(--surface) }
      }
    `
  ]
})
export class PremiumFeaturesTableComponent implements OnInit {
  @Input("featuresList") featuresList: PremiumFeature[] = [];
  @Input("upsellingOpeningReason") upsellingOpeningReason: string = null;
  @Input("expand") expand: boolean = false;
  public expanded: boolean = false;

  ngOnInit() {
    this.expanded = this.expand;
  }
}
