import { APP_INITIALIZER, NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from "@angular/common/http";
import { IonicGestureConfig } from "./services/native/ionic-gesture-config.service";
import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { MainErrorHandlerService } from "./services/performance/main-error-handler.service";
import { Calendar } from "@awesome-cordova-plugins/calendar/ngx";
import player from "lottie-web";
import { LottieModule } from "ngx-lottie";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Network } from "@ionic-native/network/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { AppRate } from "@ionic-native/app-rate/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { File } from "@ionic-native/file/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { BranchIo } from "@ionic-native/branch-io/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { Contacts } from "@ionic-native/contacts/ngx";

import * as Sentry from "@sentry/angular";

//entry component
import { We3App } from "./app.component";
import { CoreModule } from "./core/core.module";

import { AppRoutingModule } from "./app-routing.module";

//Components pre-loaded, basically what is used by services and is not a full page.

import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AgmCoreModule } from "@agm/core";
import { Device } from "@ionic-native/device/ngx";
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { StarsFeedbackComponent } from "./components/stars-feedback/stars-feedback.component";
import { StarsFeedbackComponentModule } from "./components/stars-feedback/stars-feedback.module";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { SideMenuModule } from "./components/side-menu/side-menu.module";
import { GenderPipe } from "./pipes/gender.pipe";
import { WebIntent } from "@ionic-native/web-intent/ngx";
import { TapticEngine } from "@ionic-native/taptic-engine/ngx";
import { Vibration } from "@ionic-native/vibration/ngx";
import { LoggerInterceptor } from "./shared/interceptors/logger.interceptor";
import { ThemeDetection } from "@ionic-native/theme-detection/ngx";
import { HeartfeltComponent } from "./components/heartfelt/heartfelt.component";
import { DirectivesModule } from "./directives/directives.module";
import { ChatPageModule } from "./pages/tribes/chat.module";
import { TribesPageModule } from "./pages/tribes/tribes.module";
/*import { Facebook } from "@ionic-native/facebook/ngx";*/
import { SearchStartOptionsComponent } from "./components/search-start-options/search-start-options.component";
import { SearchStartOptionsModule } from "./components/search-start-options/search-start-options.module";
import { ChipsSelectorModule } from "./components/chips-selector/chips-selector.module";
import { ChipsSelectorComponent } from "./components/chips-selector/chips-selector.component";
import { RadioSelectorComponent } from "./components/radio-selector/radio-selector.component";
import { RadioSelectorModule } from "./components/radio-selector/radio-selector.module";
import { TribeAvatarComponent } from "./components/tribe-avatar/tribe-avatar.component";
import { TribeAvatarModule } from "./components/tribe-avatar/tribe-avatar.module";
import { TopicTribeAvatarComponent } from "./components/topic-tribe-avatar/topic-tribe-avatar.component";
import { TopicTribeAvatarModule } from "./components/topic-tribe-avatar/topic-tribe-avatar.module";
import { ScheduledSearchComponent } from "./components/scheduled-search/scheduled-search.component";
import { ScheduledSearchModule } from "./components/scheduled-search/scheduled-search.module";
import { DateLabelComponent } from "./components/date-label/date-label.component";
import { DateLabelModule } from "./components/date-label/date-label.module";
import { SearchingTribeAvatarComponent } from "./components/searching-tribe-avatar/searching-tribe-avatar.component";
import { SearchingTribeAvatarModule } from "./components/searching-tribe-avatar/searching-tribe-avatar.module";
import { TribeComponent } from "./components/tribe/tribe.component";
import { TribeModule } from "./components/tribe/tribe.module";
import { MatchesPlateComponent } from "./components/matches-plate/matches-plate.component";
import { MatchesPlateModule } from "./components/matches-plate/matches-plate.module";
import { TribesGuideComponent } from "./components/tribes-guide/tribes-guide.component";
import { TribesGuideModule } from "./components/tribes-guide/tribe-guide.module";
import { ChatItemComponent } from "./components/chat-item/chat-item.component";
import { ChatItemModule } from "./components/chat-item/chat-item.module";
import { RadioListComponent } from "./components/feedback-forms/radio-list/radio-list.component";
import { RadioListModule } from "./components/feedback-forms/radio-list/radio-list.module";
import { CheckboxListModule } from "./components/feedback-forms/checkbox-list/checkbox-list.module";
import { CheckboxListComponent } from "./components/feedback-forms/checkbox-list/checkbox-list.component";
import { FeedbackFormComponent } from "./components/feedback-form/feedback-form.component";
import { FeedbackFormModule } from "./components/feedback-form/feedback-form.module";
import { ChatMessageComponent } from "./components/chat/chat-message/chat-message.component";
import { ChatMessageModule } from "./components/chat/chat-message/chat-message.module";
import { InteractiveBottomSheetComponent } from "./components/interactive-bottom-sheet/interactive-bottom-sheet.component";
import { InteractiveBottomSheetModule } from "./components/interactive-bottom-sheet/interactive-bottom-sheet.module";
import { TribeScoreComponent } from "./components/tribe-score/tribe-score.component";
import { TribeScoreModule } from "./components/tribe-score/tribe-score.module";
import { HorizontalSliderComponent } from "./components/horizontal-slider/horizontal-slider.component";
import { HorizontalSliderModule } from "./components/horizontal-slider/horizontal-slider.module";
import { HorizontalSliderCardComponent } from "./components/horizontal-slider-card/horizontal-slider-card.component";
import { HorizontalSliderCardModule } from "./components/horizontal-slider-card/horizontal-slider-card.module";
import { BulletListComponent } from "./components/bullet-list/bullet-list.component";
import { BulletListModule } from "./components/bullet-list/bullet-list.module";
import { HighlightsListComponent } from "./components/highlights-list/highlights-list.component";
import { HighlightsListModule } from "./components/highlights-list/highlights-list.module";
import { TitledCardComponent } from "./components/titled-card/titled-card.component";
import { TitledCardModule } from "./components/titled-card/titled-card.module";
import { MeetupCalendarComponent } from "./components/meetup-calendar/meetup-calendar.component";
import { MeetupCalendarModule } from "./components/meetup-calendar/meetup-calendar.module";
import { DatePickerWheelComponent } from "./components/date-picker-wheel/date-picker-wheel.component";
import { DatePickerWheelModule } from "./components/date-picker-wheel/date-picker-wheel.module";
import { MeetupStatusSwitcherComponent } from "./components/meetup-status-switcher/meetup-status-switcher.component";
import { MeetupStatusSwitcherModule } from "./components/meetup-status-switcher/meetup-calendar.module";
import { SeparatorLineComponent } from "./components/separator-line/separator-line.component";
import { SeparatorLineComponentModule } from "./components/separator-line/separator-line.module";
import { TipComponent } from "./components/tip/tip.component";
import { TipComponentModule } from "./components/tip/tip.module";
import { MenuPopoverComponent } from "./components/menu-popover/menu-popover.component";
import { MenuPopoverModule } from "./components/menu-popover/menu-popover.module";
import { ActionButtonComponent } from "./components/action-button/action-button.component";
import { ActionButtonModule } from "./components/action-button/action-button.module";
import { ImageRowComponent } from "./components/image-row/image-row.component";
import { ImageRowModule } from "./components/image-row/image-row.module";
import { MatchingTypePickerComponent } from "./components/matching-type-picker/matching-type-picker.component";
import { MatchingTypePickerModule } from "./components/matching-type-picker/matching-type-picker.module";
import { FanMatchingSearchDetailsComponent } from "./components/fan-matching-search-details/fan-matching-search-details.component";
import { FanMatchingSearchDetailsModule } from "./components/fan-matching-search-details/fan-matching-search-details.module";
import { PotentialMatchesShortComponent } from "./components/potential-matches-short/potential-matches-short.component";
import { PotentialMatchesShortModule } from "./components/potential-matches-short/potential-matches-short.module";
import { FanFailedSearchComponent } from "./components/fan-failed-search/fan-failed-search.component";
import { FanFailedSearchModule } from "./components/fan-failed-search/fan-failed-search.module";
import { NoticeComponent } from "./components/notice/notice.component";
import { NoticeModule } from "./components/notice/notice.component.module";
import { ButtonWithSuccessStateComponent } from "./components/button-with-success-state/button-with-success-state.component";
import { ButtonWithSuccessStateModule } from "./components/button-with-success-state/button-with-success-state.module";
import { ChipComponent } from "./components/chip/chip.component";
import { ChipModule } from "./components/chip/chip.module";
import { IconPlateComponent } from "./components/icon-plate/icon-plate.component";
import { IconPlateModule } from "./components/icon-plate/icon-plate.module";
import { InteractiveListComponent } from "./components/interactive-list/interactive-list.component";
import { InteractiveListModule } from "./components/interactive-list/interactive-list.module";
import { SendFeedbackComponent } from "./components/send-feedback/send-feedback";
import { SendFeedbackComponentModule } from "./components/send-feedback/send-feedback.module";
import { TribeInfoComponent } from "./pages/tribes/components/tribe-info/tribe-info.component";
import { TribeInfoModule } from "./pages/tribes/components/tribe-info/tribe-info.module";
import { ChatAvatarComponent } from "./pages/tribes/components/tribe-info/chat-avatar/chat-avatar.component";
import { ChatAvatarModule } from "./pages/tribes/components/tribe-info/chat-avatar/chat-avatar.module";
import { ChatTipComponent } from "./pages/tribes/components/tribe-info/chat-tip/chat-tip.component";
import { ChatTipModule } from "./pages/tribes/components/tribe-info/chat-tip/chat-tip.module";
import { ChatNoticeComponent } from "./pages/tribes/messages/chat-notice/chat-notice.component";
import { ChatNoticeModule } from "./pages/tribes/messages/chat-notice/chat-notice.module";
import { UpsellingPage } from "./pages/upselling/upselling.page";
import { UpsellingPageModule } from "./pages/upselling/upselling.module";
import { TimerComponent } from "./pages/upselling/timer/timer.component";
import { TimerComponentModule } from "./pages/upselling/timer/timer.module";
import { PremiumFeaturesTableComponent } from "./pages/upselling/premium-features-table/premium-features-table.component";
import { PremiumFeaturesTableComponentModule } from "./pages/upselling/premium-features-table/premium-features-table.module";
import { BillingPlanComponent } from "./pages/upselling/billing-plans/billing-plan.component";
import { BillingPlanComponentModule } from "./pages/upselling/billing-plans/billing-plan.module";
import { ExtendExpirySectionComponent } from "./components/extend-expiry-section/extend-expiry-section.component";
import { ExtendExpirySectionComponentModule } from "./components/extend-expiry-section/extend-expiry-section.module";
import { IpService } from "./services/ip.service";
import { Observable } from "rxjs";

export function playerFactory() {
  return player;
}

function appInitializer(
  sentryService: any,
  ipService: IpService
): () => Observable<any> {
  return () => ipService.init();
}

@NgModule({
  declarations: [We3App, HeartfeltComponent],
  entryComponents: [
    StarsFeedbackComponent,
    SideMenuComponent,
    HeartfeltComponent,
    SearchStartOptionsComponent,
    ChipsSelectorComponent,
    RadioSelectorComponent,
    TribeAvatarComponent,
    TopicTribeAvatarComponent,
    SearchingTribeAvatarComponent,
    ScheduledSearchComponent,
    DateLabelComponent,
    TribeComponent,
    MatchesPlateComponent,
    TribesGuideComponent,
    ChatItemComponent,
    RadioListComponent,
    CheckboxListComponent,
    FeedbackFormComponent,
    ChatMessageComponent,
    InteractiveBottomSheetComponent,
    HorizontalSliderComponent,
    HorizontalSliderCardComponent,
    BulletListComponent,
    TribeScoreComponent,
    HighlightsListComponent,
    TitledCardComponent,
    MeetupCalendarComponent,
    DatePickerWheelComponent,
    MeetupStatusSwitcherComponent,
    SeparatorLineComponent,
    TipComponent,
    ActionButtonComponent,
    ImageRowComponent,
    MatchingTypePickerComponent,
    FanMatchingSearchDetailsComponent,
    PotentialMatchesShortComponent,
    FanFailedSearchComponent,
    NoticeComponent,
    ButtonWithSuccessStateComponent,
    ChipComponent,
    IconPlateComponent,
    InteractiveListComponent,
    SendFeedbackComponent,
    TribeInfoComponent,
    ChatAvatarComponent,
    ChatTipComponent,
    ChatNoticeComponent,
    UpsellingPage,
    TimerComponent,
    PremiumFeaturesTableComponent,
    BillingPlanComponent,
    ExtendExpirySectionComponent,
  ],
  imports: [
    ExtendExpirySectionComponentModule,
    PremiumFeaturesTableComponentModule,
    BillingPlanComponentModule,
    UpsellingPageModule,
    TimerComponentModule,
    ChatAvatarModule,
    ChatTipModule,
    ChatNoticeModule,
    SendFeedbackComponentModule,
    TribeInfoModule,
    IconPlateModule,
    InteractiveListModule,
    NoticeModule,
    ButtonWithSuccessStateModule,
    ChipModule,
    PotentialMatchesShortModule,
    FanFailedSearchModule,
    MatchingTypePickerModule,
    FanMatchingSearchDetailsModule,
    TipComponentModule,
    ActionButtonModule,
    ImageRowModule,
    MeetupStatusSwitcherModule,
    SeparatorLineComponentModule,
    TitledCardModule,
    MeetupCalendarModule,
    DatePickerWheelModule,
    BulletListModule,
    TribeScoreModule,
    HighlightsListModule,
    HorizontalSliderModule,
    HorizontalSliderCardModule,
    ChatMessageModule,
    InteractiveBottomSheetModule,
    FeedbackFormModule,
    RadioListModule,
    CheckboxListModule,
    ChatItemModule,
    TribesGuideModule,
    MatchesPlateModule,
    TribeModule,
    DateLabelModule,
    ScheduledSearchModule,
    SearchingTribeAvatarModule,
    TopicTribeAvatarModule,
    TribeAvatarModule,
    RadioSelectorModule,
    ChipsSelectorModule,
    SearchStartOptionsModule,
    SideMenuModule,
    BrowserModule,
    StarsFeedbackComponentModule,
    BrowserAnimationsModule,
    HammerModule,
    CoreModule.forRoot(),
    IonicModule.forRoot({ swipeBackEnabled: false }),
    IonicStorageModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ["places"],
      language: "en",
    }),
    DirectivesModule,
    ChatPageModule,
    TribesPageModule,
  ],
  providers: [
    ThemeDetection,
    StatusBar,
    SplashScreen,
    Network,
    Geolocation,
    SocialSharing,
    GenderPipe,
    AppRate,
    Diagnostic,
    File,
    Camera,
    BranchIo,
    Keyboard,
    FirebaseX,
    WebIntent,
    Device,
    OpenNativeSettings,
    OneSignal,
    Clipboard,
    TapticEngine,
    Vibration,
    Contacts,
    /*Facebook,*/
    Calendar,
    //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: IonicGestureConfig,
    },
    { provide: ErrorHandler, useClass: MainErrorHandlerService },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [Sentry.TraceService, IpService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggerInterceptor,
      multi: true,
    },
  ],
  bootstrap: [We3App],
})
export class AppModule {}
