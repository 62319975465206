import { Injectable } from "@angular/core";
import { Platform } from '@ionic/angular';
import { Device } from "@ionic-native/device/ngx";

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  public deviceInfo;

  constructor(public device: Device,
              public platform: Platform) {
    if(this.platform.is('cordova')) { 
      this.deviceInfo = this.device;
    }
  }

  deviceManufacturerIs(manufacturer): boolean {
    if(this.platform.is('cordova')) { 
      return this.device.manufacturer.toLowerCase() === manufacturer.toLowerCase();
    } else {
      return false;
    }
  }

  getDeviceInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.deviceInfo);
    });
  }
}
