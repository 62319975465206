import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { IconPlateModule } from "src/app/components/icon-plate/icon-plate.module";
import { PipesModule } from "src/app/pipes/pipes.module";
import { ChatAvatarModule } from "./chat-avatar/chat-avatar.module";
import { ChatTipModule } from "./chat-tip/chat-tip.module";
import { TribeInfoComponent } from "./tribe-info.component";

@NgModule({
  declarations: [TribeInfoComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    ChatAvatarModule,
    IconPlateModule,
    ChatTipModule
  ],
  exports: [TribeInfoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TribeInfoModule {};