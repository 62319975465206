import {Injectable} from "@angular/core";
import {Config} from "../config/config";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs";

enum Audience {
  Default = "default",
  Scheduled = "scheduled",
  Partner = "partner"
}

@Injectable({
  providedIn: "root"
})
export class AudiencesService {

  private audienceSetSource: BehaviorSubject<Audience> = new BehaviorSubject<Audience>(Audience.Default);
  public onAudienceSet: Observable<Audience>;

  constructor(private config: Config) {
    if(this.config.isDev) {
      (window as any).audiencesService = this;
    }
    this.onAudienceSet = this.audienceSetSource.asObservable();
  }

  getAudience(): string {
    switch(true) {
      case this.inScheduledGroup():
        return Audience.Scheduled;
      case this.isPartner():
        return Audience.Partner;
      default:
        return Audience.Default;
    }
  }

  inScheduledGroup(): boolean {
    return false;
  }

  isPartner(): boolean {
    return this.config.partner;
  }
}
