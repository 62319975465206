/**
 * @property { number } Scheduled User marked for reset campaign but didn't reset levels
 * @property { number } Seen User saw reset-levels-request page
 * @property { number } Dismissed User dismissed reset-levels-request page
 * @property { number } Reset User reset levels from reset-levels-request page
 * @property { number } Completed User marked for reset campaign completed level 3
 * @default
 */
export enum ResetLevelsCampaign {
  Scheduled = "scheduled",
  Seen = "seen",
  Dismissed = "dismissed",
  Reset = "reset",
  Completed = "completed"
}
