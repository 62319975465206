import { Injectable, NgZone } from '@angular/core';
import { Saveable } from '../../shared/structure/saveable';
import { Storage } from '@ionic/storage';
import { PictureService } from '../picture.service';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root',
})
export class CachedPicturesService extends Saveable {
  defaultConfig: any = [];
  key: string = 'cachedPictures';
  public needsSave:boolean = false;

  constructor(
              public storage: Storage,
              public _ngZone: NgZone,
              public utils:UtilsService,
              public pictureService: PictureService,
             ) {
    super(storage);
    (window as any).cachePictureService = this;
  }

  async setTribesImages(tribes) {
    this.data = await this.cacheTribesImages(tribes);
    this.save();
  }
  
  cacheTribesImages(tribes) {
    let tribestoCache = [];
    return new Promise( async (resolve) => {
      for (let i = 0; i < tribes.length; i++) {
        tribestoCache[i] = { id : tribes[i].id , users: [] };
        for (let j = 0; j < tribes[i].users.length; j++) {
          let cachedPicture = await this.prepareCacheImage(tribes[i].users[j].picture);
          tribestoCache[i].users[j] = {
            id: tribes[i].id,
            picture: tribes[i].users[j].picture,
            cachedPicture: cachedPicture
          };
        }
      }
      resolve(tribestoCache);
    });
  }
    
  async prepareTribeCache(tribes) {
    await this.load().then( (data) => {
      if(!data) {
        this.setTribesImages(tribes);
      } else {
        this.updateData(tribes);
      }
    });
  }
    
  async cacheUserImage(tribeId,UserId,pictureUrl): Promise<void>{
    return new Promise( async (resolve)=>{
      let currentUser: any = this.getUserTribe(tribeId,UserId) || {};
      currentUser.cachedPicture = await this.prepareCacheImage(pictureUrl);
      resolve();
    });
  }

  async verifyCacheTribe(tribeData: any){
    if(!this.data || tribeData.id === -1) return;
    if(!this.getTribe(tribeData.id)) await this.storageTribe(tribeData);
    for (let i = 0; i < tribeData.users.length; i++) {
      const user = tribeData.users[i];
      if(user.picture != this.getUserTribe(tribeData.id,user.id)?.picture){
        await this.cacheUserImage(tribeData.id,user.id,user.picture);
        this.needsSave = true;
      }
      if (i == tribeData.length - 1 && this.needsSave) {
        this.save();
        this.needsSave = false;
      }
    }
  }

  async updateData(newTribesData){
    if(!this.data) return;
    // Cleans tribes Cache
    this.cleanCache(newTribesData);
    for (let i = 0; i < newTribesData.length; i++) {
      let tribe = newTribesData[i];
      if(!this.getTribe(tribe.id)) {
        await this.storageTribe(tribe);
      }
      for (let j = 0; j < newTribesData[i].users.length; j++) {
        let user = newTribesData[i].users[j];
        if(!this.getUserTribe(tribe.id,user.id)) {
          await this.storageUser(tribe.id,user);
        }
        else if(user.picture != this.getUserTribe(tribe.id,user.id).picture){
          await this.cacheUserImage(tribe.id,user.id,user.picture);
        }
        // Cleans Users Cache
        this.cleanCache(tribe.users,tribe.id);
        if (i == newTribesData.length - 1 && j == tribe.users.length - 1 && this.needsSave) {
          this.save();
          this.needsSave = false; 
        }
      }
    }
  }

  async cleanCache(newData,tribeId = null){
    let dataClone;
    if(tribeId){
      dataClone = this.utils.deepClone(this.getTribe(tribeId).users);
    }else{
      dataClone = this.utils.deepClone(this.data);
    }
    for (let i = 0; i < dataClone.length; i++) {
      const data = dataClone[i];
      if(!newData.find( d => d?.id == data?.id )){
        if(tribeId){
          await this.removeData(tribeId, data?.id);
        }else{
          await this.removeData(data?.id);
        }
        this.needsSave = true;
      }
    }
  }

  prepareCacheImage(image){
    return new Promise( async (resolve) => {
      let base64 = await this.pictureService.toDataUrl(image);
      let cleanImage = await this.pictureService.sanitizeImage(base64);
      resolve(cleanImage);
    });
  }

  storageUser(tribeId,user): Promise<void> {
    return new Promise ( async (resolve)=>{
      let tribe = this.getTribe(tribeId);
      tribe.users[tribe.users.length] = {
        id: user.id,
        picture: user.picture,
        cachedPicture: await this.prepareCacheImage(user.picture)
      } 
      resolve();
    });
  };

  storageTribe(tribe): Promise<void> {
    this.needsSave = true;
    return new Promise (async (resolve)=>{
      await this.prepareUsers(tribe.users).then((usersData) => {
        this.data[this.data.length] = {
          id: tribe.id,
          users: usersData
        }
    
        resolve();
      });
    });
  };

  prepareUsers(users){
    let response = [];
    return new Promise ( async (resolve) => {
      for (let i = 0; i < users.length; i++) {
        response[i] = {
          ...response[i],
          id:users[i].id,
          picture:users[i].picture,
          cachedPicture: await this.prepareCacheImage(users[i].picture) 
        }
      }
      resolve(response);
    });
  }

  getTribe(tribeId) {
    return this.getTribes().find( t => t.id == tribeId);
  }

  getUserTribe(tribeId,userId) {
    return this.getTribes().find( t => t?.id == tribeId)?.users.find( u => u?.id == userId);
  }

  getTribes() {
    return this.data || [];
  }

  removeData(tribeId,userId = null): Promise<void> {
    return new Promise( (resolve) => {
      let tribePosition = this.data.findIndex(t => t.id == tribeId);
      if(userId){
        let userPosition = this.data[tribePosition].users.findIndex(u => u.id == userId);
        this.data[tribePosition].users.splice(userPosition, 1);
      }else{
        this.data.splice(tribePosition, 1);
      }
      resolve();
    });
  }

  load() {
    return new Promise((resolve, reject) => {
      super.load().then( _ => {
        let t = this.getTribes();
        resolve(t);
      });
    });
  }

}
