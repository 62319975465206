import { Component } from '@angular/core';
import { NavController,
         LoadingController,
         PopoverController} from '@ionic/angular';
import { FormBuilder, Validators } from "@angular/forms";

import { emailValidator } from '../../validators/email.validator';
import { Config } from '../../services/config/config';
import { UtilsService } from '../../services/utils.service';
import { UserService } from '../../services/data/user.service';
import { SessionService } from '../../services/data/session.service';
import { AgeValidator } from "../../validators/age";
import { AGE_DIVIDER } from '../../shared/constants/constants';

@Component({
  selector: 'profile-info-popup',
  templateUrl: 'profile-info-popup.html',
  styleUrls: ['profile-info-popup.scss']
})
export class ProfileInfoPopupComponent{
  public profileForm : any;
  public name : String;
	public email : String;
  public gender : String;
  public birthdayDate;
  public isUnconfirmed;
  public matchPreferences;
  private isPlus: boolean;
  nameFControl: any;

  constructor(public popoverCtrl: PopoverController,
              public config: Config,
              public fb: FormBuilder,
              public utils: UtilsService,
              public userService: UserService,
              public loadingCtrl: LoadingController,
              public nav: NavController,
              public session: SessionService) {
  }

  ngOnInit() {
    this.setData();
    this.createForm();
    this.isPlus = this.config.isPlus();
    this.matchPreferences = this.config.get('matchPreferences');
  }

  setData() {
    let p = this.config.getProfile();
    this.isUnconfirmed = this.config.isUnconfirmed();
    this.name = p.firstName;
    this.email = p.email;
    this.gender = p.gender;
    this.birthdayDate = p.birthday;
  }

  createForm() {
    this.profileForm = this.fb.group({
      name: [this.name, Validators.required],
      email: [this.email, emailValidator],
      gender: [this.gender, Validators.required],
      birthdayDate: [this.birthdayDate, Validators.compose([Validators.required, AgeValidator.isPermitted])],
    });
    this.nameFControl = this.profileForm.get('name');
  }

  Cancel(){
    this.popoverCtrl.dismiss();
  }

  async submit(){
    let name = this.profileForm.get('name').value;
    let gender = this.profileForm.get('gender').value;
    let birthday = this.profileForm.get('birthdayDate').value;

    const loading = await this.loadingCtrl.create({
      message: "Updating...",
    });
    await loading.present();

    let p = this.config.getProfile();
    let oldGender = p.gender;

    if(!this.config.getProfile().gender) {
      this.userService.updateProfile({
        matchPreferences: { gender: null }
      });
    }

    this.userService.updateProfile({
      firstName: name,
      gender: gender,
      birthday: birthday
    }).then(
      resp => {        
        loading.dismiss();
        if(oldGender != gender) {
          this.userService.sendFeedback("User " + this.config.getProfile().email, "Changed Gender");
          this.utils.showGenericMessage("Profile updated. We will review your changes shortly. Thanks for keeping our community real 🙌", 5000);
        }

        this.popoverCtrl.dismiss();

        let userAge = this.utils.getUserAge(birthday);

        let ageDiff = Math.round(userAge / AGE_DIVIDER);
        let ageRange = {
          lower: userAge - ageDiff,
          upper: userAge + ageDiff
        };
        if(this.matchPreferences.gender !== null && !this.isPlus) {
          this.matchPreferences.gender = gender;
        }
        this.matchPreferences.age_range = ageRange;
        this.userService.updateProfile({matchPreferences: this.matchPreferences})

      },
      err => {
        loading.dismiss();
        this.utils.errorContext = 'profile-info-popup, error: ' + JSON.stringify(err);
        this.utils.showGenericError({
          key: "updating_profile_info"
        });
      }
    );
  }
}
