import {isNull} from "util";

export {}

declare global {
  interface Array<T> {
    hasUser(user: any): boolean;
    hasTribe(tribe: any): boolean;
    uniqPush(element: any): Array<T>;
    spliceLast(): any;
    last(): any;
    findLastElem(fn: Function): { id: number, value: any };
    populate(value: T, length: number): Array<T>;
    removeBy(key: string, value: any): Array<any>;
    remove(value: T): Array<T>;
  }
}

if(!Array.prototype.remove) {
  Array.prototype.remove = function(value) {
    const index = this.findIndex(e => e == value);
    if(index > -1) {
      this.splice(index, 1);
    }
    return this;
  }
}

if(!Array.prototype.hasUser) {
  Array.prototype.hasUser = function(arg: any): boolean {
    if(isNaN(+arg) || arg === undefined || arg === null) return false;

    if(typeof parseInt(arg) === 'number') {
      return this.some(user => user.id === parseInt(arg))
    }

    if(typeof arg === 'object') {
      return this.some(user => user.id === arg.id)
    }
  }
}

if(!Array.prototype.hasTribe) {
  Array.prototype.hasTribe = function(arg: any): boolean {
    if(typeof arg === 'number') {
      return this.some(tribe => tribe.id === arg)
    }
    if(typeof arg === 'object') {
      return this.some(tribe => tribe.id === arg.id)
    }
  }
}

if(!Array.prototype.uniqPush) {
  // checking by id, TODO update to "by" version
  Array.prototype.uniqPush = function<T>(element): Array<T> {
    if(['string', 'number'].includes(typeof element)) {
      if(this.indexOf(element) === -1) {
        this.push( element );
      }
    }
    if(typeof element === 'object') {
      if(!this.some(e => e.id === element.id)) {
        this.push(element);
      }
    }
    return this;
  }
}

if(!Array.prototype.last) {
  Array.prototype.last = function () {
    if(!this.length) return '';
    return this[this.length - 1] || undefined;
  }
}

if(!Array.prototype.spliceLast) {
  Array.prototype.spliceLast = function() {
    const length = this.length
    return this.splice(length - 1, length).last();
  }
}

if(!Array.prototype.findLastElem) {
  Array.prototype.findLastElem = function (fn) {
    for (let i = this.length - 1; i >= 0; i--) {
      if (fn(this[i])) {
        return {
          id: i,
          value: this[i]
        }
      }
    }
    return {
      id: -1,
      value: undefined
    }
  }
}

if(!Array.prototype.populate) {
  Array.prototype.populate =  
    <T extends any>(value: T, length: number) => 
      Array.from({ length }, () => value);
}

if(!Array.prototype.removeBy) {
  Array.prototype.removeBy = function(key: string, obj: any) {
    const index = this.findIndex((el) => el[key] == obj[key]);
    if(index > -1) {
      this.splice(index, 1);
    }
    return this;
  }
}