import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule  } from '@angular/forms';
import {FeedbackFormComponent} from "./feedback-form.component";
import {RadioListComponent} from "../feedback-forms/radio-list/radio-list.component";
import {CheckboxListComponent} from "../feedback-forms/checkbox-list/checkbox-list.component";
import {RadioListModule} from "../feedback-forms/radio-list/radio-list.module";
import {CheckboxListModule} from "../feedback-forms/checkbox-list/checkbox-list.module";

@NgModule({
  entryComponents: [
    RadioListComponent,
    CheckboxListComponent
  ],
  declarations: [
    FeedbackFormComponent
  ],
  imports: [
    RadioListModule,
    CheckboxListModule,
    CommonModule,
    IonicModule,
    FormsModule,
  ],
  exports: [
    FeedbackFormComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class FeedbackFormModule {}
