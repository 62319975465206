import { NgModule } from "@angular/core";
import { PreloadAllModules, Routes, RouterModule } from "@angular/router";
import { TribeSmsLinkResolver } from "./resolvers/tribe-sms-link.resolver";
import { InstagramAuthResolver } from "./resolvers/instagram-auth.resolver";
import { FrontDoorResolver } from "./resolvers/front-door.resolver";
import { NavigationResolver } from "./resolvers/navigation.resolver";

const routes: Routes = [
  {
    path: "",
    redirectTo: "front_door",
    pathMatch: "full",
    resolve: { data: FrontDoorResolver },
  },
  {
    path: "front_door",
    loadChildren: () =>
      import("./pages/front_door/front_door.module").then(
        (m) => m.FrontDoorPageModule
      ),
  },

  {
    path: "sign_in_choice",
    loadChildren: () =>
      import("./pages/sign-in-choice/sign-in-choice.module").then(
        (m) => m.SignInChoicePageModule
      ),
  },
  {
    path: "sign_in_choice/email",
    loadChildren: () =>
      import("./pages/sign-in/sign-in.module").then((m) => m.SignInPageModule),
  },
  {
    path: "sign_in_choice/email/forgot_password",
    loadChildren: () =>
      import("./pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },

  {
    path: "tabs",
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "under_maintenance",
    loadChildren: () =>
      import("./pages/under_maintenance/under_maintenance.module").then(
        (m) => m.UnderMaintenancePageModule
      ),
  },
  {
    path: "flappy",
    loadChildren: () =>
      import("./pages/under_maintenance/flappy.module").then(
        (m) => m.FlappyPageModule
      ),
  },
  {
    path: "phone_number/all_set",
    loadChildren: () =>
      import("./pages/phone_number/all_set/all_set.module").then(
        (m) => m.AllSetPageModule
      ),
  },
  {
    path: "phone_number/get_text",
    loadChildren: () =>
      import("./pages/phone_number/get_text/get_text.module").then(
        (m) => m.GetTextPageModule
      ),
  },
  {
    path: "phone_number/confirm_code/:phone_number",
    loadChildren: () =>
      import("./pages/phone_number/confirm_code/confirm_code.module").then(
        (m) => m.ConfirmCodePageModule
      ),
  },
  {
    path: "phone_number/confirm_number",
    loadChildren: () =>
      import("./pages/phone_number/confirm_number/confirm_number.module").then(
        (m) => m.ConfirmNumberPageModule
      ),
  },

  {
    path: "tribes/:tribe_id/highlights",
    loadChildren: () =>
      import("./pages/tribe-highlights/tribe-highlights.module").then(
        (m) => m.TribeHighlightsPageModule
      ),
  },
  {
    path: "tribes/:token/sms_highlights",
    loadChildren: () =>
      import("./pages/tribe-highlights/tribe-highlights.module").then(
        (m) => m.TribeHighlightsPageModule
      ),
    resolve: { items: TribeSmsLinkResolver },
  },

  {
    path: "instagram/auth",
    resolve: { items: InstagramAuthResolver },
    loadChildren: () =>
      import("./pages/front_door/front_door.module").then(
        (m) => m.FrontDoorPageModule
      ),
  },

  {
    path: "picture/cannot_detect_face",
    loadChildren: () =>
      import(
        "./pages/picture/cannot_detect_face/cannot_detect_face.module"
      ).then((m) => m.CannotDetectFacePageModule),
  },
  {
    path: "picture/cannot_detect_face/:state",
    loadChildren: () =>
      import(
        "./pages/picture/cannot_detect_face/cannot_detect_face.module"
      ).then((m) => m.CannotDetectFacePageModule),
  },
  {
    path: "picture/gender-missrepresentation",
    loadChildren: () =>
      import(
        "./pages/picture/gender-missrepresentation/gender-missrepresentation.module"
      ).then((m) => m.GenderMissrepresentationPageModule),
  },

  {
    path: "explanation_slider",
    loadChildren: () =>
      import("./pages/explanation_slider/explanation_slider.module").then(
        (m) => m.ExplanationSliderPageModule
      ),
  },
  {
    path: "pending-status/:state",
    loadChildren: () =>
      import("./pages/pending-status/pending-status.module").then(
        (m) => m.PendingStatusPageModule
      ),
  },
  {
    path: "onboarding",
    loadChildren: () =>
      import("./pages/onboarding/onboarding.module").then(
        (m) => m.OnboardingPageModule
      ),
  },

  {
    path: "settings",
    loadChildren: () =>
      import("./pages/settings/settings.module").then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: "levels/complete",
    loadChildren: () =>
      import("./pages/level-complete/level-complete.module").then(
        (m) => m.LevelCompletePageModule
      ),
  },
  {
    path: "levels/current",
    loadChildren: () =>
      import("./pages/statements/statements.module").then(
        (m) => m.StatementsPageModule
      ),
  },
  {
    path: "preferences",
    loadChildren: () =>
      import("./pages/preferences/preferences.module").then(
        (m) => m.PreferencesModule
      ),
  },
  {
    path: "searching",
    loadChildren: () =>
      import("./pages/searching/searching.module").then(
        (m) => m.SearchingPageModule
      ),
  },
  {
    path: "matches",
    loadChildren: () =>
      import("./pages/matches/matches.module").then((m) => m.MatchesPageModule),
  },

  {
    path: "review_request",
    loadChildren: () =>
      import("./pages/review-request/review-request.module").then(
        (m) => m.ReviewRequestPageModule
      ),
  },
  {
    path: "tribes/:id/chat",
    loadChildren: () =>
      import("./pages/tribes/chat.module").then((m) => m.ChatPageModule),
  },
  {
    path: "tribes/:id/chat/:action",
    loadChildren: () =>
      import("./pages/tribes/chat.module").then((m) => m.ChatPageModule),
  },

  {
    path: "account_deleted",
    loadChildren: () =>
      import("./pages/account-deleted/account-deleted.module").then(
        (m) => m.AccountDeletedPageModule
      ),
  },

  {
    path: "age_permission",
    loadChildren: () =>
      import("./pages/age-permission/age-permission.module").then(
        (m) => m.AgePermissionPageModule
      ),
  },
  {
    path: "age_auth_form",
    loadChildren: () =>
      import(
        "./pages/age-authorization-form/age-authorization-form.module"
      ).then((m) => m.AgeAuthorizationFormPageModule),
  },

  {
    path: "delete_my_account",
    loadChildren: () =>
      import("./pages/delete-my-account/delete-my-account.module").then(
        (m) => m.DeleteMyAccountPageModule
      ),
  },
  {
    path: "help",
    loadChildren: () =>
      import("./pages/help-and-support/help-and-support.module").then(
        (m) => m.HelpAndSupportPageModule
      ),
  },

  {
    path: "global_tests",
    loadChildren: () =>
      import("./pages/global-tests/global-tests.module").then(
        (m) => m.GlobalTestsPageModule
      ),
  },
  {
    path: "manual_location",
    loadChildren: () =>
      import("./pages/manual-location/manual-location.module").then(
        (m) => m.ManualLocationPageModule
      ),
  },

  {
    path: "profile/personality_type",
    loadChildren: () =>
      import("./pages/profile/personality-type/personality-type.module").then(
        (m) => m.PersonalityTypeModule
      ),
  },
  {
    path: "picture/crop",
    loadChildren: () =>
      import("./pages/picture/crop_selector/crop_selector.module").then(
        (m) => m.CropSelectorPageModule
      ),
  },
  {
    path: "opening-code-page",
    loadChildren: () =>
      import("./pages/opening-code-page/opening-code-page.module").then(
        (m) => m.OpeningCodePagePageModule
      ),
  },
  {
    path: "verify-sms/:code",
    loadChildren: () =>
      import("./pages/opening-code-page/opening-code-page.module").then(
        (m) => m.OpeningCodePagePageModule
      ),
  },
  {
    path: "start/verify-sms/:code",
    loadChildren: () =>
      import("./pages/opening-code-page/opening-code-page.module").then(
        (m) => m.OpeningCodePagePageModule
      ),
  },
  {
    path: "opening-quiz-time",
    loadChildren: () =>
      import("./pages/opening-quiz-time/opening-quiz-time.module").then(
        (m) => m.OpeningQuizTimePageModule
      ),
  },
  {
    path: "subscription_plan",
    loadChildren: () =>
      import("./pages/subscription-plan/subscription-plan.module").then(
        (m) => m.SubscriptionPlanPageModule
      ),
  },
  {
    path: "sms-sign-in",
    loadChildren: () =>
      import("./pages/sms-sign-in/sms-sign-in.module").then(
        (m) => m.SmsSignInPageModule
      ),
  },
  {
    path: "sign-in-code",
    loadChildren: () =>
      import("./pages/sign-in-code/sign-in-code.module").then(
        (m) => m.SignInCodePageModule
      ),
  },
  {
    path: "status_page",
    loadChildren: () =>
      import("./pages/status/status.module").then((m) => m.StatusPageModule),
  },
  {
    path: "whitelist",
    loadChildren: () =>
      import("./pages/whitelist/whitelist.module").then(
        (m) => m.WhitelistPageModule
      ),
  },
  {
    path: "sign-up-flow/:page",
    loadChildren: () =>
      import("./pages/sign-up-flow/sign-up-flow.module").then(
        (m) => m.SignUpFlowPageModule
      ),
  },
  {
    path: "sign-up-flow",
    loadChildren: () =>
      import("./pages/sign-up-flow/sign-up-flow.module").then(
        (m) => m.SignUpFlowPageModule
      ),
  },
  {
    path: "number-verification",
    loadChildren: () =>
      import("./pages/number-verification/number-verification.module").then(
        (m) => m.NumberVerificationPageModule
      ),
  },
  {
    path: "number-verification/:state",
    loadChildren: () =>
      import("./pages/number-verification/number-verification.module").then(
        (m) => m.NumberVerificationPageModule
      ),
  },
  {
    path: "matches-depleted",
    loadChildren: () =>
      import("./pages/matches-depleted/matches-depleted.module").then(
        (m) => m.MatchesDepletedPageModule
      ),
  },
  {
    path: "insufficient-info",
    loadChildren: () =>
      import("./pages/insufficient-info/insufficient-info.module").then(
        (m) => m.InsufficientInfoPageModule
      ),
  },
  {
    path: "location-permission",
    loadChildren: () =>
      import("./pages/location-permission/location-permission.module").then(
        (m) => m.LocationPermissionPageModule
      ),
  },
  {
    path: "blocked",
    loadChildren: () =>
      import("./pages/blocked/blocked.module").then((m) => m.BlockedPageModule),
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./pages/notifications/notifications.module").then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: "notifications/new-tribe-invites",
    loadChildren: () =>
      import(
        "./pages/new-tribe-invites-notifications/new-tribe-invites-notifications.module"
      ).then((m) => m.NewTribeInvitesNotificationsPageModule),
  },
  {
    path: "opening",
    loadChildren: () =>
      import("./pages/opening/opening.module").then((m) => m.OpeningPageModule),
  },
  {
    path: "add-number",
    loadChildren: () =>
      import("./pages/add-number/add-number.module").then(
        (m) => m.AddNumberPageModule
      ),
  },
  {
    path: "p",
    children: [
      {
        path: "**",
        loadChildren: () =>
          import("./pages/dummy/dummy.module").then((m) => m.DummyPageModule),
        resolve: { data: NavigationResolver },
      },
    ],
  },
  {
    path: "sms-opt-in/:state",
    loadChildren: () =>
      import("./pages/add-number/add-number.module").then(
        (m) => m.AddNumberPageModule
      ),
  },
  {
    path: "sms-opt-in",
    loadChildren: () =>
      import("./pages/add-number/add-number.module").then(
        (m) => m.AddNumberPageModule
      ),
  },
  {
    path: "firewall-block",
    loadChildren: () =>
      import("./pages/firewall-block/firewall-block.module").then(
        (m) => m.FirewallBlockPageModule
      ),
  },
  {
    path: "console",
    loadChildren: () =>
      import("./pages/console/console.module").then((m) => m.ConsolePageModule),
  },
  {
    path: "troubleshooting-notifications",
    loadChildren: () =>
      import(
        "./pages/troubleshooting-notifications/troubleshooting-notifications.module"
      ).then((m) => m.TroubleshootingNotificationsPageModule),
  },
  {
    path: "sms-verified/:status",
    loadChildren: () =>
      import("./pages/sms-verified/sms-verified.module").then(
        (m) => m.SmsVerifiedPageModule
      ),
  },
  {
    path: "info",
    loadChildren: () =>
      import("./pages/info/info.module").then((m) => m.InfoPageModule),
  },
  {
    path: "blocked-users",
    loadChildren: () =>
      import("./pages/blocked-users/blocked-users.module").then(
        (m) => m.BlockedUsersPageModule
      ),
  },
  {
    path: "change-color-mode",
    loadChildren: () =>
      import("./pages/change-color-mode/change-color-mode.module").then(
        (m) => m.ChangeColorModePageModule
      ),
  },
  {
    path: "reset-levels-request",
    loadChildren: () =>
      import("./pages/reset-levels-request/reset-levels-request.module").then(
        (m) => m.ResetLevelsRequestPageModule
      ),
  },
  {
    path: "referral-invitation",
    loadChildren: () =>
      import("./pages/referral-invitation/referral-invitation.module").then(
        (m) => m.ReferralInvitationPageModule
      ),
  },
  {
    path: "claim-referral-reward",
    loadChildren: () =>
      import("./pages/claim-referral-reward/claim-referral-reward.module").then(
        (m) => m.ClaimReferralRewardPageModule
      ),
  },
  {
    path: "group-started",
    loadChildren: () =>
      import("./pages/started-tribe/started-tribe.module").then(
        (m) => m.StartedTribePageModule
      ),
  },
  {
    path: "get-notified",
    loadChildren: () =>
      import("./pages/get-notified/get-notified.module").then(
        (m) => m.GetNotifiedPageModule
      ),
  },
  {
    path: "matches-modal",
    loadChildren: () =>
      import("./pages/matches-modal/matches-modal.module").then(
        (m) => m.MatchesModalPageModule
      ),
  },
  {
    path: "download",
    loadChildren: () =>
      import("./pages/download/download.module").then(
        (m) => m.DownloadPageModule
      ),
  },
  {
    path: "dummy-chat",
    loadChildren: () =>
      import("./pages/dummy-chat/dummy-chat.module").then(
        (m) => m.DummyChatPageModule
      ),
  },
  {
    path: "dummy-highlights",
    loadChildren: () =>
      import("./pages/dummy-highlights/dummy-highlights.module").then(
        (m) => m.DummyHighlightsPageModule
      ),
  },
  {
    path: "offboarding",
    loadChildren: () =>
      import("./pages/offboarding/offboarding.module").then(
        (m) => m.OffboardingPageModule
      ),
  },
  {
    path: "community-rules",
    loadChildren: () =>
      import("./pages/community-rules/community-rules.module").then(
        (m) => m.CommunityRulesPageModule
      ),
  },
  {
    path: "information",
    loadChildren: () =>
      import("./pages/information/information.module").then(
        (m) => m.InformationPageModule
      ),
  },
  {
    path: "information/:type",
    loadChildren: () =>
      import("./pages/information/information.module").then(
        (m) => m.InformationPageModule
      ),
  },
  {
    path: "room",
    loadChildren: () =>
      import("./pages/room/room.module").then((m) => m.RoomPageModule),
  },
  {
    path: "room/:tribeId",
    loadChildren: () =>
      import("./pages/room/room.module").then((m) => m.RoomPageModule),
  },
  {
    path: "matching-process",
    loadChildren: () =>
      import("./pages/matching-process/matching-process.module").then(
        (m) => m.MatchingProcessPageModule
      ),
  },
  {
    path: "smart-suggestion",
    loadChildren: () =>
      import("./pages/smart-suggestion/smart-suggestion.module").then(
        (m) => m.SmartSuggestionPageModule
      ),
  },
  {
    path: "meetup",
    loadChildren: () =>
      import("./pages/meetup/meetup.module").then((m) => m.MeetupPageModule),
  },
  {
    path: "tribes/:id/meetup",
    loadChildren: () =>
      import("./pages/meetup/meetup.module").then((m) => m.MeetupPageModule),
  },
  {
    path: "test",
    loadChildren: () =>
      import("./pages/test/test.module").then((m) => m.TestPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
