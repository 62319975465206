import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule  } from '@angular/forms';
import { CustomActionSheetComponent } from './custom-action-sheet.component';

@NgModule({
  declarations: [
    CustomActionSheetComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
  ],
  exports: [
    CustomActionSheetComponent
  ]
})
export class CustomActionSheetModule {}
