import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GiphyService {
  public items: any[] = [];
  public apiKey: string = 'WBvHfkVZmAHJQcBwP4a0QoKa1feqGh3E';

  public baseUrl: string = 'https://api.giphy.com/v1/gifs/';
  public searchEndPoint: string = 'search';
  public trendingEndPoint: string = 'trending';

  public limit: number = 5;
  public currentSearch: string = '';
  public currentOffset: number = 0;
  public totalCount: number = 100;

  constructor(public http: HttpClient) { }

  reset() {
    this.totalCount = 100;
    this.currentOffset = 0;
  }

  canLoadMore() {
    return this.totalCount > this.currentOffset;
  }

  load(search) {
    if(search != this.currentSearch) {
      this.currentSearch = search;
    }

    let url = this.baseUrl;
    if(!search){
      url = url + this.trendingEndPoint + '?';
    } else {
      url = url + this.searchEndPoint + '?' + 'q=' + search + '&';
    }

    url = url + 'api_key=' + this.apiKey + '&limit=' + this.limit + '&offset=' + this.currentOffset;

    return new Promise((resolve, reject) => { 
      if(!this.canLoadMore()){
        return resolve([]);
      } else {
        this.http.get(url)
          .toPromise()
          .then(
            (resp:any)  => { 
              if(resp.pagination.total_count) { 
                this.totalCount = resp.pagination.total_count;
              }
              this.currentOffset = resp.pagination.offset + this.limit;
              this.items = resp.data.map(d => {
                return { 
                  mini_url: d.images.fixed_height_small.url, 
                  mini_width: d.images.fixed_height_small.width,
                  url: d.images.fixed_height.url,
                }
              });
              resolve(this.items);
            }, reject);
      }
    });
  }
}

