import { Injectable } from '@angular/core';
import { actionSheetController } from '@ionic/core';
import { BehaviorSubject, combineLatest, concat, from, merge, Observable, of, Subject } from 'rxjs';
import { last, map, pluck, scan, startWith, tap } from 'rxjs/operators';
import { isDev } from '../shared/helpers/helpers';
import { logc } from '../shared/helpers/log';
import { Message } from '../shared/models/message';
import { TribeService } from './data/tribe.service';
import { TribesService } from './data/tribes.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private cachedTribe$: Observable<any>;
  private tribe$: Observable<any>;
  public showStartersTipSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showHighlightsTipSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private chatUpdates$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private tribeService: TribeService,
              private tribesService: TribesService) {
    if(isDev()) {
      (window as any).chatService = this;
    }
    logc.aquamarine("Chat service is loaded!");
  }

  showStartersTip() {
    this.showStartersTipSource.next(true);
  }

  showHighlightsTip() {
    this.showHighlightsTipSource.next(true);
  }

  async getMessages(id) {
    this.cachedTribe$ = from((await this.tribeService.loadFromCache(id) as any)?.tribe?.messages || []);
    this.tribe$ = from((await this.tribeService.loadRecent(id) as any)?.tribe?.messages || []);

    this.tribe$.pipe(tap(msg => logc.crimson("msg: ", msg))).subscribe();
    return combineLatest(
      this.chatUpdates$,
      concat(
        this.cachedTribe$,
        this.tribe$
      )
    ).pipe(
      scan((messages, [event, message]) => {
        logc.indigo("Event: ", event);
        if(!messages.map(msg => msg.id).includes(message.id) && !event.action) {
          messages = [ ...messages, message ];
        } else {
          const msgId = messages.findIndex(msg => msg.id == message.id);
          if(msgId > -1) {
            messages.splice(msgId, 1, message);
          }
        }
        if(event.action == 'add') {
          messages = [ ...messages, event.payload ];
        }
        if(event.action == 'delete') {
          messages = messages.filter(m => m.id != event.payload.id);
        }
        return messages;
      }, []),
      map(messages => [ ...messages ].reverse())
    )
  }

  sendMessage(message: Message) {
    this.chatUpdates$.next({ action: "add", payload: message })
  }

  deleteMessage(message) {
    this.chatUpdates$.next({ action: "delete", payload: message })
  }

  updateMessage(oldMessage, newMessage) {
    this.chatUpdates$.next({ action: "delete", payload: { oldMessage, newMessage } })
  }
}
