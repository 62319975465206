<ion-content >

<!--  <button (click) = "changeState()">change state</button>-->
<!--  <div>{{state}}</div>-->

  <ion-icon class = "close-button" (click) = "close()" name = "close-circle"></ion-icon>

  <div class = "content">
    <div class = "title-container">
      <div class = "title">
        Concerned about your privacy?
      </div>
      <div class = "subtitle">
        Here’s how to make sure your whereabouts remain private while still getting matched.
      </div>
    </div>

    <div *ngIf = "statusIs('allowed')" class = "location-state-container allowed">
      <div class = "step">
        <div class = "step-title"> Step 1: Enable location permissions </div>
        <div class = "step-button">
          <light-button (onClick) = "setLocationPreferences()" [buttonBody] = "allowedStepOneBody"></light-button>
        </div>
      </div>

      <div class = "step">
        <div class = "step-title"> Step 2: Disable location permissions </div>
        <div class = "step-description"> Open We3 Permissions from your device’s settings. </div>
        <div class = "step-button">
          <light-button (onClick) = "openSettings()" [buttonBody] = "allowedStepTwoBody"></light-button>
        </div>
        <div class = "step-description">
          Then, tap on <strong>Permissions</strong> and switch <strong>Location</strong> to <strong>OFF</strong>.
        </div>
      </div>

    </div>

    <div *ngIf = "!statusIs('allowed') && !isPwa" class = "location-state-container denied">
      <div class = "step">
        <div class = "step-title"> Step 1: {{ isIos ? 'Allow location access' : 'Enable location permissions' }} </div>
        <div class = "step-description"> To know where to match you, Open We3 Permissions from your device’s settings. </div>
        <div class = "step-button">
          <light-button (onClick) = "openSettings()" [buttonBody] = "deniedStepOneBody"></light-button>
        </div>
        <div class = "step-description">
          Then, tap on <strong>{{ isIos ? "Location" : "Permissions" }}</strong> and
          <span *ngIf = "isIos; else androidSwitchText">
            select <strong>While Using the App</strong>.
          </span>
          <ng-template #androidSwitchText>
            switch <strong>Location</strong> to <strong>ON</strong>.
          </ng-template>
        </div>
        <div class = "step-description"> Finally, <strong>reopen We3</strong> to make sure we detected where to match you. </div>
      </div>

      <div class = "step">
        <div class = "step-title"> Step 2: {{ isIos ? "Revoke location access" : "Disable location permissions" }} </div>
        <div class = "step-description">
          Reopen <strong>{{ isIos ? "We3 Location Settings" : "We3 Permissions" }}</strong> and
          <span *ngIf = "isIos; else androidDisablingText">
            select <strong>Never or Ask Next Time.</strong>
          </span>
          <ng-template #androidDisablingText>
            disable them by switching <strong>Location</strong> to <strong>OFF</strong>.
          </ng-template>
        </div>
      </div>
    </div>

    <div *ngIf = "isPwa" class = "location-state-container denied">
      <div class = "step">
        <div class = "step-title"> Step 1: Allow location access </div>
        <div class = "step-description"> To know where to match you, open the Website Settings on this browser. </div>
        <div class = "step-description">
          Then, tap on <strong>Location</strong> and set it to <strong>Allow</strong>.
        </div>
        <div class = "step-description"> Then, <strong>go back to We3</strong> to make sure we detected where we should match you by trying to get matched. </div>
        <div class = "step-description"> If this message reappears, verify in your <strong>Device's Settings</strong> that the browser app itself (e.g. Chrome, Safari) has location access. </div>
      </div>
      <div class = "step">
        <div class = "step-title"> Step 2: Block or Deny location access</div>
        <div class = "step-description">
          Reopen the Website Settings on this browser and set <strong>Location</strong> access back to <strong>Block</strong> or <strong>Deny</strong>.
        </div>
      </div>
    </div>

    <div class = "notice">
      <strong>Important note:</strong> All distances shown on We3 will no longer be accurate.
    </div>
  </div>
</ion-content>
