import {Injectable} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class PictureService {
  constructor(public domSanitizer: DomSanitizer) {
    (window as any).pictureService = this;
  }

  toDataUrl(url) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = () => {
        var reader = new FileReader();
        reader.onloadend = () => {
          const str = (<string> reader.result);
          resolve(str.replace(/^data:image\/(png|jpeg|jpg);base64,/, ""));
        }

        reader.onerror = (err) => {
          reject(err);
        }
 
        reader.readAsDataURL(xhr.response);
      }
      try {
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      } catch (err) {
        reject(err);
      }
    });
  }

  sanitizeImage(image){
    return new Promise((resolve)=>{
      resolve(this.domSanitizer.sanitize(4,'data:image/jpeg;base64,' +image));
    });
  }
}
