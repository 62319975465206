import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { DirectivesModule } from "src/app/directives/directives.module";
import { ImageRowModule } from "../image-row/image-row.module";
import { PotentialMatchesShortComponent } from "./potential-matches-short.component";

@NgModule({
  declarations: [PotentialMatchesShortComponent],
  imports: [
    CommonModule,
    IonicModule,
    ImageRowModule,
    DirectivesModule
  ],
  exports: [PotentialMatchesShortComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PotentialMatchesShortModule {}