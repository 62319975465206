import {
  ApplicationRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from "@angular/core";
import {logc} from "../shared/helpers/log";
import {fromEvent, pipe} from "rxjs";
import {takeUntil} from "rxjs/internal/operators";
import {tap} from "rxjs/operators";
import {Subject} from "rxjs/Subject";

export enum TooltipPosition {
  Center = 0.5,
  Left = 0.8,
  Right = 0.2
}

export enum TooltipAppearance {
  Default = "",
  Blue = "secondary-gradient white-color"
}

export interface TooltipOptions {
  showOnce?: boolean;
  initShow?: boolean;
  duration?: number;
  delay?: number;
  appearance?: TooltipAppearance;
  cssClass?: string;
  position?: TooltipPosition;
  callback?: Function;
}

@Directive({
  selector: "[tooltip]"
})
export class TooltipDirective implements OnChanges {
  @Input("showTooltip") showTooltip: boolean = true;
  @Input("progress") progress: any = {};
  @Input("tooltip") tooltipText: string;
  @Input("tooltipOptions") options: TooltipOptions = {};

  private defaultOptions = {
    initShow: false,
    showOnce: false,
    duration: 3000,
    delay: 0,
    cssClass: '',
    show: true,
    appearance: TooltipAppearance.Default,
    position: TooltipPosition.Left,
    callback: () => {}
  };

  private timeout: any;
  private tooltipShown: boolean = false;
  private tooltipElement: HTMLElement;
  private tooltipArrow: HTMLElement;

  private OFFSET: number = 10;

  @HostListener("mousedown")
  tapped() {
    if(this.options.showOnce) {
      return this.hide();
    }
    if(this.timeout || !this.tooltipText) return;

    this.tooltipShown = true;
    this.show();
    if(this.options.duration > 0) {
      this.timeout = setTimeout(() => {
        this.hide();
      }, this.options.duration);
    }
  }

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private element: ElementRef,
              private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    // logc.orange("Changes: ", changes);
  }

  ngOnInit() {
    this.options = { ...this.defaultOptions, ...this.options };
    this.element.nativeElement.style.position = 'relative';
    setTimeout(() => {
      this.createTooltip();
      if(this.options.initShow) {
        this.show();
      }
    }, this.options.delay);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private closingListener(event) {
    if(this.tooltipElement.contains(event.target)) {
      this.options?.callback();
      this.hide();
    }
  }

  createTooltip() {
    let tooltip = document.createElement("div");
    let arrow = document.createElement("div");
    tooltip.classList.add("tooltip-directive-container");
    tooltip.innerText = this.tooltipText;
    arrow.classList.add("tooltip-directive-arrow-down");
    this.addStyles(tooltip, arrow);

    setTimeout(() => {
      tooltip.style.top = (this.element.nativeElement.offsetTop - tooltip.offsetHeight - this.OFFSET) + "px";
      tooltip.style.left = (this.element.nativeElement.offsetLeft - tooltip.offsetWidth * this.options.position) + "px";
      arrow.style.top = (this.element.nativeElement.offsetTop - this.OFFSET - 2) + "px";
      arrow.style.left =
        this.element.nativeElement.offsetLeft
        + (this.element.nativeElement.offsetWidth - arrow.offsetWidth) / 2
        + "px";
    }, 0);
    this.tooltipElement = tooltip;
    this.tooltipArrow = arrow;

    fromEvent(document, "click")
      .pipe(
        takeUntil(this.unsubscribe),
        tap((event) => this.closingListener(event))
      ).subscribe();

    this.insertNode(this.tooltipElement);
    this.insertNode(this.tooltipArrow);
  }

  addStyles(tooltip, arrow) {
    const cssClass = this.options.cssClass;
    const appearance = this.options.appearance;
    if(cssClass) {
      cssClass.split(' ').forEach((cssClass) => {
        tooltip.classList.add(cssClass);
        arrow.classList.add(cssClass);
      })
    }
    if(appearance) {
      appearance.split(' ').forEach((cssClass) => {
        tooltip.classList.add(cssClass);
        arrow.classList.add(cssClass);
      })
    }
  }

  insertNode(node) {
    this.renderer.insertBefore(this.element.nativeElement.parentNode, node, this.element.nativeElement);
  }

  show() {
    this.tooltipElement.classList.add("shown");
    this.tooltipArrow.classList.add("shown");
  }

  hide() {
    if(this.tooltipElement) {
      this.tooltipElement.classList.remove("shown");
      this.tooltipArrow.classList.remove("shown");
    }
    this.timeout = null;
    this.tooltipShown = false;
  }
}
