import { Component, Input, OnInit } from '@angular/core';
import { logc } from 'src/app/shared/helpers/log';

@Component({
  selector: 'icon-plate',
  template: `
    <div class = "status-container" [class] = "cssClass">
      <div class = "status {{ size }} {{ iconCssClass }}">
        <ion-icon [name] = "name"></ion-icon>
      </div> 
    </div>
  `,
  styles: [`
    .status-container {
      position: absolute;
    }
    .status {
      position: relative;
      z-index: 150;
      border-radius: 50%;
      font-size: 18px;
      padding: 3px;
      border: 2px solid;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .big {
      font-size: 25px;
      padding: 5px;
    }
  `],
})
export class IconPlateComponent {
  @Input() name: string = "";
  @Input() cssClass: string = "";
  @Input() size: string = "";
  @Input() iconCssClass: string = "text-secondary-dark-3 bg-secondary-light-1";
}
