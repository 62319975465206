<ion-content class = "state-page state-color">

  <ion-icon *ngIf = "searchingState == 'tryingAgain' || searchingState === 'error'"
            class = "close-button fade-in-animation"
            name = "close" (click) = "close()">
  </ion-icon>

  <div #header class = "state-content {{searchingState}}">
    <div class = "radar" *ngIf = "searchingState == 'searching' || searchingState == 'tryingAgain'">
      <ng-lottie [options] = "lottieSearch"
                 width = "500px"
                 height = "500px"
                 (animationCreated) = "handleMatchingAnimation($event)">
      </ng-lottie>
    </div>
    <div class = "firework" *ngIf = "searchingState == 'found'">
      <ng-lottie
                 [options] = "lottieFirework"
                 width = "600px"
                 height = "600px"
                 (animationCreated) = "handleSuccessAnimation($event)">
      </ng-lottie>
    </div>
    <div class = "not-ready" *ngIf = "searchingState == 'notReady'">
      <ng-lottie [options] = "lottieNotReady"
                 (animationCreated) = "handleNotReadyAnimation($event)">
      </ng-lottie>
    </div>
  </div>

  <div class = "state-header custom" #content>
    <div class = "state-title" [innerHTML] = "state?.text"></div>
    <blinking-text *ngIf = "state?.blinkingText; else subtext" [options] = "state?.blinkingText"></blinking-text>
    <ng-template #subtext>
      <div class = "state-sub-title">{{ state?.subText }}</div>
    </ng-template>
  </div>

  <div *ngIf = "isIncompleteStage1">
    <div class = "incomplete-text fade-in-animation body-color">
      <div *ngIf = "matchesLength > 0; else zeroMatchesText">
        We found {{ matchesLength }} compatible people, but we can’t form a group right now.
      </div>
      <ng-template #zeroMatchesText>
        We're having trouble matching right now. Please try again later.
      </ng-template>
    </div>
    <div *ngIf = "showPotentialMatches" class = "potential-matches-section mx-[15px]">
      <potential-matches-short title = "Unavailable matches"
                               [searchData] = "searchData" 
                               [matchesNumber] = "searchData?.total_matches">
      </potential-matches-short>
    </div>
  </div>
  <div *ngIf = "isIncompleteStage2" class = "button-container">
    <ion-button class = 'ionic-button turquoise-gradient-btn fade-in-animation'
                large
                shape = "round"
                (click) = "openGetNotifiedPage()">
      Next
    </ion-button>
  </div>
</ion-content>
