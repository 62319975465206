import {Injectable, NgZone} from '@angular/core';
import {Config} from "./config/config";
import {CustomAlertComponent} from "../components/custom-alert/custom-alert.component";
import {DeclineMenuComponent} from "../components/decline-menu/decline-menu.component";
import {GenderChangePage} from "../pages/gender-change/gender-change.page";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  PopoverController
} from "@ionic/angular";
import {UtilsService} from "./utils.service";
import {AlertService} from "./popups/alert.service";
import {FeedbackService} from "./popups/feedback.service";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "./data/user.service";
import {SessionService} from "./data/session.service";
import {ApiService} from "./data/api.service";
import {StoreService} from "./native/store.service";
import {TribesService} from "./data/tribes.service";
import {StatementsService} from "./data/statements.service";
import {Subject} from "rxjs/Subject";
import {environment} from "../../environments/environment";
import {CUSTOM_NOTIFICATION_ALERTS} from "../shared/constants/custom-notification-alerts";
import {LocationPermissionPage} from "../pages/location-permission/location-permission.page";
import {ReviewRequestPage} from "../pages/review-request/review-request.page";
import {DispatcherService} from "./dispatcher.service";
import {InfoPage} from "../pages/info/info.page";
import {HeartfeltComponent} from "../components/heartfelt/heartfelt.component";
import {CheckmarkComponent} from "../components/checkmark/checkmark.component";
import {isMobile} from "../shared/helpers/helpers";
import { ManualLocationPage } from '../pages/manual-location/manual-location.page';
import {RemoteConfigService} from "./remote-config.service";
import { logc } from '../shared/helpers/log';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  public unsubscribe = new Subject<void>();
  public isProduction = environment.production;
  public showWaiting: boolean = false;
  private customAlerts = CUSTOM_NOTIFICATION_ALERTS;


  private components: any = {
    location: LocationPermissionPage,
    reviewRequest: ReviewRequestPage,
    infoModalPrivacy: InfoPage,
    heartfelt: HeartfeltComponent
  };

  constructor(public platform: Platform,
              public navCtrl: NavController,
              public alertCtrl: AlertController,
              public config: Config,
              public modalCtrl: ModalController,
              public loadingCtrl: LoadingController,
              public userService: UserService,
              public sessionService: SessionService,
              private alertService: AlertService,
              private dispatcherService: DispatcherService,
              public utils: UtilsService,
              public api: ApiService,
              private diagnostic: Diagnostic,
              public ngZone: NgZone,
              private storeService: StoreService,
              public popoverCtrl: PopoverController,
              public tribes: TribesService,
              public statementsService: StatementsService,
              private route: ActivatedRoute,
              private feedbackService: FeedbackService,
              private remoteConfigService: RemoteConfigService) {
    if(environment.name == 'development') {
      (window as any).testService = this;
    }                
  }

  async showPluginsInfo(): Promise<void> {
    if(!this.platform.is('cordova')) {
      logc.info("** Not cordova **");
      return;
    }
    //CONTACTS LOCATION NOTIFICATIONS CAMERA EXTERNAL_STORAGE CALENDAR
    logc.info("Plugins auth statuses: ");
    logc.info("Contacts: ", await this.diagnostic.getContactsAuthorizationStatus());
    logc.info("Location: ", await this.diagnostic.getLocationAuthorizationStatus());
    logc.info("Notifications: ", await this.diagnostic.getRemoteNotificationsAuthorizationStatus());
    logc.info("Camera: ", await this.diagnostic.getCameraAuthorizationStatus());
    logc.info("Calendar: ", await this.diagnostic.getCalendarAuthorizationStatus());
  }

  async offboardingGenderAsked() {
    return this.config.setFlag('genderPreferenceChangeAsked', false);
  }

  async nullTooltipFlags() {
    return this.config.setFlags({
      sideMenuTipShown: false,
      matchesTipShown: false,
      chatTipShown: false,
      highlightsHintShown: false
    })
  }

  async openCustomAlert(alertName: any) {
    try {
      const alert = await this.alertService.createAlertNotification(this.customAlerts[alertName], CustomAlertComponent);
      alert.open();
    } catch ( err ) {
      console.log( err );
    }
  }

  async openInfoModal( type ) {
    const modal = await this.modalCtrl.create({
      component: InfoPage,
      componentProps: {
        type: type
      },
      cssClass: "info-modal"
    });
    return modal.present();
  }

  async openPage(pageUrl: string, extras = {}): Promise<any> {
    try {
      await this.navCtrl.navigateForward( pageUrl, extras );
    } catch ( err ){
      console.log( err );
    }
  }

  getHeartfeltState(stateName) {
    // return this.heartfeltMessageService.getState({ state: stateName });
  }

  openHeartfeltModal({ state, userName }) {
    this.openModal('heartfelt', {
      state: {
        title: `You rock, ${ userName }! 🤘`,
        description: [
          "Putting yourself out there like you just did can be a bit scary.",
          "<div class = 'underlined bold'>And you still did it.</div>",
          "But the process of making genuine new friends won't be easy. There will be many disappointing moments along the way.",
          "Don’t let those setbacks discourage you!",
          "I wish you the best of luck on this journey."
        ],
        author: {
          name: "Julian",
          position: "Founder of We3"
        },
        signatureImage: this.utils.getSignatureImagePath('julian'),
        ps: "",
        callback: () => this.dispatcherService.firstTribeCreatedSource.next(true)
      },
      userName
    })
  }

  async showCheckmarkPage() {
    try {
      let modal = await this.modalCtrl.create({
        component: CheckmarkComponent,
        id: 'checkmarkModal',
        cssClass: "full-height-modal"
      });
      await modal.present();
      setTimeout(() => modal.dismiss(), 500000);
    } catch (e) {
    }
  }

  async openModal(component, props = {}) {
    try {
      const modal = await this.modalCtrl.create({
        component: this.components[component],
        componentProps: props,
        backdropDismiss: true,
        swipeToClose: true,
        cssClass: "modal-card"
      });
      await modal.present();
    } catch ( err ) {
      console.log( err );
    }
  }

  async extraPopup() {
    const popover = await this.popoverCtrl.create({
      component: DeclineMenuComponent,
      cssClass: 'popup-test'
    });
    await popover.present();
  }

  async openGenericPopup(popupName: string, options = {}) {
    this.dispatcherService.newPopupSource.next({ popupName, options })
  }


  genderChangePopup() {
    this.popoverCtrl.create({
      component: GenderChangePage,
      cssClass: `popup ${ !isMobile() ? "desktop" : ""}`,
      backdropDismiss: true
    }).then( popover => {
      popover.present();
    });
  }

  openWaiting() {
    this.showWaiting = true;
  }

  async openPickAPlace() {
    await this.navCtrl.navigateForward('manual_location');
  }

  async openSignUpFlow(page) {
    return this.navCtrl.navigateForward(['sign-up-flow'], { state: { setup: [page], backPath: 'tabs/profile' } });
  }

  async openFeedbackForm() {
    let question = { title: "How are you finding the levels?", body: "Help us improve our questions by rating them."};
    await this.feedbackService.createQuestion(question);
  }


  close() {
    this.modalCtrl.dismiss();
  }

  async showBottomSheet() {
    try {
      const modal = await this.modalCtrl.create({
        component: ManualLocationPage,
        breakpoints: [0, 0.3, 0.5, 0.8],
        initialBreakpoint: 0.5
      });
      await modal.present();
    } catch(e) {

    }
  }

  async showABTestsInfo() {
    const anonymousTest = await this.remoteConfigService.getObject("show_name_slide_skip_button");
    const startedTribeFirstMessage = await this.remoteConfigService.getObject("tribe_started_page_close_button_enabled");
    console.log("---- AB Tests in progress ----");
    if(anonymousTest.inExperiment) {
      console.log(`Name: ${ anonymousTest.name }`);
      console.log(`Group: ${ anonymousTest.group }`);
    }
    if(startedTribeFirstMessage.inExperiment) {
      console.log(`Name: ${ startedTribeFirstMessage.name }`);
      console.log(`Group: ${ startedTribeFirstMessage.group }`);
    }
    if(!anonymousTest.inExperiment && !startedTribeFirstMessage.inExperiment) {
      console.log("No tests");
    }
    console.log("------------------");
  }

}
