import { Component, Input } from "@angular/core";

@Component({
  selector: "close-button",
  template: `
    <div class = "close-button-container" [class.background-circle] = "backgroundCircle">
      <ion-icon class = "text-label" name = "close-outline"></ion-icon>
    </div>
  `,
  styleUrls: ["close-button.component.scss"]
})
export class CloseButtonComponent {
  @Input('backgroundCircle') backgroundCircle: boolean = false;
  constructor() {
  }
}
