import { logc } from "./helpers/log";

export const createInfiniteLoop = 
  (items, i = 0) => ({
    prev: () => {
      if(i == items.length) i -= 1;
      if(i <= 0) i = items.length;
      return items[--i];
    },
    next: () => {
      if(i == items.length) i = 0;
      return items[i++];
    },
    setStartingIndex: (index) => i = index,
    setStartingValue: (value) => 
      i = items.findIndex(item => item == value) > -1 
        ? items.findIndex(item => item == value) + 1
        : 0
  })


export const createTwoSidesInifiniteLoop = 
  (items, i = 0) => ({
    prev: () => {
      if(i == 0) {
        i = items.length - 1;
      } else {
        i -= 1;
      }
      return items[i];
    },
    current: () => items[i],
    next: () => {
    	if(i == items.length - 1) {
        i = 0;
      } else {
        i += 1;
      }
      return items[i];
    }
  })


export const createAsyncToggle = (target, values, fn) => {
  const loop = createTwoSidesInifiniteLoop(values);
  target = loop.current();
  return async () => {
    target = loop.next();
    try {
      await fn();
    } catch(e) {
      target = loop.prev();
    }
  }
}