import { Component } from '@angular/core';
import { PopoverController  } from '@ionic/angular';
import {Config} from "../../services/config/config";

@Component({
  selector: 'options-popup',
  template: `
    <div *ngIf = "isChat" class = "reactions">
      <span *ngFor = "let emoji of emojis" 
            class = "emoji" 
            [innerHTML] = "emoji" 
            (click) = "sendReaction(emoji)"></span>
    </div>
    <div class = "options">
      <div class = "buttons-holder">
        <div *ngFor="let option of options"
             class = "options-button relative"
             [class] = "(option.class || '') + ' button'"
             (click) = "optionClicked(option)">
          <ion-icon [name]="option.icon"></ion-icon> {{option.text}}
          <div *ngIf = "option.plusBadge" class = "plus-badge">Plus</div>
          <div *ngIf = "option?.attention" 
               class = "h-[10px] w-[10px] bg-error absolute rounded-full right-[15px]"></div>
        </div>
      </div>
    </div>

  `,
  styleUrls: ['options-popup.scss'],
})
export class OptionsPopupComponent {
  profile: any;
  options: any = [];
  isChat: boolean = false;

  public emojis = ["👍", "❤️", "😂", "😮", "😢"];

  constructor(private popoverCtrl: PopoverController) {}

  optionClicked(option) {
    option.handler();
      if(option.dontDismiss)
        return;
    this.popoverCtrl.dismiss();
  }

  sendReaction(emoji) {
    this.popoverCtrl.dismiss({ emoji });
  }
}
