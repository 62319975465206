import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { addMinutes, addSeconds, differenceInSeconds, parseISO } from "date-fns";
import { Observable, Subject } from "rxjs";
import { takeUntil, first } from "rxjs/operators";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { Config } from "src/app/services/config/config";
import { SessionService } from "src/app/services/data/session.service";
import { UserService } from "src/app/services/data/user.service";
import { CountdownTimerService } from "src/app/services/helpers/countdown-timer.service";
import { StoreService } from "src/app/services/native/store.service";
import { UtilsService } from "src/app/services/utils.service";
import { isDev, values } from "src/app/shared/helpers/helpers";
import { logc } from "src/app/shared/helpers/log";

export const UpsellingPoppingReason = {
  GetMatched: "get_matched",
  DailyGroupLimit: "daily_group_limit",
  SeeAllMatches: "see_all_matches",
  PickGroupMember: "pick_group_member",
  FilterByOtherGender: "filter_by_other_gender",
  DirectMessages: "direct_messages",
  ExtendExpiryTime: "extend_expiry_time",
  ChangeMatchLocation: "change_match_location"
} as const;

export type UpsellingPoppingReason = typeof UpsellingPoppingReason[keyof typeof UpsellingPoppingReason];

interface UpsellingPageState {
  subTitle: string;
  title: string;
  plans: BillingPlan[];
  features: PremiumFeature[];
}

export interface BillingPlan {
  id?: number;
  name?: string;
  description?: string;
  price?: string;
  priceDetails?: string;
  discount?: string;
  selected?: boolean;
  planId?: string;
}

export interface PremiumFeature {
  name: string;
  highlightReason?: string;
  details: PremiumFeatureDetails;
}

interface PremiumFeatureDetails {
  freePlan: PremiumFeaturePlan;
  premiumPlan: PremiumFeaturePlan;
}

interface PremiumFeaturePlan { 
  blocked?: boolean;
  unlimited?: boolean;
  value?: string;
}

@Component({
  selector: "upselling-page",
  template: `
    <ion-header class = "absolute w-[100vw] h-[100px] z-[1500]">
      <close-button class = "absolute z-[1000] right-[15px] top-[15px]"
                      [backgroundCircle] = "true"
                      (click) = "close()"></close-button>
    </ion-header>
    <ion-content class = "relative z-[1000] ion-content-extended-padding" [style.--padding-bottom.px] = "150">
      <timer *ngIf = "specialOfferTimer" 
             [timer] = "specialOfferTimer" 
             (onFinish) = "onTimerFinish()"></timer>
      <div class = "font-heavy text-base text-tertiary uppercase" [innerText] = "state?.subTitle"></div>
      <div class = "font-heavy text-title text-xl my-[10px]" [innerText] = "state?.title"></div>
      <premium-features-table [upsellingOpeningReason] = "upsellingOpeningReason" 
                              [expand] = "expandPremiumFeatures"
                              [featuresList] = "state?.features"></premium-features-table>
      <div *ngIf = "!isPWA; else downloadTheApp" class = "mt-[15px] mb-[25px]">
        <billing-plan *ngFor = "let plan of state?.plans; let last = last" 
                      [last] = "last"
                      [plan] = "plan"
                      (planSelected) = "selectPlan($event)"></billing-plan>
      </div>
      <ng-template #downloadTheApp>
        <div class = "flex justify-center items-center flex-col gap-[10px] my-[15px]">
          <ion-icon class = "text-[50px]" name = "cloud-download"></ion-icon>
          <div class = "text-large font-heavy text-title">
            Download the app to try We3 Plus
          </div>
          <div class="text-small text-body">
            You'll remain logged in.
          </div>
        </div>
      </ng-template>
    </ion-content>
    <ion-footer>
      <div class="gap-[15px] items-center px-[15px] bg-surface py-[15px]">
        <ion-button *ngIf = "!isPWA"
                    class = "ionic-button golden-button" 
                    [style.width.%] = "100"
                    (click) = "subscribe()"
                    shape = "round">
          Continue with {{ selectedPlan?.name }} Plan
        </ion-button>
        <ion-button *ngIf = "isPWA"
                    class = "ionic-button" 
                    [style.width.%] = "100"
                    (click) = "download()"
                    shape = "round">
          Download the app
        </ion-button>
        <button (click) = "close()" class = "empty-button no-border">
          Skip for now
        </button>
      </div>
   </ion-footer>
  `,
  styles: []
})
export class UpsellingPage implements OnInit {
  @Input() specialOfferDeadline: Date = null;
  public specialOfferTimer: Observable<string> = null;
  public billingPlans: BillingPlan[] = [];

  public selectedPlan: BillingPlan = null;
  public specialOfferPlan: BillingPlan = null;
  private featuresList: PremiumFeature[] = [];
  private states: any = {};
  public state: UpsellingPageState = null;

  public isPWA: boolean = false;
  public isLoaded: boolean = false;

  public unsubscribe: Subject<any> = new Subject();
  public upsellingOpeningReason: any = null;
  public expandPremiumFeatures: boolean = false;
  
  constructor(private countdownTimerService: CountdownTimerService, 
              private modalCtrl: ModalController,
              private config: Config,
              private analyticsService: AnalyticsService,
              private navParams: NavParams,
              private utils: UtilsService,
              private sessionService: SessionService,
              private userService: UserService,
              private storeService: StoreService) {
    if(isDev()) {
      (window as any).upsellingPage = this;
    }
  }

  download(): void {
    this.utils.goToAppStore();
  }
  
  ngOnInit(): void {
    this.isPWA = this.config.isPWA;
    this.incrementVisits();
   
    if(this.sessionService.stateMachine.state === 'ready') {
      console.log('Loading plans');
      this.initPlans();
    } else {
      this.sessionService.onReady
        .pipe(first())
        .subscribe(async (data) => {
          console.log('Loading plans');
          this.initPlans();
        });
    }
  }

  initPlans(): void {
    const dividePrice = (priceString, times) =>
      (parseFloat(priceString.replace(/,/g, '.').replace(/[^0-9.]/g, '')) / times).toFixed(2);
    const products = this.storeService.getSubscriptions();
    console.log('ALL PRODUCTS', JSON.stringify(products));
    const [ semiannually, quarterly, monthly, weekly ] = 
    [
      products['we3.plus.semiannually'],
      products['we3.plus.quarterly'],
      products['we3.plus.monthly'],
      products['we3.plus.weekly']
    ];
    if(!this.config.isPWA) {
      this.billingPlans = [
        { 
          id: 1,
          planId: "we3.plus.semiannualy",
          name: "Semiannual",
          description: `Billed as one payment of ${ semiannually['pricing']['price'] }`,
          priceDetails: `${ dividePrice(semiannually['pricing']['price'], 6) } /mo`,
          price: semiannually['pricing']['price'], 
          discount: "SAVE 50%",
          selected: false
        },
        {
          id: 2,
          planId: "we3.plus.quarterly",
          name: "Quarterly",
          description: `Billed as one payment of ${ quarterly['pricing']['price'] }`,
          priceDetails: `${ dividePrice(quarterly['pricing']['price'], 3) } /mo`,
          price: quarterly['pricing']['price'],
          discount: "SAVE 25%",
          selected: false
        },
        {
          id: 3,
          planId: "we3.plus.monthly",
          name: "Monthly",
          description: `${ monthly['pricing']['price'] } /mo`,
          price: monthly['pricing']['price'],
          selected: false
        },
        {
          id: 4,
          planId: "we3.plus.weekly",
          name: "Weekly",
          description: `${ weekly['pricing']['price'] } /week`,
          price: weekly['pricing']['price'],
          priceDetails: "Today only",
          discount: "65% LESS",
          selected: false
        },
      ];

      this.specialOfferPlan = this.billingPlans.find(({ planId }) => planId === "we3.plus.weekly");
    } else {
      this.billingPlans = [];
    }

    this.featuresList = [
      {
        name: "Get matched by We3",
        highlightReason: UpsellingPoppingReason.GetMatched,
        details: { freePlan: { value: "Limited" }, premiumPlan: { value: "Unlimited" } }
      },
      {
        name: "Daily group limit",
        highlightReason: UpsellingPoppingReason.DailyGroupLimit,
        details: { freePlan: { value: "1" }, premiumPlan: { value: "3" } }
      },
      {
        name: "See all your matches",
        highlightReason: UpsellingPoppingReason.SeeAllMatches,
        details: { freePlan: { value: "—" }, premiumPlan: { unlimited: true } }
      },
      {
        name: "Pick each group member",
        highlightReason: UpsellingPoppingReason.PickGroupMember,
        details: { freePlan: { value: "—" }, premiumPlan: { unlimited: true } }
      },
      {
        name: "Filter by other genders",
        highlightReason: UpsellingPoppingReason.FilterByOtherGender,
        details: { freePlan: { value: "—" }, premiumPlan: { unlimited: true } }
      },
      {
        name: "Direct message members",
        highlightReason: UpsellingPoppingReason.DirectMessages,
        details: { freePlan: { value: "—" }, premiumPlan: { unlimited: true } }
      },
      {
        name: "Extend invite expiry time",
        highlightReason: UpsellingPoppingReason.ExtendExpiryTime,
        details: { freePlan: { value: "—" }, premiumPlan: { unlimited: true } }
      },
      {
        name: "Change match location",
        highlightReason: UpsellingPoppingReason.ChangeMatchLocation,
        details: { freePlan: { value: "—" }, premiumPlan: { unlimited: true } }
      }
    ]

    this.states = {
      default: {
        subTitle: "Try We3 Plus",
        title: "Take control over your We3 experience",
        plans: this.billingPlans.slice(0, 3),
        features: this.featuresList
      },
      special: {
        subTitle: "Limited time offer",
        title: `Try We3 Plus for just ${ this.specialOfferPlan?.price || '$6.99' }`,
        plans: [ ...this.billingPlans ],
        features: this.featuresList
      }
    }

    this.state = this.states.default;

    this.setSpecialOffer();
    this.sessionService.onReady.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.setSpecialOffer();
    })

    const source = this.navParams.get('source') || '';
    const reason = this.navParams.get("reason") || '';
    this.upsellingOpeningReason = reason;
    if([
      UpsellingPoppingReason.DirectMessages,
      UpsellingPoppingReason.ExtendExpiryTime,
      UpsellingPoppingReason.ChangeMatchLocation
    ].includes(this.upsellingOpeningReason)) {
      this.expandPremiumFeatures = true;
    }
    this.analyticsService.trackEvent({ key: 'upselling_page_viewed', value: 1, source, reason });
  }

  selectPlan(plan: BillingPlan): void {
    this.state.plans = this.state.plans.map(plan => ({ ...plan, selected: false }));
    if(this.config.isPWA) {
      this.selectedPlan = {};
    } else {
      this.selectedPlan = this.state.plans.find(p => p.id == plan.id);
    }
    
    this.selectedPlan.selected = true;
  }

  onTimerFinish(): void {
    logc.crimson("onTimerFinish");
    this.state = this.states.default;
    const { id: selectedId } = this.selectedPlan;
    if(this.state.plans.some(plan => plan.id == selectedId)) {
      this.selectPlan(this.billingPlans.find(({ id }) => id == selectedId));
    } else {
      this.selectPlan(this.billingPlans.find(({ name }) => name == 'Semiannual'));
    }
  }

  async subscribe(): Promise<void> {
    await this.storeService.subscribe(this.selectedPlan.name.toLowerCase()).then(
      _ => {
        this.close();
      },
      error => {
        console.log("Purchase error" , error );
      }
    );
  }

  incrementVisits(): void {
    let visits = this.config.get("upsellingPageVisits") || 0;
    visits += 1;
    this.config.set("upsellingPageVisits", visits);
    this.userService.onUpsellingPageVisited(visits);
  }

  setSpecialOffer() {
    const specialOfferDeadline = this.config.getFlag("limited_time_offer_expires_at");
    
    let starterPlanId = "we3.plus.semiannualy";
    if(specialOfferDeadline && differenceInSeconds(parseISO(specialOfferDeadline), new Date) > 0) {
      this.specialOfferTimer = this.countdownTimerService.createCountdown(parseISO(specialOfferDeadline));
      this.state = this.states.special;
      starterPlanId = "we3.plus.weekly";
    }

    this.selectPlan(this.billingPlans.find(({ planId }) => planId === starterPlanId));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  close(): void {
    this.modalCtrl.dismiss();
  }
}
