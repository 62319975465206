import {
  NavController,
  PopoverController,
  Platform,
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { Camera } from "@ionic-native/camera/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { AppRate } from "@ionic-native/app-rate/ngx";
import {
  ViewChild,
  Component,
  ChangeDetectorRef,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Config } from "../../services/config/config";
import { TribeService } from "../../services/data/tribe.service";
import { TribesService } from "../../services/data/tribes.service";
import { ChatPictureService } from "../../services/in-chat-picture.service";
import { ApiService } from "../../services/data/api.service";
import { UserService } from "../../services/data/user.service";
import { PageWithStatus } from "../../components/page-status/page-with-status";
import { UtilsService } from "../../services/utils.service";
import { SessionService } from "../../services/data/session.service";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { NgZone } from "@angular/core";
import { fromEvent, of, Subject } from "rxjs";
import {
  takeUntil,
  catchError,
  switchMap,
  repeat,
  first,
  delay,
  tap,
} from "rxjs/operators";
import { PageStatus } from "../../components/page-status/page-status";
import { StatusServiceService } from "../../services/status-service.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { CustomActionSheetComponent } from "../../components/custom-action-sheet/custom-action-sheet.component";
import { TribeDetailsPage } from "../tribe-details/tribe-details.page";
import { StatusComponent } from "../../components/status/status.component";
import { PushNotification } from "../../services/push-notification/push-notification";
import { HttpClient } from "@angular/common/http";
import { DispatcherService } from "../../services/dispatcher.service";
import { IconStatusService } from "../../services/icon-status.service";
import { FeedbackService } from "../../services/popups/feedback.service";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { PopupService } from "../../services/popups/popup.service";
import { AlertService } from "../../services/popups/alert.service";
import { AppService } from "../../services/app";
import { MainErrorHandlerService } from "../../services/performance/main-error-handler.service";
import { Storage } from "@ionic/storage";
import { Message } from "../../shared/models/message";
import { CachedPicturesService } from "../../services/data/cached-pictures.service";
import { RouteTrackerService } from "../../services/route-tracker.service";
import { ModalService } from "../../services/popups/modal.service";
import {
  IMAGE_URL_REGEX,
  REPLY_MEDIA_REGEX,
  YOUTUBE_VIDEO_REGEX,
} from "../../shared/constants/regex";
import { TapticService } from "../../services/native/taptic.service";
import { TribeUser } from "../../shared/models/tribe-user";
import { Executed } from "../../shared/decorators/executed";
import {
  CHAT_MESSAGES_POOL_LENGTH,
  ENHANCED_MESSAGE_DELAY,
  FIRST_TIME_TOOLTIP_CLOSING_TIMEOUT,
  MAX_MESSAGE_TIME_DIFFERENCE,
  TRIGGER_AREA_DEEPNESS,
} from "../../shared/constants/constants";
import { SentryService } from "../../services/performance/sentry.service";
import { PerformanceReportService } from "../../services/performance/performance-report.service";

import * as _ from "lodash";
import * as moment from "moment";
import { listFormatter } from "../../shared/helpers/list-formatter";
import { PusherService } from "../../services/pusher.service";
import { logc } from "../../shared/helpers/log";
import { ExperimentsService } from "../../services/experiments.service";
import { AudiencesService } from "../../services/analytics/audiences.service";

import * as Hammer from "hammerjs";
import { TribeDetailsMenuComponent } from "../../components/menu-popover/tribe-details-menu.component";
import { ReportService } from "../../services/report.service";
import { getRandomly, isDev, keys, values } from "../../shared/helpers/helpers";
import { getIntros } from "src/app/shared/constants/intros";
import { OnReturn } from "src/app/shared/hooks/ion-view-did-return";
import { ObserveCalls } from "src/app/shared/decorators/observe-calls";
import { ChatService } from "src/app/services/chat.service";
import { NotificationBadgeInterface } from "src/app/components/notification-badge/notification-badge.component";
import { userExpiresSoon } from "src/app/components/extend-expiry-section/extend-expiry-section.component";
import { UserTribeStatus } from "src/app/shared/enums/user-tribe-status.enum";

const observe = () => {};
@Component({
  selector: "chat-page",
  templateUrl: "chat.page.html",
  styleUrls: ["chat.page.scss"],
})
export class ChatPage extends PageWithStatus {
  @ViewChild("chat", { static: true }) chat: any;
  @ViewChild("text", { static: true }) text: any;
  @ViewChild("textbar", { static: true }) textbar: any;
  @ViewChild(StatusComponent) statusBar: any;
  @ViewChild("guidePopup", { static: true }) guidePopup: ElementRef;
  @ViewChild("gifs") gifs: any;
  @ViewChild("emojis") emojis: any;
  @ViewChild(PageStatus) pageStatus: PageStatus;
  @ViewChild("footer", { static: true }) footer;
  @ViewChild("hint") hint;
  @ViewChild(CustomActionSheetComponent) customSheet;

  public MESSAGE_TO_LOAD: number = 10;
  public messageCleaningRegexes: RegExp[] = [
    YOUTUBE_VIDEO_REGEX,
    IMAGE_URL_REGEX,
  ];

  public extendExpiryMessageContent: NotificationBadgeInterface = {
    icon: {
      name: "timer-outline",
      cssClass: "text-tertiary",
    },
    body: {
      title: "Give them more time?",
      desc: "Extend the expiry by 24 hours. Extend",
    },
  };

  private feedbackQuestion: any = {
    title: "How are you finding the chat?",
    body: "Please rate how smooth the chatting experience has been. ",
    topic: "chat_experience",
  };

  public proposeUsingApp: boolean = false;
  public showFeedbackForm: boolean = true;

  public tribeStatus: any;
  private mouseDown: boolean = false;
  private THREE_HOURS = 10800000;

  public tribeCreatedTime;
  public hideTestButtons: boolean = true;

  public chatTipShown: boolean = true;
  public tribe: any;
  public isPWA: boolean = false;
  public seenHighlightsTooltip: boolean = false;
  public tribeId: number;
  public status: string;
  public swapStatus: boolean;
  public messages: any[] = [];
  public isDev: boolean = false;
  private requestedSwap: boolean = false;

  public showConnectingSpinner: boolean = false;
  public currentMessage: Message = new Message();
  public allUsers: any[];

  public tribemates: any[] = [];
  public title: string = "";
  public currentUser: any;
  public currentUserTribeStatus: any;
  public shouldScroll: boolean = false;
  public emptyPicture: string;
  public gender: string;
  public bot = { id: 0, name: "", picture: "" };
  public initialScrolling: boolean = true;
  public currentUserReplying: boolean = false;
  public usersToShow;
  private privateChannel: any;
  public showHighlightsTip: boolean = false;
  public showStartersTip: boolean = false;
  public hasReplaceableUsers: boolean = false;
  public typingLocked: boolean = false;
  public unreadMessagesCount: number;
  public keyboardHeight = 0;
  public chatWasVisitedOnce: boolean = false;
  public highlightsOpened: boolean = true;

  public shownMessages = []; //takes all messages and sorts them into a presentable array
  public initialPicture = false;
  public firstCreatedGroup: boolean = false;

  public total: number = 0;
  public isOriginalInitiator: boolean = false;

  public lastMessageDate: { id: number; lastMessageTime: string }[] = []; //for the rateApp trigger

  public textVisible: boolean = true;
  public gifsVisible: boolean = false;
  public emojiVisible: boolean = false;
  public showConvoTips: boolean = false;
  public actionRequired = false;

  public replyDragging: boolean = false;
  private messageBuffer: any = null;
  public sliderItem = null;
  private dragMessageRatio: number = 0;

  public whileInPage = new Subject<void>();
  public randomId = Math.floor(Math.random() * 100);

  public isSendingMessages: boolean = false;
  public isKeyboardOpen: boolean = false;
  public intros: any[] = [];
  public questions: any[] = [];

  public keyboardObservable = fromEvent(document, "keyboardShown");
  public tipList: any[] = [
    "First Tribe! hehe 🤗",
    "Hi everyone! 🤓",
    "How's it going, Tribe? 😎",
    "Hello, ladies 😜",
  ];

  public isIos: boolean = false;
  public dataLoaded: boolean = false;

  public someoneTyping: boolean = false;
  public typingTimeout: any = null;

  private membersOnline: any;
  public hasExpiries: boolean = false;
  public anim: any;
  public profileDone: boolean = true;
  public richProfilePopupShown: boolean = true;
  public navigationState = {};
  public typingAnimation: any = {
    path: "./assets/gif/typing.json",
    renderer: "canvas",
    autoplay: true,
    loop: true,
  };

  private cacheLoaded: boolean = false;

  public methodsCalls = {};

  public channel;

  public typingUsersArray: any[] = [];
  public cachedUsers: any;
  public modal: any;
  public actionState: string = "";
  public isDirectMessage: boolean = false;
  public leaveTimeout: any;
  public isScrolledUp: boolean = false;
  public showFirstTribeTooltip: boolean = false;

  public hasUserMessages: boolean = false;
  public hasRealUserMessage: boolean = false;
  public tappedStartersTooltip: boolean = false;

  private justJoined: boolean = false;
  public expiries: any[] = [];

  public removalStatuses = [];
  constructor(
    public navCtrl: NavController,
    private popoverCtrl: PopoverController,
    public config: Config,
    public tribeService: TribeService,
    public tribesService: TribesService,
    public chatPicture: ChatPictureService,
    public api: ApiService,
    public appService: AppService,
    public platform: Platform,
    public analyticsService: AnalyticsService,
    public utils: UtilsService,
    public toastCtrl: ToastController,
    public userService: UserService,
    public cachedPictureService: CachedPicturesService,
    private modalCtrl: ModalController,
    private changeDetector: ChangeDetectorRef,
    private camera: Camera,
    private keyboard: Keyboard,
    private clipboard: Clipboard,
    public alertCtrl: AlertController,
    private vibrationService: TapticService,
    public session: SessionService,
    public statusService: StatusServiceService,
    public ngZone: NgZone = null,
    private route: ActivatedRoute,
    public renderer: Renderer2,
    public push: PushNotification,
    public http: HttpClient,
    private storage: Storage,
    public routeTrackerService: RouteTrackerService,
    public nativeStatusBar: StatusBar,
    private dispatcherService: DispatcherService,
    private iconStatusService: IconStatusService,
    private alertService: AlertService,
    private feedbackService: FeedbackService,
    private sentryService: SentryService,
    private mainErrorHandlerService: MainErrorHandlerService,
    private pusherService: PusherService,
    private chatService: ChatService,
    private audienceService: AudiencesService,
    private reportService: ReportService
  ) {
    super(api, appService, routeTrackerService, sentryService, utils);
    this.debugId = `chat ${this.randomId}`;
    if (this.config.isDev || this.config.isStaging) {
      (<any>window).chatPage = this;
    }

    this.tribeId = parseInt(this.route.snapshot.paramMap.get("id"));
  }

  public typingLabel: string = "";

  countCall(methodName): void {
    if (methodName in this.methodsCalls) {
      this.methodsCalls[methodName] += 1;
    } else {
      this.methodsCalls[methodName] = 1;
    }
  }

  doctor() {
    logc.info("* Method calls: ", this.methodsCalls);
  }

  onReturn() {
    this.countCall("onReturn");
    super.onReturn();
    this.stateMachine?.exec("resume");
  }

  onConnecting(): any {
    this.countCall("onConnecting");
    this.showConnectingSpinner = true;
  }

  onConnected() {
    this.countCall("onConnected");
    console.log("--- chat.page.ts onConnected()", this.tribeId, this.randomId);
    this.showConnectingSpinner = false;
    this.tribeService.openChat(this.tribeId, this.randomId).then(() => {
      this.privateChannel = this.tribeService.channels.find(
        (c) => c.tribeId == this.tribeId
      ).private;
    });
    setTimeout(() => {
      if (!this.tribesService.dataLoaded) {
        this.tribesService.load();
      }
    }, 1000);
  }

  onDisconnected() {
    this.countCall("onDisconnected");
    console.log("*** disconnected fired ***");
    this.privateChannel = null;
    this.tribeService.closeChat(this.tribeId, this.randomId);
  }

  onPause() {
    this.countCall("onPause");
    this.privateChannel = null;
    this.tribeService.closeChat(this.tribeId, this.randomId);
  }

  onMouseUp(event) {
    this.countCall("onMouseUp");
    if (
      this.replyDragging &&
      (this.dragMessageRatio >= 1 || this.dragMessageRatio <= -1)
    ) {
      this.dragMessageRatio = 0;
      this.replyDragging = false;
      this.reply(this.messageBuffer);
      this.sliderItem.close();
      this.vibrationService.mediumVibration();
      this.messageBuffer = null;
      this.sliderItem = null;
    }
  }

  showStatusBar() {
    this.countCall("showStatusBar");
    this.dispatcherService.statusBarChangeSource.next("show");
  }

  hideStatusBar() {
    this.countCall("hideStatusBar");
    this.dispatcherService.statusBarChangeSource.next("hide");
  }

  async ngAfterViewInit() {
    this.countCall("ngAfterViewInit");
    this.proposeUsingApp = this.config.shouldRedirectToDownloadApp();
    this.formReplyTitle = this.formReplyTitle.bind(this);
    this.getImageFor = this.getImageFor.bind(this);
    this.prepareDraftMessage();
  }

  async init() {
    this.countCall("init");
    await this.config.load();

    this.iconStatusService.radius = this.config.get("radius");
    this.isIos = this.platform.is("ios");
    this.isDev = this.config.isDev;

    this.gender = this.config.get("gender");
    this.profileDone = this.config.getFlag("profileProgress") === 100;
    this.chatTipShown = this.config.getFlag("chatTipShown");
    this.richProfilePopupShown = this.config.getFlag("richProfilePopupShown");
    this.seenHighlightsTooltip = this.config.getFlag("seenHighlightsTooltip");

    this.emptyPicture = `./assets/img/default-${
      ["male", "non-binary"].includes(this.gender) ? "" : "female-"
    }avatar.png`;

    this.session.onReady.subscribe((data) => {
      this.actionRequired = this.session.doesOtherTribeRequireAction(
        this.tribeId
      );
    });

    this.shownMessages = [];

    //We only attempt to connect to the chat once we load the data
    //setting isResolvingConnection to true will ignore the Pusher channel status meanwhile

    this.chatService.showHighlightsTipSource
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => {
        this.showHighlightsTip = value;
      });

    this.chatService.showStartersTipSource
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => {
        this.showStartersTip = value;
      });

    this.dispatcherService.onJoinedTribe
      .pipe(
        takeUntil(this.unsubscribe),
        tap((justJoined: boolean | null) => (this.justJoined = justJoined))
      )
      .subscribe();

    this.pusherService.onStateChanged
      .pipe(
        takeUntil(this.unsubscribe),
        tap(({ previous, current }) => {
          if (
            ["disconnected", "unavailable", "connecting"].includes(previous) &&
            current === "connected" &&
            this.isOnline
          ) {
            console.log(
              `** Pusher was off, loading missed data... **, previous: ${previous} -> current: ${current}`
            );
            this.loadData();
          }
        })
      )
      .subscribe();

    this.keyboardObservable
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.ngZone.run(() => {
          if (data["closed"]) {
            this.isSendingMessages = false;
            if (this.platform.is("cordova")) {
              this.keyboardHeight = 0;
            }
            this.isKeyboardOpen = false;
          } else {
            setTimeout((_) => this.resize(), 500);
            this.isKeyboardOpen = true;
            if (this.platform.is("cordova")) {
              if (!this.isSendingMessages) {
                this.keyboardHeight = data["keyboardHeight"];
              } else {
                this.keyboardHeight = 0;
              }
            }
          }
          this.changeDetector.detectChanges();
        });
      });

    this.dispatcherService.onLeavingChat
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async (data) => {
        if (data.tribe_id != this.tribeId) {
          await this.stateMachine.exec("disconnect");
          this.stateMachine = null;
        }
        // console.log('--- chat.page.ts onLeavingChat stateMachine: ', this.stateMachine);
      });

    this.dispatcherService.onFirstTribeCreated
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((show: boolean) => {
        if (this.audienceService.inScheduledGroup()) return;

        // this.showFirstTribeTooltip = show;
      });

    this.tribesService.onTribeChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async (tribe) => {
        if (tribe.id != this.tribe?.id) return;
        this.tribe = {
          ...this.tribe,
          ...tribe,
        };

        this.hasReplaceableUsers = this.tribeService.hasReplaceableUsers(tribe);
        this.expiries = this.tribeService.getExpiries(this.tribe);
        logc.crimson("expiries on change: ", this.expiries);

        if (tribe.tribe_user.status === "removed") {
          await this.navCtrl.navigateBack("tabs/tribes");
          this.utils.showGenericMessage("You've been removed from this tribe");
        }
        // console.log('--- tribesService.onTribeChanged.subscribe( tribe ) ---: ', tribe);
        this.tribeStatus = await this.statusService.getStatus(tribe);
        if (this.tribeStatus) {
          if (!this.tribeStatus.action) {
            this.tribeStatus.action = () => this.toHighlights();
          }
          this.dispatcherService.statusBarChangeSource.next("show");
        }

        if (!_.isEmpty(tribe?.data?.removal_requests)) {
          try {
            this.removalStatuses = await this.statusService.getRemovalStatuses(
              tribe
            );
            this.dispatcherService.statusBarChangeSource.next("show");
          } catch (err) {
            console.log("! Collecting removal requests info error: ", err);
          }
        } else {
          if (_.isEmpty(this.statusService.getStatus(tribe))) {
            this.dispatcherService.statusBarChangeSource.next("hide");
          }
          this.removalStatuses = [];
        }

        await this.prepareIconStatusService(tribe);
        await this.setUsersToShow(tribe);
        if (
          this.tribe &&
          this.tribe.status === "incomplete" &&
          tribe.status === "approving"
        ) {
          this.navCtrl.navigateForward(
            "tribes/" + this.tribeId + "/highlights?from_chat=true"
          );
        }
        if (
          this.requestedSwap &&
          tribe.status === "incomplete" &&
          tribe.users.length === 1
        ) {
          this.utils.showGenericMessage(
            "We're unable to find a good match at this time. Try again later."
          );
        }
      });

    this.dispatcherService.onSwap
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((id) => {
        this.findSwap(id);
      });

    this.tribesService.onTribeLeft
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        clearTimeout(this.leaveTimeout);
        this.navigateBack();
      });

    this.tribeService.onMessageUpdated
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message) => {
        this.updateMessage(message);
      });

    this.tribeService.onMessageReaction
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ emoji, tribe_id, user_id, message_id }) => {
        if (this.tribe.id != tribe_id || this.currentUser.id == user_id) return;

        this.addReaction(emoji).toMessage(message_id).byUser(user_id);
      });

    this.tribeService.onMessageReactionRemoved
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ emoji, tribe_id, user_id, message_id }) => {
        if (this.tribe.id != tribe_id || this.currentUser.id == user_id) return;

        this.removeReaction(emoji).fromMessage(message_id).byUser(user_id);
      });

    this.tribeService.onUserTyping
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((userData) => {
        this.someoneTyping = true;
        this.typingUsersArray.uniqPush(userData);
        this.typingLabel = listFormatter.format(
          this.typingUsersArray.map((u) => u.name)
        );
        clearTimeout(this.typingTimeout);
        this.changeDetector.detectChanges();
        this.typingTimeout = setTimeout(() => {
          this.someoneTyping = false;
          this.typingUsersArray = [];
          this.changeDetector.detectChanges();
        }, 3000);
      });

    this.dispatcherService.onLeavingTribe
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async (id) => {
        await this.tribeService.delete({ tribeId: id });
        this.navCtrl.navigateBack("tabs/tribes");
      });

    this.tribeService.onFirstMessage
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: string) => {
        if (!message) return;

        this.send(message);
        this.tribeService.firstMessageSource.next(null);
      });

    this.chatWasVisitedOnce = this.config.getFlag("chatWasVisitedOnce", false);
    this.highlightsOpened = this.config.getFlag("highlightsOpened", false);
    if (!this.config.getFlag("chatWasVisitedOnce")) {
      this.config.setFlag("chatWasVisitedOnce", true);
    }

    if (
      this.chatWasVisitedOnce &&
      !this.config.get("howToGetInvitedTooltipTapped")
    ) {
      setTimeout(() => (this.showFirstTribeTooltip = true), 1000);
    }

    if (!this.chatWasVisitedOnce && this.audienceService.inScheduledGroup()) {
      this.dispatcherService.openPopup("firstChatVisitPopup");
    }

    this.isPWA = this.config.isPWA;
    this.tribe = await this.tribeService.loadFromCache(this.tribeId);
    if (this.isDirectMessageGroup(this.tribe)) {
      this.isDirectMessage = true;
    }

    if (this.push.ready) {
      this.checkPushPermission();
    } else {
      this.push.onReady.pipe(first()).subscribe((_) => {
        this.checkPushPermission();
      });
    }

    if (!_.isEmpty(this.tribe)) {
      this.initMessages(this.tribe);
      await this.onUpdate();
    }

    this.bot = {
      id: -4,
      name: "Chat bot",
      picture: "./assets/img/chat-bot.png",
    };
  }

  async checkPushPermission() {
    this.countCall("checkPushPermission");
    let threeHoursPassed =
      Date.now() - Date.parse(this.tribe?.tribe_user?.chat_seen_at) >=
      this.THREE_HOURS;
    if (this.config.isPWA) {
      if (
        (threeHoursPassed || !this.tribe?.tribe_user?.chat_seen_at) &&
        !this.config.isExternalLinkSignIn
      ) {
        return this.alertService.openWebPushAlert();
      }
    } else {
      if (!this.push.hasPermission) {
        return await this.alertService.openPushAlert();
      }
    }
  }

  loadData(): Promise<void> {
    this.countCall("loadData");
    logc.aquamarine(`--- chat.page.ts loadData() ${this.debugId}`);
    return new Promise(async (resolve, reject) => {
      await this.cachedPictureService.load();
      this.cachedUsers = this.cachedPictureService.getTribes();
      this.push.removeGroup(`tribe_status_${this.tribeId}`);
      this.push.removeGroup(`tribe_message_${this.tribeId}`);

      this.tribeService.loadRecent(this.tribeId).then(
        async (updateData: any) => {
          this.tribe = updateData.tribe;
          this.checkPushPermission();

          // if(isDev()) {
          //   this.tribe.users[0].extended = true;
          // }

          this.hasReplaceableUsers = this.tribeService.hasReplaceableUsers(
            this.tribe
          );
          this.expiries = this.tribeService.getExpiries(this.tribe);

          logc.crimson("expiries on load: ", this.expiries);

          this.tribesService.onIntrosSuggested
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((intros) => {
              if (
                this.tribe?.messages?.filter((msg) => msg.user_id > 0).length >
                0
              )
                return;
              if (intros.length == 0) return;

              this.tribe.suggested_intros = intros;
              this.tribesService.introsSuggestedSource.next([]);
            });

          this.tribesService.onQuestionsSuggested
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((questions) => {
              if (questions.length == 0) return;
              this.tribe.suggested_questions = questions;
              this.tribesService.questionsSuggestedSource.next([]);
            });

          this.dispatcherService.handleClientEvents({
            key: `chat_${this.tribe.id}`,
            interval: 1000,
          });

          if (this.isDirectMessageGroup(this.tribe)) {
            this.isDirectMessage = true;
          }

          if (updateData.cacheTooOld) {
            //Rebuild everything if cache was too old
            this.shownMessages = [];
            this.initMessages(updateData.tribe);
          } else {
            this.fastForwardMessages(updateData.missedMessages);
          }

          this.unreadMessagesCount = this.tribe.unread_messages_count;
          this.onUpdate();
          logc.info("unreadMessagesCount: ", this.unreadMessagesCount);
          if (!!this.unreadMessagesCount && this.unreadMessagesCount >= 15) {
            this.unreadMessagesCount = this.tribe.unread_messages_count;
            await this.getMoreMessages(this.unreadMessagesCount - 10);
            await this.setUnreadBanner();
            this.dropAndPullChat();
          } else {
            this.scrollToBottom();
            this.initialScrolling = false;
          }

          resolve();
        },
        (err) => {
          console.log("--- loadData() ERROR: ", err);
          if (err.error.user_status === "removed") {
            this.tribesService.tribeRemovedSource.next(this.tribeId);
            this.utils.showGenericMessage(
              "You don't have access to this Tribe"
            );
            this.navigateBack();
          }
          if (err.error.user_status === "replaced") {
            this.tribesService.tribeRemovedSource.next(this.tribeId);
            this.navCtrl.navigateBack("tabs/tribes");
            this.dispatcherService.newPopupSource.next({
              popupName: "invitationExpiredPopup",
            });
          }
          reject(err);
        }
      );
    });
  }

  onDidLoadData(data) {
    this.countCall("onDidLoadData");
    super.onDidLoadData(data);
    this.dataLoaded = true;
  }

  // startChatting() {
  //   setInterval(() => {
  //     this.send('London is the capital of Great Britain');
  //   }, 5000);
  // }

  public newMessageCounter = 0;

  ionViewWillEnter() {
    //Prevent page with status call
    this.countCall("ionViewWillEnter");
    console.log("--- ionViewWillEnter ---", this.randomId);
    this.onPage = true;
    console.log("--- onPage: ", this.onPage);
    document.addEventListener("touchend", this.onMouseUp.bind(this));

    window.addEventListener(
      "native.keyboardshow",
      this.keyboardShowHandler.bind(this)
    );
    window.addEventListener(
      "native.keyboardhide",
      this.keyboardHideHandler.bind(this)
    );

    this.whileInPage = new Subject<void>();
    this.tribeService.onNewMessage
      .pipe(
        takeUntil(this.whileInPage),
        switchMap((message) =>
          of(message).pipe(
            tap((message) => {
              this.onNewMessage(message);
            }),
            catchError((err) => {
              this.mainErrorHandlerService.handleError(err);
              return of(null);
            })
          )
        )
      )
      .subscribe();

    this.dispatcherService.onExpiredTribeLeft
      .pipe(takeUntil(this.whileInPage))
      .subscribe(async (id) => {
        await this.leaveAndTryNewMatch(id);
      });

    this.platform.backButton
      .pipe(takeUntil(this.whileInPage))
      .subscribe((_) => this.onBackButton());

    this.tribeService.onMemberAdded
      .pipe(takeUntil(this.whileInPage))
      .subscribe((member) => {
        if (
          this.shownMessages &&
          this.shownMessages.length &&
          !this.shownMessages?.some((msg) => msg?.seen_by?.length > 0)
        ) {
          for (let i = this.shownMessages.length - 1; i >= 0; i--) {
            if (
              this.shownMessages[i].user_id > 0 &&
              !this.shownMessages[i].seen_by?.some(
                (user) => user.id === member.id
              )
            ) {
              this.shownMessages[i].seen_by.push({
                id: member.id,
                picture: this.tribeMatesPictures[member.id],
              });
              break;
            }
          }
        }
        this.moveReadReceipts();
      });

    this.tribeService.onMembersChecked
      .pipe(takeUntil(this.whileInPage))
      .subscribe((data) => {
        this.membersOnline = data.members || {};
        this.initReadReceipts();
      });
  }

  onDidDisconnect() {
    this.countCall("onDidDisconnect");
    this.stateMachine?.exec("disconnect");
  }

  ionViewWillLeave() {
    this.countCall("ionViewWillLeave");
    this.onPage = false;
    console.log("--- onPage: ", this.onPage);
    console.log(`-- chat ${this.randomId} ionViewWillLeave --`);
    this.renderer.setStyle(this.footer.el, "margin-bottom", "0px");

    this.whileInPage.next();
    this.whileInPage.complete();
    window.removeEventListener("native.keyboardshow", this.keyboardShowHandler);
    window.removeEventListener("native.keyboardhide", this.keyboardHideHandler);
    window.removeEventListener("touchend", this.onMouseUp);
  }

  ionViewDidLeave() {
    this.countCall("ionViewDidLeave");
    console.log(`-- chat ${this.randomId} ionViewDidLeave --`);
  }

  something() {
    console.log("something");
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.stateMachine?.exec("disconnect");
    this.stateMachine = null;
    console.log(`-- chat ${this.randomId} ng on destroy --`);
  }

  showUseApp() {
    this.countCall("showUseApp");
    return this.config.shouldRedirectToDownloadApp();
  }

  prepareIconStatusService(tribe): Promise<void> {
    this.countCall("prepareIconStatusService");
    return new Promise((resolve, reject) => {
      this.iconStatusService.tribeStatus = tribe.status;
      this.iconStatusService.currentUserStatus = tribe.tribe_user.status;
      resolve();
    });
  }

  async findSwap(id) {
    this.countCall("findSwap");
    this.dispatcherService.statusBarChangeSource.next("hide");
    this.requestedSwap = true;
    await this.tribeService.findSwap(id);
  }

  updateMessage(message) {
    this.countCall("updateMessage");
    let messageId = this.shownMessages.findIndex((m) => m.id === message.id);
    if (messageId > 0) {
      this.shownMessages[messageId] = {
        ...this.shownMessages[messageId],
        ...message,
      };
    }
    this.changeDetector.detectChanges();
    this.saveCache();
  }

  async reportUser({ users }) {
    this.countCall("reportUser");
    try {
      await this.reportService.reportUser(this.tribe, users[0]);
      this.utils.showGenericMessage("User Reported");
    } catch (err) {
      console.log(err);
    }
  }

  async leaveTribe() {
    this.countCall("leaveTribe");
    try {
      await this.tribeService.startLeavingFlow(this.tribe, () => {
        this.utils.showGenericMessage(`You left the Chat.`);
        this.navCtrl.navigateBack("tabs/tribes");
      });
    } catch (e) {}
  }

  async leaveAndTryNewMatch(id) {
    this.countCall("leaveAndTryNewMatch");
    await this.tribeService.delete({ tribeId: id });
    await this.config.setFlag("visitedExpiredTribe", false);
    await this.navCtrl.navigateBack("tabs/tribes");
  }

  async openOfflinePopup() {
    this.countCall("openOfflinePopup");
    this.dispatcherService.newPopupSource.next({
      popupName: "offlinePopup",
    });
  }

  handleAnimation(anim: any) {
    this.countCall("handleAnimation");
    this.anim = anim;
    this.anim.setSpeed(0.5);
  }

  getIconStatusName(tribeMate) {
    this.countCall("getIconStatusName");
    return this.iconStatusService.getIconStatusName(tribeMate);
  }

  initReadReceipts() {
    this.countCall("initReadReceipts");
    let members = [...this.tribemates];
    this.shownMessages.forEach((msg) => (msg.seen_by = []));
    for (let i = this.shownMessages.length - 1; i >= 0; i--) {
      if (members.length) {
        for (let mate of members) {
          if (this.shownMessages[i].created_at <= mate.chat_seen_at) {
            this.shownMessages[i].seen_by.push(mate);
          }
        }
        if (this.shownMessages[i].seen_by.length > 0) {
          let ids = this.shownMessages[i].seen_by.slice();
          ids = ids.map((mate) => mate.id);
          members = members.filter((mate) => !ids.includes(mate.id));
        }
        this.moveReadReceipts();
      } else {
        return;
      }
    }
  }

  moveReadReceipts() {
    this.countCall("moveReadReceipts");
    if (!_.isEmpty(this.membersOnline)) {
      let ids = Object.keys(this.membersOnline);
      ids.forEach((id) => {
        this.shownMessages.forEach((msg) => {
          if (
            !!msg.seen_by &&
            msg.seen_by.map((mate) => mate.id)?.some((mateId) => mateId == id)
          ) {
            let mateId = msg.seen_by.findIndex((mate) => mate.id == id);
            msg.seen_by.splice(mateId, 1);
            for (let i = this.shownMessages.length - 1; i >= 0; i--) {
              if (this.shownMessages[i].user_id > 0) {
                this.shownMessages[i].seen_by.push({
                  id: id,
                  picture: this.tribeMatesPictures[id],
                });
                break;
              }
            }
          }
        });
      });
      this.changeDetector.detectChanges();
    }
  }

  async hideChatTip() {
    this.countCall("hideChatTip");
    this.chatService.showHighlightsTipSource.next(false);
    this.highlightsOpened = true;
    if (this.hint) {
      this.renderer.addClass(this.hint.nativeElement, "fade-out-animation");
    }
    setTimeout(() => (this.chatTipShown = true), 1000);
    await this.config.setFlag("highlightsOpened", true);
  }

  toggleConnectingSpinner() {
    this.countCall("toggleConnectingSpinner");
    this.showConnectingSpinner = !this.showConnectingSpinner;
  }

  async openProfile(user) {
    this.countCall("openProfile");
    if (this.currentUser.id === user.id) return;

    if (!this.chatTipShown) {
      this.hideChatTip();
    }

    this.dispatcherService.newModalSource.next({
      modalName: "profile",
      options: {
        isEditable: false,
        userId: user.id,
        profileId: user.profile_id,
        user: user,
        tribeId: this.tribeId,
        replaceable: user?.replaceable,
        tribe: this.tribe,
        fromHighlights: true,
        notificationName: this.iconStatusService.getIconStatusName(user),
      },
    });
  }

  closeConvoTips() {
    this.countCall("closeConvoTips");
    this.showConvoTips = false;
  }

  addConversationTip(tip) {
    this.countCall("addConversationTip");
    this.analyticsService.trackEvent({
      key: "conversation_starter_used",
      value: 1,
    });
    this.text.nativeElement.innerHTML = this.currentMessage.content = tip;
    this.sendMessage();
    this.isSendingMessages = !!this.keyboardHeight;
    this.closeConvoTips();
  }

  //report message section
  reportMessage(message, sender_id, index) {
    //msg_text: string, msg_id: number, sender_id: any){
    this.countCall("reportMessage");
    let reportMessage =
      "User " +
      sender_id +
      " sent: '" +
      message.text +
      "' msg id:(" +
      message.id +
      ")";
    this.reportContent(message, index, reportMessage);
  }

  async navigateBack() {
    this.countCall("navigateBack");
    await this.utils.doneLoading();
    if (await this.modalCtrl.getTop()) {
      await this.modalCtrl.dismiss();
    }
    await this.navCtrl.navigateBack("tabs/tribes");
  }

  reportContent(offensive_message, index, formatted_text) {
    this.countCall("reportContent");
    let holder = this.config.getProfile().offensiveMessageIds;
    if (holder[this.tribeId]) {
      holder[this.tribeId].push(offensive_message.id);
    } else {
      holder[this.tribeId] = [offensive_message.id];
    }

    this.shownMessages.splice(index, 1);
    this.config.updateProfile({ offensiveMessageIds: holder });
    this.userService
      .sendFeedback(
        formatted_text,
        "Offensive Message in Tribe: " + this.tribeId
      )
      .then(
        (_) => {
          this.utils.showGenericMessage("This message has been reported");
        },
        (err) => {
          this.utils.errorContext =
            "chat reportContent, error: " + JSON.stringify(err);
          this.utils.showGenericError({
            key: "chat_send_feedback",
          });
        }
      );
  }

  keyboardShowHandler(e) {
    this.countCall("keyboardShowHandler");
    var event = new CustomEvent("keyboardShown");
    event["keyboardHeight"] = e.keyboardHeight;
    document.dispatchEvent(event);
    this.onKeyboardShow();
  }

  keyboardHideHandler(e) {
    this.countCall("keyboardHideHandler");
    var event = new CustomEvent("keyboardShown");
    event["closed"] = true;
    document.dispatchEvent(event);
    this.onKeyboardHide();
  }

  setTribeAge(tribeCreated: string) {
    //Need date of tribe created
    this.countCall("setTribeAge");
    this.tribeCreatedTime = moment(tribeCreated).fromNow();
  }

  textbarClick() {
    this.countCall("textbarClick");
    this.closeEmojis();
  }

  closeEmojis() {
    this.countCall("closeEmojis");
    this.emojiVisible = false;
    this.resize();
  }

  closeGifs(ev) {
    this.countCall("closeGifs");
    this.gifsVisible = false;
    this.textVisible = true;
    this.gifs.reset();
    this.resize();
  }

  openGifs() {
    this.countCall("openGifs");
    this.closeEmojis();
    this.textVisible = false;
    this.gifsVisible = true;
    setTimeout((_) => {
      this.gifs.init();
    }, 200);
  }

  inputGifs(url) {
    this.countCall("inputGifs");
    this.closeGifs(null);
    if (url) {
      this.sendMedia(url);
    }
  }

  hasMore() {
    this.countCall("hasMore");
    return this.total > this.messagesCount();
  }

  messagesCount() {
    this.countCall("messagesCount");
    let flagMessageCount = this.config.getProfile().offensiveMessageIds[
      this.tribeId
    ]
      ? this.config.getProfile().offensiveMessageIds[this.tribeId].length
      : 0;
    return this.shownMessages.filter((m) => m.id > 0).length + flagMessageCount;
  }

  getMoreMessages = (count = this.MESSAGE_TO_LOAD): Promise<void> => {
    this.countCall("getMoreMessages");
    //**offensiveIds message is an object now */
    //let flagMessageCount = this.config.getProfile().offensiveMessageIds[this.tribeId] ? this.config.getProfile().offensiveMessageIds[this.tribeId].length : 0;
    return new Promise((resolve, reject) => {
      //since 'flag messages' splice the array to remove flag messages it messes with the infinite load, need the count to adjust the proper load
      this.tribeService
        .getMoreMessages(this.tribeId, count, this.messagesCount())
        .then((data: any) => {
          this.ngZone.run(() => {
            this.shownMessages = data.messages
              .reverse()
              .concat(this.shownMessages);
            this.addTimestamps(this.shownMessages);
            setTimeout((_) => {
              resolve();
            }, 100);
          });
        });
    });
  };

  loadingMore = false;
  previousScrollPosition = 0;
  onScroll = async (e) => {
    this.countCall("onScroll");
    if (this.initialScrolling) return;
    if (!e) return;

    const scrollEl = await this.chat.getScrollElement();
    if (!scrollEl) return;

    const page = scrollEl.clientHeight;
    const scrolled = scrollEl.scrollTop;
    const direction = this.previousScrollPosition > scrolled ? "top" : "bottom";
    this.previousScrollPosition = scrolled;
    //trigger infinite when we are at TRIGGER_AREA_DEEPNESS
    // from top and makes sure all messages loaded before loading more
    if (scrolled < page * TRIGGER_AREA_DEEPNESS) {
      this.initialMessagesLoaded = true;
      if (
        !this.loadingMore &&
        direction == "top" &&
        this.hasMore() &&
        this.isOnline
      ) {
        this.ngZone.run(() => {
          this.loadingMore = true;
        });
        const prevHeight = scrollEl.scrollHeight;

        this.getMoreMessages().then((_) => {
          if (!scrollEl) return;

          scrollEl.scrollTop += scrollEl.scrollHeight - prevHeight;

          setTimeout((_) => {
            this.ngZone.run(() => {
              this.loadingMore = false;
            });
          }, 100);
        });
      }
    }

    // Scrolling functionality for when user is scrolled up
    const scrolledPixel = scrollEl.scrollHeight - scrollEl.clientHeight;
    const currentScrollDepth = scrollEl.scrollTop;
    let triggerDepth = scrolledPixel * 0.8;
    this.isScrolledUp = currentScrollDepth <= triggerDepth;
    if (!this.isScrolledUp) {
      this.newMessageCounter = 0;
    }
  };

  insertTextAtCursor = (text) => {
    this.countCall("insertTextAtCursor");
    var sel, range, html;
    if (getSelection) {
      sel = getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        const insertedNode = document.createTextNode(text);
        range.insertNode(insertedNode);
        range.setStartAfter(insertedNode);
        sel.removeAllRanges();
        sel.addRange(range);
        this.text.nativeElement.blur();
      }
    }
  };

  checkRateApp() {
    this.countCall("checkRateApp");
    let timeIntervalCheck = false;
    let fiveMinutes = 300000;
    let timeValues = this.lastMessageDate
      .map((u) => Date.parse(u.lastMessageTime))
      .sort((a, b) => {
        return a - b;
      }); //sorts from smallest to biggest
    if (
      this.lastMessageDate.filter((u) => u.lastMessageTime == null).length == 0
    ) {
      //checks if they all have a value
      timeValues[timeValues.length - 1] - timeValues[0] < fiveMinutes
        ? (timeIntervalCheck = true)
        : (timeIntervalCheck = false); //checks the smallest vs biggest
    }
    let date = new Date();
    let botMessage = {
      content: null,
      createdAt: date.toISOString,
      media: "",
      userId: -4,
    };
    if (
      timeIntervalCheck &&
      !this.config.getFlag("rated_app") &&
      !this.config.getFlag("active_tribe_rate_app_viewed")
    ) {
      this.config.setFlag("active_tribe_rate_app_viewed", true);
      this.analyticsService.trackEvent({
        key: "active_tribe_rate_app_viewed",
        value: 1,
      });
      this.shownMessages.push(botMessage);
    }
  }

  showMessageOptionsForMedia(message, senderId, index, event) {
    this.countCall("showMessageOptionsForMedia");
    console.log("showMessageOptionsForMedia");
    if (this.isImage(event.target)) return;
    try {
      console.log(message, senderId, index, event);
      this.showMessageOptions(message, senderId, index, event);
    } catch (err) {
      console.log(err);
    }
  }

  isImage(node): boolean {
    this.countCall("isImage");
    return node instanceof HTMLImageElement;
  }

  async showMessageOptions(message, senderId, index, event) {
    this.countCall("showMessageOptions");
    if (event) {
      event.preventDefault();
    }
    let props = {
      isChat: true,
      options: [
        {
          icon: "arrow-undo-outline",
          text: "Reply",
          handler: () => this.reply(message),
        },
        {
          icon: "copy-outline",
          text: "Copy",
          handler: () => this.copySelectedMessage(message),
        },
        {
          text: senderId === this.currentUser.id ? "Delete" : "Report",
          icon:
            senderId === this.currentUser.id ? "trash-outline" : "flag-outline",
          class: "warning",
          handler: () =>
            senderId === this.currentUser.id
              ? this.confirmDeletion(message)
              : this.confirmReporting(message, senderId, index),
        },
      ],
    };
    const extraCss = message.content.match(IMAGE_URL_REGEX)
      ? ""
      : `min-height-${props.options.length}`;
    const popover = await this.utils.showOptionsPopup(
      event,
      props,
      `message-options-popover ` + extraCss
    );
    popover.onDidDismiss().then(({ data }) => {
      if (data?.emoji) {
        this.addReaction(data.emoji)
          .toMessage(message.id)
          .byUser(this.currentUser.id);
      }
    });
  }

  async showProfileOptions(message: any, event: any): Promise<void> {
    this.countCall("showProfileOptions");
    const sender = this.tribe.users.find((user) => user.id == message.user_id);
    const [source, tribe] = ["chat", this.tribe];
    await this.tribeService.showProfileOptions({
      sender,
      event,
      source,
      tribe,
    });
  }

  async confirmReporting(message, senderId, index) {
    this.countCall("confirmReporting");
    try {
      const alert = await this.alertCtrl.create({
        header: "Report message?",
        buttons: [
          { text: "Cancel", role: "cancel", cssClass: "cancel-alert-btn" },
          {
            text: "Report",
            handler: () => this.reportMessage(message, senderId, index),
          },
        ],
      });
      await alert.present();
    } catch (err) {
      console.log(err);
    }
  }

  async confirmDeletion(msg) {
    this.countCall("confirmDeletion");
    try {
      const alert = await this.alertCtrl.create({
        header: "Delete message?",
        buttons: [
          { text: "Cancel", role: "cancel", cssClass: "cancel-alert-btn" },
          {
            text: "Delete",
            handler: () => {
              this.deleteMessage(msg);
              this.shownMessages.find((m) => m.id === msg.id).status =
                "deleted";
              this.saveCache();
            },
          },
        ],
      });
      await alert.present();
    } catch (err) {
      console.log(err);
    }
  }

  async deleteMessage(msg) {
    this.countCall("deleteMessage");
    try {
      await this.tribeService.deleteMessage(this.tribeId, msg);
      this.tribeService.channels
        .find((channel) => channel.tribeId == this.tribeId)
        .private.trigger(
          "client-updating-messages",
          { id: msg.id, status: "deleted" },
          ["user"]
        );
    } catch (err) {
      console.log(err);
    }
  }

  saveCache() {
    this.countCall("saveCache");
    this.tribeService.setTribe(this.tribe);
  }

  reply(message: any): void {
    this.countCall("reply");
    this.focusText();
    this.currentUserReplying = true;
    this.currentMessage.reply_to = message;
    this.currentMessage.reply_to_id = message.id;
  }

  formReplyTitle(message) {
    this.countCall("formReplyTitle");
    let messageAuthor =
      this.allUsers.find((u) => u.id === message.user_id) || {};
    let replyAuthor =
      this.allUsers.find((u) => u.id === message.reply_to.user_id) || {};
    let title = `${messageAuthor.first_name} replied to ${
      replyAuthor.id === this.currentUser.id ? "you" : replyAuthor.first_name
    }`;
    if (message.user_id === message.reply_to.user_id) {
      title = `${messageAuthor.first_name} replied to themselves`;
    }
    return title;
  }

  cancelReply() {
    this.countCall("cancelReply");
    this.focusText();
    this.currentUserReplying = false;
    this.currentMessage.reply_to = null;
    this.currentMessage.reply_to_id = null;
  }

  async copySelectedMessage(message) {
    this.countCall("copySelectedMessage");
    try {
      await this.clipboard.copy(message.content);
      await this.utils.showGenericMessage("Message Copied");
    } catch (err) {
      await this.utils.showGenericMessage("Error! while copying message");
    }
  }

  onLoadedMedia() {
    this.countCall("onLoadedMedia");
    if (this.tribe.unread_messages_count < 16 && !this.initialMessagesLoaded) {
      setTimeout(async (_) => {
        this.scrollToBottomSmooth();
      }, 0);
    }
  }

  async prepareDraftMessage() {
    this.countCall("prepareDraftMessage");
    let draftMessage = await this.storage.get(`message-draft-${this.tribeId}`);
    if (!!draftMessage) {
      this.currentMessage.content = draftMessage;
      this.text.nativeElement.innerHTML = draftMessage;
    }
  }

  onNewMessage(message) {
    this.countCall("onNewMessage");
    this.tribe.messages.unshift(message);
    if (!this.dataLoaded) {
      return setTimeout((_) => {
        this.onNewMessage(message);
      }, 1000);
    }

    if (this.showConvoTips) this.showConvoTips = false;

    if (this.lastMessageDate.filter((u) => u.id == message.user_id)[0]) {
      this.lastMessageDate.filter(
        (u) => u.id == message.user_id
      )[0].lastMessageTime = message.created_at;
    }

    if (this.platform.is("cordova")) {
      this.checkRateApp();
    }

    this.ngZone.run(() => {
      if (!this.isScrolledUp) {
        this.scrollToBottomSmooth();
      }
      if (this.isCurrentUser(message)) {
        this.updateLocalMessage(message);
      } else {
        this.appendMessage(message);
      }
      this.hasUserMessages = this.shownMessages.some(
        (m) => m.user_id == this.currentUser.id
      );
      this.hasRealUserMessage = this.shownMessages.some((m) => m.user_id > 0);
      this.moveReadReceipts();
    });
    this.newMessageCounter++;
  }

  updateLocalMessage(message) {
    logc.crimson("updateLocalMessage: ", message);
    this.countCall("updateLocalMessage");
    let messageIndex = this.shownMessages.findIndex(
      (m) => m.id === message.local_id
    );
    this.shownMessages.splice(messageIndex, 1, message);
  }

  onKeyboardShow() {
    this.countCall("onKeyboardShow");
    setTimeout((_) => {
      this.resize();
    });
  }

  onKeyboardHide() {
    this.countCall("onKeyboardHide");
    setTimeout((_) => {
      this.resize();
    });
  }

  trackTribeUsersEvent(ev, ev_data, tribemates = []) {
    this.countCall("trackTribeUsersEvent");
    if (tribemates.length == 0) {
      tribemates = this.tribemates.filter(
        (user) => user.id != this.tribe.tribe_user.id
      );
    }

    let u1 = tribemates[0];
    let u2 = tribemates[1];

    let data: any = {
      key: ev,
      value: 1,
      tribe_id: this.tribeId,
      tribe_status: this.tribe.status,
      common_highlights: this.tribe.highlights_count,
      is_initiator: this.isOriginalInitiator ? 1 : 0,
      chat_unlocked: this.tribe.chat_unlocked ? 1 : 0,
      score: this.tribe.score,
    };

    data = Object.assign(data, ev_data);

    if (u1) {
      data.target_user_id_1 = u1.id;
      data.trust_1 = u1.trust;
      if (u1.proximity) {
        data.proximity_1 = +u1.proximity?.toFixed(2);
      }

      if (u1.proximity == null || u1.trust == null) {
        const tags = {
          klass: "tribe_highlights",
          event: ev,
          func: "trackTribeUsersEvent()",
        };
        const extras = {
          ev_data: ev_data,
          tribe: this.tribe,
          target_user_id_1: u1.id,
          trust_1: u1.trust,
          proximity: u1.proximity,
        };
        this.sentryService.sendMessage(
          "No proximity or trust in chat",
          tags,
          extras
        );
      }
    }

    if (u2) {
      data.target_user_id_2 = u2.id;
      data.trust_2 = u2.trust;
      if (u2.proximity) {
        data.proximity_2 = +u2.proximity?.toFixed(2);
      }

      if (u2.proximity == null || u2.trust == null) {
        const tags = {
          klass: "tribe_highlights",
          event: ev,
          func: "trackTribeUsersEvent()",
        };
        const extras = {
          ev_data: ev_data,
          tribe: this.tribe,
          target_user_id_2: u2.id,
          trust_2: u2.trust,
          proximity: u2.proximity,
        };
        this.sentryService.sendMessage(
          "No proximity or trust in chat",
          tags,
          extras
        );
      }
    }

    this.analyticsService.trackEvent(data);
  }

  tribeMatesPictures;
  chatViewedEventSent: boolean = false;
  unreadShown: boolean = false;
  @Executed()
  async onUpdate() {
    this.countCall("onUpdate");
    try {
      let tribe = this.tribe;

      if (tribe?.data?.removal_requests) {
        this.removalStatuses = await this.statusService.getRemovalStatuses(
          tribe
        );
        this.dispatcherService.statusBarChangeSource.next("show");
      } else {
        this.removalStatuses = [];
      }

      if (tribe?.tribe_user?.status === "replaced") {
        await this.navCtrl.navigateBack("tabs/tribes");
        await this.utils.showGenericMessage(
          "You don't have access to this Tribe"
        );
        this.dispatcherService.newPopupSource.next({
          popupName: "invitationExpiredPopup",
        });
      }

      this.tribeStatus = await this.statusService.getStatus(tribe);
      if (this.tribeStatus) {
        if (!this.tribeStatus.action) {
          this.tribeStatus.action = () => this.toHighlights();
        }
        this.dispatcherService.statusBarChangeSource.next("show");
      }

      if (tribe?.tribe_user?.status === "removed") {
        this.tribesService.tribeRemovedSource.next(tribe.id);
        this.utils.showGenericMessage("You don't have access to this Tribe");
        this.navCtrl.navigateBack("tabs/tribes");
      }

      this.dispatcherService.chatMessagesCounterSource.next({
        id: tribe?.id || 0,
      });

      this.allUsers = [...this.tribe.users, this.tribe.tribe_user];

      this.total = tribe.total_messages;

      this.status = tribe.status;
      this.currentUser = this.config.getProfile() || {};
      this.swapStatus = tribe.swap_automatically;
      this.isOriginalInitiator = tribe.is_initiator;
      this.tribemates = tribe.users;

      this.tribeMatesPictures = {};
      for (let tribeMate of this.tribemates) {
        this.tribeMatesPictures[tribeMate.id] = tribeMate.picture || "";
      }

      this.currentUserTribeStatus = tribe.tribe_user.status;

      this.lastMessageDate.length == 0
        ? this.tribemates.map((u) =>
            this.lastMessageDate.push({
              id: u.id,
              lastMessageTime: null,
            })
          )
        : null;

      await this.prepareIconStatusService(this.tribe);
      await this.setUsersToShow(this.tribe);
      if (this.isDirectMessage) {
        this.title = this.usersToShow[0]?.first_name || "Just you";
      } else {
        this.title =
          this.usersToShow.map((u) => u?.first_name).join(" & ") || "Just you";
      }
      this.initialPicture = this.total <= this.MESSAGE_TO_LOAD;
      this.setTribeAge(tribe.started_at);
      this.showConvoTips =
        this.shownMessages.filter((message) => message.user_id != -999)
          .length == 0;

      if (this.showConvoTips) {
        // this.loadConvoTips(tribe);
      }
      this.resize(); //needed to correct the initial chat glitch
      this.initReadReceipts();

      if (!this.chatViewedEventSent) {
        this.trackTribeUsersEvent("chat_viewed", {});
        this.chatViewedEventSent = true;
      }
    } catch (err) {
      console.log(err);
    }
    this.cacheLoaded = true;
  }

  openInfoModal(modalName) {
    this.countCall("openInfoModal");
    setTimeout(
      () => this.closeFirstVisitTooltip(),
      FIRST_TIME_TOOLTIP_CLOSING_TIMEOUT
    );

    let options: any = {
      type: modalName,
    };

    if (modalName === "joiningConditions") {
      this.config.set("howToGetInvitedTooltipTapped", true);
    }

    if (
      modalName === "joiningConditions" &&
      !this.config.getFlag("joinGroupsFreeShownInChat") &&
      !this.audienceService.inScheduledGroup()
    ) {
      options.closingCallback = () => {
        setTimeout(() => {
          this.chatService.showHighlightsTipSource.next(true);
          this.chatService.showStartersTipSource.next(true);
          // this.dispatcherService.newPopupSource.next({ popupName: "richProfilePopup" });
        }, 1000);
      };
      this.config.setFlag("joinGroupsFreeShownInChat", true);
    }

    this.dispatcherService.newInfoModalSource.next(options);
  }

  closeFirstVisitTooltip() {
    this.countCall("closeFirstVisitTooltip");
    this.showFirstTribeTooltip = false;
    this.config.set("howToGetInvitedTooltipTapped", true);
    // this.dispatcherService.firstTribeCreatedSource.next(false);
  }

  isCurrentUser(message): boolean {
    this.countCall("isCurrentUser");
    return message.user_id === this.currentUser?.id;
  }

  setUnreadBanner(): Promise<void> {
    this.countCall("setUnreadBanner");
    return new Promise((resolve, reject) => {
      let seen_at = this.tribe.tribe_user.chat_seen_at;
      let unreadMessagesInsertId = this.shownMessages.findIndex(
        (m) => m.created_at > seen_at
      );
      if (unreadMessagesInsertId > 0 && !this.unreadShown) {
        this.unreadShown = true;
        this.shownMessages.splice(unreadMessagesInsertId, 0, {
          user_id: -42,
          unreadMessageCount: this.tribe.unread_messages_count,
          content: [],
        });
      }
      this.initialScrolling = false;
      resolve();
    });
  }

  loadConvoTips(tribe) {
    this.countCall("loadConvoTips");
    let allTips = [];
    if (tribe.conversation_starters.length >= 5) {
      allTips = tribe.conversation_starters;
    } else {
      allTips = this.tipList.concat(tribe.conversation_starters);
    }
    this.tipList = allTips
      .sort((_) => {
        return 0.5 - Math.random();
      })
      .slice(0, 20);
  }

  setUsersToShow(tribe): Promise<void> {
    this.countCall("setUsersToShow");
    const users = tribe.users?.filter((u) => u?.id != this.currentUser?.id);
    return new Promise((resolve, reject) => {
      this.usersToShow =
        users.length === 1 ? [...users, tribe.tribe_user] : users;
      resolve();
    });
  }

  onBackButton() {
    this.countCall("onBackButton");
    if (this.gifsVisible) {
      this.closeGifs(null);
    } else if (this.emojiVisible) {
      this.closeEmojis();
    } else {
      this.navCtrl.pop();
    }
  }

  async dropAndPullChat() {
    this.countCall("dropAndPullChat");
    this.chat.scrollToBottom();
    await this.chat.scrollToPoint(0, 100, 0);
  }

  scrollToBottom(duration = 0) {
    this.countCall("scrollToBottom");
    try {
      this.chat.scrollToBottom(duration);
    } catch (err) {
      setTimeout((_) => {
        this.scrollToBottom(duration);
      }, 200);
    }
  }

  scrollToBottomSmooth() {
    this.countCall("scrollToBottomSmooth");
    setTimeout(async (_) => {
      try {
        if (this.chat) {
          const scrollEl = await this.chat.getScrollElement();
          if (!scrollEl) return;
          scrollEl.scrollTo({
            top: scrollEl.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }, 100);
  }

  showEnhancedMessage(preparedMessages) {
    this.countCall("showEnhancedMessage");
    if (preparedMessages.length) {
      const lastUserMessageInfo = preparedMessages.findLastElem(
        (m) => m.user_id > 0
      );
      preparedMessages.splice(lastUserMessageInfo.id, 1);
      const lastMessage = lastUserMessageInfo.value;
      this.shownMessages = preparedMessages;
      setTimeout(() => {
        this.appendMessage(lastMessage);
        this.tribesService.userNewMessageSource.next(null);
      }, ENHANCED_MESSAGE_DELAY);
    }
  }

  initialMessagesLoaded: boolean = false;
  initMessages({ messages, tribe_user: currentUser }): void {
    this.countCall("initMessages");
    logc.info("--- init messages ---");
    if (messages) {
      logc.info("chat messages count: ", messages?.length);
    }

    const isForOthers = (msg) =>
      !msg.meta_data?.target_user_ids?.length ||
      msg.meta_data?.target_user_ids?.includes(currentUser.id);

    let preparedMessages = [...messages.filter(isForOthers).reverse()];
    // logc.info("Chat messages: ", preparedMessages);
    if (this.justJoined && this.tribeService.isU2(this.tribe)) {
      this.dispatcherService.joinedTribeSource.next(null);
      this.showEnhancedMessage(preparedMessages);
    } else {
      this.shownMessages = preparedMessages;
    }

    // let botMessage = this.shownMessages.last();
    // botMessage.meta_data = _.merge(botMessage.meta_data, {
    //   custom_actions: [{
    //     key: "start_new_group",
    //     text: "Start Direct messages",
    //     payload: { u1_id: 55076, u2_id:  57269 }
    //   }, {
    //     key: "dismiss_new_group_proposal",
    //     text: "No thanks",
    //     payload: {}
    //   }],
    // })

    this.hasUserMessages = this.shownMessages.some(
      (message) => message?.user_id == currentUser?.id
    );
    this.hasRealUserMessage = this.shownMessages.some((m) => m.user_id > 0);

    if (this.isDirectMessageGroup(this.tribe)) {
      this.addInitialMessage(this.shownMessages[0]);
    }

    this.addTimestamps(this.shownMessages);
    this.prepareReactions(this.shownMessages);
  }

  performCustomMessageAction({ key, payload }, message): void {
    this.countCall("performCustomMessageAction");
    const customActionsLib: any = {
      start_new_group: {
        aftermathText: "Direct chat opened by you",
        handler: (payload, message) => {
          const params = {
            other_user_id: values(payload).filter(
              (id) => id != this.currentUser.id
            )[0],
          };
          const tribeId = this.tribe.id;
          const messageId = message.id;
          logc.info("Start new group data: ", tribeId, messageId, params);
          this.tribesService
            .startNewGroup(tribeId, messageId, params)
            .then(() => logc.success("New group is created!"));
        },
      },
      dismiss_new_group_proposal: {
        aftermathText: "Suggestion dismissed",
        handler: (payload, message) => {
          this.tribesService.dismissNewGroupProposal(this.tribe.id, message.id);
        },
      },
    };
    console.log("key: ", key);
    console.log("payload: ", payload);
    console.log("message: ", message);

    customActionsLib[key].handler(payload, message);
    message.meta_data.action_taken = customActionsLib[key].aftermathText;
    console.log(`${key} handler executed`);
    console.log("updated message: ", message);
  }

  openMeetupPage() {
    this.countCall("openMeetupPage");
    this.stateMachine?.exec("pause");
    this.navCtrl.navigateForward(
      `tribes/${this.tribe.id}/meetup?from_chat=true`,
      { state: { tribe: this.tribe } }
    );
  }

  navigate(path: string): void {
    this.countCall("navigate");
    this.stateMachine?.exec("pause");
    this.navCtrl.navigateForward(path, { state: { tribe: this.tribe } });
  }

  private prepareReactions(messages) {
    this.countCall("prepareReactions");
    messages.map((message) => {
      if (!message?.meta_data?.reactions) return message;

      message.reactions = Object.entries(message.meta_data.reactions)
        .map(([id, emoji]) => ({ emoji, id }))
        .reduce((acc, reaction) => {
          let existingReaction = acc.find((e) => e.emoji == reaction.emoji);
          if (!existingReaction) {
            acc.push({ emoji: reaction.emoji, usersIds: [+reaction.id] });
          } else {
            existingReaction.usersIds.push(+reaction.id);
          }
          return acc;
        }, []);
      return message;
    });
  }

  addReaction(emoji) {
    this.countCall("addReaction");
    return {
      toMessage: (messageId) => ({
        byUser: (userId) => this._addReaction(emoji, messageId, userId),
      }),
    };
  }

  removeReaction(emoji) {
    this.countCall("removeReaction");
    return {
      fromMessage: (messageId) => ({
        byUser: (userId) => this._removeReaction(emoji, messageId, userId),
      }),
    };
  }

  private async _addReaction(emoji, messageId, userId) {
    this.countCall("_addReaction");
    let message = this.shownMessages.find((msg) => msg.id == messageId);
    if (!message.reactions) {
      message.reactions = [];
    }
    if (!message.reactions.some((r) => r.emoji == emoji)) {
      message.reactions.push({ emoji, usersIds: [] });
    }
    let newReaction = message.reactions.find((r) => r.emoji == emoji);
    if (newReaction.usersIds.includes(userId)) {
      // If this message has this emoji by this user - we're just removing it.
      await this.removeReaction(emoji).fromMessage(messageId).byUser(userId);
    } else {
      const oldReaction = message.reactions.find((r) =>
        r.usersIds.includes(userId)
      );
      if (oldReaction) {
        // If this message has different emoji by this user - remove current emoji
        await this.removeReaction(oldReaction.emoji)
          .fromMessage(messageId)
          .byUser(userId);
      }
      newReaction.usersIds.push(userId);
      message.reactions.sort((a, b) => a.usersIds.length - b.usersIds.length);

      if (userId == this.currentUser.id) {
        await this.api
          .post(`tribes/${this.tribe.id}/messages/${messageId}/reactions`, {
            tribe_id: this.tribe.id,
            message_id: messageId,
            emoji,
          })
          .then(() => {
            this.analyticsService.trackEvent({
              key: "reaction_sent",
              value: 1,
              tribeId: this.tribe.id,
            });
          });
      }

      let thisMessage = this.tribe.messages.find((msg) => msg.id == messageId);
      if (thisMessage.meta_data.reactions) {
        thisMessage.meta_data.reactions[userId] = emoji;
      } else {
        thisMessage.meta_data.reactions = { [userId]: emoji };
      }

      this.saveCache();
    }
  }

  _removeReaction(emoji, messageId, userId, callApi = true): Promise<void> {
    this.countCall("_removeReaction");
    return new Promise(async (resolve, reject) => {
      const message = this.shownMessages.find((msg) => msg.id == messageId);
      // logc.coral("message: ", message);
      let reaction = message.reactions.find((r) => r.usersIds.includes(userId));
      // logc.coral("reaction: ", reaction);
      const userIndex = reaction.usersIds.findIndex((id) => id == userId);

      reaction.usersIds.splice(userIndex, 1);

      if (reaction.usersIds.length == 0) {
        const reactionIndex = message.reactions.findIndex(
          (r) => r.emoji == reaction.emoji
        );
        message.reactions.splice(reactionIndex, 1);
      }

      if (userId == this.currentUser.id) {
        await this.api.delete(
          `tribes/${this.tribe.id}/messages/${messageId}/reactions`,
          {
            tribe_id: this.tribe.id,
            message_id: messageId,
            emoji,
          }
        );
      }
      delete this.tribe.messages.find((msg) => msg.id == messageId).meta_data
        .reactions[userId];
      this.saveCache();
      resolve();
    });
  }

  isDirectMessageGroup(tribe) {
    this.countCall("isDirectMessageGroup");
    return tribe?.type && tribe?.type == "DirectMessage";
  }

  isCurrentUserMessage(message): boolean {
    return message.user_id == this.currentUser.id;
  }

  isOtherUserMessage(message): boolean {
    this.countCall("isOtherUserMessage");

    if (message.user_id !== this.currentUser.id) {
      if (message.user_id > -1) return true;
      if (this.isSystemMessage(message)) {
        if (!message?.meta_data?.target_user_ids?.length) return true;

        return this.isTargetedSystemMessage(message);
      }
    }

    return false;
  }

  isOutsideRadius(tribeMate) {
    return function (tribeMate) {
      ["invited", "needs_review"].includes(this.tribe?.tribe_user?.status) &&
        this.iconStatusService.isOutsideMaxDistance(
          tribeMate,
          this.config.get("matchPreferences").location
        );
    }.bind(this);
  }

  isSystemMessage(message): boolean {
    this.countCall("isSystemMessage");
    return message.user_id == -5;
  }

  isTargetedSystemMessage({ id }) {
    return function (message) {
      return message.meta_data?.target_user_ids?.includes(id);
    }.bind(this);
  }

  isBotMessage(message) {
    this.countCall("isBotMessage");
    return message.user_id !== this.currentUser.id && message.user_id == -5;
  }

  addTimestamps(messages) {
    this.countCall("addTimestamps");
    for (let i = 1; i < messages.length; i++) {
      let diff =
        Date.parse(messages[i].created_at) -
        Date.parse(messages[i - 1].created_at);
      let diffCurrentDate = Date.now() - Date.parse(messages[i].created_at);
      if (diff > MAX_MESSAGE_TIME_DIFFERENCE) {
        this.shownMessages.splice(
          this.shownMessages.findIndex((m) => m.id === messages[i].id),
          0,
          {
            user_id: diffCurrentDate > 86400000 ? -2 : -1,
            createdAt: messages[i].created_at,
            content: [],
            media: "",
          }
        );
      }
    }
  }

  addInitialMessage(messages) {
    this.countCall("addInitialMessage");
    this.shownMessages.unshift({
      user_id: this.tribe.is_initiator ? -4 : -3,
      createdAt: messages ? messages.created_at : Date.now(),
      content: [],
      media: "",
    });
  }

  fastForwardMessages(messages) {
    this.countCall("fastForwardMessages");
    console.log("--- fast forward messages... ---");
    this.shownMessages = this.shownMessages.filter((m) => !m.is_local);
    // logc.info("Messages: ", this.shownMessages);
    this.prepareReactions(this.shownMessages);
    this.shouldScroll = false;
    messages
      .slice()
      .reverse()
      .forEach((m) => {
        this.appendMessage(m);
      });
    this.shouldScroll = true;
  }

  appendMessage(message) {
    this.countCall("appendMessage");
    if (!message) return;
    console.log("--- chat.page.ts appendMessage() message: ", message);
    message.seen_by = [];
    if (!this.currentUser) {
      this.currentUser = this.config.getProfile() || {};
    }
    if (message.user_id != this.currentUser.id) {
      this.someoneTyping = false;
    }

    if (this.shownMessages.length) {
      let diff =
        Date.parse(message.created_at) -
        Date.parse(
          this.shownMessages[this.shownMessages.length - 1].created_at
        );
      if (diff > MAX_MESSAGE_TIME_DIFFERENCE) {
        this.shownMessages.push({
          user_id: -1,
          createdAt: message.created_at,
          content: [],
          media: "",
        });
      }
    }

    this.shownMessages.uniqPush(message);

    if (this.shownMessages.length >= CHAT_MESSAGES_POOL_LENGTH) {
      this.shownMessages.shift();
    }

    if (this.isCurrentUser(message)) {
      this.currentMessage = new Message();
      let messageText = this.text.nativeElement.innerHTML;
      if (messageText) {
        this.currentMessage.content = messageText;
      }
    }

    this.scrollToBottomSmooth();
    this.moveReadReceipts();
  }

  getTribemate(id: number): any {
    this.countCall("getTribemate");
    return this.tribemates.find((mate) => mate.id == id) || {};
  }

  sendMedia(url) {
    this.countCall("sendMedia");
    this.send(url);
  }

  async sendMessage() {
    this.countCall("sendMessage");
    this.focusText();
    await this.storage.set(`message-draft-${this.tribeId}`, "");

    if (this.currentMessage.content === "") return;
    this.send(this.currentMessage.content.trim());
    this.text.nativeElement.innerHTML = "";
    this.currentMessage.content = "";
  }

  async send(text: string) {
    this.countCall("send");
    if (!text) return;

    this.emojis.saveRecentEmojis();
    if (this.emojiVisible) this.closeEmojis();

    const completeMessage = this.prepareMessage(text);

    this.currentUserReplying = false;
    await this.appendMessage(completeMessage);

    this.tribeService.send(this.tribeId, completeMessage).then((_) => {
      this.analyticsService.trackEvent({
        key: "message_sent",
        value: 1,
        tribe_id: this.tribeId,
        is_initiator: this.tribe?.is_initiator,
      });
    });

    if (
      this.userMessageCount() == 10 &&
      !this.config.getFlag("chat_experience_asked_10")
    ) {
      this.keyboard.hide();
      this.askQuestion();
      this.config.setFlag("chat_experience_asked_10", true);
    }

    if (
      this.userMessageCount() == 250 &&
      !this.config.getFlag("chat_experience_asked_250")
    ) {
      this.keyboard.hide();
      this.askQuestion();
      this.config.setFlag("chat_experience_asked_250", true);
    }
  }

  prepareMessage(text: string) {
    this.countCall("prepareMessage");
    let completeMessage: any = {
      id: Math.floor(Math.random() * 1000),
      is_local: true,
      created_at: new Date(),
      content: text,
      user_id: this.config.getProfile().id,
      seen_by: [],
    };

    if (this.currentMessage.reply_to_id) {
      completeMessage = {
        ...completeMessage,
        reply_to: this.currentMessage.reply_to,
        reply_to_id: this.currentMessage.reply_to_id,
      };
    }
    return completeMessage;
  }

  async askQuestion() {
    this.countCall("askQuestion");
    await this.feedbackService.createQuestion(this.feedbackQuestion);
  }

  userMessageCount() {
    return this.shownMessages.filter((m) => m.user_id === this.currentUser.id)
      .length;
  }

  replyContainsMedia(text: string): boolean {
    // this.countCall("replyContainsMedia");
    return !!text.match(REPLY_MEDIA_REGEX);
  }

  onMessageDrag(event, message, slidingItem) {
    this.countCall("onMessageDrag");
    if (message.status === "deleted") {
      return slidingItem.close();
    }

    this.replyDragging = true;
    if (!this.messageBuffer) {
      this.messageBuffer = message;
    }
    if (!this.sliderItem) {
      this.sliderItem = slidingItem;
    }
    this.dragMessageRatio = event.detail.ratio;
  }

  processMessage(message: string): any {
    this.countCall("processMessage");
    let newMessage = message;
    if (message.match(/gif|img/g)) {
      newMessage = `<img class = "media" src = "${message}">`;
    }
    return newMessage;
  }

  async toHighlights() {
    this.countCall("toHighlights");
    this.hideChatTip();
    let navigationExtras: NavigationExtras = { state: { fromChat: true } };
    this.stateMachine?.exec("pause");
    await this.navCtrl.navigateForward(
      `/tribes/${this.tribeId}/highlights?from_chat=true`,
      navigationExtras
    );
  }

  async uploadPicture(source: string) {
    this.countCall("uploadPicture");
    let key = this.utils.getRandomPictureId();

    if (this.isPWA) {
      let url = await this.chatPicture.takePWAPicture(key);
      this.sendMedia(url);
      return;
    }

    if (!this.isOnline) {
      this.utils.showGenericError({
        msg: "Please connect to the Internet first",
        key: "chat_saving_picture_offline",
      });
    } else {
      if (this.currentUser) {
        if (source == "camera") {
          this.chatPicture
            .takePicture(this.camera.PictureSourceType.CAMERA, key)
            .then(
              (url) => {
                if (url) {
                  //has to save the image into a seperate message under here
                  this.sendMedia(url);
                }
                this.nativeStatusBar.hide();
                this.nativeStatusBar.show();
              },
              (err) => {
                this.nativeStatusBar.hide();
                this.nativeStatusBar.show();
                this.utils.errorContext =
                  "chat takePicture, error: " + JSON.stringify(err);
                this.utils.showGenericError({
                  key: "chat_saving_picture",
                });
              }
            );
        } else if (source == "gallery") {
          this.chatPicture
            .takePicture(this.camera.PictureSourceType.PHOTOLIBRARY, key)
            .then(
              (url) => {
                if (url) {
                  this.sendMedia(url);
                }
                this.nativeStatusBar.hide();
                this.nativeStatusBar.show();
              },
              (err) => {
                this.nativeStatusBar.show();
                this.utils.errorContext =
                  "chat photo library, error: " + JSON.stringify(err);
                this.utils.showGenericError({
                  key: "chat_opening_photo_gallery",
                });
              }
            );
        }
      } else {
        this.utils.errorContext = "chat no current user.";
        this.utils.showGenericError({});
      }
    }
  }

  /* Following section handles all additional chat input forms(Emoji, Gifs)*/
  inputEmoji(emoji) {
    this.countCall("inputEmoji");
    if (getSelection().anchorNode) {
      this.insertTextAtCursor(emoji.u);
    } else {
      this.text.nativeElement.insertAdjacentText("beforeEnd", emoji.u);
    }

    this.currentMessage.content = this.text.nativeElement.innerHTML;
  }

  focusText() {
    this.countCall("focusText");
    this.text.nativeElement.focus();
  }

  resize(duration = 0) {
    this.countCall("resize");
    setTimeout(() => this.scrollToBottom(duration), 0);
  }

  onTextChange(e) {
    this.countCall("onTextChange");
    this.currentMessage.content = e.target.innerText;
    this.currentMessage.content.replace(/(\r\n|\n|\r)/gm, "<br />");
  }

  t;
  draftTimeout;
  onKeyDown(e) {
    this.countCall("onKeyDown");
    if (!this.typingLocked && this.privateChannel) {
      this.typingLocked = true;
      let userData: { id: number; name: string } = {
        id: this.currentUser.id,
        name: this.currentUser.firstName,
      };
      this.privateChannel?.trigger("client-typing", userData, ["user"]);
      setTimeout(() => {
        this.typingLocked = false;
      }, 1500);
    }
    clearTimeout(this.draftTimeout);
    this.draftTimeout = setTimeout(async () => {
      await this.storage.set(
        `message-draft-${this.tribeId}`,
        this.text.nativeElement.innerHTML
      );
    }, 1000);

    // trap the return key being pressed
    if (e.keyCode === 13) {
      // insert 2 br tags (if only one br tag is inserted the cursor won't go to the next line)
      document.execCommand("insertHTML", false, "<br><br>");
      // prevent the default behaviour of return key pressed
      return false;
    }
  }

  //toggle between normal keyboard and emojis keyboard
  toggleEmojis(ev) {
    this.countCall("toggleEmojis");
    ev.stopPropagation();

    if (this.emojiVisible) {
      this.emojiVisible = false;
    } else {
      this.keyboard.hide();
      this.emojiVisible = true;
      this.emojis.init();
    }
    this.resize();
  }

  async showDetails(event) {
    this.countCall("showDetails");
    try {
      const popover = await this.popoverCtrl.create({
        component: TribeDetailsMenuComponent,
        componentProps: {
          tribe: this.tribe,
        },
        event,
      });
      await popover.present();
    } catch (e) {
      logc.error("Popover error: ", e);
    }
  }

  getImageFor(userId) {
    this.countCall("getImageFor");
    if (userId === -5) return this.bot.picture;
    if (userId == this.config.get("id")) return this.config.getPicture();

    let tribemate = this.getTribemate(userId);
    let defaultPicture = tribemate ? tribemate.picture : this.emptyPicture;

    return this.getUserCachedPicture(userId) || defaultPicture;
  }

  getUserCachedPicture(userId) {
    this.countCall("getUserCachedPicture");
    return this.cachedUsers
      ?.find((t) => t?.id == this.tribeId)
      ?.users.find((u) => u?.id == userId)?.cachedPicture;
  }

  getExpirationColors(user) {
    this.countCall("getExpirationColors");
    return this.utils.getExpirationColors(user);
  }

  hideStartersTooltip(): void {
    this.chatService.showStartersTipSource.next(false);
  }

  showStarters(tribe) {
    this.countCall("showStarters");
    const intros = tribe?.suggested_intros;
    const openEndedQuestions = tribe?.suggested_questions;
    let type, answers;
    if (intros && intros?.length) {
      answers = intros;
      type = "intros";
    }

    if (openEndedQuestions && openEndedQuestions?.length) {
      answers = openEndedQuestions;
      type = "starters";
    }

    answers = (answers || []).map((tip) => ({ text: tip }));
    this.tappedStartersTooltip = true;
    this.hideStartersTooltip();

    const starters = {
      analytics: {
        type,
        tribe_id: this.tribe.id,
        tribe_user_id: this.tribe?.tribe_user?.tribe_user_id,
      },
      body: [
        {
          initial: true,
          title: "Here are some ideas",
          subtitle: "You can edit before sending",
          type: "radio",
          answers,
        },
      ],
    };
    this.feedbackService.createFeedbackModal(
      starters,
      {
        next: (starter) => {
          const answer = starter?.analytics?.answer;
          this.currentMessage.content = answer;
          this.text.nativeElement.innerHTML = answer;
        },
        close: () => {},
      },
      { initialBreakpoint: 0.66 }
    );
  }
}
