import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PageStatus } from './page-status';
import { ChatStatusBarModule } from "../chat-status-bar/chat-status-bar.module";

@NgModule({
  declarations: [
    PageStatus,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ChatStatusBarModule
  ],
  exports: [
    PageStatus
  ],
  schemas:[ ]
})
export class PageStatusModule {}
