import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {RemoteConfigService} from "../../services/remote-config.service";
import {UserService} from "../../services/data/user.service";

@Component({
  selector: 'app-zoom-box',
  template: `
    
    <ion-content>
      <div class = "content">
        <div class = "close-section">
          <ion-icon class = "close-button" (click) = "close()" name = "close-circle"></ion-icon>
        </div>
        
        <div class = "header bold huge-text">
          We’re excited to talk with you in a moment
        </div>
  
        <div class = "paragraph base-text">
          Together, we’ll set up your account and answer any questions you may have.
        </div>
        <div class = "paragraph base-text">
          This call will also help us learn how to make We3 easier to use, so you may need to share your screen. We can help with this too.
        </div>
        <div class = "paragraph base-text">
          If you don’t have Zoom installed on this mobile device, please install it first.
        </div>
        <div class = "buttons-container">
          <ion-button class = "ionic-button turquoise-gradient-btn next-button medium-button"
                      (click) = "joinCall()"
                      shape = "round">Join the Zoom call</ion-button>
        </div>
      </div>
      
    </ion-content>
    
  `,
  styleUrls: ['./zoom-box.component.scss'],
})
export class ZoomBoxComponent implements OnInit {

  public zoomLink: string = "https://zoom.us/j/2979169837?pwd=UjFRaklqMXdvNzQ4bkpuRng3c1ZrUT09";

  constructor(private modalCtrl: ModalController,
              private remoteConfigService: RemoteConfigService,
              private userService: UserService) { }


  async ngOnInit() {
    await this.remoteConfigService.load();
    const remoteZoomLink = await this.remoteConfigService.getString("zoom_link");
    if(remoteZoomLink) {
      this.zoomLink = remoteZoomLink;
    }
  }

  async joinCall() {
    try {
      await this.userService.requestUsabilityTest();
      window.open(this.zoomLink);
    } catch ( err ) {
      console.log("-- error joining call: ", err);
    }
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

}
