import { Injectable } from '@angular/core';
import { ApiService } from './data/api.service';
import { Subject } from 'rxjs/Subject';
import { TribesService } from './data/tribes.service';
import {Config} from "./config/config";
import {UserTribeStatus} from "../shared/enums/user-tribe-status.enum";
import {logc} from "../shared/helpers/log";
import {AlertController, ModalController, NavController, PopoverController} from "@ionic/angular";
import {DispatcherService} from "./dispatcher.service";
import {FeedbackService} from "./popups/feedback.service";
import {UtilsService} from "./utils.service";
import {
  DAILY_PASSED_USERS_LIMIT,
  DAILY_REJECTED_USERS_LIMIT,
} from "../shared/constants/constants";
import {UserService} from "./data/user.service";
import {AnalyticsService} from "./analytics/analytics.service";
import {GenderChangePage} from "../pages/gender-change/gender-change.page";
import {isMobile, values} from "../shared/helpers/helpers";
import {IconStatusService} from "./icon-status.service";
import {PushNotification} from "./push-notification/push-notification";
import {DailyTribesService} from "./daily-tribes.service";
import {StatusServiceService} from "./status-service.service";
import {CheckmarkComponent} from "../components/checkmark/checkmark.component";
import {NavigationExtras} from "@angular/router";
import {AudiencesService} from "./analytics/audiences.service";
import {ModalService} from "./popups/modal.service";
import {PopupService} from "./popups/popup.service";
import { BehaviorSubject } from 'rxjs';

export enum PassingState {
  Decline = "decline",
  Reject = "reject"
}

export enum PassingAction {
  DontFeel = "dontFeel",
  TooFar = "tooFar",
  ReportUser = "reportUser",
  BlockUser = "blockUser",
  TooBusy = "tooBusy"
}

export enum EducationalPopup {
  First = "firstPassingEducationalPopup",
  Second = "secondPassingEducationalPopup",
  Third = "thirdPassingEducationalPopup"
}

export enum RejectEducationalPopup {
  First = "firstRejectEducationalPopupShown",
  Third = "thirdRejectEducationalPopupShown"
}

export enum DeclineEducationalPopup {
  First = "firstDeclineEducationalPopupShown",
  Third = "thirdDeclineEducationalPopupShown"
}

@Injectable({
  providedIn: 'root',
})
export class TribeHighlightsService {
  tribeId: any;
  data: any;
  private highlightsDataSource = new BehaviorSubject({});
  public onDataLoad: any;

  public passingState: PassingState | string = null;
  private passingAction: PassingAction | string = null;

  private declineOptions: any[] = [];
  private feedbackModal: any = null;

  public emptyPicture: string = '';

  private FAILED_SEARCH_HIGHLIGHTS = {};

  private startingTribe: boolean = false;

  public dataForStatus: any = {};
  public dataForInstructions: any = {};

  public acceptedTribe = true;
  public justAccepted = false;

  public navTitle: string = '';

  private isInitiator: boolean = false;

  private tribe: any = {};

  private dispatcher: any = {};

  public initialized: boolean = false;

  constructor(public api: ApiService,
              private navCtrl: NavController,
              private popoverCtrl: PopoverController,
              private modalCtrl: ModalController,
              private tribesService: TribesService,
              private dispatcherService: DispatcherService,
              private feedbackService: FeedbackService,
              private alertCtrl: AlertController,
              private userService: UserService,
              private utils: UtilsService,
              private push: PushNotification,
              private iconStatusService: IconStatusService,
              private analyticsService: AnalyticsService,
              private dailyTribesService: DailyTribesService,
              private statusService: StatusServiceService,
              private audiencesService: AudiencesService,
              private config: Config,
              private popupService: PopupService,
              private modalService: ModalService) {
    this.onDataLoad = this.highlightsDataSource.asObservable();
    (window as any).highlightsService = this;
  }

  openMatchesFlow() {
    let navigationExtras: NavigationExtras = { state: { inProgressTribe: this.data }};
    this.navCtrl.navigateForward("matches", navigationExtras);
  }

  cancelManualTribe() {
    this.tribesService
      .cancelTribe(this.data)
      .then(data => {
        this.navCtrl.navigateBack('tabs/tribes');
      });
  }

  async onApprove() {
    console.log("-- tribe-highlights.page.ts onApprove fired --");
    await this.utils.showLoading('Approving...');

    let newData = this.updateMates();
    this.dataForStatus = await this.statusService.getStatus( newData );
    if(this.dataForStatus && !this.dataForStatus.action) {
      this.dataForStatus.action = () => {};
    }

    this.tribesService.approve(this.tribe.id).then(async (res) => {
      if(await this.modalCtrl.getTop()) this.modalCtrl.dismiss();
      setTimeout( _ => {
        this.utils.doneLoading();
        this.navCtrl.navigateBack('tribes/' + this.tribe.id + '/chat');

        let options: any = {};
        const needsReviewUser = this.data.users.find(user => user.status === 'needs_review');
        const standbyUser = this.data.users.find(user => user.status === 'standby');
        if(needsReviewUser) {
          options = {
            popupName: "firstApprovedPopup",
            options: {
              standbyName: standbyUser.first_name,
              secondNeedsReviewName: needsReviewUser.first_name
            }
          }
        } else {
          options = {
            popupName: "secondApprovedPopup",
            options: {
              standbyName: standbyUser.first_name,
            }
          }
        }

        this.dispatcherService.newPopupSource.next( options );

      }, 1000);
      this.trackTribeUserEvent('approves', {});
      }, err => {
        this.utils.doneLoading();
        this.utils.errorContext = 'onApprove, error: ' + JSON.stringify(err);
        this.utils.showGenericError({
          key: "user_approve"
        });
      });
  }

  private getPassingState(userStatus): string {
    switch(userStatus) {
      case UserTribeStatus.Invited:
        return PassingState.Decline;
      case UserTribeStatus.NeedsReview:
        return PassingState.Reject;
      default:
        return PassingState.Decline;
    }
  }

  isMember(status) {
    return ['accepted', 'needs_review', 'pending_swap'].includes(status);
  }

  perform({ type, reason }) {
    return this.dispatcher[type][reason]();
  }

  warnNotificationsNeeded() {
    this.analyticsService.trackEvent({
      key: 'join_failed',
      value: 1,
      tribe_id: this.tribe.id,
      is_initiator: this.isInitiator,
      reason: 'no_pushes'
    });
    this.utils.showNotificationsNeededAlert();
  }

  async warnProfileIncomplete() {
    this.utils.showProfileIncomplete({
      setup: await this.utils.getMissingProfileValues(),
      backPath: `tribes/${ this.tribe.id }/highlights`
    });
  }

  async sendFeedback() {
    this.feedbackService.showFeedbackForm({
      context: 'Account Blocked.',
      errorContext: 'sendFeedback error'
    })
  }

  hasReplaceableUsers(tribe): boolean {
    return tribe?.users?.some(user => user?.replaceable);
  } 

  warnNeedPlus(source) {
    this.modalService.openUpselling({ source });
  }

  join() {
    return this.tribesService.join(this.tribe.id);
  }

  async warnCannotDetectFace() {
    this.analyticsService.trackEvent({
      key: 'join_failed',
      value: 1,
      tribe_id: this.tribe.id,
      is_initiator: this.isInitiator,
      reason: 'picture'
    });
    this.utils.showAlert(
      'Profile Issue Detected',
      'There seems to be a small issue with your profile photo. Please fix it.',
      'Reminder: Profiles are never public.',
      [
        {
          text: 'Fix Issue',
          handler: () => {
            this.navCtrl.navigateForward('/picture/cannot_detect_face',
              {
                state: {
                  backPage: `tribes/${this.tribe.id}/highlights`,
                  pageState: 'noFace'
                }
              });
          }}]);
  }

  updateMates() {
    let mates, status;
    if (this.tribe.status == 'pending') {
      status = 'inviting';
      mates = this.tribe.users.map(user => {
        if (user.status == 'invited') {
          user.status = 'accepted';
        }
        if (user.status == 'standby') {
          user.status = 'invited';
          user.expires_at = new Date(Date.now() + 1000 * 60 * 60 * 12);
        }
        return user;
      });
    } else if (this.tribe.status == 'inviting') {
      status = 'forming';
      if(this.tribe.tribe_user.status == 'invited') {
        this.tribe.tribe_user.status = 'accepted';
      }
    }
    return {
      id: this.tribe.id,
      tribe_user: this.tribe.tribe_user,
      status: status,
      users: mates || []
    };
  }

  async onJoin(options = {}) {
    logc.pink("onJoin options: ", options);
    this.startingTribe = true;
    if(!await this.userService.canJoin(this.tribe, options)) {
      this.startingTribe = false;
      return false;
    }

    if(this.isInitiator) {
      await this.dailyTribesService.addDailyTribe();
      await this.dailyTribesService.setRefreshTimeout();
    }
    this.join().then(async (res) => {
      this.dispatcherService.closeInstructionsSource.next(null);
      let newData = this.updateMates();
      this.dispatcherService.joinedTribeSource.next(true);
      this.dataForStatus = await this.statusService.getStatus( this.tribe );
      this.dataForInstructions = this.statusService.getInstructions(this.tribe.tribe_user, newData.status, newData.users);
      if(this.dataForStatus && Object.entries(this.dataForStatus).length) {
        if(this.dataForStatus && !this.dataForStatus.action) {
          this.dataForStatus.action = () => {};
        }

        this.pushStatusBar();
      }

      this.tribesService.addToProgressBarQueue(Object.assign({ id: this.tribe.id }, this.tribe));

      this.onDidJoin();

      if(!this.isInitiator) { return; }
      // if(this.config.getFlag('request_review_page_viewed')) { return; }

    }, err => {
      this.startingTribe = false;
      this.utils.doneLoading();
      this.onJoinError(err);
    });
  }

  onJoinError(err : any) {
    if (err.status !== 402) {
      this.utils.errorContext = 'onJoinError, error: ' + JSON.stringify(err);
      this.utils.showGenericError({
        key: "tribe_join"
      });
    }
  }

  async onDidJoin() {
    let modal = await this.modalCtrl.create({
      component: CheckmarkComponent,
      id: 'checkmarkModal',
      cssClass: "full-height-modal"
    });
    await modal.present();

    if(this.isInitiator) {
      this.trackTribeUsersEvent('tribe_started', { 
        search_location: this.config.get("matchPreferences").location,
        nth: this.config.getFlag("started_tribe_count") 
      });
    } else {
      this.trackTribeUsersEvent('tribe_joined', {});
    }

    if(this.config.shouldRedirectToDownloadApp()) {
      this.onDidJoinPWA();
    } else {
      this.onDidJoinInApp();
    }

    this.acceptedTribe = true;
    this.justAccepted = true;
    this.navTitle = 'Group Compatibility';
  }

  onDidJoinPWA() {
    setTimeout(() => {
      this.goToYouAreIn();
    }, 1500);
    setTimeout(() => {
      this.modalCtrl.dismiss(null, null, 'checkmarkModal');
    }, 2000);
  }

  goToYouAreIn() {
    return this.navCtrl.navigateForward('/pending-status/you-are-in', { 
      state: { backPath: `tribes/${ this.tribeId }/chat`}
    });
  }

  async onDidJoinInApp() {
    setTimeout(async () => {
      if(this.isInitiator) {
        await this.navCtrl.navigateForward("group-started", { state: { tribe: this.tribe }})
      } else {
        await this.navCtrl.navigateBack(`tribes/${ this.tribe.id }/chat`);
      }
    }, 1500)

    setTimeout(() => {
      this.modalCtrl.dismiss(null, null, 'checkmarkModal')
    }, this.isInitiator ? 2000 : 1800);
  }

  pushStatusBar() {
    let imageArea = document.getElementsByClassName('image-area')[0];
    if(!!imageArea) {
      // this.renderer.addClass(imageArea, 'push-from-top');
    }
  }

  async showEducationalPopup(popupName: string, state: "decline" | "reject") {
  }

  educationalPopupShown(flag: string): boolean {
    return this.config.get("educationalPopups")[flag];
  }

  async performDontFeelLikeIt() {
    this.passingAction = 'dontFeel';
    if (this.passingState === 'decline') {
      this.updateActionSheet();
      if(this.isInitiator) {
        if (!this.educationalPopupShown(DeclineEducationalPopup.First)) {
          const popup = await this.popupService.createPopup({
            popupName: EducationalPopup.First
          });
          this.analyticsService.trackEvent({ key: "passing_warning", value: 1, content: "dont-judge-by-pic" });
          popup.onDidDismiss().then(() => {
            this.tribesService.riseEducationalPopupFlag(DeclineEducationalPopup.First)
            // this.performDontFeelLikeIt();
          });
          return;
        } else {
          if (!this.educationalPopupShown(DeclineEducationalPopup.Third) &&
            this.config.get("passedUsers").declined.daily >= DAILY_PASSED_USERS_LIMIT - 2) {
            const popup = await this.popupService.createPopup({
              popupName: EducationalPopup.Third,
              options: {
                popupName: "resetMatchesPopup",
                tribe: this.tribe,
                source: "passing_warning",
                callback: () => {
                  this.declineClick(this.tribe.users);
                  this.navCtrl.navigateBack("tabs/tribes");
                }
              }
            });
            this.analyticsService.trackEvent({ key: "passing_warning", value: 1, content: "warning-too-picky" });
            popup.onDidDismiss().then((data) => {
              this.tribesService.riseEducationalPopupFlag(DeclineEducationalPopup.Third)
              console.log("dismissed data: ", data);
              if(data?.data?.blockPerforming) return;

              this.performDontFeelLikeIt();
            });
            return;
          }
        }
      }
      if(document.getElementById("menu-popover")) {
        this.popoverCtrl.dismiss(null, '', 'menu-popover');
      }

      return this.showBottomSheet();
    }

    if(this.passingState === 'reject') {
      if(!this.educationalPopupShown(RejectEducationalPopup.First)) {
        const popup = await this.popupService.createPopup({ popupName: EducationalPopup.First });
        this.analyticsService.trackEvent({ key: "passing_warning", value: 1, content: "dont-judge-by-pic" });
        popup.onDidDismiss().then(() => {
          this.tribesService.riseEducationalPopupFlag(RejectEducationalPopup.First)
          this.performDontFeelLikeIt();
        });
        return;
      } else {
        if(!this.educationalPopupShown(RejectEducationalPopup.Third) &&
            this.config.get("passedUsers").rejected.daily == DAILY_REJECTED_USERS_LIMIT - 1)
        {
          const popup = await this.popupService.createPopup({
            popupName: EducationalPopup.Third,
            options: {
              popupName: "resetMatchesRejectPopup",
              source: "passing_warning"
            }
          });
          this.analyticsService.trackEvent({ key: "passing_warning", value: 1, content: "warning-too-picky" });
          popup.onDidDismiss().then(({ data }) => {
            this.tribesService.riseEducationalPopupFlag(RejectEducationalPopup.Third)
            // if(data?.blockPerforming) return;
            this.performDontFeelLikeIt();
          });
          return;
        }
      }
      if(document.getElementById("menu-popover")) {
        this.popoverCtrl.dismiss(null, '', 'menu-popover');
      }
      this.dispatcherService.closeInstructionsSource.next(null);
      this.rejectDontFeel();
    }
  }

  async rejectDontFeel() {
    if(!this.config.getFlag('passRejectWarningShown')) {
      return this.showPassWarning([],
        () => this.rejectDontFeel(),
        'passRejectWarningShown')
    }
    this.rejectDontFeelHandler()
  }

  rejectDontFeelHandler() {
    const standbyUser = this.standbyUser();
    this.rejectUser(standbyUser, 'passed_not_feeling_it');
    this.onRejectSuccess('passed_not_feeling_it');
  }

  standbyUser() {
    return this.tribesService.getTribe(this.tribe.id).users.find(user => user.status === 'standby');
  }

  trackTribeUserEvent(ev, ev_data) {
    const u = this.standbyUser();
    logc.error("U: ", u);
    let data = {
      key: ev,
      value: 1,
      tribe_id: this.tribe.id,
      common_highlights: this.getCommonHighlights(),
      is_initiator: this.isInitiator ? 1 : 0,
      chat_unlocked: this.tribe.chat_unlocked ? 1 : 0,
      score: this.tribe.score,
      trust: u.trust,
      proximity: +u.proximity.toFixed(2),
      target_user_id: u.id,
    };
    data = Object.assign(data, ev_data);
    this.analyticsService.trackEvent(data);
  }

  showRejectionFeedback(standbyUser: any): void {
    const tribemates = this.tribe.users;
    if(tribemates[0].id == standbyUser.id){
      tribemates[0].isRejected = true;
      tribemates[0].picture = this.emptyPicture;
    } else {
      tribemates[1].isRejected = true;
      tribemates[1].picture = this.emptyPicture;
    }
  }

  async onRejectSuccess(reason) {
    this.trackTribeUserEvent('tribe_user_rejected', { reason: reason });
    const standbyUser = this.standbyUser();
    this.showRejectionFeedback(standbyUser);

    switch(reason) {
      case 'passed_not_feeling_it':
        this.presentToast('User Rejected');
        if(this.tribe.status === 'proposed') {
          await this.tribesService.removeTribe(this.tribe);
          await this.navCtrl.navigateBack('tabs/tribes');
          this.tribesService.tribeRemovedSource.next(this.tribe.id);
          this.presentToast('Group dismissed. Try getting matched again.');
          if(!this.config.getFlag('min_tribe_quality_popup_shown')) {
            this.dispatcherService.newPopupSource.next({
              popupName: 'minTribeQualityPopup'
            })
          }
        }
        break;
      case 'passed_report':
        this.presentToast('User Rejected');
        break;
    }
  }

  async rejectUser(standbyUser, reason) {
    try {
      await this.tribesService.reject(this.tribe.id, standbyUser.id, reason);
    } catch(err) {
      this.utils.errorContext = 'rejectUser, error: ' + JSON.stringify(err);
      this.utils.showGenericError({
        key: "user_reject"
      });
    }
  }

  updateActionSheet() {
    const states = {
      dontFeel: { message: "I'm not feeling ", subTitle: "Don't worry, this is anonymous." },
      reportUser: { message: 'Report ', subTitle: "Don't worry, this is anonymous."},
      blockUser: { message: 'Block ', subTitle: "They won't be able to match with you."}
    };
    this.declineOptions.forEach((mate) => {
      mate.message = states[this.passingAction].message + mate.name;
      mate.subTitle = states[this.passingAction].subTitle;
    });
  }

  async showBottomSheet(): Promise<any> {
    const initialBreakpoint = this.passingAction == PassingAction.DontFeel ? 0.45 : 0.25;
    return await this.modalService.openUsersModal({
      componentProps: {
        options: this.declineOptions,
        state: this.passingAction
      },
      callback: this.declineClick.bind(this),
      initialBreakpoint
    })
  }

  flag = false;
  async declineClick(data): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.passingState === PassingState.Decline) {
        const flag = data.length == 1 ? "passDeclineWarningShown" : "rejectDeclineWarningShown" ;
        if (!this.config.getFlag(flag)) {
          return this.showPassWarning(data, () => this.declineClick(data), flag);
        }
        if (this.passingAction === PassingAction.ReportUser) this.openFeedbackModal('report', data);
        if (this.passingAction === PassingAction.DontFeel) this.declineDontFeel(data);
        if (this.passingAction === PassingAction.BlockUser) this.declineBlock(data);
      }
    })
  }

  declineBlock(data) {
    let ids = data.map(u => u.id);
    this.decline(ids, 'passed_block').then( res => {
      let t = this.tribe.users.filter(u => ids.includes(u.id));
      this.onDeclineSuccess('passed_block', t);
    }, err => {
      this.utils.errorContext = 'declineBlock, error: ' + JSON.stringify(err);
      this.utils.showGenericError({});
    });
  }

  declineDontFeel(data) {
    let mates = data;
    let ids = mates.map(u => u.id);
    this.decline(ids, 'passed_not_feeling_it').then(async res => {
      this.tribesService.tribeDeclinedSource.next({});
      this.dispatcherService.closeInstructionsSource.next(null);
      let tribemates = this.tribe.users.filter(u => ids.includes(u.id));

      this.onDeclineSuccess('passed_not_feeling_it', tribemates);
      if(this.needGenderOffboarding(mates)) {
        await this.askForGender();
      }
    }, err => {
      this.utils.errorContext = 'declineDontFeel, error: ' + JSON.stringify(err);
      this.utils.showGenericError({
        key: "decline_dont_feel"
      });
    });
  }

  async askForGender() {
    try {
      const popup = await this.popoverCtrl.create({
        component: GenderChangePage,
        backdropDismiss: true,
        cssClass: `popup ${ !isMobile() ? "desktop" : ""}`,
      });
      await popup.present();
    } catch( err ) {
      console.log( err );
    }
  }

  singleMate(mates) {
    return mates.length === 1;
  }

  needGenderOffboarding(mates) {
    let asked = this.config.getFlag('genderPreferenceChangeAsked');
    let genderbinaryAndOpposite = this.utils.genderBinaryAndOpposite(mates[0].gender);
    return this.singleMate(mates) && !this.isInitiator && !asked && genderbinaryAndOpposite;
  }

  showPassWarning(data, handler = null, warningShowedFlag ) {
    let users = data;
    if(data.length === 1) {
      users = this.tribe.users.filter(user => ![data[0].id, this.tribe.tribe_user.id].includes(user.id))
    }
    let popupOptions = {
      popupName: "otherUserBeGonePopup",
      options: {
        users: users,
        handler: () => handler(),
        matches: this.config.getFlag('total_matches_under_75') || 0
      }
    };

    this.config.setFlag(warningShowedFlag, true);
    this.dispatcherService.newPopupSource.next(popupOptions);
  }

  async openFeedbackModal(reason, actionSheetData) {
    try {
      this.feedbackModal = await this.feedbackService.createModal('report');
      this.feedbackModal.onClick.subscribe(reason => {
        this.dispatchReportReason(reason, actionSheetData);
      })
    } catch ( err ) {
      console.log( err );
    }
  }

  async dispatchReportReason(reason, actionSheetData) {
    if(!reason) return;

    if(reason === 'Other') {
      this.feedbackModal.close();
      this.openReportingForm(actionSheetData);
    } else {
      this.declineReport(reason, actionSheetData);
    }
  }

  async openReportingForm(actionSheetData) {
    const alert = await this.alertCtrl.create(
      {
        header: "Reporting User",
        cssClass: 'low-top',
        inputs: [
          { name: 'reason', placeholder: 'Please tell us why...' },
        ],
        buttons: [
          { text: 'Cancel', role: 'cancel'},
          { text: "Report", handler: data => this.declineReport('other', actionSheetData, data.reason) }
        ]
      });
    await alert.present();
  }

  declineReport(reason, data, details = '') {
    let ids = data.map(u => u.id);
    this.decline(ids, 'passed_report', reason, details).then( res => {
      let t = this.tribe.users.filter(u => ids.includes(u.id));
      this.onDeclineSuccess(reason, t, details);
      this.presentToast('User Reported');
    }, err => {
      this.utils.errorContext = 'declineReport, error: ' + JSON.stringify(err);
      this.utils.showGenericError({
        key: "decline_report"
      });
    }).finally(() => this.feedbackModal.close());
  }

  async onDeclineSuccess(reason, tribemates = [], details = '') {
    let analytics = { reason: this.utils.underscoreCase(reason) };
    if(!!details) {
      analytics = { ...analytics, ...{ details: details }};
    }

    this.trackTribeUsersEvent('tribe_declined', analytics, tribemates);

    switch(reason) {
      case 'too_busy_right_now':
        this.presentToast('Group Dismissed');
        break;
      case 'passed_too_far_enforce_radius':
        //this.presentToast('Group Dismissed');
        this.enforceRadius();
        this.matchPrefsToast();
        break;
      case 'passed_not_feeling_it':
        this.presentToast('Group dismissed. Try getting matched again.');
        if(!this.config.getFlag('min_tribe_quality_popup_shown') && !this.isInitiator) {
          this.dispatcherService.newPopupSource.next({
            popupName: 'minTribeQualityPopup'
          });
          this.config.setFlag('min_tribe_quality_popup_shown', true);
        }
        break;
      case 'passed_block':
        this.presentToast('User Blocked');
        break;
    }

    await this.backToTribes();
  }

  async backToTribes() {
    this.tribesService.refresh(null);
    await this.navCtrl.navigateBack('/tabs/tribes')
    if(this.tribe.status == "awaiting"
      && !this.config.getFlag('awaiting_tribe_visited')
      && !this.config.getFlag('start_global_tribe_viewed')
      && !this.audiencesService.inScheduledGroup()
    ) {
      this.dispatcherService.openPopup('updateRadiusToGlobal');
      this.config.setFlags({
        awaiting_tribe_visited: true,
        start_global_tribe_viewed: true
      });
    }
  }

  async matchPrefsToast() {
    this.utils.showGenericMessage(
      "Group dismissed",
      3000,
      "Adjust max distance",
      () => this.navCtrl.navigateForward('preferences')
    )
  }

  moreThanAndOdd = (limit, number) => number > limit && number % 2 == 1;

  countPass() {
    let notFeelingPassedCounter = this.config.get("notFeelingPassedCounter") || 0;
    notFeelingPassedCounter += 1;
    this.config.set("notFeelingPassedCounter", notFeelingPassedCounter);
  }

  async enforceRadius() {
    try {
      await this.userService.updateProfile({ enforceRadius: true });
    } catch(err) {
      this.utils.errorContext = 'enforceRadius, error: ' + JSON.stringify(err);
      this.utils.showGenericError({
        key: "enforcing_radius"
      });
    }
  }

  presentToast(toastMessage) {
    this.utils.showGenericMessage(toastMessage);
  }

  decline(users, declineReason, reportReason?, details?) {
    this.dispatcherService.closeInstructionsSource.next(null);
    return this.tribesService.decline(this.tribe.id, users, declineReason, reportReason, details);
  }

  trackTribeUsersEvent(ev, ev_data, tribemates = []) {
    if(this.tribe.id < 0) return;


    if(tribemates.length == 0) {
      tribemates = this.tribe.users.filter(user => user.id !== this.tribe.tribe_user.id);
    }

    let u1 = tribemates[0];
    let u2 = tribemates[1];

    let data : any = {
      key: ev,
      value: 1,
      tribe_id: this.tribe.id,
      tribe_status: this.tribe.status,
      common_highlights: this.getCommonHighlights(),
      is_initiator: this.isInitiator ? 1 : 0,
      chat_unlocked: this.tribe.chat_unlocked ? 1 : 0,
      score: this.tribe.score,
    };

    data = Object.assign(data, ev_data);

    if(u1) {
      data.big_smile_1 = tribemates[0].smile,
      data.target_user_id_1 = u1.id;
      data.trust_1 = u1.trust;
      if (u1.proximity) {
        data.proximity_1 = +u1.proximity?.toFixed(2);
      }
    }

    if(u2) {
      data.big_smile_2 = tribemates[1].smile,
      data.target_user_id_2 = u2.id;
      data.trust_2 = u2.trust;
      if(u2.proximity) {
        data.proximity_2 = +u2.proximity?.toFixed(2);
      }
    }

    this.analyticsService.trackEvent(data);
  }

  getCommonHighlights(): any {
    return values(this.tribe.highlights)
      .reduce((sum: number, highlights: any) =>
        sum += highlights.length, 0
      );
  }

  async rejectReportHandler()  {
    this.feedbackModal = await this.feedbackService.createModal('report');
    this.feedbackModal.onClick.subscribe(reason => this.rejectReport(reason));
    return this.feedbackModal;
  };

  async rejectReport(reason: string) {
    const standbyUser = this.standbyUser();
    try {
      await this.tribesService.reject(this.tribe.id, standbyUser.id, 'passed_report');
      this.showRejectionFeedback(standbyUser);
      this.onRejectSuccess('passed_report');
    } catch ( err ) {
      console.log( err );
    } finally {
      this.feedbackModal.close();
    }
  }

  async reportUser(): Promise<any> {
    this.passingAction = 'reportUser';

    if (this.passingState === 'decline') {
      this.updateActionSheet();
      return this.showBottomSheet();
    }

    if(this.passingState === 'reject') {
      if(!this.config.getFlag('passRejectWarningShown')) {
        return this.showPassWarning([], () =>
          this.rejectReportHandler(),
          'passRejectWarningShown'
        );
      }
      return this.rejectReportHandler();
    }
  }

  async performTooBusy() {
    this.passingAction = "tooBusy";
    await this.decline([], "too_busy_right_now");
    await this.onDeclineSuccess("too_busy_right_now", this.tribe.users, "too_busy");
  }

  blockUser() {
    this.passingAction = 'blockUser';

    if (this.passingState === 'decline') {
      this.updateActionSheet();
      return this.showBottomSheet();
    }

    if(this.passingState === 'reject') {
      this.rejectBlock();
    }
  }

  rejectBlock() {
    if(!this.config.getFlag('passRejectWarningShown')) {
      return this.showPassWarning([], () => this.rejectBlockHandler(), 'passRejectWarningShown')
    }
    this.rejectBlockHandler();
  }

  rejectBlockHandler() {
    const standbyUser = this.standbyUser();
    this.tribesService.reject(this.tribe.id, standbyUser.id, 'passed_block').then(res => {
      this.showRejectionFeedback(standbyUser);
      this.onRejectSuccess('passed_block');
      this.presentToast('User Rejected');
    }, err => {
      this.utils.errorContext = 'rejectBlock, error: ' + JSON.stringify(err);
      this.utils.showGenericError({
        key: "tribe_reject"
      });
    });
  }

  tooFarAway() {
    if (this.passingState === 'decline') {
      this.declineTooFarAway();
    }
    if(this.passingState === 'reject') {
      this.rejectTooFarAway();
    }
  }

  async rejectTooFarAway() {
    const standbyUser = this.standbyUser();
    await this.tribesService.reject(this.tribe.id, standbyUser.id, 
                                    'passed_too_far_enforce_radius');
    await this.onRejectSuccess('passed_too_far_enforce_radius');
  }

  showBackToLocalRadiusPopup() {
    this.popupService.createPopup({
      popupName: "updateRadiusToNearMe",
      callback: (resp) => {
        let reason = 'passed_too_far_no_enforce_radius';
        if(resp.data && resp.data.matchNearby) {
          reason = 'passed_too_far_enforce_radius';
        }

        this.utils.showGenericMessage('Declining...');
        this.decline([], reason).then(async res => {
          this.utils.doneLoading();
          await this.onDeclineSuccess(reason);
          logc.error(resp)
          if(resp.data && resp.data.closingCallback) {
            resp.data.closingCallback();
          }
        }, err => {
          this.utils.doneLoading();
          this.utils.errorContext = 'declining, error: ' + JSON.stringify(err);
          this.utils.showGenericError({
            key: "back_to_nearme_decline"
          });
        });
      }
    });
  }

  async declineTooFarAway() {
    if(this.config.isGlobalSearching()) {
      return this.showBackToLocalRadiusPopup();
    }
    await this.decline([], 'passed_too_far_enforce_radius');
    await this.onDeclineSuccess('passed_too_far_enforce_radius');
  }

  async init() {
    await this.config.load();
    logc.red("Highlights service initialized");
    this.initialized = true;
    this.emptyPicture = `./assets/img/default-${this.config.get("gender") === 'male' ? '' : 'female-'}avatar.png`;

    this.dispatcher = {
      tribe: {
        join:        () => this.onJoin({ backPage: `tribes/${ this.tribe.id }/highlights` }),
        approve:     () => this.onApprove(),
        matches_flow: () => this.openMatchesFlow(),
        cancel_tribe: () => this.cancelManualTribe()
      },
      pass: {
        dontFeel:    () => this.performDontFeelLikeIt(),
        tooBusy:     () => this.performTooBusy(),
        tooFar:      () => this.tooFarAway(),
        report:      () => this.reportUser(),
        block:       () => this.blockUser()
      }
    };

    this.FAILED_SEARCH_HIGHLIGHTS = {
      id: -1,
      highlights: { traits: [
          {factor_id: 45, type: "spectrum", text: "Somewhat Shy", spectrum_score: 0.7},
          {factor_id: 38, type: "spectrum", text: "More Carefree", spectrum_score: 0.3},
          {factor_id: 23, type: "spectrum", text: "Straightforward", spectrum_score: 0.75},
          {factor_id: 35, type: "spectrum", text: "More Progressive", spectrum_score: 0.7},
          {factor_id: 43, type: "spectrum", text: "More Gentle", spectrum_score: 0.3}
        ],
        interests: [
          {factor_id: 68, type: "item", text: "Agriculture & Forestry", spectrum_score: 0},
          {factor_id: 68, type: "item", text: "Aerospace & Defense", spectrum_score: 0},
          {factor_id: 68, type: "item", text: "Construction", spectrum_score: 0},
          {factor_id: 68, type: "item", text: "Energy", spectrum_score: 0},
          {factor_id: 68, type: "item", text: "Economics", spectrum_score: 0},
        ],
        goals: [
          {factor_id: 52, type: "spectrum", text: "Exercise More Often", spectrum_score: 0.8},
          {factor_id: 52, type: "item", text: "Try Biking", spectrum_score: 0},
          {factor_id: 52, type: "item", text: "Try Capoeira", spectrum_score: 0},
          {factor_id: 52, type: "item", text: "Try Jogging/Running", spectrum_score: 0},
          {factor_id: 52, type: "item", text: "Try Crossfit", spectrum_score: 0},
          {factor_id: 52, type: "item", text: "Try Cross Country Skiing", spectrum_score: 0}
        ]
      },
      tribe_user: {
        id: -1,
        first_name: 'Casey',
        birthday: null,
        picture: './assets/img/pixel-img.png',
        status: 'invited'
      },
      users: [
        {
          id: -1,
          first_name: 'Casey',
          birthday: null,
          picture: './assets/img/pixel-img.png',
          status: 'invited'
        },
        { id: null,
          first_name: "Missing member",
          picture: this.config.getDefaultAvatar()
        }
      ],
      status: 'awaiting'
    };
  }

  getHighlightsData() {
    return this.data;
  }

  left() {
    this.tribeId = null;
  }

  private getHighlights(tribe_id) {
    return new Promise((resolve, reject) => {
      this.api.get('tribes/' + tribe_id + '/highlights', {}).then( (resp: any) => {
        resolve(resp);
      }, reject)
    });
  }

  // getSmsHighlights(){
  //   return new Promise((resolve, reject) => {
  //     this.api.get('sms_tribe/highlights', {}).then( (resp: any) => {
  //       resolve(resp);
  //     }, reject);
  //   });
  // }

  loadHighlightData( tribeId ): Promise<void> {
    if(tribeId != this.tribeId) {
      this.tribeId = tribeId;
      this.data = null;
    }
    return new Promise((resolve, reject) => {
      if(tribeId == -1) {
        this.data = this.FAILED_SEARCH_HIGHLIGHTS;
        return resolve();
      }
      this.getHighlights(tribeId).then(async (data: any) => {
        // logc.info("highlights data: ", data);
        this.data = data;
        this.tribe = data;
        const { tribe_user: currentUser } = data;

        this.passingState = this.getPassingState(this.tribe.tribe_user.status);
        this.declineOptions = this.tribe.users.map(mate => {
          return {
            id: mate.id,
            picture: mate.picture,
            gender: mate.gender,
            name: mate.first_name,
            message: mate.first_name,
          };
        });

        this.dataForStatus = await this.statusService.getStatus(data);

        this.acceptedTribe = this.isMember(currentUser);
        this.isInitiator = currentUser.is_initiator;
        this.highlightsDataSource.next(data);
        this.tribesService.actionTaken(tribeId);
        resolve();
      }, err => {
        reject(err);
      });
    });
  }
}
