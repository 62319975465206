import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendFeedbackComponent } from './send-feedback';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {AutosizeModule} from 'ngx-autosize';
import { DirectivesModule } from 'src/app/directives/directives.module';

@NgModule({
  declarations: [
    SendFeedbackComponent,
  ],
  imports: [
    DirectivesModule,
    AutosizeModule,
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [
    SendFeedbackComponent
  ]
})
export class SendFeedbackComponentModule {}
