import { Injectable } from '@angular/core';
import {ModalService} from "./popups/modal.service";
import {FeedbackService} from "./popups/feedback.service";
import {UtilsService} from "./utils.service";
import {AnalyticsService} from "./analytics/analytics.service";
import {TribesService} from "./data/tribes.service";
import {logc} from "../shared/helpers/log";
import {AlertController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private modalOptions: any = null;

  constructor(private modalService: ModalService,
              private utils: UtilsService,
              private alertCtrl: AlertController,
              private tribesService: TribesService,
              private analyticsService: AnalyticsService,
              private feedbackService: FeedbackService) { }

  async sendAnalytics(tribe, usersIds, reason, details): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        this.analyticsService.trackEvent({
          key: 'reported_user',
          value: 1,
          reason: this.utils.underscoreCase(reason),
          details,
          reported_users_ids: usersIds,
          tribe_id: tribe.id,
          is_initiator: tribe.tribe_user.is_initiator
        })
        resolve();
      } catch(e) {
        reject();
      }
    })
  }

  collectReport(tribe: any): Promise<{ reportedUsersIds: number[], reason: string, details: string }> {
    return new Promise(async (resolve, reject) => {
      await this.modalService.openUsersModal({
        componentProps: {
          options: tribe.users.map(({ id, picture, first_name }) => ({
            id, picture,
            message: `Report ${ first_name }`,
            subTitle: `Don't worry, this is anonymous.`
          }))
        },
        callback: async (data) =>
          resolve({
            ...await this.getReason(),
            reportedUsersIds: data.map(u => u.id)
          })
      })
    })
  }

  getReason(): Promise<{ reason: string, details: string }> {
    return new Promise(async (resolve, reject) => {
      this.modalOptions = await this.feedbackService.createModal("report");
      this.modalOptions.onClick.subscribe(async (selectedOption) => {
        this.modalOptions.close();
        try {
          resolve({
            reason: selectedOption,
            details: selectedOption == "Other" ? await this.getUsersReason() : ""
          });
        } catch(e) {
          reject();
        }
      })
    })
  }

  async reportUser(tribe: any, user: any): Promise<void> {
    try {
      const { reason, details } = await this.getReason();
      await this.sendAnalytics(tribe, [user.id], reason, details);
    } catch(e) {
      logc.error("Reporting user attempt failed: ", e);
    }
  }

  async reportTribe(tribe: any): Promise<void> {
    try {
      const { reportedUsersIds, reason, details } = await this.collectReport(tribe);
      await this.tribesService.reportUsers(tribe.id, reportedUsersIds);
      await this.utils.showGenericMessage("User was reported!");
      await this.sendAnalytics(tribe, reportedUsersIds, reason, details);
    } catch(e) {
      logc.error("User report attempt error: ", e);
      await this.utils.showGenericError({ msg: "Something went wrong, try again later" })
    }
  }

  async getUsersReason(): Promise<string> {
    return this.feedbackService.showInputAlert({
      header: "Reporting User",
      placeholder: 'Please tell us why...',
      buttonText: "Report"
    })
  }
}
