import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Config } from '../services/config/config';
import { ApiService } from '../services/data/api.service';
import { UtilsService } from '../services/utils.service';
import { ProfilePictureService } from '../services/profile-picture.service';
import { PictureService } from '../services/picture.service';
import { VisionService } from '../services/vision.service';
import { StoreService } from '../services/native/store.service';
import { ChatPictureService } from '../services/in-chat-picture.service';
import { SessionService } from '../services/data/session.service';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { StatementsService } from '../services/data/statements.service';
import { EmojiService } from '../services/data/emoji.service';
import { CountryInformation } from '../services/data/country-information.service';
import { GiphyService } from '../services/data/giphy.service';
import { TribesService } from '../services/data/tribes.service';
import { TribeService } from '../services/data/tribe.service';
import { UserService } from '../services/data/user.service';
import { SearchService } from '../services/data/search.service';
import { LocationService } from '../services/native/location.service';
import { HighlightStylesService } from '../services/data/highlight-styles.service';
import { PushNotification } from '../services/push-notification/push-notification';
import { TribeHighlightsService } from '../services/tribe-highlights.service';
import { CountdownTimerService } from '../services/helpers/countdown-timer.service';
import { D3AnimationsService } from '../services/helpers/d3-animations.service';
import { AccountCreatorService } from '../services/account-creator.service';
import { PusherService } from '../services/pusher.service';
import { StatusServiceService } from '../services/status-service.service';
import { CrashReportService } from '../services/performance/crash-report.service'
import { PerformanceReportService } from '../services/performance/performance-report.service'
import { RemoteConfigService } from '../services/remote-config.service'
import { TribeSmsLinkResolver } from '../resolvers/tribe-sms-link.resolver';
import { CachedPicturesService } from '../services/data/cached-pictures.service';
import { AppService } from '../services/app';
import {ConsoleService} from "../services/performance/console.service";
import {PerformanceService} from "../services/performance/performance.service";
import {NavigationResolver} from "../resolvers/navigation.resolver";
import {ContactsService} from "../services/native/contacts.service";
import {ColorModeService} from "../services/native/color-mode.service";
import {KeyboardService} from "../services/native/keyboard.service";
import {DailyTribesService} from "../services/daily-tribes.service";
import {IpService} from "../services/ip.service";
//import {FacebookService} from "../services/facebook.service";
import {HeartfeltMessagesService} from "../services/heartfelt-messages.service";
import {ExperimentsService} from "../services/experiments.service";
import {AudioService} from "../services/audio.service";
import {BuilderService} from "../services/helpers/builder.service";
import {PodcastsService} from "../services/topics/podcasts.service";
import {AudiencesService} from "../services/analytics/audiences.service";
import {MenuService} from "../services/menu.service";
import {WordingService} from "../services/wording.service";
import {ChatService} from "../services/chat.service";
import {ReportService} from "../services/report.service";
import {InstructionsService} from "../services/instructions.service";
import { MeetupService } from '../services/meetup.service';
import { ActivitiesService } from '../services/activities.service';
import { TopicsService } from '../services/topics.service';
import { FetchingTribeResolver } from '../resolvers/fetching-tribe.resolver';
import { CalendarService } from '../services/calendar.service';

@NgModule({
  imports: [ CommonModule ],
  providers: [
    ConsoleService, ColorModeService,
    Config, ApiService, SessionService,
    AnalyticsService, StatementsService,
    TribesService, TribeService, UserService,
    StoreService, UtilsService, PictureService,
    VisionService, ProfilePictureService,
    ChatPictureService, EmojiService, 
    GiphyService, HighlightStylesService, PushNotification,
    TribeHighlightsService, CountryInformation, 
    D3AnimationsService, SearchService, 
    AccountCreatorService, CountdownTimerService,
    PusherService, StatusServiceService,
    TribeSmsLinkResolver, AppService,
    RemoteConfigService, CrashReportService, PerformanceReportService,
    LocationService, CachedPicturesService, PerformanceService,
    NavigationResolver, ContactsService, KeyboardService,
    DailyTribesService, IpService, HeartfeltMessagesService,
    /*FacebookService,*/ ExperimentsService, AudioService,
    BuilderService, PodcastsService, AudiencesService,
    MenuService, WordingService, ChatService,
    ReportService, InstructionsService, MeetupService,
    ActivitiesService, TopicsService, CalendarService,
  ],
})
export class CoreModule {
  static forRoot() : ModuleWithProviders<CoreModule> {
    return {
        ngModule: CoreModule,
        providers: []
    };
  }
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}

