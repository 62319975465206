import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {BulletListComponent} from "./bullet-list.component";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [BulletListComponent],
  imports: [CommonModule],
  exports: [BulletListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BulletListModule {}
