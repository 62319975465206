import { IonicModule } from '@ionic/angular';
import { NgModule } from "@angular/core";
import { HighlightsTabComponent } from "./highlights-tab.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { Loading } from 'src/app/components/loading/loading';

@NgModule({
  declarations: [
    HighlightsTabComponent,
  ],
  entryComponents: [
    Loading
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    LoadingModule
  ],
  exports: [
    HighlightsTabComponent
  ]
})

export class HighlightsTabModule {}
