import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { StatementsService } from "./statements.service";
import { PushNotification } from "../push-notification/push-notification";
import { Config } from "../config/config";
import { LocationService } from "../native/location.service";
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import { Saveable } from "../../shared/structure/saveable";
import { Storage } from "@ionic/storage";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { UtilsService } from "../utils.service";
import { merge, Observable, Subject } from "rxjs";
import { ConsoleService } from "../performance/console.service";
import {
  CONSOLE_LOGS_NUMBER,
  MINIMUM_HIGHLIGHTS_LEVEL,
} from "../../shared/constants/constants";
import { Contacts } from "@ionic-native/contacts/ngx";
import { DispatcherService } from "../dispatcher.service";
import { ContactsService } from "../native/contacts.service";
import { AnalyticsService } from "../analytics/analytics.service";
import { fromNullable } from "../../shared/helpers/either-monad";
import {
  EMAIL_CLEANER_REGEX,
  PHONE_CLEANER_REGEX,
} from "../../shared/constants/regex";
import { environment } from "../../../environments/environment";
import {
  TOTAL_MATCHES_GLOBAL_THRESHOLD,
  TOTAL_MATCHES_UNDER_75_THRESHOLD,
  USABILITY_TEST_COUNTRIES_CODES,
} from "../../shared/constants/user.constants";
import { UsabilityTestAge } from "../../pages/sign-up-flow/shared/usability-test.enum";
import { logc } from "../../shared/helpers/log";
import { MatchingStatus } from "../../shared/interfaces";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { UserMatchingStatus } from "../../shared/enums/user.enums";
import { isIosPWA, performSequentially } from "../../shared/helpers/helpers";
import { AppService } from "../app";
import { Progress } from "../../shared/enums/progress.enum";
import { AudiencesService } from "../analytics/audiences.service";
import { PictureStatus } from "../profile-picture.service";
import { PictureRejectionReasons } from "../../pages/picture/cannot_detect_face/cannot_detect_face.page";
import { FeedbackService } from "../popups/feedback.service";
import { ModalService } from "../popups/modal.service";
import { MatchPreferencesLocation } from "src/app/shared/enums/match-preferences.enum";
import { UpsellingPoppingReason } from "src/app/pages/upselling/upselling.page";

@Injectable({
  providedIn: "root",
})
export class UserService extends Saveable {
  defaultConfig: any = null;
  key: string = `user`;

  public details = {
    work_title: "",
    work_organization: "",
    school: "",
    about: "",
    smile: false,
  };
  loaded = false;

  personalityType: any = null;
  topThreeFactors: any = null;

  public optionalRequirements = ["location_permission", "contact", "age_slip"];
  public criticalRequirements = [
    "gender",
    "notifications_cant_reach",
    "reach",
    "birthday",
    "location",
    "levels",
    "picture_unverified",
    "account",
  ];

  public standardMatches: any[] = [];

  public personalityTypeChange = new Subject();
  private readyForMatchesSource: Subject<any> = new BehaviorSubject(false);
  public matchPreferencesChangeSource = new Subject();
  public warningsChangeSource = new BehaviorSubject({});
  public onMatchPreferencesChanged: any;
  public onWarningsChanged: Observable<any>;

  public onReadyForMatchesStatusChanged: Observable<any>;

  public profileProgressChangeSource: BehaviorSubject<number> =
    new BehaviorSubject<number>(40);
  public onProfileProgressChanged: Observable<number | null>;

  public profileProgress: number = 0;

  constructor(
    public api: ApiService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    public config: Config,
    private utils: UtilsService,
    public alertCtrl: AlertController,
    public popover: PopoverController,
    public statementsService: StatementsService,
    public locationService: LocationService,
    private consoleService: ConsoleService,
    public diagnostic: Diagnostic,
    public platform: Platform,
    public storage: Storage,
    private modalService: ModalService,
    private appService: AppService,
    public pushNotification: PushNotification,
    public dispatcherService: DispatcherService,
    public contacts: Contacts,
    private feedbackService: FeedbackService,
    public analyticsService: AnalyticsService,
    private contactsService: ContactsService,
    private navCtrl: NavController,
    private audiencesService: AudiencesService
  ) {
    super(storage);

    this.onMatchPreferencesChanged =
      this.matchPreferencesChangeSource.asObservable();
    this.onWarningsChanged = this.warningsChangeSource.asObservable();
    this.onReadyForMatchesStatusChanged =
      this.readyForMatchesSource.asObservable();
    this.onProfileProgressChanged =
      this.profileProgressChangeSource.asObservable();

    merge(
      this.config.onProfileChanged,
      this.pushNotification.onPushChanged
    ).subscribe(() => {
      this.setMatchingStatus();
    });

    merge(this.appService.onAppReady, this.config.onProfileChanged).subscribe(
      () => {
        logc.pink("user service flags changed");
        this.calculateUserProgress();
      }
    );

    this.appService.onAppReady.subscribe(async () => {
      logc.indigo("-- Initializing user service --");
      this.init();
    });
  }

  async init() {
    this.calculateUserProgress();
    await this.setMatchingStatus();
  }

  calculateUserProgress() {
    const steps = [
      { done: true, text: "Enter your basic info", value: Progress.BasicInfo },
      {
        done: !!this.details.smile,
        text: "Show a big smile",
        value: Progress.Smile,
      },
      {
        done: !!this.details.work_organization || !!this.details.work_title,
        value: Progress.Work,
      },
      {
        done: !!this.details.school,
        text: "Add your school",
        value: Progress.School,
      },
      {
        done: !!this.details.about,
        text: 'Write an "About me"',
        value: Progress.AboutMe,
      },
      {
        done:
          this.config.getProfile().instagramToken &&
          this.config.getProfile().instagramToken !== "none",
        value: Progress.Instagram,
      },
    ];

    this.profileProgress = steps.reduce(
      (acc, v) => (acc += v.done ? v.value : 0),
      0
    );

    this.config.setFlag("profileProgress", this.profileProgress);
    logc.orange("Progress: ", this.profileProgress);
    this.profileProgressChangeSource.next(this.profileProgress);
  }

  formatEmailEntry(email) {
    return this.formatEntry(email, EMAIL_CLEANER_REGEX);
  }

  formatPhoneEntry(phone) {
    return this.formatEntry(phone, PHONE_CLEANER_REGEX);
  }

  isReadyForSmsOptIn() {
    return (
      this.config.getFlag("notify_me_intent") &&
      this.config.getProfile().phoneNumberStatus == "verified" &&
      this.config.getNotificationSettings()["sms-tribes-new_tribe_invites"]
    );
  }

  smsOptIn(fromFailedSearch = false) {
    return new Promise(async (resolve, reject) => {
      if (!this.config.get("smsOptIn") && this.isReadyForSmsOptIn()) {
        await this.updateProfile({ smsOptIn: true });
        this.analyticsService.trackEvent({
          key: "sms_opt_in",
          value: 1,
        });
        performSequentially([
          {
            wait: 500,
            run: async () =>
              await this.navCtrl.navigateForward("sms-verified/success"),
          },
          {
            wait: 1500,
            run: async () => await this.navCtrl.navigateForward("tabs/tribes"),
          },
          {
            wait: 500,
            run: async () => {
              if (
                fromFailedSearch &&
                [
                  UserMatchingStatus.Closed,
                  UserMatchingStatus.Invisible,
                ].includes(await this.getMatchingStatus())
              ) {
                await this.navCtrl.navigateForward("status_page");
              }
              if (!this.audiencesService.inScheduledGroup()) {
                await this.config.callOnce("start_global_tribe_viewed", () => {
                  this.dispatcherService.openPopup("updateRadiusToGlobal");
                });
              }
            },
          },
        ]);
        resolve("-- SMS Opting in! --");
      } else {
        if (this.config.get("smsOptIn")) {
          reject("-- Already SMS opted it --");
        } else {
          reject("-- Not ready for SMS Opt In yet --");
        }
      }
    });
  }

  formatEntry(value, regex) {
    const toLowercase = (v) => v.toLowerCase();
    const replaceSymbols = (v) => v.replace(regex, "");

    return fromNullable(value)
      .map(replaceSymbols)
      .map(toLowercase)
      .fold(
        () => "",
        (phone) => phone
      );
  }

  async setGlobalPreferences() {
    return await this.updateProfile({
      matchPreferences: {
        ...this.config.get("matchPreferences"),
        location: MatchPreferencesLocation.Global,
        age_range: this.config.getDefaultAgeRange(),
      },
    });
  }

  async canJoin(tribe: any = {}, options: any = {}): Promise<boolean> {
    let reason = null;

    if (!this.config.finishedLevel(3)) {
      this.modalCtrl.dismiss();
      this.navCtrl.navigateForward("insufficient-info");
      reason = "not_finished_level_3";
    } else {
      if (this.utils.profileIsIncomplete()) {
        this.warnProfileIncomplete();
        reason = "profile_incomplete";
      } else if (!this.pictureVerified()) {
        if (!(await this.warnCannotDetectFace(options))) return false;
        reason =
          this.config.getProfile().pictureStatus == PictureStatus.OnHold
            ? "no_smile"
            : "picture_issue";
      } else if (this.config.getProfile().banned) {
        this.warnAccountIsBanned();
        reason = "banned";
      } else if (!this.pushNotification.isReadyForMatching()) {
        if (this.config.isPWA) {
          if (isIosPWA()) {
            this.warnNotificationsNeededIosPwa();
          } else {
            this.warnNotificationsAndroidPwa();
          }
          // if(this.utils.isAndroidPWA()) this.warnNotificationsAndroidPwa();
        } else {
          this.warnNotificationsNeeded(tribe);
        }
        reason = "no_pushes";
      } else if (
        !this.config.isPlus() &&
        tribe.search_location == MatchPreferencesLocation.PickPlace &&
        tribe.is_initiator
      ) {
        this.modalService.openUpselling({
          source: "tribe-highlights-page",
          reason: UpsellingPoppingReason.ChangeMatchLocation,
        });
        reason = "pick_place_not_plus";
      }
    }
    if (reason) {
      const key = this.config.isFan()
        ? "fan_matching_signup_failed"
        : "join_failed";
      this.analyticsService.trackEvent({
        key,
        value: 1,
        reason,
        is_initiator: tribe.is_initiator,
        tribe_id: tribe.id,
      });
    }

    return !reason;
  }

  warnAccountIsBanned() {
    this.utils.showGenericMessage(
      "Your account is currently blocked. Contact us find out why.",
      6000,
      "CONTACT US",
      async () => {
        this.toastCtrl.dismiss();
        await this.feedbackService.showFeedbackForm({
          context: "sendFeedback error",
        });
        await this.sendFeedback("Account Blocked.", "sendFeedback error");
      }
    );
  }

  async warnCannotDetectFace(options) {
    if (await this.alertCtrl.getTop()) return false;
    await this.utils.showAlert(
      "Profile Issue Detected",
      "There seems to be a small issue with your profile photo. Please fix it.",
      "Reminder: Profiles are never public.",
      [
        {
          text: "Fix Issue",
          handler: async () => {
            this.modalCtrl.dismiss();
            this.navCtrl.navigateForward("/picture/cannot_detect_face", {
              state: {
                backPage: options?.backPage || `tabs/tribes`,
                pageState:
                  this.config.getProfile().pictureStatus == PictureStatus.OnHold
                    ? "serious"
                    : "noFace",
              },
            });
          },
        },
      ]
    );
    return true;
  }

  warnNotificationsNeeded(tribe) {
    this.utils.showNotificationsNeededAlert().then(
      async () => this.dismissModal(),
      (err) => logc.warn("(!) Push Notification warning just closed", err)
    );
  }

  warnNotificationsAndroidPwa() {
    this.utils.showAlert(
      "Notifications are needed",
      "We need to notify you about new groups and incoming messages.",
      "Otherwise, it creates a bad experience for the others.",
      [
        {
          text: "Use Browser Notifications",
          handler: () => this.pushNotification.requestPushPermissionAndInit(),
          cssClass: "text-label capitalize",
        },
        {
          text: "Use the app (Recommended)",
          handler: () => this.utils.openBranchLink(),
          cssClass: "text-primary capitalize",
        },
      ]
    );
  }

  warnNotificationsNeededIosPwa() {
    this.utils.showAlert(
      "Notifications are needed to match in groups",
      "We need to notify you about new groups and incoming messages, but iPhones don’t allow notifications in the mobile browser. Get the full app to allow notifications.",
      "",
      [
        {
          text: "Got it",
          handler: () => this.dispatcherService.openPopup("downloadAppPopup"),
        },
      ]
    );
  }

  async warnProfileIncomplete() {
    this.utils
      .showProfileIncomplete({
        setup: await this.utils.getMissingProfileValues(),
        backPath: `tabs/tribes`,
      })
      .then(
        () => this.dismissModal(),
        (err) => logc.warn("(!) Profile Incomplete warning just closed", err)
      );
  }

  async dismissModal() {
    const modalsToKeep = ["fans-searching-modal"];
    const modal = await this.modalCtrl.getTop();
    if (modal && !modalsToKeep.includes(modal?.id)) {
      this.modalCtrl.dismiss();
    }
  }

  readyForMatches(data = {}) {
    const totalMatchesUnder75 =
      data["total_matches_under_75"] ||
      this.config.getFlag("total_matches_under_75", 0);
    const totalMatchesGlobal =
      data["global_matches"] || this.config.getFlag("global_matches", 0);
    const matchPreferences = this.config.get("matchPreferences");

    let readyForMatches: boolean = false;
    if (matchPreferences.location == MatchPreferencesLocation.Global) {
      readyForMatches = totalMatchesGlobal > TOTAL_MATCHES_GLOBAL_THRESHOLD;
    } else {
      readyForMatches = totalMatchesUnder75 >= TOTAL_MATCHES_UNDER_75_THRESHOLD;
    }
    this.readyForMatchesSource.next(readyForMatches);
    return readyForMatches;
  }

  listContacts() {
    return new Promise(async (resolve, err) => {
      try {
        await this.utils.showLoading();
        let contactsData = await this.contacts.find(["*"]);

        await this.utils.showGenericMessage(`Contacts blocked!`);
        await this.config.setFlag("contactsBlocked", true);
        this.dispatcherService.contactsBlockedSource.next(true);

        //iPhone 5S exception
        if (
          contactsData &&
          contactsData.length > 0 &&
          contactsData[0]["_objectInstance"]
        ) {
          contactsData = contactsData.map((c) => c["_objectInstance"]);
        }

        const contacts: any = contactsData.map((c) => {
          return {
            emails:
              c.emails &&
              c.emails.map((e) => this.formatEmailEntry(e.value)).join(","),
            phones:
              c.phoneNumbers &&
              c.phoneNumbers
                .map((e) => this.formatPhoneEntry(e.value))
                .join(","),
          };
        });
        console.log("CONTACTS LENGTH: ", contacts.length);
        await this.utils.doneLoading();
        resolve(
          contacts.filter((c) => {
            return (
              (c.emails && c.emails.length > 0) ||
              (c.phones && c.phones.length > 0)
            );
          })
        );
      } catch (err) {
        console.log("listContacts err: ", err);
        await this.utils.doneLoading();
      }
    });
  }

  getCurrentUser() {
    return this.config.getProfile();
  }

  async blacklistContacts(): Promise<void> {
    this.analyticsService.trackEvent({
      key: "blocked_contacts_click",
      value: 1,
    });
    if (this.config.isPWA) {
      this.analyticsService.trackEvent({
        key: "blocked_contacts_pwa",
        value: 1,
      });
      this.utils.showDownloadToast();
      return;
    }

    if (
      (await this.contactsService.getContactsPermissionStatus()) ===
      this.diagnostic.permissionStatus.DENIED_ALWAYS
    ) {
      return this.contactsService.showInstructions();
    }

    const contacts: any = await this.listContacts();
    if (contacts.length == 0) {
      this.analyticsService.trackEvent({
        key: "blocked_contacts",
        value: 1,
        count: 0,
      });
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    try {
      await this.storage.set("contacts", contacts);
      await this.contactsService.sendContacts(contacts);
      this.analyticsService.trackEvent({
        key: "blocked_contacts",
        value: 1,
        count: contacts.length,
      });
    } catch (err) {
      console.log("blacklisitng contacts error: ", err);
      this.analyticsService.trackEvent({
        key: "blocked_contacts_error",
        value: 1,
        details: JSON.stringify(err),
      });
    }
  }

  ngOnDestroy() {}

  extractTopThreeFactors(score) {
    let organized = [];
    for (let i = 0; i < score.length; i++) {
      let high = score[i] - 0.5 > 0;
      let value = {
        image: "./assets/img/animals/",
        label: "",
        strength: Math.abs(score[i] - 0.5),
      };
      switch (i) {
        case 0: {
          value.image += high ? "dolphin.png" : "panda.png";
          value.label = high ? "Sociable" : "Reserved";
          break;
        }
        case 1: {
          value.image += high ? "lion.png" : "elephant.png";
          value.label = high ? "Unbalanced" : "Calm";
          break;
        }
        case 2: {
          value.image += high ? "beaver.png" : "monkey.png";
          value.label = high ? "Organized" : "Disorderly";
          break;
        }
        case 3: {
          value.image += high ? "hen.png" : "cat.png";
          value.label = high ? "Caring" : "Egoistic";
          break;
        }
        case 4: {
          value.image += high ? "puppy.png" : "rhino.png";
          value.label = high ? "Curious" : "Incurious";
          break;
        }
      }
      organized.push(value);
    }
    let sortedStrength = organized.slice(0);
    sortedStrength.sort((a, b) => {
      return b.strength - a.strength;
    });
    return sortedStrength.slice(0, 3);
  }

  resetCache() {
    this.loaded = false;
  }

  getProfile() {
    return this.config.getProfile();
  }

  updateProfile(params) {
    return new Promise(async (resolve, reject) => {
      let p = ApiService.formatParams(params);

      try {
        this.dispatcherService.profileUpdatingSource.next({
          status: "started",
        });
        const data: any = await this.api.put("users", p);
        this.details = data.details;
        this.config.updateProfile(params).then(resolve, reject);
      } catch (err) {
        console.log("Error", err);
        reject(err);
      } finally {
        this.dispatcherService.profileUpdatingSource.next({
          status: "completed",
        });
      }
    });
  }

  fetchPersonalityProfile() {
    return new Promise((resolve, reject) => {
      this.api.get("users/personality_type", {}).then((data: any) => {
        this.personalityType = data;
        this.topThreeFactors = this.extractTopThreeFactors(
          data.personality_score
        );
        resolve(data);
      }, reject);
    });
  }

  getPersonalityStatus() {
    return this.api.get("users/personality_type/status", {});
  }
  changePersonalityStatus(newPersonalityStatus) {
    return this.api.post("users/personality_type/status", {
      personality_type_status: newPersonalityStatus,
    });
  }

  async runAPIAction(customAction, successText, failureText, customPayload) {
    try {
      this.analyticsService.trackEvent({
        key: customAction,
        value: 1,
        ...customPayload,
      });
      await this.api.post("users/custom_action", {
        action: customAction,
        options: JSON.stringify(customPayload),
      });
      this.utils.showGenericMessage(successText);
    } catch (err) {
      this.utils.showGenericError({
        msg: failureText,
        key: "custom_push_action_error",
      });
    }
  }

  //phoneNumber in the form of +15149991111 includes + and regional code
  getSMSVerificationCode(phoneNumber) {
    return this.api.get("users/sms/verification", {
      phone_number: phoneNumber,
    });
  }

  //phoneNumber in the form of +15149991111 includes + and regional code
  getLoginSMSVerificationCode(phoneNumber) {
    return this.api.get(
      "sms_login/verification",
      { phone_number: phoneNumber },
      this.api.getAnonymousHeaders()
    );
  }

  //code 4 digit code
  checkSMSVerificationCode(code) {
    return new Promise((resolve, reject) => {
      this.api.post("users/sms/verification", { code: code }).then((d) => {
        this.config.updateProfile({ phoneNumberStatus: "verified" });
        resolve(d);
      }, reject);
    });
  }

  fetch() {
    return this.api.get("users", {});
  }

  provisionPurchase({ receipt, signature }) {
    let params = { receipt: receipt };
    if (signature) {
      params["signature"] = signature;
    }
    return this.api.post("users/provision", params);
  }

  clearSession() {
    return this.api.post("users/clear_session", {});
  }

  resetPassword(email) {
    return this.api.post(
      "users/reset_password",
      { email: email },
      this.api.getAnonymousHeaders()
    );
  }

  resendConfirmationEmail() {
    return this.api.post("users/resend_confirmation_email", {});
  }

  async sendFeedback(content, context) {
    content = "Context:" + context + "... " + content;
    let anonymous = !this.api.hasToken();

    return this.sendRequest(
      "New in-app feedback",
      content,
      !this.api.hasToken()
    );
  }

  resetLevels(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.api.post("levels/reset_level", {}).then((res) => {
        this.analyticsService.trackEvent({ key: "reset_levels", value: 1 });
        this.config
          .updateProfile({ currentLevel: 1, startedCurrentLevel: true })
          .then((_) => {
            Promise.all([this.reset(), this.statementsService.reset()]).then(
              (_) => {
                this.statementsService.load().then((_) => {
                  resolve();
                }, reject);
              },
              reject
            );
          }, reject);
      }, reject);
    });
  }

  delete() {}

  requestPictureReview(picture) {
    return this.api.post("requests/picture_reviews", {
      picture: picture,
    });
  }

  isUserFrom(country: string): boolean {
    return this.config.getProfile()?.closest_city?.country == country;
  }

  requestUsabilityTest() {
    const headers = this.api.getAnonymousHeaders();
    return this.api.post("requests/usability_test", {}, headers);
  }

  sendRequest(title, content, anonymous = false) {
    content += " ::devLogs:: ";
    const logsString = this.prepareLogs();
    const headers = anonymous ? this.api.getAnonymousHeaders() : null;
    return this.api.post(
      "requests",
      {
        title: title,
        content: content + logsString,
        anonymous: anonymous,
      },
      headers
    );
  }

  private prepareLogs(): string {
    let logs = [...this.consoleService.getLogs()];
    let stringifiedLogs: string = "";
    const consoleLogsLimit = environment.debugLogsLimit;
    if (!!consoleLogsLimit && logs.length > consoleLogsLimit) {
      logs = logs
        .slice(0, CONSOLE_LOGS_NUMBER)
        .concat(logs.slice(CONSOLE_LOGS_NUMBER * -1));
    }
    for (let i = 0; i < logs.length; i++) {
      stringifiedLogs += `${logs[i].createdAt}: ${logs[i].event} <br>`;
    }
    return stringifiedLogs;
  }

  // async readyForUsabilityTest(userInfo): Promise<boolean> {
  //   const fitAge = parseInt(userInfo.age || 0).isBetween(
  //     UsabilityTestAge.LowerThreshold,
  //     UsabilityTestAge.HigherThreshold
  //   );

  // const fitCountry = USABILITY_TEST_COUNTRIES_CODES.includes(
  //   fromNullable(this.getUserCountryInfo().code)
  //     .map((v) => v.toLowerCase())
  //     .fold(
  //       () => "us",
  //       (value) => value
  //     )
  // );

  //   console.log("- UX test age OK: ", fitAge);
  //   console.log("- UX test country OK: ", fitCountry);
  //   return fitAge && fitCountry;
  // }

  // public getUserCountryInfo() {
  // const { countryname, countrycode } = this.ipService.getIpInfo();
  // return {
  //   name: countryname,
  //   code: countrycode,
  // };
  // }

  public notReadyForHighlights(user: any): boolean {
    const lvl = MINIMUM_HIGHLIGHTS_LEVEL;
    return user.level < lvl || (user?.level == lvl && user?.started_level);
  }

  hasCriticalIssues(warnings: any[]): boolean {
    return warnings.some((warning) =>
      this.criticalRequirements.includes(warning)
    );
  }

  hasOptionalIssues(warnings: any[]): boolean {
    return warnings.some((warning) =>
      this.optionalRequirements.includes(warning)
    );
  }

  async getMatchingStatus(): Promise<any> {
    logc.info("-- getMatchingStatus() fired");
    const warnings = await this.getMatchingPoolUnmetRequirements();
    let availableForTribes = this.config.get("availableForTribes");
    let hasCriticalIssues = this.hasCriticalIssues(warnings);
    let hasOptionalIssues = this.hasOptionalIssues(warnings);
    let matchingStatus: MatchingStatus = null;

    if (hasCriticalIssues) {
      matchingStatus = UserMatchingStatus.Closed;
    } else if (!hasCriticalIssues && hasOptionalIssues) {
      matchingStatus = UserMatchingStatus.Available;
    } else if (!hasCriticalIssues && !hasOptionalIssues) {
      matchingStatus = UserMatchingStatus.Opened;
    }

    if (
      !availableForTribes &&
      [UserMatchingStatus.Available, UserMatchingStatus.Opened].includes(
        matchingStatus
      )
    ) {
      matchingStatus = UserMatchingStatus.Invisible;
    }

    return matchingStatus;
  }

  async setMatchingStatus() {
    this.dispatcherService.matchingStatusSource.next(
      await this.getMatchingStatus()
    );
  }

  async getMatchingPoolUnmetRequirements() {
    logc.info("-- getMatchingPoolUnmetRequirements fired --");
    await this.config.load();
    let warnings = [];
    let profile = this.config.getProfile();
    const hasLocationPermission =
      await this.locationService.hasLocationPermission();

    try {
      await this.pushNotification.checkPermissions();
    } catch (err) {
      console.log("getting requirements error: ", err);
    }

    if (!["male", "female", "non-binary"].includes(profile.gender)) {
      warnings.push("gender");
    }

    if (await this.profileIsIncomplete()) {
      warnings.push("incomplete");
    }

    if (!this.config.hasLocation()) {
      warnings.push("location");
    } else if (!hasLocationPermission) {
      warnings.push("location_permission");
    }

    if (
      this.config.getProfile().notificationPreferences[
        "push-tribes-new_tribe_invites"
      ] == false
    ) {
      if (
        this.config.getFlag("smsRestricted") ||
        !this.config.hasSms() ||
        this.config.getProfile().notificationPreferences[
          "sms-tribes-new_tribe_invites"
        ] == false
      ) {
        warnings.push("notifications_cant_reach");
      }
    }

    if (!this.config.isAnonymous() && !profile.birthday)
      warnings.push("birthday");
    if (this.config.isMinor() && !profile.legalGuardian)
      warnings.push("age_slip");
    if (!this.config.finishedLevel(3)) warnings.push("levels");
    if (this.config.isAnonymous()) warnings.push("account");
    if (profile.firstName && profile.pictureStatus != "verified")
      warnings.push("picture_unverified");
    if (
      (!this.pushNotification.hasPermission ||
        !this.pushNotification.hasPushToken()) &&
      profile.hasSms
    ) {
      warnings.push("contact");
    }
    if (
      (!this.pushNotification.hasPermission ||
        !this.pushNotification.hasPushToken()) &&
      !profile.hasSms
    ) {
      warnings.push("reach");
    }

    this.warningsChangeSource.next({
      critical: warnings.filter((warning) =>
        this.criticalRequirements.includes(warning)
      ).length,
      optional: warnings.filter((warning) =>
        this.optionalRequirements.includes(warning)
      ).length,
    });

    return warnings;
  }

  pictureVerified(): boolean {
    return this.config.getProfile().pictureStatus == "verified";
  }

  profileIsIncomplete(): boolean {
    const profile = this.config.getProfile();
    const requiredValues = ["firstName", "email", "hasPassword"];
    return Object.entries(profile).some(
      (pair) => requiredValues.includes(pair[0]) && !pair[1]
    );
  }

  load(): Promise<void> {
    //configs work offline and users info are 100% in the device config
    //so they are always accessible and up to date since we log the user in as soon as we can.

    return new Promise((resolve, reject) => {
      if (this.loaded) return resolve();

      this.config.load().then((_) => {
        this.fetch().then((data: any) => {
          this.details = data.details;
          this.calculateUserProgress();
          this.loaded = true;
          resolve();
        }, reject);
      }, reject);
    });
  }

  createLegalGuardian(options) {
    return new Promise((resolve, reject) => {
      this.api.post("users/create_legal_guardian", options).then((data) => {
        resolve(data);
      });
    });
  }

  removeUser(user): Promise<void> {
    return new Promise((resolve, reject) => {
      // logc.info("User id: ", user.id)
      // let index = this.standardMatches.findIndex(u => u.id === user.id);
      // console.log("index: ", index);
      // this.standardMatches.splice(index, 1);
      resolve();
    });
  }

  mockScore(users) {
    return users.map((u) => {
      u.score = Number.parseInt("" + Math.random() * 500);
      // u.pool_status = Math.ceil(Math.random() * 3);
      return u;
    });
  }

  sortUsers(users) {
    if (this.config.isDev) {
      users = this.mockScore(users);
    }

    return users.sort((current, next) => {
      if (!current.details.location) return;
      let currentProximity = current.details.location.distance;
      // currentProximity = Number.isInteger(currentProximity) ? currentProximity : '';
      let nextProximity = next.details.location.distance;
      // nextProximity = Number.isInteger(nextProximity) ? nextProximity : '';
      return current.score - currentProximity > next.score - nextProximity
        ? -1
        : 1;
    });
  }

  getStandardMatches(): Promise<any> {
    return new Promise((res, rej) => {
      this.api
        .get("users/pool_v2", {})
        .then(({ matches, opposite_descriptors }) => {
          res({
            matches: this.sortUsers(matches),
            oppositeDescriptors: opposite_descriptors,
          });
        });
    });
  }

  getHybridMatches(userId): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const data: any = await this.api.get("users/pool_v2/hybrid", {
          u2_id: userId,
        });
        resolve(this.sortUsers(data.matches));
      } catch (err) {
        reject(err);
      }
    });
  }

  notInterested(userId) {
    return this.api.post("users/not_interested", { user_id: userId });
  }

  block(userId) {
    return this.api.post("users/block", { user_id: userId });
  }

  blockAndReport(userId) {
    return this.api.post("users/block_and_report", { user_id: userId });
  }

  onUpsellingPageVisited(nth): Promise<any> {
    return this.api.post("visited_upselling_page", { nth: nth});
  }
}
