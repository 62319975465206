<div class = "groups">
  <span *ngFor = "let group of groups"
        class = "group"
        [class.selected] = "currentGroup.k == group.k"
        (click) = "loadGroup(group)">
    {{ group.u }}
  </span>
</div>

<div class = "separator"></div>
<div class = "emoji-holder">
  <div *ngFor="let emoji of emojis" (click) = "select(emoji)" class='emoji'>
    <span *ngIf = "emoji != 0">
      {{ emoji.u }}
    </span>
    <span *ngIf = "emoji == 0" class = "emoji">
      <span></span>
    </span>
  </div>
</div>
