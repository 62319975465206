import {Injectable} from '@angular/core';
import {Config} from './config/config';
import { UtilsService } from "./utils.service";
import {Platform, ActionSheetController, AlertController, LoadingController} from '@ionic/angular';
declare var AWS: any;

import {Camera} from '@ionic-native/camera/ngx';
import {File} from '@ionic-native/file/ngx';
import {ProfilePictureService} from "./profile-picture.service";

//NOT IMPORTED ANYWHERE//
@Injectable({
  providedIn: 'root',
})
export class ChatPictureService {
  public bucket : any;
  public awsAccessKeyId: string = 'AKIAJEVIF72XB46E7EKQ';
  public awsSecretAccessKey: string = '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';
  public awsRegion: string = 'us-east-1';
  public awsBucket: string = 'me3.in-chat-pictures';
  public loading = null;

  constructor(public actionSheetCtrl: ActionSheetController,
              public config: Config,
              public loadingCtrl: LoadingController,
              public alertCtrl: AlertController,
              public platform: Platform,
              private file: File,
              private profilePictureService: ProfilePictureService,
              public utils: UtilsService,
              private camera: Camera){

    AWS.config.update({ 
      accessKeyId: this.awsAccessKeyId,
      secretAccessKey: this.awsSecretAccessKey
    });
    AWS.config.region = this.awsRegion;
    this.bucket = new AWS.S3({
      params: {
        Bucket: this.awsBucket
      }
    });
  }

  async showLoading(){
    this.loading = await this.loadingCtrl.create({
      message: "Adding image...",
    });
    await this.loading.present();
  }

  doneLoading(){
    if(this.loading) {
      this.loading.dismiss().then(_=> {
        this.loading = null;
      }, err => {
        console.log(err);
      });
    }
  }

  uploadToS3(key, buffer) {
    return new Promise((resolve, reject) => {
      /*
      let binaryString = atob(base64String);
      let byteArray = new Uint8Array(binaryString.length);
      for (var i = 0; i < binaryString.length; i++)  {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      */
      
      let params = {
        Key: key + '.jpg', 
        Body: buffer,
        ContentType: 'image/jpeg',
        ContentEncoding: 'base64',
        CacheControl: 'max-age=1296000'
      };

      this.bucket.upload(params, (err, data) => {
        this.doneLoading();
        if(err) {
          reject(err);
        } else {
          resolve(data.Location);
        };
      });
    });
  }

  takePWAPicture(key) {
    const el = document.getElementById('camera-input') as any;
    el.value = "";//Sometimes when uploading many in a row the control needs to be reset (chrome)
    return new Promise((resolve, reject) => {
      el.onchange = async d => {
        if(el.files.length == 0) {
          return null;
        }

        await this.utils.showLoading('Loading picture...');
        try {
          (<any> window).profileService = this;
          const reader = new FileReader();
          reader.onload = async (r: any) => {
            let base64 = r.target.result;
            base64 = base64.replace(/data:image\/(png|jpeg|jpg);base64,/g, "");
            let imageUrl = await this.profilePictureService.uploadBase64ToS3(key, base64);
            this.utils.doneLoading();
            resolve(imageUrl);
          };
          reader.readAsDataURL(el.files[0]);
        } catch(err) {
          this.utils.doneLoading();
        }
      };
      el.click();
    });
  }

  takePicture(sourcetype, forKey, uploadS3: boolean = true){
    return new Promise(async (resolve, reject) => {
      this.camera.getPicture({
        quality : 75,
        destinationType: this.camera.DestinationType.FILE_URI,
        sourceType: sourcetype,
        allowEdit: false,
        encodingType: this.camera.EncodingType.JPEG,
        targetWidth: 1200,
        targetHeight: 1200,
        correctOrientation: true,
        saveToPhotoAlbum: false
      }).then(imageData => {
        this.showLoading();
        let a = imageData.split('/')
        let fi = a[a.length - 1];
        let fo = imageData.replace('/' + fi, '');

        let paramsIndex = fi.lastIndexOf('?');
        if(paramsIndex > -1) {
          let extra = fi.substring(paramsIndex);
          fi = fi.replace(extra, '');
        }

        this.file.readAsArrayBuffer(fo, fi).then(arrayBuffer => {
          if(uploadS3){
            this.uploadToS3(forKey, arrayBuffer).then(resolve, reject);
          }else{//used for sharing images to social media
            resolve(imageData);//might be imageData
            this.doneLoading();
          }
        }, (err) => {
          this.doneLoading();
          reject(err);
        });
      }, (err) => {
        this.doneLoading();
        if(err != "No Image Selected") {
          reject(err);
        }
      });
    });
	}
}
