<form [formGroup] = "profileForm">
  <ion-grid>
    <ion-row>
      <ion-item style="--border-width: 0" >
        <ion-label position="stacked" [class.validation-error]="nameFControl.errors">First Name <span *ngIf="nameFControl.errors">- Required </span></ion-label>
        <ion-input type="text" formControlName="name" [class.validation-error]="nameFControl.errors" placeholder = "Enter your name" ></ion-input>
      </ion-item>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <ion-item style="--border-width: 0" class="gender-form" [class.error-profile]="!profileForm.get('gender').valid">
          <ion-label position="stacked">Gender</ion-label>
          <ion-select formControlName="gender">
            <ion-select-option value="male">Male</ion-select-option>
            <ion-select-option value="female">Female</ion-select-option>
            <ion-select-option value="non-binary">Non-binary</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>

      <ion-col size="6">
        <ion-item style="--border-width: 0" [class.error]="!profileForm.get('birthdayDate').valid && profileForm.get('birthdayDate').touched">
          <ion-label position="stacked">Date of Birth</ion-label>
          <ion-datetime displayFormat="MM/DD/YYYY" formControlName="birthdayDate"></ion-datetime>
        </ion-item>
        <div class="error-box" *ngIf="profileForm.get('birthdayDate').hasError('ageError')">
          * {{profileForm.get('birthdayDate').errors.ageError}}
        </div>
      </ion-col>
    </ion-row>
    <!-- warning-->
    <ion-row class = "error-message" *ngIf = "profileForm.get('birthdayDate').hasError('ageError')">
      <b>WARNING</b>
      You may be banned if you intentionally misrepresent your gender or date of birth, as per our Terms of Use.
    </ion-row>
  </ion-grid>

  <div class="alert-button-group">
    <div class = "alert-button" (click) = "Cancel()">Cancel</div>
    <div class = "alert-button" [class.disabled] = "profileForm.invalid" (click) = "submit()">Update</div>
  </div>
</form>
