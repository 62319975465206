import { Component, Input } from "@angular/core";
import { Config } from "src/app/services/config/config";
import { TribesService } from "src/app/services/data/tribes.service";
import { PopupService } from "src/app/services/popups/popup.service";
import { UtilsService } from "src/app/services/utils.service";
import { ModalService } from "src/app/services/popups/modal.service";
import { getExpiryColors, isDev } from "src/app/shared/helpers/helpers";
import { logc } from "src/app/shared/helpers/log";
import { hoursLeft } from "src/app/shared/helpers/time-helpers";
import { UserTribeStatus } from "src/app/shared/enums/user-tribe-status.enum";
import { UpsellingPoppingReason } from "src/app/pages/upselling/upselling.page";

const EXPIRY_THRESHOLD = 12;
export const userExpiresSoon = ({ expires_at }) => expires_at ? hoursLeft(expires_at) < EXPIRY_THRESHOLD : false;


type ExtendExpiryStateName = "extendBoth" | "extendOne" | "extended" | "default";
interface ExtendExpiryPageState {
  title: string;
  description: string;
  callback: Function;
}

@Component({
  selector: "extend-expiry-section",
  template: `
    <div *ngIf = "showSection" class = "
      fixed top-[60px] w-[90%] z-[1000] 
      bg-surface m-[15px] flex shadow-md 
      rounded-[5px] p-[20px] gap-[15px]
      items-center justify-left
      border-[1px] border-line
    " [ngClass] = "{'fixed top-[90px] w-[90%]': source == 'chat'}">
      <close-button *ngIf = "showCloseButton" 
                    class = "absolute z-[1000] top-[5px] right-[5px]" 
                    (click) = "close()"></close-button>
      <div class = "flex rounded-full p-[5px] {{ getExpiryColors(soonerExpirationUser) }}">
        <ion-icon name = "timer-outline" class = "text-xl shrink-0"></ion-icon>
      </div>
      <div class = "text-left" (click) = "state?.callback()">
        <div class = "text-base text-title font-semibold" [innerText] = "state?.title"></div>
        <div class = "text-small text-body" [innerHTML] = "state?.description"></div>
      </div>
    </div>
  `
})
export class ExtendExpirySectionComponent {
  @Input() users: any = null;
  @Input() tribe: any = {};
  @Input() source: string = null;
  @Input() showCloseButton: boolean = true;
  public soonerExpirationUser: any = null;
  public showSection: boolean = false;
  private stateName: ExtendExpiryStateName = null;  
  public state: ExtendExpiryPageState = null;

  private setState = (name, ctx) => ({
    extendBoth: {
      title: `Give them more time?`,
      description: `Extend the expiry by 24 hours. <span class = "text-primary bold">Extend</span>`,
      callback: () => this.extendExpiry()
    },
    extendOne: {
      title: `Give ${ ctx?.first_name } more time?`,
      description: `Extend the expiry by 24 hours. <span class = "text-primary bold">Extend</span>`,
      callback: () => this.extendExpiry()
    },
    extended: {
      title: `You extended their expiry time`,
      description: `You gave them 24 more hours to join.`,
      callback: () => {}
    },
    default: {
      title: "Waiting for them to join",
      description: `If ${ ctx?.first_name }'s time runs out, we'll suggest another user you're compatible with.`,
      callback: () => {}
    }
  }[name])

  constructor(private popupService: PopupService,
              private config: Config,
              private tribesService: TribesService,
              private modalService: ModalService) {
    if(isDev()) {
      (window as any).expirySection = this;
    }
  }
  
  ngOnChanges() {
    this.soonerExpirationUser = this.users
      .sort((a, b) => new Date(a.expires_at).getTime() - new Date(b.expires_at).getTime())[0];
    this.showSection = this.users[0].status === UserTribeStatus.Invited &&
      !this.config.getFlag("skippedExtendExpiry", {})[this.tribe.id];
    logc.crimson("showSection: ", this.showSection);
    this.stateName = this.getStateName(this.users);
    this.state = this.setState(this.stateName, this.soonerExpirationUser);
    logc.info("state: ", this.state);
  }

  getStateName(users): ExtendExpiryStateName {
    let name: ExtendExpiryStateName = 'default';
    if(users.length == 1) {
      if(users[0]?.on_extended_invite) {
        name = 'extended';
      } else if(userExpiresSoon(this.users[0])) {
        name = 'extendOne';
      }
    } else {
      if(users.every(userExpiresSoon)) {
        name = 'extendBoth';
      }
    }
    return name;
  }
  
  extendExpiry(): void {
    if(!this.config.isPlus()) {
      this.modalService.openUpselling({
        source: 'extend_expiry_section',
        reason: UpsellingPoppingReason.ExtendExpiryTime
      })
      return;
    }
    this.popupService.createPopup({
      popupName: "extendExpiryPopup",
      options: {
        users: this.users,
        callback: () => {
          this.tribesService.extendExpiry({
            users: this.users,
            tribeId: this.tribe.id
          });
        }
      }
    })
  }

  formatExpiriesString(users: any[] = []) {
    if(users.filter(userExpiresSoon)?.length > 1) {
      return "them";
    } else {
      return users.find(userExpiresSoon).first_name;
    }
  }

  getExpiryColors(user): string {
    return getExpiryColors(user);
  }

  close() {
    this.showSection = false;
    let skippedExtendExpiry = this.config.getFlag("skippedExtendExpiry") || {};
    skippedExtendExpiry[this.tribe.id] = true;
    this.config.setFlag("skippedExtendExpiry", skippedExtendExpiry);
  }
}
