import {Component, ViewChild} from '@angular/core';
import {NavParams, ModalController, PopoverController} from '@ionic/angular';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import {UserService} from '../../../services/data/user.service';
import {SessionService} from '../../../services/data/session.service';
import {AnalyticsService} from '../../../services/analytics/analytics.service';

@Component({
  selector: 'profile-school-popup',
  templateUrl: 'profile-school-popup.html',
  styleUrls: ['profile-school-popup.scss']
})
export class ProfileSchoolPopupComponent {
  public schoolForm: FormGroup;
  public name : any;

  @ViewChild('schoolInput') schoolInput;

  constructor(public modalCtrl: ModalController,
              public formBuilder: FormBuilder,
              public usersService: UserService,
              public navParams: NavParams,
              public session: SessionService,
              public popoverCtrl: PopoverController,
              public analyticsService: AnalyticsService) {

    this.schoolForm = this.formBuilder.group({
      'school': [this.navParams.get('school')]
    });

    this.name = this.schoolForm.get('school');
  }

  ngAfterViewInit() {
    setTimeout(() => this.schoolInput.setFocus(), 0);
  }

  submit(){
    if(this.schoolForm){
      let schoolName = this.schoolForm.get('school').value;
      this.usersService.details.school = schoolName;
      this.usersService.updateProfile({school: schoolName}).then(_=>{
        this.popoverCtrl.dismiss();
        this.analyticsService.trackEvent({key: "school_set", value: 1 });
      });
    }
  }

  cancel(){
    this.popoverCtrl.dismiss();
  }
}
