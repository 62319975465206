import { Injectable } from '@angular/core';
import {TribesPage} from "../../pages/tribes/tribes.page";

@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  constructor() {
  }

  collectData(classObject: any) {
    return {
      className: classObject.name,
      methodsLength: Object.keys(classObject.prototype).length
    }
  }

}
