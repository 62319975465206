import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { createLogger, logc } from "../shared/helpers/log";
import { environment } from "../../environments/environment";
import { BehaviorSubject, Observable, of } from "rxjs";

const IP_DEFINING_URL = "http://api.ipify.org/?format=json";
const IP_INFO_URL = "https://iplist.cc/api/";

const LOG_STYLES = { background: "black", color: "red" };
const DEFAULT_IP_INFO = { countryname: "", countrycode: "" };

const log = createLogger(LOG_STYLES);

@Injectable({ providedIn: "root" })
export class IpService {
  private ip: string = "0.0.0.0";
  private ipInfo = null;

  public onIpInfoFetched = new BehaviorSubject(DEFAULT_IP_INFO);

  constructor(private http: HttpClient) {
    if (environment.name == "development") {
      (window as any).ip = this;
    }
  }

  getIpInfo() {
    return this.ipInfo;
  }

  getIp() {
    return this.ip;
  }

  init(): Observable<any> {
    if (this.ipInfo) return;
    log("-- IpService initialized --");
    this.prepareData();
    return of(this.ipInfo);
  }

  private async prepareData() {
    try {
      logc.blue("fetching ip...");
      const { ip } = await this.fetchIp();
      this.ipInfo = await this.fetchIpInfo(ip);
      logc.blue("ipInfo: ", this.ipInfo);
      this.onIpInfoFetched.next(this.ipInfo);
    } catch (e) {
      console.log("-- Preparing IP service data error: ", e);
      this.ipInfo = DEFAULT_IP_INFO;
    }
  }

  private fetchIp() {
    return this.http.get<{ ip: string }>(IP_DEFINING_URL).toPromise();
  }

  private fetchIpInfo(ip: string) {
    return this.http.get<any>(IP_INFO_URL + ip).toPromise();
  }
}
