import { Directive, ElementRef, Input } from '@angular/core';

@Directive({ selector: '[loadSrc]' })
export class LoadSrcDirective {
  @Input() loadSrc: string;

  constructor(public el: ElementRef) { }

  ngOnInit() {
    let el = this.el.nativeElement;
    if(this.loadSrc) {
      const img = new Image();
      img.src = this.loadSrc;
      img.onload = _ => {
        el.innerHTML = img.outerHTML;
      };
    }
  }
}
