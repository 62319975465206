import {Storage} from '@ionic/storage'
import {Injectable} from '@angular/core';
import {logc} from "../helpers/log";

@Injectable({
  providedIn: 'root',
})
export class Saveable {
  data: any;
  loaded = false;
  loadPromise: any = null;
  key: string;
  defaultConfig: any = {};
  unableToSaveError: any = { message: 'Unable to save' };

  constructor(public storage: Storage) { }

  static dataToString(data){
    return JSON.stringify(data, null, 2);
  }

  stringifyData(data){
    return Saveable.dataToString(data);
  }

  //Override this when you need to load data from the device
  prepareConfig(): Promise<void> {
    return new Promise((resolve, reject) => resolve());
  }

  private locked: boolean = false;
  reset() {
    this.loaded = false;
    this.locked = true;
    this.resetCache();
    this.init().then(() => this.locked = false);
  }

  init() {
    return new Promise((resolve, reject) => {
      this.prepareConfig().then(
        _=> {
          if(this.defaultConfig && this.defaultConfig.constructor == Object){
            //Objects get copied by reference
            this.data = {};
            Object.keys(this.defaultConfig).forEach(
              (key) => {
                let val = this.defaultConfig[key];
                if(val && val.constructor == Object)
                  val = JSON.parse(JSON.stringify(val));
                this.data[key] = val;
              }
            );
          } else {
            this.data = JSON.parse(JSON.stringify(this.defaultConfig));
          }
          this.save().then(resolve, reject);
        }, reject);
    });
  }

  update(key, value){
    this.data[key] = value;
  }

  deleteKey(){
    this.storage.remove(this.key);
  }

  save(): Promise<void> {
    return new Promise( (resolve, reject) => {
      this.storage
        .set(this.key, this.stringifyData(this.data))
        .then(
          () => resolve(),
          (err) => reject({ err, info: this.unableToSaveError })
        );
      });
  }

  get(key) {
    /*if(!this.loaded) {
      await this.load();
    }*/

    let keys:any = Object.keys(this.data || {});
    if (keys.includes(key)){
      return this.data[key];
    } else {
      return this.defaultConfig[key];
    }
  }

  set(key, value) {
    console.log(`CONFIG SET -> ${key}`, value);
    this.data[key] = value;
    this.save();
  }

  resetCache(){
    this.loadPromise = null;
    this.loaded = false;
  }

  unlocked(): Promise<void> {
    return new Promise((resolve, reject) => {
      if(this.locked) {
        setTimeout( _ =>  this.unlocked().then(resolve, reject), 200 );
      } else {
        resolve();
      }
    });
  }

  async load() {
    await this.unlocked();

    if(this.loadPromise)
      return this.loadPromise;

    this.loadPromise = new Promise<void>((isReady, failed) => {
      if(this.loaded)
        return isReady(this.data);

      this.storage.ready().then( _ => {
        this.storage.get(this.key).then((config) => {
          if(config) {
            this.loaded = true;
            var data = JSON.parse(config);
            this.data = data;
            isReady(data);
          } else {
            this.init().then(() => isReady(), failed );
          }
        });
      });
    });
    return this.loadPromise;
  }
}
