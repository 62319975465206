import { Component, OnInit, Input } from "@angular/core";
import { Config } from "../../services/config/config";
import { UtilsService } from "../../services/utils.service";

@Component({
  selector: "location-section",
  template: `
    <ion-grid class="mx-[15px]">
      <ion-row>
        <ion-text>
          <div class="text-base text-title">
            {{ dataLocation?.location | map : formatLocation }}
          </div>
        </ion-text>
      </ion-row>
      <ion-row>
        <ion-text>
          <div class="text-small text-body">
            {{ dataLocation.distance }} {{ defaultUnitSystem }} away
          </div>
        </ion-text>
      </ion-row>
    </ion-grid>
  `,
  styleUrls: ["./location-section.component.scss"],
})
export class LocationSectionComponent {
  @Input() dataLocation: any = {};
  defaultUnitSystem: string;

  constructor(public config: Config, private utils: UtilsService) {
    this.dataLocation.distance = this.utils.getDistanceForUnits(
      this.dataLocation.distance
    );
    this.defaultUnitSystem = config.data.matchPreferences.defaultUnitSystem
      ? config.data.matchPreferences.defaultUnitSystem
      : config.defaultConfig.defaultUnitSystem;
  }

  formatLocation(locationData: any): string {
    if (!locationData) return "";

    const { closest_city, closest_country } = locationData;

    if (!closest_city && !closest_country) return "";
    if (
      closest_city &&
      closest_city.toLowerCase() === "no city" &&
      closest_country &&
      closest_country.toLowerCase() === "no country"
    ) {
      return "";
    }
    return `${closest_city}, ${closest_country}`;
  }
}
