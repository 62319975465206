import { Component, Input, NgZone } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { SessionService } from '../../services/data/session.service';
import { Subject } from "rxjs/Subject";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'page-status',
  templateUrl: 'page-status.html',
  styleUrls: ['page-status.scss'],
})
export class PageStatus {

  @Input() isFullPage;

  public showUpdateMessage = false;

  public unsubscribe = new Subject();

  constructor(public utils:UtilsService,
              public session: SessionService,
              public ngZone: NgZone){

    this.showUpdateMessage = !this.session.isAppLatestVersion();

    this.session
      .onReady
      .pipe(takeUntil(this.unsubscribe))
      .subscribe( _ => {
        this.ngZone.run(async () => {
          this.showUpdateMessage = !this.session.isAppLatestVersion();
        });
      });
  }

  goToAppStore() {
    this.utils.goToAppStore();
  }

  ngOnInit(){}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
