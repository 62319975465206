import {fromNullable} from "../../shared/helpers/either-monad";

export const getReferralInvitationRewardDescription =
    referralPageStateName =>
      fromNullable({
        plus_1_days: `Get We3 Plus for free`,
        plus_3_days: `Get We3 Plus for free`,
        plus_7_days: `Get We3 Plus for free`,
        give_drinks_unlimited: `Give them a free drink`,
        give_drinks_2_friends: `Give them a free drink`
      }[referralPageStateName])
        .fold(notExist => "Get a reward", success => success)

