import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { logc } from 'src/app/shared/helpers/log';

@Component({
  selector: 'button-with-success-state',
  template: `
    <div class = "flex items-center justify-center w-full h-[60px]">
      <ion-button *ngIf = "!activated; else activatedButton"
                  [style.opacity] = "clicked ? 0 : 1" 
                  [disabled] = "disabled"
                  [class.disabled-button] = "disabled"
                  class = "turquoise-gradient-btn btn-large-action ionic-button turquoise-btn-shadow duration-300 {{ cssClass }}"
                  shape = "round"
                  (click) = "emit()">
        <ng-content></ng-content>
      </ion-button>
      <ng-template #activatedButton>
        <div class = "opacity-0 flex items-center justify-center gap-[10px] fade-in-animation">
          <ion-icon class = "text-success text-large" name = "checkmark-circle"></ion-icon>
          <div class = "font-semibold text-large text-success" [innerText] = "activatedText"></div>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./button-with-success-state.component.scss'],
})
export class ButtonWithSuccessStateComponent implements OnInit {
  @Input() activate: boolean = false;
  @Input() activatedText: string = "";
  @Input() disabled: boolean = false;
  @Input() cssClass: string = "";
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  public clicked: boolean = false;
  public activated: boolean = false;
  constructor() { }

  ngOnInit() {
    if(this.activate) {
      this.activated = true;
    }
  }

  ngOnChanges(changes) {
    const activate = changes['activate'];
    if(activate && !activate.previousValue && activate.currentValue == true) {
      this.clicked = true;
      setTimeout(() => this.activated = true, 300);
    }
  }

  emit() {
    this.onClick.emit(null)
  }
}
