import { Injectable } from '@angular/core';
import { differenceInMilliseconds, hoursToMilliseconds } from 'date-fns';
import { combineLatest, interval, Observable, of, Subject, timer } from 'rxjs';
import { finalize, map, scan, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { isDev } from 'src/app/shared/helpers/helpers';
import { logc } from 'src/app/shared/helpers/log';

@Injectable({
  providedIn: 'root',
})
export class CountdownTimerService {
  public nextAttempt: any;
  public now: any;
  public sec_num: any;
  public days: any;
  public hours: any;
  public minutes: any;
  public seconds: any;
  public tryingAt: any = {};

  public onTimerFinish: Subject<boolean> = new Subject();

  constructor() {
    if(isDev()) {
      (window as any).timer = this;
    }
  }

  createCountdown(finishDate: Date): Observable<string> {
    if(!finishDate) return;

    return of(differenceInMilliseconds(finishDate, Date.now()))
      .pipe(
        switchMap((value) => 
          interval(1000)
            .pipe(
              map(() => value -= 1000),
              map(this.msToTime),
              map((string: string) => string ? string : null),
              takeWhile(v => !!v, true)
            )
          )
        )
  }

  tryingAgainAt(tryingDate){
    this.tryingAt = this.getGenericTime(tryingDate);
    if(this.tryingAt.hours < 10)
      this.tryingAt.hours = "0"+ this.tryingAt.hours;
    if(this.tryingAt.minutes < 10)
      this.tryingAt.minutes = "0"+ this.tryingAt.minutes;
    if(this.tryingAt.seconds < 10)
      this.tryingAt.seconds = "0"+ this.tryingAt.seconds;

    if(this.tryingAt.days > 0)
      return this.tryingAt.days+'d '+this.tryingAt.hours+'h '+this.tryingAt.minutes+'m';
    else
      return this.tryingAt.hours+'h '+this.tryingAt.minutes+'m '+this.tryingAt.seconds+'s';
  }

  getGenericTime(datetime){
    this.nextAttempt = new Date(datetime);
    this.now = new Date();
    this.sec_num = ((this.nextAttempt - this.now) - 5000) / 1000;
    this.days = Math.floor(this.sec_num / (3600 * 24));
    this.hours = Math.floor((this.sec_num - (this.days * (3600 * 24)))/3600);
    this.minutes = Math.floor((this.sec_num - (this.days * (3600 * 24)) - (this.hours * 3600)) / 60);
    this.seconds = Math.floor(this.sec_num - (this.days * (3600 * 24)) - (this.hours * 3600) - (this.minutes * 60));
    return {days: this.days, hours: this.hours, minutes: this.minutes, seconds: this.seconds};
  }

  getFullHours(datetime): number {
    const { days, hours } = this.getGenericTime(datetime);
    return days * 24 + hours;
  }

  private msToTime = (ms) => {
    const formatZero = (value) => `${ value < 10 ? 0 : "" }${ value }`;
    const secondsSum = Math.round(ms / 1000);
    const hours = Math.floor(secondsSum / 3600);
    const minutes = Math.floor((secondsSum - hours * 3600) / 60);
    const seconds = Math.floor((secondsSum - hours * 3600 - minutes * 60));
    return ms >= 0
      ? `${ formatZero(hours) }:${ formatZero(minutes) }:${ formatZero(seconds) }`
      : null;
  }
}
