import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Saveable } from '../../shared/structure/saveable';
import { Storage } from '@ionic/storage';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { environment } from '../../../environments/environment';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Subject } from 'rxjs/Subject';
import amplitude from 'amplitude-js';
import {logc} from "../../shared/helpers/log";

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends Saveable {
  private newEventSource = new Subject();
  private propertySetSource = new Subject();

  public key: string = 'analytics';
  public context: any = '';
  public config:any = null;
  public oneSignalTrackedKeys: any = [
    'signup', 'picture_status', 'completed_level',
    'confirmed_number', 'first_name', 'started_search_count'
  ];
  public userId = null;

  public onNewEvent: any;
  public onPropertySet: any;

  defaultConfig: any = {}

  isContext(context) {
    return this.context === context;
  }

  setConfig(config){
    this.config = config;
  }

  setContext(context:any){
    this.context = context;
  }

  clearContext(){
    this.context = '';
  }
  
  constructor(public platform: Platform,
              private oneSignal: OneSignal,
              private firebase: FirebaseX,
              public storage: Storage) {
    super(storage);

    this.onNewEvent = this.newEventSource.asObservable();
    this.onPropertySet = this.propertySetSource.asObservable();

    const options = {};
    amplitude.getInstance().init("951d24f3c93e41c1bd5d82195c202818", null, options);
    (<any>window).amplitude = amplitude;

    if(!environment.production) {
      amplitude.getInstance().setOptOut(true);//change to true
      if(this.platform.is('cordova')) {
        this.firebase.setAnalyticsCollectionEnabled(false);
      } else {
        if((<any>window).firebase) {
          (<any>window).firebase.analytics().setAnalyticsCollectionEnabled(false);
        }
      }
    }

    (<any> window).firebaseAnalytics = this.firebase;
    (<any> window).analyticsService = this;
  }

  tryInit(profile, userId, hasPushPermissions): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.init().then(async _ => {
        await this.initProperties(profile, userId, hasPushPermissions);
        return resolve();
      }, reject);
    });
  }

  trackAppStart() {
    this.trackEvent({ 
      key: 'app_start', 
      value: 1, 
      app_version: this.config.version,
      platform: this.config.platformName
    });
  }

  initProperties(profile, userId, hasPushPermissions): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getAnalytics().then( (data: any)=> {
        this.setUserId(userId);
        resolve();
      }, reject);
    });
  }

  getPseudoId() {
    //Not yet possible on the web version
    //Not super useful given that we already have the user id
    /*
    if(this.platform.is('cordova')) {
      return this.firebase.getAppInstanceId();
    } else {
      if(this.config.isPWA && this.config.platformName == 'dev')
        return (<any>window).firebase.analytics().getAppInstanceId();
    }
    */
  }

  setUserId(id) {
    this.userId = id;
    if(this.platform.is('cordova')) {
      console.log('adding property: userId, val: ' + id);
      setTimeout( _ => {
        this.firebase.setUserId(id.toString());
      });
    } else {
      if(this.config.isPWA && environment.production) {       
        setTimeout( _ => {
          (<any>window).firebase.analytics().setUserId(id.toString());
          let props = {}
          props['user_id'] = id.toString();
          (<any>window).firebase.analytics().setUserProperties(props);
        });
      } else {
        console.log('--- Tracking property change ---');
        console.log('Setting userId: ' + id);
      }
    }

    amplitude.getInstance().setUserId(id.toString());

    this.trackAppStart();
  }

  //Some analytics systems (Firebase mostly) has issues handling properties 
  //of different types in iOS. This is probably related to Safari.
  prepareValue(value) {
    if(value == null) {
      return 'ND';
    } else {
      console.log("attempted value: ", value);
      return value.toString();
    }
  }

  reset() {
    this.config = null;
    this.userId = null; 
    return super.reset();
  }

  reInit() {
    return this.reset();
  }

  cleanup(config): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.reInit();
      if(this.platform.is('cordova'))
        this.oneSignal.deleteTags(this.oneSignalTrackedKeys);
      this.config = config;
      resolve();
    });
  }

  getAnalytics(): Promise<void> {
    return new Promise((resolve, reject) => {
      //this.userService.fetchAnalytics().then(resolve, reject);
      resolve();//We might add some here later
    });
  }

  trackProperty(prop) {
    console.log('for: ', prop.key);
    let val = this.prepareValue(prop.value);

    setTimeout( _ => {
      var identify = new amplitude.Identify();
      identify.set(prop.key, val);
      amplitude.getInstance().identify(identify);
    });

    if(!environment.production) {
      console.log('--- Tracking property change ---');
      console.log('key: ' + prop.key);
      console.log('value: ' + val);
      return this.propertySetSource.next({ name: prop.key, value: val});
    }

    if(this.platform.is('cordova')) {
      setTimeout( _ => {
        console.log('--- Tracking property change ---');
        console.log('adding property: ' + prop.key + ' , val: ' + val);
        if(this.oneSignalTrackedKeys.includes(prop.key))
          this.oneSignal.sendTag(prop.key, val);
        this.firebase.setUserProperty(prop.key, val);
      });//Properties are not time dependent
    } else {
      if(this.config.isPWA && this.config.platformName == 'pwa') {
        setTimeout( _ => {
          let props = {}
          props[prop.key] = val;
          (<any>window).firebase.analytics().setUserProperties(props);
        });
      }
    }
  }

  trackEvent(ev) {
    logc.analytics('analytics Service tracking event', ev);
    //include it all except the key in firebase
    let firebaseEventDetails = Object.keys(ev)
      .filter(key => key !== 'key')
      .reduce((obj, key) => {
        obj[key] = ev[key];
        return obj;
      }, {});

    amplitude.getInstance().logEvent(ev.key, firebaseEventDetails);
    if(!environment.production) {
      firebaseEventDetails['user_id'] = (this.userId || '').toString();
      logc.analytics('--- Tracking new event ---');
      logc.analytics('key: ' + ev.key);
      logc.analytics('value: ' + ev.value);
      logc.analytics('eventDetails:', firebaseEventDetails);
      return this.newEventSource.next({name: ev.key, params: firebaseEventDetails});
    }

    if(this.platform.is('cordova')) {
      setTimeout( _ => {
        this.firebase.logEvent(ev.key, firebaseEventDetails);
        logc.analytics('adding event: ' + ev.key + ' , val: ' + ev.value);
        logc.analytics('eventDetails:', firebaseEventDetails);
      });
    } else {
      if(this.config.isPWA && environment.production) {
        //For some reason th user_id is not set 
        firebaseEventDetails['backup_user_id'] = (this.userId || '').toString();

        this.newEventSource.next({name: ev.key, params: firebaseEventDetails});
        setTimeout( _ => {
          (<any>window).firebase.analytics().logEvent(ev.key, firebaseEventDetails);
        });
      }
    }
  }
}
