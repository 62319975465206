import { Injectable } from '@angular/core';
import { filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { MenuController } from "@ionic/angular";
import {Observable, Subject} from "rxjs";
import Timeout = NodeJS.Timeout;
import { isDev } from '../shared/helpers/helpers';

interface RouteChangeEvent {
  previous: string;
  current: string;
}

@Injectable({
  providedIn: 'root'
})
export class RouteTrackerService {

  // public routeChange = new Subject();

  private menuAllowedPages: string[] = ['/tabs/tribes', '/tabs/profile'];
  private previousPage: string = null;
  private currentPage: string = null;

  private timeout: Timeout;

  private routeChangedSource: Subject<RouteChangeEvent> = new Subject();
  public onRouteChanged: Observable<RouteChangeEvent>;

  private history: any[] = [];

  constructor(private router: Router,
              private menu: MenuController) {
    if(isDev()) {
      (window as any).routeTrackingService = this; 
    }
    this.onRouteChanged = this.routeChangedSource.asObservable();
  }

  init() {
    this.router
      .events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(event => {
        // this.routeChange.next(event);
        // console.log(`page is ${(<any>event).url}`);

        let url: string = (<any>event).url;
        this.history.push(url);
        this.changeStates(url);
        console.log(`%c (route) previous page: ${ this.previousPage } `, 'background-color: green; color: white;');
        console.log(`%c (route) current page: ${ this.currentPage } `, 'background-color: green; color: white;');

        clearTimeout(this.timeout);
        let menuEnabled: boolean = this.menuAllowedPages.includes(url);
        this.timeout = setTimeout(async () => {
          await this.menu.enable(menuEnabled, 'first');
          // console.log('menu is enabled: ', menuEnabled);
        }, menuEnabled ? 0 : 750);
      });
  }

  getHistory(): string[] {
    return this.history;
  }

  changeStates(url: string): void {
    this.previousPage = this.currentPage;
    this.currentPage = url;
    this.routeChangedSource.next({
      previous: this.previousPage,
      current: this.currentPage
    })
  }

  getPreviousPage(): string {
    return this.previousPage;
  }

  getCurrentPage(): string {
    return this.currentPage;
  }

}
