import {Component, EventEmitter, Input, Output} from '@angular/core';

interface ILightButtonBody {
  style: any,
  icon: string,
  text: string,
  rotate?: boolean
}

@Component({
  selector: 'light-button',
  template: `
    <div class = "light-button {{ cssClass }}" 
         [class.empty] = "empty"
         (click) = "emit($event)">
      <ion-icon [class.rotated] = "rotating" 
                [class] = "buttonBody.style" 
                [name] = "buttonBody.icon"></ion-icon>
      <div class = "light-button-text">{{buttonBody.text}}</div>
    </div>
  `,
  styleUrls: ['./light-button.component.scss'],
})
export class LightButtonComponent {
  @Input('cssClass') cssClass: string = '';
  @Input("empty") empty: boolean = false;
  @Input('buttonBody') buttonBody: ILightButtonBody = {
    style: "small",
    icon: '',
    text: '',
  }
  @Output() onClick = new EventEmitter();

  public rotating: boolean = false;

  constructor() { }

  emit(event) {
    if(this.buttonBody.rotate) {
      this.rotating = !this.rotating;
    }
    this.onClick.emit(event);
  }

}
