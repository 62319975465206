import {Injectable} from '@angular/core';
import {CountdownTimerService} from "./helpers/countdown-timer.service";
import {DeclineEducationalPopup, RejectEducationalPopup, TribeHighlightsService} from "./tribe-highlights.service";
import {InfoPopoverComponent} from "../components/info-popover/info-popover.component";
import {AlertController, ModalController, NavController, PopoverController} from "@ionic/angular";
import {MenuItem} from "./menu.service";
import {UserTribeStatus} from "../shared/enums/user-tribe-status.enum";
import {MenuPopoverComponent} from "../components/menu-popover/menu-popover.component";
import {UserService} from "./data/user.service";
import {SearchService} from "./data/search.service";
import {TribesService} from "./data/tribes.service";
import {PopupService} from "./popups/popup.service";
import {BuilderService} from "./helpers/builder.service";
import {InstructionsComponent} from "../components/instructions/instructions.component";
import {logc} from "../shared/helpers/log";

import * as _ from "lodash";
import {Subject} from "rxjs/Subject";
import {keys} from "../shared/helpers/helpers";
import {DispatcherService} from "./dispatcher.service";
import {startWith} from "rxjs/operators";
import {Config} from "./config/config";
import {TribeStatus} from "../shared/enums/tribe-status.enum";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstructionsService {

  private closeSource = new Subject();
  private instructionsShown: boolean = false;
  private instructionsShown$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private timerService: CountdownTimerService,
              private navCtrl: NavController,
              private config: Config,
              private alertCtrl: AlertController,
              private userService: UserService,
              private searchService: SearchService,
              private tribeHighlightsService: TribeHighlightsService,
              private dispatcherService: DispatcherService,
              private tribesService: TribesService,
              private popupService: PopupService,
              private buildService: BuilderService,
              private modalCtrl: ModalController,
              private popoverCtrl: PopoverController) {
    this.dispatcherService.onCloseInstructions.subscribe(() => this.hideInstructions());

    this.tribesService.onUserRejected.subscribe((data) => this.hideInstructions());
  }

  async showInstructions(tribe, anchor = document.body) {
    // logc.info("Instructions: ", this.getInstructions(tribe));
    const state = this.getInstructions(tribe);
    if(state && !this.instructionsShown) {
      await this.buildService.create(InstructionsComponent, {
        inputs: {
          state,
          close: this.closeSource
        }
      }, anchor);
      this.instructionsShown = true;
      this.instructionsShown$.next(true);
    }
  }

  getInstructionsStatus() {
    return this.instructionsShown$;
  }

  hideInstructions(): void {
    this.closeSource.next(null);
    this.instructionsShown = false;
    this.instructionsShown$.next(false);
  }

  private getInstructions(tribe) {
    if(!tribe) return;

    const { tribe_user: currentUser, users, status } = tribe;
    let mates = [...users, currentUser];
    let tribeUserStatus = currentUser.status;

    let defaultState = {
      header: { icon: 'group-add' },
      cssClass: 'primary-gradient'
    };

    let notExpiredTribemates = mates.filter(m => m.expires_at);

    let timeLeft = '';
    if (notExpiredTribemates.length) {
      timeLeft = this.getTimeLeft(notExpiredTribemates);
    }

    const standByMateName = users.find(user => user.status == 'standby')?.first_name;

    const states = {
      pending: {
        invited: {
          header: { title: 'Do you want to start this group?' },
          buttons: [
            { text: "More options", cssClass: "secondary", icon: "caret-down-outline", callback: () => this.openPassingMenu(event, tribe) },
            { text: "Start Group", cssClass: "primary", callback: () => this.performTribeAction({ type: "tribe", reason: "join" })}
          ]
        }
      },
      inviting: {
        invited: {
          header: { title: "Join this group before it expires.", details: timeLeft },
          buttons: [
            { text: "Pass", cssClass: "secondary", icon: "caret-down-outline", callback: () => this.openPassingMenu(event, tribe) },
            { text: "Join Group", cssClass: "primary", callback: () => this.performTribeAction({ type: "tribe", reason: "join" }) },
          ]
        }
      },
      forming: {
        invited: {
          header: { title: "Join this group before it expires.", details: timeLeft },
          buttons: [
            { text: "Pass", cssClass: "secondary", icon: "caret-down-outline", callback: () => this.openPassingMenu(event, tribe) },
            { text: "Join Group", cssClass: "primary", callback: () => this.performTribeAction({ type: "tribe", reason: "join" }) },
          ]
        }
      },
      confirming: {
        needs_review: {
          header: { title: `Do you want to approve ${standByMateName}?` },
          cssClass: "secondary-gradient",
          buttons: [
            { text: "More options", cssClass: "secondary", icon: "caret-down-outline", callback: (event) => this.openPassingMenu(event, tribe)},
            { text: "Approve", cssClass: "primary", callback: () => this.performTribeAction({ type: 'tribe', reason: "approve"}) }
          ]
        }
      },
      approving: {
        invited: {
          header: { title: "Join this group before it expires.", details: timeLeft },
          buttons: [
            { text: "Pass", cssClass: "secondary", icon: "caret-down-outline", callback: () => this.openPassingMenu(event, tribe) },
            { text: "Join Group", cssClass: "primary", callback: () => this.performTribeAction({ type: "tribe", reason: "join" }) },
          ]
        },
        needs_review: {
          header: { title: `Do you want to approve ${standByMateName}?` },
          cssClass: "secondary-gradient",
          buttons: [
            { text: "More options", cssClass: "secondary", icon: "caret-down-outline", callback: (event) => this.openPassingMenu(event, tribe)},
            { text: "Approve", cssClass: "primary", callback: () => this.performTribeAction({ type: 'tribe', reason: "approve"}) }
          ]
        }
      },
      proposed: {
        needs_review: {
          header: { title: 'Confirm this group or Pass for more options.' },
          cssClass: "secondary-gradient",
          buttons: [
            { text: "More options", cssClass: "secondary", icon: "caret-down-outline", callback: (event) => this.openPassingMenu(event, tribe)},
            { text: "Approve", cssClass: "primary", callback: () => this.performTribeAction({ type: "tribe", reason: "approve" })}
          ]
        }
      },
      in_progress: {
        invited: {
          header: { title: 'Select a user to be a part of this group.' },
          buttons: [
            { text: "Cancel", cssClass: "secondary", callback: () => this.performTribeAction({ type: "tribe", reason: "cancel_tribe" }) },
            { text: "View Matches", cssClass: "primary", callback: () => this.performTribeAction({ type: "tribe", reason: "matches_flow" }) },
          ]
        }
      },
      incomplete: {
        invited: {
          header: { title: "Join this group before it expires.", details: timeLeft },
          buttons: [
            {text: "Pass", cssClass: "secondary", icon: "caret-down-outline", callback: () => this.openPassingMenu(event, tribe)},
            {text: "Start Group", cssClass: "primary", callback: () => this.performTribeAction({type: "tribe", reason: "join"})},
          ]
        },
        accepted: {
          header: { title: "Add a member to complete this group.", icon: "waving-hand" },
          buttons: [
            { text: "Find the third match",
              cssClass: "primary",
              onDisabled:
                this.searchService
                  .onRejectionDisabled
                  .pipe(
                    startWith(this.searchService.reachedDailyRejectedUsersLimit())
                  ),
              disablingReason: this.config.isPlus() ? "" : 'too_many_rejections',
              callback: async () => {
                if(this.searchService.reachedDailyRejectedUsersLimit()) {
                  return this.dispatcherService.matchMePopoverSource.next({
                    action: "open",
                    name: "too_many_rejections"
                  });
                }

                await this.tribesService.startSearchingSwapFlow(tribe);
              }
            }
          ]
        }
      },
      awaiting: {
        invited: {
          header: {title: "This group isn’t ready to be started yet"},
          buttons: [
            {text: "More options", icon: "caret-down-outline", callback: (event) => this.openAwaitingMenu(event, tribe)},
            {text: "Start Group", callback: (event) => this.showPopup(event)},
          ]
        }
      }
    };

    if(!keys(states).includes(status)) return null;

    const newState = states[status][tribeUserStatus];
    if(_.isEmpty(newState)) {
      return null;
    } else {
      return _.merge(defaultState, newState);
    }
  }

  private getTimeLeft(others) {
    let shorterDate = null;

    others = others.filter(mate => mate.status == 'invited');
    if (!others.length) return;

    if (others.length == 2) {
      let date = others[0].expires_at;
      shorterDate = new Date(date);
      others.forEach(mate => {
        const currentDate = new Date(mate.expires_at);
        if (currentDate < shorterDate)
          shorterDate = currentDate;
      });
    } else {
      shorterDate = new Date(others[0].expires_at);
    }
    let genericTime = this.timerService.getGenericTime(shorterDate);
    let timeLeft = `${genericTime.hours}h ${genericTime.minutes}m`;
    return timeLeft;
  }

  private performTribeAction(action: { type: string, reason: string }): void {
    return this.tribeHighlightsService.perform(action);
  }

  private async openAwaitingMenu(event, { search_location: searchLocation }) {
    let items: any = [
      {
        icon: {
          name: "options"
        },
        text: "Expand my filters",
        handler: () => {
          this.navCtrl.navigateBack('tabs/tribes');
          this.expandPreferences();
        }
      },
      {
        image: "./assets/img/world-emoji.png",
        text: "Match around the world",
        handler: async () => {
          await this.navCtrl.navigateBack('tabs/tribes');
          await this.userService.setGlobalPreferences();
          await this.searchService.startSearch();
        }
      },
      {
        icon: {
          name: "ban",
          cssClass: "error-color"
        },
        text: "Abort group",
        handler: async () => {
          try {
            const alert = await this.alertCtrl.create({
              header: "Abort group",
              message: "Stop We3 from trying to complete this group?",
              buttons: [
                {
                  text: "Cancel",
                  role: "cancel"
                },
                {
                  text: "Abort",
                  cssClass: "error-color",
                  handler: () => {
                    this.navCtrl.navigateBack('tabs/tribes');
                    this.tribesService.abortGroup( searchLocation );
                  }
                }
              ]
            });
            await alert.present();
          } catch ( e ) {
            console.log( e );
          }
        }
      }
    ];

    // if(this.blacklistRestricted) {
    //   items.unshift({
    //     icon: {
    //       name: "reset",
    //       cssClass: "success-color"
    //     },
    //     text: "Reset past matches",
    //     rightIcon: {
    //       name: "information-circle-outline"
    //     },
    //     handler: () => this.startOver()
    //   })
    // }

    this.createMenuPopover(event, items);
  }

  private async showPopup(event) {
    try {
      const popover = await this.popoverCtrl.create({
        component: InfoPopoverComponent,
        event: event,
        componentProps: {
          info: {
            icon: {
              name: "warning",
              cssClass: "tertiary-color"
            },
            title: "This group is incomplete",
            description: "You can’t start a group with fewer than 3 people."
          }
        },
        backdropDismiss: true,
        cssClass: "info-popover"
      });
      await popover.present();
    } catch ( err ) {
      console.log( err );
    }
  }

  keepOpened(tribeStatus: any): boolean {
    if([TribeStatus.Pending, TribeStatus.Inviting, TribeStatus.Forming].includes(tribeStatus)) {
      return !this.tribeHighlightsService.educationalPopupShown(DeclineEducationalPopup.First);
    } else {
      return !this.tribeHighlightsService.educationalPopupShown(RejectEducationalPopup.First);
    }
  }

  private async openPassingMenu(event, {
    is_initiator: isInitiator,
    tribe_user: currentUser,
    status: tribeStatus
  }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const items: MenuItem[] = [
        {
          image: "./assets/img/not-feeling-it.svg",
          text: "Don't Feel Like It",
          keepOpened: this.keepOpened(tribeStatus),
          rightIcon: {
            name: "chevron-down-outline"
          },
          handler: () => this.performTribeAction({ type: 'pass', reason: 'dontFeel' }),
        },
        {
          image: "./assets/img/too-far.svg",
          text: "Too Far",
          handler: () => this.performTribeAction({ type: "pass", reason: 'tooFar' })
        },
        {
          image: "./assets/img/report-user.svg",
          text: "Report a User",
          rightIcon: {
            name: "chevron-down-outline"
          },
          handler: () => this.performTribeAction({ type: "pass", reason: 'report' })
        },
        {
          icon: {
            name: "hand-right-outline",
            cssClass: "title-color"
          },
          text: "Block a User",
          rightIcon: {
            name: "chevron-down-outline"
          },
          handler: () => this.performTribeAction({ type: "pass", reason: 'block' })
        }
      ];

      if(!isInitiator && currentUser.status == UserTribeStatus.Invited) {
        items.splice(1, 0, {
          icon: {
            name: "too-busy",
            cssClass: "label-color"
          },
          text: "Too busy right now",
          rightIcon: {
            name: ''
          },
          handler: () => this.performTribeAction({ type: "pass", reason: 'tooBusy' })
        })
      }
      const popover = await this.createMenuPopover(
        event, 
        items, 
        `menu-popover min-height-${ items.length }`, 
        currentUser
      );
    })
  }

  private async createMenuPopover(
    event,
    items,
    cssClass = "menu-popover",
    currentUser: any = {}
  ) {
    try {
      const popover = await this.popoverCtrl.create({
        component: MenuPopoverComponent,
        componentProps: { items, isAwaiting: currentUser.status == 'awaiting' },
        backdropDismiss: true,
        id: "menu-popover",
        event,
        cssClass
      });
      await popover.present();
      return popover;
    } catch ( err ) {
      console.log( err );
    }
  }

  private async expandPreferences() {
    return this.navCtrl.navigateForward('preferences');
  }

  private async startOver() {
    this.popupService.createPopup({ popupName: "resetMatchesPopup" });
  }
}
