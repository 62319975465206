import { Directive, ElementRef, Output, EventEmitter} from '@angular/core';

@Directive({ selector: '[onTextChange]' })
export class TextChangeDirective {
  @Output() onTextChange = new EventEmitter();

  referee:any = null;//Emanual decided this name!

  constructor(public el: ElementRef) { }

  ngOnInit() {
    let textChangeTimeout = null;
    let textChange = (ev) => {
      clearTimeout(textChangeTimeout);
      textChangeTimeout = setTimeout(_=> {
        this.onTextChange.emit(ev);
      }, 200);
    }
    this.referee = textChange;
    this.el.nativeElement.addEventListener("input", textChange);
  }

  ngOnDestroy(){
    this.el.nativeElement.removeEventListener("input", this.referee);
  }
}
