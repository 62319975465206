import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {MatchesPlateComponent} from "./matches-plate.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";

@NgModule({
  declarations: [ MatchesPlateComponent ],
  imports: [
    CommonModule
  ],
  exports: [ MatchesPlateComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class MatchesPlateModule {}
