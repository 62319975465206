import { Injectable } from '@angular/core';
import {Config} from "../config/config";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

  private logs: any = [];

  constructor() {
    if(environment.name == 'development') {
      (window as any).cs = this;
    }
  }

  getLast(number) {
    const length = this.logs.length - 1;
    return this.logs.slice(length - number, length);
  }

  data = []
  init(): void {
    let consoleRef = console.log;
    console.log = (...args) => {
      consoleRef.apply(this, args);
      args = args.map(element => ConsoleService.formatLog(JSON.stringify(element)));
      this.logEvent(args.join(' '));
    };

    window.onerror = function(error, url, line) {
      console.log( error );
    };
  }

  getLogs(): any[] {
    return this.logs || [];
  }

  clearLogs(): void {
    this.logs.length = 0;
  }

  private logEvent(event: string): void {
    if(event.match(/ping|pong/gi)) return;
    let date = new Date();
    this.logs.push({
      createdAt: `[${ date.toTimeString().split(' ')[0] }]`,
      cssClass: ConsoleService.chooseCssClass(event),
      event: event,
    });
  }

  static formatLog(log): string {
    let formattedLog = log;
    if(typeof log === 'object') {
      formattedLog
        .replace(/,/g, '<br>')
        .replace(/{/g, '{<br>')
        .replace(/}/g, '<br>}')
    }
    return formattedLog;
  }

  static chooseCssClass(event: string): string {
    if(event.match(/executed|transitioned|ignored/g)) return 'state-machine';
    if(event.match("(route)")) return 'navigation';
    if(event.match(/error|TypeError/g)) return 'error';
    if(event.match("api/") || event.match("PARAMS") || event.match("--------------")) return 'api-request';
    return '';
  }

}
