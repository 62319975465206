import {Component, ComponentRef, ElementRef, Input, OnInit} from '@angular/core';
import {NavController, NavParams, PopoverController} from "@ionic/angular";
import {Config} from "../../services/config/config";
import {BuilderService} from "../../services/helpers/builder.service";
import {TooltipWarningComponent} from "../tooltip-popup/tooltip-warning/tooltip-warning";
import {logc} from "../../shared/helpers/log";
import {DeclineEducationalPopup} from "../../services/tribe-highlights.service";

/* Component for group popup options */
@Component({
  selector: 'app-menu-popover',
  template: `
    <div class = "relative">
      <div *ngFor = "let item of items" class = "menu-item" (click) = "execute( item )">
        <ion-icon *ngIf = "item?.icon" 
                  class = "menu-item-icon shrink-0 {{ item?.icon?.cssClass }}" 
                  [name] = "item?.icon?.name"></ion-icon>
        <img *ngIf = "item?.image" class = "menu-item-image" [src] = "item?.image" alt="Menu image">
        <div class = "menu-item-text" [innerHTML] = "item?.text"></div>
        <div *ngIf = "item?.rightIcon" class = "menu-item-caret">
          <ion-icon [name] = "item?.rightIcon?.name"></ion-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./menu-popover.component.scss'],
})
export class MenuPopoverComponent implements OnInit {

  public items: any = [];
  public showTooltip: boolean = false;

  public item = {
    icon: {
      name: '',
      cssClass: ''
    },
    image: '',
    text: '',
    cssClass: '',
    rightIcon: '',
    handler: () => {}
  };


  private isAwaiting: boolean;
  private tooltipRef: ComponentRef<TooltipWarningComponent> = null;

  constructor(private navParams: NavParams,
              private navCtrl: NavController,
              private config: Config,
              private elementRef: ElementRef,
              private popoverCtrl: PopoverController,
              private builderService: BuilderService) {
  }

  async execute(item) {
    if(!item.keepOpened) {
      await this.popoverCtrl.dismiss();
    }

    await item.handler();
  }

  ngOnInit() {
    this.showTooltip = !this.config.getFlag('menuPopoverTooltipShown') 
                         && !this.isAwaiting;
    this.items = this.navParams.get('items');
  }

  async ngAfterViewInit() {
    if(this.showTooltip) {
      setTimeout(async () => {
        let coords = document.getElementsByClassName(
            "menu-item-caret"
          )[0]?.getBoundingClientRect();

        this.tooltipRef = await this.builderService.createAt({
          component: TooltipWarningComponent,
          options: { inputs: { callback: () => this.items[0].handler() }},
          coords: {
            top: (coords.top - 50) + "px",
            left: (coords.left - 125) + "px"
          },
          anchor: document.getElementsByTagName('ion-app')[0]
        });
      }, 200)
    }
  }

  ngOnDestroy() {
    if(this.tooltipRef) {
      this.tooltipRef.instance.destroySelf();
    }
    if(!this.isAwaiting) {
      this.config.setFlag('menuPopoverTooltipShown', true);
    }
  }

}
