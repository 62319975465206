import {Injectable} from "@angular/core";
import {MatchingStrategy} from "./matching-strategy";
import {SearchService} from "../../data/search.service";

@Injectable({
  providedIn: "any"
})
export class DefaultMatchingStrategy extends MatchingStrategy {
  constructor(private searchService: SearchService) {
    super();
  }

  async execute(options): Promise<any> {
    return this.searchService.start(options);
  }
}
