import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { logc } from "../../shared/helpers/log";

interface HeartFeltProps {
  userName?: string;
  state: string;
}

@Component({
  selector: "heartfelt",
  template: `
    <ion-header>
      <div class="close-button-container" (click)="closeModal($event)">
        <ion-icon
          class="close-icon-absolute right no-plate"
          name="close-outline"
        ></ion-icon>
      </div>
    </ion-header>
    <ion-content class="ion-content-extended-padding">
      <div
        class="huge-text bold title-color"
        [innerHTML]="pageState?.title"
      ></div>
      <div
        *ngFor="let paragraph of pageState?.description"
        class="large-text body-color paragraph"
        [innerHTML]="paragraph"
      ></div>
      <div class="signature-container">
        <img
          class="signature"
          [src]="pageState?.signatureImage"
          alt="Signature"
        />
        <!--        <div class = "large-text bold title-color" [innerText] = "pageState?.author?.name"></div>-->
        <div
          class="large-text italic body-color"
          [innerText]="pageState?.author?.position"
        ></div>
      </div>
      <div
        *ngIf="pageState?.ps"
        class="small-text body-color heartfelt-ps"
        [innerText]="pageState?.ps"
      ></div>
    </ion-content>
  `,
  styleUrls: ["./heartfelt.component.scss"],
})
export class HeartfeltComponent implements OnInit {
  public pageState: any = {};
  public props: HeartFeltProps = null;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {
    this.props = this.navParams.data as HeartFeltProps;
  }

  ngOnInit() {
    this.pageState = this.props.state;
  }

  async ionViewDidEnter() {
    (await this.modalCtrl.getTop()).onDidDismiss().then((data) => {
      // logc.info("Page state: ", this.pageState);
      if (this.pageState.callback) {
        this.pageState.callback();
      }
    });
  }

  async closeModal(event) {
    await this.modalCtrl.dismiss(event);
  }
}
