import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import { MeetupStatusSwitcherComponent } from './meetup-status-switcher.component';

@NgModule({
  declarations: [MeetupStatusSwitcherComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [MeetupStatusSwitcherComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class MeetupStatusSwitcherModule {}