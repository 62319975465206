import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SeparatorLineComponent } from './separator-line.component';

@NgModule({
  declarations: [SeparatorLineComponent],
  imports: [],
  exports: [SeparatorLineComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SeparatorLineComponentModule {}
