import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { NavController } from "@ionic/angular";
import { SessionService } from "../services/data/session.service";
import { Config } from "../services/config/config";
import { UtilsService } from '../services/utils.service';
import { first } from 'rxjs/operators'
import {TribeService} from "../services/data/tribe.service";
import {logc} from "../shared/helpers/log";
import {getTribeIDFromUrl} from "../shared/helpers/helpers";

@Injectable({
  providedIn: 'root'
})
export class NavigationResolver implements Resolve<any> {
  constructor(private navCtrl: NavController,
              private activatedRoute: ActivatedRoute,
              private sessionService: SessionService,
              private utils: UtilsService,
              private config: Config,
              private tribeService: TribeService) {
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const token = route.url[0].path;
    const path = route.url.slice(1).map(segment => segment.path).join('/');

    if(this.sessionService.stateMachine.state === 'connected') {
      this.processLink(token, path);
    } else {
      this.utils.showLoading("Loading", 2000);
      setTimeout( _ => {
        this.processLink(token, path);
      }, 2000);
    }
  }

  async processLink(token, path) {
    try {
      if(token) {
        await this.utils.showLoading("Authenticating");
        await this.config.load();
        this.sessionService.skippingLoginRedirect = true;
        await this.sessionService.pwaLogin(token);
        this.config.isExternalLinkSignIn = true;
        setTimeout(() => {
          this.config.isExternalLinkSignIn = false;
        }, 5000);
      }

      if(this.sessionService.stateMachine.state === 'ready') {
        this.goToPath(path);
      } else {
        this.sessionService.onReady.pipe(first()).subscribe( _ => {
          this.goToPath(path);
        });
      }
    } catch( err ) {
      await this.utils.doneLoading();
      console.log( err );
    } finally {
      await this.utils.doneLoading();
    }
  }

  async goToPath(path) {
    let url = path;
    const extras = {
      state: {
        isExternal: true
      }
    };

    if(path.includes("chat")) {
      if(path.split('/').last() == 'leave') {
        url = 'tabs/tribes';
        const tribeId = getTribeIDFromUrl(path);
        await this.tribeService.delete({ tribeId });
        this.utils.showGenericMessage("You left the group");
      }
    }

    await this.utils.doneLoading();
    await this.navCtrl.navigateForward( url, extras );
  }
}
