<form class = "work-form" [formGroup]="workForm" (ngSubmit)="submit()">
  <ion-grid>
    <ion-row>
      <ion-item class = "form-input">
      <ion-label position="stacked">Title</ion-label>
      <ion-input #workTitleInput
                 [class.ng-invalid] = "title.touched && !title.valid"
                 type = "text"
                 placeholder = "Enter Title (optional)"
                 formControlName="title" autofocus></ion-input>
      </ion-item>
    </ion-row>

    <ion-row>
      <ion-item  class = "form-input">
      <ion-label position="stacked">Organization</ion-label>
      <ion-input #workOrganizationInput
                 [class.ng-invalid] = "title.touched && !title.valid"
                 type = "text"
                 placeholder = "Enter Organization (optional)"
                 formControlName="organization"></ion-input>
      </ion-item>
    </ion-row>
   <div class="alert-button-group">
     <ion-button clear type = "button" (click) = "Cancel()">Cancel</ion-button>
     <ion-button clear
                 (click) = "submit()"
                 [disabled] = "!workForm.valid"
                 class = "update">Update</ion-button>
   </div>
  </ion-grid>
</form>
