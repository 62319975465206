import {NgModule} from "@angular/core";
import {CheckmarkButtonsListComponent} from "./checkmark-buttons-list.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {CheckmarkButtonModule} from "../checkmark-button/checkmark-button.module";

@NgModule({
  declarations: [
    CheckmarkButtonsListComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    CheckmarkButtonModule
  ],
  exports: [
    CheckmarkButtonsListComponent
  ]
})
export class CheckmarkButtonsListModule {}
