import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { FeedbackModalComponent } from "./feedback-modal.component";
import { DirectivesModule } from "../../directives/directives.module";

@NgModule({
  declarations: [
    FeedbackModalComponent
  ],
  imports: [
    AutosizeModule,
    DirectivesModule,
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [
    FeedbackModalComponent
  ]
})
export class FeedbackModalModule {}
