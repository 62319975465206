import { Injectable, NgZone } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import { Config } from "./config/config";
import { AnalyticsService } from "./analytics/analytics.service";
import { ApiService } from "./data/api.service";
import { CountdownTimerService } from "./helpers/countdown-timer.service";

import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppRate } from "@ionic-native/app-rate/ngx";
import { nanoid } from "nanoid";
import {
  AGE_DIVIDER,
  GENERIC_ERROR_TOAST_DURATION,
} from "../shared/constants/constants";
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { RouteTrackerService } from "./route-tracker.service";
import { PushNotification } from "./push-notification/push-notification";
import { OptionsPopupComponent } from "../components/options-popup/options-popup";
import * as compareVersionsPlugin from "compare-versions";
import { UAParser } from "ua-parser-js";
import { Device } from "@ionic-native/device/ngx";
import { fromNullable } from "../shared/helpers/either-monad";
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { ColorModeService } from "./native/color-mode.service";
import { isIosPWA, isMobile } from "../shared/helpers/helpers";
import { DownloadPage } from "../pages/download/download.page";
import { DispatcherService } from "./dispatcher.service";
import { logc } from "../shared/helpers/log";
import { CheckmarkComponent } from "../components/checkmark/checkmark.component";
import { FeedbackService } from "./popups/feedback.service";
import { SearchService } from "./data/search.service";
import { ConsoleService } from "./performance/console.service";
import { hoursLeft } from "../shared/helpers/time-helpers";

interface ShortDeviceInfo {
  model: string;
  vendor: string;
  os: { name: string; version: string };
}

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  private PLAYSTORE =
    "https://play.google.com/store/apps/details?id=co.me3app.me3";
  private APPSTORE =
    "https://itunes.apple.com/us/app/me3-meet-new-people-3-at-a-time-real-friendship/id1215414002?ls=1&mt=8";

  private isDarkMode: boolean = false;
  private loading = null;
  public popover;
  public errorContext = null;
  private timeoutMessage = null;
  private binaryGenders: string[] = ["female", "male"];
  private lastTimeBack: number;
  private isPWA: boolean;
  private deviceParser = new UAParser();

  private notificationIssuesPopupLib = {
    no_token: "noTokenPopup",
    away_2: "away2Popup",
    away_3: "away3Popup",
  };

  private navigator = (window as any).navigator as any;
  public androidNotchHeight: number = 0;

  constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private dispatcherService: DispatcherService,
    private config: Config,
    private modalCtrl: ModalController,
    private api: ApiService,
    private colorModeService: ColorModeService,
    private navCtrl: NavController,
    private pushNotification: PushNotification,
    private routeTrackerService: RouteTrackerService,
    private analyticsService: AnalyticsService,
    private socialSharing: SocialSharing,
    private statusBar: StatusBar,
    private appRate: AppRate,
    private device: Device,
    private feedbackService: FeedbackService,
    public ngZone: NgZone = null,
    private settings: OpenNativeSettings,
    private diagnostic: Diagnostic,
    private countdownTimerService: CountdownTimerService,
    private consoleService: ConsoleService,
    private clipboard: Clipboard
  ) {
    this.isPWA = this.config.isPWA;
    if (this.config.isDev || this.config.isStaging) {
      (window as any).utils = this;
    }
    this.dispatcherService.onDownloadAppCase.subscribe(() =>
      this.showDownloadToast()
    );
  }

  getRandomPictureId() {
    return this.getRandomId(50);
  }

  getRandomId(length) {
    return nanoid(length);
  }

  async showCheckmarkModal(duration, callback): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: CheckmarkComponent,
      id: "checkmarkModal",
      cssClass: "full-height-modal",
    });
    await modal.present();
    setTimeout(async () => {
      await modal.dismiss();
      callback();
    }, duration);
  }

  getExpirationTextColor(user): string {
    const _hoursLeft = hoursLeft(user.expires_at);
    if (_hoursLeft >= 0 && _hoursLeft < 2) return "text-error-dark-3";
    if (_hoursLeft >= 2 && _hoursLeft < 12) return "text-tertiary-dark-3";
    return "text-secondary-dark-3";
  }

  getExpirationColors(user): string {
    if (!user) return "";
    const _hoursLeft = hoursLeft(user.expires_at);

    if (_hoursLeft >= 0 && _hoursLeft < 2)
      return "bg-error-light-1 text-error-dark-3";
    if (_hoursLeft >= 2 && _hoursLeft < 12)
      return "bg-tertiary-light-1 text-tertiary-dark-3";

    return "bg-secondary-light-1 text-secondary-dark-3";
  }

  getProgressMeterColor(hoursLeft) {
    const isDarkMode = this.config.isDarkMode();
    if (hoursLeft >= 0 && hoursLeft < 2) return "#FF9BC5";
    if (hoursLeft >= 2 && hoursLeft < 12) return "#ECC169";

    return isDarkMode ? "#4465B8" : "#91ABEC";
  }

  genderBinaryAndOpposite(gender) {
    return (
      this.binaryGenders.includes(gender) && this.config.get("gender") != gender
    );
  }

  setTransparentStatusBar(style) {
    if (style == "dark") {
      style = "default";
    }
    if (!this.platform.is("android")) {
      this.setStatusBarColor("", style, true);
    }
  }

  setDefaultStatusBar() {
    this.setStatusBarColor('#FFFFFF', 'default', false);
  }

  openLocationSettings() {
    this.settings.open("location");
  }

  async showOptionsPopup(event, props, customCss) {
    if (event) {
      event.preventDefault();
    }
    const popover = await this.popoverCtrl.create({
      id: "options",
      component: OptionsPopupComponent,
      event: event,
      componentProps: props,
      cssClass: customCss,
      backdropDismiss: true,
    });
    await popover.present();
    return popover;
  }

  getDistanceForUnits(distance) {
    let units = {
      mi: Math.floor(distance / 1.609),
      km: Math.floor(distance),
    };

    return units[this.config.get("matchPreferences").defaultUnitSystem || "km"];
  }

  exitApp(): void {
    if (navigator["app"]) {
      navigator["app"].exitApp();
    }
  }

  setStatusBarColor(color = "#FFFFFF", style = "default", overlay = false) {
    if (!this.platform.is("cordova")) return;
    this.statusBar.overlaysWebView(overlay);

    switch (style) {
      case "default":
        this.statusBar.styleDefault();
        break;
      case "light":
        this.statusBar.styleLightContent();
        break;
      default:
        break;
    }

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
      this.platform.is("ios")
    ) {
      this.statusBar.backgroundColorByHexString("#000000");
    } else {
      this.statusBar.backgroundColorByHexString(color);
    }
  }

  async showProfileIncomplete(details): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const alert = await this.alertCtrl.create({
          header: "Your account is incomplete",
          message: "Please complete your account to be a part of a group.",
          cssClass: "regular-alert",
          buttons: [
            {
              text: "Complete my account",
              handler: async () => {
                this.navCtrl.navigateForward("sign-up-flow", {
                  state: details,
                });
                resolve();
              },
            },
          ],
        });
        await alert.present();
        await alert.onDidDismiss().then(() => reject());
      } catch (err) {
        console.log(err);
      }
    });
  }

  profileIsIncomplete(): boolean {
    const profile = this.config.getProfile();
    const requiredValues = ["firstName", "email", "hasPassword"];
    return Object.entries(profile).some(
      (pair) => requiredValues.includes(pair[0]) && !pair[1]
    );
  }

  getMissingProfileValues(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let newOrder = [];
      const properOrder = ["name", "email", "password", "photo"];
      const observableValues = ["firstName", "email", "hasPassword", "picture"];

      const missingPages = Object.entries(this.config.getProfile())
        .filter((pair) => observableValues.includes(pair[0]) && !pair[1])
        .map(
          (pair) =>
            ({
              firstName: "name",
              hasPassword: "password",
              picture: "photo",
              email: "email",
            }[pair[0]])
        );

      properOrder.forEach((e) => {
        if (missingPages.includes(e)) {
          newOrder.push(e);
        }
      });

      console.log("NEW ORDER: ", newOrder);
      resolve(newOrder);
    });
  }

  yearsToDate(number) {
    let dayMs = 24 * 60 * 60 * 1000;
    const birthDay = Date.now() - (number * 365 + 35 / 4) * dayMs;
    const date = new Date(birthDay);
    return date.toISOString();
  }

  isLessThan13(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let date13 = new Date(+year + 13, +month, +day);
    console.log("date13: ", date13);
    return date13 > new Date();
  }

  is13to17(data: Date) {
    // getting rid off hours / minutes / seconds
    let userBirthDate = new Date(new Date(data).toDateString());
    let year = userBirthDate.getFullYear();
    let month = userBirthDate.getMonth();
    let day = userBirthDate.getDate();
    let date17 = new Date(year + 17, month, day);

    let currentDate = new Date();
    let date13 = new Date(
      currentDate.getFullYear() - 13,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return currentDate < date17 && date13 >= userBirthDate;
  }

  getDefaultAgeRange(limit) {
    let age = this.getAge(this.config.get("birthday"));
    let age_range = {
      lower: age - Math.round(age / AGE_DIVIDER),
      upper: age + Math.round(age / AGE_DIVIDER),
    };
    return age_range[limit];
  }

  changeRootPage(page) {
    this.config.setFlag("current_onboarding_page", page);
  }

  compareVersions(version1, version2) {
    return compareVersionsPlugin(version1, version2);
  }

  loadScript(id, src, onload, async = true, inner_text_content = "") {
    if (document.getElementById(id)) {
      return;
    }

    let js = document.createElement("script");
    js.async = async;
    js.src = src;
    js.onload = onload;
    js.text = inner_text_content; // LinkedIn
    document.head.appendChild(js);
  }

  async showUnderLevel3Alert({ first_name }) {
    try {
      const alert = await this.alertCtrl.create({
        header: "We can’t display your compatibility at this time",
        message: `${first_name} reset her psychographic profile but hasn’t completed the levels again.`,
        buttons: [{ role: "cancel", text: "Got it" }],
      });
      await alert.present();
    } catch (e) {
      console.log(e);
    }
  }

  async showGenericError({
    msg = "An error occurred, please try again later.",
    duration = GENERIC_ERROR_TOAST_DURATION,
    key = null,
  }): Promise<any> {
    const errorPostfix = "_error";
    const defaultError = "an_error_occurred";
    const keyName = fromNullable(key).fold(
      () => defaultError,
      (key) => key + errorPostfix
    );

    this.sendErrorAnalytics(msg, keyName);
    const toast = await this.toastCtrl.create({
      message: msg,
      position: "top",
      duration: duration,
      cssClass: "custom-toast",
    });
    return toast.present();
  }

  sendErrorAnalytics(msg, key): void {
    let profile = this.config.getProfile();
    this.analyticsService.trackEvent({
      key: key,
      value: 1,
      picture_status: profile.pictureStatus,
      gender: profile.gender,
      radius: profile.radius,
      pushes_status: this.pushNotification.hasPush(),
      has_location: this.config.hasLocation(),
      picture_gender: profile.pictureGender,
      account_status: profile.banned,
      page_url: this.routeTrackerService.getCurrentPage(),
      message: msg,
      context: this.errorContext,
    });
    this.errorContext = null;
  }

  //this.diagnostic.getExternalStorageAuthorizationStatus
  //this.diagnostic.requestExternalStorageAuthorization
  //this.diagnostic.getCameraRollAuthorizationStatus

  requestCameraPermission(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let response = false;
      if (this.platform.is("android")) {
        response = await this.requestPermission(
          (scope) => {
            return scope.diagnostic.getExternalStorageAuthorizationStatus();
          },
          (scope) => {
            return scope.diagnostic.requestExternalStorageAuthorization();
          },
          (scope) => {
            return scope.showGalleryInstructions();
          }
        );
      } else {
        response = await this.requestPermission(
          (scope) => {
            return scope.diagnostic.getCameraAuthorizationStatus();
          },
          (scope) => {
            return scope.diagnostic.requestCameraAuthorization();
          },
          (scope) => {
            return scope.showGalleryInstructions();
          }
        );
      }
      resolve(response);
    });
  }

  requestGalleryPermission(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let response = false;
      if (this.platform.is("android")) {
        response = await this.requestPermission(
          (scope) => scope.diagnostic.getExternalStorageAuthorizationStatus(),
          (scope) => scope.diagnostic.requestExternalStorageAuthorization(),
          (scope) => scope.showGalleryInstructions()
        );
      } else {
        response = await this.requestPermission(
          (scope) => scope.diagnostic.getCameraRollAuthorizationStatus(),
          (scope) => scope.diagnostic.requestCameraRollAuthorization(),
          (scope) => scope.showGalleryInstructions()
        );
      }
      resolve(response);
    });
  }

  /*Android 11, DENIED_ALWAYS doesn't mean we can't request it again.
   * - if the popup is dismissed
   * - if the user selects to grant it only once and then starts a new session
   *   https://github.com/dpa99c/cordova-diagnostic-plugin/issues/422 */
  requestPermission(
    checkFunc,
    requestFunc,
    deniedAlwaysFunc
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let permission = await checkFunc(this);
      switch (permission) {
        case this.diagnostic.permissionStatus.NOT_REQUESTED:
        case "DENIED_ONCE":
          requestFunc(this).then((status) => {
            if (
              [
                this.diagnostic.permissionStatus.GRANTED,
                this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE,
              ].includes(status)
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
          break;
        case this.diagnostic.permissionStatus.DENIED_ALWAYS:
          /*new android 11, DENIED_ALWAYS can be there when popup is dismissed and when allowed this time and session ends, but it can still be requested again*/
          if (this.platform.is("android")) {
            requestFunc(this).then((status) => {
              if (
                [
                  this.diagnostic.permissionStatus.GRANTED,
                  this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE,
                ].includes(status)
              ) {
                resolve(true);
              } else {
                deniedAlwaysFunc(this);
                resolve(false);
              }
            });
          } else {
            deniedAlwaysFunc(this);
            resolve(false);
          }
          break;
        case "authorized_when_in_use": /*new android 11*/
        case this.diagnostic.permissionStatus.GRANTED:
        case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
          resolve(true);
          break;
      }
    });
  }

  async showGalleryInstructions() {
    const alert = await this.alertCtrl.create({
      header: "To allow access to your pictures, go to your settings",
      message: "Make sure the Storage switch is set to ON.",
      cssClass: "push-alert",
      buttons: [
        {
          text: "Open Settings",
          handler: () => {
            this.settings.open("application_details");
          },
        },
      ],
    });
    return alert.present();
  }

  async showAlert(header, messageP1, messageP2, buttons): Promise<any> {
    let message = `${messageP1} ${messageP2}`;
    if (this.platform.is("android")) {
      message = `${messageP1} <br><br> ${messageP2}`;
    }

    let alert;
    try {
      alert = await this.alertCtrl.create({
        header,
        message,
        buttons,
        cssClass: "z-index-35000",
      });
      await alert.present();
    } catch (e) {
      logc.error(e);
    }

    return alert;
  }

  getPlatform(): string {
    if (this.config.isPWA) return "web";
    if (this.platform.is("ios")) return "ios";
    if (this.platform.is("android")) return "android";
    return "";
  }

  showNotificationsNeededAlert(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        if (this.pushNotification.hasPush()) return resolve();

        const alert = await this.showAlert(
          "Notifications Are Needed",
          "You don’t have push notifications enabled. We need to be able to notify you of incoming messages.",
          "Otherwise, it creates a poor experience for the rest.",
          [
            {
              text: "Got It!",
              handler: () => {
                this.pushNotification.requestPushPermissionAndInit();
                resolve();
              },
            },
          ]
        );
        alert.onDidDismiss().then(() => reject());
      }, 0);
    });
  }

  getInstructionsLink() {
    if (this.platform.is("ios"))
      return "https://www.we3app.com/faq/ios-push-notifications/";
    if (this.platform.is("android"))
      return "https://www.we3app.com/faq/android-push-notifications/";
  }

  userReadyButHasNoPushes(): boolean {
    console.log(
      "this.config.finishedLevel(3) (true): ",
      this.config.finishedLevel(3)
    );
    console.log(
      "this.profileIsIncomplete() (false): ",
      this.profileIsIncomplete()
    );
    console.log(
      "this.config.getProfile().pictureStatus == 'verified' (true): ",
      this.config.getProfile().pictureStatus == "verified"
    );
    console.log(
      "this.config.getProfile().banned (false): ",
      this.config.getProfile().banned
    );
    console.log("isIosPWA() (true): ", isIosPWA());
    console.log(
      "this.pushNotification.isReadyForMatching() (false): ",
      this.pushNotification.isReadyForMatching()
    );
    return (
      [
        this.config.finishedLevel(3),
        !this.profileIsIncomplete(),
        this.config.getProfile().pictureStatus == "verified",
        !this.config.getProfile().banned,
      ].every(Boolean) && !this.pushNotification.isReadyForMatching()
    );
  }

  getBranchLink() {
    let token = this.config.get("pwaToken");
    let url = `https://we3.app.link/pwa/?utm_source=pwa`;
    if (token)
      url = `https://we3.app.link/pwa/?pwa_token=${token}&utm_source=pwa`;

    if (
      this.config.get("partner")?.partner_type == "influencer" &&
      this.userReadyButHasNoPushes()
    ) {
      if (isIosPWA()) {
        url += "&r=tabs/tribes&callback=show_fan_details_ask_pushes";
      }

      if (this.isAndroidPWA()) {
        url += "&r=tabs/tribes&callback=show_fan_details";
      }
    }

    this.analyticsService.trackEvent({
      key: "redirect_to_store",
      value: 1,
      token: token,
    });

    logc.crimson("generated branch link: ", url);

    return url;
  }

  getTheApp() {
    if (isMobile()) {
      this.openBranchLink();
    } else {
      this.openDownloadModal();
    }
  }

  async openDownloadModal() {
    try {
      const modal = await this.modalCtrl.create({ component: DownloadPage });
      await modal.present();
    } catch (e) {}
  }

  showLoading(message = "", duration = 10000): Promise<void> {
    return new Promise((resolve, reject) => {
      this.ngZone.run(async () => {
        this.loading = await this.loadingCtrl.create({
          message: message,
          duration: duration,
          cssClass: "custom-loading",
        });
        await this.loading.present();
        await this.waitABit(); //Fixes loading that doesn't dismiss
        resolve();
      });
    });
  }

  changeLoadingMessage(message, timer) {
    this.timeoutMessage = setTimeout(() => {
      let loadingMessageContainer = document.querySelector(".loading-content");
      if (!!loadingMessageContainer) {
        loadingMessageContainer.innerHTML = message;
      }
    }, timer);
  }

  waitABit(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        resolve();
      }, 200);
    });
  }

  doneLoading(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.ngZone.run(async () => {
        try {
          await this.loadingCtrl.dismiss();
          if (this.timeoutMessage) {
            clearTimeout(this.timeoutMessage);
            this.closeGenericMessage();
          }
          resolve();
        } catch (err) {
          resolve();
          console.log(err);
        }
      });
    });
  }

  underscoreCase(string: string) {
    return string.toLowerCase().split(" ").join("_");
  }

  async showGenericMessage(
    message = "",
    duration = 3000,
    actionText = null,
    action = null,
    position = "bottom"
  ) {
    const defaultSettings = {
      message,
      duration,
      position,
      cssClass: "custom-toast",
    };
    const extrasSettings = action
      ? { buttons: [{ text: actionText, handler: action }] }
      : {};
    const finalSettings: any = { ...defaultSettings, ...extrasSettings };
    let toast;

    try {
      toast = await this.toastCtrl.create(finalSettings);
      await toast.present();
    } catch (err) {
      logc.error("** showGenericMessage error **: ", err);
    }
    return toast;
  }

  async closeGenericMessage() {
    try {
      if (await this.toastCtrl.getTop()) {
        await this.toastCtrl.dismiss();
      }
    } catch (err) {
      console.log(err);
    }
  }

  onDoubleTapBackExit() {
    if (this.routeTrackerService.getCurrentPage() == "/tabs/tribes") {
      if (Date.now() - this.lastTimeBack < 2000) {
        navigator["app"].exitApp();
      }
      this.lastTimeBack = Date.now();
      this.showGenericMessage("Tap back again to exit");
    }
  }

  async copyText(text: string, source = "") {
    this.analyticsService.trackEvent({
      key: "copy_link",
      value: 1,
      media: "clipboard",
      source: source,
    });

    const successText = "Copied link to clipboard!";

    if (this.isPWA) {
      if ("clipboard" in navigator) {
        navigator.permissions
          .query(<any>{ name: "clipboard-write" })
          .then((result) => {
            if (result.state == "granted" || result.state == "prompt") {
              navigator.clipboard.writeText(text);
              this.analyticsService.trackEvent({
                key: "copy_link",
                value: 1,
                media: "clipboard",
                user_id: this.config.get("id"),
              });
              this.showGenericMessage(successText);
            }
          });
      }
      return;
    }

    try {
      await this.clipboard.copy(text);
      this.analyticsService.trackEvent({
        key: "copy_link",
        value: 1,
        media: "clipboard",
        user_id: this.config.get("id"),
      });

      await this.showGenericMessage(successText);
    } catch (e) {
      console.log("Utils copying text error: ", e);
    }
  }

  showLocationTip() {
    this.showAlert(
      "Wrong location?",
      `${this.config.getUserCity()} and it’s surrouding areas are the nearest location where we’re matching fans of ${this.config.getPartnerName()}.`,
      "To request that we add your city, please contact us.",
      [
        {
          text: "Contact us",
          handler: () =>
            this.feedbackService.showFeedbackForm({
              context: "Scheduling fan matching",
              errorContext: "Wrong user's location",
            }),
        },
        { text: "Got it", role: "cancel" },
      ]
    );
  }

  getReferralLink(): string {
    const appUrl = "https://we3.app/";
    const referralCode = this.config.getProfile().referralCode;
    return appUrl + referralCode;
  }

  shareApp(message: string, subject: string, link: string, file: any = null) {
    if (this.isPWA) {
      if ("clipboard" in navigator) {
        navigator.permissions
          .query(<any>{ name: "clipboard-write" })
          .then((result) => {
            if (result.state == "granted" || result.state == "prompt") {
              navigator.clipboard.writeText(link);
              this.showGenericMessage("Link copied to your clipboard!");
            }
          });
      }
      return;
    } else {
      return this.socialSharing.share(message, subject, file, link);
    }
  }

  pwaCanShare(): boolean {
    return "share" in this.navigator;
  }

  async shareLink(url) {
    this.analyticsService.trackEvent({
      key: "shared_link",
      value: 1,
    });

    if (this.isPWA && this.pwaCanShare()) {
      try {
        await this.navigator.share({ url });
        this.analyticsService.trackEvent({
          key: "shared_link",
          value: 1,
          user_id: this.config.get("id"),
        });
        console.log("Success referral sharing!");
      } catch (err) {
        console.log("Error referral sharing:", err);
      }
    } else {
      this.analyticsService.trackEvent({
        key: "shared_link",
        value: 1,
        user_id: this.config.get("id"),
      });
      return this.socialSharing.shareWithOptions({ url });
    }
  }

  getNotificationIssuesPopup(
    situation: "no_token" | "away_2" | "away_3"
  ): string {
    return this.notificationIssuesPopupLib[situation];
  }

  goToAppStore() {
    if (this.platform.is("ios")) {
      window.location.href = this.APPSTORE;
    } else if (this.platform.is("android")) {
      window.location.href = this.PLAYSTORE;
    }
  }

  openBranchLink() {
    window.open(this.getBranchLink(), "_blank");
  }

  setAgeCategory(age) {
    if (!age) {
      return "mid";
    }

    if (age < 25) {
      return "early";
    }
    if (age >= 25 && age <= 35) {
      return "mid";
    }
    if (age > 35) {
      return "late";
    }
  }

  getAge(birthdate) {
    if (birthdate) {
      return new Date().getFullYear() - birthdate.split("-")[0];
    }
  }

  getUserAge(birthday: Date = undefined) {
    let ms =
      new Date().valueOf() -
      Date.parse(
        birthday ? birthday : this.config.getProfile().birthday
      ).valueOf();
    let years = 1000 * 60 * 60 * 24 * 365;
    return Math.trunc(ms / years);
  }

  getCurrency(price: string = "") {
    return price.replace(/[0-9.]/g, "");
  }

  isLatestVersion(remoteVersion, localVersion) {
    let remoteParts = remoteVersion.split(".");
    let localParts = localVersion.split(".");

    let min = Math.min(remoteParts.length, localParts.length);

    for (let i = 0; i < min; i++) {
      let x = parseInt(remoteParts[i]);
      let y = parseInt(localParts[i]);
      if (y > x) return true;
      if (y < x) return false;
    }
    return true;
  }

  getExpiredDate(expireTime) {
    let timeLeft = this.countdownTimerService.getGenericTime(expireTime);
    let hours =
      timeLeft.hours > 0 ? timeLeft.days * 24 + timeLeft.hours + "h " : "";
    let minutes = timeLeft.minutes + "m";
    return hours + minutes;
  }

  getDeviceVendor() {
    console.log(
      "-- utils.is getDeviceVendor(): device:",
      this.deviceParser.getResult().device.vendor
    );
    return this.getDeviceInfo().vendor;
  }

  isAndroidPWA(): boolean {
    return this.getDeviceInfo()?.os?.name == "Android";
  }

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      (<any>navigator).userAgent
    );
  }

  getDeviceInfo(): ShortDeviceInfo {
    let shortDeviceInfo: ShortDeviceInfo;
    if (this.isPWA) {
      const { device, os } = this.deviceParser.getResult();
      shortDeviceInfo = {
        model: device.model,
        vendor: device.vendor,
        os,
      };
    }

    if (this.platform.is("cordova")) {
      const device = this.device;
      shortDeviceInfo = {
        model: device.model,
        vendor: device.manufacturer,
        os: {
          name: device.platform,
          version: device.version,
        },
      };
    }

    return shortDeviceInfo;
  }

  showDownloadToast() {
    this.toastCtrl
      .create({
        message: "For this, you need the full app.",
        buttons: [
          {
            text: "DOWNLOAD",
            handler: () => {
              this.openBranchLink();
            },
          },
        ],
        duration: 3000,
        position: "bottom",
      })
      .then((toast) => toast.present());
  }

  deepClone(object) {
    if (!object) {
      return object;
    }

    let value;
    let clone = Array.isArray(object) ? [] : {};
    for (let key in object) {
      value = object[key];
      clone[key] = typeof value === "object" ? this.deepClone(value) : value;
    }

    return clone;
  }

  getSignatureImagePath(name: string) {
    return `./assets/img/${name}-signature-${
      this.colorModeService.isDark() ? "white" : "black"
    }.png`;
  }

  async askToDeleteApp() {
    const alert = await this.alertCtrl.create({
      header: "Delete your We3 account?",
      message:
        "Sorry we weren’t meant to be. We wish you the best of luck finding that person who will make your heart go wild.",
      buttons: [
        {
          text: "Back",
          role: "cancel",
        },
        {
          text: "Delete",
          handler: async () => {
            this.navCtrl.navigateForward("delete_my_account");
          },
        },
      ],
    });
    return alert.present();
  }

  getPerformanceInfo(extras = {}) {
    return {
      sm: this.config.SMAggregator,
      device: this.getDeviceInfo(),
      pusher: this.pushNotification.pusher.state,
      logs: this.consoleService.getLast(100),
      history: this.routeTrackerService.getHistory(),
      ...extras,
    };
  }
}
