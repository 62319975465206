import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { StarsFeedbackComponent } from './stars-feedback.component';
import { LottieModule } from "ngx-lottie";
import {DirectivesModule} from "../../directives/directives.module";

@NgModule({
  declarations: [
    StarsFeedbackComponent
  ],
  imports: [
    AutosizeModule,
    DirectivesModule,
    CommonModule,
    IonicModule,
    FormsModule,
    LottieModule
  ],
  exports: [
    StarsFeedbackComponent
  ]
})
export class StarsFeedbackComponentModule {}
