import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {FeedbackRadioListAnswer, FeedbackRadioListQuestion} from "../../../shared/interfaces/feedback-modal";
import {KeyboardService} from "../../../services/native/keyboard.service";
import {takeUntil} from "rxjs/internal/operators";
import {Subject} from "rxjs/Subject";
import {fromEvent} from "rxjs";
import {BuilderService} from "../../../services/helpers/builder.service";
import {DoneButtonComponent} from "../../done-button/done-button.component";

@Component({
  selector: 'radio-list',
  template: `
      <ion-list>
        <ion-item *ngFor = "let answer of currentQuestion.answers" lines = "none" (click) = "selectAnswer(answer, $event)">
          <ion-avatar *ngIf = "answer.image || answer.emoji || answer.icon" slot = "start">
            <img *ngIf = "answer.image" [src] = "answer.image" alt="">
            <span *ngIf = "answer.emoji" [innerHTML] = "answer.emoji"></span>
            <ion-icon *ngIf = "answer.icon" [name] = "answer.icon"></ion-icon>
          </ion-avatar>
          <ion-label *ngIf = "!showOtherOption || answer.text != 'Other'; else detailsInputContainer"
                     class = "ion-text-wrap"
                     [innerHTML] = "answer?.text"></ion-label>
          <ng-template #detailsInputContainer>
            <ion-textarea #detailsInput 
                       placeholder = "Start typing" 
                       [(ngModel)] = "details"
                       (ionBlur) = "shrinkForm()"
                       (click) = "popupForm()"></ion-textarea>
          </ng-template>
        </ion-item>
      </ion-list>
  `,
  styleUrls: ['./radio-list.component.scss'],
})
export class RadioListComponent {
  @Input("currentQuestion") currentQuestion: FeedbackRadioListQuestion;
  @ViewChild("detailsInput") detailsInput: any;

  public showOtherOption: boolean = false;
  public details: any = '';
  public onParentDestroyed = new Subject();
  private initialBreakpoint: number = null;
  private submitting: boolean = false;

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private modalCtrl: ModalController,
              private builderService: BuilderService) {
    fromEvent(document, "mousedown")
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((event) => {
        this.submitting =
          (event?.target as any).classList?.contains("done-button-container") ||
          (event?.target as any).classList?.contains("done-button-label")
      })
  }

  async popupForm() {
    const modal = await this.modalCtrl.getTop();
    const currentModalBreakpoint = await modal.getCurrentBreakpoint();
    if(currentModalBreakpoint != 1) {
      await modal.setCurrentBreakpoint(1);
    }
  }

  async shrinkForm() {
    if(this.submitting) return;
    this.details = '';
    const modal = await this.modalCtrl.getTop();
    await modal.setCurrentBreakpoint(this.initialBreakpoint);
    this.showOtherOption = false;
    this.onParentDestroyed.next(null);
  }

  async selectAnswer(answer: FeedbackRadioListAnswer, event) {
    console.log("-- Select answer --");
    if(answer.text == 'Other') {
      if(this.showOtherOption) return;

      this.showOtherOption = true;
      const modal = await this.modalCtrl.getTop();
      this.initialBreakpoint = await modal.getCurrentBreakpoint();
      await modal.setCurrentBreakpoint(1);
      setTimeout(() => this.detailsInput.setFocus(), 500);

      this.builderService.create(DoneButtonComponent, {
        inputs: { onParentDestroyed: this.onParentDestroyed, text: "Submit" },
        outputs: {
          onClick: () => {
            this.onParentDestroyed.next(null)
            modal.setCurrentBreakpoint(this.initialBreakpoint);
            this.close({
              action: "next",
              canBeCommented: answer.text == "Other",
              next: answer.next,
              current:  { ...this.currentQuestion, ...{ next: answer.next }},
              analytics: {
                topic: this.currentQuestion.title,
                answer: answer.text,
                details: this.details
              }
            });
          }
        }
      })
      return;
    }

    this.close({
      action: "next",
      canBeCommented: answer.text == "Other",
      next: answer.next,
      current: this.currentQuestion,
      analytics: {
        topic: this.currentQuestion.title,
        answer: answer.text
      }
    });
  }

  close(data) {
    this.modalCtrl.dismiss(data);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
