import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { BillingPlanComponent } from "./billing-plan.component";

@NgModule({
  declarations: [BillingPlanComponent],
  imports: [
    IonicModule,
    CommonModule
  ],
  exports: [BillingPlanComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class BillingPlanComponentModule {}