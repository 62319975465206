import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CloseButtonComponent} from "./close-button.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";

@NgModule({
  declarations: [CloseButtonComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [CloseButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CloseButtonModule {}
