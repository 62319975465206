import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ButtonWithSuccessStateComponent } from "./button-with-success-state.component";

@NgModule({
  declarations: [ButtonWithSuccessStateComponent],
  imports: [
    IonicModule,
    CommonModule
  ],
  exports: [ButtonWithSuccessStateComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ButtonWithSuccessStateModule {}