import {Injectable} from "@angular/core";
import {ApiService} from "../data/api.service";
import {Observable, of} from "rxjs";
import {map} from "rxjs/internal/operators";
import {catchError} from "rxjs/operators";
import {logc} from "../../shared/helpers/log";

export const itunesParentCategories = [
  "arts", "business", "comedy", "education", "fiction",
  "government", "history", "health & fitness", "kids & family",
  "leisure", "music", "news", "religion & spirituality",
  "science", "society & culture", "sports", "technology",
  "true crime", "tv & film"
]

interface PodcastsFetchingStrategy {
  sourceUrl: string;
  createRequestUrl(name, options): string;
}

class PodcastsFetchingItunesStrategy implements PodcastsFetchingStrategy {
  sourceUrl: string = ApiUrl.Itunes;

  createRequestUrl(name, limit): string {
    const urlParams: ItunesUrlRequestBody = {
      term: name.split(" ").join("+"),
      country: "us",
      media: "podcast",
      sort: "recent",
      limit
    }
    return this.sourceUrl + Object
      .keys(urlParams)
      .reduce((url, key) => url += `&${ key }=${ urlParams[key] }`, "");
  }
}

interface Podcast {
  trackId: string;
  trackName: string;
}

interface ItunesUrlRequestBody {
  term: string;
  country?: string;
  media?: string;
  sort?: string;
  attribute?: string;
  limit?: string;
}

enum ApiUrl {
  Itunes = "https://itunes.apple.com/search?"
}

@Injectable({
  providedIn: "root"
})
export class PodcastsService {
  private strategy: any = null;

  constructor(private api: ApiService) {
    (window as any).podcastsService = this;
  }

  private getPodcastsGenres(podcast): string[] {
    return podcast
      .genres
      .map(genre => genre.toLowerCase())
      .filter(genre => itunesParentCategories.includes(genre))
  }

  private setStrategy(strategy): void {
    this.strategy = strategy;
  }

  private getRequestUrl(name, options): string {
    return this.strategy.getRequestUrl(name, options);
  }

  private createFetchingStrategy(source: string): PodcastsFetchingStrategy {
    switch(source) {
      case "itunes":
        return new PodcastsFetchingItunesStrategy();
    }
  }

  getPodcasts(searchText: string, limit: number, source: string): Observable<any> {
    const fetchingStrategy = this.createFetchingStrategy(source);
    const requestUrl = fetchingStrategy.createRequestUrl(searchText, limit);

    console.log("requestUrl: ", requestUrl);

    return this.fetch(requestUrl)
      .pipe(
        map(data => data.results),
        map(results => results.map((podcast) => ({
          id: podcast.trackId,
          name: podcast.trackName,
          thumbnail: podcast.artworkUrl100,
          external: true,
          genres: this.getPodcastsGenres(podcast)
        }))),
        catchError(err => {
          logc.error("Error occurred: ", err);
          return of([]);
        })
      );
  }

  private fetch(url: string): Observable<any> {
    return this.api.jsonp(url);
  }
}
