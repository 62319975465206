import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chip',
  template: `
    <ion-chip class = "bg-input-background ml-auto text-label {{ activated ? activatedCssClass : '' }}">
      <ion-icon class = "text-small text-label mr-[4px] {{ activated ? activatedIconCssClass : '' }}" [name] = "iconName"></ion-icon>
      <span class = "font-heavy text-small">{{ text }}</span>
    </ion-chip>
  `,
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnInit {
  @Input() iconName: string;
  @Input() text: string;
  @Input() activatedIconCssClass: string = '';
  @Input() activated: boolean = false;
  @Input() activatedCssClass: string = '';

  constructor() { }

  ngOnInit() {}

}
