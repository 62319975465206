<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title> </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class = "state-page state-neutral">
  <page-status [isFullPage] = true></page-status>

  <!-- <div *ngIf = "isDev" class = "test-buttons-container vertical" [class.hidden] = "hideTestButtons">
    <div class="top">
      <button (click) = "setState('noFace')">No Face</button>
      <button (click) = "setState('group')">Group</button>
      <button (click) = "setState('risky')">Risly</button>
      <button (click) = "setState('stolen')">Stolen</button>
    </div>
    <div class="bottom">
      <ion-icon name="chevron-back-outline" (click) = "hideTestButtons = !hideTestButtons"></ion-icon>
    </div>
  </div> -->

  <div class="vertically-centered secondary-page">
    <div class = "state-content">
      <div class="content fade-in-icon">
        <div class="tooltip-container">
          <div class = "blue-tooltip">
              Tip: Smile 😃
            <div class = "arrow-down"></div>
          </div>
          <img [src] = "image" class = "avatar">
        </div>
      </div>
    </div>

    <div class = "state-header">
      <div class = "state-title" [innerHTML] = "state?.title"></div>
      <div class = "state-sub-title" [innerHTML] = "state?.desc"></div>
    </div>

    <div class = "state-footer two-buttons">
      <div class = "button-wrapper">
        <button class = 'btn-large-action turquoise-gradient-btn turquoise-btn-shadow'
                large
                [class.disabled] = "!api.isOnline"
                (click) = "selectDifferentPhoto()">
          {{ state?.primaryButtonText }}
        </button>
        <div *ngIf = "state?.secondaryButtonText"
             class = 'support-action btn-large-action'
             large
             (click) = "state?.secondaryAction()">
          {{ state?.secondaryButtonText }}
        </div>
      </div>
    </div>
  </div>
</ion-content>





