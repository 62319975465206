import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { uniqueId } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Config } from 'src/app/services/config/config';
import { HighlightStylesService } from 'src/app/services/data/highlight-styles.service';
import { StatementsService } from 'src/app/services/data/statements.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { fromNullable } from 'src/app/shared/helpers/either-monad';
import { values } from 'src/app/shared/helpers/helpers';
import { logc } from 'src/app/shared/helpers/log';

type HighlightsType = "traits" | "goals" | "interests";

interface HighlightsListStates {
  [key: string | number]: HighlightsListState
}

interface HighlightsListState {
  title: string,
  background: string,
  stub: HighlightsListStub
}

interface HighlightsListStub {
  background: string,
  title: string
}

type ListType = "tags" | "list";

@Component({
  selector: 'highlights-list',
  template: `
    <div *ngIf = "highlights?.length || !hasHighlights"
         class = "p-[25px] relative">
      <div class = "absolute {{ bgCssClass }} inset-0 bg-contain bg-no-repeat {{ isDarkMode ? 'opacity-40' : '' }}" 
           [style.background-image] = "'url(' + state?.background + ')'"></div>
      <div class = "relative py-[15px] px-[20px] bg-opacity-75 rounded-[5px] shadow-md text-left">
        <div class = "absolute inset-0 bg-surface opacity-80 z-5 rounded-[5px]"></div>
        <div class = "relative z-10">
          <div class = "heading mb-4">{{ state?.title }}</div>
          <div class = "highlights-container">
            <div class = "text-center my-[20px]" *ngIf = "!hasHighlights">
              <img class = "w-[60px] my-[15px]" [src] = "state?.stub?.background" alt = "Stub">
              <div class = "text-base text-placeholder">{{ state?.stub?.title }}</div>
            </div>
            <div *ngIf = "format == 'list'" 
                 class = "overflow-hidden duration-300">
              <div *ngFor = "let highlight of highlights" 
                   class = "flex items-center justify-start gap-[15px]">
                <i *ngIf = "highlight?.icon" 
                   class = "ss-icon text-huge relative top-1" 
                   [style.color] = "highlight?.color">
                   {{ highlight?.icon }}
                </i>
                <div class = "text-base text-body font-semibold">
                  {{ highlight?.text }}
                </div>
              </div>  
            </div>
            <div *ngIf = "format == 'tags'"
                 class = "overflow-hidden duration-300">
              <div *ngFor = "let highlight of highlights" 
                  class = "
                    m-1 bg-input-background rounded-3xl py-[5px]
                    px-4 inline-flex max-w-full items-center justify-around gap-2">
                <div class = "text-base text-body font-semibold truncate">{{ highlight?.text }}</div>
                <ion-icon name = "checkmark-circle" class = "text-large text-label shrink-0"></ion-icon>
              </div>
            </div>
            <!-- <div *ngIf = "highlights?.length > NUMBER_OF_SHOWN_ROWS && !listExpanded" class = "w-full text-center mt-3">
              <light-button [buttonBody] = "moreButtonBody"
                            [empty] = 'true'
                            (onClick) = "toggleList()"></light-button>
            </div> -->
          </div>
        </div>
      </div>
      <div *ngIf = "!allLevelsFinished && highlights?.length" class = "relative z-10 flex flex-col items-center gap-3 m-4">
        <div class = "text-small text-label bold">You may have more in common. To find out...</div>
        <div class = "text-small text-primary title-font-heavy" (click) = "openLevels()">Start the next level</div>
      </div>
    </div>
  `,
  styleUrls: ['./highlights-list.component.scss'],
})
export class HighlightsListComponent implements OnInit {
  // @Input("title") title: string = '';
  @Input() showEmpty: boolean = false;
  @Input("bgCssClass") bgCssClass: string = '-top-[20px]';
  @Input('data') data: any = {};
  @Input("format") format: ListType = "list";
  @Input("type") type: HighlightsType = 'traits';
  // @Input("backgroundImage") backgroundImage: string = './assets/img/highlights/bg-highlights-traits.png';

  public listExpanded: boolean = false;
  public allLevelsFinished: boolean = false;
  public highlights: any[] = [];

  public NUMBER_OF_SHOWN_ROWS = 8;

  public hasHighlights: boolean = false;

  private rowHeights = {
    list: 36,
    tags: 39
  }

  public listHeight = 0;
  public isDarkMode: boolean = false;

  public states: HighlightsListStates = {
    traits: {
      title: "Shared Traits",
      background: "./assets/img/highlights/traits-bg.png",
      stub: {
        title: "Shared traits will appear here",
        background: "./assets/img/highlights-traits.png"
      }
    },
    goals: {
      title: "Common Goals",
      background: "./assets/img/highlights/goals-bg.png",
      stub: {
        title: "Common goals will appear here",
        background: "./assets/img/highlights-goals.png"
      }
    },
    interests: {
      title: "Mutual Interests",
      background: "./assets/img/highlights/interests-bg.png",
      stub: {
        title: "Mutual interests will appear here",
        background: "./assets/img/highlights-interests.png"
      }
    }
  }

  public state: HighlightsListState = null;

  public moreButtonBody = {
    style: "small",
    icon: "chevron-down-outline",
    text: "Show more",
    rotate: true
  }

  constructor(private statementsService: StatementsService,
              private dispatcherService: DispatcherService,
              private config: Config,
              private modalCtrl: ModalController,
              private highlightsStylesService: HighlightStylesService) { }

  ngOnInit() {
    this.isDarkMode = this.config.isDarkMode();
    this.allLevelsFinished = this.statementsService.allLevelsFinished();
    this.state = this.states[this.type];
  }

  ngOnChanges(changes: any): void {
    this.highlights = (this.data || {})[this.type] || [];
    this.hasHighlights = values(this.data).flat().length > 0;
    this.setStyles();
    this.setListHeight();
  }

  setListHeight() {
    const highlightsLength = this.highlights?.length || 0;
    const rowHeight = this.rowHeights[this.format];
    if(highlightsLength < this.NUMBER_OF_SHOWN_ROWS) {
      this.listHeight = highlightsLength * rowHeight;
    } else {
      if(this.listExpanded) {
        this.listHeight = highlightsLength * rowHeight;
      } else {
        this.listHeight = this.NUMBER_OF_SHOWN_ROWS * rowHeight;
      }
    }
  }

  setStyles() {
    if(!this.highlights || this.highlights.length == 0) return;
    this.highlightsStylesService.mapForHighlights(this.highlights);
  }

  toggleList() {
    this.listExpanded = !this.listExpanded;
    this.setListHeight();
  }

  async openLevels() {
    if(await this.modalCtrl.getTop()) {
      this.modalCtrl.dismiss();
    }
      
    this.statementsService.openCurrentLevel();
  }
}
