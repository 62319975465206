import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "cleanFrom",
})
export class CleanFromPipe implements PipeTransform {
  transform(value: string, regexes: RegExp[]): any {
    if (!value || !value?.length) return value;
    return regexes.reduce((acc, regex) => acc.replace(regex, ""), value);
  }
}
