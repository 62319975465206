import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {RadioListComponent} from "./radio-list.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [RadioListComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  exports: [RadioListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RadioListModule {}
