import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class D3Service {

  constructor(public platform: Platform) { }

  isSafariBrowser() {
    return (navigator.userAgent.search("Safari")>=0) && (navigator.userAgent.search("Chrome")<0)
  }

  addShadow(containerDefs, element, shadowDx, shadowDy, std, filterId, routeToGraph,source="SourceGraphic")  {
    if(this.platform.is('ios') || this.isSafariBrowser())
      return;

    var filters = containerDefs.append("filter").attr("id",filterId)
      filters.append("feOffset")
      .attr("result","offOut")
      .attr("in",source)
      .attr("dx",shadowDx)
      .attr("dy",shadowDy)
      .attr("width","200%")
      .attr("height","200%");

      filters.append("feGaussianBlur")
      .attr("result","offOut")
      .attr("in","blurOut")
      .attr("stdDeviation",std)

      filters.append("feBlend")
      .attr("in","SourceGraphic")
      .attr("in2","blurOut")
      .attr("mode","normal");
      element.attr("filter","url("+routeToGraph+"#"+filterId+")");
  }
  addDarkShadow(containerDefs, element, shadowDx, shadowDy, std, filterId, slope, routeToGraph){
    if(this.platform.is('ios') || this.isSafariBrowser())
      return;

    containerDefs.append("filter").attr("id",filterId)
    .html(`
    <feOffset result="offOut" in="SourceAlpha" dx="${shadowDx}" dy="${shadowDy}" width="200%" height="200%">
    </feOffset><feGaussianBlur result="offOut" in="blurOut" stdDeviation="${std}">
    </feGaussianBlur>
    <feBlend in="SourceGraphic" in2="blurOut" mode="normal">
    </feBlend><feComponentTransfer>
    <feFuncA type="linear" slope="${slope}"></feFuncA>
    </feComponentTransfer>
    <feMerge>
      <feMergeNode></feMergeNode>
      <feMergeNode in="SourceGraphic"></feMergeNode>
    </feMerge>
    `)
    element.attr("filter","url("+routeToGraph+"#"+filterId+")");
  }

  fillGradient(containerDefs, element, start:Point, end:Point, startColor, endColor, opacity, gradientId, routeToGraph){
    var gradient = containerDefs.append("linearGradient")
    .attr("id",gradientId)
    .attr("x1",start.x)
    .attr("y1",start.y)
    .attr("x2",end.x)
    .attr("y2",end.y)
    gradient.append("stop")
    .attr("offset","0%")
    .style("stop-color",startColor)
    .style("stop-opacity",opacity);
    gradient.append("stop")
    .attr("offset","100%")
    .style("stop-color",endColor)
    .style("stop-opacity",opacity);

    element.attr("fill","url("+routeToGraph+"#"+gradientId+")");
  }

  pathDFromPoints(points,smooth=false,group=null,tangents=null,curvature=0,printVisualAids=false){
    /**
     * See https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths for more info
     */
    let pathD;
    for(var i=0;i<=points.length;i++){
      var point = points[i%points.length];
      if(!pathD){
        pathD = "M"
      } else {
        if(!smooth){
          pathD += "L"
        } else{
          pathD += "C"
        }
      }
      if(smooth && i>0){
        var previousPoint = points[i==0? points.length-1:i-1];
        let orthoPrev = tangents[i-1]
        var controlFirstPoint = Point.substract(previousPoint,orthoPrev,-1,-curvature)
        let orthoPoint = tangents[i%points.length];
        var controlSecondPoint = Point.substract(point,orthoPoint,-1,curvature)
        pathD +=controlFirstPoint.x+" "+controlFirstPoint.y+","+controlSecondPoint.x+" "+controlSecondPoint.y+",";
        if(printVisualAids){
          group.append("path")
          .attr("d",this.pathDFromPoints([previousPoint,controlFirstPoint]))
          .attr("fill","black")
          .attr("stroke","grey")
          .attr("stroke-width","1")
          group.append("path")
          .attr("d",this.pathDFromPoints([controlSecondPoint,point]))
          .attr("fill","black")
          .attr("stroke","tomato")
          .attr("stroke-width","1")
        }
      }
      pathD += point.x+" "+point.y;
    }
    pathD +="Z";//Close shape
  return pathD;
  }

  computeCirclePoint(angle,distFromCenter)
  {
    return new Point(distFromCenter*Math.sin(angle/360*2*Math.PI),-distFromCenter*Math.cos(angle/360*2*Math.PI))
  }
  
  wrap(text, width) {
    text.each(function() {
      let text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        x = text.attr("x"),
        y = text.attr("y"),
        dy = 0,
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", dy + "em"),
        nbWords = words.length;
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width && nbWords>1 ) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }
}

export class Point {
  x:any
  y:any
  constructor(x,y) {
    this.x = x;
    this.y = y;
  }
  static interpolate(p1,p2,t){
    return new Point(p1.x + (p2.x-p1.x)*t,p1.y + (p2.y-p1.y)*t);
  }
  static substract(p1,p2,alpha=1,beta=1){
    return new Point(beta*p2.x-alpha*p1.x,beta*p2.y-alpha*p1.y);
  }
  norm(){
    return Math.sqrt(Math.pow(this.x,2)+ Math.pow(this.y,2));
  }
  normalise(){
    var norm = Math.pow(this.norm(),1);
    this.x = this.x /norm;
    this.y = this.y /norm;
  }
}
