import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TribesAlertComponent } from './tribes-alert.component';
import {TribeAvatarModule} from "../tribe-avatar/tribe-avatar.module";

@NgModule({
  declarations: [
    TribesAlertComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TribeAvatarModule
  ],
  exports: [
    TribesAlertComponent
  ]
})
export class TribesAlertModule {}
