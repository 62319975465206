import { log } from "./../../helpers/log";
import { Subject } from "rxjs/Subject";
import { AppService } from "../../../services/app";
import {SMState} from "../../interfaces";

export class StateMachine {
  public states: any = {};
  public running: boolean = false;
  public currentEvent: string = '';
  public identifier: string = '';
  public state: string = ''; // current state
  private queue: Array<any> = [];
  public env: any = {};
  public instanceId: any = 0;
  public hasPendingSystemEvent: boolean = false;

  public stateChangeSource: Subject<any> = new Subject();
  public onStateChanged: any;

  constructor(states: any, 
              identifier: string = '',
              private appService: AppService = null) {
    this.instanceId++;

    this.onStateChanged = this.stateChangeSource.asObservable();

    if( Object.keys(states).length <= 0 ) { throw "Error, no states provided" }
    this.states = states;
    this.state = 'standby';
    this.identifier = identifier;

    //Identifier for console logs if none specified
    this.setIdentifier(identifier);
  }

  setIdentifier(identifier: string) {
    if(identifier) {
      this.identifier = identifier;
    } else {
      const no = Math.round(Math.random() * (1 - 1000) + 1);
      this.identifier = `SM${no}`;
      log(this.identifier, { color: "yellow", background: "blue" });
    }
  }

  public systemEvents = ['pause', 'resume'];
  isSystemEvent( ev ) {
    return this.systemEvents.includes( ev );
  }

  clearDroppables() {
    const droppables = this.states[this.state]?.drop;
    if(!droppables) return;

    this.queue = this.queue.filter(event => !droppables.includes(event.event));
    console.log('-- Clearing QUEUE from droppables...', this.queue, droppables);
  }

  async exec(ev: string, context = {}, lastTry = false): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let func = this.states[this.state][ev];
      this.log('1');
      this.log('is running: ', this.running);
      if(this.states[this.state]?.drop?.includes(ev)) {
        this.log('2');
        this.log(`dropped ${ ev }, part of the droppable events for ${ this.state }`);
        return resolve();
      }
      if(this.isAlreadyRunning(ev)) {
        this.log('3');
        this.log(`skipped ${ev}, already running`);
        return resolve();
      } else if (this.running) {
        // --------------------------------------
        //If we are already running something else
        // --------------------------------------
        if(this.isSystemEvent(ev)) {
          this.log('4');
          if(!lastTry) {
            this.hasPendingSystemEvent = true;
            this.log(`system event registered ${ ev }`);
          }
        } else if(!lastTry) {
          this.log('5');
          this.log(`adding ${ ev } to QUEUE: already running`);
          this.queue.push({
            event: ev,
            context: context
          });
          this.log(`QUEUE: ${ this.queue.map(e => e.event) }`);
        } else {
          this.log('6');
          this.log(`ignored ${ev} (running)`);
        }
        return resolve();
      } else if (!func) {
        this.log('7');
        this.log(`${ this.state } - ${ ev }: no function`);
        // --------------------------------------
        // if the state - event returns undefined
        // no function defined
        // --------------------------------------
        if(this.isSystemEvent(ev)) {
          if(!lastTry) {
            this.hasPendingSystemEvent = true;
            this.log(`system event registered ${ ev }`);
          }
        } else if(!lastTry) {
          this.log(`adding ${ ev } to QUEUE: no function`);
          if(!this.queue.some(e => e.event == ev)) {
            this.queue.push({
              event: ev,
              context: context
            });
          }
          this.log(`QUEUE: ${[ ...this.queue.map(e => e.event ) ]}`);
        } else {
          await this.runFromQueue();
          this.log(`ignored ${ev} (no func)`);
        }
        return resolve();
      }

      if(ev == 'on') {
        this.clearDroppables();
      }

      this.onWillRun( ev );
      func = this.prepareFunction( func );
      const newState = await func( context );
      await this.onDidRun( newState, ev, context );


      resolve();
    });
  }

  onWillRun( ev ) {
    this.currentEvent = ev;
    this.running = true;
  }

  prepareFunction(event) {
    if(typeof event === 'string') {
      return () => event;
    } else {
      return event;
    }
  }

  async onDidRun(newState, ev, context) {
    this.log(`${this.state} executed ${ev}, -> ${ newState }`);

    let prevState = this.state;

    this.running = false;
    this.currentEvent = '';

    if(newState) {
      // transition
      if(newState != prevState) {
        this.state = newState;
        const state: SMState = { name: this.state, queue: this.queue };
        this.stateChangeSource.next(state);
        this.log(`transitioned from ${ prevState } to ${ newState }`);
        this.log(`QUEUE: ${ [ ...this.queue.map(e => e.event) ] }`);
        await this.exec('on', context);
      }
    }

    await this.runFromQueue();
  }

  isAlreadyRunning(event) {
    return this.running && event == this.currentEvent;
  }

  async runFromQueue() {
    const lastTry = true;
    if(this.queue.length > 0) {
      let q = this.queue.shift();
      this.log('- running from queue', q);
      await this.exec(q.event, q.context, lastTry);
    } else if (this.hasPendingSystemEvent) {
      this.hasPendingSystemEvent = false;
      const ev = this.appService.isInBackground ? 'pause' : 'resume';
      await this.exec(ev, {}, lastTry);
    }
  }

  log(str, p = null) {
    if(p) {
      console.log(`${this.identifier} ${str}`, p);
    } else {
      console.log(`${this.identifier} ${str}`);
    }
  }
}
