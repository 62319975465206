// Koef. we use to get lower and higher
// bar for user's age
export const AGE_DIVIDER = 7; // koef.

// Maximum distance for matching in km
export const MAX_RANGE = 350;
export const DEFAULT_DISTANCE = 25; // km

/**
 * @constant
 * @type { number } Number that indicates that profile strength reached maximum
 * @default
 */
export const PROFILE_PROGRESS_MAXIMUM = 100;

// Minimum age to create the account
export const PERMITTED_AGE = 13;

// Tribe quality range
export const QUALITY = { min: 100, max: 500 };

// Minimum required level to see other user's highlights
export const MINIMUM_HIGHLIGHTS_LEVEL = 3; // level

// If logs are longer than CONSOLE_LOGS_LIMIT
// we cut it in the start and in the end by
// CONSOLE_LOGS_NUMBER and send CONSOLE_LOGS_NUMBER * 2 rows
export const CONSOLE_LOGS_NUMBER = 500;

// The limits we use to decide how many logs we want to send
export const CONSOLE_LOGS_LIMIT = 2000; // logs rows

// Maximum length of the requests' params in logs
// to avoid noise from big requests
export const CONSOLE_LOG_MAX_PARAM_LENGTH = 50;

// Number of user's contacts we send in one request
// to avoid heavy requests
export const CONTACTS_CHUNK_LENGTH = 100;

// The time user is being pause after 5
// "I'm not feeling it passes" to prevent horny
// motherfuckers spam girls
export const NOT_FEELING_PASSED_TIMER_HOURS = 6; // hours

export const DEFAULT_MEETUP_HOUR = 20; // hours in 24 format

// Timeout in milliseconds for
// Recheck for pushes to be turned on
export const PUSHES_RECHECK_DELAY = 15000;

// After this time we fire pause events to prevent
// spamming of native plugins like camera, geo,
// pushes etc. They fuck with state machines
export const BACKGROUND_TIMEOUT = 500;

// Timeout to check if something is off
// in any part of the app
export const SMELL_CHECK_TIMEOUT = 10000;

// Timeout to close "first seen tooltip" in chat
export const FIRST_TIME_TOOLTIP_CLOSING_TIMEOUT = 1000;

// Time for toast error to stay on the page
// if nothing else specified
export const GENERIC_ERROR_TOAST_DURATION = 3000;

// Time to retry geolocation call if something
// went terribly wrong
export const LOCATION_ATTEMPT_RETRY_TIMEOUT = 30000;

// Time to retry branch init if something went
// horribly wrong
export const BRANCH_RETRY_TIMEOUT = 15000;

// Time to set "date" dividers in chat
// messages section
export const MAX_MESSAGE_TIME_DIFFERENCE = 60000;

// export const UPDATE_GLOBAL_TEXT = "Location filter Updated";

// When scroll bar reaches this area
// TRIGGER_AREA_DEEPNESS * screen height
// from top, older chat message will be
// loaded
export const TRIGGER_AREA_DEEPNESS = 0.15;

// How many messages we allow to store in the
// chat messages container
export const CHAT_MESSAGES_POOL_LENGTH = 50;

/**
 * @constant 1 day time in milliseconds
 */
export const DAY_IN_MS = 86400000;

export const STATEMENTS_SAVE_DELAY = 5000;

export const DAILY_TRIBES_REFRESH_HOUR = 6;

/**
 * @constant { number }
 * Proximity in km which counts as "near_me" and should not change match preferences location to "pick_place"
 */
export const NEAR_ME_PROXIMITY = 25;

export const DEFAULT_AVATAR_PATH = './assets/img/default-avatar.png';
export const DEFAULT_DARK_AVATAR_PATH = './assets/img/default-avatar-dark.png';

export const ENHANCED_MESSAGE_DELAY = 1000;

export enum WeeklyScheduledSearchLimit {
  Plus = 3,
  Default = 1
};

export const DAILY_PASSED_USERS_LIMIT = 3;
export const DAILY_REJECTED_USERS_LIMIT = 4;
export const PASSED_USERS_RESET_DAYS = 1;
export const REJECTED_USERS_RESET_DAYS = 1;

export const CURRENT_USER_NEW_MESSAGE_RECEIVING_GAP = 5000;
export const CURRENT_USER_NEW_MESSAGE_RECEIVING_TIMEOUT = 15000;
export const PAGE_LOADING_ACCEPTABLE_TIME = 5000;
