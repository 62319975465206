import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ButtonWithSuccessStateModule } from "../button-with-success-state/button-with-success-state.module";
import { DateLabelModule } from "../date-label/date-label.module";
import { NoticeModule } from "../notice/notice.component.module";
import { FanMatchingSearchDetailsComponent } from "./fan-matching-search-details.component";

@NgModule({
  declarations: [FanMatchingSearchDetailsComponent],
  imports: [
    CommonModule,
    IonicModule,
    DateLabelModule,
    NoticeModule,
    ButtonWithSuccessStateModule
  ],
  exports: [FanMatchingSearchDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FanMatchingSearchDetailsModule {}