import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject, Observable } from "rxjs";
import { logc } from "../shared/helpers/log";
import { environment } from "../../environments/environment";

interface ClientEvent {
  name: string;
  data: any
}

@Injectable({
  providedIn: 'root'
}) export class DispatcherService {

  public clientEventsQueue: Array<ClientEvent> = [];

  public instructionsActionsSource = new Subject();
  public joinedTribeSource: BehaviorSubject<any> = new BehaviorSubject(null);
  public firstTribeCreatedSource = new BehaviorSubject(false);
  public plusSubscriptionChangedSource = new Subject();
  public matchingStatusSource = new BehaviorSubject(null);
  public highlightsDataSource = new Subject();
  public swapSource = new Subject();
  public expiredTribeLeftSource = new Subject();
  public leavingTribeSource = new Subject();
  public showPopupSource = new Subject();
  public matchesRecomputedSource = new Subject();
  public sessionResumeSource = new Subject();
  public chatMessagesCounterSource = new Subject();
  public newPopupSource = new Subject();
  public newModalSource = new Subject();
  public newInfoModalSource = new Subject();
  public statusBarChangeSource = new Subject();
  public hardwareBackButtonTap = new Subject();
  public leavingChatSource = new Subject();
  public contactsBlockedSource = new Subject();
  public matchMePopoverSource = new Subject();
  public sideMenuResetSource = new Subject();
  public profileUpdatingSource = new Subject();
  public deviceInfoTransmittingSource = new Subject();
  public downloadAppCaseSource = new Subject();
  public closeInstructionsSource = new Subject();
  public connectingInstagramSource: Subject<boolean> = new Subject();
  public onConnectingInstagram: Observable<any>;
  public onDownloadAppCase;
  public onSessionResume;
  public onInstructionsActions;
  public onPlusSubscriptionChanged;
  public onMatchingStatusChanged;
  public onHighlightsDataChanged;
  public onSwap;
  public onExpiredTribeLeft;
  public onLeavingTribe;
  public onShowPopup;
  public onNewPopup;
  public onNewModal;
  public onChatMessagesCounterUpdate;
  public onStatusBarChange;
  public onLeavingChat;
  public onNewInfoModal;
  public onContactsBlocked;
  public onMatchMePopover;
  public onSideMenuReset;
  public onProfileUpdating;
  public onDeviceInfoTransmitting;
  public onFirstTribeCreated;
  public onJoinedTribe: any;
  public onCloseInstructions: any;

  constructor() {
    logc.error("DISPATCHER SERVICE INIT");
    this.onInstructionsActions = this.instructionsActionsSource.asObservable();
    this.onPlusSubscriptionChanged = this.plusSubscriptionChangedSource.asObservable();
    this.onMatchingStatusChanged = this.matchingStatusSource.asObservable();
    this.onHighlightsDataChanged = this.highlightsDataSource.asObservable();
    this.onSwap = this.swapSource.asObservable();
    this.onExpiredTribeLeft = this.expiredTribeLeftSource.asObservable();
    this.onLeavingTribe = this.leavingTribeSource.asObservable();
    this.onShowPopup = this.showPopupSource.asObservable();
    this.onSessionResume = this.sessionResumeSource.asObservable();
    this.onChatMessagesCounterUpdate = this.chatMessagesCounterSource.asObservable();
    this.onNewPopup = this.newPopupSource.asObservable();
    this.onNewModal = this.newModalSource.asObservable();
    this.onStatusBarChange = this.statusBarChangeSource.asObservable();
    this.onLeavingChat = this.leavingChatSource.asObservable();
    this.onNewInfoModal = this.newInfoModalSource.asObservable();
    this.onContactsBlocked = this.contactsBlockedSource.asObservable();
    this.onMatchMePopover = this.matchMePopoverSource.asObservable();
    this.onSideMenuReset = this.sideMenuResetSource.asObservable();
    this.onProfileUpdating = this.profileUpdatingSource.asObservable();
    this.onDeviceInfoTransmitting = this.deviceInfoTransmittingSource.asObservable();
    this.onFirstTribeCreated = this.firstTribeCreatedSource.asObservable();
    this.onDownloadAppCase = this.downloadAppCaseSource.asObservable();
    this.onJoinedTribe = this.joinedTribeSource.asObservable();
    this.onCloseInstructions = this.closeInstructionsSource.asObservable();
    this.onConnectingInstagram = this.connectingInstagramSource.asObservable();

    if(environment.name == 'development' || environment.name == "staging") {
      (window as any).dispatcherService = this;
    }
  }

  addClientEvent(event: ClientEvent) {
    this.clientEventsQueue.push(event);
  }

  handleClientEvents(
    { key, interval }: { key: string, interval: number }
  ): void {
    this.clientEventsQueue =
      this.clientEventsQueue
        .filter(event => {
          if(event.name.match(key)) {
            setTimeout(() => event.data.action(), interval)
          }
          return !event.name.match(key);
        });
  }

  openPopup(popupName, options = {}): void {
    this.newPopupSource.next({ popupName, options });
  }

}
