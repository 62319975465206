import { Component, Injectable } from '@angular/core';

@Component({
  selector: 'chat-video',
  template: `<!--<a (click)="click()">{{url}}</a>-->
             <iframe width="200" 
                     height="145"
                     class = "video-iframe"
                     [src]="src | safe">
             </iframe>`
})
@Injectable()
export class ChatVideo {
  public src: string = "";
  public url: string = "";

  constructor(){ }
  public updateData(data: any){
    this.url = data.url;
    this.src = data.src
  }

  click(){
    (<any> window).open(this.url, '_system');
  }
}

