<div class = "container">
  <ion-icon (click) = "closePopup()" class = "close-icon back-arrow-position right" name = "close"></ion-icon>
  <!--<ion-icon class = "prize-icon" name="time"></ion-icon>-->
  <img class = "main-icon img" src = "{{icon}}" alt = "Local">
  <div class = "header">
    <div class = "sub-header blue">Adjust Preferences</div>
  </div>
  <div class = "content">
    <div class = "title">Match with {{gender}} only?</div>
    <div class = "description">You’re currently open to matching with anyone. <br> Would you like to match with {{gender}} only?</div>
    <ion-button class = 'turquoise-gradient-btn text-white turquoise-btn-shadow ionic-button btn-large-action primary-popup-button'
                (click) = "adjustPreferences()"
                expand = "block"
                shape = "round">
       Yes, {{gender == 'male' ? 'Men' : 'Women'}} Only
    </ion-button>
    <ion-button  fill = "clear"
                 (click) = "closePopup()"
                 class = "empty-button no-border">No, Remain Open to Anyone</ion-button>
  </div>
</div>
