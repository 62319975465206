import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ImageRowModule } from "../image-row/image-row.module";
import { InteractiveListComponent } from "./interactive-list.component";

@NgModule({
  declarations: [InteractiveListComponent],
  imports: [IonicModule, CommonModule, ImageRowModule],
  exports: [InteractiveListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InteractiveListModule {}