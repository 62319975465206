import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ChipComponent } from "./chip.component";

@NgModule({
  declarations: [ChipComponent],
  imports: [
    IonicModule,
    CommonModule
  ],
  exports: [ChipComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChipModule {}