import { IonicModule } from '@ionic/angular';
import { NgModule } from "@angular/core";
import { HighlightsComponent } from "./highlights.component";
import { HighlightsTabModule } from './tabs/highlights-tab.module';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { Loading } from 'src/app/components/loading/loading';

@NgModule({
  declarations: [
    HighlightsComponent,
  ],
  entryComponents: [
    Loading
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    LoadingModule,
    HighlightsTabModule
  ],
  exports: [
    HighlightsComponent
  ]
})

export class HighlightsModule {}
