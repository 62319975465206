import { Component, OnInit } from '@angular/core';
import {Config} from "../../services/config/config";
import {AudiencesService} from "../../services/analytics/audiences.service";
import {UserService} from "../../services/data/user.service";
import {Observable} from "rxjs";
import {DailyTribesService} from "../../services/daily-tribes.service";
import {NavController} from "@ionic/angular";
import {TribesService} from "../../services/data/tribes.service";
import {NavigationExtras} from "@angular/router";
import {DispatcherService} from "../../services/dispatcher.service";

@Component({
  selector: 'matches-plate',
  template: `
    <div class = "matches-overflow"
         [class.half-opacity] = "isPlus && !canStartGroup"
         (click) = "openMatchesFlow()" >
      <div class="little-plate"></div>
      <div class="smaller-plate"></div>
      <div class="yellow-plate">
        <div class="match-avatar">
          <img src = "./assets/img/pixel-img.png" alt = "Image">
          <div *ngIf = "!isPlus" class="matches-number-container">
            <div class="matches-number">+10</div>
          </div>
        </div>
      </div>
      <div class = "info-section matches-label">
        <div class = "title" [innerHTML] = "title"></div>
      </div>
    </div>
  `,
  styleUrls: [
    './matches-plate.component.scss',
    './../tribe/tribe.component.scss'
  ],
})
export class MatchesPlateComponent implements OnInit {
  public isPlus: boolean = false;
  public hasChats: boolean = false;
  public canStartGroup: boolean = false;
  public title: string = ''
  constructor(private config: Config,
              private navCtrl: NavController,
              private tribesService: TribesService,
              private dispatcherService: DispatcherService,
              private audiencesGroup: AudiencesService,
              private dailyTribesService: DailyTribesService) {
  }

  ngOnInit() {
    this.setTitle();
    this.canStartGroup = this.dailyTribesService.canStartGroup();
    this.isPlus = this.config.isPlus();
    this.hasChats = this.tribesService.hasChats();
  }

  setTitle() {
    if(this.config.isFan()) {
      this.title = "Individual <br> Matches";
      return;
    }
    this.title = this.hasChats ? 'Matches' : 'Individual <br> Matches';
  }

  async openMatchesFlow(): Promise<void> {
    if(this.isPlus && this.dailyTribesService.reachedDailyTribesLimit()) {
      return this.dispatcherService.matchMePopoverSource.next({
        name: "daily_tribes_limit_reached",
        action: "open"
      })
    }
    const inProgressTribe = this.tribesService.data.find(tribe => tribe.status == 'in_progress');
    const navigationExtras: NavigationExtras = { state: { inProgressTribe } };
    await this.navCtrl.navigateForward('/matches', navigationExtras);
  }

}
