import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {ChipsSelectorComponent} from "./chips-selector.component";
import {CommonModule} from "@angular/common";
import {PipesModule} from "../../pipes/pipes.module";
import {IonicModule} from "@ionic/angular";
import {FormsModule} from "@angular/forms";
import {CloseButtonModule} from "../close-button/close-button.module";
import {CloseButtonComponent} from "../close-button/close-button.component";
import {DirectivesModule} from "../../directives/directives.module";

@NgModule({
  entryComponents: [
    CloseButtonComponent
  ],
  declarations: [
    ChipsSelectorComponent
  ],
  imports: [
    DirectivesModule,
    CloseButtonModule,
    CommonModule,
    IonicModule,
    FormsModule,
    PipesModule
  ],
  exports: [
    ChipsSelectorComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ChipsSelectorModule {}
