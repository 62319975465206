import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import { MatchingTypePickerComponent } from './matching-type-picker.component';
import { CheckmarkButtonsListModule } from '../checkmark-buttons-list/checkmark-buttons-list.module';
import { NoticeModule } from '../notice/notice.component.module';
import { CheckmarkButtonModule } from '../checkmark-button/checkmark-button.module';
@NgModule({
  declarations: [MatchingTypePickerComponent],
  imports: [
    CommonModule,
    IonicModule,
    CheckmarkButtonsListModule,
    NoticeModule,
    CheckmarkButtonModule
  ],
  exports: [MatchingTypePickerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class MatchingTypePickerModule {}