export enum TribeStatus {
  Pending = "pending",
  Inviting = "inviting",
  Approving = "approving",
  Confirming = "confirming",
  Formed = "formed",
  Forming = "forming",
  Expired = "expired",
  Incomplete = "incomplete"
}
