export const TEST_HIGHLIGHTS = {
  "goals": [
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 }
  ],
  "interests": [
    { "factor_id": 91, "type": "spectrum", "text": "Love Live Performances", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love Live Performances", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love Performances", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love Live Performances", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love Live Performances", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love ", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love Live Performances", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love Live ", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love ", "spectrum_score": 0.85 },
    { "factor_id": 91, "type": "spectrum", "text": "Love Live Performances", "spectrum_score": 0.85 }
  ],
  "traits": [
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 41, "type": "spectrum", "text": "More Prudent", "spectrum_score": 0.7 },
    { "factor_id": 30, "type": "spectrum", "text": "Appreciate Art", "spectrum_score": 0.75 },
    { "factor_id": 39, "type": "spectrum", "text": "More Hard-Working", "spectrum_score": 0.75 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 41, "type": "spectrum", "text": "More Prudent", "spectrum_score": 0.7 },
    { "factor_id": 30, "type": "spectrum", "text": "Appreciate Art", "spectrum_score": 0.75 },
    { "factor_id": 39, "type": "spectrum", "text": "More Hard-Working", "spectrum_score": 0.75 },
    { "factor_id": 35, "type": "spectrum", "text": "More Progressive", "spectrum_score": 0.7 },
    { "factor_id": 41, "type": "spectrum", "text": "More Prudent", "spectrum_score": 0.7 },
    { "factor_id": 30, "type": "spectrum", "text": "Appreciate Art", "spectrum_score": 0.75 },
    { "factor_id": 39, "type": "spectrum", "text": "More Hard-Working", "spectrum_score": 0.75 }
  ]
}

export const OPPOSITE_DESCRIPTORS = [
  {
      "id": 144,
      "text": "More Intellectually Stimulating Discussions",
      "score": 0.99
  },
  {
      "id": 138,
      "text": "Learn New Skills",
      "score": 0.99
  },
  {
      "id": 86,
      "text": "More DIY Projects",
      "score": 0.99
  },
  {
      "id": 141,
      "text": "Grab Drinks More Often",
      "score": 0.99
  },
  {
      "id": 54,
      "text": "Super Sporty",
      "score": 0.99
  },
  {
      "id": 3,
      "text": "Working on your English",
      "score": 0.01
  },
  {
      "id": 137,
      "text": "To Have a Support Group",
      "score": 0.99
  },
  {
      "id": 142,
      "text": "Go to the Cinema More Often",
      "score": 0.99
  },
  {
      "id": 33,
      "text": "Highly Intellectual",
      "score": 0.99
  }
]