import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';

@Injectable({
  providedIn: 'root',
})
export class HighlightStylesService {
  public data: any = null;

  private defaultIcon = {
    icon_high: "globe",
    icon_low: "globe",
    color_high: "#9cd4ec",
    color_low: "#9cd4ec"
  }

  constructor(public http: HttpClient,
              public config: Config) {
    (window as any).highlightsStyles = this;  
  }

  styleFor(factorId, value) {
    let style = this.data[factorId];
    if(style == null) {
      return {
        color: '#9cd4ec',
        icon: 'globe'
      }
    }
    let i = (value > .5) ? 'high' : 'low';
    return { 
      color: style['color_' + i], 
      icon: style['icon_' + i]
    }
  }

  async mapForHighlights(highlights) {
    const styles = await this.load();
    highlights.forEach((highlight) => {
      const factorItem = styles[highlight.factor_id] || this.defaultIcon;
      const score = highlight.spectrum_score;
      highlight.icon = factorItem[score > 0.5 ? 'icon_high' : "icon_low"] || "globe";
      highlight.color = factorItem[score > 0.5 ? 'color_high' : "color_low"] ||  "#9cd4ec";
    })
  }

  async mapOppositeDescriptors(highlights) {
    const styles = await this.load();
    highlights.forEach((highlight) => {
      const factorItem = styles[highlight.id] || this.defaultIcon;
      const score = highlight.score;
      highlight.icon = factorItem[score > 0.5 ? 'icon_high' : "icon_low"] || "globe";
      highlight.color = factorItem[score > 0.5 ? 'color_high' : "color_low"] ||  "#9cd4ec";
    })
  }

  load() {
    return new Promise((resolve, reject) => { 
      if(this.data) { return resolve(this.data); }
      this.http.get('./assets/data/highlight_styles.json')
        .toPromise()
        .then(
          (data) => { 
            this.data = data;
            resolve(this.data);
         }, reject);
    });
  }
}

