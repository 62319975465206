import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {TopicTribeAvatarComponent} from "./topic-tribe-avatar.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {LottieModule} from "ngx-lottie";

@NgModule({
  declarations: [TopicTribeAvatarComponent],
  imports: [
    CommonModule,
    IonicModule,
    LottieModule
  ],
  exports: [TopicTribeAvatarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TopicTribeAvatarModule {}
