import { IonicModule } from '@ionic/angular';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { InstagramModalComponent } from 'src/app/components/instagram-modal/instagram-modal.component';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    InstagramModalComponent,
  ],
  imports: [
    SwiperModule,
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [
    InstagramModalComponent
  ]
})

export class InstagramModalModule {}
