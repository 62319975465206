import { Injectable, ViewChild } from '@angular/core';
import { Config } from './config/config';
import { VisionService } from './vision.service';
import { UtilsService } from "./utils.service";
import { Platform, ModalController, ActionSheetController, AlertController, NavController } from '@ionic/angular';
import { Subject } from "rxjs/Subject";
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import {AnalyticsService} from "./analytics/analytics.service";
import {first} from "rxjs/operators";
import {SentryService} from "./performance/sentry.service";
import {logc} from "../shared/helpers/log";

declare const AWS: any;

export enum PictureStatus {
  Invalid = "invalid",
  Unverified = "unverified",
  OnHold = "on_hold_missing_smile",
  Verified = "verified"
}

@Injectable({
  providedIn: 'root',
})
export class ProfilePictureService {
  public bucket : any;
  public awsAccessKeyId: string = 'AKIAJEVIF72XB46E7EKQ';
  public awsSecretAccessKey: string = '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';
  public awsRegion: string = 'us-east-1';
  public awsBucket: string = 'me3.profile-pictures'
  public currentData:any = null;
  public fullSizePicture:any = null;

  private croppedSource:any = new Subject();
  public onCropped:any = null;

  constructor(public actionSheetCtrl: ActionSheetController,
              public config: Config,
              public alertCtrl: AlertController,
              public navCtrl: NavController,
              public modalCtrl: ModalController,
              public platform: Platform,
              private camera: Camera,
              private file: File,
              public utils: UtilsService,
              private sentryService: SentryService,
              private analyticsService: AnalyticsService,
              public vision: VisionService,
              private errorTrackingService: SentryService){

    this.onCropped = this.croppedSource.asObservable();
    // (<any>window).filePlugin = this.file;

    AWS.config.update({
      accessKeyId: this.awsAccessKeyId,
      secretAccessKey: this.awsSecretAccessKey
    });
    AWS.config.region = this.awsRegion;
    this.bucket = new AWS.S3({
      params: {
        Bucket: this.awsBucket
      }
    });
  }

  cropped(data) {
    this.croppedSource.next(data);
  }

  crop(base64) {
    return new Promise( (resolve, reject) => {
      this.currentData = base64;
      this.navCtrl.navigateForward('/picture/crop');
      this.onCropped.pipe(first()).subscribe( base64 => {
        !!base64 ? resolve(base64) : reject('Crop was canceled.');
      });
    });
  }

  mime(uri) {
    return uri.split(';')[0].slice(5);
  }

  base64ToByteArray(base64String) {
    let binaryString = atob(base64String);
    let byteArray = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++)  {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    //byteArray.type = this.mime(base64String);
    return byteArray;
  }

  resizeImage(srcBase64,width) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      const that = this;
      img.onload = function() {
        var canvas = document.createElement('canvas'),
            height = img.height,
            ctx = canvas.getContext("2d");

        //scale the image to width and keep aspect ratio
        var scaleFactor = width / img.width;
        canvas.width = width;
        canvas.height = height * scaleFactor;

        // draw image
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // export base64
        resolve(that.prepareBase64(canvas.toDataURL('image/jpeg', 0.5)));
      };

      img.src = srcBase64;
    });
  }

  //TODO i want to kill myself because of this method
  async captureHtml5Image(forKey, originPath = '') {
    console.log('--- ORIGIN PATH: ', originPath);
    let popUpMessageTimeout;
    const el = document.getElementById('camera-input') as any;
    //Sometimes when uploading many in a row the control needs to be reset (chrome)
    el.value = "";

    return new Promise((resolve, reject) => {
      el.onchange = async d => {
        if(el.files.length == 0)
          return null;  

        let tracingStarted = performance.now();
        if( originPath != '/picture/cannot_detect_face' && originPath != '/sign-up-flow') {
          await this.utils.showLoading('Loading picture...');
        }
        try {
          const reader = new FileReader();
          reader.onload = async (r: any) => {
            let base64 = r.target.result;
            //This seems to be causing interference with croppie's exif correction
            //base64 = await this.resetOrientation(base64, orientation);
            this.fullSizePicture = await this.resizeImage(base64,700);
            this.utils.doneLoading();
            base64 = this.prepareBase64(base64);
            let cropStarted = performance.now();
            this.crop(base64).then(async base64 => {

              this.utils.doneLoading();
              if(originPath == '/picture/cannot_detect_face'){
                await this.imageLoadingProcess();
              } else if (originPath == '/sign-up-flow') {
                if(this.config.getFlag('opening_is_done')) {
                  this.navCtrl.navigateBack('tabs/tribes');
                } else {
                  this.navCtrl.navigateForward('opening-quiz-time');
                }
                // this.navCtrl.navigateForward(this.utils.is13to17(this.config.get('birthday')) ? 'age_permission' : 'opening-quiz-time');
                this.utils.showGenericMessage('Uploading photo....',15000);
              } else {
                this.utils.showLoading('Preparing picture...');
              }
              let cropTime = Math.floor(performance.now() - cropStarted);
              this.validateImage(base64, forKey, originPath).then(async (data: object) => {
                await this.uploadfullSizePicture(this.fullSizePicture, `${forKey}-fullsize`);
                let timeData = { 
                  crop: cropTime,
                  total: Math.floor(performance.now() - tracingStarted),
                };
                logc.green("HTML - picture is validated, caching it...");
                this.config.set("pictureRejectionReason", null);
                this.config.processCachePicture(base64);
                // this.sendTraceAnalytics(timeData,"new_profile_picture");
                this.utils.closeGenericMessage();
                this.utils.doneLoading();
                resolve(data);
              }).catch((err) => {
                logc.error("Picture is not validated: ", err);
                this.vision.rejectedPicture = base64;
                if(this.config.getProfile().pictureStatus === PictureStatus.Unverified ||
                  !this.config.getProfile().picture)
                {
                  logc.error("HTML - Picture is unverified or not exist, caching it...");
                  this.config.set("pictureRejectionReason", err?.reason);
                  this.config.processCachePicture(base64);
                }
                reject(err);
              }).finally(() => {
                  console.log(this.popUpMessageTimeout);
                  if (this.popUpMessageTimeout)  {
                    clearTimeout(this.popUpMessageTimeout);
                  }
              });
            }, (err) => {
              this.analyticsService.trackEvent({
                key: 'pic_error',
                value: 1,
                page: location.pathname,
                reason: "crop",
                duration: Math.floor(performance.now() - tracingStarted),
                details: JSON.stringify(err)
              });
              reject(err);
            });
          };
          reader.readAsDataURL(el.files[0]);
        } catch(err) {
          this.analyticsService.trackEvent({ 
            key: 'pic_error',
            value: 1,
            page: location.pathname,
            reason: "web.captureHtml5Image",
            duration: Math.floor(performance.now() - tracingStarted),
            details: JSON.stringify(err)
          });

          //An unexpeted error happened
          this.utils.doneLoading();
        }
      };
      el.click();
    });
  }

  uploadfullSizePicture(fullSizePicture, fullSizeKey){
    let timeData : any = {};
    let traceStarted = performance.now();
    return new Promise((resolve, reject) => {
      this.uploadBase64ToS3(fullSizeKey,fullSizePicture).then(url => {
          let s3Finished = performance.now();
          timeData.s3 =  Math.floor(s3Finished);
          timeData.total =  Math.floor(s3Finished - traceStarted);
          // this.sendTraceAnalytics(timeData,"new_profile_picture");
          this.utils.doneLoading();
          resolve(url);
      },err => {
        this.analyticsService.trackEvent({ 
          key: 'pic_error',
          value: 1,
          page: location.pathname,
          reason: "uploadfullSizePicture.uploadBase64ToS3",
          duration: Math.floor(performance.now() - traceStarted),
          details: JSON.stringify(err)
        });
        this.utils.doneLoading();
        reject(err);
      });
    });
  }
  popUpMessageTimeout;
  private async imageLoadingProcess() {
    await this.utils.showLoading('Uploading...', 15000);
    this.utils.changeLoadingMessage('Scanning...', 5000);
    this.popUpMessageTimeout = setTimeout(() =>
      this.utils.showGenericMessage('This is taking longer than usual. Thanks for your patience.'),
      4900);
    this.utils.changeLoadingMessage('Finalizing...', 10000);
  }

  uploadBase64ToS3(key, base64String) {
    let byteArray = this.base64ToByteArray(base64String);
    return this.uploadToS3(key, byteArray);
  }

  uploadToS3(key, byteArray): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        Key: key + '.jpg',
        Body: byteArray,
        ContentType: 'image/jpeg',
        ContentEncoding: 'base64',
        CacheControl: 'max-age=86400'
      };

      this.bucket.upload(params, (err, data) => {
        if(err) {
          reject(err);
        } else {
          resolve(data.Location);
        };
      });
    });
  }

	showUploadMediaPicker({ originPath = null } = {}){
    const forKey = this.utils.getRandomPictureId();
    if(this.config.isPWA)
      return this.captureHtml5Image(forKey, originPath);

    return new Promise( async (resolve, reject) => {
      let buttons = [
        {
          text: 'Take a photo',
          handler: () => {
            this.takePictureWithValidation(this.camera.PictureSourceType.CAMERA, forKey, originPath)
              .then( resolve, err => {
                console.log(err);
                if(err != "No Image Selected") {
                  reject(err);
                }
            });
          }
        },
        {
          text: 'Select from gallery',
          handler: () => {
            console.log('=================');
            this.takePictureWithValidation(this.camera.PictureSourceType.PHOTOLIBRARY, forKey , originPath).then(resolve, err => { 
              if(err != "No Image Selected") {
                reject(err);
              }
            });
          }
        }
      ];

      if(this.config.platformName == 'web') {
        buttons.push({ 
          text: 'Web',
          handler: () => { this.showWebUrlPopup().then(resolve, reject); }
        });
      }

      buttons.push({
        text: 'Cancel',
        handler: () => { 
          reject();
        }
      });

      let takePictureMenu = await this.actionSheetCtrl.create({
        header: 'Picture Sources',
        buttons: buttons,
        cssClass: 'custom-action-sheet'
      });

      await takePictureMenu.present();
    });
	}

  showWebUrlPopup() {
    return new Promise(async (resolve, reject) => {
      let prompt = await this.alertCtrl.create({
        header: 'Web image',
        inputs: [{ name: 'url', placeholder: 'URL' }],
        buttons: [
          { text: 'Cancel', handler: () => resolve(null) },
          { text: 'Save', handler: (data) => resolve(data.url) }
        ]
      });
      await prompt.present();
    });
  }

  // removeParams(path) {
  //   let paramsIndex = path.lastIndexOf('?');
  //   if(paramsIndex > -1) {
  //     let extra = path.substring(paramsIndex);
  //     path = path.replace(extra, '');
  //   }
  //   return path;
  // }

  // uploadFromUri(path, forKey) {
  //   path = this.removeParams(path);
  //   let a = path.split('/');
  //   let fi = a[a.length - 1];
  //   let fo = path.replace('/' + fi, '');

  //   return new Promise((resolve, reject) => {
  //     this.file.readAsArrayBuffer(fo, fi).then( arrayBuffer => {
  //       this.uploadToS3(forKey, arrayBuffer).then(resolve, reject);
  //     }, reject);
  //   });
  // }

  prepareBase64(base64: string): string {
    return base64
      .replace(/data:image\/(png|jpeg|jpg|webp);base64,/g, "")
      .replace("\\", "")
  }

  async prepareWebp(base64: string): Promise<string> {
    const path = 'file://' + base64.substring(0, base64.lastIndexOf('/') + 1);
    const filename = base64.substring(base64.lastIndexOf('/') + 1);
    try {
      const result = await this.file.readAsDataURL(path, filename);
      return result.replace("data:image/webp;base64,", "");
    } catch (e) {
      logc.error('* Reading file err: ', e);
      return base64;
    }
  }

  async takePictureWithValidation(sourcetype, forKey, originPath = '') {
    console.log("-- SOURCETYPE: ", sourcetype);
    //pictureStatus to be delt with outside
    if( originPath != '/picture/cannot_detect_face' && originPath != '/sign-up-flow') {
      await this.utils.showLoading('Adding image...');
    }
    let tracingStarted = performance.now();

    let options = {
      quality : 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      sourceType: sourcetype,
      encodingType: this.camera.EncodingType.JPEG, //0 JPEG
      saveToPhotoAlbum: false,
      correctOrientation: true,
      cameraDirection: 1,//front
      targetWidth: 700,
      targetHeight: 700,
      allowEdit: false
    };

    return new Promise(async (resolve, reject) => {
      this.camera.getPicture(options).then(async (base64) => {
        if(base64.match(/webp/g)) {
          base64 = await this.prepareWebp(base64)
        }
        this.fullSizePicture = this.prepareBase64(base64);
        this.utils.doneLoading();
        this.crop(base64).then( async base64 => {
          console.log("CROPPED!");
          if(originPath == '/picture/cannot_detect_face') {
            await this.imageLoadingProcess();
          } else if (originPath == '/sign-up-flow') {

            if(this.config.getFlag('opening_is_done')) {
              this.navCtrl.navigateBack('tabs/tribes');
            } else {
              this.navCtrl.navigateForward('opening-quiz-time');
            }
            this.utils.showGenericMessage('Uploading photo....',15000);
            this.config.processCachePicture(base64);
          } else {
            this.utils.showLoading('Adding image...');
          }
          this.validateImage(base64, forKey, originPath).then( async (data) => {
            await this.uploadfullSizePicture(this.fullSizePicture, `${forKey}-fullsize`);
            let timeData = { duration: Math.floor(performance.now() - tracingStarted) }
            // this.sendTraceAnalytics(timeData,"new_profile_picture");
            logc.success("Camera picture is validated, caching...");
            this.config.set("pictureRejectionReason", null);
            this.config.processCachePicture(base64);
            this.utils.closeGenericMessage();
            this.utils.doneLoading();
            resolve(data);
          }).catch((err) => {
            logc.error("Camera - Picture is not validated...", err);
            this.vision.rejectedPicture = base64;
            if(this.config.getProfile().pictureStatus === PictureStatus.Unverified ||
              !this.config.getProfile().picture)
            {
              logc.error("Camera - Picture is unverified or not exist, caching it...");
              this.config.set("pictureRejectionReason", err?.reason);
              this.config.processCachePicture(base64);
            }
            reject(err);
          }).finally(() => {
              if (this.popUpMessageTimeout)  {
                clearTimeout(this.popUpMessageTimeout);
              }
            })
        }, (err) => {
          this.analyticsService.trackEvent({ 
            key: 'pic_error',
            value: 1,
            reason: "crop",
            page: location.pathname,
            duration: Math.floor(performance.now() - tracingStarted),
            source_type: sourcetype,
            details: JSON.stringify(err)
          });
          reject(err);
        });
      }, (err) => {
        this.analyticsService.trackEvent({ 
          key: 'pic_error',
          value: 1,
          page: location.pathname,
          reason: "native.getPicture",
          duration: Math.floor(performance.now() - tracingStarted),
          source_type: sourcetype,
          details: JSON.stringify(err)
        });

        this.utils.doneLoading();
        reject(err);
      });
    });
  }

  validateImage(base64, forKey, originPath){
    return new Promise((resolve, reject) => {
      let timeData : any = {}; 

      let uploadTimeout = setTimeout(() => {
        this.analyticsService.trackEvent({ 
          key: 'pic_error',
          page: location.pathname,
          value: 1, 
          reason: "picture_verification_timeout"
        });
        this.utils.doneLoading();
        reject();
      }, 15000);

      let traceStarted = performance.now();

      this.vision.verifyBase64(base64).then((data:any) => {
        console.log('--- profile-picture.service.ts validateImage() verifyBase64 data: ', data);
        let visionFinished = performance.now();
        timeData.vision =  Math.floor(visionFinished - traceStarted);

        this.analyticsService.trackEvent({ key: "pic_verified", value: 1, source: "automated" });
        this.config.update("cachedPicture", null);

        this.uploadBase64ToS3(forKey, base64).then(url => {
          let s3Finished = performance.now();
          timeData.s3 =  Math.floor(s3Finished - visionFinished);
          timeData.total =  Math.floor(s3Finished - traceStarted);
          // this.sendTraceAnalytics(timeData,"picture_verification");
          logc.info("Caching picture in validateImage() method...");
          clearTimeout(uploadTimeout);
          resolve({
            url: url,
            gender: data.gender,
            hasSmile: data.hasSmile
          });
        }, err => {
          console.log('--- profile-picture.service.ts validateImage() verifyBase64 error: ', err);
          this.processValidationError(err);
          this.utils.showGenericError({});
          this.analyticsService.trackEvent({
            key: 'pic_error',
            value: 1,
            page: location.pathname,
            reason: "uploadBase64ToS3",
            duration: Math.floor(performance.now() - traceStarted),
            details: JSON.stringify(err)
          });

          clearTimeout(uploadTimeout);
          this.utils.doneLoading();
          reject(err);
        });
      }, err => {

        let trackedErr = { ...err };
        delete trackedErr.data;

        console.log(trackedErr);
        if(this.vision.denialReasons.length >= 3 || this.vision.denialReason === 'badResponse') {
          console.log('PROFILE PICTURE IMAGE DENIED');
          this.uploadBase64ToS3(forKey, base64).then(url => {
            const tags = { klass: 'profile_picture', func: 'validateImage', reason: 'photo_denied_3_times' };
            const extras = {
              user_id: this.config.get('id'),
              photo_url: url,
              reasons: this.vision.denialReasons
            }
            this.sentryService.sendMessage(this.vision.denialReason === 'badResponse'
              ? "Bad vision response"
              : 'Photo denials limit reached',
              tags, extras);
          })
        }
        this.analyticsService.trackEvent({
          key: 'pic_rejected',
          value: 1,
          source: "automated",
          reason: trackedErr.reason
        });

        clearTimeout(uploadTimeout);
        if(originPath == '/sign-up-flow') this.utils.closeGenericMessage();
        this.utils.doneLoading();
        reject(err);//sends uri as parameter
      });
    });
  }

  processValidationError( err ) {
    if(err.code == "RequestTimeTooSkewed") {
      const message = "RequestTimeTooSkewed error";
      const tags = { klass: "ProfilePictureService", func: "processValidationError()" };
      const extras = { error: err };
      this.errorTrackingService.sendException(message, tags, extras);
    }
  }

  async sendTraceAnalytics(timeData,key) {
    let event = {
      key: key,
      value: 1,
      ...timeData
    };
    this.analyticsService.trackEvent(event)
  }

}
