import { Injectable } from '@angular/core';
import { addHours, endOfWeek, formatISO, getDate, getHours, getMonth, getYear, nextFriday, nextSaturday, nextSunday, nextThursday, parseISO, startOfWeek } from 'date-fns';
import { keys, trimStart, values } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MeetupStatus } from '../shared/enums/meetup-statuss.enum';
import { isEmpty } from '../shared/helpers/lodash';
import { logc } from '../shared/helpers/log';
import { AnalyticsService } from './analytics/analytics.service';
import { ApiService } from './data/api.service';
import { TribesService } from './data/tribes.service';

const AVAILABILITY_UPDATE_DELAY = 2000;

export interface MeetupAvailabilities {
  [ key:string ]: Array<MeetupEvent>
}

interface UsersMeetupSelections {
  [key: string]: {
    [key: string]: MeetupStatus
  }
}

export type MeetupEvent = [ number | string, MeetupStatus ];

export interface ShortDate {
  date: string,
  hour: string | number
}

@Injectable({
  providedIn: 'root'
})
export class MeetupService {
  public defaultAvailabilities: MeetupAvailabilities;
  public availabilities: MeetupAvailabilities;
  public currentTribe: any = null;
  public tribeUsers: any[] = [];

  public availabilitiesPickedByUsers: UsersMeetupSelections = null;

  public availabilityUpdatedSource: Subject<any> = new Subject();
  public onAvailabilityUpdated: Observable<any> = null;

  constructor(
    private tribesService: TribesService, 
    private apiService: ApiService,
    private analyticsService: AnalyticsService
  ) {
    (window as any).meetupService = this;

    this.onAvailabilityUpdated = this.availabilityUpdatedSource.asObservable();

    this.tribesService.onTribeLoaded.subscribe(tribe => {
      this.currentTribe = tribe;
      this.availabilitiesPickedByUsers = tribe?.data?.availabilities || {};
      this.tribeUsers = [ tribe.tribe_user, ...tribe.users ];
      this.prepareAvailabilities(tribe?.data?.availabilities || {});
    });

    this.onAvailabilityUpdated.subscribe((data) => {
      if(this.currentTribe.id == data.tribe_id) {
        this.availabilitiesPickedByUsers = data.data.availabilities;
        this.updateAvailabilities(this.formatAvailabilities(this.availabilitiesPickedByUsers));
        // this.availabilities = this.formatAvailabilities(data.data.availabilities);
      }
    });
  }

  private getBestMeetupDatesOf(week) {
    return [
      addHours(nextThursday(week), 18),
      addHours(nextFriday(week), 12),
      addHours(nextSaturday(week), 12),
      addHours(nextSaturday(week), 21),
      addHours(nextSunday(week), 12)
    ];
  }

  static formatDate(date: string): string {
    const parsedDate = parseISO(date);
    return formatISO(new Date(
      getYear(parsedDate),
      getMonth(parsedDate),
      getDate(parsedDate),
      getHours(parsedDate),
    ));
  }

  private getDefaultDates() {
    const DATES_TO_PICK = 3;
    const CURRENT_DATE = new Date();
    let dates = [];
    let bestMeetupDates = this.getBestMeetupDatesOf(startOfWeek(CURRENT_DATE));
    const indexOfFirstDateLater = bestMeetupDates.findIndex(e => e > CURRENT_DATE);
    dates = bestMeetupDates.slice(indexOfFirstDateLater, indexOfFirstDateLater + DATES_TO_PICK);
    if(indexOfFirstDateLater + DATES_TO_PICK > bestMeetupDates.length - 1) {
      const nextWeekDatesToPickNumber = indexOfFirstDateLater + DATES_TO_PICK - bestMeetupDates.length;
      const nextWeekbestMeetupDates = this.getBestMeetupDatesOf(endOfWeek(CURRENT_DATE));
      dates = dates.concat(nextWeekbestMeetupDates.slice(0, nextWeekDatesToPickNumber));
    }
    return dates;
  }

  private prepareDefaultData(): void {
    this.defaultAvailabilities = {};
    const dates = this.getDefaultDates().map((date) => formatISO(date));

    dates.forEach((date) => {
      this.defaultAvailabilities[date] = this.getDate(this.tribeUsers);
    });
  }

  getDate(users): MeetupEvent[] {
    return users.map(({ id }) => [id, MeetupStatus.Blank])
  }

  formatAvailabilities(availabilities): MeetupAvailabilities {
    let usersAvailabilities = {};
    // logc.info("prepareAvailabilities(availabilities) avaialabilities: ", availabilities);
    
    for(let date in availabilities) {
      if(parseISO(date) < new Date()) {
        delete availabilities[date];
      } else {
        usersAvailabilities[date] = this.getDate(this.tribeUsers);
      }
    }

    for(let date in usersAvailabilities) {
      for(let userId in availabilities[date]) {    
        usersAvailabilities[date] = 
          usersAvailabilities[date]
            .map(([id, status]) => 
              id == userId
                ? [ id, availabilities[date][id] ]
                : [ id, status]
            )
          }
    }

    return usersAvailabilities;
  }

  updateAvailabilities(availabilities) {
    for(let date in this.availabilities) {
      if(!keys(availabilities).includes(date)) {
        this.availabilities[date] = this.getDate(this.tribeUsers);
      }
    }
    this.availabilities = {
      ...this.availabilities,
      ...availabilities
    }
  }

  private prepareAvailabilities(availabilities) {
    this.prepareDefaultData();
    const usersAvailabilities = this.formatAvailabilities(availabilities);

    // logc.crimson("usersAvailabilities: ", usersAvailabilities);

    this.availabilities = {
      ...this.defaultAvailabilities,
      ...usersAvailabilities
    };
  }

  static availabilitiesToApiRequest(data) {
    let availabilities = {};
    for(let key in data) {
      let userData = {};
      data[key].forEach(([id, status]) => userData[id] = status)
      availabilities[key] = userData;
    }
    return availabilities
  }

  static availabilitiesToMeetupEvents(data) {

  }

  getDefaultAvailabilities(): MeetupAvailabilities {
    return this.defaultAvailabilities;
  }

  updateTimeout = null;
  updateAvailability(
    date: string, 
    userId: number, 
    status: MeetupStatus
  ) {
    clearTimeout(this.updateTimeout);
    let availabilities = this.availabilitiesPickedByUsers;
    if(status != MeetupStatus.Blank) {
      if(!availabilities[date]) {
        availabilities[date] = { [userId]: status };
      } else { 
        availabilities[date][userId] = status;
      } 
    } else {
      if(availabilities[date] && availabilities[date][userId]) {
        delete availabilities[date][userId];
      }
      if(isEmpty(availabilities[date])) {
        delete availabilities[date];
      }
    }
    // logc.info("availabilities: ", availabilities);
    const tribe_id = this.currentTribe.id;
    this.updateTimeout = setTimeout(() => {
      let key = "availability_updated";
      if(this.allUsersAvailable(availabilities, date)) {
        key = "meetup_date_confirmed";
      } 
      this.sendAnalytics(key, status);
      this.apiService.put(`tribes/${ tribe_id }/availabilities`, { tribe_id, availabilities })
    }, AVAILABILITY_UPDATE_DELAY);
  }

  private allUsersAvailable(availabilities, date): boolean {
    return keys(availabilities[date]).length == 3 && values(availabilities[date]).every(status => status == MeetupStatus.Available)
  }

  private sendAnalytics(key, status): void {
    this.analyticsService.trackEvent({ 
      key, 
      value: 1, 
      tribe_id: this.currentTribe.id, 
      tribe_status: this.currentTribe.status,
      status
    })
  }
}
