import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {Subject} from "rxjs/Subject";
import {takeUntil} from "rxjs/operators";
import {Platform} from '@ionic/angular';
import has = Reflect.has;

/**
 * Generated class for the KeyboardAttachDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Directive({
  selector: '[keyboard-attach]' // Attribute selector
})
export class KeyboardAttachDirective {

  @Input('chat') chat;

  public unsubscribe = new Subject<void>();

  public keyboardListener;
  public keyboardListenerClose;

  public keyboardShowSubscription: any;
  public keyboardHideSubscription: any;

  public isIos: boolean;
  public androidNotchHeight;

  constructor(public element: ElementRef,
              public keyboard: Keyboard,
              public renderer: Renderer2,
              public platform: Platform) {
    this.isIos = this.platform.is('ios');
    this.listenKeyboard();
  }

  ngOnInit() {
    if(window['AndroidNotch']) {
      let an = window['AndroidNotch'];
      an.getInsetTop(success => this.androidNotchHeight = success);
    }
  }

  onKeyboardShow = (data) => {
    console.log('--- showing keyboard ---');
    if(!this.isIos) return;
    if(this.chat && this.isIos) return;
    let element = this.element.nativeElement;
    let height = !!this.androidNotchHeight ? data.keyboardHeight + this.androidNotchHeight : data.keyboardHeight;
    this.renderer.setStyle(element, 'bottom',  height + 'px');
  };

  onKeyboardHide = () => {
    console.log('--- hiding keyboard ---');
    if(!this.isIos) return;
    if(this.chat && this.isIos) return;
    let element = this.element.nativeElement;
    this.renderer.setStyle(element, 'bottom', '0px');
  };

  listenKeyboard() {
    window.addEventListener("keyboardWillShow", this.onKeyboardShow);
    window.addEventListener("keyboardWillHide", this.onKeyboardHide);
  }

  ngOnDestroy() {
    window.removeEventListener("keyboardWillShow", this.onKeyboardShow);
    window.removeEventListener("keyboardWillHide", this.onKeyboardHide);
  }
}
