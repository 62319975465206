import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatVideo } from './chat-video';
import {PipesModule} from "../../pipes/pipes.module";

@NgModule({
  declarations: [
    ChatVideo
  ],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [
    ChatVideo
  ]
})
export class ChatVideoModule {}
