import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { WebIntent } from '@ionic-native/web-intent/ngx';
import { Config } from '../../services/config/config';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'custom-alert',
  template: `
    <div class = "alert-container fade-in-animation" [class.fade-out-animation] = "!alertNotificationShown">
      <img class = "custom-alert-image {{customClass}} " src = "{{ image }}" alt = "Image">
      <div class = "information-container">
        <div class = "title">{{ title }}</div>
        <div class = "description">{{ description }}</div>
      </div>
     <div class="icon-control">
       <ion-icon *ngIf="redirectToSubs" name = "chevron-forward-outline"></ion-icon>
     </div>
    </div>
  `,
  styleUrls: ['./custom-alert.component.scss'],
})
export class CustomAlertComponent implements OnInit {

  public alertNotificationShown: boolean = false;
  private closeTimeout: Timeout;

  @Input('image') image: string;
  @Input('title') title: string;
  @Input('description') description: string;
  @Input('redirectToSubs') redirectToSubs: boolean;
  @Input('customClass') customClass: string;

  @Output('onClose') onClose = new EventEmitter();

  @HostListener('click', ['$event'])
  click(){
    !this.redirectToSubs ? this.close() : this.redirectToSubscriptions();
  }

  constructor( private webIntent: WebIntent, private config: Config ) { }

  ngOnInit() {
    this.closeTimeout = setTimeout(() => this.close(), 3000);
  }

  open() {
    this.alertNotificationShown = true;
  }

  close() {
    clearTimeout(this.closeTimeout);
    this.alertNotificationShown = false;
    setTimeout(() => this.onClose.emit(true), 500);
  }
  
  async redirectToSubscriptions(){
    const subscriptionSku = this.config.getSubscriptionName();
    const options = {
      action: this.webIntent.ACTION_VIEW,
      url: `https://play.google.com/store/account/subscriptions?sku=${{ subscriptionSku }}&package=co.me3app.me3`
    };

    await this.webIntent.startActivity(options);
  }
}
