import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TribeScoreComponent } from "./tribe-score.component";

@NgModule({
  exports: [TribeScoreComponent],
  imports: [
    CommonModule
  ],
  declarations: [TribeScoreComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TribeScoreModule {}