import { Injectable } from '@angular/core';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { UserService } from '../data/user.service';
import { Config } from '../config/config';
import { UtilsService } from "../utils.service";
import {  SUBSCRIPTIONS_IDS } from "../../shared/constants/store-products";
import { SessionService } from '../data/session.service';
import {Subject} from "rxjs";
import { first } from 'rxjs/operators'
import { logc } from 'src/app/shared/helpers/log';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public loading = null;
  private subscriptions = {};
  private subscriptionsIds = [];
  private initialized = false;
  public onShoppingFinished = new Subject();
  private store = null;
  private cordovaPurchase = null;

  public subscriptionIds = {
    semiannual: 'we3.plus.semiannually',
    quarterly: 'we3.plus.quarterly',
    monthly: 'we3.plus.monthly',
    weekly: 'we3.plus.weekly'
  }

  constructor(public loadingCtrl: LoadingController,
              public userService: UserService,
              public session: SessionService,
              public config: Config,
              public platform: Platform,
              public modalCtrl: ModalController,
              private utils: UtilsService){
    if(this.config.isDev || this.config.isStaging) {
      (<any> window).storeService = this;
    }
  }

  init(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      if(this.initialized) return reject();
      this.initialized = true;

      // console.log('store service is initialized: ', this.initialized);
      if(!this.platform.is('cordova')) return resolve();

      await this.platform.ready();
      this.cordovaPurchase = (<any> window).CdvPurchase;
      this.store = this.cordovaPurchase.store;

      // console.log('store service init');
      this.store.verbosity = this.store.DEBUG;
      if(this.config.isDev) {
        this.store.autoFinishTransactions = true;
        (<any> window).store = this.store;
      }

      this.setProductsIds();

      await this.registerProducts();
      await this.listenToStore();

      this.store.ready(() => {
        this.setProductsForLocalStore();
        console.log('Store is ready');
      });

      await this.store.initialize([this.getStorePlatform()]); //to initialize the plugin
      await this.store.update(); //to refresh product prices and status of purchases
      await this.store.restorePurchases(); //to restore purchases

      resolve();
    });
  }

  listenToStore() {
    // this.store.ready(status => console.log('STORE IS READY: ', status));
    // this.store.error( error => console.log( `STORE ERROR ${ error.code }: ${ error.message }`));
    this.store.when()
      .productUpdated(this.subscriptionUpdateHandler)
      .approved(this.subscriptionApprovedHandler)
  }

  subscriptionUpdateHandler = product => {
    // console.log(`--- ${product.type} purchase UPDATED: `);
    // console.log( product );
  };

  subscriptionErrorHandler = product => {
    console.log(`--- ${ product.type } purchase ERROR: `);
    console.log( product );
    this.finishLoading();
    this.utils.showGenericMessage("Try again later");
  };

  subscriptionCancelledHandler = product => {
    // If payment dialogu was cancelled, not the sub itself
    // console.log(`--- ${ product.type } purchase CANCELLED: `);
    // console.log( product );
    this.finishLoading();
  };

  subscriptionApprovedHandler = transaction => {
    console.log(`--- purchase APPROVED: `);
    console.log( transaction );
    if(transaction) {
      this.purchaseProduct(transaction);
    }
  };

  consumableUpdateHandler = product => {
    // console.log(`--- ${product.type} purchase UPDATED: `);
    // console.log( product );
  };

  consumableCancelledHandler = product => {
    // console.log(`--- ${ product.type } purchase CANCELLED: `);
    // console.log( product );
    this.finishLoading();
  };

  consumableErrorHandler = product => {
    // console.log(`--- ${ product.type } purchase ERROR: `);
    // console.log( product );
    this.utils.showGenericMessage("Try again later");
    this.finishLoading();
  };

  consumableApprovedHandler = transaction => {
    // console.log(`--- ${product.type} purchase APPROVED: `);
    // console.log( product );
    if(transaction) {
      this.purchaseProduct(transaction);
    }
  };

 getStorePlatform() {
    return this.platform.is('ios')
      ? this.cordovaPurchase.Platform.APPLE_APPSTORE
      : this.cordovaPurchase.Platform.GOOGLE_PLAY;
 }

 getPurchaseParams(transaction) {
    let params = {
      receipt: null,
      signature: null
    };

    if(this.platform.is('ios')) {
      params.receipt = transaction.parentReceipt.nativeData.appStoreReceipt;
    }

    if(this.platform.is('android')) {
      params.receipt = transaction.nativePurchase.receipt;
      params.signature = transaction.nativePurchase.signature;
    }

    return params;
  }

  async provisionPurchase(transaction) {
    await this.userService.provisionPurchase(
        this.getPurchaseParams(transaction)
      );
    transaction.finish();
  }

  async purchaseProduct(transaction) {
    try {
      console.log('purchaseproduct =>>>>>>>>>>', transaction);

      if(this.session.stateMachine.state === 'ready') {
        await this.provisionPurchase(transaction);
      } else {
        this.session.onReady
          .pipe(first())
          .subscribe(async (data) => {
            console.log('SESSION NOW READY, sending provisionPurchase');
            await this.provisionPurchase(transaction);
          });
      }
    } catch ( err ) {
      console.log( err );
    } finally {
      this.finishLoading();
      this.finishShopping();
    }
  }

  async finishShopping() {
    this.onShoppingFinished.next(true);
  }

  async registerProducts(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.subscriptionsIds.forEach(subId => this.store.register({
        id: subId,
        alias: subId,
        type: this.store.PAID_SUBSCRIPTION,
        platform: this.getStorePlatform()
      }));
      console.log("***** ALL PRODUCTS *****: ", this.store.products);
      resolve();
    })
  }

  setProductsForLocalStore(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.subscriptionsIds.forEach(subId => 
        this.subscriptions[subId] = this.store.get(subId));
      console.log('*** SUBSCRIPTIONS ***: ', this.subscriptions);
      resolve();
    })
  }

  isSubscription(pId) {
    return this.getSubscriptionIds().includes(pId)
  }

  setProductsIds() {
    this.subscriptionsIds = SUBSCRIPTIONS_IDS;
  }

  getSubscriptionIds(){
    return this.subscriptionsIds;
  }

  getTestPrices() {
   let currency = "$"
    return {
      semi: {
        full: `${ currency } ${ 200 }`,
        part: `${ currency } ${ (200 / 6).toFixed(2)}/mo`
      },
      quarter: {
        full: `${ currency } ${ 150 }`,
          part: `${ currency } ${(150 / 3 ).toFixed(2)}/mo`
      },
      month: {
        full: `${ currency } ${ 100 }/mo`
      },
      week: {
        full: `${ currency } ${ 50 }/wk`
      }
    };
  }

  getSubscriptions() {
    if(this.config.isPWA) return {};
    return this.subscriptions;
  }

  async startLoading() {
    const loading = await this.loadingCtrl.create();
    return loading.present();
  }

  async finishLoading() {
    if(!!await this.loadingCtrl.getTop()) {
      await this.loadingCtrl.dismiss();
    }
  }

  async subscribe(planName: string): Promise<void> {
    const subscriptionId = this.subscriptionIds[planName];
    logc.info("subscriptionId: ", subscriptionId);
    return new Promise(async (resolve, reject) => {
      try {
        this.utils.showLoading();
        // we can have various offers now per subscriptions
        await this.store.get(subscriptionId).offers[0].order().then( error => {
          if (error) {
            if (error.code === this.cordovaPurchase.ErrorCode.PAYMENT_CANCELLED) {
              // Purchase flow has been cancelled by user
              this.subscriptionErrorHandler(subscriptionId);
            }
            else {
              // Other type of error, check error.code and error.message
              this.subscriptionCancelledHandler(subscriptionId);
            }
            this.utils.doneLoading();
            reject();
          } else {
            this.utils.doneLoading();
            resolve(error);
          }
        });
      } catch ( err ) {
        this.utils.doneLoading();
        console.log( err );
        reject();
      }
    })
  }

  getPrice({ planId }): string {
    const pricing = this.subscriptions[planId].pricing;
    // also has pricing.currency
    return pricing.price;
  }
}
