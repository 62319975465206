import { Component, Input, OnInit } from '@angular/core';
import { logc } from 'src/app/shared/helpers/log';
import { Config } from 'src/app/services/config/config';

interface ImageElement {
  picture: string;
}
@Component({
  selector: 'image-row',
  template: `
    <div class = "votes-container">
      <div class = "votes small-text label-color">
        <span *ngIf = "title">{{ title }}</span>
        <div class = "voted-users min-w-[40px] w-max">
          <div *ngFor = "let item of data; let index = index"
               [style.z-index] = "data?.length - index"
               [style.width.px] = "diameter"
               [style.height.px] = "diameter"
               class = "relative border-[2px] border-surface rounded-[50%] overflow-hidden">
            <img class = "relative rounded-[50%] {{ cssClass }}"
                 [src] = "item?.picture || defaultAvatar" 
                 alt = "Saved user image">
            </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./image-row.component.scss'],
})
export class ImageRowComponent implements OnInit {
  @Input() diameter: number = 30;
  @Input("title") title: string = "";
  @Input("data") data: ImageElement[] = [];
  @Input("cssClass") cssClass: string = '';

  public defaultAvatar: string = null;
  constructor(private config: Config) { }

  ngOnInit() {
    this.defaultAvatar = this.config.getDefaultAvatar();
  }
}
