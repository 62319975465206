import { Injectable } from '@angular/core';

import * as Sentry from "@sentry/angular";
import {wrapLog} from "../../shared/helpers/log";
import {ConsoleService} from "./console.service";

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor(private consoleService: ConsoleService) { }

  addBreadcrumb(name, ctx) {

  }

  sendException( message, tags, extras ) {
    if(!extras.logs) {
      extras.logs = this.consoleService.getLogs();
    }
    try {
      Sentry.captureException(new Error( message ), this.setScope( tags, extras ));
    } catch ( err) {
      console.log("Sentry service sendException error: ", err );
    }
  }

  sendEvent() {
    // try {
    //   Sentry.captureEvent( 'Sentry event!' );
    // } catch ( err) {
    //   console.log("Sentry service sendException error: ", err );
    // }
  }

  sendMessage(message, tags = {}, extras: any = {}): Promise<void> {
    if(!extras.logs) {
      extras.logs = this.consoleService.getLogs();
    }
    return new Promise((resolve, reject) => {
      const msg = `Error tracking service sendMessage: ${ message }`;
      wrapLog(msg, '*');
      try {
        Sentry.captureMessage( message, this.setScope( tags, extras ));
        resolve();
      } catch ( err ) {
        console.log("Sentry service sendMessage error: ", err );
        reject(err);
      }
    })
  }

  setScope(tags, extras) {
    let scope = new Sentry.Scope();
    scope.setTags( tags );
    scope.setExtras( extras );
    return scope;
  }

}
