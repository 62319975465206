import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { MatchesModalPage } from 'src/app/pages/matches-modal/matches-modal.page';
import { SearchService } from 'src/app/services/data/search.service';
import { ModalService } from 'src/app/services/popups/modal.service';
import { UtilsService } from 'src/app/services/utils.service';
import { logc } from 'src/app/shared/helpers/log';

@Component({
  selector: 'potential-matches-short',
  template: `
    <div class = "
          flex justify-start items-center 
          px-[10px] py-[12px] border-line rounded-[10px] border-[1px]
          my-[15px] mx-[5px]"
          (click) = "openPotentialMatchesModal()"P>
      <image-row [data] = "avatars" [diameter] = "40" [cssClass] = "'blur-[3px]'"></image-row>
      <div class = "text-title text-large font-semibold whitespace-nowrap ml-[12px]">
        {{ matchesNumber }} {{ title }}
      </div>
      <ion-icon class = "text-placeholder text-larger ml-auto shrink-0" 
                [tooltip] = "'See details'"
                [tooltipOptions] = "helperTooltipOptions"
                name = "chevron-forward-outline"></ion-icon>
    </div>
  `,
  styleUrls: ['./potential-matches-short.component.scss'],
})
export class PotentialMatchesShortComponent {
  @Input() title: string = "";
  @Input() matchingType: string = "default";
  @Input() matchesNumber: number = null;
  @Input() searchData: any = {};
  @Input() avatars: any[] = [
    { picture: "./assets/img/pixel-img.png" },
    { picture: "./assets/img/default-pick-place-avatar.png" },
    { picture: "./assets/img/pixel-img.png" }
  ];

  public helperTooltipOptions = {
    initShow: true,
    showOnce: true,
    cssClass: "secondary-gradient white-color",
    delay: 2000,
    callback: () => this.openPotentialMatchesModal()
  }
  constructor(private utils: UtilsService, 
              private searchService: SearchService,
              private modalService: ModalService) {}

  async openPotentialMatchesModal() {
    const defaultInfo = { matches: [], opposite_descriptors: [] };
    const data = await this.searchService.exploreMatches(this.searchData)
    try {
      await this.utils.showLoading();
      const { matches, opposite_descriptors: oppositeDescriptors } = !isEmpty(data) ? data : defaultInfo;
      // logc.crimson("matches: ", matches);
      await this.utils.doneLoading();
      await this.modalService.create({
        component: MatchesModalPage,
        componentProps: {
          matches,
          oppositeDescriptors,
          matchesLength: this.matchesNumber,
          matchingType: this.matchingType
        },
        cssClass: "bottom-sheet-modal full-height-modal"
      });
    } catch (e) {
      logc.error(e);
      this.utils.showGenericError({});
    }

  }
}
