import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {SearchStartOptionsComponent} from "./search-start-options.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {CheckmarkButtonsListModule} from "../checkmark-buttons-list/checkmark-buttons-list.module";

@NgModule({
  declarations: [
    SearchStartOptionsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    CheckmarkButtonsListModule
  ],
  exports: [
    SearchStartOptionsComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SearchStartOptionsModule {}
