import { Injectable } from "@angular/core";
import { TribesService } from "./data/tribes.service";
import { UtilsService } from "./utils.service";
import { Config } from "./config/config";
import { ModalService } from "./popups/modal.service";
import { DispatcherService } from "./dispatcher.service";
import { RemoteConfigService } from "./remote-config.service";
import { AnalyticsService } from "./analytics/analytics.service";
import { environment } from "../../environments/environment";
import { logc } from "../shared/helpers/log";
import { ColorModeService } from "./native/color-mode.service";
import { RouteTrackerService } from "./route-tracker.service";
import { filter, tap } from "rxjs/operators";
import { PopupService } from "./popups/popup.service";

@Injectable({
  providedIn: "root",
})
export class HeartfeltMessagesService {
  private analyticsEvent: any = {};

  constructor(
    private tribesService: TribesService,
    private dispatcherService: DispatcherService,
    private utils: UtilsService,
    private colorModeService: ColorModeService,
    private config: Config,
    private popupService: PopupService,
    private routeTrackerService: RouteTrackerService,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private remoteConfigService: RemoteConfigService
  ) {
    if (this.config.isDev || this.config.isStaging) {
      (window as any).heartfeltService = this;
    }
  }

  async init() {
    logc.orange("** Heartfelt messages service init **");
    await this.remoteConfigService.load();
    const inHeartfeltExperiment = await this.remoteConfigService.getBoolean(
      "heartfelt_experiment"
    );

    this.analyticsEvent = {
      key: "heartfelt_messages_experiment",
      value: 1,
      variant: inHeartfeltExperiment ? "heartfelt_message" : "control",
    };

    this.tribesService.onTribeLeft.subscribe((tribe) => {
      this.config.callOnce("heartfeltExperimentStarted", () => {
        this.analyticsService.trackEvent(this.analyticsEvent);
      });

      if (!this.config.getFlag("groupFailedBefore")) {
        if (inHeartfeltExperiment) {
          setTimeout(() => {
            // this.modalService.openHeartfeltModal({
            //   state: this.getState({ state: "groupFailed" }),
            // });
            this.modalService.openVideoModal();
          }, 1000);
        }
      }
    });

    this.tribesService.onTribesReady.subscribe((tribes) => {
      const expiredTribe = tribes.find((tribe) => tribe.status == "expired");
      if (expiredTribe) {
        this.config.callOnce("heartfeltExperimentStarted", () => {
          this.analyticsService.trackEvent(this.analyticsEvent);
        });

        if (!this.config.getFlag("groupFailedBefore")) {
          if (inHeartfeltExperiment) {
            this.dispatcherService.addClientEvent({
              name: `chat_${expiredTribe.id}_expired`,
              data: {
                action: () => {
                  // this.modalService.openHeartfeltModal({
                  //   state: this.getState({ state: "groupFailed" }),
                  // });     
                  this.modalService.openVideoModal();

                  this.config.setFlag("groupFailedBefore", true);
                },
              },
            });
          } else {
            this.config.setFlag("groupFailedBefore", true);
          }
        }
      }
    });

    this.tribesService.onTribeStarted.subscribe((tribe) => {
      this.config.callOnce("heartfeltExperimentStarted", () => {
        this.analyticsService.trackEvent(this.analyticsEvent);
      });

      if (!this.config.getFlag("isFirstTribeCreated")) {
        if (inHeartfeltExperiment) {
          this.dispatcherService.addClientEvent({
            name: `chat_${tribe.id}_first_opened`,
            data: {
              tribeId: tribe.id,
              action: async () => {
                const state = this.getState({
                  state: "groupStarted",
                  userName: this.config.getProfile().firstName,
                });
                this.modalService.openHeartfeltModal({
                  state,
                  callback: () =>
                    setTimeout(
                      () =>
                        this.popupService.createPopup({
                          popupName: "richProfilePopup",
                        }),
                      1000
                    ),
                });
              },
            },
          });
        }
      }
    });
  }

  showPopup(state, options) {
    this.modalService.openHeartfeltModal({
      state: this.getState({ state, ...options }),
    });
  }

  getState(options) {
    return {
      groupStarted: {
        title: `You rock, ${options.userName}! 🤘`,
        description: [
          "Putting yourself out there like you just did can be a bit scary.",
          "<div class = 'underlined bold'>And you still did it.</div>",
          "But the process of making genuine new friends won't be easy. There will be many disappointing moments along the way.",
          "Don’t let those setbacks discourage you!",
          "I wish you the best of luck on this journey.",
        ],
        author: {
          name: "Julian",
          position: "Founder of We3",
        },
        signatureImage: this.utils.getSignatureImagePath("julian"),
        ps: "",
        callback: () =>
          this.dispatcherService.firstTribeCreatedSource.next(true),
      },
      groupFailed: {
        title: `Looks like that group didn't work out, eh? 😬`,
        description: [
          "Check out how our founder uses We3 to get responsive groups.",
        ],
        author: {
          name: "Julian",
          position: "Founder of We3",
        },
        signatureImage: this.utils.getSignatureImagePath("julian"),
        ps: "PS: Just so you know, people who miss their groups twice in a row are blocked from matching.",
      },
      dontSkip: {
        title: `Don’t skip this part, ${options?.userName}! 🙏`,
        description: [
          "The worst mistake you can make on We3 is to throw up an empty profile and shop around.",
          "You’ll burn through your best matches because <span class = 'bold underlined'>they think it looks lazy</span>",
          "So take a moment to fill it out, and keep it positive!",
          "I promise it makes a huge difference.",
        ],
        author: {
          name: "Julian",
          position: "Founder of We3",
        },
        signatureImage: this.utils.getSignatureImagePath("julian"),
      },
    }[options.state];
  }
}
