import {Component, Injectable} from '@angular/core';

@Component({
  selector: 'chat-link',
  template: `<a (click)="click()">{{url}}</a>`,
})
export class ChatLink {
  public src: string = "";
  public url: string = "";

  constructor(){ }
  public updateData(data: any){
    this.src = data.src;
    this.url = data.url;
  }

  click(){
    (<any> window).open(this.url, '_system');
  }
}

