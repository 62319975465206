import { Component, NgZone, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Config } from "../../services/config/config";
import { DispatcherService } from "../../services/dispatcher.service";
import { takeUntil } from "rxjs/operators";
import { merge, Subject } from "rxjs";
import { UserService } from "../../services/data/user.service";
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
} from "@ionic/angular";
import { UtilsService } from "../../services/utils.service";
import { ApiService } from "../../services/data/api.service";
import { SessionService } from "../../services/data/session.service";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { StatementsService } from "../../services/data/statements.service";
import { GenderPipe } from "../../pipes/gender.pipe";
import { ContactsService } from "../../services/native/contacts.service";
import { MAX_RANGE } from "../../shared/constants/constants";
import { ColorModeService } from "../../services/native/color-mode.service";
import { RemoteConfigService } from "../../services/remote-config.service";
import { getReferralInvitationRewardDescription } from "./helpers";
import { ReferralRewards } from "../../shared/enums/referral-flags.enum";
import { MatchingStatus } from "../../shared/interfaces";
import { UserMatchingStatus } from "../../shared/enums/user.enums";
import { environment } from "../../../environments/environment";
import { getNumberVerificationPageStateFromPhoneStatus } from "../../shared/helpers/helpers";
import { ModalService } from "src/app/services/popups/modal.service";
import { UpsellingPoppingReason } from "src/app/pages/upselling/upselling.page";
import { logc } from "src/app/shared/helpers/log";
import { IpService } from "src/app/services/ip.service";

@Component({
  selector: "side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  @ViewChild("hint") hint;

  public isDarkMode: boolean = false;

  private DRINKS_EXPERIMENT_FLAGS = [
    ReferralRewards.GiveDrinks2Friends,
    ReferralRewards.GiveDrinksUnlimited,
  ];

  // user data
  public sideMenuTipShown: boolean;
  public contactsBlocked: boolean;
  public wasInPool: boolean;
  public userImage: string;
  public matchingStatus: MatchingStatus = UserMatchingStatus.Closed;
  public userName: string;
  public currentLevel: number;
  public currentLevelStarted: boolean;
  public levelsFinished: boolean = false;
  public phoneNumber: string;
  public phoneNumberStatus: string;
  public emailStatus: string;
  public nextLevel: string = "";
  public matchPreferences = {
    gender: null,
    location: "",
    age_range: {
      lower: null,
      upper: null,
    },
  };

  public accountOptions = [];
  public settingsOptions = [];
  public helpOptions = [];

  private unsubscribe = new Subject();

  private currentCountryCode: string | null = null;
  private availableCountries: string[] = ["US", "CA"];

  constructor(
    private config: Config,
    private dispatcherService: DispatcherService,
    private userService: UserService,
    private ngZone: NgZone,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private utils: UtilsService,
    private menu: MenuController,
    private api: ApiService,
    private modalService: ModalService,
    private colorModeService: ColorModeService,
    public sessionService: SessionService,
    private loadingCtrl: LoadingController,
    private analyticsService: AnalyticsService,
    private statementsService: StatementsService,
    private genderPipe: GenderPipe,
    private ipService: IpService
  ) {
    this.ipService.onIpInfoFetched.subscribe(({ countrycode }) => {
      logc.red("SIDE MENU countrycode", countrycode);
      this.currentCountryCode = countrycode;
      this.setOptions();
    });
  }

  async ngOnInit() {
    await this.config.load();

    this.dispatcherService.onMatchingStatusChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((status: MatchingStatus) => {
        this.ngZone.run(() => {
          this.matchingStatus = status;
          if([
               UserMatchingStatus.Available,
               UserMatchingStatus.Opened,
             ].includes(status)) {
            this.wasInPool = true;
          }
        });
      });

    this.dispatcherService.onContactsBlocked
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((status) => {
        console.log("SIDE MENU CONTACTS STATUS: ", status);
        this.ngZone.run(async () => {
          this.contactsBlocked = status;
        });
      });

    merge(
      this.colorModeService.onModeChanged,
      this.config.onFlagsChanged,
      this.config.onProfileChanged,
      this.dispatcherService.onContactsBlocked,
      this.dispatcherService.onMatchingStatusChanged,
      this.config.onPicturedCached
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        // this.ngZone.run(async () => {
        this.setData();
        // });
      });

    await this.setData();
  }

  async setData() {
    return new Promise(async (resolve, reject) => {
      this.isDarkMode = this.colorModeService.isDark();
      this.matchPreferences = this.config.get("matchPreferences");
      this.sideMenuTipShown = this.config.getFlag("sideMenuTipShown");
      this.userName = this.config.get("firstName") || "Your Name";
      this.contactsBlocked = this.config.getFlag("contactsBlocked");

      let enteredPoolCount = this.config.getFlag("entered_pool_count");
      this.wasInPool = enteredPoolCount && enteredPoolCount > 0;

      this.setAvatar();
      this.checkLevelsCompletion();
      this.setOptions();
      this.setLevelInfo();
      this.getNextLevel();
    });
  }

  async openLevel() {
    this.menu.close();
    this.sessionService.setUserId(this.config.get("id"));
    this.analyticsService.trackEvent({
      key: "levels_action_button_click",
      value: 1,
      user_id_analytics: this.analyticsService.userId,
      user_id_in_config: this.config.get("id"),
      environment_name: environment.name,
      isPWA: this.config.isPWA,
    });

    if (!this.currentLevelStarted) {
      await this.statementsService.goToNextLevel();
    }
    await this.navCtrl.navigateForward("levels/current");
  }

  hideHint() {
    if (!this.sideMenuTipShown) {
      this.sideMenuTipShown = true;
      this.config.setFlag("sideMenuTipShown", true);
    }
  }

  async openPage(page: string) {
    if (page === "status_page") {
      this.hideHint();
    }
    this.menu.close();
    return this.navCtrl.navigateForward(page);
  }

  async setOptions() {
    const profile = this.config.getProfile();
    const {
      email,
      status: emailStatus,
      phoneNumber,
      phoneNumberStatus,
    } = profile;

    this.accountOptions = [
      {
        icon: "mail-outline",
        title: `${!!email ? email : "Add a recovery email"}`,
        description: `${
          !!email ? profile.status : "In case you get locked out."
        }`,
        warning: !email || emailStatus === "unconfirmed",
        cssClass: `email ${!!email ? profile.status : ""}`,
        action: () =>
          this.navigate("sign-up-flow", {
            state: { setup: ["email"], backPath: "tabs/tribes" },
          }),
      },
      {
        icon: "trophy-outline",
        title: "We3 Plus",
        description: `${
          this.config.isPlus() ? "Manage account" : "See all features."
        }`,
        warning: false,
        cssClass: `we3-plus`,
        action: () => this.openPlus(),
      },
    ];
    // if (
    //   !this.currentCountryCode ||
    //   this.availableCountries.includes(this.currentCountryCode)
    // ) {
    
    if(this.wasInPool) {
      this.accountOptions.unshift({
        id: "verify-account",
        icon: "call-outline",
        title: `${phoneNumber || "Verify your phone"}`,
        description: `${phoneNumberStatus || "Secure your account."}`,
        warning: !phoneNumberStatus,
        cssClass: `phone ${phoneNumberStatus || "unverified"}`,
        action: () =>
          this.navigate(
            `number-verification/${getNumberVerificationPageStateFromPhoneStatus(
              phoneNumberStatus
            )}`,
            { state: { backPath: "tabs/tribes" } }
          ),
      });
    }

    if (this.config.getFlag("request_for_referral")) {
      this.accountOptions.push({
        icon: "person-add-outline",
        title: "Invite a friend",
        description: getReferralInvitationRewardDescription(
          this.config.getFlag("request_for_referral")
        ),
        warning: false,
        action: () => {
          this.navigate("referral-invitation");
        },
      });
    }
    if (
      this.config.get("referrer") &&
      this.DRINKS_EXPERIMENT_FLAGS.includes(
        this.config.get("referrer")?.variant
      )
    ) {
      this.accountOptions.push({
        icon: "local-drink",
        title: "Claim your drink",
        description: "At your first group meetup",
        warning: false,
        action: () => {
          this.navigate("claim-referral-reward", {
            state: { referrer: this.config.get("referrer") },
          });
        },
      });
    }

    if (this.config.getFlag("smsRestricted")) {
      this.accountOptions.splice(0, 1);
    }

    this.settingsOptions = [
      {
        icon: "moon-outline",
        title: "Night mode",
        description: this.isDarkMode ? "On" : "Off",
        action: () => this.openPage("change-color-mode"),
      },
      {
        icon: "options-outline",
        title: "Filters",
        description: `${this.getGender()} • ${this.getRadius()} • ${this.getAge(
          "lower"
        )}-${this.getAge("upper")}`,
        action: () => this.navigate("preferences"),
      },
      {
        icon: "notifications-outline",
        title: "Notifications",
        description: "Manage",
        action: () => this.navigate("notifications"),
      },

      {
        icon: "refresh-outline",
        title: "Restart levels",
        description: "Redo the levels",
        action: () => this.restartLevels(),
      },
    ];

    if (!this.config.isPWA) {
      this.settingsOptions.splice(2, 0, {
        icon: "hand-right-outline",
        title: "Blocked users",
        description: this.contactsBlocked
          ? "<span class = 'success-color'>My Contacts</span>"
          : "Hide your contacts",
        action: () => this.openPage("blocked-users"),
      });
    }

    this.helpOptions = [
      {
        icon: "help-buoy",
        title: "Help & Support",
        action: () => this.navigate("help"),
      },
    ];
  }

  getGender() {
    return (
      this.genderPipe.transform(this.matchPreferences.gender, true) || "Anyone"
    );
  }

  getRadius() {
    let radius = this.config.get("radius");
    return radius >= MAX_RANGE ? "Global" : `${radius?.toFixed()}km`;
  }

  getAge(limit: string) {
    return this.matchPreferences.age_range
      ? this.matchPreferences.age_range[limit]
      : this.utils.getDefaultAgeRange(limit);
  }

  setAvatar() {
    this.userImage =
      this.config.getPicture() || this.config.get("cachedPicture");
  }

  setPhoneNumber() {
    this.phoneNumber = this.config.get("phoneNumber");
    this.phoneNumberStatus = this.config.get("phoneNumberStatus");
  }

  setLevelInfo() {
    this.currentLevel = this.config.getCurrentLevel();
    this.currentLevelStarted = this.config.get("startedCurrentLevel");
  }

  checkLevelsCompletion() {
    this.levelsFinished = this.config.finishedLevel(5);
  }

  openPlus() {
    this.config.isPlus()
      ? this.navigate("subscription_plan")
      : this.openUpselling();
  }

  async openUpselling() {
    this.modalService.openUpselling({
      source: "side-menu",
      reason: UpsellingPoppingReason.SeeAllMatches,
    });
  }

  navigate(page, options?) {
    this.menu.close();
    this.navCtrl.navigateForward(page, options);
  }

  getNextLevel() {
    this.nextLevel = this.currentLevelStarted ? "Continue " : "Start ";
    this.nextLevel += this.config.finishedLevel(5)
      ? "Bonus level"
      : `level ${
          this.currentLevelStarted ? this.currentLevel : this.currentLevel + 1
        }`;
  }

  hasReward() {
    return (
      ([3, 4].includes(this.currentLevel) && this.currentLevelStarted) ||
      ([2, 3].includes(this.currentLevel) && !this.currentLevelStarted)
    );
  }

  showLevels() {
    return (
      !this.levelsFinished ||
      (this.config.getFlag("bonusLevelUnlocked") &&
        !this.config.get("bonusLevelDone"))
    );
  }

  async restartLevels() {
    const alert = await this.alertCtrl.create({
      header: "Reset existing answers?",
      message:
        "You won't lose your groups, but you will be starting over at level 1. This action cannot be reversed.",
      cssClass: "reset-level",
      buttons: [
        {
          text: "CANCEL",
          role: "cancel",
        },
        {
          text: "RESET",
          handler: () => {
            this.utils
              .showLoading("Reseting levels")
              .then((): Promise<void> => {
                return new Promise((resolve, reject) => {
                  this.userService
                    .resetLevels()
                    .then(
                      async (res) => {
                        await this.menu.close();
                        await this.config.updateProfile({
                          currentLevel: 1,
                          startedCurrentLevel: true,
                        });
                        await this.config.setFlag("bonusLevelUnlocked", false);
                        await this.userService.updateProfile({
                          startedLevel: true,
                          bonusLevelDone: false,
                        });
                        await this.navCtrl.navigateForward("/levels/current");

                        resolve();
                      },
                      (err) => {
                        console.log(err);
                        this.utils.errorContext =
                          "side menu, error: " + JSON.stringify(err);
                        this.utils.showGenericError({
                          key: "side_menu_reset_levels",
                        });
                        reject(err);
                      }
                    )
                    .finally(() => {
                      this.utils.doneLoading();
                    });
                });
              });
          },
        },
      ],
    });
    return alert.present();
  }

  async openLogoutMenu() {
    const alert = await this.alertCtrl.create({
      header: "Log Out of We3?",
      buttons: [
        { text: "Cancel", role: "cancel" },
        { text: "Log Out", handler: () => this.logout() },
      ],
    });
    await alert.present();
  }

  async logout() {
    await this.menu.close();
    const loading = await this.loadingCtrl.create({
      message: "Loging out...",
      duration: 5000,
    });
    await loading.present();
    await this.sessionService.logout();
    console.log("dismissing Loging out...");
    await loading.dismiss();
  }

  async deleteAccount() {
    if (this.api.isOnline) {
      await this.menu.close();
      this.navCtrl.navigateForward("/delete_my_account");
    } else {
      this.utils.showGenericError({
        msg: "Please connect to the Internet first, so that we can fully delete your account.",
        key: "side_menu_delete_account",
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
