import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { logc } from 'src/app/shared/helpers/log';
import { SessionService } from '../../services/data/session.service';
import { UtilsService } from "../../services/utils.service";

export interface NotificationBadgeInterface {
  icon: { 
    name: string; 
    cssClass?: string; 
  };
  image?: string;
  body: {
    desc: string;
    title?: string;
    learnMore?: boolean;
  };
  callback?: () => {};
}

@Component({
  selector: 'notification-badge',
  template: `
  <div #whitelist class = "notification-section {{ cssClass }}">
    <div class = "close-button" (click) = "close($event)">
      <ion-icon *ngIf = "showCloseButton" name = "close"></ion-icon>
    </div>
    <div *ngIf = "content?.icon"
         class = "icon-section mt-[10px] self-start"
         (click) = "content?.callback()">
      <div class="icon-background">
        <ion-icon class = "notification-badge-icon {{ content?.icon?.cssClass }}"
                  name = "{{ content?.icon?.name }}">
        </ion-icon>
      </div>
    </div>
    <div *ngIf = "content?.image" class = "notification-section-image" (click) = "content?.callback()">
      <img [src] = "content?.image" alt = "Badge image">
    </div>
    <div class = "text-section" (click) = "content?.callback()">
      <div class = "notification-title text-base text-title font-semibold" [innerHTML] = "content?.body.title"></div>
      <div class = "notificadtion-desc text-small text-body">
        <div [innerHTML] = "content?.body.desc"></div>
        <span *ngIf = "content?.body.learnMore" class = "learn-more">Learn More</span>
      </div>
      <div *ngIf = "buttons?.length" class = "flex justify-end gap-[20px] mt-[20px] mb-[10px]">
        <div *ngFor = "let button of buttons" class = "text-base {{ button?.cssClass }}" (click) = "button?.handler()">
          {{ button?.text }}
        </div>
      </div>
      <div *ngIf = "content?.buttons?.length" class = "flex justify-end gap-[20px] mt-[20px] mb-[10px]">
        <div *ngFor = "let button of content?.buttons" class = "text-base {{ button?.cssClass }}" (click) = "button?.handler()">
          {{ button?.text }}
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./notification-badge.component.scss'],
})
export class NotificationBadgeComponent {
  @Output() onClose = new EventEmitter();
  @ViewChild('whitelist') whitelist;

  @Input('showCloseButton') showCloseButton: boolean = false;
  @Input('content') content: any;
  @Input("cssClass") cssClass: string = '';
  @Input() buttons: any[] = [];

  constructor(
    private session: SessionService,
    private utils: UtilsService,
    private renderer: Renderer2
  ) {}

  isAppLatestVersion() {
    return this.session.isAppLatestVersion()
  }

  close(ev) {
    this.renderer.setStyle(this.whitelist.nativeElement, 'display', 'none');
    this.onClose.emit();
  }

}
