import { ErrorHandler, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CrashReportService } from './crash-report.service';
import { Config } from '../config/config';
import { environment } from '../../../environments/environment';
import * as Sentry from "@sentry/angular";
import {wrapLog} from "../../shared/helpers/log";
import {SessionService} from "../data/session.service";
var StackTrace = require('stacktrace-js');

@Injectable({
  providedIn: 'root',
})
export class MainErrorHandlerService implements ErrorHandler {
  public sentry: any;

  constructor(public platform: Platform, 
              public config: Config,
              public crashReportService: CrashReportService) {
    this.sentry = Sentry.createErrorHandler();
  }

  handleError(error) {
    if(!this.config.isAppLatestVersion) return;

    StackTrace.fromError(error).then((stackframes:any) => {
      var str = stackframes.splice(0, 5)
      console.log(`Unhandled error intercepted. ${error.message}`, str);
    });

    if(environment.name == 'development') {
      return;
    } else {
      this.sentry.handleError(error);
      this.crashReportService.logException(error);
    }
  }
}
