import {MatchingStrategy} from "./matching-strategies/matching-strategy";
import {PartnerMatchingStrategy} from "./matching-strategies/partner-matching-strategy";
import {DefaultMatchingStrategy} from "./matching-strategies/default-matching-strategy";
import {Injectable, Injector} from "@angular/core";
import {Config} from "../config/config";
import {ScheduledMatchingStrategy} from "./matching-strategies/scheduled-matching-strategy";
import { FansMatchingStrategy } from "./matching-strategies/fan-matching-strategy";

@Injectable({
  providedIn: "any"
})
export class MatchingStrategyFactory {
  constructor(private config: Config,
              private injector: Injector) {
  }

  getMatchingStrategy(): MatchingStrategy {
    return this.injector.get<MatchingStrategy>(this.createStrategy());
  }

  private createStrategy(): any {
    switch(true) {
      case this.config.get("partner")?.partner_type == 'influencer':
        return FansMatchingStrategy;
      default:
        return DefaultMatchingStrategy;
    }
  }
}
