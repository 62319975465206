import { AbstractControl } from "@angular/forms";
 
export function emailValidator(control?: AbstractControl) {
  let emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let emailRegexpShort = /\S+@\S+\.\S+/;

  let val = control && control.value;
  if (val && !emailRegexpShort.test(val)) {
    return {invalidEmail: true};
  }
}
