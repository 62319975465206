<div id = "radar-chart-container" #radarContainer
     [class.loading] = "!dispalyedData"
     [class.fade-in-fwd] = "dispalyedData">
  <div *ngIf = "!dispalyedData">
      <loading ></loading>
  </div>
  <div *ngIf = "dispalyedData && showTip"
       class = "tip-container" (click) = "showLegend()">
    <div class = "outside-tip pulsate-fwd"></div>
    <ion-icon name = "touch-finger" class = "pulsate-fwd"></ion-icon>
    <p *ngIf = "!isCurrentUserProfile">Tap to compare</p>
    <p *ngIf = "isCurrentUserProfile">Tap to see legend</p>
  </div>
</div>
