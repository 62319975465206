import {Component, NgZone, ViewChild} from '@angular/core';
import {
  Platform,
  ModalController,
  LoadingController,
  NavController,
  IonRouterOutlet,
  PopoverController, ToastController
} from '@ionic/angular';

import { first } from 'rxjs/operators'

import "./shared/lib/array/array-methods";
import "./shared/lib/string/string-methods";
import './shared/lib/number/number-methods';
import "./shared/decorators/executed";

import { Config } from './services/config/config';
import { environment } from '../environments/environment';
import { ApiService } from './services/data/api.service';
import { AppService } from './services/app';
import { SessionService } from './services/data/session.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { TribesService } from './services/data/tribes.service';
import { TribeService } from './services/data/tribe.service';
import { SearchService } from './services/data/search.service';
import { ProfilePictureService } from './services/profile-picture.service';

import { UserService } from './services/data/user.service';
import { CrashReportService } from './services/performance/crash-report.service';
import { UtilsService } from './services/utils.service';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BranchIo } from '@ionic-native/branch-io/ngx';
import { InstagramService } from './services/instagram.service';
import { RouteTrackerService } from "./services/route-tracker.service";
import { DispatcherService } from "./services/dispatcher.service";
import {StoreService} from "./services/native/store.service";
import {ConsoleService} from "./services/performance/console.service";
import {PerformanceService} from "./services/performance/performance.service";
import {ModalService} from "./services/popups/modal.service";
import {PopupService} from "./services/popups/popup.service";
import {ContactsService} from "./services/native/contacts.service";
import {ColorModeService} from "./services/native/color-mode.service";
import {KeyboardService} from "./services/native/keyboard.service";
import {SentryService} from "./services/performance/sentry.service";
import {BRANCH_RETRY_TIMEOUT} from "./shared/constants/constants";
import {BranchErrors} from "./shared/enums/branch-errors.enum";
import {log, logc} from "./shared/helpers/log";
import sizeof from "object-sizeof";
import {DailyTribesService} from "./services/daily-tribes.service";
import {BRANCH_ERROR_PROCESSING_TIMEOUT} from "./shared/constants/branch";
import * as Sentry from "@sentry/angular";
import packageJson from "../../package.json";
import {Integrations} from "@sentry/tracing";
import {ExtraErrorData} from "@sentry/integrations";
import {RemoteConfigService} from "./services/remote-config.service";
import {HeartfeltMessagesService} from "./services/heartfelt-messages.service";
import {AudioService} from "./services/audio.service";
import {TestService} from "./services/test.service";
import {PictureRejectionReasons} from "./pages/picture/cannot_detect_face/cannot_detect_face.page";
import {values} from "./shared/helpers/helpers";
import {WordingService} from "./services/wording.service";
import {ReportService} from "./services/report.service";
import {InstructionsService} from "./services/instructions.service";
import { MeetupService } from './services/meetup.service';
import { ActivitiesService } from './services/activities.service';
import { TopicsService } from './services/topics.service';
import { CalendarService } from './services/calendar.service';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { PushNotification } from './services/push-notification/push-notification';
import { AlertService } from './services/popups/alert.service';
import { ChatService } from './services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class We3App {
  @ViewChild(IonRouterOutlet, { static: true }) ionRouterOutlet: IonRouterOutlet;

  public isDev: boolean = false;
  public isStaging: boolean = false;
  public branchInitAttempts: number = 0;

  private deeplinksCallbacks = {
    show_fan_details_ask_pushes: () => setTimeout(() => {
      this.searchService.openFanSearchDetails();
      // this.alertService.openPushAlert();
      this.push.requestPushPermissionAndInit();
      this.config.isExternalLinkSignIn = false;
    }, 1000),
    show_fan_details: () => {
      this.searchService.openFanSearchDetails();
      this.config.isExternalLinkSignIn = false;
    },
    undefined: () => {}
  }

  constructor(public platform: Platform,
              public config: Config,
              public api: ApiService,
              private alertService: AlertService,
              private popoverCtrl: PopoverController,
              public tribesService: TribesService,
              public tribeService: TribeService,
              public modalCtrl: ModalController,
              public loadingCtrl: LoadingController,
              public sessionService: SessionService,
              public userService: UserService,
              public searchService: SearchService,
              private profilePictureService: ProfilePictureService,
              public analyticsService: AnalyticsService,
              public ngZone: NgZone,
              private push: PushNotification,
              private storeService: StoreService,
              public utils: UtilsService,
              private statusBar: StatusBar,
              private splashScreen: SplashScreen,
              private branch: BranchIo,
              public instagramService: InstagramService,
              public appService: AppService,
              private navCtrl: NavController,
              private crashReportService: CrashReportService,
              private routeTrackerService: RouteTrackerService,
              private dispatcherService: DispatcherService,
              private consoleService: ConsoleService,
              private modalService: ModalService,
              private popupService: PopupService,
              private toastCtrl: ToastController,
              private colorModeService: ColorModeService,
              private contactsService: ContactsService,
              private performanceService: PerformanceService,
              private keyboardService: KeyboardService,
              private errorTrackingService: SentryService,
              private dailyTribesService: DailyTribesService,
              private remoteConfigService: RemoteConfigService,
              private audioService: AudioService,
              private heartfeltMessagesService: HeartfeltMessagesService,
              private testsService: TestService,
              private meetupService: MeetupService,
              private reportService: ReportService,
              private instructionsService: InstructionsService,
              private wordingService: WordingService,
              private topicsService: TopicsService,
              private chatService: ChatService,
              private calendarService: CalendarService) {
    if(environment.name === 'development') {
      // (<any> window).debug(true);
    }

    if(this.config.isPWA) {
      window.addEventListener('popstate', async event => {
        if (await this.modalCtrl.getTop()) {
          this.modalCtrl.dismiss();
        }
      });
    }

    this.routeTrackerService.init();
    this.modalService.init();
    this.popupService.init();
    this.keyboardService.init();

    this.isDev = this.config.isDev;
    this.isStaging = this.config.isStaging;

    this.platform.ready().then(async _ => {
      // try {
        await this.initSentry();
      // } catch(e) {
      //   logc.error("Error initializing sentry: ", e);
      // }

      await this.onReady();
      await this.showABTestsInfo();
      await this.heartfeltMessagesService.init();

      // (<any> window).codePushChecker = this.codePush;
      if(this.isDev || this.config.isStaging) {
        (<any> window).crashReportService = this.crashReportService;
        (<any> window).userService = this.userService;
        (<any> window).app = this;
        (<any> window).sizeOf = sizeof;
      }

      if(window['AndroidNotch']) {
        const an = window['AndroidNotch'];
        an.getInsetTop( r => {
          this.config.safeTop = `${r}px`
        });
      }
    });

    this.sessionService.onBeforeLogout.subscribe( _=>{
      this.analyticsService.trackEvent({ key: 'logout', value: 1 });
    });

    this.sessionService.onLogin.subscribe( _ =>{
      this.analyticsService.trackEvent({ key: 'login', value: 1 });
    });

    this.sessionService.onLogout.subscribe( data => {
      if(data.kickedOut) {
        this.utils.showGenericError({
          msg: "Your session has expired, please login.",
          key: 'kicked_out'
        });
      }

      this.config.cleanupAccountConfigs();

      this.navCtrl.navigateRoot('/front_door?source=logout');
    });

    document.addEventListener('ionBackButton', e => {
      this.dispatcherService.hardwareBackButtonTap.next(e);
    });

    this.config.onGenderMissrepresented.subscribe( res => {});

    this.sessionService.onPictureRejected.subscribe( res => {
      this.config.updateProfile({ banned: res.banned });
      this.profilePictureService.currentData = res.picture;
      this.analyticsService.trackEvent({
        key: "pic_rejected",
        value: 1,
        source: 'manual',
        reason: res.reason
      })
      if(values(PictureRejectionReasons).includes(res.reason)) {
        this.navCtrl.navigateForward(`/picture/cannot_detect_face/${ res.reason }`);
      } else if(res.reason === 'gender' && this.config.getFlag('opening_is_done')) {
        this.navCtrl.navigateForward('/picture/gender-missrepresentation');
      }
    });

    this.sessionService.onDeleteAccount.subscribe( res => {
      this.config.cleanupAccountConfigs();
      this.analyticsService.cleanup(this.config);
      this.navCtrl.navigateRoot('/account_deleted');
    });

    this.tribeService.onTribeError.subscribe( res => {
      if(res.type == "load"){
        setTimeout( _ => {
          this.utils.showGenericMessage("We could not locate this Tribe. Sorry.");
        }, 3000);
      }
    });

    this.tribesService.onTribeFailed.subscribe( tribe => {
      if(tribe.is_initiator) {
        this.navCtrl.navigateForward('/pending-status/initiator-failed');
      } else {
        this.navCtrl.navigateForward('/pending-status/failed');
      }
    });

    platform.resume.subscribe( _ => {
      console.log('INITIALIZING BRANCH');
      this.initUri();
    });
  }

  async initSentry() {
    let errorsToIgnore: any;
    let remoteConfigErrorsToIgnore: any = [];
    const defaultSentryError = ["/^AbortError: The operation was aborted.%/"];

    try {
      await this.remoteConfigService.load();
      let sentryString = await this.remoteConfigService.getString("sentry_errors_to_ignore");
      if(sentryString) {
        sentryString
          .split(',')
          .map(e => e.trim())
          .forEach(e => remoteConfigErrorsToIgnore.push(e))
      }
    } catch (e) {
      logc.error("-- Remote config error while initializaing Sentry...", e);
    } finally {
      errorsToIgnore = remoteConfigErrorsToIgnore.length ? remoteConfigErrorsToIgnore : defaultSentryError;

      logc.info("-- Initializing Sentry --");
      logc.info("Ignoring errors: ", errorsToIgnore);
      Sentry.init({
        dsn: "https://c3372eac354e4c31ad9d086dc954de05@o559248.ingest.sentry.io/5693810",
        environment: environment.name,
        release: `we3@${ packageJson.version }`,
        ignoreErrors: errorsToIgnore,
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ["localhost", "http://localhost:3000"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
          new ExtraErrorData({ depth: 10 }) as any
        ],
        normalizeDepth: 10,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
      });
    }
  }

  async onReady() {
    await this.analyticsService.load();
    await this.consoleService.init();
    await this.config.load();

    this.contactsService.init();

    this.utils.setDefaultStatusBar();

    this.initUri();

    if(this.platform.is('cordova')) {
      this.splashScreen.hide();
      this.storeService.init();
    }
    this.appService.appReady();
  }

  showABTestsInfo() {
    this.testsService.showABTestsInfo();
  }

  async sendLogs() {
    try {
      await this.userService.sendFeedback("Logs", 'development');
      this.utils.showGenericMessage("Logs are sent!");
    } catch (e) {
      console.log(e);
      this.utils.showGenericError({
        msg: "Something went wrong, try again.",
        key: "sending_logs"
      });
    }
  }

  connectToInstagram(code){
    this.instagramService.getAccessToken(code)
    .then(
      res => {
        this.config.updateProfile({
          instagramToken: res
        });
        this.analyticsService.trackEvent({ key: 'instagram_set', value: 1 });
      }
    ).catch(
      err => {
        console.log(err);
        this.analyticsService.trackEvent({ 
          key: 'connect_instagram_error', 
          value: 1, 
          ctx: JSON.stringify(err), 
          step: 'branch_link_issue',
        });
      }
    );
  }

  //const redirect = data['$deeplink_path'] || search.get('r');
  //const path = this.processUriData(link, redirect);

  async processUriData(uri, redirect) {
    //let path = url.pathname;
    console.log('processing uri data', uri);
    const url = (new URL(uri));
    let path = url.pathname;
    const search = url.searchParams;
    if(!redirect) {
      redirect = search.get('r');
    }

    const callback = search.get("callback");
    const pwa_token = search.get('pwa_token');
    const code = search.get('code');
    const pcode = search.get('pcode');
    let instagram_login = path.includes("/instagram/auth");

    // logc.crismon("Callback name: ", callback);

    if(instagram_login) {
      if(code) {
        this.sessionService.connectingInstagram = false;
        //Get instagram code from deeplink
        if(environment.name != 'production') {
          this.utils.showGenericMessage(`Deeplink instagram code: ${code}`);
        }
        this.analyticsService.trackEvent({ 
          key: 'instagram_redirect', 
          value: 1, 
          step: 'branch_link',
        });
        path = `instagram/auth?code=${code}`
      } else {
        this.analyticsService.trackEvent({ 
          key: 'instagram_redirect', 
          value: 1, 
          step: 'user_declined_permission',
        });
        path = null;
        //Clear the path if we don't have a code, the user declined the permissions
      }
    }
    
    if(pwa_token && !this.api.hasToken()) {
      this.utils.showLoading("Authenticating", 2000);
      setTimeout( _ => {
        this.utils.showLoading("Preparing your account", 2000);
        this.config.isExternalLinkSignIn = true;
        this.sessionService.pwaLogin(pwa_token);

        if(environment.name != 'production')
          this.utils.showGenericMessage(`Deeplink pwa_token: ${pwa_token}`);
        }, 2000);

        this.sessionService.onReady.pipe(first()).subscribe( _ =>{
          if(redirect?.length > 0) {
            this.sessionService.skippingLoginRedirect = true;
            this.navCtrl.navigateForward(redirect).then(() => {
              this.execDeeplinkCallback(callback);
              this.sessionService.skippingLoginRedirect = false;
            });
          }
        });
        return;
    }

    if(!instagram_login && (code || pcode) ) {
      if(environment.name != 'production') {
        this.utils.showGenericMessage(`Deeplink referal code: ${code}, pcode: ${pcode}`);
      }
      this.config.updateProfile({ referredBy: code, partnerCode: pcode }).then(
        resp => { 
          console.log('used referral code: ' + code);
          console.log('used partner code: ' + pcode);
        },
        err => { console.log('error saving ref code.'); }
      );
    }

    let nextPage = '';
    if(redirect) {
      nextPage = redirect;
    } else if(path && !['/', '/pwa/', '/pwa', 'pwa'].includes(path) ) {
      nextPage = path;
    }

    if(nextPage) {
      this.navCtrl.navigateRoot(nextPage).then(() => this.execDeeplinkCallback(callback));
    }
    
    return path;
  }

  execDeeplinkCallback(callback: any): void {
    if(!callback || !(callback in this.deeplinksCallbacks)) return;
    
    this.deeplinksCallbacks[callback]();
  }

  //Handles uri params and branch links
  async initUri() {
    if (!this.platform.is('cordova')) { 
      //Process normal uri no branch at all
      this.processUriData(document.URL, null);
      return false; 
    }

    (<any> window).branch = this.branch;
    this.branch.initSession().then(async data => {
      console.log('BRANCH DATA: ', data);
      (<any> window).branch_d = data;
      let link = data['~referring_link'];
      if(!link)
        link = data['+non_branch_link'];

      if(!link) {
        //Process normal uri, we have branch setup but no branch link was used or URI data passed.
        this.processUriData(document.URL, null); 
      } else {
        this.processUriData(link, data['$deeplink_path']);
      }
    }, this.branchErrorHandler);
  }

  branchErrorHandler = (err) => {
    //delay the error treatment so that it doesn't slow down the app start
    //also the other services are often not ready when branch encounters an error
    setTimeout( _ => {
      const tags = { klass: "appService", func: "processBranchError()" },
            extras = { error: err, logs: this.consoleService.getLogs() || [] },
            error = JSON.stringify(err);

      // !error isn't fit, we want to message only
      // if error is equal null, not undefined or
      // false or 0 or '' etc
      if(error === null) {
        log("Branch error is null");
        return;
      }

      if(error.includes(BranchErrors.AlreadyInit)) {
        log('-- Branch already initialized --');
        log(err);
        logc.error(error);
        return;
      }

      if(error.includes(BranchErrors.ReachingIssue) || error.includes(BranchErrors.InvalidRequest)) {
        if(this.branchInitAttempts == 3) {
          const errorMessage = "Branch attempted to init 3 times and failed";
          this.errorTrackingService.sendMessage(errorMessage, tags, extras);
          this.branchInitAttempts = 0;
          return;
        }
        this.branchInitAttempts += 1;
        log(`-- Branch init attempt #${ this.branchInitAttempts } --`);
        setTimeout(() => this.initUri(), BRANCH_RETRY_TIMEOUT);
      }

      if(error.includes(BranchErrors.NetworkInvalid)) {
        log("-- Branch tried init while internet was off --");
      }

      if(error.includes(BranchErrors.PluginNotInstalled)) {
        log("-- Branch tried init but its plugin not installed --");
      }

      this.analyticsService.trackEvent({
        key: 'branch_error',
        value: 1,
        ctx: error,
      });
   }, BRANCH_ERROR_PROCESSING_TIMEOUT);
  }

  get isDesktop() {
    return this.config.isDesktop();
  }

}
