import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {RemovalStatusesComponent} from "./removal-statuses.component";
import {StatusComponentModule} from "../status/status.module";

@NgModule({
  declarations: [
    RemovalStatusesComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    StatusComponentModule
  ],
  exports: [
    RemovalStatusesComponent
  ]
})
export class RemovalStatusesModule {}
