import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CheckmarkButtonsListModule } from "src/app/components/checkmark-buttons-list/checkmark-buttons-list.module";
import { CloseButtonModule } from "src/app/components/close-button/close-button.module";
import { BillingPlanComponentModule } from "./billing-plans/billing-plan.module";
import { PremiumFeaturesTableComponentModule } from "./premium-features-table/premium-features-table.module";
import { TimerComponentModule } from "./timer/timer.module";
import { UpsellingPage } from "./upselling.page";

@NgModule({
  declarations: [UpsellingPage],
  imports: [
    IonicModule,
    CommonModule,
    CloseButtonModule,
    TimerComponentModule,
    PremiumFeaturesTableComponentModule,
    CheckmarkButtonsListModule,
    BillingPlanComponentModule
  ],
  exports: [UpsellingPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UpsellingPageModule {}