import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Config } from '../config/config';
import { AnalyticsService } from '../analytics/analytics.service';
import { Saveable } from '../../shared/structure/saveable';
import { Storage } from '@ionic/storage';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import {of, Subject} from 'rxjs';
import {NavController} from "@ionic/angular";
import {STATEMENTS_SAVE_DELAY} from "../../shared/constants/constants";
import {itunesParentCategories, PodcastsService} from "../topics/podcasts.service";
import {map} from "rxjs/internal/operators";
//import follow_up_items from "./../../../assets/data/follow_up_items.json";
import {catchError} from "rxjs/operators";
import {logc} from "../../shared/helpers/log";
import { cloneDeep } from "lodash";

enum DynamicStatementsIds {
  Podcasts = 55
}

export interface Statement {
  answer: string;
  description: string;
  factor_parent: string;
  follow_up: StatementFollowUp;
  follow_up_trigger: string;
  id: number;
  external_ids: string;
  item_ids: number[];
  save: boolean;
}

interface StatementFollowUp {
  id: number;
  items: StatementFollowUpItem[];
  name: string;
  selector: string;
}

export interface StatementFollowUpItem {
  id: number;
  name: string;
  selected: boolean;
  sort: any;
}

@Injectable({
  providedIn: 'root',
})
export class StatementsService extends Saveable {
  defaultConfig: any = {};
  key: string = 'statements';
  public metaUserSource = new Subject();
  public onMetaUserUpdated;

  private traits = [
    "Personality",
    "Demographic",
    "Lifestyle",
    "Beliefs & Values",
    "Goals & Motives"
  ];

  private followUpItems;
  private suggestions;
  private followUpItemsIds: number[] = []

  private counts = [
    [ 0.3, 0.1, 0.15, 0.2, 0.12 ],
    [ 0.51, 0.29, 0.35, 0.43, 0.36],
    [ 0.65, 0.46, 0.5, 0.72, 0.6 ],
    [ 0.85, 0.69, 0.73, 0.87, 0.8 ],
    [ 1, 1, 1, 1, 1]
  ];

  private radialStats =
    Array(5)
      .fill(
        Array(5)
          .fill({})
      );

  constructor(public http: HttpClient,
              public api: ApiService,
              public config: Config,
              private analyticsService: AnalyticsService,
              public storage: Storage,
              private navCtrl: NavController,
              private podcastsService: PodcastsService) {
    super(storage);
    this.onMetaUserUpdated = this.metaUserSource.asObservable();

    (window as any).ss = this;

    this.radialStats = this.radialStats
      .map((level, levelIndex) =>
        level.map((trait, traitIndex) => ({
          name: this.traits[traitIndex],
          count: this.counts[levelIndex][traitIndex]
        }
      )));

    //(window as any).statement_service = this;
    this.http.get('./assets/data/follow_up_items.json')
      .toPromise()
      .then( (follow_up_items:any) => {
        this.followUpItems =
          follow_up_items
            .map((item) => {
              if(item.genres && item.follow_up_id != 180) {
                item.genres = undefined;
              }
              return item;
            })|| [];
          this.followUpItemsIds = this.followUpItems.map(e => e.id);
      });

    this.http.get("./assets/data/recommendations.json")
      .toPromise()
      .then((suggestions) => this.suggestions = suggestions);
  }

  getSuggestions(selectedIds) {
    return selectedIds
      .filter(id => this.followUpItemsIds.includes(id))
      .map(id => this.suggestions[id])
      .reduce((acc, elems) => [...acc, ...Object.entries(elems)], [])
      .sort((a, b) => b[1] - a[1])
      .reduce((acc, e) => {
        if (!acc.map(el => el[0]).includes(e[0])) {
          acc.push(e);
        } else {
          const duplicate = e;
          const existingId = acc.findIndex(el => el[0] == e[0]);
          if (duplicate[1] > acc[existingId][1]) {
            acc[existingId][1] = duplicate[1];
          }
        }
        return acc;
      }, [])
      .map(e => this.followUpItems.find(item => item.id == +e[0]));
  }

  getFollowUpItems() {
    return cloneDeep(this.followUpItems);
  }

  getExtraItems({ statement, value }): Promise<any[]> {
    //console.log(statement.id, value);
    switch(statement.id) {
      case DynamicStatementsIds.Podcasts:
        return this.podcastsService.getPodcasts(value, 25, 'itunes').toPromise();
    }
  }

  setCurrentStatements(currentStatements) {
    this.data = currentStatements;
    return this.save();
  }

  getCurrentStatements(){
    return this.data || [];
  }

  getStatementIndex(statementId) {
    return this.data.findIndex((s) => s.id === statementId);
  }

  reset() {
    return super.reset();
  }

  resetLevel(){
    let i = 0;
    while(i < this.data.length-1) {
      this.data[i].answer = null;
      this.data[i].item_ids = [];
      this.data[i].saved = false;
      i++;
    }
  }

  resetStatement(statement) {
    this.data[this.getStatementIndex(statement.id)].answer = null;
    this.data[this.getStatementIndex(statement.id)].item_ids = [];
    this.data[this.getStatementIndex(statement.id)].saved = false;
    this.save().then(() => {}, () => {});
  }

  setStatement(statement) {
    this.data[this.getStatementIndex(statement.id)] = statement;
  }

  saveStatements(urgent = false): Promise<void> {
    clearTimeout(this.saveStatementsTimeout);
    return new Promise((resolve, reject) => {
      let toSave = this.data.filter((s) => {
        return !s.saved && s.answer;
      }).map((s) => {
        return {
          value: s.answer,
          item_ids: s.item_ids || [],
          external_ids: s.external_ids,
          statement_id: s.id
        }
      });


      this.api.post('answers/grouped', {
        answers: JSON.stringify(toSave),
        urgent: urgent
      }).then(
        (data: any) => {
          toSave.forEach( (answer) => {
            let i = this.getStatementIndex(answer.statement_id);
            this.data[i].saved = true;
          });
          resolve();
          this.save().then( _ => {
          }, _ => {});
        }, err => {
          reject();
          this.save().then( _ => {}, _ => {});
          console.log(err);
        });
    });
  }

  saveStatementsTimeout = null;
  enqueueSaveStatements(){
    clearTimeout(this.saveStatementsTimeout);
    this.saveStatementsTimeout = setTimeout( _=> {
      this.saveStatements();
    }, STATEMENTS_SAVE_DELAY);
  }

  getAnswers(){
    return this.data.filter((s) => {
      return !s.saved && s.answer;
    }).map((s) => {
      return {
        value: s.answer,
        item_ids: s.item_ids || [],
        statement_id: s.id
      }
    });
  }

  getFactorColor(factor){
    const isDarkMode = this.config.isDarkMode();
    switch(factor){
      case "Beliefs & Values" : return isDarkMode ? "#536287" : "#91ABEC";
      case "Personality" : return isDarkMode ? "#796A4C" : "#ECC169";
      case "Lifestyle" : return isDarkMode ? "#3D7788" : "#68CEEC";
      case "Demographic" : return isDarkMode ? "#8A5169" : "#FF9BC5";
      case "Interests" : return isDarkMode ? "#3D7788" : "#98D8E3";
      case "Goals & Motives" : return isDarkMode ? "#346C60" : "#77D8C3";
      default : return "gray";
    }
  }

  getFactorGradientColor(factor){
    switch(factor){
      case "Beliefs & Values" : return ["#3a9af5","#8067f5"];
      case "Demographic" : return ["#d5a6bd","#b4a7d6"];
      case "Lifestyle" : return ["#6fa8dc","#35c1d4"];
      case "Personality" : return ["#e69138","#ebd254"];
      case "Goals & Motives" : return ["#52c1aa","#46a3b5"];
      default : return "gray";
    }
  }

  async openCurrentLevel() {
    if(!this.config.get('startedCurrentLevel')) {
      await this.goToNextLevel();
    }
    await this.navCtrl.navigateForward('levels/current');
  }

  goToNextLevel() {
    console.log('--- GO TO NEXT LEVEL FIRED! ');
    return new Promise((resolve, reject) => {
      let level = this.config.getCurrentLevel() + 1;
      //console.log("CURRENT LEVEL: ", level);
      this.config.updateProfile({
        currentLevel: level,
        startedCurrentLevel: true
      });

      this.analyticsService.trackEvent({
        key: 'started_level_' + level.toString(),
        value: 1
      });
      this.analyticsService.trackProperty({
        key: 'started_level',
        value: level.toString()
      });

      this.nextLevelStarted(level).then(resolve, reject);
    });
  }

  claimReward(level) {
    return this.api.post('levels/claim_reward', {level: level});
  }

  getCompletionStats( level ) {
    return Promise.resolve(this.radialStats[ level - 1 ]);
  }

  getRadarData(userId){
    return this.api.get(`users/radar_data/${userId}`,{});
  }

  metaUserUpdated(data){
    this.metaUserSource.next(data);
  }

  nextLevelStarted(level) {
    return this.api.post('levels/started_level', {level: level});
  }

  public currentLevelHasUnansweredStatements(){
    let localStatements = this.getCurrentStatements();
    return localStatements.length > 0 && localStatements.filter(q => q.answer == null).length > 0
  }

  public currentLevelDone(){
    let localStatements = this.getCurrentStatements();
    return localStatements.length > 0 &&
      localStatements.filter(q => q.answer == null).length == 0
  }

  public answerAll(){
    this.data.forEach( (a) => {
      a.answer = 'neutral'
    });
  }

  private fetch(resolve, reject) {
    //return the statements from config if it's present and uncompleted
    let localStatements = this.getCurrentStatements();
    if(this.currentLevelHasUnansweredStatements()) {
      return resolve(localStatements);
    }

    this.api.get('statements', {}).then(
      (data: any) => {
        this.setCurrentStatements(data);
        resolve(data);
      },
      err => reject(err));
  }

  load(){
    return new Promise((resolve, reject) => {
      this.config.load().then( _=> {
        super.load().then( _ => {
          this.fetch(resolve, reject);
        }, _ => reject(this.unableToSaveError));
      });
    });
  }

  getNextStatementsDependencies(){
    return this.api.get('statements/dependencies',{
      statement_ids: this.data.map(el => el.id).join()
    })
  }

  fastFinishLevel(answer: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.data.forEach(statement => {
        statement.answer = answer;
      });
      await this.saveStatements();
      resolve();
    });
  }

  sortItems(items) {
    if (items.some(item => item.sort)) {
      // selecting items with sort == null means it is old items without any
      // order and we want to sort them alphabetically
      let noSort = items.filter(e => e.sort == null);
      items =
        items
          .filter(e => e.sort !== null)
          .sort((a,b) => a.sort - b.sort)
          .concat(
            noSort.sort((a,b) =>
              a.name.localeCompare( b.name )
            ));
    }

    return items;
  }

  prepareItems(statement) {
    //(window as any).current_statement = statement;
    let items = statement.follow_up.items;
    //console.log('--statements service items: ', items);
    switch(statement.id) {
      case DynamicStatementsIds.Podcasts:
        items = this.sortItems(items)
          .map((item, index) => {
            if(index < 200) {
              if(!item.genres) {
                item.genres = [ "top" ];
              } else if(Array.isArray(item.genres)){
                item.genres.push("top");
              }
            }
            return item;
          })
          .map((item) => {
            if(!item.genres) return item;
            item.genres = item.genres.filter(genre => ['top', ...itunesParentCategories].includes(genre));
            return item;
          })
        return items;
      default:
        return this.sortItems(items);
    }
  }
  
  allLevelsFinished(): boolean {
    return this.config.finishedLevel(5) && this.config.get('bonusLevelDone');
  }

}
