<ion-content padding>
  <div class="request-body">

    <div class="request-title">
      Hi {{userName}}!
    </div>

    <div class="request-desc-one">
      I don't mean to bother you, but We3 is made by two friends and no investors.  And it has really become our lives' mission.
    </div>

    <div class="animals">
      <img src="./assets/img/animal-astronauts.png" alt="Astronauts">
    </div>

    <div class="request-desc-two">
      If you'd like to support us, can you please give us a review? It helps others find us, and We3’s success depends on this.
    </div>

    <img class="stars-animation" src="./assets/img/stars-animation.gif" alt="Stars">
    <div class="request-footer">
      <div class="request-footer-title">
        Thank you so much for your help!
      </div>

      <div class="request-footer-credentials">
        Julian and Emanuel
      </div>

      <div class="buttons-container">
        <ion-button class = 'ionic-button turquoise-gradient-btn ii-button'
                    large
                    shape = "round"
                    (click) = "rateApp()">
          Give a Review
        </ion-button>

        <button (click) = "close()" class = "empty-button no-border">
          Maybe Later
        </button>
      </div>

    </div>
  </div>


</ion-content>
