import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ALERT_STATES } from './states';
import Timeout = NodeJS.Timeout;
import {initiatorFirst} from "../../shared/helpers/sorting-methods";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-tribes-alert',
  template: `
    <div class = "alert-container fade-in-animation"
         (click) = 'openTribeHighlights()'
         [class.fade-out-animation] = "!alertShown">
      <tribe-avatar [tribe] = "tribe"></tribe-avatar>
      <div class = "information-container">
        <div class = "title">{{state?.title}}</div>
        <div class = "description">{{state?.description}}</div>
      </div>
      <div class="icon-control">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </div>
    </div>
  `,
  styleUrls: ['./tribes-alert.component.scss'],
})
export class TribesAlertComponent implements OnInit {

  @Input('stateName') stateName;
  @Input('tribe') tribe;

  @Output('onClose') onClose = new EventEmitter();
  @Output('onClick') onClick = new EventEmitter();

  private closeTimeout: Timeout;

  public state: any = {};

  private states = ALERT_STATES;

  public alertShown: boolean = false;

  public CLOSING_TIME = environment.name == "development" ? 1000 : 5000;

  constructor() { }

  ngOnInit() {
    this.setState();
    this.showModal();
    this.tribe.users.uniqPush(this.tribe.tribe_user).sort(initiatorFirst);
    this.closeTimeout = setTimeout(() => this.close(), this.CLOSING_TIME);
  }

  setState() {
    this.state = this.states[this.stateName];
  }

  showModal() {
    this.alertShown = true;
  }

  openTribeHighlights() {
    this.onClick.emit(this.tribe.id);
  }

  close() {
    clearTimeout(this.closeTimeout);
    this.alertShown = false;
    setTimeout(() => this.onClose.emit(null), 300);
  }

}
