import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { PictureService } from './picture.service';
import { UserService } from './data/user.service';
import {SentryService} from "./performance/sentry.service";
import {UploaderService} from "./native/uploader.service";
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root',
})
export class VisionService {
  public visionKey: string = 'AIzaSyA-tqgMWDWwcnZfXz4KthA1WLs8eI-HE28';
  public apiUrl: string = "https://vision.googleapis.com/v1/images:annotate?key=" + this.visionKey;
  public loading = null;

  public denialReason: string = '';
  public rejectedPicture = null;

  constructor(private loadingCtrl: LoadingController,
              public userService: UserService,
              private http: HttpClient,
              private utils: UtilsService,
              private errorTrackingService: SentryService,
              private pictureService: PictureService,
              private uploaderService: UploaderService) {
    (window as any).vision = this;
  }

  async showLoading() {
    this.loading = await this.loadingCtrl.create({
      message: "Verifying...",
    });
    await this.loading.present();
  }

  doneLoading() {
    this.loading.dismiss().then(_=> {
      this.loading = null;
    }, err => {
      console.log(err);
    });
  }

  verifyWithLoading(url) {
    this.showLoading();

    return new Promise((resolve, reject) => {
      this.verifyURI(url).then( data => {
        this.doneLoading();
        resolve(data);
      }, err => {
        this.doneLoading();
        reject(err);
      });
    });
  }

  dataRequest(data) {
    return {
      "requests":[{
          "image": {
            "content": data
          },
          "features":[
            { "type": 'LABEL_DETECTION', "maxResults": 25 },
            { "type": 'SAFE_SEARCH_DETECTION' },
            { "type": 'WEB_DETECTION' },
            { "type": 'FACE_DETECTION' },
            { "type": 'OBJECT_LOCALIZATION' }
          ]
      }]}; 
  }

  urlRequest(url) {
    return {
      "requests":[{
          "image": {
            "source": { "imageUri": url }
          },
          "features":[
            { "type": 'LABEL_DETECTION' },
            { "type": 'SAFE_SEARCH_DETECTION' },
            { "type": 'FACE_DETECTION'},
            { "type": 'OBJECT_LOCALIZATION' }
          ]
      }]}; 
  }

  fetch(base64) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl,
        JSON.stringify(this.dataRequest(base64)), { headers: {} })
        .toPromise()
        .then(
          (verification:any) => {
            resolve({verification: verification, data: base64});
          }, 
          err => {
            reject({message: "Can't validate this picture.", code: 2, data: base64});
          });
    });
  }

  fetchFromUrl(url) { 
    return new Promise((resolve, reject) => {
      this.pictureService.toDataUrl(url).then((base64:any) => {
        this.fetch(base64).then(resolve, reject);
       }, err => {
         reject({message: "Can't validate this picture.", data: null});
       });
    });
  }

  isFace(face) {
    if(!face["landmarks"]) return false;

    let landmarks = face['landmarks'];
    let left_eye = landmarks.find(landmark => landmark['type'].includes('LEFT_EYE'));
    let right_eye = landmarks.find(landmark => landmark['type'].includes('RIGHT_EYE'));
    let nose = landmarks.find(landmark => landmark['type'].includes('NOSE'));
    let mouth = landmarks.find(landmark => landmark['type'].includes('MOUTH'));
    return nose && mouth && (left_eye || right_eye);
  }

  verify() {
  
  }

  denialReasons: any[] = [];
  onData(data) {
    return new Promise(async (resolve, reject) => {
      let v = data.verification;
      data = data.data;

      console.log("VISION DATA: ", v);

      if(v.responses.length == 0 || v?.responses[0]?.error) {
        this.denialReason = 'badResponse';
        this.denialReasons.push({
          reason: this.denialReason,
          details: {
            responses_length: v.responses.length
          },
          data: v,
          error: v?.responses[0]?.error
        })
        return reject({
          reason: this.denialReason,
          data: data,
          message: "Can't validate this picture."
        });
      }

      v = v.responses[0];
      let porn = v.safeSearchAnnotation.adult == 'VERY_LIKELY' || v.safeSearchAnnotation.racy == 'VERY_LIKELY';
      let bareChested = v.labelAnnotations.find(label => label.description === "Barechested");
      if(porn || bareChested) {
        this.denialReason = 'risky';
        this.denialReasons.push({
          reason: this.denialReason,
          details: {
            porn: porn,
            bareChested: bareChested
          },
          data: v
        })
        return reject({
          reason: this.denialReason,
          data: data,
          code: 3
        });
      }

      let violence = v.safeSearchAnnotation.violence == 'VERY_LIKELY';
      let is_joyfull;

      let face = false;
      let numFacedDetected = 0;
      if(!v['faceAnnotations']) {
        if(v.labelAnnotations) {
          const la = v.labelAnnotations;
          let forehead = la.find(a => a.description === 'Forehead');
          let nose = la.find(a => a.description === 'Nose');
          let lips = la.find(a => a.description === 'Lip');
          if(forehead && nose && lips) {
            const imageUrl = await this.uploaderService.uploadBase64ToS3(this.utils.getRandomPictureId(), data);
            const tags = { klass: "vision", func: "onData" };
            const extras = { imageUrl: imageUrl, response: v }
            this.errorTrackingService.sendMessage("No faceAnnotations but forehead, lips and nose", tags, extras);
          }
        }
        this.denialReason = 'noFace';
        this.denialReasons.push({
          reason: this.denialReason,
          details: {
            face: v['faceAnnotations']
          },
          data: v
        })
        return reject({
          reason: this.denialReason,
          data: data,
          code: 3
        });
      }

      if(v['faceAnnotations']) {
        face = this.isFace(v['faceAnnotations'][0]);
        numFacedDetected = v['faceAnnotations'].length;
        is_joyfull = v['faceAnnotations'][0]['joyLikelihood']=='VERY_LIKELY';
        if(v['faceAnnotations'].length > 2) {
          if(this.isFace(v['faceAnnotations'][0]) &&
             this.isFace(v['faceAnnotations'][1])){
            this.denialReason = 'group';
            this.denialReasons.push({
              reason: this.denialReason,
              details: {
                faces_number: numFacedDetected,
                is_joyfull: is_joyfull,
                face: face
              },
              data: v
            })
            reject({
              reason: this.denialReason,
              message: "Too many faces.",
              code: 3,
              data: data
            });
            return;
          }
        }
      }

      const webDetection = v.webDetection;
      if(webDetection) {
        if(webDetection.fullMatchingImages && webDetection.fullMatchingImages.length > 2 ||
          webDetection.partialMatchingImages && webDetection.partialMatchingImages.length > 9) {
          this.denialReason = "stolen";
          this.denialReasons.push({
            reason: this.denialReason,
            details: {
              fulls: webDetection.fullMatchingImages,
              partials: webDetection.partialMatchingImages
            },
            data: v
          })
          const msg = "Stolen picture!";
          const tags = { klass: "VisionService", func: "onData()" };
          const extras = {
            fulls: webDetection.fullMatchingImages,
            partials: webDetection.partialMatchingImages
          }
          this.errorTrackingService.sendMessage(msg, tags, extras);
          return reject({
            reason: this.denialReason,
            code: 3,
            data: data
          })
        }
      }

      if((v.labelAnnotations && !v.labelAnnotations.length) || !v.labelAnnotations){
        this.denialReason = 'noFace';
        this.denialReasons.push({
          reason: this.denialReason,
          data: v
        })
        return reject({
          reason: this.denialReason,
          message: "Picture is not clear.",
          data: data,
          code: 3
        });
      }

      let fictionalContent = false;
      let lip, beauty, beard, facial_hair, moustache;
      let smile;
      if(v.labelAnnotations) {
        let cartoon = v.labelAnnotations.find(a => a.description === 'Cartoon' );
        let sketch = v.labelAnnotations.find(a => a.description === 'Sketch' );
        let drawing = v.labelAnnotations.find(a => a.description === 'Drawing' );
        let illustration = v.labelAnnotations.find(a => a.description === 'Illustration' );
        let anime = v.labelAnnotations.find(a => a.description === 'Anime' );
        let fictional = v.labelAnnotations.find(a => a.description === 'Fictional character' );
        fictionalContent = cartoon || sketch || drawing || illustration || anime || fictional;

        if(fictionalContent) {
          this.denialReason = 'risky';
          this.denialReasons.push({
            reason: this.denialReason,
            details: {
              cartoon: cartoon,
              sketch: sketch,
              drawing: drawing,
              illustration: illustration,
              anime: anime,
              fictional: fictional,
              fictional_content: fictionalContent
            },
            data: v
          })
          return reject({
            reason: this.denialReason
          })
        }

        lip = v.labelAnnotations.find(a => a.description === 'Lip' );
        beauty = v.labelAnnotations.find(a => a.description === 'Beauty' );
        beard = v.labelAnnotations.find(a => a.description === 'Beard' );
        facial_hair = v.labelAnnotations.find(a => a.description === 'Facial hair' );
        moustache = v.labelAnnotations.find(a => a.description === 'Moustache' );
        smile = v.labelAnnotations.find(a => a.description === 'Smile' );
      }

      let gender = 'unknown';
      if(lip || beauty) {
        if(beard || facial_hair || moustache ) {
          gender = 'male_likely';
        } else if(lip && beauty) {
          gender = 'female';
        } else {
          gender = 'female_likely';
        }
      } else {
        if(beard || facial_hair || moustache) {
          gender = 'male';
        } else {
          gender = 'unknown';
        }
      }
      if (numFacedDetected > 1 && (gender ==='male' || gender==='female')){
        gender = gender +'_likely';
      }

      let hasSmile = !!smile || is_joyfull;

      if(!porn && !violence && face && !fictionalContent){
        return resolve({
          gender: gender,
          hasSmile: hasSmile
        });
      } else {
        this.denialReason = 'risky';
        this.denialReasons.push({
          reason: this.denialReason,
          details: {
            porn: porn,
            violence: violence,
            face: face,
            fictional_content: fictionalContent
          },
          data: v
        })
        return reject({
          reason: this.denialReason,
          message: "Bad content",
          code: 3,
          data: data
        });
      }
    });
  }

  getDenialReason(): string {

    return '';
  }

  verifyBase64(base64) {
    return new Promise((resolve, reject) => {
      this.fetch(base64).then(
         (data:any) => {
           this.onData(data).then(resolve, reject);
         }, err => {
           reject(err); 
         });
    });
  }

  verifyURI(uri) {
    return new Promise((resolve, reject) => {
      this.fetchFromUrl(uri).then(
         (data:any) => {
           this.onData(data).then(resolve,reject);
         }, err => {
           reject(err);
         });
    });
  }
}
