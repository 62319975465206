/**
 * @property { number } BASIC_INFO Points for filling name, email and age
 * @property { number } SMILE Points for adding photo with smile = true
 * @property { number } WORK Points for adding work info
 * @property { number } SCHOOL Points for adding alumni info
 * @property { number } ABOUT_ME Points for filling "About me" textarea
 * @property { number } INSTAGRAM Points for connecting instagram
 * @default
 */
export enum Progress {
  BasicInfo = 40,
  Smile = 15,
  Work = 5,
  School = 5,
  AboutMe = 20,
  Instagram = 15
}
