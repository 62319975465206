import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {
  transform(value: any, callback) {
    return callback(value);
  }
}
