export const CUSTOM_NOTIFICATION_ALERTS = {
  subscriptionResumed: {
    image: "./assets/img/trophy.png",
    title: "Subscription recovered",
    description: "Your form of payment was updated.",
    customClass: "subscription-alert",
    redirectToSubs: true
  },
  subscriptionPaused: {
    image: "./assets/img/trophy.png",
    title: "Your subscription has an issue",
    description: "Open your subscription settings to fix your payment method.",
    customClass: "subscription-alert",
    redirectToSubs: true
  }
};
