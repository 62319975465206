import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {RadioSelectorComponent} from "./radio-selector.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [RadioSelectorComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [RadioSelectorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RadioSelectorModule {}
