import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2} from '@angular/core';
import {NavController, PopoverController} from '@ionic/angular';
import {LocationService} from "../../services/native/location.service";
import {TribesService} from "../../services/data/tribes.service";
import {UtilsService} from "../../services/utils.service";
import {ModalService} from "../../services/popups/modal.service";
import {DispatcherService} from "../../services/dispatcher.service";
import {Config} from "../../services/config/config";
import { UpsellingPoppingReason } from 'src/app/pages/upselling/upselling.page';

@Component({
  selector: 'match-me-popover',
  template: `
    <div class = "content">
      <div class = "title">{{ state?.title }}</div>
      <div class = "subtitle">{{ state?.subTitle }}</div>
      <div *ngIf = "state?.buttons" class = "button-container">
        <button *ngFor = "let button of state?.buttons" 
                class = "match-me-button bold link {{ button?.cssClass }}"
                (click) = "button?.handler()">
          {{ button?.text }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./match-me-popover.component.scss']
})
export class MatchMePopoverComponent implements OnInit {

  @Input('reason') reason: string;
  @Output() recheck = new EventEmitter();
  @Output() onClick = new EventEmitter();

  @HostListener('mousedown', ['$event']) onMouseEnter(event: MouseEvent, reason = this.reason) {
    let componentClicked: boolean = (<HTMLElement>event.target).classList.contains('match-me-button');
    if(!componentClicked) {
      this.close();
    }
    if(this.reason == 'daily_tribes_limit_reached') {
      this.config.setFlag("dailyLimitPopoverTapped", true);
    }
  }

  public popupPresented: boolean = false;
  public state: any = {};
  public states = {
    too_many_rejections: {
      title: "Matching paused for today",
      subTitle: "To match with new people, try again tomorrow.",
      buttons: [
        { text: "Start over", handler: () => this.resetMatches() },
        { 
          text: "Keep matching", 
          cssClass: "tertiary-color", 
          handler: () => this.openUpselling({ 
            source: "tribes-page", 
            reason: UpsellingPoppingReason.DailyGroupLimit
          })
        }
      ]
    },
    scheduled_searches_limit: {
      title: "Weekly limit reached",
      subTitle: "You are already signed up to match with 3 groups."
    },
    rejected: {
      title: "Last step",
      subTitle: `There is an unresolved issue with your profile.`,
      buttons: [
        { text: "View My Profile", handler: () => this.navCtrl.navigateForward("tabs/profile") }
      ]
    },
    too_many_passes: {
      title: "Matching paused for today",
      subTitle: "To match with new people, try again tomorrow.",
      buttons: [
        {
          text: "Start Over",
          handler: () => this.resetMatches()
        }
      ]
    },
    too_many_users_passed: {
      title: "Matching paused for today",
      subTitle: "To match with new people, try again tomorrow.",
      buttons: [
        { text: "Start over", handler: () => this.resetMatches() },
        { 
          text: "Keep matching", 
          cssClass: "tertiary-color", 
          handler: () => this.openUpselling({ 
            source: "tribes-page", 
            reason: UpsellingPoppingReason.DailyGroupLimit
          })
        }
      ]
    },
    no_location: {
      title: "Device location missing",
      subTitle: "We don't know where to match you. Should we use a rough approximation?",
      buttons: [
        {
          text: "Approximate my location",
          handler: () => this.locationService.approximateLocation()
        }
      ]
    },
    pending_tribe: {
      title: "To match again, first respond to your group",
      subTitle: "You can only have one group pending at a time.",
      buttons: [
        {
          text: "Open group",
          handler: () => this.openPendingTribe()
        }
      ]
    },
    other_city: {
      title: "You're about to match in a different city!",
      subTitle: "Keep in mind that you'll need We3 Plus to create groups in cities you're not currently in.",
    },
    daily_tribes_limit_reached: {
      title: "Daily limit reached",
      subTitle: `You already started ${ this.config.isPlus() ? "3 groups" : "a group" } today. Matching will resume tomorrow.`,
      buttons: [
        {
          text: this.config.isPlus() ? "" : "Learn more",
          cssClass: "label-color",
          handler: () => {
            this.openGenericPopup({ popupName: "freeDailyLimitPopup" })
          }
        },
        {
          text: this.config.isPlus() ? '' : "Skip the wait",
          cssClass: "tertiary-color",
          handler: () => {
            if(this.config.isPlus()) return;
            this.config.setFlag("dailyLimitPopoverTapped", true);
            this.close();
            this.openUpselling({ 
              source: "tribes_page", 
              reason: UpsellingPoppingReason.DailyGroupLimit
            })
          }
        }
      ]
    }
  };

  constructor(private popoverCtrl: PopoverController,
              private locationService: LocationService,
              private tribesService: TribesService,
              private utils: UtilsService,
              private config: Config,
              private navCtrl: NavController,
              private dispatcherService: DispatcherService,
              private modalService: ModalService,
              private element: ElementRef,
              private renderer: Renderer2) {
    // this.reason = this.navParams.get('reason') || 'no_location';
  }

  ngOnInit() {
    this.popupPresented = true;
    this.dispatcherService
      .onMatchMePopover
      .subscribe((state: { name: string, action: string }) => {
        this.reason = state.name;
        this.state = this.states[ state.name ];
        this.execute( state.action );
      })
  }

  openUpselling(options) {
    this.modalService.openUpselling(options)
  }

  execute( action ) {
    const actions = {
      open: () => this.open(),
      close: () => this.close()
    };

    actions[ action ]();
  }

  open() {
    this.renderer.removeClass(this.element.nativeElement, 'fade-out-animation');
    this.renderer.addClass(this.element.nativeElement, 'fade-in-animation');
    this.renderer.setStyle(this.element.nativeElement, 'display', 'block');
  }

  close() {
    this.renderer.removeClass(this.element.nativeElement, 'fade-in-animation');
    this.renderer.addClass(this.element.nativeElement, 'fade-out-animation');
    setTimeout(() => {
      this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
    }, 500);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.state = this.states[this.reason];
    }, 0);
  }

  resetMatches() {
    this.close();
    this.dispatcherService.newPopupSource.next({
      popupName: "resetMatchesPopup",
      options: {
        source: "blocked_matching"
      }
    })
  }

  async setLocation() {
    try {
      await this.locationService.approximateLocation();
      this.close();
    } catch(e) {

    }
  }

  async openGenericPopup( data ) {
    this.dispatcherService.newPopupSource.next( data );
  }

  async openPendingTribe() {
    await this.tribesService.openPendingTribe();
    this.close();
  }

}
