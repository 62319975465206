import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { IconPlateModule } from "src/app/components/icon-plate/icon-plate.module";
import { PipesModule } from "src/app/pipes/pipes.module";
import { ChatAvatarComponent } from "./chat-avatar.component";

@NgModule({
  declarations: [ChatAvatarComponent],
  imports: [
    CommonModule,
    IonicModule,
    IconPlateModule,
    PipesModule
  ],
  exports: [ChatAvatarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class ChatAvatarModule {}