import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Config} from "../../services/config/config";
import {filter, tap} from "rxjs/operators";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class LoggerInterceptor implements HttpInterceptor {

  private isDev: boolean = false;

  constructor(private config: Config) {
    this.isDev = this.config.isDev;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const joinKeys = (
      obj: HttpRequest<any> | HttpResponse<any>,
      keys: string[],
      sep: string = ' - '
    ) => keys.map(key => obj[key]).join(sep)

    console.log(`Request: ${ joinKeys(req, ['method', 'url']) }`);

    return next.handle(req)
      .pipe(
        filter(res => res instanceof HttpResponse),
        tap((res: HttpResponse<any>) =>
          console.log(`Response: ${ joinKeys(res, ['method', 'url', 'status', 'statusText']) }`)
        )
      );
  }
}
