import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {ScheduledSearchComponent} from "./scheduled-search.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {DateLabelComponent} from "../date-label/date-label.component";
import {DateLabelModule} from "../date-label/date-label.module";

@NgModule({
  entryComponents: [ DateLabelComponent ],
  declarations: [ ScheduledSearchComponent ],
  imports: [
    CommonModule,
    IonicModule,
    DateLabelModule
  ],
  exports: [ ScheduledSearchComponent ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ScheduledSearchModule {}
