<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon class = "modal-close-button" name = "close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="vertically-centered">
    <div class="top">
    </div>
    <div class="middle">
      <img [src]="url">
    </div>
    <div class="bottom">
    </div>
  </div>
</ion-content>
