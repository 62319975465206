import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {TribesGuideComponent} from "./tribes-guide.component";
import {CommonModule} from "@angular/common";
import {LightButtonModule} from "../light-button/light-button.module";

@NgModule({
  declarations: [ TribesGuideComponent ],
  imports: [
    CommonModule,
    LightButtonModule
  ],
  exports: [ TribesGuideComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class TribesGuideModule {}
