import { Component } from '@angular/core';
import { PopoverController, NavParams, AlertController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-settings-popup',
  templateUrl: './settings-popover.component.html',
  styleUrls: ['./settings-popover.component.scss'],
})
export class SettingsPopoverComponent {

  public data:{
    title: "",
    text:"",
    type:""
  };

  constructor( public popoverCtrl: PopoverController,
               public navParams: NavParams,
               public alertController: AlertController,
               public navCtrl: NavController ) {
    this.data = this.navParams.get('data');
   }

   async changeAlert() {

    this.popoverCtrl.dismiss();
    
    const alert = await this.alertController.create({
      header: 'We3 will review this change.',
      message: 'We care a lot about keeping our community real. Do you still want to make a change?',
      buttons: [
        'Cancel',
        {
          text: 'Change',
          handler: () => this.changeInfo()
        }]
    });

    await alert.present();
  }

  changeInfo() {
    this.navCtrl.navigateForward(['sign-up-flow'],
      {
        state: {
          setup: [this.data.type],
          backPath: 'tabs/profile'
        }
      });
  }

}
