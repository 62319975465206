import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "chat-tip",
  template: `
    <div class = "
           flex min-w-[50%] mx-[60px] gap-[10px] 
           custom-tooltip secondary-gradient 
           fade-in-animation delay-1sec p-[15px]
           rounded-[5px] absolute z-[1000]">
      
      <div class="top-arrow left"></div>
      <div class="top-arrow right"></div>
      
      <ion-icon class = "text-xl tertiary-color shrink-0" name = "thumbs-up-sharp"></ion-icon>
      
      <div class = "text-base">
        <div class = "text-[#FFF]">Getting invited doesn't count toward their daily limit.</div>
        <div (click) = "onClick()" class = "mt-[10px] mb-[5px] text-right underline text-tertiary"> 
          See how to get invited 
        </div>
      </div>

      <ion-icon class = "text-xl text-[#FFF] shrink-0" 
                (click) = "close()" 
                name = "close"></ion-icon>
    </div>
  `,
  styleUrls: ["chat-tip.component.scss"]
})
export class ChatTipComponent {
  @Output() onClose: EventEmitter<{ manually: boolean }> = new EventEmitter();
  @Output() onButtonClick: EventEmitter<null> = new EventEmitter();

  close(): void {
    this.onClose.emit({ manually: true });
  }

  onClick(): void {
    this.onButtonClick.emit(null);
  }
}