import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatImage } from './chat-image';

import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { ImageViewerModule } from '../image-viewer/image-viewer.module';

@NgModule({
  declarations: [
    ChatImage
  ],
  entryComponents: [
    ImageViewerComponent,
  ],
  imports: [
    CommonModule,
    ImageViewerModule,
  ],
  exports: [
    ChatImage
  ]
})
export class ChatImageModule {}
