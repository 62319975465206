import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TribesPage } from './tribes.page';
import { RouterModule } from '@angular/router';
import { PageStatusModule } from '../../components/page-status/page-status.module';
import { TribeDetailsPage } from '../tribe-details/tribe-details.page';
import { TribeDetailsPageModule } from '../tribe-details/tribe-details.module';
import { CustomActionSheetModule } from '../../components/custom-action-sheet/custom-action-sheet.module';
import { CustomActionSheetComponent } from '../../components/custom-action-sheet/custom-action-sheet.component';
import { LightButtonModule } from '../../components/light-button/light-button.module';
import { NotificationBadgeComponent } from '../../components/notification-badge/notification-badge.component';
import { NotificationBadgeModule } from '../../components/notification-badge/notification-badge.module';
import {StarsFeedbackComponent} from '../../components/stars-feedback/stars-feedback.component';
import {StarsFeedbackComponentModule} from '../../components/stars-feedback/stars-feedback.module';
import {LottieModule} from "ngx-lottie";
import {MatchMePopoverModule} from "../../components/match-me-popover/match-me-popover.module";
import {FeedbackModalComponent} from "../../components/feedback-modal/feedback-modal.component";
import {FeedbackModalModule} from "../../components/feedback-modal/feedback-modal.module";
import { TribesAlertComponent } from '../../components/tribes-alert/tribes-alert.component';
import { TribesAlertModule } from '../../components/tribes-alert/tribes-alert.module';
import {CustomAlertComponent} from "../../components/custom-alert/custom-alert.component";
import {CustomAlertModule} from "../../components/custom-alert/custom-alert.module";
import {PipesModule} from "../../pipes/pipes.module";
import {BlinkingTextComponent} from "../../components/blinking-text/blinking-text.component";
import {BlinkingTextModule} from "../../components/blinking-text/blinking-text.module";
import {DirectivesModule} from "../../directives/directives.module";
import {TribeAvatarModule} from "../../components/tribe-avatar/tribe-avatar.module";
import {TopicTribeAvatarModule} from "../../components/topic-tribe-avatar/topic-tribe-avatar.module";
import {SearchingTribeAvatarModule} from "../../components/searching-tribe-avatar/searching-tribe-avatar.module";
import {TribeModule} from "../../components/tribe/tribe.module";
import {MatchesPlateModule} from "../../components/matches-plate/matches-plate.module";
import {TribesGuideModule} from "../../components/tribes-guide/tribe-guide.module";
import {ChatItemModule} from "../../components/chat-item/chat-item.module";
import { TribeScoreComponent } from 'src/app/components/tribe-score/tribe-score.component';
import { TribeScoreModule } from 'src/app/components/tribe-score/tribe-score.module';
import {HorizontalSliderComponent} from "../../components/horizontal-slider/horizontal-slider.component";
import {HorizontalSliderModule} from "../../components/horizontal-slider/horizontal-slider.module";
import {HorizontalSliderCardComponent} from "../../components/horizontal-slider-card/horizontal-slider-card.component";
import {HorizontalSliderCardModule} from "../../components/horizontal-slider-card/horizontal-slider-card.module";
import {BulletListComponent} from "../../components/bullet-list/bullet-list.component";
import {BulletListModule} from "../../components/bullet-list/bullet-list.module";
import { PotentialMatchesShortModule } from 'src/app/components/potential-matches-short/potential-matches-short.module';
import { ButtonWithSuccessStateModule } from 'src/app/components/button-with-success-state/button-with-success-state.module';


@NgModule({
  declarations: [
    TribesPage
  ],
  entryComponents: [
    TribeDetailsPage,
    CustomActionSheetComponent,
    NotificationBadgeComponent,
    StarsFeedbackComponent,
    FeedbackModalComponent,
    TribesAlertComponent,
    CustomAlertComponent,
    BlinkingTextComponent,
    TribeScoreComponent,
    HorizontalSliderComponent,
    HorizontalSliderCardComponent,
    BulletListComponent
  ],
  imports: [
    ButtonWithSuccessStateModule,
    PotentialMatchesShortModule,
    TribeScoreModule,
    LottieModule,
    BulletListModule,
    HorizontalSliderCardModule,
    HorizontalSliderModule,
    BlinkingTextModule,
    CustomAlertModule,
    FeedbackModalModule,
    TribesAlertModule,
    IonicModule.forRoot(),
    CommonModule,
    NotificationBadgeModule,
    LightButtonModule,
    PageStatusModule,
    TribeDetailsPageModule,
    CustomActionSheetModule,
    DirectivesModule,
    StarsFeedbackComponentModule,
    RouterModule.forChild([{
      path: '',
      component: TribesPage,
    }]),
    LottieModule,
    MatchMePopoverModule,
    PipesModule,
    TribeAvatarModule,
    TopicTribeAvatarModule,
    SearchingTribeAvatarModule,
    TribeModule,
    MatchesPlateModule,
    TribesGuideModule,
    ChatItemModule
  ],
  exports: [
    TribesPage
  ],
  schemas: []
})
export class TribesPageModule {}
