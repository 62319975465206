import {Component, Input, OnInit} from '@angular/core';
import {RECOMMENDED_MATCHES_LENGTH} from "../../shared/constants/matches";
import {MatchesService, MatchStatus} from "../../services/matches.service";
import {takeUntil} from "rxjs/internal/operators";
import {Subject} from "rxjs/Subject";
import {logc} from "../../shared/helpers/log";
import {Config} from "../../services/config/config";
import {of} from "rxjs";
import {AnalyticsService} from "../../services/analytics/analytics.service";
import {AppService} from "../../services/app";
import {AudiencesService} from "../../services/analytics/audiences.service";

enum MatchesPageStates {
  Standard,
  Hybrid
}

@Component({
  selector: 'matches-cards',
  template: `
    <div class="matches-container {{ cssClass }}">
      <div *ngFor = "let user of matches | async; let index = index"
           class = "match-card"
           [class.sections-margin] = "isPlus && !incompatibleMode && index == recommendedMatchesLength - 1"
           [class.section-divider] = "isPlus && !incompatibleMode && index == recommendedMatchesLength"
           [class.recommended] = "isPlus && !incompatibleMode && index < recommendedMatchesLength">
        <match-card (click) = "incrementVisits()"
                    [interactive] = "interactive" 
                    [index] = "index"
                    [matchingType] = "matchingType"
                    [source] = "source"
                    [user] = "user"></match-card>
      </div>
    </div>
  `,
  styleUrls: ['./matches-cards.component.scss'],
})
export class MatchesCardsComponent implements OnInit {
  @Input('matches') passedMatches;
  @Input("source") source;
  @Input("matchingType") matchingType: string = "default";
  @Input("cssClass") cssClass: string = '';
  @Input("interactive") interactive: boolean = true;
  @Input("incompatibleMode") incompatibleMode: boolean = false;
  public state;
  public matches;
  public RECOMMENDED_MATCHES_LENGTH = RECOMMENDED_MATCHES_LENGTH;
  public recommendedMatchesLength: number = null;
  public unsubscribe: Subject<any> = new Subject<any>()
  public isHybrid: boolean = false;
  public isPlus: boolean = false;

  private matchesViewed: number = 0;
  private browsingStart = Date.now();
  public inScheduledGroup: boolean = false;

  constructor(private matchesService: MatchesService,
              private config: Config,
              private app: AppService,
              private analyticsService: AnalyticsService,
              private audiencesService: AudiencesService) { 
    (window as any).matchesCards = this;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // logc.error("Source: ", this.source);
    }, 1000)
  }
  ngOnInit() {
    this.isPlus = this.config.isPlus();
    this.inScheduledGroup = this.audiencesService.inScheduledGroup();

    if(!this.passedMatches) {
      this.matches = this.matchesService.matchesFetched;
      this.matches.pipe(takeUntil(this.unsubscribe)).subscribe((matches) => {
        this.recommendedMatchesLength = 
          matches
            .filter(match => match.pool_status == MatchStatus.Recommended).length;
      })
    } else {
      this.matches = of(this.passedMatches);
    }

    this.app.onPause.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.browsingStart = null;
      this.matchesViewed = 0;
    })

    this.app.onResume.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.browsingStart = Date.now();
    })

    this.matchesService
      .stateChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
        logc.info("** Matches cards state: ", state);
        this.state = state;
        this.isHybrid = state == MatchesPageStates.Hybrid;
      });
  }

  incrementVisits() {
    this.matchesViewed += 1;
  }

  ngOnDestroy() {
    if(this.source == "failedSearch") {
      this.analyticsService.trackEvent({
        key: "unavailable_matches_viewed",
        value: 1,
        timeSpent: (Date.now() - this.browsingStart) / 1000,
        matchesViewed: this.matchesViewed
      })
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
