import {
  Component, ElementRef, ViewContainerRef,
  ComponentFactoryResolver, ChangeDetectorRef,
  NgZone, EventEmitter, Output, ViewChild, Input
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Loading } from '../../components/loading/loading';
import { ChatImage } from '../../components/chat-image/chat-image';
import { ChatMap } from '../../components/chat-map/chat-map';
import { ChatVideo } from '../../components/chat-video/chat-video';
import { ChatLink } from '../../components/chat-link/chat-link';
import { Renderer2 } from "@angular/core";

import { Subject } from "rxjs/Subject";
import { takeUntil } from "rxjs/operators";
import Hammer from "hammerjs";
import {logc} from "../../shared/helpers/log";

@Component({ 
  selector: 'rich-content',
  template: `<div #target><ng-content></ng-content></div>`
})
export class RichContent {
  @Output() onDoneLoading = new EventEmitter();
  @Output() onTest = new EventEmitter();
  @Output() longPress = new EventEmitter();
  @Input('data') data;
  @Input() message: any;
  //@ViewChild('target') target: ViewContainerRef;
  @ViewChild('target', { read: ViewContainerRef, static: true }) target: ViewContainerRef;
  private unsubscribe = new Subject<void>();

  emojiRegex = /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/g;
  linksRegex = /(https?:\/\/[^\s]+)/g;
  singleLink = /^\s*(https?:\/\/[^\s]+)\s*$/;
  linksToReplace = [];
  nativeEl = null;

  constructor(public el: ElementRef,
              private http: HttpClient, 
              private componentFactoryResolver: ComponentFactoryResolver,
              private viewContainer: ViewContainerRef,
              private changeDetector: ChangeDetectorRef,
              public ngZone: NgZone = null,
              public renderer: Renderer2
              ){}
  
  ngOnInit(){
    this.nativeEl = this.target.element.nativeElement;
  }

  ngAfterViewInit() {
    // let html = this.nativeEl.innerHTML;
    let html = this.data;

    this.addLongPressListener();

    if(this.emojiRegex.exec(html)) {
      if(!/\w/.exec(html)) {
        this.renderer.addClass(this.el.nativeElement, 'emojis-only');
      }
    }
    let link = this.singleLink.exec(html);
    if(link) {
      this.changeDetector.detach();
      setTimeout( _ => {
        this.replaceLink(link[1]);
      }, 0);
    } else if(this.linksRegex.exec(html)) {
      html = html.replace(this.linksRegex, l => this.replaceLink(l));
      this.nativeEl.innerHTML = html;
    } else {
      this.nativeEl.innerHTML = html.replace(/&lt;br&gt;/g, "<br>");
    }
  }

  showLoading(){
    let component = null;
    this.target.clear();
    this.nativeEl.innerHTML = '';
    component = this.componentFactoryResolver.resolveComponentFactory(Loading);
    let ref = this.target.createComponent(component);
  }

  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  loadMedia(data){
    let component = null;
    if(data.mediaType == 'video') {
      component = this.componentFactoryResolver.resolveComponentFactory(ChatVideo);
      this.target.clear();
      let ref = this.target.createComponent(component);
      (<any>ref.instance).updateData(data);
      this.changeDetector.reattach();
    }

    if(data.mediaType == 'image') {
      this.renderer.addClass(this.el.nativeElement, 'tribe-chat-media');
      let preview = new Image();
      preview.src = data.src;

      preview.onload = () => {
        if(data.mediaType == 'image') {
          component = this.componentFactoryResolver.resolveComponentFactory(ChatImage);
          this.target.clear();
          let ref = this.target.createComponent(component);
          (<any>ref.instance).updateData(data);
          this.changeDetector.reattach();

          (<any>ref.instance).onDoneLoading
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((height) => {
              this.onDoneLoading.emit(height);
            });
        } else if (data.mediaType == 'map') {
          component = this.componentFactoryResolver.resolveComponentFactory(ChatMap);
          this.target.clear();
          let ref = this.target.createComponent(component);
          (<any>ref.instance).updateData(data);
          this.changeDetector.reattach();
        } else {
          this.changeDetector.reattach();
        }
      }
    }
  }

  addLongPressListener(): void {
    const options: any = { time: 500 };
    const manager = new Hammer.Manager(this.el.nativeElement);
    const Press = new Hammer.Press(options);
    manager.add(Press);
    manager.on("press", () => this.longPress.emit(null));
  }

  hyperlinkFallback(data){
    let component = this.componentFactoryResolver.resolveComponentFactory(ChatLink);
    let ref = this.target.createComponent(component);
    (<any>ref.instance).updateData(data);
    this.nativeEl.innerHTML = "";
    this.changeDetector.reattach();
  }

  replaceLink(src){
    let source = src.replace(/&amp;/g, '&').replace(/&lt;br&gt;/g, "");

    let data = { url: source, src: source, mediaType: 'image' };

    if(source.includes('youtu')){
      source = source.replace("https://m.youtube.com/watch?v=",
                        "https://www.youtube.com/embed/")
               .replace("https://www.youtube.com/watch?v=", 
                        "https://www.youtube.com/embed/")
               .replace("https://youtu.be/", 
                        "https://www.youtube.com/embed/");
      data['mediaType'] = 'video';
      data['src'] = source;
    }

    if(this.hasImgExtension(src) || data.mediaType == 'video'){
      this.loadMedia(data);
    } else {
      this.hyperlinkFallback(data);
    }
    return '';
  }

  hasImgExtension(url) {
    let imgUrl = /(http)?s?:?(\/\/[^"']*\.(?:png|jpe?g|gif|png|svg))/i;
    return imgUrl.exec(url);
  }

  // extractCenter(str) {
  //   let longLat = /\@[\d\.]*,[^\,]*/
  //   let res = longLat.exec(str);
  //   return res && res[0].substring(1);
  // }
  //
  // extractTargetName(str) {
  //   let target = /(place(\/)|dir(\/\/))[^\/]*/;
  //   let res = target.exec(str);
  //   if(!res){ return; }
  //
  //   let place = res[0].replace('place/', '').replace('dir//', '');
  //   return decodeURI(place);
  // }
  //
  // extractTarget(place) {
  //   if(place.indexOf('°') > -1) {
  //     place = this.parseDMS(place);
  //   }
  //   return place;
  // }
  //
  // parseDMS(input) {
  //   let parts = input.split(/[^\d\.\w]+/);
  //   let toDD = (degrees, minutes, seconds, direction) => {
  //     var dd = degrees*1 + minutes/60 + seconds/(60*60);
  //     if (direction == "S" || direction == "W") {
  //         dd = dd * -1;
  //     }
  //     return dd;
  //   };
  //   return [
  //     toDD(parts[0], parts[1], parts[2], parts[3]),
  //     toDD(parts[4], parts[5], parts[6], parts[7])
  //   ].join(',');
  // }
}
