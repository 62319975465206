import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FeedbackRadioListAnswer, FeedbackRadioListQuestion} from "../../shared/interfaces/feedback-modal";
import {Subject} from "rxjs/Subject";
import {ModalController} from "@ionic/angular";
import {BuilderService} from "../../services/helpers/builder.service";


@Component({
  selector: 'app-feedback-form',
  template: `
    <ion-header>
      <ion-toolbar class = "no-border">
        <ion-buttons [class.hide] = "currentQuestion.initial" slot = "start">
          <ion-button (click) = "showPreviousQuestion()">
            <ion-icon name = "arrow-back"></ion-icon>
          </ion-button>
        </ion-buttons>
        <div class = "title mt-[10px]" [innerHTML] = "currentQuestion?.title"></div>
        <div *ngIf = "currentQuestion?.subtitle" 
             class = "subtitle" 
             [innerHTML] = "currentQuestion.subtitle"></div>
        <ion-buttons slot = "end">
          <ion-button (click) = "close({ action: 'close' })">
            <ion-icon name = "close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content (clickOutside) = "onClickOutside($event)">
      <radio-list *ngIf = "currentQuestion.type == 'radio'" 
                  [currentQuestion] = "currentQuestion"></radio-list>
      <checkbox-list *ngIf = "currentQuestion.type == 'checkbox'" 
                     [currentQuestion] = "currentQuestion"
                     (onInputOpened) = "popupForm()"
                     (onInputClosed) = "shrinkForm()"></checkbox-list>
    </ion-content>
  `,
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent {
  @Input("currentQuestion") currentQuestion: FeedbackRadioListQuestion;

  public showOtherOption: boolean = false;
  public details: any = '';
  public onParentDestroyed = new Subject();
  private initialBreakpoint: number = null;

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private modalCtrl: ModalController,
              private builderService: BuilderService) {
  }

  onClickOutside(event) {}

  async ionViewDidEnter() {
    const modal = await this.modalCtrl.getTop();
    this.initialBreakpoint = await modal.getCurrentBreakpoint();
  }

  async popupForm() {
    const modal = await this.modalCtrl.getTop();
    await modal.setCurrentBreakpoint(1);
  }

  async shrinkForm() {
    const modal = await this.modalCtrl.getTop();
    await modal.setCurrentBreakpoint(this.initialBreakpoint);
  }

  showPreviousQuestion() {
    this.close({ action: 'prev'});
  }

  close(data) {
    this.modalCtrl.dismiss(data);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
