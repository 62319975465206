import { Injectable } from '@angular/core';
import {TapticEngine} from "@ionic-native/taptic-engine/ngx";
import { Vibration } from '@ionic-native/vibration/ngx';
import {Platform} from "@ionic/angular";
import {Config} from "../config/config";

const ANDROID_LIGHT_PATTERN = [25];
const ANDROID_MEDIUM_PATTERN = [50];
const ANDROID_HEAVY_PATTERN = [100];

@Injectable({
  providedIn: 'root'
})
export class TapticService {

  private isIos: boolean;
  private isPWA = this.config.isPWA;

  constructor(private tapticEngine: TapticEngine,
              private vibration: Vibration,
              private config: Config,
              private platform: Platform) {
  }

  lightVibration() {
    if(this.platform.is('ios')) this.tapticImpact('light');
    if(this.platform.is('android')) this.vibrate(ANDROID_LIGHT_PATTERN)
  }

  mediumVibration() {
    if(this.isPWA) return this.pwaVibrate(ANDROID_MEDIUM_PATTERN);
    if(this.platform.is('ios')) return this.tapticImpact('medium');
    if(this.platform.is('android')) return this.vibrate(ANDROID_MEDIUM_PATTERN)
  }

  heavyVibration() {
    if(this.platform.is('ios')) return this.tapticImpact('heavy');
    if(this.platform.is('android')) return this.vibrate(ANDROID_HEAVY_PATTERN)
  }

  private async tapticSelection() {
    await this.tapticEngine.selection();
  }

  private async tapticNotification(type) {
    await this.tapticEngine.notification({ type });
  }

  private async tapticImpact(style) {
    await this.tapticEngine.impact({ style });
  }

  private vibrate(pattern: number[]) {
    this.vibration.vibrate(pattern);
  }

  private pwaVibrate( pattern ) {
    if("vibrate" in (<any>window).navigator) {
      window.navigator.vibrate( pattern );
    }
  }


}
