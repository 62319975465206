import {Component, Input} from '@angular/core';
import {ModalController, NavController, PopoverController} from "@ionic/angular";
import {TribeDetailsPage} from "../../pages/tribe-details/tribe-details.page";
import {ReportService} from "../../services/report.service";
import {TribeService} from "../../services/data/tribe.service";

@Component({
  selector: 'app-menu-popover',
  template: `
    <div class = "menu-item" (click) = "openDetails()">
      <ion-icon class = "menu-item-icon" name = "information-circle-outline"></ion-icon>
      <div class = "menu-item-text">Tribe details</div>
      <div *ngIf = "hasReplaceable" class = "w-[10px] h-[10px] rounded-full ml-auto shrink-0 bg-error"></div>
    </div>
    <div class = "menu-item" (click) = "leave()">
      <ion-icon class = "menu-item-icon" name="exit-outline"></ion-icon>
      <div class = "menu-item-text">Leave group</div>
    </div>
    <div class = "menu-item" (click) = "report()">
      <ion-icon class = "menu-item-icon" name = "flag-outline"></ion-icon>
      <div class = "menu-item-text">Report a user</div>
    </div>
  `,
  styleUrls: ['./menu-popover.component.scss'],
})
export class TribeDetailsMenuComponent {
  @Input() tribe: any;

  public hasReplaceable: boolean = false;

  constructor(private tribeService: TribeService,
              private modalCtrl: ModalController,
              private reportService: ReportService,
              private popoverCtrl: PopoverController) {}

  ngOnInit() {
    this.hasReplaceable = this.tribeService.hasReplaceableUsers(this.tribe);
  }

  async openDetails() {
    this.popoverCtrl.dismiss();
    try {
      const modal = await this.modalCtrl.create({
        component: TribeDetailsPage,
        componentProps: {
          tribe: this.tribe,
          tribeId: this.tribe.id
        }
      });
      await modal.present();
    } catch ( err ) {
      console.log( err );
    }
  }

  leave() {
    this.popoverCtrl.dismiss();
    this.tribeService.startLeavingFlow(this.tribe);
  }

  hasReplaceableUsers(): boolean {
    return this.tribeService.hasReplaceableUsers(this.tribe);
  }

  report() {
    this.popoverCtrl.dismiss();
    this.reportService.reportTribe(this.tribe)
  }
}
