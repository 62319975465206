import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {IOpeningPageContent} from "../../shared/interfaces";

@Component({
  selector: 'download',
  template: `
    <ion-content>
      <div class = "close-button" (click) = "close()">
        <ion-icon name = "close"></ion-icon>
      </div>
      <opening-content [state] = "state"></opening-content>
    </ion-content>
  `,
  styleUrls: ['./download.page.scss'],
})
export class DownloadPage implements OnInit {

  public state : IOpeningPageContent = {
    title: "Download the mobile app",
    subtitle: "Aim your mobile phone's camera at the QR code above and wait for a link to appear on your screen.",
    image: "./assets/img/qr-code.png"
  }

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
