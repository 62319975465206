import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { LottieModule } from "ngx-lottie";
import { PotentialMatchesShortModule } from "../potential-matches-short/potential-matches-short.module";
import { FanFailedSearchComponent } from "./fan-failed-search.component";

@NgModule({
  declarations: [FanFailedSearchComponent],
  imports: [
    LottieModule,
    CommonModule,
    PotentialMatchesShortModule
  ],
  exports: [FanFailedSearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FanFailedSearchModule {}