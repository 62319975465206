import {Component, EventEmitter, Input, Output} from "@angular/core";

export interface CheckmarkButton {
  text: string;
  titleIcon?: CheckmarkButtonTitleIcon;
  chip?: CheckmarkButtonChip;
  type?: string;
}

interface CheckmarkButtonTitleIcon {
  name: string;
  cssClass: string;
}
interface CheckmarkButtonChip {
  icon: string;
  text: string;
  activatedCssClass?: string;
  activatedIconCssClass?: string;
}

@Component({
  selector: "checkmark-buttons-list",
  template: `
    <div class = "checkmark-buttons-list">
      <checkmark-button *ngFor = "let button of buttons"
                        [selected] = "selected"
                        [button] = "button"
                        (onSelected) = "setSelected($event)"></checkmark-button>
    </div>
  `,
  styleUrls: ["checkmark-buttons-list.component.scss"]
})
export class CheckmarkButtonsListComponent {
  @Input("buttons") buttons: CheckmarkButton[];
  @Output("onChange") onChange = new EventEmitter<CheckmarkButton>();

  public selected: any;

  constructor() {
  }

  ngOnInit() {
    this.selected = {}
  }

  setSelected(selected: CheckmarkButton): void {
    this.selected = selected;
    this.emit(this.selected);
  }

  emit(event: CheckmarkButton): void {
    this.onChange.emit(event);
  }
}
