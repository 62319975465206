import { NgModule } from "@angular/core";
import { SideMenuComponent } from "./side-menu.component";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { PipesModule } from "../../pipes/pipes.module";
import { FormsModule } from "@angular/forms";
import { IpService } from "src/app/services/ip.service";

@NgModule({
  declarations: [SideMenuComponent],
  imports: [IonicModule, FormsModule, CommonModule, PipesModule],
  exports: [SideMenuComponent],
})
export class SideMenuModule {}
