import { Injectable, NgZone } from '@angular/core';
import { ApiService } from './api.service';
import {
  AlertController,
  ModalController,
  NavController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { Storage } from '@ionic/storage'
import { Saveable } from '../../shared/structure/saveable';
import { Subject } from 'rxjs/Subject';
import { Config } from '../config/config';
import { UtilsService } from '../utils.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { TribesService } from './tribes.service';
import { PusherService } from '../pusher.service';
import { PushNotification } from '../push-notification/push-notification';
import { DispatcherService} from '../dispatcher.service';
import { CrashReportService } from '../performance/crash-report.service';
import { IconStatusService} from "../icon-status.service";
import { FeedbackService} from "../popups/feedback.service";
import { RouteTrackerService } from "../route-tracker.service";

import { TribeUser } from "../../shared/models/tribe-user";

import * as _ from "lodash";
import {UserService} from "./user.service";
import {SentryService} from "../performance/sentry.service";
import {takeUntil, tap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {logc} from "../../shared/helpers/log";
import {ReportService} from "../report.service";
import {from, Observable, of} from "rxjs";
import { CURRENT_USER_NEW_MESSAGE_RECEIVING_GAP, CURRENT_USER_NEW_MESSAGE_RECEIVING_TIMEOUT } from 'src/app/shared/constants/constants';
import { userExpiresSoon } from 'src/app/components/extend-expiry-section/extend-expiry-section.component';
import { UserTribeStatus } from 'src/app/shared/enums/user-tribe-status.enum';
import { TribeStatus } from 'src/app/shared/enums/tribe-status.enum';

@Injectable({
  providedIn: 'root',
})
export class TribeService extends Saveable {
  defaultConfig: any = {};
  tribeId: number = null;
  key: string = 'tribe_id';
  mostRecentMessageId: number = -1;
  private newMessageSource = new Subject();
  private tribeErrorSource = new Subject();
  public firstMessageSource = new BehaviorSubject(null);
  public onNewMessage: any;
  public onTribeError: any;
  public onFirstMessage: any;

  public newMemberSource          = new Subject();
  public membersCheckSource       = new Subject();
  public memberRemovedSource      = new Subject();
  public typingSource             = new Subject();
  public messageUpdatingSource    = new Subject();
  public messageReactionSource   = new Subject();
  public messageReactionRemovedSource   = new Subject();

  private currentUserMessageSentAt: any = null;

  public onMemberAdded: any;
  public onMembersChecked: any;
  public onMemberRemoved: any;
  public onUserTyping: any;
  public onConnectionChanged: any;
  public onMessageUpdated: any;
  public onMessageReaction: any;
  public onMessageReactionRemoved: any;

  private actionState: string = '';

  private modal: any;

  private tribesData: any = {};
  private messageDeliveryTimeout = null;

  public memberStates = ['needs_review', 'accepted'];

  constructor(public api: ApiService,
              public navCtrl: NavController,
              public config: Config,
              public utils: UtilsService,
              public alertCtrl: AlertController,
              public analyticsService: AnalyticsService,
              public crashReportService: CrashReportService,
              public storage: Storage,
              private toastCtrl: ToastController,
              private pusherService: PusherService,
              public pushNotification: PushNotification,
              private userService: UserService,
              public ngZone: NgZone,
              private modalCtrl: ModalController,
              private routeTrackerService: RouteTrackerService,
              private feedbackService: FeedbackService,
              private popupCtrl: PopoverController,
              private tribesService: TribesService,
              private reportService: ReportService,
              private iconStatusService: IconStatusService,
              private dispatcherService: DispatcherService,
              private errorTrackingService: SentryService) {
    super(storage);
    if(this.config.isDev || this.config.isStaging) {
      (<any> window).tribeService = this;
    }
    this.onTribeError = this.tribeErrorSource.asObservable();
    this.onNewMessage = this.newMessageSource.asObservable();
    this.onFirstMessage = this.firstMessageSource.asObservable();

    this.onMemberAdded = this.newMemberSource.asObservable();
    this.onMembersChecked = this.membersCheckSource.asObservable();
    this.onMemberRemoved = this.memberRemovedSource.asObservable();
    this.onUserTyping = this.typingSource.asObservable();
    this.onMessageUpdated = this.messageUpdatingSource.asObservable();
    this.onMessageReaction = this.messageReactionSource.asObservable();
    this.onMessageReactionRemoved = this.messageReactionRemovedSource.asObservable();

    this.tribesService
      .onNewMessage
      .subscribe(({ id, tribe_user }) => {
        if(this.config.get("id") === tribe_user?.id) {
          if(this.messageDeliveryTimeout) {
            clearTimeout(this.messageDeliveryTimeout);
          }
          if(!this.currentUserMessageSentAt) return;
          const receivingTime = performance.now() - this.currentUserMessageSentAt;
          const tooSlow = receivingTime > CURRENT_USER_NEW_MESSAGE_RECEIVING_GAP;
          this.currentUserMessageSentAt = null;
          if(tooSlow) {
            logc.error(`Message took more than ${ CURRENT_USER_NEW_MESSAGE_RECEIVING_GAP } to be received from server`);
            logc.error("Time: ", receivingTime.toFixed(2));
            this.analyticsService.trackEvent({ key: "slow_message_delivery", value: 1, tribe_id: id });

            const msg = `Current user message delivery took more than ${ CURRENT_USER_NEW_MESSAGE_RECEIVING_GAP }ms`;
            const tags = { klass: "TribeService", func: "onNewMessage()" };
            const extras = { data: this.utils.getPerformanceInfo({ receivingTime }) };
            this.errorTrackingService.sendMessage(msg, tags, extras);
          }
        }
      });
  }

  loadFromCache( tribeId ) {
    return new Promise((resolve, reject) => {
      this.getTribe( tribeId ).then((localTribe: any) => {
        const tribe = _.cloneDeep(localTribe) || {};
        // this.tribesData[tribeId] = localTribe;
        if(!_.isEmpty( tribe ) && tribe.messages.length > 0) {
          this.mostRecentMessageId = Math.max( ...tribe.messages.map(msg => msg.id) );
        } else {
          this.mostRecentMessageId = -1;
        }
        resolve(localTribe);
      });
    });
  }

   _loadFromCache( tribeId ): Promise<Observable<any>> {
     return new Promise((resolve, reject) => {
       this.getTribe( tribeId ).then((localTribe: any) => {
         const tribe = _.cloneDeep(localTribe) || {};
         // this.tribesData[tribeId] = localTribe;
         if(!_.isEmpty( tribe ) && tribe.messages.length > 0) {
           this.mostRecentMessageId = Math.max( ...tribe.messages.map(msg => msg.id) );
         } else {
           this.mostRecentMessageId = -1;
         }
         resolve(of({
           cacheTooOld: false,
           tribe,
           missedMessages: []
         }));
       });
     });
  }

  mapReactions(messages) {
    let obj = {};
    messages.forEach((msg, index) => {
      obj[index] = msg?.meta_data?.reactions;
    })
    return obj;
  }

  hasNewReactions(oldMessages, newMessages): boolean {
    const oldReactions = this.mapReactions(oldMessages);
    const newReactions = this.mapReactions(newMessages);
    return !_.isEqual(oldReactions, newReactions);
  }

  loadRecent(tribeId) {
    return new Promise((resolve, reject) => {
      this.api.get('tribes/' + tribeId, {}).then(
        async (data: any) => {
          // this.tribesData[tribeId] = data;
          //Is cache too old
          let cacheTooOld = true;
          let newMessages = [];
          // Override cache with recent data
          if(data.tribe_user) {
            data.tribe_user.chat_seen_at = new Date().toUTCString();
          }
          this.setTribe(data);
          if(this.mostRecentMessageId > -1) {
            let newIds = data.messages.map((m) => m.id);
            //If our most recent Id is not in the recent messages, cache is too old
            if(newIds.includes(this.mostRecentMessageId)){
              cacheTooOld = false;
              newMessages = data.messages.filter(e => e.id > this.mostRecentMessageId);
            }

            if(this.hasNewReactions((await this.getTribe(tribeId)).messages, data.messages)) {
              cacheTooOld = true;
            }
          }

          if(data && data.messages && data.messages.length > 0){
            this.mostRecentMessageId = data.messages[0].id;
          }

          logc.green("cacheTooOld: ", cacheTooOld);
          this.tribesService.tribeLoadedSource.next(data);
          this.tribesService.actionTaken(tribeId);
          resolve({
            tribe: data,
            cacheTooOld: cacheTooOld,
            missedMessages: newMessages
          });
        }, err => reject(err));
    });
  }

  _loadRecent(tribeId): Promise<Observable<any>> {
    return new Promise((resolve, reject) => {
      this.api.get('tribes/' + tribeId, {}).then(
        async (data: any) => {
          // this.tribesData[tribeId] = data;
          //Is cache too old
          let cacheTooOld = true;
          let newMessages = [];
          // Override cache with recent data
          if(data.tribe_user) {
            data.tribe_user.chat_seen_at = new Date().toUTCString();
          }
          this.setTribe(data);
          if(this.mostRecentMessageId > -1) {
            let newIds = data.messages.map((m) => m.id);
            //If our most recent Id is not in the recent messages, cache is too old
            if(newIds.includes(this.mostRecentMessageId)){
              cacheTooOld = false;
              newMessages = data.messages.filter(e => e.id > this.mostRecentMessageId);
            }

            if(this.hasNewReactions((await this.getTribe(tribeId)).messages, data.messages)) {
              cacheTooOld = true;
            }
          }

          if(data && data.messages && data.messages.length > 0){
            this.mostRecentMessageId = data.messages[0].id;
          }

          logc.green("cacheTooOld: ", cacheTooOld);

          this.tribesService.actionTaken(tribeId);
          resolve(of({
            tribe: data,
            cacheTooOld: cacheTooOld,
            missedMessages: newMessages
          }));
        }, err => reject(err));
    });
  }

  hasReplaceableUsers({ users }): boolean {
    return users?.some(user => user?.replaceable);
  }

  setTribe(tribe) {
    return this.storage.set(`tribe_${tribe.id}`, this.stringifyData(tribe));
  }

  async getTribe( tribeId ){
    return JSON.parse(await this.storage.get(`tribe_${tribeId}`));
  }

  isU2(tribe: any): boolean {
    const currentUser = tribe.tribe_user;
    const isU2 = currentUser.status == 'accepted'
      && !tribe.is_initiator
      && tribe.users.some(u => u.status != 'accepted');
    return isU2;
  }

  send(tribeId, { id = null, content = "", reply_to_id = null }): Promise<void> {
    return new Promise(async (resolve, reject) => {

      let params: any = { content, id };
      if(reply_to_id) {
        params = { ...params, reply_to_id }
      }

      if(!tribeId){
        this.sendErrorReport('send(message)');
        reject();
      }

      this.messageDeliveryTimeout = setTimeout(() => {
        this.analyticsService.trackEvent({ key: "message_delivery_timeout", value: 1, tribe_id: id });
        const msg = "Current user message not delievered";
        const tags = { klass: "TribeService", func: "send()" };
        const extras = { data: this.utils.getPerformanceInfo() };
        this.errorTrackingService.sendMessage(msg, tags, extras);
      }, CURRENT_USER_NEW_MESSAGE_RECEIVING_TIMEOUT);

      console.log(`--- sending message: ${ { id, content, reply_to_id } } to tribe: ${ tribeId }`);
      this.currentUserMessageSentAt = performance.now();
      this.api.post('tribes/' + tribeId + '/messages', params).then( _=> {
        this.save();
        resolve();
      }, reject);
    });
  }

  sendErrorReport(functionName){
    this.crashReportService.logException('Missing Tribe id', this.crashReportService.formReport({
      className: "Tribe",
      functionName: functionName,
      reason: 'missing Tribe id'
    }));
    this.navCtrl.navigateForward(`tabs/tribes`);
  }

  deleteMessage(tribeId, message): Promise<void> {
    return new Promise((resolve, reject) => {
      let params = { message_id: message.id, tribe_id: tribeId };
      try {
        if(!tribeId){
          this.sendErrorReport('delete(message)');
          reject();
        }
        this.api.delete('tribes/' + tribeId + '/messages', params);
        this.analyticsService.trackEvent({
          key: 'message_deleted',
          value: 1,
          tribe_id: tribeId,
          message_id: message.id,
          is_initiator: this.tribesData[tribeId]?.is_initiator,
        });
        resolve();
      } catch ( err ) {
        reject(new Error(err));
      }
    })
  }

  isCurrentChat(id) {
    const m = location.pathname.match(/\/tribes\/(?<tribeId>[\d]+)\/chat/);
    return (m && (id.toString() == m.groups.tribeId));
  }

  init(){
    return super.init();
  }

  async findSwap(tribeId: number) {
    await this.utils.showLoading('Matching');
    return new Promise((resolve, reject) => {
      this.api.post('tribes/' + tribeId + '/swap', {}).then(data => {
        resolve(data);
        this.utils.doneLoading();
      }).catch(err => reject(err));
    })
  }

  hasUserMessages(tribe: any) {
    return tribe?.sent_messages_count > 0;
  }

  getConversationStarters(tribe_id){
    return new Promise((resolve, reject) => {
      this.api.get('tribes/' + tribe_id + '/conversation_starters', {}).then( (resp: any) => {
        resolve(resp);
      }, reject);
    });
  }

  getMoreMessages(tribeId, limit, offset){
    return new Promise((resolve, reject) => {
      this.api.get('tribes/' + tribeId + '/messages', {
        limit: limit,
        offset: offset
      }).then( (resp: any) => {
        resolve(resp);
      }, reject);
    });
  }

  async _leaveTribe({ tribe, reason, reportedUsers, reportReason, details }) {
    try {
      const tribeId = tribe.id;
      if(reportedUsers) {
        this.delete({ tribeId, reason, reportedUsers, details });
        this.reportService.sendAnalytics(tribe, reportedUsers, reportReason, details);
      } else {
        this.delete({ tribeId, reason });
      }
      await this.utils.showGenericMessage("You left the group.");

      let analyticsEvent = {
        key: 'left_tribe',
        value: 1,
        tribe_id: tribe.id,
        is_initiator: tribe.is_initiator,
        reason: reason
      };
      if(details) {
        analyticsEvent = {...analyticsEvent, ...{ reason: 'Other', details: details}}
      }
      this.analyticsService.trackEvent(analyticsEvent);
      if(await this.modalCtrl.getTop()) {
        this.modalCtrl.dismiss();
      }
      await this.navCtrl.navigateBack('tabs/tribes');
    } catch(e) {
      logc.error("Leaving tribe attempt error: ", e);
      this.utils.showGenericError({
        msg: "Unable to leave group, please try again later.",
        key: "leaving_group"
      });
    }
  }

  async leaveTribe(tribe, reason, details = '') {
    try {
      const tribeId = tribe.id;
      await this.delete({ tribeId, reason });
      await this.utils.showGenericMessage("You left the group.");
      let analyticsEvent = {
        key: 'left_tribe',
        value: 1,
        tribe_id: tribe.id,
        is_initiator: tribe.is_initiator,
        reason: reason
      };
      if(details) {
        analyticsEvent = {...analyticsEvent, ...{ reason: 'Other', details: details}}
      }
      this.analyticsService.trackEvent(analyticsEvent);
    } catch ( err ) {
      logc.error("Leaving tribe attempt error: ", err);
      this.utils.showGenericError({
        msg: "Unable to leave group, please try again later.",
        key: "leaving_group"
      });
    }
  }

  delete({ 
    tribeId, 
    reason = '', 
    reportedUsers = [], 
    details = '' }
  ): Promise<void> {
    // console.log('reportedUsers: ', reportedUsers);
    return new Promise((resolve, reject) => {
      this.api.delete('tribes/' + tribeId, {
        reason: reason,
        reported_users: reportedUsers.join(','),
        details: details
      }).then(_ => {
        this.deleteKey();
        this.closeChat(tribeId);
        this.tribesService.tribeRemoved(tribeId);
        resolve();
      }, reject);
    });
  }

  newMessage(data) {
    const { tribe_id: tribeId } = data;
    // logc.aquamarine("New message: ", data);
    if(data.message.user_id > 0) {
      this.tribesService.data.find(tribe => tribe.id === data.tribe_id).has_user_messages = true;
      this.tribesService.save();
    }
    const message = data.message;
    let currentTribe = this.tribesData[tribeId];
    if(!_.isEmpty(currentTribe) && !!currentTribe.messages) {
      currentTribe.messages.unshift(message);
    }
    if(currentTribe) {
      currentTribe.unread_messages_count = 0;
    }
    this.save();
    this.newMessageSource.next(message)
  }

  channels: any[] = [];

  channel = null;
  privateChannel = null;
  openChat(tribeId, debugId): Promise<void> {
    console.log('--- OPEN CHAT ---', tribeId, debugId);
    return new Promise(async (resolve, reject) => {
      try {
        // await this.updateChatSeen(this.tribesService.data?.find(t => t?.id === tribeId));

        const pusher:any = await this.pusherService.getInstance();

        if(this.channels.find(channel => channel.tribeId === tribeId)) {
          this.channels.splice(this.channels.findIndex(ch => ch.tribeId === tribeId), 1);
        }

        this.channels.push({
          tribeId: tribeId,
          presence: pusher.subscribe('presence-tribe_channel_' + tribeId),
          private: pusher.subscribe('private-tribe_channel_' + tribeId)
        });
        console.log('___PUSHER CHANNELS___: ', this.channels.map(c => c.tribeId));

        let channel = this.channels.find(channel => channel.tribeId === tribeId);
        console.log(`___CURRENT CHANNEL (${ tribeId }, debugId: ${ debugId }): `, channel.tribeId);
        if(channel) {
          channel.presence.bind('pusher:subscription_succeeded', this.onPusherSubscriptionSuccess, this);
          channel.presence.bind('pusher:member_added', this.onPusherMemberAdded, this);
          channel.presence.bind('pusher:member_removed', this.onPusherMemberRemoved, this);
          channel.private.bind('client-typing', this.userTypingHandler, this);
          channel.private.bind('client-updating-messages', this.messageUpdatedHandler, this);
        }
        console.info("--- tribe.service.ts openChat() this.channels: ", this.channels);
        resolve();
      } catch (err) {
        this.closeChat(tribeId, debugId);
        console.log(err);
        console.log('could not connect to the chat room');
        reject(err);
      }
    });
  }

  async closeChat(tribeId, debugId = null) {
    console.log('--- CLOSE CHAT ---', tribeId, debugId);
    const pusher:any = await this.pusherService.getInstance();

    let channel = this.channels.find(channel => channel.tribeId == tribeId);
    if(channel) {
      pusher.unsubscribe('presence-tribe_channel_' + tribeId);
      pusher.unsubscribe('private-tribe_channel_' + tribeId);
    }
    this.channels.splice(this.channels.findIndex(c => c.tribeId == tribeId), 1);
    delete this.tribesData[tribeId];
    console.info("--- tribe.service.ts closeChat() this.channels: ", this.channels);
  }

  userTypingHandler( data ) {
    this.typingSource.next(data);
  }

  messageUpdatedHandler(data) {
    this.messageUpdatingSource.next(data);
  }

  onPusherMemberAdded( member ) {
    console.log('--- tribe.service.ts onPusherMemberAdded member: ', member);
    this.newMemberSource.next( member );
  }

  onPusherMemberRemoved( member ) {
    console.log('--- tribe.service.ts onPusherMemberRemoved member: ', member);
    this.memberRemovedSource.next( member );
  }

  onPusherSubscriptionSuccess( members ) {
    console.log('--- tribe.service.ts onPusherSubscriptionSuccess members: ', members);
    this.membersCheckSource.next( members );
  }

  async updateChatSeen(tribe) {
    if(tribe) {
      tribe.tribe_user.chat_seen_at = new Date();
      await this.save();
    }
  }

  canDirectMessage(targetUser: TribeUser): boolean {
    return this.memberStates.includes( targetUser.status );
  }

  replaceUser(replaceableId, tribeId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.api.post('tribes/replace_user', {
          user_id: replaceableId,
          tribe_id: tribeId
        });
        console.log('-- tribe.service.ts replaceUser(replaceableId, tribeId): ', response);
        resolve( response );
      } catch( err ) {
        reject( err );
      }
    });
  }

  requestUserRemoval(requesterId, removeeId, tribeId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.api.post('tribes/remove_request', {
          user_id: requesterId,
          removed_user_id: removeeId,
          tribe_id: tribeId
        });
        console.log('-- tribe.service.ts requestUserRemoval(): ', response);
        resolve( response );
      } catch( err ) {
        reject( err );
      }
    });
  }

  removeUser(userId, tribeId) {
    return new Promise((resolve, reject) =>{
        this.api.post('tribes/remove_user', {
          user_id: userId,
          tribe_id: tribeId
        }).then( async (res : any) => {
          resolve(res);
        }, err =>{
          reject(err);
        });
    });
  }

  denyRemoval({ userId, tribeId }) {
    return new Promise((resolve, reject) =>{
      this.api.delete('tribes/remove_request', {
        user_id: userId,
        tribe_id: tribeId
      }).then( async (res : any) => {
        resolve(res);
      }, err =>{
        reject(err);
      });
    });
  }

  getUser(userId) {
    return this?.data?.users.find( u => u.id == userId);
  }

  async openReportModal( reportedUser: TribeUser, tribe ) {
    try {
      this.modal = await this.feedbackService.createModal('report');
      this.modal
        .onClick
        .subscribe(reason => {
          this.dispatchReportReason(reason, reportedUser, tribe)
        });
    } catch ( err ) {
      console.log( err );
    }
  }

  async dispatchReportReason(reason: string, reportedUser: TribeUser, tribe) {
    if(!reason) return;

    if(reason === 'Other') {
      this.openReportFeedbackForm( reportedUser, tribe );
    } else {
      this.sendReport( reportedUser, tribe );
      this.analyticsService.trackEvent({
        key: 'reported_user',
        value: 1,
        reason: this.utils.underscoreCase(reason),
        reported_users_ids: [ reportedUser.id ],
        tribe_id: tribe.id,
        is_initiator: tribe.is_initiator
      })
    }
    this.modal.close();
  }

  private async sendReport( reportedUser: TribeUser, tribe ) {
    try {
      if(tribe.id) {
        await this.tribesService.reportUsers( tribe.id, [ reportedUser.id ] );
      } else {
        await this.userService.blockAndReport(reportedUser.id);
      }

      const toast = await this.toastCtrl.create({
        message: "User was reported!",
        duration: 3000
      });
      await toast.present();
    } catch ( err ) {
      console.log(err);
    } finally {
      this.actionState = null;
    }
  }

  private async openReportFeedbackForm( reportedUser: TribeUser, tribe ) {
    this.modal.close();
    const alert = await this.alertCtrl.create(
      {
        header: "Reporting User",
        cssClass: 'low-top',
        inputs: [
          {
            name: 'reason',
            placeholder: 'Please tell us why...'
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: "Report",
            handler: async data => {
              this.dispatchReportReason(data.reason, reportedUser, tribe);
              this.analyticsService.trackEvent({
                key: 'reported_user',
                value: 1,
                reported_users_ids: [ reportedUser.id ],
                reason: "other",
                details: data.reason,
                tribe_id: tribe.id,
                is_initiator: tribe.is_initiator
              })
            }
          }
        ]
      });
    await alert.present();
  }

  async showProfileOptions({ sender, event, source, tribe }) {
    const otherUser = tribe.users?.find(user => user.id !== sender.id);
    let props = {
      options: [
        {
          text: "Report",
          icon: 'flag',
          attention: false,
          handler: () => this.openReportModal( sender, tribe ),
          plusBadge: false
        }
      ]
    };

    if(source === 'chat') {
      props.options.splice(0,0,{
        icon: 'person-circle',
        text: 'View Profile',
        attention: false,
        handler: async () => {
          this.dispatcherService.newModalSource.next({
            modalName: "profile",
            options: {
              isEditable: false,
              userId: sender.id,
              profileId: sender.profile_id,
              user: sender,
              tribe: tribe,
              tribeId: tribe.id,
              fromHighlights: true,
              notificationName: this.iconStatusService.getIconStatusName( sender )
            }
          })
        },
        plusBadge: false
      },)
    }

    if(tribe.status === 'formed') {
      props.options.splice(1, 0, {
        icon: 'person-remove',
        text: 'Remove',
        attention: false,
        handler: async () => {
          await this.popupCtrl.dismiss();
          this.dispatcherService.newPopupSource.next({
            popupName: 'removalRequestPopup',
            options: {
              requester: tribe.tribe_user,
              removee: sender,
              otherUser: otherUser,
              tribeId: tribe.id
            }
          })
        },
        plusBadge: false
      })
    }

    if(this.canDirectMessage( sender )){
      props.options.splice(1,0,{
        icon: 'send',
        attention: false,
        text: 'Direct Message',
        handler: (): any => this.initDirectMessage( tribe.id, sender ),
        plusBadge: !this.config.isPlus()
      });
    }

    if(sender.replaceable) {
      props.options.splice(1, 0, {
        icon: 'swap-horizontal',
        text: 'Replace',
        attention: true,
        handler: async (): Promise<any> => {
          await this.popupCtrl.dismiss();
          this.dispatcherService.newPopupSource.next({
            popupName: "singleReplaceablePopup",
            options: {
              replaceableUser: sender,
              tribeId: tribe.id
            }
          });
          this.dispatcherService.statusBarChangeSource.next('hide');
        },
        plusBadge: false
      });
    }

    return this.utils.showOptionsPopup(
      event, 
      props, 
      `message-options-popover ${ this.config.isPlus() ? "" : "wide" } z-index-35000`
    );
  }

  async initDirectMessage( currentTribeId, sender: TribeUser ){
    let existingTribe = this.tribesService.getDirectMessageTribe( sender );
    if(existingTribe) {
      if( existingTribe.id == currentTribeId ) return;

      await this.navCtrl.navigateBack(`tabs/tribes`);
      setTimeout(async () => {
        await this.navCtrl.navigateForward(`tribes/${existingTribe.id}/chat`)
      }, 300);

      if(await this.modalCtrl.getTop()) {
        this.modalCtrl.dismiss();
      }
    } else {
      if(this.config.isPlus()) {
        this.askToDirectMessage( sender );
      } else {
        this.dispatcherService.newModalSource.next({
          modalName: 'upselling',
          source: `tribes/${ currentTribeId }/chat`,
          reason: "send-direct-message"
        });
      }
    }
  }

  async askToDirectMessage( user ) {
    try {
      const alert = await this.alertCtrl.create({
        header: "Message privately?",
        message: "This will create a direct chat with them. Your Group will remain accessible.",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: 'cancel-alert-btn'
          },
          {
            text: "Message " + user.first_name,
            handler: async () => {
              this.navCtrl.navigateForward(`tabs/tribes`);
              this.utils.showLoading('Creating chat...');
              const response: any = await this.tribesService.directMessage( user );
              if(await this.modalCtrl.getTop()) {
                this.modalCtrl.dismiss();
              }
              this.utils.doneLoading();
              await this.navCtrl.navigateForward(`tribes/${ response.id }/chat`);
            }
          }
        ]
      });
      await alert.present();
    } catch ( err ) {
      console.log( err );
    }
  }

  modalOptions;
  async startLeavingFlow(tribe, callback = () => {}): Promise<void> {
    try {
      this.modalOptions = await this.feedbackService.createModal("leave");
      this.modalOptions.onClick.subscribe(async (reason) => {
        this.modalOptions.close();
        logc.info("reason: ", reason);
        let report: any = {};
        if(reason == "Report a user") {
          report = await this.reportService.collectReport(tribe);
        }
        if(reason == "Other") {
          reason = await this.feedbackService.showInputAlert({
            header: "Leaving group",
            placeholder: "Please tell us why...",
            buttonText: "Leave"
          })
        }
        const { reportedUsersIds: reportedUsers, reason: reportReason, details } = report;
        await this._leaveTribe({
          tribe, reason, reportedUsers, reportReason, details
        });
        callback();
      })
    } catch(e) {

    }
  }

  getExpiries({ users, status }): any[] {
    if(status === TribeStatus.Expired) return [];

    return _.cloneDeep(users)
      .filter(userExpiresSoon)
      .filter(({ status }) => status != UserTribeStatus.Accepted)
      .filter(user => !user?.on_extended_invite);
  }
}
