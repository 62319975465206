import { FormControl } from "@angular/forms";
import { PERMITTED_AGE } from "../shared/constants/constants";

export class AgeValidator {
  static isPermitted(control: FormControl) {
    if (control.value) {
      let d = new Date(control.value);
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
      let dateOfPermittedAge = new Date(year + PERMITTED_AGE, month, day);

      let dateOfPermittedAgeHasPassed = dateOfPermittedAge < new Date();

      if (!dateOfPermittedAgeHasPassed) {
        return {
          ageError: 'Min. 13 years old'
        };
      }
    }
  }
}
