import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {UtilsService} from "../../services/utils.service";
import {MatchesOptionsComponent} from "../matches-options/matches-options.component";
import {ModalController, PopoverController} from "@ionic/angular";
import {MatchesService} from "../../services/matches.service";
import {ProfilePopupComponent} from "../profile-popups/profile-popup";
import {Config} from "../../services/config/config";
import {RECOMMENDED_MATCHES_LENGTH} from "../../shared/constants/matches";
import {environment} from "../../../environments/environment";
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface FailedStatus {
  icon: string;
  title: string;
  description: string;
}

@Component({
  selector: 'match-card',
  template: `
    <div class = "card-container">
      <div #hint *ngIf = "index === 0 && !matchesTipShown && interactive" class = "blue-tooltip">
        {{ tooltipText }}
      <div class = "arrow-down"></div>
      </div>
      <div *ngIf = "isDev" class = "test-info">#{{ index }} - {{ user.score - user.details.location.distance }}</div>
      <div *ngIf = "isPlus && !fromFailedSearch" (click) = "openOptions($event)" class = "more-icon">&#8942;</div>
      <div class="match-card-blur" [class.no-details] = "!user.details.work_title">
        <div class = "triangle"></div>
        <div class = "card-gradient"></div>
        <div *ngIf = "interactive" 
             class = "match-distance">
          {{user.details?.location.distance | distance}} {{defaultUnitSystem}} away
        </div>
        <img class = "blur-image" src="{{user.picture}}" alt = "User avatar background">
        <div class = "match-card-distance"></div>
      </div>
      <div (click) = "openProfile()" 
           [class.hidden-text] = "!isPlus || fromFailedSearch" 
           class="match-card-info">
        <div class = "match-card-avatar-wrapper relative">
          <div class = "match-card-avatar-container">
            <img [class.blur] = "!isPlus || fromFailedSearch" 
                 class = "avatar" 
                 [src] = "user.picture" 
                 alt = "User avatar">
          </div>
          <div *ngIf = "matchingFailureReason" class = "
              border-surface rounded-full border-[3px] w-[35px] h-[35px] 
              bg-error-light-1 flex justify-center 
              items-center absolute z-20 bottom-0 right-0
            ">
              <ion-icon class = "text-error-dark-3 text-large stroke-error-dark-3" 
                        [name] = "matchingFailureIcons[matchingFailureReason]"></ion-icon>
            </div>
        </div>
        <div class="title-section">
          <div class="match-card-title">{{user.first_name | firstname}}, </div>
          <div class="match-card-age">{{getAge(user.birthday)}}</div>
        </div>
        <div *ngIf = "user.details?.work_organization || user.details?.work_title" class="match-card-work">
          {{user.details?.work_organization}}
          {{user.details?.work_organization && user.details?.work_title ? ', ' : ''}}
          {{user.details?.work_title}}
        </div>
        <div *ngIf = "user.details?.school" class="match-card-school">{{user.details?.school}}</div>
      </div>
    </div>

  `,
  styleUrls: ['./match-card.component.scss'],
})
export class MatchCardComponent implements OnInit {
  @Input('user') user;
  @Input("index") index: number;
  @Input('section') section: string;
  @Input("source") source: any;
  @Input("matchingType") matchingType: string = "default";
  @Input("interactive") interactive: boolean = true;

  @Output('done') done = new EventEmitter();

  @ViewChild("hint") hint: ElementRef;

  public matchingFailureIcons = {
    you_rejected: "thumb-down",
    previously_grouped: "ban",
    daily_limit: "too-busy",
    taking_a_break: "time-outline",
    unreachable: "notifications-off"
  }

  public tooltipText: string = null;
  public tooltipTextStates: { [key:string]: string } = {
    available: "Tap to see your compatibility",
    unavailable: "Tap to see why you can't match"
  }
  
  public matchingFailureReason: string = null;
  public defaultUnitSystem: string;
  public isPlus: boolean = false;
  public matchesTipShown: boolean = false;
  public isDev: boolean = environment.name == 'development';
  public fromFailedSearch: boolean = false;
  public fromMatchesPage: boolean = false;

  private unsubscribe: Subject<any> = new Subject();

  constructor(private utils: UtilsService,
              private popoverCtrl: PopoverController,
              private matchesService: MatchesService,
              private dispatcherService: DispatcherService,
              public config: Config,
              private renderer: Renderer2,
              private el: ViewContainerRef,
              private modalCtrl: ModalController) { }

  ngOnInit() {
    this.isPlus = this.config.isPlus();

    this.dispatcherService
      .onPlusSubscriptionChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.isPlus = this.config.isPlus();
      })
    
    this.fromFailedSearch = this.source === "failedSearch";
    this.fromMatchesPage = this.source === "matchesPage";

    this.matchingFailureReason = this.user?.failed_match?.reason;
    
    if(this.matchingFailureReason) {
      this.tooltipText = this.tooltipTextStates.unavailable;
    } else {
      this.tooltipText = this.tooltipTextStates.available;
    }

    this.matchesTipShown = this.config.getFlag("matchesTipShown");

    if(this.config.isDev) {
      this.fakeDetails();
    }
    if(this.user.details.location.distance) {
      this.user.details.location.distance = this.utils.getDistanceForUnits(this.user.details?.location.distance);
    }
    this.defaultUnitSystem = (this.config.data.matchPreferences.defaultUnitSystem) ? this.config.data.matchPreferences.defaultUnitSystem : this.config.defaultConfig.defaultUnitSystem;
  }

  fakeDetails() {
    if(Math.random() > 0.5) {
      this.user.details.work_organization = 'We3';
      this.user.details.work_title = "Founder";
      this.user.details.school = "School of Life";
    }
  }

  ngAfterViewInit() {
    this.done.emit(true);
  }

  async openOptions(event) {
    if(!this.interactive) return;
    const popover = await this.popoverCtrl.create({
      component: MatchesOptionsComponent,
      componentProps: {
        userId: this.user.id,
        section: this.index < RECOMMENDED_MATCHES_LENGTH ? 'rec' : 'std'
      },
      event: event,
      backdropDismiss: true,
      cssClass: 'matches-options-popover'
    });
    await popover.present();
  }

  async openProfile() {
    if(!this.interactive) return;
    try {
      const modal = await this.modalCtrl.create({
        component: ProfilePopupComponent,
        componentProps: {
          userId: this.user.id,
          profileId: this.user.profile_id,
          section: this.index < RECOMMENDED_MATCHES_LENGTH ? 'rec' : 'std',
          fromMatches: this.fromMatchesPage,
          fromFailedSearch: this.fromFailedSearch,
          failedStatus: this.matchingFailureReason,
        },
        backdropDismiss: true,
        swipeToClose: true,
        cssClass: 'profile-modal modal-card'
      });
      await modal.present();
      this.hideMatchesTooltip();
    } catch ( err ) {
      console.log( err );
    }
  }

  hideMatchesTooltip() {
    this.config.callOnce('matchesTipShown',() => {
      this.matchesTipShown = true;
    });
  }

  getAge(birthday) {
    return this.utils.getUserAge(birthday);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
