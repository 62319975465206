import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'tribe',
  template: `
    <tribe-avatar [tribe] = "tribe"></tribe-avatar>
    <div *ngIf = "tribe.status"
         class = "info-section">
      <div class = "title capitalize">
        <ion-icon *ngIf = "tribe.type == 'fans'" name = "calendar"></ion-icon>
        {{ tribe.status | textFormatTribeStatus }}
      </div>
    </div>
  `,
  styleUrls: ['./tribe.component.scss'],
})
export class TribeComponent implements OnInit {
  @Input("tribe") tribe;

  constructor() { }

  ngOnInit() {}

}
