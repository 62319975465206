import { Pipe, PipeTransform } from '@angular/core';
import {REPLY_MEDIA_REGEX} from "../shared/constants/regex";
@Pipe({
  name: 'replyContent',
})
export class ReplyContentPipe implements PipeTransform {
  transform(value: string) {
    if(value && !!value.match( REPLY_MEDIA_REGEX )) {
     return 'Picture';
    }
    return value;
  }
}
