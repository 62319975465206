import {Injectable, Injector} from '@angular/core';
import { ApiService } from './api.service';
import { Config } from '../config/config';
import {ModalController, NavController} from '@ionic/angular';
import { Subject } from 'rxjs/Subject';
import { DispatcherService } from "../dispatcher.service";
import { AnalyticsService } from '../analytics/analytics.service';
import * as moment from "moment";
import {UtilsService} from "../utils.service";
import {FeedbackService} from "../popups/feedback.service";
import {PusherService} from "../pusher.service";
import {DailyTribesService} from "../daily-tribes.service";
import {Tribes} from "../../shared/enums/tribes.enum";
import {RemoteConfigService} from "../remote-config.service";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {logc} from "../../shared/helpers/log";
import {SentryService} from "../performance/sentry.service";
import {merge, Observable} from "rxjs";
import { DeviceInfoTransmittingStatus } from './session.service';
import {MatchingStrategyFactory} from "../matching/matching-strategy-factory";
import {getWeekDay} from "../../shared/helpers/time-helpers";
import {DAILY_PASSED_USERS_LIMIT, DAILY_REJECTED_USERS_LIMIT} from "../../shared/constants/constants";
import { FanFailedSearchComponent } from 'src/app/components/fan-failed-search/fan-failed-search.component';
import { getRandomImage } from 'src/app/shared/helpers/helpers';
import { FanMatchingSearchDetailsComponent } from 'src/app/components/fan-matching-search-details/fan-matching-search-details.component';
import { MatchingTypePickerComponent } from 'src/app/components/matching-type-picker/matching-type-picker.component';
import { UserService } from './user.service';
import { MatchPreferencesLocation } from 'src/app/shared/enums/match-preferences.enum';
import { ModalService } from '../popups/modal.service';
import { UpsellingPoppingReason } from 'src/app/pages/upselling/upselling.page';

export enum ScheduledStates {
  PendingReview = "pending_review",
  Participant = "participant",
  Incomplete = "incomplete",
  Rejected = "rejected",
  Scheduled = "scheduled",
  NotScheduled = "not_scheduled",
  Skipped = "skipped"
}

const FAN_SEARCHES_LIMIT = 3;
export enum SearchEvent {
  FoundOne = "found_one"
}

export enum SearchType {
  Fans = "fans"
}
export interface SearchFilter {
  text: string;
}

export enum MatchingType {
  Fans = "fans",
  Default = "default" 
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  // Match making system is a bit too fast, we throttle it so it looks like it's searching more
  searchThrottleTime = 2000;

  private defaultAvatar: string = '';
  private defaultPickPlaceAvatar: string = "./assets/img/default-pick-place-avatar.png";
  private pixelatedAvatar: string = './assets/img/pixel-img.png';
  private currentUserAvatar: string = '';

  public onSearchChanged: any;
  public onFoundOne: any;
  public onSearchStopped: any;
  public onSearchSucceeded: any;
  public onSearchingDummy: any;
  public onAddMatchingDummy: any;
  public onRemoveMatchingDummy: any;
  public onSearchError: any;
  public onSearchBlocked: Observable<boolean>;
  public onScheduledSearch: Observable<any>;
  public onRemoveFansDummy: Observable<any>;
  public addMatchingDummySource = new Subject();
  public removeMatchingDummySource = new Subject();
  public searchChangedSource = new Subject();
  public searchFoundOneSource = new BehaviorSubject(null);
  public searchStoppedSource = new Subject();
  public searchSuccessSource = new BehaviorSubject(null)
  public searchErrorSource = new Subject();
  public ongoingSearchesSource = new Subject();
  public searchBlockSource = new Subject<boolean>();
  public scheduledSearchSource = new BehaviorSubject(null);
  public removeSearchSource: Subject<any> = new Subject();
  public rejectionDisabledSource: Subject<boolean> = new Subject<boolean>();
  public removeFansDummySource: Subject<any> = new Subject();
  public onSearchRemoved: Observable<any>;
  public justFailed = false;
  public isBlacklistRestricted = false;
  public onOngoingSearchesAdded: any;
  public ongoingSearches: any[] = [];
  private isTransmittingDeviceInfo: boolean = false;

  public onRejectionDisabled: Observable<any>;

  public searchingDummySource = new Subject();
  public tribes: any[];
  public inDecliningTribeProcess: boolean = false;
  public searchingPageOpened: boolean = false;
  public searchInProgress: boolean = false;

  private matchingStrategyFactory: MatchingStrategyFactory = null;

  constructor(public api: ApiService,
              public analyticsService: AnalyticsService,
              public config: Config,
              private utils: UtilsService,
              private userService: UserService,
              private feedbackService: FeedbackService,
              private dispatcherService: DispatcherService,
              private pusherService: PusherService,
              private modalCtrl: ModalController,
              private navCtrl: NavController,
              private modalService: ModalService,
              private dailyTribesService: DailyTribesService,
              private remoteConfigService: RemoteConfigService,
              private errorTrackingService: SentryService,
              private injector: Injector){
    this.onSearchChanged = this.searchChangedSource.asObservable();
    this.onFoundOne = this.searchFoundOneSource.asObservable();
    this.onScheduledSearch = this.scheduledSearchSource.asObservable();
    this.onSearchStopped = this.searchStoppedSource.asObservable();
    this.onSearchSucceeded = this.searchSuccessSource.asObservable();
    this.onSearchingDummy = this.searchingDummySource.asObservable();
    this.onOngoingSearchesAdded = this.ongoingSearchesSource.asObservable();
    this.onAddMatchingDummy = this.addMatchingDummySource.asObservable();
    this.onRemoveMatchingDummy = this.removeMatchingDummySource.asObservable();
    this.onSearchError = this.searchErrorSource.asObservable();
    this.onSearchBlocked = this.searchBlockSource.asObservable();
    this.onSearchRemoved = this.removeSearchSource.asObservable();
    this.onRejectionDisabled = this.rejectionDisabledSource.asObservable();
    this.onRemoveFansDummy = this.removeFansDummySource.asObservable();

    this.matchingStrategyFactory = this.injector.get(MatchingStrategyFactory);

    if(this.config.isDev || this.config.isStaging) {
      (window as any).searchService = this;
    }

    merge(
      this.onFoundOne,
      this.onSearchSucceeded
    ).subscribe(() => {
      this.removeMatchingDummy();
      this.searchInProgress = false;
    })

    this.config.onLocalDataChange.subscribe((data) => {
      this.rejectionDisabledSource.next(data['dailyRejectedUsers'] >= 4 && !this.config.isPlus());
    })

    this.onFoundOne.subscribe(async (event) => {
      this.addDummy();
      if(event && !this.searchingPageOpened) {
        this.openSearchingPage("notReady");
      }
    })

    this.dispatcherService
      .onDeviceInfoTransmitting
      .subscribe((transmitting: any) => {
        this.isTransmittingDeviceInfo = transmitting.status != DeviceInfoTransmittingStatus.Completed;
      })

    this.onSearchSucceeded.subscribe(async (event) => {
      this.removeDummy();
      if(event && !this.searchingPageOpened) {
        this.openSearchingPage("found");
      }
    })
  }


  getSearchingDummy({ preferences,  currentUserAvatar }) {
    const dummies = {
      [MatchPreferencesLocation.NearMe]: {
        id: -1,
        attempts_count: null,
        location: MatchPreferencesLocation.NearMe,
        label: 'Ongoing',
        type: "searching",
        users: [
          { picture: currentUserAvatar },
          { picture: this.defaultAvatar},
          { picture: this.defaultAvatar }
        ]
      },
      [MatchPreferencesLocation.Global]: {
        id: -1,
        attempts_count: null,
        location: MatchPreferencesLocation.NearMe,
        label: 'Global',
        type: "searching",
        users: [
          { picture: currentUserAvatar },
          { picture: this.defaultAvatar },
          { picture: this.defaultAvatar }
        ],
        searching: true
      },
      [MatchPreferencesLocation.PickPlace]: this.getPickPlaceAvatar(preferences?.remote_city_name, currentUserAvatar)
    };

    return dummies[ preferences?.location ];
  }

  getFansDummy({ scheduledId }) {
    return {
      id: -5,
      attempts_count: null,
      pending: false,
      status: "Thursday",
      name: "Thursday",
      type: 'fans',
      picture: this.config.getPartnerImage(),
      users: [
        { picture: this.defaultAvatar },
        { picture: this.defaultAvatar },
        { picture: this.defaultAvatar }
      ],
      otherUsers: [ {} ],
      searching: true,
      scheduledId
    }
  }

  getScheduledDummy({
    currentUser = this.config.getProfile(),
    secondUser = { picture: "" },
    scheduledId = -1
  }) {
    let dummy = {
      id: -5,
      attempts_count: null,
      pending: false,
      status: getWeekDay(this.config.getFlag("next_scheduled_matches_at")),
      type: 'fans',
      users: [
        { picture: currentUser?.picture },
        { picture: secondUser?.picture || this.defaultAvatar },
        { picture: this.defaultAvatar }
      ],
      otherUsers: [ secondUser ],
      searching: true,
      scheduledId
    }
    return dummy;
  }

  getPickPlaceAvatar( city, currentUserAvatar ) {
    return {
      id: -1,
      attempts_count: null,
      location: MatchPreferencesLocation.PickPlace,
      label: city,
      type: "searching",
      users: [
        {picture: currentUserAvatar },
        {picture: this.defaultAvatar },
        {picture: this.defaultAvatar }
      ],
      searching: true
    }
  };

  isReadyToMatch() {
    const matchRequirements = [
      !this.dailyTribesService.reachedDailyTribesLimit(),
      !this.reachedDailyPassedUsersLimit(),
      !this.config.get('banned'),
      !this.tribes?.some(tribe => tribe?.status === 'pending'),
      this.config.hasLocation(),
      this.config.SMAggregator.session.name == 'ready',
      !this.inDecliningTribeProcess,
      this.pusherService.state == 'connected',
      !this.isTransmittingDeviceInfo,
      this.api.isOnline(),
      !this.searchInProgress,
    ]
    return matchRequirements.every(Boolean);
  }

  reachedDailyPassedUsersLimit(): boolean {
    return this.config.get("passedUsers")?.declined?.daily >= DAILY_PASSED_USERS_LIMIT && !this.config.isPlus();
  }

  reachedDailyRejectedUsersLimit(): boolean {
    return this.config.get("passedUsers")?.rejected?.daily >= DAILY_REJECTED_USERS_LIMIT && !this.config.isPlus();
  }

  scheduledMatchingReady() {
    return ![
      ScheduledStates.Rejected,
      ScheduledStates.Incomplete
    ].includes(this.config.getFlag("scheduled_matches_status")) &&
      this.ongoingSearches.filter(os => os.status == "scheduled").length < 3
  }

  outputReadyToMatchDetails(): void {
    logc.info("-- Requirements for match --");
    let missing = [];
    const requirementsDetails = {
      reachedDailyTribesLimit: { current: this.dailyTribesService.reachedDailyTribesLimit(), shouldBe: false },
      hasSearchInProgress: { current: this.searchInProgress, shouldBe: false },
      apiOnline: { current: this.api.isOnline(), shouldBe: true },
      banned: { current: this.config.get('banned'), shouldBe: false },
      hasPendingTribe: { current: !!this.tribes?.some(tribe => tribe?.status === 'pending'), shouldBe: false },
      hasLocation: { current: this.config.hasLocation(), shouldBe: true },
      sessionReady: { current: this.config.SMAggregator.session.name == 'ready', shouldBe: true },
      inDecliningTribeProcess: { current: this.inDecliningTribeProcess, shouldBe: false },
      pusherConnected: { current: this.pusherService.state == 'connected', shouldBe: true },
      isTransmittingDeviceInfo: { current: this.isTransmittingDeviceInfo, shouldBe: false },
      reachedDailyPassedUsersLimit: { current: this.reachedDailyPassedUsersLimit(), shouldBe: false },
    }
    for(let key in requirementsDetails) {
      const meetsReq = requirementsDetails[key].current == requirementsDetails[key].shouldBe;
      console.log(`${ meetsReq ? "✔" : "✗"} ${ key }: ${ requirementsDetails[key].current } (shouldBe: ${ requirementsDetails[key].shouldBe })`);
      if(requirementsDetails[key].current != requirementsDetails[key].shouldBe) {
        missing.push({ [key]: requirementsDetails[key] });
      }
    }
    if(missing.length) {
      logc.error("Missing requirements (json): ", missing);
      console.log("------------------------");
      logc.error("Missing requirements (text): ");
      for(let item of missing) {
        const [reqName, reqValue] = Object.entries(item).flat();
        console.log(`✗ - ${ reqName }: ${ reqValue['current'] } (shouldBe: ${ reqValue['shouldBe'] })`)
      }
      console.log("------------------------");
    }
  }

  getBlockReason(): string {
    if(this.reachedDailyPassedUsersLimit()) return "too_many_users_passed";
    if(this.dailyTribesService.reachedDailyTribesLimit()) return "daily_tribes_limit_reached";
    if(!this.config.hasLocation()) return 'no_location';
    if(this.ongoingSearches.filter(os => os.status == "scheduled").length >= 3) return "scheduled_searches_limit";
    // if(this.config.getFlag("scheduled_matches_status") == 'rejected') return 'rejected';
    if(this.tribes?.some(tribe => tribe?.status === 'pending')) return 'pending_tribe';
    if(this.config.get('matchPreferences').location === MatchPreferencesLocation.PickPlace && !this.config.isPlus()) return 'other_city';
    if(this.config.getProfile().banned) {
      this.utils.showGenericMessage('Your account is currently blocked. Contact us find out why.', 6000, 'CONTACT US', _ => {
        this.feedbackService.showFeedbackForm({
          context: 'Account Blocked.',
          errorContext: 'tribes send feedback error'
        });
      });
      return '';
    }

    return '';
  }

  async startSearch(options = { apply_filter: false }): Promise<any> {
    if(await this.remoteConfigService.getBoolean("search_start_profile_checkup_experiment")) {
      if (!this.config.getFlag('searchStartedOnce')
        && this.config.getFlag('total_matches_under_75')) {
        this.dispatcherService.newPopupSource.next({
          popupName: 'profileCheckupPopup',
          options: {
            matches: this.config.getFlag('total_matches_under_75'),
            city: this.config.getProfile()?.closest_city?.name,
            handler: () => this.startSearch(options)
          }
        });
        this.config.setFlag('searchStartedOnce', true);
        return '';
      }
    }

    if(this.isReadyToMatch()) {
      const matchingStrategy = this.matchingStrategyFactory.getMatchingStrategy();
      await matchingStrategy.execute(options);
    } else {
      const reason = this.getBlockReason();
      if(!reason) return;

      this.dispatcherService.matchMePopoverSource.next({
        name: reason,
        action: 'open'
      });
    }
  }

  openSearchingPage(state = ''): void {
    this.dispatcherService.newModalSource.next({
      modalName: 'searchingPage',
      options: {
        searchingState: state
      }
    })
    this.searchingPageOpened = true;
  }

  removeMatchingDummy() {
    this.removeMatchingDummySource.next(null);
  }

  addMatchingDummy() {
    this.addMatchingDummySource.next({
      id: Tribes.MatchingDummyId,
      attempts_count: null,
      location: MatchPreferencesLocation.NearMe,
      label: 'Matching',
      type: "matching",
      users: [
        { picture: this.currentUserAvatar },
        { picture: this.pixelatedAvatar },
        { picture: this.defaultAvatar }
      ],
      searching: true
    })
  }

  isGlobalMatching(): boolean {
    return this.config.get("matchPreferences")?.location == MatchPreferencesLocation.Global;
  }

  searchChanged(data) {
    if(data?.search_type == SearchType.Fans) {
      this.openFailedFanSearchPage(data);
      if(data?.search_status == 'found_one') {
        this.removeFansDummySource.next(data);
        const index = this.ongoingSearches.findIndex(s => s?.status == 'fans');
        if(index > -1) {
          this.ongoingSearches.splice(index, 1);
        }
      }
      return;
    }

    switch (data.search_status) {
      case "scheduled_matches_failed":
        this.analyticsService.trackEvent({
          key: 'search_failed_viewed',
          value: 1,
          search_location: data.preferences && data.preferences.location || '',
          nth: this.config.getFlag('started_search_count', 1),
          blacklist_restricted: (data.blacklist_restricted ? 1 : 0),
        });
        this.scheduledSearchSource.next(data);
        this.openSearchingPage("notReady");
        break;
      case 'found_one':
        this.analyticsService.trackEvent({
          key: 'search_failed_viewed',
          value: 1,
          search_location: data.preferences && data.preferences.location || '',
          nth: this.config.getFlag('started_search_count', 1),
          blacklist_restricted: (data.blacklist_restricted ? 1 : 0),
        });
        setTimeout( _ => {
          this.isBlacklistRestricted = data.blacklist_restricted;
          this.justFailed = true;
          this.ongoingSearchesSource.next(data);
          this.searchFoundOneSource.next(data);
          this.searchingDummySource.next(Object.assign(data, { action: 'add' }));
        }, this.searchThrottleTime);
        break;
      case 'searching':
        this.searchChangedSource.next(null);
        break;
      case 'found':
        setTimeout( _ => {
          this.analyticsService.trackEvent({
            key: 'search_success_viewed',
            value: 1,
            tribe_id: data.tribe_id,
            score: data.score,
            proximity_1: data.proximity_1,
            proximity_2: data.proximity_2,
            trust_1: data.trust_1,
            trust_2: data.trust_2,
            search_location: data.preferences && data.preferences.location || '',
            attempts_count: data.attempts_count,
            big_smile_1: data.tribe.users[0].smile,
            big_smile_2: data.tribe.users[1].smile,
            nth: this.config.getFlag('started_search_count', 1),
          });

          this.searchingDummySource.next(Object.assign(data, { action: 'remove' }));

          this.hasStopped(data.tribe_id);

          //Let the UI reflect the stop search first
          setTimeout( _ => {
            this.searchSuccessSource.next(data);
          }, 200);
        }, this.searchThrottleTime);
        break;
      case 'stopped':
        // this.hasStopped();
        this.removeSearch(data);
        this.searchingDummySource.next(Object.assign(data, { action: 'remove' }));
        break;
    }
  }

  removeSearch(data) {
    this.removeSearchSource.next(data);
  }

  addDummy() {
    this.searchingDummySource.next({
      attempts_count: 5,
      preferences: {
        location: this.config.get('matchPreferences').location
      },
      action: 'add'
    });
  }

  removeDummy() {
    this.searchingDummySource.next({
      preferences: {
        location: this.config.get('matchPreferences').location
      },
      action: 'remove'
    });
  }


  performNow() {
    return new Promise((resolve, reject) => {
      setTimeout( _ => {
        return this.api.post('search/perform_now', {}).then(resolve, reject);
      }, this.searchThrottleTime);
    });
  }

  start({ apply_filter = false, state = "searching" }) {
    this.openSearchingPage(state);
    this.searchBlockSource.next(true);
    setTimeout(() => this.searchBlockSource.next(false), 5000);
    this.searchFoundOneSource.next(null);
    this.searchSuccessSource.next(null);

    if(!this.config.finishedLevel(3)) {
      setTimeout(async () => {
        await this.modalCtrl.dismiss();
        await this.navCtrl.navigateForward('insufficient-info');
      }, 3000);
      return;
    }

    return new Promise((resolve, reject) => {
      setTimeout( _ => {
        this.api.post('search', { apply_filter }).then((data: any) => {
          logc.info("Search info: ", data);
          this.addMatchingDummy();
          this.searchInProgress = true;
          setTimeout(() => this.searchInProgress = false, 30000);
          this.analyticsService.trackEvent({
            key: 'started_search',
            value: 1,
            search_location: data.preferences.location,
            gender: data.preferences.gender,
            nth: data.nth,
          });
          resolve(data);
        }, (err) => {
          this.searchErrorSource.next(err);
          this.utils.errorContext = 'searching, error: ' + JSON.stringify(err);
          this.utils.showGenericError({
            msg: "searching"
          });
          console.log(`-- searching page start() error: ${ err }`);
          if(err.status === 422) {
            this.dispatcherService.openPopup('matchingAbortedPopup');
          }
          const tags = { klass: "Searching", func: 'start()' };
          const extras = { error: err }
          this.errorTrackingService.sendMessage('Searching failed', tags, extras);
        });
      }, this.searchThrottleTime);
    });
  }

  hasStopped(tribeId?) {
    this.searchingDummySource.next({})
    // this.searchStoppedSource.next({tribeId: tribeId});
  }

  load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.config.load().then(_=> {
        resolve();
      }, reject);
    });
  }

  exploreMatches({ id }): Promise<any> {
    return this.api.get(`users/explore_matches_v2`, { search_id: id });
  }

  isScheduledToMatch(): boolean {
    return this.inScheduledFanMatching();
  }

  async openFailedFanSearchPage(data) {
    const modal = await this.modalCtrl.create({
      component: FanFailedSearchComponent,
      componentProps: {
        searchData: data
      }
    })
    modal.present();
  }

  inScheduledFanMatching(): boolean {
    return this.getFansSearches().length == FAN_SEARCHES_LIMIT;
  }

  getFansSearches(): any[] {
    return this.ongoingSearches.filter(({ status }) => status == 'fans');
  }

  async openFanSearchDetails() {
    const onButtonClicked: Subject<any> = new Subject();
    const modal = await this.modalCtrl.create({
      component: FanMatchingSearchDetailsComponent,
      componentProps: {
        onButtonClicked
      },
      id: "fans-searching-modal",
      breakpoints: [0, 0.65],
      initialBreakpoint: 0.65,
      cssClass: "border-round-modal bottom-sheet-modal"
    })

    onButtonClicked.subscribe(async (data) => {
      if(!this.config.isPlus() && data?.groupsNumber > 1) {
        this.modalService.openUpselling({ 
          source: "fan-search-details-bottom-sheet",
          reason: UpsellingPoppingReason.GetMatched
        })
        return;
      }
      if(!(await this.userService.canJoin())) return;

      this.modalCtrl.dismiss();
      try {
        await this.startFanSearch(data?.groupsNumber);
        this.navCtrl.navigateForward("offboarding?type=fan");
      } catch(e) {
        this.utils.showGenericError({});
      }
    });
    modal.present();
  }

  private async startFanSearch(groups) {
    try {
      await this.api.post("users/fans_matching_schedule", { groups });
      this.analyticsService.trackEvent({
        key: "fan_matching_signed_up",
        value: 1
      })
    } catch(e) {
      logc.error("Fan sign up error: ", e);
      this.utils.showGenericError({});
    }
  }

  async openFansSearchPicker() {
    try {
      const topBreakpoint = 0.6;
      const initialBreakpoint = 0.4;
      const buttonBreakpoint = 0.5;
      const modalSource: Subject<any> = new Subject();
      const buttonClickedSource: Subject<any> = new Subject();
      const modal = await this.modalCtrl.create({
        component: MatchingTypePickerComponent,
        componentProps: { 
          modalSource,
          buttonClickedSource,
          items: [{
            influencer: this.config.getPartnerName(),
            text: "Fans of " + this.config.getPartnerName(),
            type: "fan",
            chip: { 
              icon: "time-outline", 
              text: "Later",
              activatedCssClass: 'bg-tertiary-light-1 text-tertiary-dark-3',
              activatedIconCssClass: 'text-tertiary-dark-3' 
            }
          }]
        },
        breakpoints: [0, initialBreakpoint, buttonBreakpoint, topBreakpoint],
        initialBreakpoint,
        cssClass: "border-round-modal bottom-sheet-modal"
      })
      await modal.present();
      modalSource.next(modal);

      buttonClickedSource.subscribe(async ({ text, type }) => {
        this.modalCtrl.dismiss();
        if(type == 'fan') this.openFanSearchDetails()
        if(type == 'default') this.start({ apply_filter: false });
      });
    } catch(e) {

    }
  }
}
