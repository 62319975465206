import {Component, Input, NgZone, Renderer2, ViewChild} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import { Config } from '../../services/config/config';
import { SearchService } from '../../services/data/search.service';
import { TribesService } from '../../services/data/tribes.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { UtilsService } from '../../services/utils.service';
import { Subject } from "rxjs/Subject";
import {finalize, takeUntil, tap} from "rxjs/operators";
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {PushNotification} from "../../services/push-notification/push-notification";
import {PerformanceReportService} from "../../services/performance/performance-report.service";
import {SessionService} from "../../services/data/session.service";
import {PusherService} from "../../services/pusher.service";
import {SentryService} from "../../services/performance/sentry.service";
import {ApiService} from "../../services/data/api.service";
import {DispatcherService} from "../../services/dispatcher.service";
import {MatchesService} from "../../services/matches.service";
import {logc} from "../../shared/helpers/log";
import {isNull} from "util";
import {AppService} from "../../services/app";
import {prepareExtras} from "../../shared/helpers/helpers";
import {merge} from "rxjs";
import {AudiencesService} from "../../services/analytics/audiences.service";
import { MatchPreferencesLocation } from 'src/app/shared/enums/match-preferences.enum';
import { isThursday } from 'date-fns';

@Component({
  selector: 'page-searching',
  templateUrl: 'searching.page.html',
  styleUrls: ['searching.page.scss']
})
export class SearchingPage {
  @Input() matchingType: string = "default";
  @ViewChild('header', { static: true }) header;
  @ViewChild('content', { static: true }) content;

  private stateChange: Subject<any> = new Subject<any>();
  public unsubscribe: Subject<any> = new Subject<any>();
  public matchingAnimation: any;
  public successAnimation: any;
  public notReadyAnimation: any;

  public potentialMatchesShortAvatars: any[] = [
    { picture: "./assets/img/pixel-img.png" },
    { picture: "./assets/img/default-pick-place-avatar.png" },
    { picture: "./assets/img/pixel-img.png" }
  ]

  public searching: boolean = true;
  public searchData: any;
  public searchingState: any = null;
  public showCloseButton: boolean = false;
  private defaultExtras: any = { preferences: { location: MatchPreferencesLocation.NearMe } };
  private getNotifiedPageExtras: NavigationExtras;
  public state: any;
  public isIncompleteStage1: boolean = false;
  public isIncompleteStage2: boolean = false;
  public screenWidth = 0;
  public matchesLength: number = 0;
  public isDev: boolean = false;
  public showPotentialMatches: boolean = false;
  public calendarAnimationConfig: any = {
    path: './assets/gif/calendar-short.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  }

  public notifyButton = {
    style: 'medium',
    icon: 'notifications-outline',
    text: 'Notify me when it is ready',
  };

  public lottieFirework: any = {
    path: 'assets/gif/success.json',
    renderer: 'svg',
    autoplay: true,
    loop: false
  };
  public lottieSearch: any = {
    path: 'assets/gif/matching-2000.json',
    renderer: 'svg',
    autoplay: true,
    loop: true
  };
  public lottieNotReady: any = {
    path: 'assets/gif/match-delayed.json',
    renderer: 'svg',
    autoplay: true,
    loop: false
  };

  public states = {};

  constructor(public nav: NavController,
              public config: Config,
              public searchService: SearchService,
              private tribesService: TribesService,
              private analyticsService: AnalyticsService,
              public utils: UtilsService,
              private renderer: Renderer2,
              public route: ActivatedRoute,
              public router: Router,
              public sessionService: SessionService,
              private ngZone: NgZone,
              private navParams: NavParams,
              private push: PushNotification,
              private sentryService: SentryService,
              private pusherService: PusherService,
              private appService: AppService,
              public modalCtrl: ModalController,
              private audiencesService: AudiencesService) {
    (window as any).searchingPage = this;
  }

  showElement( el ) {
    this.renderer.removeClass(el, 'fade-out-animation');
    this.renderer.addClass(el, 'fade-in-animation');
  }

  hideElement( el ) {
    this.renderer.removeClass(el, 'fade-in-animation');
    this.renderer.addClass(el, 'fade-out-animation');
  }

  async notify() {
    if(!this.config.isPWA) {
      await this.push.requestPushPermissionAndInit();
    } else {
      const pushStatus = await this.push.getWebPushPermissionStatus();
      if(pushStatus == 'not_supported') {
        this.utils.showDownloadToast();
      }
    }
    return this.close();
  }

  handleMatchingAnimation(animation: any): void {
    this.matchingAnimation = animation;
    this.matchingAnimation.setSpeed(2.4);
    this.matchingAnimation.play();
  }

  handleSuccessAnimation(animation: any): void {
    this.successAnimation = animation;
    this.successAnimation.setSpeed(0.75);
    this.successAnimation.play();
  }

  handleNotReadyAnimation(animation: any): void {
    this.notReadyAnimation = animation;
    this.notReadyAnimation.play();
  }

  play() {
    // this.ngZone.runOutsideAngular(() => {
    //   this.anim.play();
    // });
  }

  setStates() {
    this.states = {
      fan: {
        text: "Your group is being rescheduled"
      },
      searching: {
        text: "Matching",
        blinkingText: {
          items: [
            { text: "Running compatibility algorithm", duration: 3500 },
            { text: "Applying your filters", duration: 2500 }
          ],
          cssClass: "title-color base-text",
          startingDelay: 300,
          loop: true
        }
      },
      tryingAgain: {
        text: "Trying again...",
        subText: "We're experiencing delays. Thanks for your patience"
      },
      found: {
        text: "Success",
        subText: ""
      },
      notReady: {
        text: this.audiencesService.inScheduledGroup() ? "Group match delayed" : "Match attempt failed",
        subText: ""
      },
      error: {
        text: "Matching",
        subText: ""
      }
    }
  }

  ngOnInit() {
    this.setStates();
    this.isDev = this.config.isDev;
    this.screenWidth = (window as any).screen.width;
    this.searchingState = this.navParams.get("searchingState");
    logc.crimson("Searching state: ", this.searchingState);
    this.state = this.states[this.searchingState];
    this.stateChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: string) => {
        this.searchingState = state;
        this.state = this.states[state];
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  stopAnimation(animation) {
    this.ngZone.runOutsideAngular(async () => {
      await animation.stop();
    });
  }

  ionViewWillEnter() {
    if(this.searchingState === 'tryingAgain') {
      return;
    }

    let tryingAgainTimeout;
    let navigateBackTimeout;

    tryingAgainTimeout = setTimeout(async () => {
      this.changeState('tryingAgain');
      if((this.pusherService.state !== 'connected' || this.sessionService.stateMachine.state !== 'ready')
        && !this.appService.isInBackground) {
        const tags = { page: "searching_page" };
        const extras = {
          pusher_state: this.pusherService.state,
          session_state: this.sessionService.stateMachine.state
        }
        this.sentryService
          .sendMessage("Search didn't receive tribe", tags, extras)
          .then(async () => {
            await this.modalCtrl.dismiss();
            window.location.reload();
          });
      }
    }, 8000);

    merge(
      this.searchService.onFoundOne,
      this.searchService.onScheduledSearch
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async (searchData: any) => {
        if(!searchData) return;

        this.searchData = searchData;
        this.showPotentialMatches = 
          !this.searchService.isGlobalMatching() && this.searchData?.total_matches > 1;
        this.matchesLength = searchData["total_matches"];
        clearTimeout(navigateBackTimeout);
        clearTimeout(tryingAgainTimeout);

        this.changeState('notReady');
        setTimeout(() => this.isIncompleteStage1 = true, 2000);
        setTimeout(() => this.isIncompleteStage2 = true, 3000);
        return;
      });

    this.searchService
      .onSearchSucceeded
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async (searchData: any) => {
        if(searchData == undefined) return;

        logc.info("-- searching.page.ts this.searchService.onSearchSucceeded: ", searchData);
        this.searchData = searchData;
        clearTimeout(tryingAgainTimeout);
        clearTimeout(navigateBackTimeout);
        this.changeState('found');
        setTimeout(() => {
          this.tribesService.openQueueTribe(searchData.tribe_id);
          this.stopAnimation(this.successAnimation);
          this.modalCtrl.dismiss();
        }, 3000)
      });

    this.searchService
      .onSearchError
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.modalCtrl.dismiss());

    merge(...[
      this.searchService.onSearchSucceeded,
      this.searchService.onFoundOne
    ]).pipe(
      takeUntil(this.unsubscribe),
      tap((searchData: any) => this.getNotifiedPageExtras = prepareExtras(searchData || this.defaultExtras)),
      finalize(() => this.getNotifiedPageExtras = null)
    ).subscribe();
  }

  changeState(state) {
    if(state != 'tryingAgain') {
      this.fadeInAnimation();
    }
    this.fadeInTitles();
    setTimeout(() => {
      this.stateChange.next(state);
      this.fadeOutAnimation();
      this.fadeOutTitles();
    }, 500);
  }

  fadeInAnimation() {
    this.renderer.addClass(this.header.nativeElement, 'opacity');
  }

  fadeOutAnimation() {
    this.renderer.removeClass(this.header.nativeElement, 'opacity');
  }

  fadeInTitles() {
    this.renderer.addClass(this.content.nativeElement, 'opacity');
  }

  fadeOutTitles() {
    this.renderer.removeClass(this.content.nativeElement, 'opacity');
  }

  async close() {
    this.searchService.searchingPageOpened = false;
    await this.modalCtrl.dismiss();
    return this.nav.navigateRoot('tabs/tribes');
  }

  openGetNotifiedPage() {
    this.navigate('get-notified', this.getNotifiedPageExtras)
  }

  navigate( page, extras ) {
    this.modalCtrl.dismiss();
    this.nav.navigateForward(page, extras);
  }
}
