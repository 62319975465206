import { Component, EventEmitter, Input, Output } from "@angular/core";
import { isThisHour } from "date-fns";
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil, tap } from "rxjs/operators";
import { logc } from "src/app/shared/helpers/log";

@Component({
  selector: "timer",
  template: `
    <div [class.opacity-0] = "!(timer | async)"
         [style.margin-top.px] = "(timer | async) ? 0 : -75"
         class = "transition-[750] opacity-1 text-center text-[41px] font-heavy text-body p-[15px]">
      {{ (timer | async) || "00:00:00" }}
    </div>
  `
})
export class TimerComponent {
  @Input("timer") timer: Observable<string | null> = null;
  @Output("onFinish") timerFinishEmitter: EventEmitter<any> = new EventEmitter();

  private unsubscribe: Subject<any> = new Subject();

  ngOnInit() {
    this.timer.pipe(
      takeUntil(this.unsubscribe),
      filter((value) => value == undefined),
      tap(() => {
        logc.indigo("timer thing");
        this.timerFinishEmitter.emit(true);
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}