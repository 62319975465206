import { Component, OnInit, Input } from '@angular/core';
import { IonModal, ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Config } from 'src/app/services/config/config';
import { UtilsService } from 'src/app/services/utils.service';
import { logc } from 'src/app/shared/helpers/log';

@Component({
  selector: 'app-matching-type-picker',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title class = "px-0 text-body text-large font-semibold text-center">
          What type of group?
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class = "padding-20" style = "--padding-top: 0">
      <!-- <checkmark-buttons-list [buttons] = "buttons" 
                              (onChange) = "onOptionSelected($event)"></checkmark-buttons-list> -->
      <checkmark-button [selected] = "selectedOption"
                        [cssClass] = "'-mt-0'"
                        [button] = "buttons[0]"
                        (onSelected) = "onOptionSelected($event)"></checkmark-button>
      <notice *ngIf = "selectedOption?.type == 'default'">
        This considers ALL users in and around
        <div class = "font-heavy text-primary inline" (click) = "showLocationTip()">
          {{ userArea }}
        </div>,
        not just fans of {{ influencer }}
      </notice>
      <checkmark-button *ngIf = "buttons[1]"
                        [selected] = "selectedOption"
                        [button] = "buttons[1]"
                        (onSelected) = "onOptionSelected($event)"></checkmark-button>
      <ion-button *ngIf = "selectedOption" 
                  class = "turquoise-gradient-btn btn-large-action ionic-button turquoise-btn-shadow"
                  shape = "round"
                  (click) = "execute()">
        {{ buttonText }}
      </ion-button>
    </ion-content>
  `,
  styleUrls: ['./matching-type-picker.component.scss'],
})
export class MatchingTypePickerComponent implements OnInit {
  @Input() modalSource;
  @Input() items;
  @Input() buttonClickedSource;
  
  public buttons = [{ 
    text: "Best overall", 
    type: "default", 
    titleIcon: {
      name: "stars",
      cssClass: "text-tertiary"
    },
    chip: {
      icon: "flash",
      text: "Instant",
      activatedCssClass: 'bg-tertiary-light-1 text-tertiary-dark-3',
      activatedIconCssClass: 'text-tertiary-dark-3'
    }
  }]
  public selectedOption: any = null;
  private modal: any = null;
  public userArea: string = null;
  public buttonText: string = null;
  public influencer: string = null;

  private unsubscribe: Subject<any> = new Subject();
  private breakpoints: number[] = [];
  constructor(private config: Config, private utils: UtilsService) {}

  async ngOnInit() {
    this.influencer = this.config.getPartnerName();
    this.buttons = [ ...this.buttons, ...this.items ];
    this.userArea = this.config.getUserCity() || "your area";
    this.modalSource
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async (modal: any) => {
        this.modal = modal;
        this.breakpoints = modal.breakpoints;
      })
  }

  showLocationTip(): void {
    this.utils.showLocationTip();
  }

  onOptionSelected(event): void {
    this.selectedOption = event;
    this.setButtonText();
    if(this.selectedOption.type == 'default') {
      this.modal.setCurrentBreakpoint(this.breakpoints.last());
    } else {
      this.modal.setCurrentBreakpoint(this.breakpoints[2]);
    }
  }

  setButtonText(): void {
    this.buttonText = {
      default: "Match me now",
      fan: "See the next matching date"
    }[this.selectedOption.type];
  }

  execute() {
    this.buttonClickedSource.next(this.selectedOption);
  }
}
