import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {CheckboxListComponent} from "./checkbox-list.component";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [CheckboxListComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [CheckboxListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CheckboxListModule {}
