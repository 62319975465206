import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMap } from './chat-map';

@NgModule({
  declarations: [
    ChatMap
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ChatMap
  ]
})
export class ChatMapModule {}
