export const FEEDBACK_STATES = {
  leave: {
    title: "Everything OK?",
    description: "Please tell us why you're leaving.",
    options: [
      { icon: 'unresponsive', text: "Unresponsive" },
      { icon: "chatbubbles-outline", text: "Chat fizzled out" },
      { icon: "shapes-outline", text: "Not compatible enough" },
      { icon: "sad-outline", text: "Got uncomfortable" },
      { icon: "flag-outline", text: "Report a user" },
      { icon: "logo-whatsapp", text: "Switched to another app" },
      { icon: "ellipsis-horizontal-outline", text: "Other" }
    ]
  },
  leaveTribesPage: {
    title: "Everything OK?",
    description: "Please tell us why you're leaving.",
    options: [
      { icon: 'unresponsive', text: "Unresponsive" },
      { icon: "chatbubbles-outline", text: "Chat fizzled out" },
      { icon: "shapes-outline", text: "Not compatible enough" },
      { icon: "sad-outline", text: "Got uncomfortable" },
      { icon: "ellipsis-horizontal-outline", text: "Other" }
    ]
  },
  report: {
    title: "What's going on?",
    description: "Help us keep We3 safe by telling us why you're reporting this user.",
    options: [
      { icon: 'heart-outline', text: "Using We3 to date" },
      { icon: "warning-outline", text: "Spam or scam" },
      { icon: "camera-outline", text: "Using stolen photo" },
      { icon: "thumb-down", text: "Lying about their age" },
      { icon: "hand-left-outline", text: "Inappropriate content" },
      { icon: "ellipsis-horizontal-outline", text: "Other" }
    ]
  }
};