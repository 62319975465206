import { IonicModule } from '@ionic/angular';
import { NgModule } from "@angular/core";
import { RadarChartComponent } from "./radar-chart.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { Loading } from 'src/app/components/loading/loading';


@NgModule({
  declarations: [
    RadarChartComponent,
  ],
  entryComponents: [
    Loading
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    LoadingModule,
  ],
  exports: [
    RadarChartComponent
  ]
})

export class RadarChartModule {}
