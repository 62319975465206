import { Component, OnInit, Input } from '@angular/core';
import { InstagramService } from 'src/app/services/instagram.service';
import { Config } from 'src/app/services/config/config';
import {ModalController, AlertController, ToastController, PopoverController} from '@ionic/angular';
import { InstagramModalComponent } from 'src/app/components/instagram-modal/instagram-modal.component';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import {AnalyticsService} from '../../services/analytics/analytics.service';
import {SessionService} from "../../services/data/session.service";
import {ColorModeService} from "../../services/native/color-mode.service";
import {takeUntil} from "rxjs/operators";
import {SentryService} from "../../services/performance/sentry.service";
import {MainErrorHandlerService} from "../../services/performance/main-error-handler.service";
import {logc} from "../../shared/helpers/log";
import { DEFAULT_SLIDER_TRANSITION_TIME } from 'src/app/shared/helpers/time-helpers';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {
  @Input() displayProfile :any;
  @Input() isCurrentUserProfile: boolean;
  @Input() currentUserInstagramToken;
  @Input('fromMatches') fromMatches: boolean;

  private instagramFailed: boolean = false;
  public isPlus: boolean;

  onMediaRefLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public needsReconnection: boolean = false;

  instaTokenDisplayed;
  displayedMedia:any;
  loadedImgs = {};

  public isDarkMode: boolean = false;
  private unsubscribe = new Subject();

  constructor(public instagramService: InstagramService,
              public modalCtrl: ModalController,
              public alertCtrl: AlertController,
              public utils: UtilsService,
              public config: Config,
              private colorModeService: ColorModeService,
              private analyticsService: AnalyticsService,
              private errorTrackingService: SentryService,
              private mainErrorHandler: MainErrorHandlerService,
              private sessionService: SessionService) {
              }
  ngOnInit() {
    (window as any).insta = this;
    this.colorModeService
      .onModeChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(mode => {
        this.isDarkMode = mode == 'dark';
      });

    this.isDarkMode = this.colorModeService.isDark();
    this.isPlus = this.config.isPlus();
    this.instaTokenDisplayed = this.displayProfile.instagramToken;
    if(this.instaTokenDisplayed != 'none'){
      this.displayProfileRecentMedia(this.instaTokenDisplayed);
    }
    this.instagramService.onAccessTokenAvailable.subscribe({next: token => {
      this.displayProfileRecentMedia(token);
      this.currentUserInstagramToken = token;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  verySpecialCondition() {
    return (this.config.get('email') === this.config.get('instagramTestUser')) || !this.config.get('instagramTestUser');
    // return true;
  }

  buttonBlocked = false;
  async loginToInsta(){
    if(this.buttonBlocked) return;
    this.buttonBlocked = true;

    try {
      this.analyticsService.trackEvent({ key: 'connect_instagram_clicked', value: 1 });
      setTimeout(() => {
        this.sessionService.connectingInstagram = true;
      }, 2000);
      setTimeout(() => this.buttonBlocked = false, 5000);
      await this.instagramService.initInstagramLogin();
      this.needsReconnection = false;
    } catch ( err ) {
      console.log( err );
      this.processError( new Error(err) );
      this.analyticsService.trackEvent({
        key: 'connect_instagram_error', 
        value: 1, 
        ctx: JSON.stringify(err), 
        step: 'loginToInsta',
      });
    } finally {
    }
  }
  /**
  instaTokenDisplayed = {access_token:string
                user_id: string}
  **/
  async displayProfileRecentMedia(instaTokenDisplayed){
    // bot profile
    if(instaTokenDisplayed.access_token == "eee") return;
    if(instaTokenDisplayed == 'none') return;

    try {
      this.displayedMedia = await this.instagramService
        .getRecentMedia(
          instaTokenDisplayed.user_id,
          instaTokenDisplayed.access_token
        );
      this.onMediaRefLoadedSubject.next(true);
    } catch ( err ) {
      console.log( err );
      this.processError( err );
      this.disconnect();
      this.analyticsService.trackEvent({
        key: 'connect_instagram_error', 
        value: 1, 
        ctx: JSON.stringify(err), 
        step: 'displayProfileRecentMedia',
      });
    }
  }

  async zoomPic(index){
    if(this.fromMatches && !this.isPlus) {
      return;
    } else {
      const modal = await this.modalCtrl.create({
        component: InstagramModalComponent,
        componentProps: {
          displayedMedia: this.displayedMedia,
          initialSlide: index,
          isCurrentUserProfile: this.isCurrentUserProfile,
          currentUserInstagramToken: this.currentUserInstagramToken,
        },
      });
      await modal.present();
    }
  }

  processError(error) {
    this.mainErrorHandler.handleError(
      new Error(JSON.stringify({
        message: "Instagram error",
        error: error
      }))
    );

    if(error.status === 400) {
      this.needsReconnection = true;
    }
  }

  onImgLoaded(index){
    this.loadedImgs[index]=true;
  }

  showDisconnectAlert(){
    let alert = this.alertCtrl.create({
      header: '',
      message: 'Disconnect Instagram from your profile?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',

        },
        {
          text: 'Disconnect',
          handler: () => this.disconnect()
        }
      ]
    }).then(alert =>{
      alert.present();
    })
  }

  disconnect() {
    this.instagramFailed = false;
    this.instagramService.disconnectInstagram().then(_=>{
      this.utils.showGenericMessage('Your instagram account was succesfully disconnected');
      this.config.updateProfile({instagramToken: null}).then(_=>{
        this.instaTokenDisplayed = 'none';
        this.displayedMedia = null;
        this.onMediaRefLoadedSubject.next(false);
      })
    }).catch(err =>{
      this.utils.errorContext = 'instagram disconnect, error: ' + JSON.stringify(err);
      this.utils.showGenericError({
        key: "instagram_disconnect"
      });
    });
  }
}
