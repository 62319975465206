import { Injectable } from '@angular/core';
import {AlertController, ModalController, NavController, Platform, PopoverController} from "@ionic/angular";
import {GenericPopup} from "../../components/generic-popup/generic-popup";
import {DispatcherService} from "../dispatcher.service";
import {Executed} from "../../shared/decorators/executed";
import {blankFn, getDefaultPicture, isMobile, keys} from "../../shared/helpers/helpers";
import {environment} from "../../../environments/environment";
import {logc} from "../../shared/helpers/log";
import {PictureStatus} from "../profile-picture.service";
import {getWeekDay} from "../../shared/helpers/time-helpers";
import {ZoomBoxComponent} from "../../components/zoom-box/zoom-box.component";
import {Config} from "../config/config";
import {UserService} from "../data/user.service";
import {UtilsService} from "../utils.service";
import {FeedbackService} from "./feedback.service";
import {AnalyticsService} from "../analytics/analytics.service";
import {ModalService} from "./modal.service";
import {SearchService} from "../data/search.service";
import {InstagramService} from "../instagram.service";
import {PushNotification} from "../push-notification/push-notification";
import {AudiencesService} from "../analytics/audiences.service";
import {OpenNativeSettings} from "@ionic-native/open-native-settings/ngx";
import {TribeService} from "../data/tribe.service";
import {TribesService} from "../data/tribes.service";
import { MatchPreferencesLocation } from 'src/app/shared/enums/match-preferences.enum';
import { UpsellingPoppingReason } from 'src/app/pages/upselling/upselling.page';

interface PopupContent {
  image: string; // ./assets/img/name.png | http://rick.astley.com/nevergonnagiveyouup.png;
  gradient: PopupGradient;
  header: string;
  title: string;
  description: string;
}

type PopupGradient = "secondary-gradient" | "tertiary-gradient-dark" | "primary-gradient" | "error-gradient";

interface PopupOptions {
  popupName: string;
  options: any;
  callback?: any
}

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private popups: any = null;
  private context: any = {};

  constructor(private popoverCtrl: PopoverController,
              private config: Config,
              private alertCtrl: AlertController,
              private analyticsService: AnalyticsService,
              private navCtrl: NavController,
              private utils: UtilsService,
              private feedbackService: FeedbackService,
              private userService: UserService,
              private searchService: SearchService,
              private modalService: ModalService,
              private modalCtrl: ModalController,
              private settings: OpenNativeSettings,
              private instagramService: InstagramService,
              private dispatcherService: DispatcherService,
              private pushService: PushNotification,
              private platform: Platform,
              private tribeService: TribeService,
              private tribesService: TribesService,
              private audiencesService: AudiencesService) {
    if(environment.name == 'development') {
      (window as any).popupService = this;
    }
  }

  init() {
    this.populatePopups();
    this.dispatcherService
      .onNewPopup
      .subscribe(async (data: PopupOptions) => {
        await this.createPopup( data )
      });
  }

  getPopups(): any {
    return this.popups;
  }

  async createPopup({
    popupName,
    options: context = {},
    callback = blankFn
  }): Promise<any>{
    logc.info('- Opening popup -');
    console.log("-- popupName: ", popupName);
    console.log("-- context: ", context);

    try {
      const popover = await this.popoverCtrl.create({
        component: GenericPopup,
        componentProps: {
          popup: this.getPopupProps(popupName, context)
        },
        cssClass: `popup ${ !isMobile() ? "desktop" : ""} z-index-50000`,
        backdropDismiss: true
      });
      await popover.present()
      popover
        .onDidDismiss()
        .then(callback)
        .catch((err) => logc.error("** Popup dismiss error: ", err));
      return popover;
    } catch ( err ) {
      logc.error('--- popup.service.ts -> create({ popupName, options }) error: ', err);
    }
  }

  getPopupProps(popupName, context): any {
    return this.popups[popupName](context);
  }

  populatePopups() {
    this.popups = {
      extendExpiryPopup: ({ users, callback }) => ({
        icon: 'timer-outline',
        iconStyles: "text-secondary",
        gradient: 'secondary-gradient',
        header: "Extend the Expiry",
        title: `Give ${ users.length > 1 ? "them" : users[0]?.first_name } 24 hours more`,
        description: `We’ll send them a reminder and give them more time to join.`,
        buttons: [
          {
            text: "Extend",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.close();
              callback();
            }
          }
        ]
      }),
      downloadAppPopup: () => ({
        image: './assets/img/phone-security.png',
        gradient: 'secondary-gradient',
        header: `Download We3`,
        title: "You need the full app to chat with your groups",
        description: `You will remain logged in.`,
        buttons: [
          {
            text: "Get the free app",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.close();
              this.utils.openBranchLink();
            }
          },
          {
            text: 'Later',
            secondary: true,
            cssClass: '',
            action: () => this.close()
          }
        ]
      }),
      addNumberForBackupPopup: () => ({
        image: './assets/img/phone-security.png',
        gradient: 'secondary-gradient',
        header: `Have a Backup`,
        title: "Don’t miss your group if our notification gets blocked",
        description: `To save battery-life, many devices block some notifications without telling you. If our notification gets blocked when your group is ready, we can send you an SMS.`,
        buttons: [
          {
            text: "Add my number",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.close();
              this.navCtrl.navigateForward("number-verification/no_number", { state: { backPath: "dummy-chat"} })
            }
          }
        ]
      }),
      skipMessagePopup: (ctx) => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: `Are you sure?`,
        title: "The chat is unlikely to get off the ground if you skip",
        description: `We3 users expect the person who starts the group to kick-off the conversation. It’s awkward to join a group and see an empty chat. 
          <br> <b>Don’t overthink it. You got this!</b>`,
        buttons: [
          {
            text: "Ok, go back",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          },
          {
            text: "Skip this step",
            secondary: true,
            cssClass: 'text-error',
            action: () => {
              this.close();
              ctx.callback();
            }
          }
        ]
      }),
      resetMatchesRejectPopup: () => ({
        image: './assets/img/reset-matches.png',
        gradient: 'secondary-gradient',
        header: `Starting over`,
        title: "Match again with the people you passed on?",
        description: "This will allow you to match with the people you previously indicated you weren’t feeling.",
        buttons: [
          {
            text: "Start Over",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => {
              this.resetMatches();
            }
          }
        ]
      }),
      compatibilityScorePopup: () => ({
        image: './assets/img/psychographics.svg',
        gradient: 'secondary-gradient',
        header: `Compatibility Score`,
        title: "The chances you’ll click",
        description: "This score is our estimate for the likelihood that your group will hit it off when meeting in person.",
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          }
        ]
      }),
      swapFailedPopup: () => ({
        image: './assets/img/stopwatch.png',
        gradient: 'secondary-gradient',
        header: 'Match Update',
        title: `We’re unable to complete the group right now`,
        description: "The best matches for this group are unavailable at the moment. Please try again in a few days.",
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          }
        ]
      }),
      firstPassingEducationalPopup: () => ({
        image: './assets/img/brain.png',
        gradient: 'secondary-gradient',
        header: 'Keep In Mind',
        title: `Pictures are bad predictors of friendship potential`,
        description: "Try not to trip yourself up by passing without a strong reason, because the more you pass, the less compatible your matches will be.",
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          }
        ]
      }),
      secondPassingEducationalPopup: () => ({
        image: './assets/img/crying-cat.svg',
        gradient: 'secondary-gradient',
        header: 'What’s wrong?',
        title: `Not finding what you're looking for?`,
        description: "You passed on some pretty cool people. Can you please tell us why?",
        buttons: [
          {
            text: 'Send feedback',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.feedbackService.showFeedbackForm({ context: 'Location Report.' })
          },
          { text: 'Skip', secondary: true, cssClass: '', action: () => this.close() }
        ]
      }),
      thirdPassingEducationalPopup: (context) => ({
        image: './assets/img/siren.png',
        gradient: 'secondary-gradient',
        header: 'Heads Up!',
        title: `Rejecting your matches too often can backfire`,
        description: "Your actions in the app also affect who you match with. " +
          "That means you will start matching with picky users " +
          "who are unlikely to join your groups.\n" +
          "<br><b>Want to start over?</b>",
        buttons: [
          {
            text: 'Okay, start over',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => {
              await this.close({ blockPerforming: true });
              this.dispatcherService.openPopup(context.popupName, context);
            }
          },
          {
            text: 'No, keep going',
            secondary: true,
            cssClass: '',
            action: () => this.close()
          }
        ]
      }),
      firstChatVisitPopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: 'Important',
        title: `It's worth it to be friendly`,
        description: "Your behaviour in the group affects who you match with in the future. For example, ghosting others without a valid reason makes it more likely you'll match with other users who ghost.",
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          }
        ]
      }),
      feedbackModalClosedPopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: 'Important',
        title: 'Honest feedback helps us give you better matches',
        iconStyles: "tertiary-color",
        description: 'Are you sure you want to skip this step?',
        buttons: [
          {
            text: 'Complete feedback',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close({ action: "continue" })
          },
          {
            text: 'Skip feedback',
            secondary: true,
            cssClass: '',
            action: () => this.close({ action: "skip" })
          }
        ]
      }),
      anotherScheduledGroupPopup: () => ({
        image: './assets/img/group-complete.png',
        gradient: 'secondary-gradient',
        header: 'Group Scheduled',
        title: 'Sign up for another one?',
        iconStyles: "tertiary-color",
        description: 'Add another group this week and meet more people sooner.',
        buttons: [
          {
            text: 'Add another group',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          },
          {
            text: 'No thanks',
            secondary: true,
            cssClass: '',
            action: () => {
              this.close();
              this.navCtrl.navigateForward('offboarding');
            }
          }
        ]
      }),
      unreachablePopup: () => ({
        image: './assets/img/no-notifications.png',
        gradient: 'secondary-gradient',
        header: 'We can’t reach you',
        title: 'You have missed important notifications.',
        description: "It appears your device is blocking incoming notifications about your Tribes. Our support team has been notified and is investigating. <br> <strong>We suggest you reinstall We3 in the meantime.</strong>",
        buttons: [
          {text: 'Reinstall from App Store', primary: true, cssClass: 'turquoise-gradient-btn', action: () => this.utils.goToAppStore() }
        ]
      }),
      recommendedExplanationPopup: () => ({
        icon: "stars",
        gradient: 'secondary-gradient',
        header: 'Recommended',
        title: 'The best matches this week',
        iconStyles: "tertiary-color",
        description: 'These matches are more likely to be available and ready to meet up over the weekend',
        buttons: [
          {text: 'Got it', primary: true, cssClass: 'turquoise-gradient-btn', action: () => this.close()}
        ]
      }),
      trustUsPopup: () => ({
        image: './assets/img/pray.png',
        gradient: 'secondary-gradient',
        header: 'Please Trust Us',
        title: 'After 1 million groups, we know what works!',
        description: 'Unfortunately, most of your matches won’t be open to meet you if your profile doesn’t immediately appear friendly, approachable and trustworthy.',
        buttons: [
          {
            text: 'I understand',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          },
          {
            text: 'Ignore advice',
            secondary: true,
            cssClass: 'error-color',
            action: () => {
              this.close({ignored: true});
              this.analyticsService.trackEvent({
                key: "rejected_profile_pic_advice",
                value: 1
              })
              if (this.config.getProfile().pictureStatus == PictureStatus.OnHold) {
                this.userService.updateProfile({pictureStatus: PictureStatus.Verified});
                // this.analyticsService.trackEvent({ key: "picture_change_rejected", value: 1 });
              }
            }
          },
        ]
      }),
      profileStrengthCongratulationsPopup: () => ({
        image: './assets/img/fast-strength.png',
        gradient: 'secondary-gradient',
        header: 'Great Job',
        title: 'Your daily limit was reset',
        description: 'With your Profile Strength at 100%, you can match again today and start another group.',
        buttons: [
          {
            text: 'Try matching again',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.navCtrl.navigateForward("tabs/tribes");
              this.close();
            }
          },
        ]
      }),
      freeDailyLimitPopup: () => ({
        image: './assets/img/first-tribe.png',
        gradient: 'secondary-gradient',
        header: 'Daily Limit',
        title: `Free accounts can start up to 1 group each day`,
        description: 'Skip the wait and match with more groups now with We3 Plus.',
        buttons: [
          { 
            text: 'See all We3 Plus perks', 
            primary: true, 
            cssClass: 'turquoise-gradient-btn', 
            action: () => {
              this.close();
              this.modalService.openUpselling({
                source: "daily_limit_popup",
                reason: UpsellingPoppingReason.DailyGroupLimit
              });
            }
          },
        ]
      }),
      offlinePopup: () => ({
        image: './assets/img/no-connection.svg',
        gradient: 'error-gradient',
        header: 'No Connection',
        title: 'We\'re unable to connect to the Internet.',
        description: 'If the problem persists, restart the app. You can also contact us to report the issue.',
        buttons: [
          {text: 'Retry', primary: true, cssClass: 'turquoise-gradient-btn', action: () => this.retry() },
          {
            text: 'Report the Issue',
            secondary: true,
            action: () => this.feedbackService.showFeedbackForm({ context: 'Connectivity Issue' }) }
        ]
      }),
      richProfilePopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: 'Heads Up!',
        title: 'Your profile could use a boost. ',
        description: 'People are 3.5x more likely to join your group with a strong profile.',
        buttons: [
          {
            text: 'See Suggestions',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.openProfilePage()
          }
        ]
      }),
      minimumTribeFilterPopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: 'Suggestion',
        title: 'Increase your minimum compatibility filter',
        description: 'Stop getting invited to groups below a certain level of compatibility',
        buttons: [
          {
            text: 'Adjust my filters',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.openPreferencesPage()
          }
        ]
      }),
      expiredTribePopup: () => ({
        image: './assets/img/stopwatch.png',
        bodyImage: './assets/img/missed-notification.png',
        gradient: 'secondary-gradient',
        header: 'Group Expired',
        title: 'They slept through it.',
        description: "Unfortunately, the others <strong>didn't see your group</strong> before it expired",
        buttons: [
          {
            text: 'Try a new match',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.tryNewMatch(null)
          }
        ]
      }),
      swapClarificationPopup: () => ({
        image: './assets/img/swap.png',
        gradient: 'secondary-gradient',
        header: 'Group Update',
        title: 'We replaced a member that didn’t join the group.',
        description: "We think this new member is a pretty good match for you two. What do you think?",
        buttons: [
          {
            text: 'See our compatibility',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close({ status: 'openProfile' })
          }
        ]
      }),
      proposedTribePopup: () => ({
        image: './assets/img/swap.png',
        gradient: 'secondary-gradient',
        header: 'Group Update',
        title: 'We replaced the members that didn’t join the group.',
        description: "We think they’re a pretty good match for you. What do you think?",
        buttons: [
          {
            text: 'See our compatibility',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          }
        ]
      }),
      premiumPopup: () => ({
        image: './assets/img/pixel-img.png',
        gradient: 'tertiary-gradient-dark',
        header: 'Premium Feature',
        title: 'View all your matches & hand-pick your groups',
        description: "<strong>Important</strong>: You don’t need this to match. Just tap on “Match me in a group’ and let us find the best groups.",
        buttons: [
          {
            text: 'All We3 Plus Features', 
            primary: true, 
            cssClass: 'turquoise-gradient-btn', 
            action: () => this.modalService.openUpselling({ 
              source: "premium-popup",
              reason: UpsellingPoppingReason.SeeAllMatches
            })
          }
        ]
      }),
      updateRadiusToNearMe: () => ({
        image: './assets/img/local.png',
        gradient: 'secondary-gradient',
        header: 'Adjust Filters',
        title: 'Stop matching around the world?',
        description: "Your filters are set to match with compatible groups around the world.",
        buttons: [
          {
            text: 'Match nearby only',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => {
              await this.config.updateMatchPreferences({ location: MatchPreferencesLocation.NearMe });
              this.close({ matchNearby: true });
            }
          },
          {
            text: 'Set a maximum distance',
            secondary: true,
            action: async () => {
              await this.config.updateMatchPreferences({ location: MatchPreferencesLocation.NearMe });
              await this.close({
                closingCallback: () => this.navCtrl.navigateForward("preferences")
              })
            }
          }
        ]
      }),
      locationDisabledPopup: () => ({
        image: './assets/img/local.png',
        gradient: 'secondary-gradient',
        header: 'Enable GPS',
        title: "We can't access your location",
        description: "To match you with people near you, make sure your location is enabled.",
        buttons: [
          {
            text: 'Enable Location',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.openLocationSettings()
          }
        ]
      }),
      locationRequestFailed: () => ({
        image: './assets/img/local.png',
        gradient: 'secondary-gradient',
        header: 'Request Failed',
        title: "We're unable to get this device's location.",
        description: "Please close We3, reopen it and try again. If it fails again, please contact support and include the city and country you're currently in.",
        buttons: [
          {text: 'Got it', primary: true, cssClass: 'turquoise-gradient-btn', action: () => this.close()},
          {
            text: 'Contact support',
            secondary: true,
            cssClass: '',
            action: () => this.feedbackService.showFeedbackForm({ context: 'Location Report.' })
          }
        ]
      }),
      updateRadiusToGlobal: () => ({
        image: './assets/img/global.png',
        gradient: 'secondary-gradient',
        header: 'Want a group now?',
        title: 'Match around the world',
        description: "We’ll keep trying to complete your group nearby while you match around the world.",
        buttons: [
          {
            text: 'Match around the world',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => {
              await this.close();
              await this.userService.setGlobalPreferences()
              await this.searchService.startSearch();
            }
          }
        ]
      }),
      noDatingPopup: () => ({
        image: './assets/img/not-for-dating.png',
        gradient: 'secondary-gradient',
        header: 'Important Notice',
        title: 'We3 is <span class = "bold underlined">not</span> a dating app!',
        description: "Help us keep We3 awesome by reporting anyone using We3 to date.",
        buttons: [
          {text: 'I understand', primary: true, cssClass: 'turquoise-gradient-btn', action: () => this.close()},
          {
            text: "Then I'm not interested",
            secondary: true,
            action: () => {
              this.close();
              this.utils.askToDeleteApp();
            }
          }
        ]
      }),
      instagramFailed: () => ({
        icon: 'instagram-colored',
        gradient: 'secondary-gradient',
        header: 'Connection failed',
        title: 'Instagram has rejected your request.',
        description: "In most cases, it’s because your Instagram account doesn’t have an age associated with it. Make sure they have your age and try again.",
        buttons: [
          {
            text: 'Open Instagram',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.openInstagram()
          },
          {text: 'Try connecting again', secondary: true, cssClass: '', action: () => this.loginInstagram()}
        ]
      }),
      addNumberPopup: () => ({
        image: './assets/img/phone-security.png',
        gradient: 'secondary-gradient',
        header: 'Add a Back-up',
        title: 'Notifications can get blocked',
        description: "To save battery-life, your phone regularly blocks some notifications without telling you. \n If a notice about a new Group is blocked, We3 can send you an SMS. \n You can disable this at any time.",
        buttons: [
          {text: 'Got it', primary: true, cssClass: 'turquoise-gradient-btn', action: () => this.close()},
        ]
      }),
      blockedNotificationsPopup: () => ({
        image: './assets/img/no-notifications.png',
        gradient: 'secondary-gradient',
        header: `Notifications blocked`,
        title: 'We seem unable to send you important notifications',
        description: `To change your status to "Open", we need to be able to notify you of new groups or messages. Please download the app to resolve this issue`,
        buttons: [
          {
            text: 'Get the app',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.close();
              this.utils.getTheApp();
            },
          }
        ]
      }),
      numberVerificationCarrierNoticePopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: `Mobile Carrier Notice`,
        title: 'We seem unable to reach you via SMS',
        description: "Either your mobile carrier blocked our SMS or we have stopped using them due to their high delivery fees. Don’t worry, number verification is not required to use We3.",
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.close();
              this.navCtrl.navigateForward('tabs/tribes');
            },
          }
        ]
      }),
      notifyNativeCarrierNoticePopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: `Mobile Carrier Notice`,
        title: 'We seem unable to reach you via SMS',
        description: "Either your mobile carrier blocked our SMS or we have stopped using them due to their high delivery fees.",
        buttons: [
          {
            text: 'Use Push Notifications',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => {
              await this.pushService.requestPushPermissionAndInit();
              this.close();
              this.navCtrl.navigateForward('tabs/tribes');
              if (!this.config.getFlag('start_global_tribe_viewed') && !this.audiencesService.inScheduledGroup()) {
                this.createPopup({ popupName: "updateRadiusToGlobal" });
                this.config.setFlag('start_global_tribe_viewed', true);
              }

            },
          }
        ]
      }),
      notifyPwaCarrierNoticePopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: `Mobile Carrier Notice`,
        title: 'We seem unable to reach you via SMS',
        description: "Either your mobile carrier blocked our SMS or we have stopped using them due to their high delivery fees. You’ll need the full app so we can notify you via push notifications.",
        buttons: [
          {
            text: 'Get the full app',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => {
              this.close();
              this.navCtrl.navigateForward('tabs/tribes');
              window.location.href = this.utils.getBranchLink();
            },
          }
        ]
      }),
      minTribeQualityPopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'primary-gradient',
        header: `Suggestion`,
        title: 'Increase your minimum compatibility filter',
        description: "Stop getting invited to groups below a certain level of compatibility.",
        buttons: [
          {
            text: 'Adjust my Filters',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.openPreferencesPage()
          },
        ]
      }),
      invitationExpiredPopup: () => ({
        image: './assets/img/swap.png',
        gradient: 'secondary-gradient',
        header: `Invitation expired`,
        title: "You're no longer in this group",
        description: `Our notifications didn’t go through when the Group was started, so a Group member had you replaced. <br> ${this.config.isIOS ? "" : "Learn more about why you were replaced and how to prevent it."}`,
        buttons: [{
          text: this.config.isIOS ? "Got it" : 'Learn more',
          primary: true,
          cssClass: 'turquoise-gradient-btn',
          action: () => this.config.isIOS ? this.close() : this.learnHowToFix()
        }]
      }),
      noTokenPopup: () => ({
        image: './assets/img/no-notifications.png',
        gradient: 'secondary-gradient',
        header: `Notifications Blocked`,
        title: "We’re unable to reach you.",
        description: "To receive important notifications again, open We3’s settings and allow notifications.",
        buttons: [
          {
            text: this.config.isPWA ? 'Learn how to fix' : 'Open We3 settings',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.config.isPWA ? this.seeInstructions() : this.openSettings()
          },
          {
            text: 'Contact support',
            secondary: true,
            action: () => this.contactSupport()
          }
        ]
      }),
      away2Popup: () => ({
        image: './assets/img/no-notifications.png',
        gradient: 'secondary-gradient',
        header: `Notification Issues`,
        title: "Your device is delaying important notifications.",
        description: "Some notifications are taking over 24 hours to show up. Please fix these issues in your device’s settings.",
        buttons: [
          {
            text: 'Learn how to fix it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.learnHowToFix()
          },
          {
            text: 'Contact support',
            secondary: true,
            cssClass: '',
            action: () => this.contactSupport()
          }
        ]
      }),
      away3Popup: () => ({
        image: './assets/img/no-notifications.png',
        gradient: 'secondary-gradient',
        header: `Notification Issues`,
        title: "We paused your account. You can’t get groups right now.",
        description: "It seems your device is blocking or delaying our notifications. <br> Before you update your status to match again, please fix these issues in your device’s settings.",
        buttons: [
          {
            text: 'Learn how to fix',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.learnHowToFix()
          },
          {text: 'Contact support', secondary: true, cssClass: '', action: () => this.contactSupport()}
        ]
      }),
      matchingAbortedPopup: () => ({
        image: './assets/img/local.png',
        gradient: 'secondary-gradient',
        header: `Matching Aborted`,
        title: "We’re missing your location",
        description: "Your device didn't send us your desired matching location in time. Try restarting We3, as this forces your device to try again.",
        buttons: [
          {
            text: this.prepareMatchingAborted().button,
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.prepareMatchingAborted().handler()
          }
        ]
      }),
      blockContactsPopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: `Blocking Contacts`,
        title: "We3 can collect your contact list to prevent them from seeing or matching with you",
        description: "People you already know might be using We3, or will in the future. Sharing your contacts list blocks them in advance. We will never contact them.",
        buttons: [
          {
            text: "Block my contacts",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.close();
              this.userService.blacklistContacts()
            }
          }
        ]
      }),
      haveBackupPopup: () => ({
        image: './assets/img/phone-security.png',
        gradient: 'secondary-gradient',
        header: `Have a Backup`,
        title: "Don’t miss amazing groups if a notification is blocked",
        description: "To save battery-life, many devices regularly block some notifications without telling you. If you get invited to join a new group but it gets blocked, we can send you an SMS before the invite expires.",
        buttons: [
          {
            text: "Got it",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          }
        ]
      }),
      matchScheduledPopup: () => ({
        image: './assets/img/group-complete.png',
        gradient: 'secondary-gradient',
        header: `Match Scheduled`,
        title: "Your group is on the way",
        description: `We're already working on finding the perfect group. You're all set to match on ${getWeekDay(this.config.getFlag("next_scheduled_matches_at"))}.`,
        buttons: [
          {
            text: "Got it",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          }
        ]
      }),
      notificationBackupPopup: () => ({
        image: './assets/img/phone-security.png',
        gradient: 'secondary-gradient',
        header: `Have a Backup`,
        title: "Don’t miss your group if our notification gets blocked  ",
        description: "To save battery-life, many devices block some notifications without telling you. If our notification gets blocked when your group is ready, we can send you an SMS.",
        buttons: [
          {
            text: "Got it",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          },
          {
            text: "Skip my number",
            secondary: true,
            cssClass: '',
            action: () => {
              this.close();
              this.skipNumber()
            }
          }
        ]
      }),
      resetMatchesPopup: (context) => ({
        image: './assets/img/reset-matches.png',
        gradient: 'secondary-gradient',
        header: `Starting over`,
        title: "Match again with the people you passed on?",
        description: "This will allow you to match with the people you previously indicated you weren’t feeling.",
        buttons: [
          {
            text: "Start Over",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => this.resetMatches(() => this.navCtrl.navigateBack('tabs/tribes'))
          }
        ]
      }),
      groupSavedPopup: () => ({
        image: './assets/img/group-saved.png',
        gradient: 'secondary-gradient',
        header: `Group Saved`,
        title: "Get notified when it’s ready",
        description: "New people join We3 every day, so we’ll notify you as soon as we detect the right person to complete this group. We hope it won’t take long. 🤞",
        buttons: [
          {
            text: "Notify me when it’s ready",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => {
              this.analyticsService.trackEvent({
                key: 'notify_me_when_tribe_is_ready',
                value: 1,
                has_push: this.pushService.hasPermission ? 1 : 0,
                has_sms: this.config.getProfile().phoneNumberStatus === 'verified' ? 1 : 0
              });
              this.close();
              let page = '';
              let extras = {};
              if (this.config.getProfile().phoneNumberStatus === 'verified'
                || this.config.getFlag('codePageHiddenButtonPushed')
                || this.config.getFlag("numberVerificationSkippedFromNotifBackupPopup")) {
                page = 'tabs/tribes';
              } else {
                page = 'number-verification/notify_me';
                extras = {state: {fromAwaiting: true}};
                this.utils.showGenericMessage("We'll notify you when your group is ready");
              }
              await this.navCtrl.navigateForward(page, extras);
            }
          }
        ]
      }),
      callSuggestionPopup: () => ({
        image: './assets/img/lightbulb.png',
        gradient: 'secondary-gradient',
        header: 'Suggestion',
        title: 'Let our friendly staff help you get started',
        description: 'Our support team would love to help you set up your We3 account over a Zoom call right now. There’s no cost and you don’t even need to turn on your camera.',
        buttons: [
          {
            text: 'OK, let’s do a Zoom call',
            primary: true,
            action: async () => {
              try {
                const modal = await this.modalCtrl.create({
                  component: ZoomBoxComponent,
                  cssClass: 'profile-modal modal-card'
                })
                modal.present();
              } catch (e) {
                console.log(`Creating zoom modal error: ${e}`);
              } finally {
                this.close()
              }
            }
          },
          {
            text: 'No thanks',
            secondary: true,
            action: () => this.close()
          }
        ]
      }),
      callBookedPopup: () => ({
        image: './assets/img/group-saved.png',
        gradient: 'secondary-gradient',
        header: "Call Booked",
        title: "We'll join the Zoom call shortly",
        description: "Please join the Zoom call and we’ll be there very soon. If you don’t have the Zoom app already installed, just follow the instructions to install it. We sent the link to your email too.",
        buttons: [
          {
            text: 'Join the Zoom call',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: async () => {
              this.userService.requestUsabilityTest();
            }
          }
        ]
      }),
      matchingNearMePopup: (context) => ({
        image: './assets/img/group-saved.png',
        gradient: 'secondary-gradient',
        header: 'Matching Near Me',
        title: `${context?.city} is near you`,
        description: `Selecting another city is a premium feature to match in a city you’re not currently in. Since you’re near ${context?.city}, you can match there for free.`,
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          }
        ]
      }),
      singleReplaceablePopup: ({ replaceableUser, tribeId }) => ({
        image: replaceableUser?.picture || getDefaultPicture(this.config.get("gender")),
        gradient: 'secondary-gradient',
        header: `Replace ${ replaceableUser?.first_name }?`,
        title: `We’re struggling to reach them at the moment`,
        description: "We haven’t received confirmation that their invitation to the group was successfully delivered.",
        buttons: [
          {
            text: `Replace`,
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.replaceUser( replaceableUser?.id, tribeId )
          },
          {
            text: 'Wait and see', secondary: true, cssClass: '', action: () => this.close()
          }
        ]
      }),
      multipleReplaceablePopup: ({ tribeId }) => ({
        image: './assets/img/no-notifications.png',
        gradient: 'secondary-gradient',
        header: `Group Update`,
        title: "We're unable to reach the other group members",
        description: "Their phone is off, low on battery, or they recently uninstalled We3. <br/> <strong> Only 12% of users in this situation end up seeing the group within 24h. <strong/>",
        buttons: [
          {
            text: "Leave group",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.confirmLeaving(tribeId)
          },
          { text: 'Wait and see', secondary: true, cssClass: '', action: () => this.close()  }
        ]
      }),
      radarChartTipPopup: ({ firstName = "user" }) => ({
        image: './assets/img/radar-chart.png',
        gradient: 'secondary-gradient',
        header: 'What\'s this?',
        title: `A fancy chart of ${ firstName }`,
        description: "Your Radar Chart is a visual summary of your responses across the many subjects we use to match you. The shape is unique to you, and you can use it to compare yourself to others.",
        buttons: [
          {
            text: 'Learn more',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.openInfoModal({ type: 'radar' })
          },
        ]
      }),
      removeUserPopup: ({ requester, removee, tribeId, otherUser }) => ({
        image: removee?.picture || getDefaultPicture(this.config.get("gender")),
        gradient: 'secondary-gradient',
        header: `Removal Request`,
        title: `${ requester?.first_name } wants to remove ${ removee?.first_name } from this group`,
        description: `If you agree with ${ requester?.first_name }, ${ removee?.first_name } will lose access to the group and the chat history.`,
        buttons: [
          {
            text: `Remove ${ removee?.first_name }`,
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.removeUser({
              removeeId: removee.id,
              tribeId: tribeId,
              otherUserId: otherUser.id
            })
          },
          {
            text: "Don't remove",
            secondary: true,
            cssClass: '',
            action: () => this.denyRemoval({
              userId: otherUser.id,
              tribeId: tribeId
            })
          }
        ]
      }),
      removalRequestPopup: ({ requester, removee, otherUser, tribeId }) => ({
        image: removee?.picture || getDefaultPicture(this.config.get("gender")),
        gradient: 'secondary-gradient',
        header: `Remove ${ removee?.first_name }?`,
        title: `${ otherUser?.first_name } must also agree with your request`,
        description: `If you both agree, ${ removee?.first_name } will lose access to the group and the chat history. Otherwise, everyone will remain in the group.`,
        buttons: [
          {
            text: `Request removal`,
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.requestRemoval({
                requesterId: requester.id,
                removeeId: removee.id,
                tribeId: tribeId
              })
            }
          }
        ]
      }),
      otherUserBeGonePopup: ({ users, handler, matches }) => ({
        image: './assets/img/waving-hand.svg',
        gradient: 'secondary-gradient',
        header: `Are you sure?`,
        title: users?.length === 1 ? `${users[0]?.first_name} will also be gone.` : "You might not match again right away.",
        description: users?.length === 1
          ? `We match on <span class = "lato bold italic ">group compatibility</span>, so rejecting one member dismisses the group. You might still match with ${users[0]?.first_name} 
         in another group. ${this.config.getFlag('total_matches_under_75') < 10 ? '' : 'To take control and pick group members <span class = "lato bold italic">individually</span>, go to My Matches'}`
          : "By passing, you might run out of your highly compatible matches for the time being.",
        buttons: [
          {
            text: "Continue",
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => {
              this.close();
              handler();
            }
          },
          {
            text: users?.length === 1 && !(matches < 10) ? "Go to My Matches" : "Go back",
            secondary: true,
            cssClass: '',
            action: async () => {
              this.close();
              if(users?.length === 1 && !(matches < 10)) {
                await this.navCtrl.navigateBack("tabs/tribes");
                await this.navCtrl.navigateForward("matches");
              }
            }
          }
        ]
      }),
      profileCheckupPopup: ({ matches = 0, city, handler }) => {
        const lowDensity = matches < 10;
        let buttons = [];

        if(lowDensity) {
          buttons = [
            {
              text: "Try matching anyway",
              primary: true,
              cssClass: 'turquoise-gradient-btn',
              action: () => {
                this.close();
                handler();
              }
            }
          ]
        } else {
          buttons = [
            {
              text: "Yes, it’s fine",
              primary: true,
              cssClass: 'turquoise-gradient-btn',
              action: () => {
                this.close();
                handler();
              }
            },
            {
              text: "Review my profile",
              secondary: true,
              cssClass: '',
              action: () => {
                this.close();
                this.navCtrl.navigateForward('tabs/profile');
              }
            }
          ]
        }

        return {
          image: './assets/img/lightbulb.png',
          gradient: 'secondary-gradient',
          header: lowDensity ? `Heads up!` : 'Profile Checkup',
          title: lowDensity ? `We’re fairly new to ${ city ? city : 'this city.' }` : "Is your profile ready?",
          description: lowDensity
            ? "There isn’t a ton of activity among users in your age range just yet."
            : "If you decide to start a group, your group members will see your profile. Is it ready to show?",
          buttons
        }
      },
      firstApprovedPopup: ({ secondNeedsReviewName, standbyName }) => ({
        image: './assets/img/group-saved.png',
        gradient: 'secondary-gradient',
        header: 'Approved',
        title: `We've notified ${ secondNeedsReviewName } of your approval`,
        description: `As soon as ${ secondNeedsReviewName } approves ${ standbyName }, we'll invite them to join the group`,
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          },
        ]
      }),
      secondApprovedPopup: ({ standbyName }) => ({
        image: './assets/img/group-saved.png',
        gradient: 'secondary-gradient',
        header: 'Approved',
        title: `We've invited ${ standbyName } to the group!`,
        description: `We hope they join soon.`,
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          },
        ]
      }),
      remote: (context) => ({
        image: "./assets/img/lightbulb.png",
        gradient: 'secondary-gradient',
        ...context,
        buttons: [
          {
            text: 'Got it',
            primary: true,
            cssClass: 'turquoise-gradient-btn',
            action: () => this.close()
          },
        ]
      })
    }
  }

  async close(data = null): Promise<any> {
    return this.popoverCtrl.dismiss(data);
  }

  async retry(): Promise<any> {
    try {
      await this.popoverCtrl.dismiss();
      await this.navCtrl.navigateRoot('/');
      this.dispatcherService.sessionResumeSource.next(true);
    } catch ( err ) {
      logc.error("* Retrying resume from popup error: ", err);
    }
  }

  async openProfilePage(): Promise<any> {
    await this.close();
    await this.navCtrl.navigateBack('tabs/profile', { state: { openProgress: true } });
  }

  async openPreferencesPage(): Promise<any> {
    await this.close();
    await this.navCtrl.navigateBack('preferences');
  }

  openInfoModal( options ) {
    this.close();
    this.dispatcherService.newInfoModalSource.next(options)
  }

  async tryNewMatch(tribeId): Promise<any> {
    await this.popoverCtrl.dismiss();
    await this.tribeService.delete({ tribeId });
    await this.config.setFlag('visitedExpiredTribe', false);
    await this.navCtrl.navigateBack('tabs/tribes');
  }

  openLocationSettings(): Promise<any> {
    this.utils.openLocationSettings();
    return this.close();
  }

  openInstagram() {
    this.close();
    location.href = 'http://instagram.com';
  }

  loginInstagram() {
    this.close();
    this.instagramService.initInstagramLogin();
  }

  async learnHowToFix() {
    await this.close();
    await this.navCtrl.navigateForward('whitelist');
  }

  async seeInstructions() {
    window.location.href = this.utils.getInstructionsLink();
  }

  async openSettings() {
    await this.settings.open("settings");
  }

  contactSupport() {
    this.feedbackService
      .showFeedbackForm({
        context: 'Contact support',
        errorContext: 'Notifications issues'
      })
  }

  prepareMatchingAborted() {
    let button = '';
    let handler: any;
    if(this.platform.is('cordova')) {
      if(this.config.isIOS) {
        button = "Got it";
        handler = () => this.close();
      } else {
        button = "Close We3"
        handler = () => navigator['app'].exitApp(); // android only
      }
    } else {
      button = "Reload";
      handler = () => {
        location.reload();
      }
    }
    return { button, handler }
  }

  async skipNumber() {
    this.analyticsService.trackEvent({
      key: "skipped_number_sms_opt_in",
      value: 1
    })
    await this.config.setFlag('numberVerificationSkippedFromNotifBackupPopup', true);
    try {
      await this.pushService.requestPushPermissionAndInit();
      this.utils.showGenericMessage("We’ll notify you when your group is ready.");
    } catch (err) {
      this.utils.showGenericMessage(
        "We can’t notify you unless you enable notifications.",
        3000,
        "Enable",
        () => this.navCtrl.navigateForward('notifications/new-tribe-invites')
      )
    } finally {
      if (!this.config.getFlag('start_global_tribe_viewed') && !this.audiencesService.inScheduledGroup()) {
        this.dispatcherService.openPopup('updateRadiusToGlobal');
        this.config.setFlag('start_global_tribe_viewed', true);
      }
      this.navCtrl.navigateBack("tabs/tribes");
    }
  }

  async resetMatches(callback = () => {}): Promise<void> {
    this.close();
    try {
      // this.tribeService._leaveTribe({
      //   tribe,
      //   reason: "passed_not_feeling_it"
      // } as any);
      await this.tribesService.emptyBlacklist();
      this.config.refreshDailyDeclinedUsers();
      this.config.refreshDailyRejectedUsers();
    } catch(e) {
      this.utils.showGenericError({});
    }
  }

  async confirmLeaving(tribeId) {
    this.close();
    try {
      const alert = await this.alertCtrl.create({
        header: "Leave group?",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: 'cancel-alert-btn'
          },
          {
            text: "Leave",
            handler: () => this.leaveTribe('Both users are unreachable', tribeId)
          }
        ]
      });
      await alert.present();
    } catch (err) {
      console.log(err);
    }
  }

  async leaveTribe(
    reason = '',
    tribeId,
    reportedUsersIds = [],
    details = ''
  ) {
    try {
      await this.tribeService.delete({
        tribeId,
        reason: reason,
        reportedUsers: reportedUsersIds,
        details: details
      });
      this.utils.showGenericMessage(`You left the group.`);
      this.utils.showLoading('Leaving...');

    } catch ( err ) {
      console.log( err );
    }
  }

  async removeUser({ removeeId, tribeId, otherUserId }){
    this.close();
    await this.tribeService.removeUser( removeeId, tribeId );
    this.analyticsService.trackEvent({
      value: 1,
      key: 'removal_approved',
      user_id: otherUserId,
      removed_user_id: removeeId,
      tribe_id: tribeId
    })
  }

  async replaceUser(userId, tribeId) {
    this.close();
    this.dispatcherService.statusBarChangeSource.next('hide');
    await this.tribeService.replaceUser(userId, tribeId);
  }

  async requestRemoval({ requesterId, removeeId, tribeId }) {
    this.close();
    await this.tribeService.requestUserRemoval( requesterId, removeeId, tribeId );
    this.analyticsService.trackEvent({
      value: 1,
      key: 'removal_requested',
      requester_id: requesterId,
      removed_user_id: removeeId,
      tribe_id: tribeId
    })
  }

  async denyRemoval({ userId, tribeId }) {
    this.close();
    this.dispatcherService.statusBarChangeSource.next('hide');
    try {
      await this.tribeService.denyRemoval({
        userId: userId,
        tribeId: tribeId
      });
      let tribe = this.tribesService.data.find(tribe => tribe.id === tribeId);
      if(tribe && tribe.data) {
        tribe.data.removal_requests = {};
      }
      this.tribesService.tribeChangedSource.next( tribe );
    } catch ( err ) {
      console.log( err );
    }
  }

  run = (popupNames: string[] = keys(this.popups)) => {
    this.createPopup({ popupName: popupNames.shift() })
    setTimeout(async () => {
      await this.close();
      if(popupNames.length == 0) {
        console.log("all popups tested");
      } else {
        this.run(popupNames);
      }
    }, 300)
  }
}
