export {}

declare global {
  interface String {
    firstWord(): string;
    capitalize(): string;
  }
}

if(!String.prototype.firstWord) {
  String.prototype.firstWord = function(): string {
    if(this == undefined) return this;
    return this.split(' ')[0];
  }
}

if(!String.prototype.capitalize) {
  String.prototype.capitalize = function(): string {
    if(typeof this != "string") return this;
    return this[0].toUpperCase() + this.slice(1, this.length);
  }
}
